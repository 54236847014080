import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "review-status-selector" },
    [
      _vm.items.length
        ? _c(VSelect, {
            staticStyle: { width: "200px" },
            attrs: {
              value: _vm.selectedStatus,
              items: _vm.items,
              outlined: "",
              "hide-details": "",
              "single-line": "",
              placeholder: "Select data status",
            },
            on: {
              change: function ($event) {
                return _vm.$emit("on-status-select", $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }