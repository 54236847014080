<template lang="pug">
v-layout(column)
  //- Seasons
  v-flex.mb-6(xs12 d-flex)
    AppMultiselect(
      name="Season"
      help-text="Select seasons in which the partner is allowed to sell"
      value-prop="label"
      v-model="values.seasons"
      :saved-value="savedValues.seasons"
      :attributes="attributes.seasons"
      :readonly="isSubmitting || isReadonly || isExporting"
    )

  //- Brands
  v-flex.mb-6(xs12 d-flex)
    AppMultiselect(
      name="Brand"
      help-text="Select brands which the partner is allowed to sell"
      value-prop="code"
      v-model="values.brands"
      :saved-value="savedValues.brands"
      :name-prop="brandText"
      :attributes="attributes.brands"
      :readonly="isSubmitting || isReadonly || isExporting"
      :on-brand-change="onBrandChange"
    )

  //- Owner Brands
  v-flex.mb-6(v-if="isACUChangesEnabled" xs12 d-flex)
    AppMultiselect(
      name="Brand ownership"
      help-text="Select brands which the partner is allowed to update"
      value-prop="code"
      v-model="values.ownerBrands"
      :saved-value="savedValues.ownerBrands"
      :name-prop="ownerBrandText"
      :attributes="modifiedOwnerBrandAttributes"
      :readonly="isSubmitting || isReadonly || isExporting || values.brands.length === 0"
    )

  //- Silhouette
  v-flex.mb-6(xs12 d-flex)
    SilhouetteField(
      v-model="values.silhouettes"
      :saved-value="savedValues.silhouettes"
      :attributes="attributes.silhouettes"
      :readonly="isSubmitting || isReadonly || isExporting"
    )

  //- Size Chart
  v-flex.mb-6(xs12 d-flex)
    AppMultiselectAsync(
      help-text="Select the size charts which the partner is allowed to sell"
      name="Size Chart"
      value-prop="id"
      name-prop="legacyCode"
      v-model="values.sizeCharts"
      :saved-value="savedValues.sizeCharts"
      :value-to-attributes-map="sizeAttrsCacheMap"
      :attributes="sizeAttrs"
      :valueAttrs="valueAttrs"
      :loading="sizeAttrsLoading"
      :readonly="isSubmitting || isReadonly || isExporting"
      @update:search-input="$emit('update:sizeSearchInput', $event)"
    )

  v-flex(xs-12 v-if="!isReadonly")
    v-layout(justify-end)
      v-btn.my-0.mx-2(
        ref="cancel"
        color="primary"
        :disabled="!hasChanges || isSubmitting || isExporting"
        @click="$emit('clear')"
      ) Cancel

      v-btn.my-0.mx-2(
        ref="save"
        color="primary"
        :loading="isSubmitting"
        :disabled="!hasChanges || isSubmitting || isExporting"
        @click="$emit('save')"
      ) Save

      v-btn.my-0.mr-0.mx-2(
        ref="export"
        depressed
        color="primary"
        :loading="isExporting"
        :disabled="!canExport || hasChanges || !isComplete || isSubmitting || isExporting"
        @click="$emit('export')"
      ) Send to export
</template>

<script lang="ts">
import Vue from 'vue';
import SilhouetteField from '../silhouette-field/masterdata-silhouette-field.vue';
import AppMultiselect from 'Common/components/multiselect/app-multiselect.vue';
import AppMultiselectAsync from 'Common/components/multiselect-async/app-multiselect-async.vue';
import { canEnableACUChanges } from '@/feature-flags';

export default Vue.extend({
  components: {
    AppMultiselect,
    AppMultiselectAsync,
    SilhouetteField,
  },

  props: {
    /** @type {MasterdataAttributes} */
    attributes: {
      type: Object,
      required: true,
    },
    sizeAttrs: {
      type: Array as () => Array<any>,
      required: true,
    },
    sizeAttrsCacheMap: {
      type: Map,
      required: true,
    },
    sizeAttrsLoading: Boolean,
    /** @type {Masterdata} */
    values: {
      type: Object,
      required: true,
    },
    /** @type {Masterdata} */
    savedValues: {
      type: Object,
      required: true,
    },
    isSubmitting: Boolean,
    isExporting: Boolean,
    isReadonly: Boolean,
    hasChanges: Boolean,
    isComplete: Boolean,
    canExport: Boolean,
    onBrandChange: {
      type: Function,
    },
  },

  computed: {
    valueAttrs() {
      return this.values.sizeCharts.map((val) =>
        this.sizeAttrsCacheMap.get(val)
      );
    },
    isACUChangesEnabled() {
      return canEnableACUChanges();
    },
    modifiedOwnerBrandAttributes() {
      const { attributes, values } = this;
      return values.brands.length
        ? attributes.ownerBrands.filter((ob) => values.brands.includes(ob.code))
        : attributes.ownerBrands;
    },
  },

  methods: {
    brandText(item) {
      return `${item.code} / ${item.name}`;
    },
    ownerBrandText(item) {
      return `${item.code} / ${item.name}`;
    },
  },
});
</script>

<style lang="scss"></style>
