<template lang="pug">
  span(:class="statusCssClass").dc-status.mcp-admin-contract-status
    span.mcp-admin-contract-status__label {{label}}
</template>

<script lang="ts">
import Vue from 'vue';
import { MCP_COMPLIANCE_STATUS_LABELS } from 'Common/constants/compliance-statuses.constant';
import { ApprovalStatusT } from 'Api/models/ApprovalStatus';

export default Vue.extend({
  props: {
    status: {
      type: String as () => ApprovalStatusT,
      required: false,
      default: '',
    },
  },

  computed: {
    statusCssClass(): string {
      return {
        [ApprovalStatusT.PENDING]: 'dc-status--inactive',
        [ApprovalStatusT.IN_REVIEW]: 'dc-status--new',
        [ApprovalStatusT.APPROVED]: 'dc-status--active',
        [ApprovalStatusT.REJECTED]: 'dc-status--error',
      }[this.status];
    },
    label(): string {
      return MCP_COMPLIANCE_STATUS_LABELS[this.status] || 'Unknown';
    },
  },
});
</script>

<style lang="scss" scoped></style>
