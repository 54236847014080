import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VContainer,
    { attrs: { fluid: "" } },
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "text-center mt-2" },
            [
              _c(VProgressCircular, {
                attrs: { size: 50, color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _vm.isInitFailed
        ? _c(VAlert, { attrs: { value: true, type: "error" } }, [
            _vm._v("Right of Withdrawal initialisation failed"),
          ])
        : _vm.isAddressNotDefined
        ? _c(VAlert, { attrs: { value: true, type: "warning" } }, [
            _vm._v(
              "Return Address needs to be defined in order for Rights of Withdrawal to be generated."
            ),
          ])
        : _vm.isInitialised
        ? [
            _vm.locales.length > 1
              ? _c(
                  "div",
                  [
                    _c(
                      VTabs,
                      {
                        attrs: { "slider-color": "primary" },
                        model: {
                          value: _vm.activeLocale,
                          callback: function ($$v) {
                            _vm.activeLocale = $$v
                          },
                          expression: "activeLocale",
                        },
                      },
                      _vm._l(_vm.locales, function (locale) {
                        return _c(VTab, { key: locale }, [
                          _vm._v(_vm._s(locale)),
                        ])
                      }),
                      1
                    ),
                    _c(
                      VTabsItems,
                      {
                        model: {
                          value: _vm.activeLocale,
                          callback: function ($$v) {
                            _vm.activeLocale = $$v
                          },
                          expression: "activeLocale",
                        },
                      },
                      _vm._l(_vm.locales, function (locale) {
                        return _c(
                          VTabItem,
                          { key: locale, staticClass: "grey lighten-5" },
                          [
                            _c("ContractRowItemContainer", {
                              staticClass: "py-3 px-3",
                              attrs: {
                                locale: locale,
                                merchantId: _vm.merchantId,
                                salesChannelId: _vm.salesChannelId,
                                useDraft: _vm.useDraft,
                                fulfillmentType: _vm.fulfillmentType,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _c("ContractRowItemContainer", {
                  attrs: {
                    locale: _vm.locales[0],
                    merchantId: _vm.merchantId,
                    salesChannelId: _vm.salesChannelId,
                    useDraft: _vm.useDraft,
                    fulfillmentType: _vm.fulfillmentType,
                  },
                }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }