import * as angularResourceModule from 'angular-resource';
import util from '../../core/util/util';
import httpTransformers from './httpTransformers';
import mcbJsonapiResourceBehavior from './jsonapiResourceBehavior';
import mcbJsonapiResourceCollectionFactory from './jsonapiResourceCollection';
import mcbResource from './resource';
import resourceMergeTransformers from './resourceMergeTransformers';

/**
 * @ngdoc module
 * @name mcb.components.http
 */
export default angular
  .module('mcb.components.http', [angularResourceModule, util.name])
  .factory('mcbHttpTransformers', httpTransformers)
  .factory('mcbJsonapiResourceBehavior', mcbJsonapiResourceBehavior)
  .factory('McbJsonapiResourceCollection', mcbJsonapiResourceCollectionFactory)
  .factory('mcbResource', mcbResource)
  .factory('$$mcbResourceMergeTransformers', resourceMergeTransformers);
