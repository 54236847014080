import { mcb } from 'mcb';
import { getToken } from '@/authentication/token.helper';
import './ng-bundle';
import 'dress-code/dist/css/dress-code.css';
import './admin.scss';
import './material-icons.scss';
import Vue from 'vue';
import VueRx from 'vue-rx';
import 'Common/filters/vue-filters';
import configModule from './mc-config/config.module';
import loadingModule from './loading/loading.module';
import errorModule from './error/error.module';
import authModule from './authentication/authentication.module';
import navbarModule from './navbar/navbar.module';
import registerModule from './register/register.module';
import merchantsModule from './merchants/merchants.module';
import merchantAccountsModule from './merchant-accounts/merchant-accounts.module';
import merchantAccountDetailsModule from './merchant-account-details/merchant-account-details.module';
import legalEntityDetailsModule from './legal-entity-details/legal-entity-details.module';
import merchantAccountEditModule from './merchant-account-edit/merchant-account-edit.module';
import merchantModule from 'Merchant/merchant.module';
import monitoringModule from './monitoring/admin.monitoring.module';
import rootModule from './root/root.module';
import { MCP_ADMIN_ROLES } from 'Common/constants/roles.constant';
import '@/store/vue-store';
import VueRouter from 'vue-router';

Vue.use(VueRx);
Vue.use(VueRouter);

angular.module('mc', [configModule.name, loadingModule.name]);

export default angular
  .module('mcp.admin', [
    'mcb',

    // mc center modules
    'mc',
    monitoringModule.initialize().name,
    errorModule.name,
    authModule.name,
    navbarModule.name,
    registerModule.name,
    merchantsModule.name,
    merchantAccountsModule.name,
    merchantAccountEditModule.name,
    merchantAccountDetailsModule.name,
    legalEntityDetailsModule.name,
    merchantModule.name,
    rootModule.name,
  ])

  .config([
    '$logProvider',
    'mcbViewTitleProvider',
    '$provide',
    '$analyticsProvider',
    (
      $logProvider,
      mcbViewTitleProvider,
      $provide,
      $analyticsProvider
    ): void => {
      // Enable log
      $logProvider.debugEnabled(true);

      mcbViewTitleProvider.options({ title: 'MC Center App - Loading...' });

      $analyticsProvider.trackExceptions(true);

      // FIXME - TODO CHECK IF THIS RESPONSE WAS FIXED....
      $provide.decorator('mcbHttpServerErrorInterceptor', [
        '$q',
        '$delegate',
        ($q, $delegate): any => {
          const responseErrorFn = $delegate.responseError;

          $delegate.responseError = function (rejection): any {
            if (rejection.status === 304) {
              return $q.reject(rejection);
            }

            return responseErrorFn(rejection);
          };
          return $delegate;
        },
      ]);
    },
  ])

  .config([
    '$stateProvider',
    'mcbMenuProvider',
    'mcbDashboardProvider',
    '$urlRouterProvider',
    (
      $stateProvider: ng.ui.IStateProvider,
      mcbMenuProvider: any,
      mcbDashboardProvider: any,
      $urlRouterProvider
    ): void => {
      let adminAbstractState: mcb.firewall.IMcbState = {
        abstract: true,
        template: '<mcp-admin-root></mcp-admin-root>',
        firewalls: {
          iam: {
            roles: [
              MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
              MCP_ADMIN_ROLES.STOCK_MANAGER,
              MCP_ADMIN_ROLES.FINANCIAL_APPROVER,
              MCP_ADMIN_ROLES.SUPER_ADMIN,
              MCP_ADMIN_ROLES.READ_ONLY,
              MCP_ADMIN_ROLES.OPERATIONS_MANAGER,
            ],
          },
        },
      } as mcb.firewall.IMcbState;
      $stateProvider.state('mcp.admin', adminAbstractState);

      mcbMenuProvider
        .section('mcp.admin', {
          label: 'Manage merchants profiles',
        })
        .entry('mcp.admin.merchants', {
          section: 'mcp.admin',
          label: 'Merchants list',
          state: 'mcp.admin.merchants',
          asModule: { id: 8 },
        });

      mcbDashboardProvider.addWidget({
        label: 'merchants',
        icon: 'merchants',
        state: 'mcp.admin.merchants',
      });

      $urlRouterProvider.otherwise(($injector) => {
        $injector.invoke([
          '$state',
          ($state): void => {
            $state.go('mcp.admin.merchants');
          },
        ]);
      });
    },
  ])
  .controller('AppCtrl', [
    function AppController(): void {
      /* empty controller */
    },
  ])

  //
  // $stateChangeError Handler
  //
  .run([
    '$rootScope',
    '$analytics',
    '$state',
    '$log',
    '$cookies',
    'mcbToast',
    'McAuthentication',
    'McIamAuthorizationError',
    'McIamAuthenticationError',
    (
      $rootScope,
      $analytics,
      $state,
      $log,
      $cookies,
      mcbToast,
      McAuthentication,
      McIamAuthorizationError,
      McIamAuthenticationError
    ): void => {
      $rootScope.$on(
        '$stateChangeError',
        (event, toState, toParams, fromState, fromParams, error) => {
          if ($analytics.settings.trackExceptions) {
            $analytics.exceptionTrack(error);
          }

          $log.error(
            event.name,
            toState,
            toParams,
            fromParams,
            fromState,
            fromParams,
            error
          );

          if (error instanceof McIamAuthenticationError) {
            // take user to error page
            McAuthentication.logout().finally(function () {
              $cookies.put('returnUrl', window.location.href);
              window.location.href = '/auth/zalando/login';
            });
            return;
          }

          if (error instanceof McIamAuthorizationError) {
            // User is not authorized for the specific state,
            // check if there is a fallback state and if the user has role to access the fallback
            if (
              toState.firewalls.fallback &&
              toState.firewalls.fallback.roles.indexOf(
                getToken().getCurrentRole()
              ) > -1
            ) {
              $state.go(toState.firewalls.fallback.state, toParams);
              return;
            }
            // take user to error page
            $state.go('mc-public.error', {
              errorType: error.getErrorStateType(),
            });
            return;
          }

          if (error && error.message) {
            mcbToast.error(error.message);
          } else {
            mcbToast.error('Oops.. an error occurred on state change.');
          }

          // if a stateChangeError happen on root state (on loading...)
          // broadcast 'mcStateFirstLoadError' event
          if ($state.current.name === '') {
            $rootScope.$broadcast(
              'mcStateFirstLoadError',
              toState,
              toParams,
              fromState,
              fromParams,
              error
            );
          }
        }
      );
    },
  ])
  .run([
    '$state',
    '$rootScope',
    '$log',
    '$cookies',
    ($state, $rootScope, $log, $cookies): void => {
      //
      // mcbHttpServerError Handler
      //
      $rootScope.$on('mcbHttpServerError', (event, error) => {
        $log.error(event.name, error);
      });

      /*
       * $viewContentLoading Handler
       * Checks if it is required to navigate to a different URL
       * if a deep link url was entered when not authenticated, the deep link url is stored in cookie till the authentication is done
       */
      $rootScope.$on('$viewContentLoading', (event) => {
        if ($state.current.name === '') {
          const returnUrl = $cookies.get('returnUrl');

          if (returnUrl) {
            event.preventDefault();
            $cookies.remove('returnUrl');
            window.location.href = returnUrl;
          }
        }
      });
    },
  ]);
