import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import { path, pipe } from 'ramda';
import ErrorHandler, { nullIf404Handler } from 'Api/api-errors-handler';
import axios from 'axios';
import { ComplianceStatusResponseT } from 'Api/models/ComplianceStatus';
import { SalesChannelComplianceRequirementT } from 'Api/models/ComplianceRequirement';

const merchantApi = axios.create({
  baseURL: '/api/merchant-profile/merchants/',
});

merchantApi.interceptors.request.use(decamelizeRequestHandler);
merchantApi.interceptors.response.use(
  pipe(camelizeResponseHandler, path(['data'])),
  ErrorHandler
);

export function fetchSalesChannelComplianceDetailsRequirements(
  merchantId: string,
  salesChannelId: string
): Promise<SalesChannelComplianceRequirementT[]> {
  return merchantApi
    .get(
      `${merchantId}/sales-channels/${salesChannelId}/compliance-details/requirements`
    )
    .then((list) => {
      // @ts-ignore
      return list.items
        .filter((item) => {
          return item.required;
        })
        .map((item) => {
          return item.section;
        });
    })
    .catch((e) => {
      if (e.response && e.response.status === 404) {
        return [];
      } else {
        return Promise.reject(e);
      }
    });
}

export function fetchSalesChannelComplianceStatus(
  merchantId: string,
  salesChannelId: string
): Promise<ComplianceStatusResponseT> {
  return merchantApi
    .get(
      `${merchantId}/sales-channels/${salesChannelId}/compliance-details/status`
    )
    .catch(nullIf404Handler);
}
