import { getToken } from '@/authentication/token.helper';
import { MCP_ADMIN_ROLES } from '../constants/roles.constant';

const {
  SUPER_ADMIN,
  INTEGRATION_KEY_ACCOUNT_MANAGER,
  OPERATIONS_MANAGER,
  FINANCIAL_APPROVER,
  READ_ONLY,
  STOCK_MANAGER,
} = MCP_ADMIN_ROLES;

export default {
  hasEditPermissions,
  hasEditMDPermissions,
  hasFinancialApprovalPermissions,
  hasViewPermission,
  isReadOnly,
};

function checkUserCurrentRole(roles: string[]): boolean {
  try {
    const userCurrentRole: string = getToken().getCurrentRole();
    return roles.indexOf(userCurrentRole) > -1;
  } catch (e) {
    return false;
  }
}

export function hasEditPermissions(): boolean {
  return checkUserCurrentRole([
    STOCK_MANAGER,
    INTEGRATION_KEY_ACCOUNT_MANAGER,
    SUPER_ADMIN,
  ]);
}

/**
 * Can the user edit Masterdata
 */
export function hasEditMDPermissions(): boolean {
  return checkUserCurrentRole([
    STOCK_MANAGER,
    INTEGRATION_KEY_ACCOUNT_MANAGER,
    OPERATIONS_MANAGER,
    SUPER_ADMIN,
  ]);
}

export function hasViewPermission(): boolean {
  return checkUserCurrentRole([
    INTEGRATION_KEY_ACCOUNT_MANAGER,
    SUPER_ADMIN,
    STOCK_MANAGER,
    FINANCIAL_APPROVER,
    READ_ONLY,
    OPERATIONS_MANAGER,
  ]);
}

export function hasFinancialApprovalPermissions(): boolean {
  return checkUserCurrentRole([FINANCIAL_APPROVER]);
}

export function isReadOnly(): boolean {
  return checkUserCurrentRole([READ_ONLY, OPERATIONS_MANAGER]);
}
