import { mcb } from 'mcb';
import wrapVue from '../common/wrap-vue.converter';
import coreModule from 'Common/core.module';
import { MCP_ADMIN_ROLES } from 'Common/constants/roles.constant';
import { HeaderService } from 'Common/services/header.service';
import LegalEntityDetailsContainerComponent from './legal-entity-details.container.vue';

export default angular
  .module('mcp.admin.legal-entity-details', [coreModule.name])

  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider): void => {
      $stateProvider.state('mcp.admin.legal-entity-details', {
        url: '/legal-entities/:legalEntityId',
        template: `<mcp-admin-legal-entity-details-wrapper
          legal-entity-id="ctrl.mcpHeaderService.getCurrentLegalEntityId()"
        ></mcp-admin-legal-entity-details-wrapper>`,
        controller: [
          'mcpHeaderService',
          function (mcpHeaderService: HeaderService) {
            this.mcpHeaderService = mcpHeaderService;
          },
        ],
        controllerAs: 'ctrl',
        firewalls: {
          iam: {
            roles: [
              MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
              MCP_ADMIN_ROLES.STOCK_MANAGER,
              MCP_ADMIN_ROLES.SUPER_ADMIN,
            ],
          },
        },
      } as mcb.firewall.IMcbState);
    },
  ])
  .component(
    'mcpAdminLegalEntityDetailsWrapper',
    wrapVue(
      LegalEntityDetailsContainerComponent,
      'mcpAdminLegalEntityDetailsWrapper'
    )
  );
