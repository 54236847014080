import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "silhouette-field-wrapper flex-grow-1" },
    [
      _c(VTextField, {
        ref: "textfield",
        attrs: {
          hint: "Select silhouettes which the partner is allowed to sell",
          "persistent-hint": "",
          filled: "",
          label: _vm.label,
          disabled: _vm.readonly,
          "append-icon": _vm.inputIcon,
        },
        on: { focus: _vm.onFocus, blur: _vm.onBlur },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _vm.el
        ? _c(
            VMenu,
            {
              attrs: {
                "max-height": "350",
                "disable-keys": "",
                "nudge-top": -58,
                "open-on-click": false,
                "close-on-content-click": false,
                attach: _vm.el,
              },
              on: { "update:returnValue": _vm.onMenuClose },
              model: {
                value: _vm.menuOpened,
                callback: function ($$v) {
                  _vm.menuOpened = $$v
                },
                expression: "menuOpened",
              },
            },
            [
              _c(
                VSheet,
                [
                  _c(VTreeview, {
                    attrs: {
                      light: "",
                      selectable: "",
                      "selected-color": "primary",
                      hoverable: "",
                      transition: "",
                      "open-on-click": "",
                      items: _vm.attributesTree,
                      search: _vm.search,
                      value: _vm.localValue,
                    },
                    on: { input: _vm.onInput },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function ({ item, selected }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "tree-item-label-wrapper",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onItemClick(item, selected)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "tree-item-label v-label theme--light",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      665370344
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.valuesAdded.length
        ? _c("div", { staticClass: "green--text" }, [
            _c("span", { staticClass: "font-weight-bold mr-1" }, [
              _vm._v("Added (" + _vm._s(_vm.valuesAdded.length) + "):"),
            ]),
            _c("span", { staticClass: "font-weight-medium" }, [
              _vm._v(_vm._s(_vm.valuesAdded.join(", "))),
            ]),
          ])
        : _vm._e(),
      _vm.valuesRemoved.length
        ? _c("div", { staticClass: "red--text" }, [
            _c("span", { staticClass: "font-weight-bold mr-1" }, [
              _vm._v("Removed (" + _vm._s(_vm.valuesRemoved.length) + "):"),
            ]),
            _c("span", { staticClass: "font-weight-medium" }, [
              _vm._v(_vm._s(_vm.valuesRemoved.join(", "))),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }