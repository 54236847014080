import {
  ProviderFormField,
  ContextParams,
  ConfigFormFieldParams,
} from 'Common/constants/configuration.constant';
import { CHANNELS, LOCALES } from 'Common/constants/channels.constant';
import { Configuration } from 'Api/models/Configuration';

interface NetevenConfigFormParams {
  providerMerchantId: string;
  ftpUsername: string;
  ftpPassword: string;
  ftpExternalSubChannel?: string;
}

interface NetevenUrlParams extends ContextParams, NetevenConfigFormParams {}

export function generateNetevenConfig(
  config: Configuration, // current config
  params: NetevenUrlParams
): Configuration {
  const {
    providerMerchantId,
    ftpUsername,
    ftpPassword,
    ftpExternalSubChannel,
    merchantName,
  } = params;

  return {
    ...config,
    ftpConfig: {
      ...config.ftpConfig,
      ftpUsername,
      ftpPassword,
      ftpUrl: 'ftp://ftp.neteven.com',
      ftpPath: `/${providerMerchantId}/`,
      ftpExternalPartnerName: merchantName,
      ftpExternalChannel: merchantName,
      ftpExternalSubChannel,
    },
    username: providerMerchantId,
  };
}

export function getNetevenFormFields({
  countryCode,
  isZfs,
  locale,
}: ConfigFormFieldParams): ProviderFormField[] {
  return [
    {
      label: 'Neteven Merchant ID',
      name: 'providerMerchantId',
      required: true,
    },
    {
      label: 'Ftp username',
      name: 'ftpUsername',
      required: true,
      defaultValue: getFtpUsernameDefaulVal(countryCode, isZfs, locale),
    },
    { label: 'Ftp password', name: 'ftpPassword', required: true },
    {
      label: 'External sub channel',
      name: 'ftpExternalSubChannel',
      defaultValue: '0',
    },
  ];
}

function getFtpUsernameDefaulVal(
  countryCode: string,
  isZfs: boolean,
  locale: string
): string {
  if (isZfs) {
    return '';
  }

  const data = {
    [CHANNELS.AUSTRIA + LOCALES.AUSTRIA.DE_AT]: 'zalando_at',
    [CHANNELS.BELGIUM + LOCALES.BELGIUM.FR_BE]: 'zalando_be_fr',
    [CHANNELS.BELGIUM + LOCALES.BELGIUM.NL_BE]: 'zalando_be_nl',
    [CHANNELS.SWITZERLAND + LOCALES.SWITZERLAND.FR_CH]: 'zalando_ch_fr',
    [CHANNELS.SWITZERLAND + LOCALES.SWITZERLAND.DE_CH]: 'zalando_ch_de',
    [CHANNELS.GERMANY + LOCALES.GERMANY.DE_DE]: 'zalando_de',
    [CHANNELS.SPAIN + LOCALES.SPAIN.ES_ES]: 'zalando_es',
    [CHANNELS.FRANCE + LOCALES.FRANCE.FR_FR]: 'zalando',
    [CHANNELS.ITALY + LOCALES.ITALY.IT_IT]: 'zalando_it',
    [CHANNELS.NETHERLANDS + LOCALES.NETHERLANDS.NL_NL]: 'zalando_nl',
    [CHANNELS.POLAND + LOCALES.POLAND.PL_PL]: 'zalando_pl',
  };

  return data[countryCode + locale] || '';
}
