var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.isSmall
      ? _c("div", { staticClass: "title text-body-1" }, [
          _vm._v(_vm._s(_vm.title)),
        ])
      : _c("div", { staticClass: "title text-h6 font-weight-bold" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }