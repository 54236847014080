import { ContractStatusT } from 'Api/models/Contract';
import { ApprovalStatusT } from 'Api/models/ApprovalStatus';

export const MCP_CONTRACT_STATUSES: Record<string, ContractStatusT> = {
  IN_PROGRESS: 'IN_PROGRESS',
  IN_FINANCIAL_REVIEW: 'IN_FINANCIAL_REVIEW',
  FINANCIAL_APPROVED: 'FINANCIAL_APPROVED',
  FINANCIAL_REJECTED: 'FINANCIAL_REJECTED',
  AFFECTED: 'AFFECTED',
  DEACTIVATED: 'DEACTIVATED',
};

export const MCP_CONTRACT_STATUS_LABELS = {
  [MCP_CONTRACT_STATUSES.IN_PROGRESS]: 'In progress',
  [MCP_CONTRACT_STATUSES.IN_FINANCIAL_REVIEW]: 'In review',
  [MCP_CONTRACT_STATUSES.FINANCIAL_APPROVED]: 'Approved',
  [MCP_CONTRACT_STATUSES.FINANCIAL_REJECTED]: 'Rejected',
  [MCP_CONTRACT_STATUSES.AFFECTED]: 'In progress',
  [MCP_CONTRACT_STATUSES.DEACTIVATED]: 'Deactivated',
  [ApprovalStatusT.APPROVED]: 'Approved',
  [ApprovalStatusT.PENDING]: 'In progress',
  [ApprovalStatusT.REJECTED]: 'Rejected',
  [ApprovalStatusT.IN_REVIEW]: 'In review',
};

export const CONTRACT_REQ_LAUNCH_STATUSES = {
  PENDING: 'LAUNCH_PENDING',
  IN_PROGRESS: 'LAUNCH_IN_PROGRESS',
  DONE: 'LAUNCH_DONE',
  FAILED: 'LAUNCH_FAILED',
  LOADING: 'LAUNCH_LOADING',
};

export const CONTRACT_PARTNERS_LAUNCH_STATUSES: Record<
  string,
  'INACTIVE' | 'LAUNCH_PENDING' | 'LAUNCH_DONE'
> = {
  INACTIVE: 'INACTIVE',
  PENDING: 'LAUNCH_PENDING',
  DONE: 'LAUNCH_DONE',
};
