import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        VMenu,
        {
          attrs: {
            bottom: "",
            "nudge-bottom": "58",
            "max-height": "260",
            value: _vm.menuVisible,
            "close-on-click": false,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({}) {
                return [
                  _c(VTextField, {
                    directives: [
                      {
                        name: "stream",
                        rawName: "v-stream:input",
                        value: _vm.searchInput$,
                        expression: "searchInput$",
                        arg: "input",
                      },
                    ],
                    attrs: {
                      label: "Merchant Account Name",
                      hint: "Enter merchant account name for the business entity",
                      "persistent-hint": "",
                      filled: "",
                      loading: _vm.itemsLoading,
                      value: _vm.value,
                      disabled: _vm.disabled,
                      "error-messages": _vm.errorMessage,
                    },
                    on: {
                      input: _vm.onInput,
                      focus: function ($event) {
                        _vm.isFocused = true
                      },
                      blur: function ($event) {
                        _vm.isFocused = false
                      },
                    },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardText,
                { staticClass: "text--primary" },
                [
                  _c("div", { staticClass: "mb-4" }, [
                    _vm._v(
                      "Merchant accounts with similar names already exist:"
                    ),
                  ]),
                  _vm._l(_vm.items, function (item) {
                    return _c("div", { staticClass: "mt-2" }, [
                      _vm._v(_vm._s(item)),
                    ])
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.notify === "NOTIFY_SEARCH_FAILED"
        ? _c(
            VSnackbar,
            {
              attrs: { top: "", color: "error", timeout: 3000 },
              model: {
                value: _vm.notify,
                callback: function ($$v) {
                  _vm.notify = $$v
                },
                expression: "notify",
              },
            },
            [
              _vm._v(" Error while searching for merchant accounts"),
              _c(
                VBtn,
                {
                  attrs: { text: "", dark: "" },
                  on: {
                    click: function ($event) {
                      _vm.notify = false
                    },
                  },
                },
                [_vm._v("Close    ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }