import { Contract, NewContract } from '../../models/Contract';
import { Endpoint } from '../../services/endpoint';
import { RequestConfig } from '../../services/http.service';
import { ChangedData } from 'Api/models/DataSections';

export class ContractEndpoint extends Endpoint {
  public resourceType = 'Contract';

  saveContract(contract: Contract): ng.IPromise<void> {
    const url = this.endpointsService.getContractUrlTemplate();
    const contractToSend: Contract = this.prepareContractToSend(contract);
    const config: RequestConfig = {
      method: 'POST',
      url: url,
      data: contractToSend,
      resourceType: this.resourceType,
    };
    return this.http.request(config);
  }

  saveContracts(contracts: NewContract[]): ng.IPromise<void[]> {
    const { endpointsService, resourceType, http } = this;
    const url = endpointsService.getContractUrlTemplate() + '/batch';
    const config: RequestConfig = {
      method: 'POST',
      url: url,
      data: contracts.map((contract) => ({ $type: resourceType, ...contract })),
      resourceType,
    };
    return http.request(config);
  }

  updateContract(contractId: string, contract: Contract): ng.IPromise<void> {
    const url = this.endpointsService.getContractUrlTemplate(contractId);
    const contractToSend: Contract = this.prepareContractToSend(contract);
    const config: RequestConfig = {
      method: 'PATCH',
      url: url,
      data: contractToSend,
      resourceType: this.resourceType,
    };
    return this.http.request(config);
  }

  putContract(contract: Contract): ng.IPromise<void> {
    if (contract.$id) {
      return this.updateContract(contract.$id, contract);
    } else {
      return this.saveContract(contract);
    }
  }

  putContracts(contracts: Contract[]): ng.IPromise<void[]> {
    return this.http.all(
      contracts.map((contract: Contract) => {
        return this.putContract(contract);
      })
    );
  }

  deleteContract(contractId: string): ng.IPromise<void> {
    const url = this.endpointsService.getContractUrlTemplate(contractId);
    const config: RequestConfig = { method: 'DELETE', url: url };
    return this.http.request(config);
  }

  deleteContracts(contractIds: string[]): ng.IPromise<void> {
    return this.http.all(
      contractIds.map((contractId) => this.deleteContract(contractId))
    );
  }

  fetchContract(contractId: string): ng.IPromise<Contract> {
    const config: RequestConfig = {
      method: 'GET',
      url: this.endpointsService.getContractUrlTemplate(contractId),
      include: ['sales-channel', 'retailer', 'partners'],
    };

    return this.http.request(config).then((contract) => {
      return contract;
    });
  }

  fetchContractDataChanges(contractId: string): ng.IPromise<ChangedData> {
    const url = this.endpointsService.getContractDataChangesUrl(contractId);
    const config: RequestConfig = {
      method: 'GET',
      url: url,
    };
    return this.http.requestJson(config);
  }

  private prepareContractToSend(contractToPrepare: Contract): Contract {
    let contract = angular.copy(contractToPrepare);
    delete contract.address;
    return contract;
  }
}
