var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("h1", { staticClass: "dc-h1 mcp-admin__summary-header__title" }, [
      _vm._v(_vm._s(_vm.merchantName)),
    ]),
    _c("div", { staticClass: "mcp-admin__summary-header__summary" }, [
      _vm.serviceLevel
        ? _c(
            "span",
            [
              _c(
                "span",
                {
                  staticClass:
                    "mcp-admin__summary-header__value service-level-value",
                },
                [_vm._v(_vm._s(_vm.serviceLevel))]
              ),
              _c("mp-info-tooltip", {
                attrs: { infoText: "Merchant service level" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.serviceLevel && _vm.merchantType
        ? _c(
            "span",
            { staticClass: "mcp-admin__summary-header__value-separator" },
            [_vm._v(" / ")]
          )
        : _vm._e(),
      _vm.merchantType
        ? _c(
            "span",
            [
              _c(
                "span",
                {
                  staticClass:
                    "mcp-admin__summary-header__value merchant-type-value",
                },
                [_vm._v(_vm._s(_vm.merchantType))]
              ),
              _c("mp-info-tooltip", { attrs: { infoText: "Merchant type" } }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }