const ibanMinLength = 14;
const ibanMaxLength = 29;

export function stringToIbanFormat(value: string): string {
  const iban = value.toUpperCase().replace(/\s/g, '');
  let result = [];
  for (let i = 0; i < iban.length; i++) {
    if (i > 0 && i % 4 === 0) {
      result.push(' ');
      result.push(iban[i]);
    } else {
      result.push(iban[i]);
    }
  }
  return result.join('');
}

export function isFormatCorrect(iban: string): boolean {
  return /^[A-Z]{2}([0-9a-zA-Z]+)$/.test(iban);
}

export function isMinLengthCorrect(iban: string): boolean {
  return iban.length > ibanMinLength;
}

export function isMaxLengthCorrect(iban: string): boolean {
  return iban.length < ibanMaxLength;
}

export function getCountryCode(iban: string): string {
  return iban.slice(0, 2);
}
