import { Configuration } from 'Api/models/Configuration';
import { ConfigPartnerProvider } from 'Api/models/ConfigPartnerProvider';
import { ConfigProviderFlagsT } from 'Api/models/ConfigPartnerProviderDefaults';
import { Endpoint } from 'Api/services/endpoint';
import { RequestConfig } from 'Api/services/http.service';

let cachedPartnerProviders: Map<number, ConfigPartnerProvider>;

export class ConfigurationEndpoint extends Endpoint {
  public resourceType = 'Configuration';

  updateConfiguration(
    configuration: Configuration
  ): ng.IPromise<Configuration> {
    if (
      configuration.ftpConfig &&
      configuration.ftpConfig.ftpExternalSubChannel === ''
    ) {
      configuration.ftpConfig.ftpExternalSubChannel = null;
    }

    const config: RequestConfig = {
      url: this.endpointsService.getConfigurationsUrlTemplate(
        configuration.$id
      ),
      method: 'PATCH',
      data: configuration,
      resourceType: this.resourceType,
    };
    return this.http.request(config);
  }

  fetchConfiguration(partnerId: string): ng.IPromise<Configuration> {
    const config: RequestConfig = {
      url: this.endpointsService.getConfigurationsUrlTemplate(partnerId),
      method: 'GET',
    };
    return this.http.request(config);
  }

  fetchConfiurationProviderDefaults(
    providerId: number
  ): ng.IPromise<ConfigProviderFlagsT> {
    const config: RequestConfig = {
      url: this.endpointsService.getConfigPartnerProviderDefaultsUrlTemplate(
        String(providerId)
      ),
      method: 'GET',
    };
    return this.http.requestJson(config);
  }

  fetchPartnerProviders(): ng.IPromise<Map<number, ConfigPartnerProvider>> {
    if (cachedPartnerProviders) {
      return Promise.resolve(cachedPartnerProviders) as ng.IPromise<
        Map<number, ConfigPartnerProvider>
      >;
    }

    const config: RequestConfig = {
      url: this.endpointsService.getConfigPartnerProvidersUrlTemplate(),
      method: 'GET',
    };

    return this.http
      .requestJson(config)
      .then(
        (data) =>
          (cachedPartnerProviders = new Map(
            data.items.map((item: ConfigPartnerProvider) => [
              item.partnerProviderId,
              item,
            ])
          ) as Map<number, ConfigPartnerProvider>)
      );
  }
}
