import { MerchantRequirements } from 'Api/models/MerchantRequiremenets';
import {
  MerchantState,
  merchantInitialState,
} from './reducers/merchant.reducer';
import {
  MerchantGroupsState,
  merchantGroupsInitialState,
} from './reducers/merchant-groups.reducer';
import {
  ContractState,
  contractInitialState,
} from './reducers/contract.reducer';
import {
  ContractRequirementsState,
  contractRequirementsInitialState,
} from './reducers/contract-status.reducer';
import {
  contractsInitialState,
  ContractsState,
} from './reducers/contracts.reducer';
import {
  FactoringMigrationStatusState,
  initialFMSState,
} from './reducers/factoring-migration-status.reducer';

export interface FlowState {
  flow: {
    general: {
      requirements: MerchantRequirements;
      merchant: MerchantState;
      contracts: ContractsState;
      merchantGroups: MerchantGroupsState;
    };
    specific: {
      requirements: ContractRequirementsState;
      contract: ContractState;
      factoringMigrationStatus: FactoringMigrationStatusState;
    };
  };
}

// @ts-ignore
export const initialFlowState: FlowState = {
  flow: {
    general: {
      requirements: null,
      merchant: merchantInitialState,
      contracts: contractsInitialState,
      merchantGroups: merchantGroupsInitialState,
    },
    specific: {
      contract: contractInitialState,
      requirements: contractRequirementsInitialState,
      factoringMigrationStatus: initialFMSState,
    },
  },
};
