import template from './contract-status-label.html';
import {
  MCP_CONTRACT_STATUSES,
  MCP_CONTRACT_STATUS_LABELS,
} from '../../constants/contract-statuses.constant';

export class ContractStatusLabelComponent implements ng.IComponentOptions {
  static Factory() {
    return new ContractStatusLabelComponent();
  }

  bindings: { [binding: string]: string } = {
    status: '@',
  };
  controller = ContractStatusController;
  template: string = template;
}

class ContractStatusController {
  status: string;
  statusCssClass = '';
  statusLabel = '';

  private cssClassesMap = {
    [MCP_CONTRACT_STATUSES.IN_PROGRESS]: 'dc-status--inactive',
    [MCP_CONTRACT_STATUSES.AFFECTED]: 'dc-status--inactive',
    [MCP_CONTRACT_STATUSES.IN_FINANCIAL_REVIEW]: 'dc-status--new',
    [MCP_CONTRACT_STATUSES.FINANCIAL_APPROVED]: 'dc-status--active',
    [MCP_CONTRACT_STATUSES.FINANCIAL_REJECTED]: 'dc-status--error',
    [MCP_CONTRACT_STATUSES.DEACTIVATED]: 'dc-status--error',
  };

  $onChanges(changes: { status: ng.IChangesObject<string> }) {
    if (changes.status.currentValue !== undefined) {
      this.statusLabel = MCP_CONTRACT_STATUS_LABELS[this.status] || 'Unknown';
      this.statusCssClass =
        this.cssClassesMap[this.status] || 'dc-status--error';
    }
  }
}
