export default angular
  .module('mcb.components.merchant', [])
  .factory('mcbMerchant', function mcbMerchant() {
    return {
      current: function () {
        return {
          id: 3451,
        };
      },
      currentId: function () {
        return this.current().id;
      },
    };
  });
