import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VMenu,
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                VBtn,
                _vm._g(
                  {
                    attrs: { icon: "", "data-testid": "userProfileMenuButton" },
                  },
                  on
                ),
                [_c("UserProfileIcon")],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        VCard,
        { attrs: { "data-testid": "userProfileMenu" } },
        [
          _c(VCardText, [
            _c("div", { staticClass: "secondary--text" }, [
              _vm._v(_vm._s(_vm.username)),
            ]),
          ]),
          _c(
            VBtn,
            {
              attrs: {
                text: "",
                tile: "",
                block: "",
                large: "",
                "data-testid": "logoutBtn",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("logout")
                },
              },
            },
            [
              _c(VIcon, { attrs: { left: "" } }, [_vm._v("logout")]),
              _vm._v("Logout"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }