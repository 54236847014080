import { RejectionScopes } from '@/api/models/Contract';
import { MCP_MERCHANT_STATUSES as STATUSES } from 'Common/constants/merchant-statuses.constant';
import { MerchantRequirements } from '../../models/MerchantRequiremenets';
import { Endpoint } from '../../services/endpoint';
import { RequestConfig } from '../../services/http.service';
export const RESOURCE_TYPE = 'MerchantStatus';

export class MerchantStatusEndpoint extends Endpoint {
  fetchMerchantRequirements(
    merchantId: string
  ): ng.IPromise<MerchantRequirements> {
    const url =
      this.endpointsService.getMerchantRequirementsUrlTemplate(merchantId);
    const config: RequestConfig = { method: 'GET', url: url };
    return this.http.request(config);
  }

  markAsInProgress(merchantId: string): ng.IPromise<void> {
    return this.changeMerchantStatus(merchantId, STATUSES.IN_PROGRESS);
  }

  markAsInFinancialReview(merchantId: string): ng.IPromise<void> {
    return this.changeMerchantStatus(merchantId, STATUSES.IN_FINANCIAL_REVIEW);
  }

  markAsFinancialApproved(merchantId: string): ng.IPromise<void> {
    return this.changeMerchantStatus(merchantId, STATUSES.FINANCIAL_APPROVED);
  }

  markAsFinancialRejected(
    merchantId: string,
    reason: string,
    scope?: RejectionScopes,
    sections?: string[]
  ): ng.IPromise<void> {
    return this.changeMerchantStatus(
      merchantId,
      STATUSES.FINANCIAL_REJECTED,
      reason,
      scope,
      sections
    );
  }

  markAsDeactivated(merchantId: string): ng.IPromise<void> {
    return this.changeMerchantStatus(merchantId, STATUSES.DEACTIVATED);
  }

  private changeMerchantStatus(
    merchantId: string,
    status: string,
    comment: string | undefined = undefined,
    scope?: RejectionScopes,
    sections?: string[]
  ): ng.IPromise<void> {
    const url =
      this.endpointsService.getMerchantStatusChangeUrlTemplate(merchantId);

    const config: RequestConfig = {
      method: 'POST',
      url,
      data: {
        status,
        approverComment: comment,
        rejection_scope: scope,
        rejected_sections: sections,
      },
      resourceType: RESOURCE_TYPE,
    };

    return this.http.request(config);
  }
}
