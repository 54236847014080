<template lang="pug">
v-app#market-container.root
  v-container
    div.ml-3
      img(v-if="imagesPath" :src="imagesPath" width="30" height="17")
      span.text-h6.ml-2 {{ countryName }}

    v-tabs.ml-n7(align-with-title background-color="transparent")
      v-tabs-slider(color="primary")
      v-tab Overview

    SalesChannelOverview(
      v-if="channel && merchant"
      :merchant-id="merchant.$id"
      :merchant-name="merchant.name"
      :channel-id="channel.$id"
      :country-name="channel.countryName"
      :shipment-data="shipmentData"
      :is-shipment-data-loading="isShipmentDataLoading"
      :is-live="isLive"
      :is-put-live-btn-displayed="!isLive"
      :is-put-live-btn-enabled="canGoLive"
      :is-take-offline-btn-displayed="isLive"
      :is-both-contracts-online="isBothContractsOnline"
      :is-zfs-channel="isPureZFSChannel"
      :is-hybrid-channel="isPureHybridChannel"
      :is-zfs-channel-launched="isZFSChannelLaunched"
      :on-put-live-cb="onPutLiveCb"
      :on-take-offline-cb="onTakeOfflineCb"
      :on-shipment-change="updateShipment"
      :request-go-online-confirmation="launchedContracts.length > 1"
    )

  v-container
    div(v-if="isVatDataLoading")
      v-progress-circular(
        color="secondaryDark"
        indeterminate
      )
    div(v-else="!isVatDataLoading")
      div.pb-4(v-if="vatData !== null")
        VatReviewSection(
          title="Vat ID details"
          :vat-details="vatData"
          :status="vatData.approvalStatus"
          enable-review=false
        )

      NoDataSection(
        v-if="complianceRequirements !== null && complianceRequirements.length !== 0 && vatData === null"
        :title="titles.scVatDetails"
      )
</template>

<script lang="ts">
import Vue from 'vue';
import VatReviewSection from 'Merchant/common/components/vat-review-section/vat-review-section.vue';
import NoDataSection from 'Merchant/general/components/company-compliance-details/no-data-compliance-section.vue';
import {
  fetchContractRequirementsWithLaunchedStatus,
  putChannelOnline,
  putChannelOffline,
} from 'Api/endpoints/contract/contract-new.endpoint';
import { Contract } from 'Api/models/Contract';
import { CONTRACT_REQ_LAUNCH_STATUSES } from 'Common/constants/contract-statuses.constant';
import SalesChannelOverview from './channel-overview.vue';
import { MCP_CONTRACT_STATUSES } from 'Common/constants/contract-statuses.constant';
import { MCP_MERCHANT_STATUSES } from 'Common/constants/merchant-statuses.constant';
import {
  fetchShipmentServices,
  patchUpdateShipmentServices,
} from 'Api/endpoints/sales-channel/shipment-service.endpoint';
import { ShipmentServiceT } from 'Api/models/ShipmentService';
import { MCP_FF_TYPES } from 'Common/constants/misc.constant';
import { fetchCarriersRequirements } from 'Api/endpoints/sales-channel/carrier.endpoint';
import {
  fetchSalesChannelComplianceDetailsRequirements,
  fetchSalesChannelComplianceStatus,
} from '@/api/endpoints/sales-channel/compliance.endpoint';
import { fetchMerchantComplianceStatus } from '@/api/endpoints/merchant/merchant-compliance.endpoint';
import { canEnableBankDetails, canEnableVatCollection } from '@/feature-flags';
import { SalesChannelVatInfoT } from '@/api/models/SalesChannelVat';
import { fetchSalesChannelVatDetails } from '@/api/endpoints/legal-entity/compliance.endpoint';
import { SalesChannelComplianceRequirementT } from 'Api/models/ComplianceRequirement';
const FETCH_ERROR_MESSAGE = 'Failed to fetch compliance data for review';
export default Vue.extend({
  components: {
    SalesChannelOverview,
    VatReviewSection,
    NoDataSection,
  },
  props: {
    onGoOnlineCb: { type: Function, required: true },
    onGoOnlineErrorCb: { type: Function, required: true },
    onGoOfflineCb: { type: Function, required: true },
    onGoOfflineErrorCb: { type: Function, required: true },
  },

  data() {
    return {
      salesChannelId: null,
      contracts: [], // channel contracts
      contractsRequirements: [],
      stocksRequirements: {},
      complianceRequirements: [] as SalesChannelComplianceRequirementT[],
      merchantComplianceStatus: null,
      salesChannelComplianceStatus: null,
      channel: null,
      isLive: false,
      canGoLive: false,
      isBothContractsOnline: false,
      contractsToGoLive: [],
      launchedContracts: [],
      imagesPath: null,
      shipmentData: null,
      isShipmentDataLoading: false,
      titles: {
        scVatDetails: 'Vat ID details',
      },
      vatData: null as SalesChannelVatInfoT,
      isVatDataLoading: false,
      merchant: null,
      // @ts-ignore
      ...this.mapState({
        merchant: 'flow.general.merchant.data',
        merchantRequirements: 'flow.general.requirements',
        merchantContracts: 'flow.general.contracts.data',
        legalEntityId: 'flow.general.merchant.data.legalEntityId',
      }),
    };
  },
  computed: {
    countryName() {
      return this.channel ? this.channel.countryName : '';
    },
    isPureZFSChannel() {
      if (this.contracts.length > 1) return false;

      return (
        this.contracts[0] &&
        this.contracts[0].fulfillmentType === MCP_FF_TYPES.ZFS
      );
    },
    isPureHybridChannel() {
      if (this.contracts.length === 1) return false;
      else return true;
    },
    isZFSChannelLaunched() {
      return (
        (this.contracts as Contract[]).findIndex(
          ({ launchStatus, fulfillmentType }) =>
            launchStatus === CONTRACT_REQ_LAUNCH_STATUSES.DONE &&
            fulfillmentType === MCP_FF_TYPES.ZFS
        ) !== -1
      );
    },
  },
  mounted() {
    // We have to use setTimeout to wait for a URL to change,
    // Otherwise the old url is used by this.$route
    setTimeout(() => {
      this.salesChannelId = this.$route.params.salesChannelId;
      this.contracts = this.merchantContracts.filter(
        ({ salesChannelId }) => salesChannelId === this.salesChannelId
      );
      this.channel = this.contracts[0].salesChannel;
      this.isLive = this.contracts.some(({ live }) => live);
      this.isBothContractsOnline =
        this.contracts.length === 2 && this.contracts.every(({ live }) => live);

      this.imagesPath =
        this.$root.clientConfig.urls.mountURL +
        `/images/flags/flag--${this.channel.countryCode}.svg`;

      this.fetchShipment();

      if (canEnableVatCollection(this.merchant)) {
        this.fecthVatDataToView();
      }

      // Load Contracts requirements
      Promise.all(
        this.contracts.map(({ $id }) =>
          fetchContractRequirementsWithLaunchedStatus($id)
        )
      )
        .then(async (reqs) => {
          return {
            contractReqs: reqs,
            stocksReqs: await fetchCarriersRequirements(
              this.merchant.$id,
              this.salesChannelId
            ),
            merchantComplianceStatus: canEnableVatCollection(this.merchant)
              ? await fetchMerchantComplianceStatus(this.merchant.$id)
              : null,
            complianceStatusReq: canEnableVatCollection(this.merchant)
              ? await fetchSalesChannelComplianceStatus(
                  this.merchant.$id,
                  this.salesChannelId
                )
              : null,
          };
        })
        .then(
          ({
            contractReqs,
            stocksReqs,
            merchantComplianceStatus,
            complianceStatusReq,
          }) => {
            this.contractsRequirements = contractReqs;
            this.stocksRequirements = stocksReqs;
            this.merchantComplianceStatus = merchantComplianceStatus;
            this.salesChannelComplianceStatus = complianceStatusReq;
            this.contractsToGoLive = this.contracts.filter(
              (contract, contractIndex) =>
                canContractGoLive(
                  this.merchantRequirements,
                  this.contractsRequirements[contractIndex],
                  contract,
                  this.merchant,
                  this.stocksRequirements
                )
            );
            const isVatRequiredForSalesChannel =
              this.channel.countryCode !== 'ch';
            const isComplianceStatusApprovedForSC =
              this.salesChannelComplianceStatus?.status === 'APPROVED';
            const isComplianceStatusApprovedForMerchant =
              this.merchantComplianceStatus?.status === 'APPROVED';

            const complianceCheck =
              this.complianceRequirements.length && isVatRequiredForSalesChannel
                ? isComplianceStatusApprovedForSC &&
                  isComplianceStatusApprovedForMerchant
                : isComplianceStatusApprovedForMerchant;

            const shouldCheckComplianceStatus =
              canEnableVatCollection(this.merchant) &&
              !this.merchant.hasBeenOnboarded;

            const isComplianceRequirementCompleted = shouldCheckComplianceStatus
              ? complianceCheck
              : true;

            this.canGoLive =
              this.contractsToGoLive.length > 0 &&
              isComplianceRequirementCompleted;
            this.launchedContracts = (this.contracts as Contract[]).filter(
              ({ launchStatus }) =>
                launchStatus === CONTRACT_REQ_LAUNCH_STATUSES.DONE
            );
          }
        );
    });
  },

  methods: {
    async fecthVatDataToView(): Promise<void> {
      try {
        this.isVatDataLoading = true;
        fetchSalesChannelComplianceDetailsRequirements(
          this.merchant.$id,
          this.salesChannelId
        ).then((res) => {
          this.complianceRequirements = res;
          if (res.length !== 0) {
            fetchSalesChannelVatDetails(
              this.legalEntityId,
              this.salesChannelId
            ).then((res) => {
              this.vatData = res;
            });
          }
        });
      } catch (e) {
        this.showApiError(FETCH_ERROR_MESSAGE);
      } finally {
        this.isVatDataLoading = false;
      }
    },

    onPutLiveCb(): void {
      const contractToGoOnline = this.contracts.find(
        (contract, contractIndex) =>
          canContractGoLive(
            this.merchantRequirements,
            this.contractsRequirements[contractIndex],
            contract,
            this.merchant,
            this.stocksRequirements
          )
      );
      putChannelOnline(contractToGoOnline.$id)
        .then(() => this.onGoOnlineCb(this.channel.countryName))
        .catch(() => this.onGoOnlineErrorCb(this.channel.countryName));
    },
    onTakeOfflineCb(reason: string): void {
      const liveContract = this.contracts.find(({ live }) => !!live);
      putChannelOffline(liveContract.$id, reason)
        .then(() => this.onGoOfflineCb(this.channel.countryName))
        .catch(() => this.onGoOfflineErrorCb(this.channel.countryName));
    },
    async fetchShipment(): Promise<void> {
      this.isShipmentDataLoading = true;
      this.shipmentData = await fetchShipmentServices(
        this.merchant.$id,
        this.salesChannelId
      );
      this.isShipmentDataLoading = false;
    },
    async updateShipment(shipmentData: ShipmentServiceT): Promise<void> {
      this.shipmentData = {
        ...(await patchUpdateShipmentServices(
          this.merchant.$id,
          this.salesChannelId,
          shipmentData
        )),
      };
    },
  },
});

function canContractGoLive(
  merchantRequirements,
  contractsRequirements,
  contract,
  merchant,
  stocksRequirements
): boolean {
  const isBankDetailsChangesEnabled = canEnableBankDetails();
  // For the case of Partner Fulfillment contract we need to check for cepConfiguration
  // It's a temporary check before all launched Partner contracts are fixed

  return (
    // Merchant and contract requirements should be met
    (isBankDetailsChangesEnabled
      ? true
      : merchantRequirements.generalDataDefined) &&
    merchantRequirements.metadataDefined &&
    contractsRequirements.areAllPartnersLaunched &&
    // Merchant and contract have to be financially approved
    contract.status === MCP_CONTRACT_STATUSES.FINANCIAL_APPROVED &&
    (isBankDetailsChangesEnabled
      ? true
      : merchant.status === MCP_MERCHANT_STATUSES.FINANCIAL_APPROVED) &&
    // CEP should be configured when contract is PF, not needed for ZFS contracts
    (contract.fulfillmentType === 'ZALANDO' ||
      stocksRequirements?.carrierProductsSynced)
  );
}
</script>

<style lang="scss" scoped>
.root ::v-deep .v-application--wrap {
  min-height: inherit;
}
.mono {
  font-family: monospace;
}
</style>
