<template lang="pug">
div
  //- Loading Indicator
  div.text-center.mt-2(
    v-if="isLoading"
  )
    v-progress-circular(
      :size="50"
      color="primary"
      indeterminate
    )

  //- Initialisation Failed
  v-alert(
    v-else-if="isApiError"
    :value="true"
    type="error"
  ) Right of Withdrawal initialisation failed

  //- Main Content
  div(v-else-if="isInitialised")
    v-btn.ml-0.mb-6.mt-0(
      depressed
      color="primary"
      :loading="isPdfLoading"
      @click="$emit('downloadPdf')"
      ) Download PDF

    v-card
      div.px-4.pt-1.pb-2(v-html="html")
</template>

<script lang="ts">
import Vue from 'vue';
import {
  ROW_STATUS_LOADING,
  ROW_STATUS_API_ERROR,
  ROW_STATUS_OK,
} from '../row.constants';

export default Vue.extend({
  props: {
    locale: String,
    html: String,
    status: String,
    isPdfLoading: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    isApiError(): boolean {
      return this.status === ROW_STATUS_API_ERROR;
    },
    isLoading(): boolean {
      return this.status === ROW_STATUS_LOADING;
    },
    isInitialised(): boolean {
      return this.status === ROW_STATUS_OK;
    },
  },
  methods: {},
});
</script>

<style lang="scss" scoped></style>
