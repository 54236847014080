import axios from 'axios';
import { pipe, pick } from 'ramda';
import { MarketContactT } from 'Api/models/MerchantContact';
import ErrorHandler from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';

const contactsApi = axios.create({ baseURL: 'api/merchant-profile/merchants' });

contactsApi.interceptors.request.use(decamelizeRequestHandler);
contactsApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data.items),
  ErrorHandler
);

export function fetchContacts(
  businessPartnerId: string,
  salesChannelId: string
): Promise<MarketContactT[]> {
  return contactsApi.get(
    `${businessPartnerId}/sales-channels/${salesChannelId}/contacts`
  );
}

export function updateContacts(
  businessPartnerId: string,
  salesChannelId: string,
  contacts: MarketContactT[]
): Promise<MarketContactT[]> {
  return contactsApi.put(
    `${businessPartnerId}/sales-channels/${salesChannelId}/contacts`,
    {
      items: contacts.map(
        pick(['contactType', 'email', 'firstName', 'lastName', 'phone'])
      ),
    }
  );
}

export function useMerchantContacts(
  businessPartnerId: string,
  salesChannelId: string
): Promise<MarketContactT[]> {
  return contactsApi.post(
    `${businessPartnerId}/sales-channels/${salesChannelId}/contacts/use-merchant`
  );
}
