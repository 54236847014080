import { VatReviewListItemT } from 'Merchant/common/components/vat-review-section/vat-review-list-section.vue';
import { ZfsVatInfoT } from 'Api/models/ZfsVat';
import { OutboundWarehouseInfoT } from 'Api/models/OutboundWarehouse';
import { OssInfoT } from 'Api/models/Oss';
import { MerchantComplianceRequirementT } from 'Api/models/ComplianceRequirement';
import { VatDetailsT, VatGroupDetailsT } from 'Api/models/Vat';
import { FixedEstablishmentResponseT } from 'Api/models/FixedEstablishment';
import {
  ApprovalStatusT,
  SelectableReviewStatusT,
} from 'Api/models/ApprovalStatus';

export const REJECTION_REASON_MIN_WORD_COUNT = 5;

export const normaliseZfsVatData = (
  zfsVatData: ZfsVatInfoT[],
  selectedStatuses: { [key: string]: SelectableReviewStatusT }
): VatReviewListItemT[] => {
  if (!zfsVatData) return [];
  return zfsVatData.map((item, index) => {
    const id = String(item.key);
    return {
      id,
      title: `ID ${index + 1}`,
      vatDetails: {
        vatId: item.vatId,
        certificateId: item.certificateId,
        validationResult: item.validationResult,
        lastValidatedAt: item.lastValidatedAt,
      },
      approvalStatus: item.approvalStatus,
      rejectionReason: item.rejectionReason,
      selectedStatus: selectedStatuses ? selectedStatuses[id] : '',
    };
  }) as VatReviewListItemT[];
};

export const normaliseOutboundWarehouseData = (
  outboundWarehouseData: OutboundWarehouseInfoT[],
  selectedStatuses: { [key: string]: SelectableReviewStatusT }
): VatReviewListItemT[] => {
  if (!outboundWarehouseData) return [];
  return outboundWarehouseData.map((item) => {
    const id = item.warehouseId;
    let vatDetails = {
      vatId: item.vatId,
      certificateId: item.certificateId,
      validationResult: item.validationResult,
      lastValidatedAt: item.lastValidatedAt,
    };

    if (item.isUsingLrd) {
      vatDetails = null;
    }

    const lrdDetails = {
      isUsingLrd: item.isUsingLrd,
      lrdCertificateId: item.lrdCertificateId,
    };

    return {
      id,
      title: item.name,
      address: {
        addressLine1: item.addressLine1,
        addressLine2: item.addressLine2,
        city: item.city,
        countryCode: item.countryCode,
        zipCode: item.zipCode,
      },
      vatDetails,
      lrdDetails,
      approvalStatus: item.approvalStatus,
      rejectionReason: item.rejectionReason,
      selectedStatus: selectedStatuses ? selectedStatuses[id] : '',
    } as VatReviewListItemT;
  });
};

interface OssDataT {
  vat: VatDetailsT | null;
  vatGroup: VatGroupDetailsT | null;
  approvalStatus: ApprovalStatusT;
  hasOss: boolean;
}

export const normaliseOssData = (ossData: OssInfoT): OssDataT => {
  if (!ossData) return null;
  return {
    vat: ossData.hasOss
      ? {
          vatId: ossData.oss.vatId,
          certificateId: ossData.oss.vatCertificateId,
          lastValidatedAt: ossData.oss.lastValidatedAt,
          validationResult: ossData.oss.validationResult,
        }
      : null,
    vatGroup: ossData.oss
      ? {
          belongsToVatGroup: ossData.oss.belongsToVatGroup,
          name: ossData.oss.vatGroupName,
          certificateId: ossData.oss.vatGroupCertificateId,
        }
      : null,
    approvalStatus: ossData.approvalStatus,
    hasOss: ossData.hasOss,
  };
};

export interface SelectedStatusesT {
  euFixedEstablishment?: SelectableReviewStatusT;
  ukFixedEstablishment?: SelectableReviewStatusT;
  oss?: SelectableReviewStatusT;
  zfsVat?: { [key: string]: SelectableReviewStatusT };
  outboundWarehouse?: { [key: string]: SelectableReviewStatusT };
}

export interface ProvidedRejectionReasonT {
  euFixedEstablishment: string;
  ukFixedEstablishment: string;
  oss: string;
  zfsVat: { [key: string]: string };
  outboundWarehouse: { [key: string]: string };
}

export interface ComplianceDataListT {
  zfsVatData: ZfsVatInfoT[];
  outboundWarehouseData: OutboundWarehouseInfoT[];
  euFixedEstablishmentData: FixedEstablishmentResponseT;
  ukFixedEstablishmentData: FixedEstablishmentResponseT;
  ossData: OssInfoT;
}

interface IsReviewSubmitDisabledPropsT extends ComplianceDataListT {
  requirements: MerchantComplianceRequirementT[];
  selectedStatuses: SelectedStatusesT;
  providedRejectionReasons: ProvidedRejectionReasonT;
}

const hasValidRejectionReason = (rejectionReason?: string) => {
  return (
    rejectionReason?.trim().split(/\s+/).length >=
    REJECTION_REASON_MIN_WORD_COUNT
  );
};

export const isDataMissingForTask = (
  approvalStatus: ApprovalStatusT,
  selectedStatus: SelectableReviewStatusT,
  rejectionReason?: string
) => {
  return (
    approvalStatus === ApprovalStatusT.IN_REVIEW &&
    (!selectedStatus ||
      (selectedStatus === ApprovalStatusT.REJECTED &&
        !hasValidRejectionReason(rejectionReason)))
  );
};

export const isReviewSubmitDisabled = ({
  requirements,
  selectedStatuses,
  providedRejectionReasons,
  euFixedEstablishmentData,
  ossData,
  ukFixedEstablishmentData,
  zfsVatData,
  outboundWarehouseData,
}: IsReviewSubmitDisabledPropsT): boolean => {
  return requirements.some((requirement) => {
    switch (requirement) {
      case MerchantComplianceRequirementT.FIXED_ESTABLISHMENT_EU:
        return isDataMissingForTask(
          euFixedEstablishmentData.approvalStatus,
          selectedStatuses.euFixedEstablishment,
          providedRejectionReasons.euFixedEstablishment
        );
      case MerchantComplianceRequirementT.FIXED_ESTABLISHMENT_UK:
        return isDataMissingForTask(
          ukFixedEstablishmentData.approvalStatus,
          selectedStatuses.ukFixedEstablishment,
          providedRejectionReasons.ukFixedEstablishment
        );
      case MerchantComplianceRequirementT.OSS:
        return isDataMissingForTask(
          ossData.approvalStatus,
          selectedStatuses.oss,
          providedRejectionReasons.oss
        );
      case MerchantComplianceRequirementT.ZFS_VAT:
        return zfsVatData.some(({ key, approvalStatus }) => {
          return isDataMissingForTask(
            approvalStatus,
            selectedStatuses.zfsVat[key],
            providedRejectionReasons.zfsVat[key]
          );
        });
      case MerchantComplianceRequirementT.OUTBOUND_WAREHOUSES:
        return outboundWarehouseData.some(({ warehouseId, approvalStatus }) => {
          return isDataMissingForTask(
            approvalStatus,
            selectedStatuses.outboundWarehouse[warehouseId],
            providedRejectionReasons.outboundWarehouse[warehouseId]
          );
        });
    }
  });
};
