var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "mcp-admin-merchant-details-section",
      class: { "is-loading": _vm.isLoading },
    },
    [
      _c(
        "div",
        { staticClass: "mcp-admin-merchant-details-section__header" },
        [
          _vm._v("Return Address"),
          _vm.isAddressDefined && !_vm.isFormVisible
            ? _c("ConfirmDataChange", {
                attrs: {
                  "confirm-cb": _vm.confirm,
                  customEditText: _vm.isMarketSpecific
                    ? ""
                    : "Use different address",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isLoading
        ? _c(
            "div",
            {
              staticClass: "mcp-admin__spinner-container",
              attrs: { "data-testid": "return-details-loader" },
            },
            [_c("div", { staticClass: "dc-spinner" })]
          )
        : _vm.isAddressDefined
        ? _c(
            "div",
            [
              _vm.isFormVisible
                ? _c("AddressForm", {
                    attrs: {
                      "country-name": _vm.countryName,
                      "is-submitting": _vm.isSubmitting,
                      address: _vm.address,
                    },
                    on: {
                      submit: _vm.saveReturnAddress,
                      "use-merchant-address": _vm.useMerchantAddress,
                      cancel: _vm.cancel,
                    },
                  })
                : _c("AddressReadOnly", {
                    attrs: { address: _vm.address, "show-name": "" },
                  }),
            ],
            1
          )
        : _c("div", { staticClass: "dc-msg dc-msg--warning" }, [
            _c("div", { staticClass: "dc-msg__inner" }, [
              _vm._m(0),
              _c("div", { staticClass: "dc-msg__bd" }, [
                _c("h1", { staticClass: "dc-msg__title" }, [
                  _vm._v("Please define return address in "),
                  _c(
                    "a",
                    {
                      staticClass: "dc-link dc-msg__bd__link",
                      attrs: { href: _vm.merchantDataPageLink },
                    },
                    [_vm._v("General Company Data")]
                  ),
                  _vm._v(" page"),
                ]),
              ]),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dc-msg__icon-frame" }, [
      _c("i", { staticClass: "dc-icon dc-msg__icon dc-icon--warning" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }