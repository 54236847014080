<template lang="pug">
v-flex.align-center.justify-end(d-flex data-testid="pagination")
  div.px-4.caption.secondary--text.text-no-wrap Rows per page:

  div.select-container
    v-select.pt-5(
      @change="val => $emit('select-page-size', val)"
      :items="numberOfItemsPerPageOptions"
      :value="defaultNumberOfItemsPerPage"
      dense
      ref="pageSizeSelect"
    )
      //- Customize selection style (font)
      template(v-slot:selection="{ item }")
        div.caption.secondary--text {{ item }}

  //- From-To indicator
  div.from-to-indicator.text-center.caption.secondary--text.text-no-wrap
    span(data-testid="pagination-display-from") {{ showingFrom }}
    | -
    span(data-testid="pagination-display-to") {{ showingTo }}
    | &nbsp;of&nbsp;
    span(data-testid="pagination-total-count") {{ totalCount }}

  //- Prev/next buttons
  //- Disabled state is shown by using explicit color, because disabled buttons
  //- have different color set in src/vuetify/styles-variables.scss
  v-btn(v-if="!previousButtonDisabled" text icon ref="previous" @click="$emit('previous-page')")
    v-icon mdi-chevron-left
  v-btn(text icon v-if="previousButtonDisabled" style="pointer-events: none")
    v-icon(color="#9b9b9b") mdi-chevron-left

  v-btn.ml-4(v-if="!nextButtonDisabled" text icon ref="next" @click="$emit('next-page')" :disabled="nextButtonDisabled")
    v-icon mdi-chevron-right
  v-btn.ml-4(v-if="nextButtonDisabled" text icon style="pointer-events: none")
    v-icon(color="#9b9b9b") mdi-chevron-right
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'Pagination',
  props: {
    showingFrom: {
      type: Number,
      required: true,
    },
    showingTo: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    const numberOfItemsPerPageOptions = [10, 25, 50, 100];

    return {
      numberOfItemsPerPageOptions,
      defaultNumberOfItemsPerPage: numberOfItemsPerPageOptions[0],
    };
  },
  computed: {
    previousButtonDisabled(): boolean {
      return this.showingFrom <= 1;
    },
    nextButtonDisabled(): boolean {
      return this.showingTo >= this.totalCount;
    },
  },
});
</script>

<style lang="scss" scoped>
.select-container {
  width: 50px;
}

// Set static width, so when the numbers get longer, the whole component doesn't change width.
.from-to-indicator {
  width: 130px;
}
</style>
