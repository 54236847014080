<template lang="pug">
div
  h1.dc-h1.mcp-admin__summary-header__title {{merchantName}}
  div.mcp-admin__summary-header__summary
    span(v-if="serviceLevel")
      span.mcp-admin__summary-header__value.service-level-value {{serviceLevel}}
      mp-info-tooltip(infoText="Merchant service level")
    span(v-if="serviceLevel && merchantType").mcp-admin__summary-header__value-separator
      = " / "
    span(v-if="merchantType")
      span.mcp-admin__summary-header__value.merchant-type-value {{merchantType}}
      mp-info-tooltip(infoText="Merchant type")
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    merchantName: {
      type: String,
      required: false,
      default: '',
    },
    serviceLevel: {
      type: String,
      required: false,
      default: '',
    },
    merchantType: {
      type: String,
      required: false,
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped>
.mcp-admin__summary-header__summary {
  margin-left: 24px;
}

.mcp-admin__summary-header__value {
  text-transform: uppercase;
  margin-right: 3px;
}
</style>
