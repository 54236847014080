let env: string = '';
if ((<any>window).__MC_INITIAL_STATE__) {
  env = (<any>window).__MC_INITIAL_STATE__.clientConfig.env || '';
}

// Environment role prefix
const ENV_ROLE_PREFIX = env.toLowerCase() === 'staging' ? 'Staging-' : '';

const ROLE_INTEGRATION_MANAGER = 'IntegrationKeyAccountManager';
const ROLE_OPERATIONS_MANAGER = 'OperationsManager';
const ROLE_FINANCIAL_APPROVER = 'FinancialApprover';
const ROLE_SUPER_ADMIN = 'SuperAdmin';
const ROLE_READ_ONLY = 'ReadOnly';
const ROLE_STOCK_MANAGER = 'StockManager';

export const ROLES_LIST = [
  ROLE_INTEGRATION_MANAGER,
  ROLE_OPERATIONS_MANAGER,
  ROLE_FINANCIAL_APPROVER,
  ROLE_SUPER_ADMIN,
  ROLE_READ_ONLY,
  ROLE_STOCK_MANAGER,
].map((ROLE) => ENV_ROLE_PREFIX + ROLE);

export const MCP_ADMIN_ROLES = {
  INTEGRATION_KEY_ACCOUNT_MANAGER: ENV_ROLE_PREFIX + ROLE_INTEGRATION_MANAGER,
  OPERATIONS_MANAGER: ENV_ROLE_PREFIX + ROLE_OPERATIONS_MANAGER,
  FINANCIAL_APPROVER: ENV_ROLE_PREFIX + ROLE_FINANCIAL_APPROVER,
  SUPER_ADMIN: ENV_ROLE_PREFIX + ROLE_SUPER_ADMIN,
  READ_ONLY: ENV_ROLE_PREFIX + ROLE_READ_ONLY,
  STOCK_MANAGER: ENV_ROLE_PREFIX + ROLE_STOCK_MANAGER,
};

export const ROLES_TO_DEFAULT_MERCHANT_ROUTE_STATE_MAP = {
  [MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER]:
    'mcp.admin.merchant.general.company-details',
  [MCP_ADMIN_ROLES.STOCK_MANAGER]: 'mcp.admin.merchant.general.company-details',
  [MCP_ADMIN_ROLES.OPERATIONS_MANAGER]:
    'mcp.admin.merchant.general.metadata-readonly',
  [MCP_ADMIN_ROLES.FINANCIAL_APPROVER]:
    'mcp.admin.merchant.general.company-details-approval',
  [MCP_ADMIN_ROLES.SUPER_ADMIN]: 'mcp.admin.merchant.general.company-details',
  [MCP_ADMIN_ROLES.READ_ONLY]: 'mcp.admin.merchant.general.metadata-readonly',
};

export const ROLES_TO_DEFAULT_CONTRACT_ROUTE_STATE_MAP = {
  [MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER]:
    'mcp.admin.merchant.contract.overview',
  [MCP_ADMIN_ROLES.STOCK_MANAGER]: 'mcp.admin.merchant.contract.overview',
  [MCP_ADMIN_ROLES.OPERATIONS_MANAGER]: 'mcp.admin.merchant.contract.overview',
  [MCP_ADMIN_ROLES.FINANCIAL_APPROVER]: 'mcp.admin.merchant.contract.approval',
  [MCP_ADMIN_ROLES.SUPER_ADMIN]: 'mcp.admin.merchant.contract.overview',
  [MCP_ADMIN_ROLES.READ_ONLY]: 'mcp.admin.merchant.contract.overview',
};
