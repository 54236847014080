var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      attrs: { novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _vm.isConfirmMerchantTypeChangeVisible
        ? _c(
            "mpConfirmationModal",
            {
              attrs: {
                "show-warning": "",
                "no-btn": "Cancel",
                "yes-btn": "Yes, Change",
                "data-testid": "confirmMerchantTypeModal",
              },
              on: {
                reject: function ($event) {
                  _vm.isConfirmMerchantTypeChangeVisible = false
                },
                confirm: _vm.save,
              },
            },
            [
              _vm.merchantTypeInitial !== _vm.data.merchantType
                ? _c("div", [
                    _vm._v(
                      "Changing the Merchant Type will also change the associated compensations for this merchant."
                    ),
                  ])
                : _vm._e(),
              _vm.factoringInitial !== _vm.data.factoring &&
              _vm.data.merchantType === "CONNECTED_RETAIL"
                ? _c("div", [
                    _vm._v(
                      "Please be aware that changing the factoring type would also mean that you need to change the compensation rates for this merchant."
                    ),
                  ])
                : _vm._e(),
              _vm.factoringInitial !== _vm.data.factoring &&
              _vm.data.merchantType !== "CONNECTED_RETAIL"
                ? _c("div", [
                    _vm._v(
                      "Changing the Factoring Type will also change the associated compensations for this merchant."
                    ),
                  ])
                : _vm._e(),
              _vm.integrationTypeInitial !== _vm.data.integrationType
                ? _c("div", [
                    _vm._v(
                      "Changing the integration type will affect partner experience on zDirect."
                    ),
                  ])
                : _vm._e(),
              _c("br"),
              _vm._v("Please proceed only when you are sure of this action."),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "mcp-admin-merchant-details-section" }, [
        _c("div", { staticClass: "mcp-admin-merchant-details-section-form" }, [
          _c("div", { staticClass: "mcp-admin__form-field field-wrapper" }, [
            _c(
              "label",
              { staticClass: "dc-label", attrs: { for: "service-level" } },
              [_vm._v("Service level")]
            ),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.serviceLevel,
                    expression: "data.serviceLevel",
                  },
                ],
                staticClass: "dc-select field",
                attrs: {
                  disabled: _vm.isSubmitInProgress,
                  name: "serviceLevel",
                  "data-testid": "service-level",
                  id: "service-level",
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.data,
                      "serviceLevel",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.serviceLevelOptions, function (opt) {
                return _c(
                  "option",
                  { key: opt.value, domProps: { value: opt.value } },
                  [_vm._v(_vm._s(opt.label))]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "mcp-admin__form-field field-wrapper" }, [
            _c(
              "label",
              { staticClass: "dc-label", attrs: { for: "merchant-type" } },
              [_vm._v("Merchant Type")]
            ),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.merchantType,
                    expression: "data.merchantType",
                  },
                ],
                staticClass: "dc-select field",
                attrs: {
                  disabled:
                    _vm.isSubmitInProgress || _vm.isMerchantTypeDisabled,
                  name: "merchantType",
                  "data-testid": "merchant-type",
                  id: "merchant-type",
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.data,
                      "merchantType",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.merchantTypeOptions, function (opt) {
                return _c(
                  "option",
                  { key: opt.value, domProps: { value: opt.value } },
                  [_vm._v(_vm._s(opt.label))]
                )
              }),
              0
            ),
            _vm.isMerchantTypeDisabled
              ? _c("div", { staticClass: "dc--text-error" }, [
                  _vm._v(
                    "The field is disabled to prevent automatic change of the active compensation. Contact Team Wilma for further details."
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "mcp-admin__form-field field-wrapper" }, [
            _c(
              "label",
              { staticClass: "dc-label", attrs: { for: "factoring-type" } },
              [_vm._v("Factoring Type")]
            ),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.factoring,
                    expression: "data.factoring",
                  },
                ],
                staticClass: "dc-select field",
                attrs: {
                  disabled: _vm.isSubmitInProgress || _vm.isFactoringDisabled,
                  name: "factoringType",
                  "data-testid": "factoring-type",
                  id: "factoring-type",
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.data,
                      "factoring",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.factoringOptions, function (opt) {
                return _c(
                  "option",
                  { key: opt.value, domProps: { value: opt.value } },
                  [_vm._v(_vm._s(opt.text))]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "mcp-admin__form-field field-wrapper" }, [
            _c("span", { staticClass: "dc-label" }, [
              _vm._v("Integration Type"),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.integrationType,
                  expression: "data.integrationType",
                },
              ],
              staticClass: "dc-radio",
              attrs: {
                type: "radio",
                id: "direct-integration",
                disabled: _vm.isSubmitInProgress,
                value: "DIRECT",
              },
              domProps: { checked: _vm._q(_vm.data.integrationType, "DIRECT") },
              on: {
                change: function ($event) {
                  return _vm.$set(_vm.data, "integrationType", "DIRECT")
                },
              },
            }),
            _c(
              "label",
              { staticClass: "dc-label", attrs: { for: "direct-integration" } },
              [_vm._v("Direct")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.integrationType,
                  expression: "data.integrationType",
                },
              ],
              staticClass: "dc-radio",
              attrs: {
                type: "radio",
                id: "non-direct-integration",
                disabled: _vm.isSubmitInProgress,
                value: "PARTNER_PROVIDER",
              },
              domProps: {
                checked: _vm._q(_vm.data.integrationType, "PARTNER_PROVIDER"),
              },
              on: {
                change: function ($event) {
                  return _vm.$set(
                    _vm.data,
                    "integrationType",
                    "PARTNER_PROVIDER"
                  )
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "dc-label",
                attrs: { for: "non-direct-integration" },
              },
              [_vm._v("Partner Provider")]
            ),
          ]),
          _c("div", { staticClass: "mcp-admin__form-field" }, [
            _c(
              "label",
              { staticClass: "dc-label", attrs: { for: "supplier-code" } },
              [_vm._v("Supplier Code")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.data.supplierCode,
                  expression: "data.supplierCode",
                },
              ],
              staticClass: "dc-input dc-input--block",
              class: { "error-input-field": !_vm.isSupplierCodeValid },
              attrs: {
                type: "string",
                disabled: _vm.isSubmitInProgress,
                name: "supplierCode",
                "data-testid": "supplier-code",
                id: "supplier-code",
              },
              domProps: { value: _vm.data.supplierCode },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.data, "supplierCode", $event.target.value)
                },
              },
            }),
            !_vm.isSupplierCodeValid
              ? _c(
                  "div",
                  {
                    staticClass: "errors dc--text-error",
                    attrs: { "data-testid": "supplier-code-error" },
                  },
                  [
                    !_vm.isEmpty(_vm.supplierCodeInitial) &&
                    _vm.isEmpty(_vm.data.supplierCode)
                      ? _c("span", [_vm._v("Please don't leave this empty.")])
                      : _c("span", [
                          _vm._v(
                            'Invalid format. It should start with "K" followed by 5 or 6 numbers. Also, first digit cannot be 0.'
                          ),
                        ]),
                  ]
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "dc-row dc-row--align--right" }, [
            _c("div", { staticClass: "dc-column dc-column--shrink" }, [
              _c(
                "button",
                {
                  staticClass: "dc-btn",
                  class: { "dc-btn--disabled": !_vm.hasChanges },
                  attrs: {
                    type: "reset",
                    disabled: !_vm.hasChanges,
                    "data-testid": "cancel-btn",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "button",
                {
                  staticClass: "dc-btn dc-btn--primary",
                  class: {
                    "dc-btn--disabled":
                      _vm.isSubmitInProgress || !_vm.hasChanges || !_vm.isValid,
                  },
                  staticStyle: { "margin-left": "3px" },
                  attrs: {
                    type: "submit",
                    disabled:
                      _vm.isSubmitInProgress || !_vm.hasChanges || !_vm.isValid,
                    "data-testid": "save-btn",
                  },
                  on: { click: _vm.confirmChange },
                },
                [_vm._v("Save")]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }