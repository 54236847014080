<template lang="pug">
v-card.pa-6(flat outlined)
  v-row.my-n3
    v-col(cols="4")
      // Shop name
      div
        a.headline.primary--text.font-weight-medium(:href="merchantUrl")
          mp-highlight-substring(:text="merchant.name" :match="activeNameFilter")

      // Legal name
      div.title {{ merchant.companyName }}

      // Modified at / by
      div.caption.disabled--text
        | Last modified on {{ merchant.modifiedAt | formatDate }} - by {{ merchant.modifiedBy }}

      // Service level
      v-chip.mt-6.service-level(v-if="merchant.serviceLevel" color="secondaryLight")
        div.subtitle-2.service-level-text
          | {{ merchant.serviceLevel }}
      // Factoring type
      v-chip.mt-6.service-level.ml-1(v-if="merchant.factoring" color="secondaryLight")
        div.subtitle-2.service-level-text
          | Factoring {{ merchant.factoring }}

    v-col
      v-flex.d-flex.pt-3(v-if="merchant.contracts.length === 0")
        span.subtitle-2.no-sales-channels-label
          | No sales channel(s) selected

        a.subtitle-2(
          v-if="canEdit"
          :href="salesChannelsUrl"
          data-testid="select-sales-channel"
        )
          | SELECT SALES CHANNEL

      // For each status that is present, show retailers
      div(
        v-for="statusData in retailersByStatus"
        :key="statusData.status"
      )
        v-flex.d-flex.pt-1(
          v-if="statusData.retailers.length > 0 || (statusData.shouldDisplayMerchant && canSeeGeneralData)"
        )
          div.subtitle-2.status-label
            span.status-label-circle(:style="{ color: MCP_MERCHANT_STATUS_COLORS[statusData.status] }")
            | {{ MCP_MERCHANT_STATUS_LABELS[statusData.status] }}

          v-flex
            // Include merchant's status
            a.subtitle-2(
              v-if="statusData.shouldDisplayMerchant && canSeeGeneralData"
              :href="merchantUrl"
              data-testid="general-company-data"
            )
              | GENERAL COMPANY DATA

            // Show retailers with contracts' sales channels
            div(
              v-for="retailer in statusData.retailers"
              :key="retailer.name"
              data-testid="retailers"
            )
              span.subtitle-2.text-uppercase.retailer
                | {{ retailer.name }}&nbsp;

              span.subtitle-2.text-uppercase(
                v-for="(contract, index) in retailer.contracts"
                :key="contract.id"
              )
                a(
                  :href="getContractUrl(contract.id)"
                  data-testid="`sales-channel-${contract.salesChannel}`"
                )
                  | {{ contract.salesChannel }}
                  span(v-if="index < retailer.contracts.length - 1")
                    | ,&nbsp;
</template>

<script lang="ts">
import Vue from 'vue';
import {
  MCP_MERCHANT_STATUSES,
  MCP_MERCHANT_STATUS_COLORS,
  MCP_MERCHANT_STATUS_LABELS,
} from 'Common/constants/merchant-statuses.constant';
import { groupContractsByRetailerAndStatus } from './merchant-summary-card.helper';
import { FactoringTypeT } from '@/api/models/MerchantProfile';

export interface MerchantSummaryCardContractT {
  id: string;
  status: string;
  salesChannel: string;
  retailer: {
    name: string;
  };
}

export interface MerchantSummaryCardT {
  id: string;
  companyName: string;
  name: string;
  modifiedAt: string;
  modifiedBy: string;
  serviceLevel: string;
  status: string;
  contracts: MerchantSummaryCardContractT[];
  factoring: FactoringTypeT;
  legalEntityId: string;
  merchantType: string;
}
export default Vue.extend({
  name: 'MerchantSummaryCard',
  props: {
    merchant: {
      type: Object as () => MerchantSummaryCardT,
      required: true,
    },
    canSeeGeneralData: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
    activeNameFilter: {
      type: String,
      required: true,
    },
    merchantPageRouteState: {
      type: String,
      required: false,
      default: null,
    },
    contractPageRouteState: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      MCP_MERCHANT_STATUSES,
      MCP_MERCHANT_STATUS_COLORS,
      MCP_MERCHANT_STATUS_LABELS,
    };
  },

  computed: {
    retailersByStatus() {
      return groupContractsByRetailerAndStatus(
        this.merchant.contracts,
        this.merchant.status
      );
    },

    merchantUrl(): string {
      return this.$router.resolve({
        name: this.merchantPageRouteState,
        params: { merchantId: this.merchant.id },
      }).href;
    },

    salesChannelsUrl(): string {
      return this.$router.resolve({
        name: 'mcp.admin.merchant.general.sales-channels',
        params: { merchantId: this.merchant.id },
      }).href;
    },
  },

  methods: {
    getContractUrl(contractId: string): string {
      return this.$router.resolve({
        name: this.contractPageRouteState,
        params: { merchantId: this.merchant.id, contractId },
      }).href;
    },
  },
});
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.status-label,
.no-sales-channels-label {
  width: 240px;
}

.status-label-circle:before {
  content: '\25CF'; /* a circle character */
  font-size: 16px;
  padding-right: 8px;
}

.service-level {
  pointer-events: none;
}

.service-level-text {
  min-width: 144px;
  text-align: center;
}

.retailer {
  display: inline-block;
  min-width: 110px;
}
</style>
