import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.hasData
    ? _c(
        VLayout,
        {
          staticClass: "align-center",
          attrs: { "justify-space-around": "", column: "" },
        },
        [
          _c(VIcon, { staticClass: "no-data-icon", attrs: { size: "56" } }, [
            _vm._v(" remove_circle_outline"),
          ]),
          _c("p", { staticClass: "subtitle-1 copy pt-6 text-center" }, [
            _vm._v("Specify master data configuration "),
            _c("br"),
            _vm._v(" to view the summary"),
          ]),
        ],
        1
      )
    : _c(
        VExpansionPanels,
        { attrs: { value: 0, accordion: "" } },
        [
          _c("MasterdataSummaryItem", {
            attrs: {
              label: "Season",
              readonly: _vm.readonly,
              data: _vm.seasons,
            },
            on: {
              remove: function ($event) {
                return _vm.remove("seasons", $event)
              },
            },
          }),
          _c("MasterdataSummaryItem", {
            attrs: { label: "Brand", readonly: _vm.readonly, data: _vm.brands },
            on: {
              remove: function ($event) {
                return _vm.remove("brands", $event)
              },
            },
          }),
          _vm.isACUChangesEnabled
            ? _c("MasterdataSummaryItem", {
                attrs: {
                  label: "Brand ownership",
                  readonly: _vm.readonly,
                  data: _vm.ownerBrands,
                },
                on: {
                  remove: function ($event) {
                    return _vm.remove("ownerBrands", $event)
                  },
                },
              })
            : _vm._e(),
          _c("MasterdataSummaryItem", {
            attrs: {
              label: "Silhouette",
              readonly: _vm.readonly,
              data: _vm.silhouettes,
            },
            on: {
              remove: function ($event) {
                return _vm.remove("silhouettes", $event)
              },
            },
          }),
          _c("MasterdataSummaryItem", {
            attrs: {
              clickable: "",
              label: "Size Chart",
              readonly: _vm.readonly,
              data: _vm.sizeCharts,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("showPreview", $event)
              },
              remove: function ($event) {
                return _vm.remove("sizeCharts", $event)
              },
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }