<template lang="pug">
  div
    div.text-h6.mb-2
      | Outbound locations
      mp-info-tooltip.ml-1(infoText=`Outbound locations are ids of locations from where the order will be fulfilled. For ZFS they are real locations since Zalando does have the data about the warehouses. For Partner Fulfillment - they are virtual ones, without being tied to a location in the real world`)
    div.text--primary.mb-4.mt-4 Live - {{ displayedFulfillmentType }}
    v-data-table(
      :headers="headers"
      :items="activeStockLocations"
      :loading="loadingStocks"
      hide-default-footer
      class="elevation-1"
      disable-pagination
    )
      template(v-slot:item.actions="{ item }")
        v-icon(
          @click="offboardStock(item)"
          small
        ) mdi-delete
    div.text--primary.mb-4.mt-4 Offboarded - {{ displayedFulfillmentType }}
    v-data-table(
      :headers="headers"
      :items="availableStockLocations"
      :loading="loadingStocks"
      hide-default-footer
      class="elevation-1"
      disable-pagination
    )
      template(v-slot:item.actions="{ item }")
        v-icon(
          @click="onboardStock(item)"
          small
        ) mdi-plus
    //- Buttons
    div(v-if="canEdit").dc-row.dc-row--align--right.mt-5
      div.dc-column.dc-column--shrink
        button.dc-btn(
          v-if="!isEditingEnabled"
          @click="onEnableEdit"
          type="reset"
          :class="{'dc-btn--disabled': false}"
        ) Edit

        button.dc-btn(
          v-if="isEditingEnabled"
          :class="{'dc-btn--disabled': false}"
          @click="onCancelEdit"
          type="reset"
        ) Cancel

        button.dc-btn.dc-btn--primary(
          v-if="isEditingEnabled"
          :disabled="!canSaveChanges"
          style="margin-left: 3px"
          type="submit"
          @click="onSave"
        ) Save

    //- confirmation modal
    v-dialog(
      v-model="showConfirmModal"
      width="550"
    )
      v-card
        v-card-title.title Provide a business reason

        v-card-text
          v-textarea(
            filled
            autofocus
            label="Business Reason for the change"
            v-model="businessReason"
          )

        v-card-actions
          v-spacer
          v-btn(
            text
            @click="onCancelEdit"
          ) Cancel

          v-btn(
            color="primary"
            @click="updateStocksLocations"
            :disabled="!businessReason"
          ) Confirm
</template>

<script lang="ts">
import Vue from 'vue';
import {
  fetchActiveStockLocations,
  updateAllStockLocations,
} from 'Api/endpoints/sales-channel/stocks.endpoint';
import Swal from 'sweetalert2';
import { getToken } from '@/authentication/token.helper';
import { MCP_ADMIN_ROLES } from '@/common/constants/roles.constant';
import { MCP_FF_TYPE_NAMES } from '@/common/constants/misc.constant';
import { CONTRACT_STOCK_STATUS } from '@/merchant/common/state/reducers/contract.reducer';

export default Vue.extend({
  props: {
    merchantId: { type: String, required: true },
    channelId: { type: String, required: true },
    fulfillmentType: { type: String, required: true },
  },

  data() {
    return {
      headers: [
        {
          text: 'Stock Id',
          align: 'start',
          sortable: false,
          value: 'stockId',
        },
        { text: 'Stock Name', value: 'stockName' },
        { text: 'Stock Location Id', value: 'stockLocationId' },
        { text: 'Stock Location name', value: 'stockLocationName' },
        { text: 'Country Code', value: 'countryCode' },
      ],
      initialActiveStockLocations: [],
      activeStockLocations: [],
      initialAvailableStockLocations: [],
      availableStockLocations: [],
      loadingStocks: true,
      businessReason: '',
      isEditingEnabled: false,
      showConfirmModal: false,
    };
  },
  computed: {
    canEdit() {
      return getToken().getCurrentRole() === MCP_ADMIN_ROLES.STOCK_MANAGER;
    },
    canSaveChanges() {
      return (
        this.availableStockLocations.some((s) => s.onboarded) ||
        this.activeStockLocations.some((s) => !s.onboarded)
      );
    },
    onboardStockIds() {
      return this.activeStockLocations
        .filter((s) => !s.onboarded)
        .map((s) => s.stockId);
    },
    offboardStockIds() {
      return this.availableStockLocations
        .filter((s) => s.onboarded)
        .map((s) => s.stockId);
    },
    displayedFulfillmentType() {
      return MCP_FF_TYPE_NAMES[this.fulfillmentType];
    },
  },
  mounted() {
    this.fetchStocksLocations();
  },

  methods: {
    fetchStocksLocations() {
      this.loadingStocks = true;
      fetchActiveStockLocations(
        this.merchantId,
        this.channelId,
        this.fulfillmentType
      ).then((data) => {
        // set active stock locations
        this.initialActiveStockLocations = [...data.activeConfigurations].map(
          (c) => ({
            ...c,
            onboarded: true,
          })
        );
        this.activeStockLocations = [...this.initialActiveStockLocations];
        this.store.dispatch({
          type: CONTRACT_STOCK_STATUS,
          payload: !!this.activeStockLocations.length,
        });
        // set available stock locations
        this.initialAvailableStockLocations = [
          ...data.availableConfigurations,
        ].map((c) => ({ ...c, onboarded: false }));
        this.availableStockLocations = [...this.initialAvailableStockLocations];
        this.loadingStocks = false;
      });
    },
    moveStockToActive(stock) {
      this.onboardStockIds.push(stock.stockId);
    },
    updateStocksLocations() {
      updateAllStockLocations(
        this.merchantId,
        this.channelId,
        this.fulfillmentType,
        {
          businessReason: this.businessReason,
          offboardStockIds: this.offboardStockIds,
          onboardStockIds: this.onboardStockIds,
        }
      )
        .then(() => {
          setTimeout(() => this.onCancelEdit(), 1000);
          Swal.fire({
            html: 'Stock Locations are updated successfully!',
            timer: 2000,
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            icon: 'success',
          });
        })
        .catch(() => {
          this.onCancelEdit();
          Swal.fire({
            html: 'Something went wrong while updating the Stock Locations.Please try again',
            timer: 2000,
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            icon: 'error',
          });
        });
    },
    resetStocks() {
      this.fetchStocksLocations();
      this.businessReason = '';
      this.showConfirmModal = false;
    },
    onEnableEdit() {
      this.isEditingEnabled = true;
      this.headers.push({
        text: 'Actions',
        value: 'actions',
        sortable: false,
      });
    },
    offboardStock(stock) {
      this.activeStockLocations = this.activeStockLocations.filter(
        (s) => s.stockId !== stock.stockId
      );
      this.availableStockLocations.push(stock);
    },
    onboardStock(stock) {
      this.availableStockLocations = this.availableStockLocations.filter(
        (s) => s.stockId !== stock.stockId
      );
      this.activeStockLocations.push(stock);
    },
    onSave() {
      this.showConfirmModal = true;
    },
    onCancelEdit() {
      this.isEditingEnabled = false;
      this.headers.pop();
      this.resetStocks();
    },
  },
});
</script>

<style lang="scss" scoped>
.root ::v-deep .v-application--wrap {
  min-height: inherit;
}
.mono {
  font-family: monospace;
}
</style>
