import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "certificate-container" },
    [
      _c(
        VSnackbar,
        {
          attrs: { timeout: 5000, color: "reject", bottom: "", right: "" },
          model: {
            value: _vm.showError,
            callback: function ($$v) {
              _vm.showError = $$v
            },
            expression: "showError",
          },
        },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _vm.isFetchingCertificate
        ? _c(VProgressCircular, {
            attrs: { indeterminate: "", color: "secondaryDark" },
          })
        : _vm.name
        ? _c(
            "button",
            {
              staticClass: "blue--text certificate-link",
              on: { click: _vm.handleCertificateClick },
            },
            [_vm._v(_vm._s(_vm.name))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }