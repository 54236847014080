import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { style: { background: "white" }, attrs: { id: "vue-carrier" } },
    [
      _c(
        VMain,
        [
          _c(
            VContainer,
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _vm.isLoading
                ? _c(
                    "div",
                    { staticClass: "text-center mt-2" },
                    [
                      _c(VProgressCircular, {
                        attrs: {
                          size: 50,
                          color: "primary",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.isLoadingError
                ? _c(VAlert, { attrs: { value: true, type: "error" } }, [
                    _vm._v(
                      "Something went wrong while fetching Carrier Details information"
                    ),
                  ])
                : [
                    [
                      !_vm.shipmentCarrier
                        ? _c(
                            "div",
                            { staticClass: "mb-5" },
                            [
                              _c(
                                VBanner,
                                { attrs: { outlined: "", rounded: "" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "mr-4 justify-center" },
                                        [_vm._v("mdi-information-outline")]
                                      ),
                                      _c("div", { staticClass: "text-dark" }, [
                                        _vm._v(
                                          "This partner does not have a carrier preference set yet.\nIf this is a hybrid partner (the merchant is using both ZFS and PF),\nyou will need to set their carrier for them. If this is not a hybrid partner,\nplease wait for the partner to set their carrier in zDirect."
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "mb-5 text-dark" }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Note:"),
                        ]),
                        _c("span", [
                          _vm._v(" Please refer to the\n"),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://partnerportal.zalando.com/partners/s/article/Logistic-providers-per-country",
                              },
                            },
                            [_vm._v("carrier matrix")]
                          ),
                          _vm._v(
                            " before configuring a carrier. If the carrier is not in the list,\nrequest an exception by asking the partner to fill out a form\n"
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://docs.google.com/forms/d/e/1FAIpQLSfC9w77A7p_qqHLV3xTSrso3NaZA4NNMmudPxBrWMMB1jnNuQ/viewform",
                              },
                            },
                            [_vm._v("here")]
                          ),
                          _vm._v("."),
                        ]),
                      ]),
                      _c("div", { staticClass: "grey lighten-5 py-5 mb-5" }, [
                        _c("h3", { staticClass: "black--text ml-4" }, [
                          _vm._v("Outbound carrier details"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex mt-3 ml-0" },
                          [
                            _c("mp-labeled-text", {
                              attrs: {
                                label: "Shipment carrier",
                                value: _vm.carrierName,
                              },
                            }),
                            _c("mp-labeled-text", {
                              attrs: {
                                label: "Delivery method",
                                value: _vm.deliveryProductsLabels,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "grey lighten-5 px-4 py-5 mb-5" },
                        [
                          _c("h3", { staticClass: "black--text" }, [
                            _vm._v("Add outbound carrier configuration"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mt-1 row" },
                            [
                              _c(VSelect, {
                                staticClass: "ml-4",
                                style: { maxWidth: "40%" },
                                attrs: {
                                  label: `${
                                    _vm.selectedCarrier ? "Selected" : "Select"
                                  } shipment carrier`,
                                  items: _vm.carriersList,
                                },
                                on: { change: _vm.onCarrierChange },
                                model: {
                                  value: _vm.selectedCarrier,
                                  callback: function ($$v) {
                                    _vm.selectedCarrier = $$v
                                  },
                                  expression: "selectedCarrier",
                                },
                              }),
                              _c(VSelect, {
                                staticClass: "ml-4",
                                style: { maxWidth: "40%" },
                                attrs: {
                                  label: `${
                                    _vm.selectedCarrierId
                                      ? "Selected"
                                      : "Select"
                                  } delivery method`,
                                  items: _vm.deliveryDestinationTypes,
                                  disabled:
                                    !_vm.deliveryDestinationTypes.length,
                                },
                                on: { change: _vm.setParcelServices },
                                model: {
                                  value: _vm.selectedCarrierId,
                                  callback: function ($$v) {
                                    _vm.selectedCarrierId = $$v
                                  },
                                  expression: "selectedCarrierId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-1 row" },
                            [
                              _c(VSelect, {
                                staticClass: "ml-4",
                                style: { maxWidth: "40%" },
                                attrs: {
                                  label: `${
                                    _vm.selectedOptionalCapabilities.length
                                      ? "Selected"
                                      : "Select"
                                  } opt in service`,
                                  items: _vm.optionalCapabilities,
                                  multiple: true,
                                  disabled: !_vm.optionalCapabilities.length,
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.isChanged = true
                                  },
                                },
                                model: {
                                  value: _vm.selectedOptionalCapabilities,
                                  callback: function ($$v) {
                                    _vm.selectedOptionalCapabilities = $$v
                                  },
                                  expression: "selectedOptionalCapabilities",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex flex-column ml-4",
                                  style: { maxWidth: "40%" },
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-caption v-label theme--light font-weight-light",
                                    },
                                    [_vm._v("Parcel service")]
                                  ),
                                  _c("div", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectedParcelServices.join(", ") ||
                                          "—"
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass:
                                "mt-5 mb-6 ml-2 white--text text-none",
                              attrs: {
                                color: "black",
                                disabled:
                                  _vm.shouldDisableShipmentCarrierSubmit,
                                loading: _vm.isSubmitting,
                              },
                              on: { click: _vm.addNewCarrier },
                            },
                            [_vm._v("Add")]
                          ),
                        ],
                        1
                      ),
                      _vm.configuredCarriers.length
                        ? _c("CarrierCards", {
                            attrs: {
                              list: _vm.configuredCarriers,
                              "on-delete": _vm.deleteCarrier,
                            },
                          })
                        : _vm._e(),
                    ],
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }