import { getToken, getUser } from '@/authentication/token.helper';
import template from './navbar.html';
import { mcb } from 'mcb';
import {
  ROLES_TO_DEFAULT_MERCHANT_ROUTE_STATE_MAP,
  ROLES_TO_DEFAULT_CONTRACT_ROUTE_STATE_MAP,
} from 'Common/constants/roles.constant';

export class NavbarComponent implements ng.IComponentOptions {
  static Factory() {
    return new NavbarComponent();
  }

  controller = NavbarController;
  controllerAs = 'ctrl';
  template: string = template;
}

export class NavbarController {
  static $inject = ['McAuthentication', '$window', '$state', 'mcEnv'];

  showNav = false;
  user: mcb.IUser;
  userRoles: string[];
  userCurrentRole = '';
  env: string;

  constructor(
    private McAuthentication,
    private $window: ng.IWindowService,
    private $state: ng.ui.IStateService,
    private mcEnv
  ) {}

  $onInit(): void {
    const { mcEnv } = this;

    const environments = {
      PRODUCTION: 'Production',
      STAGING: 'Staging',
      DEV: 'Development',
    };

    this.env = environments[mcEnv] || 'Not detected!';

    this.user = getUser();
    this.userRoles = getToken().getRoles();
    this.userCurrentRole = getToken().getCurrentRole();
  }

  showRoleSwitch(): boolean {
    // Display the switch only on Merchant pages (on other pages we have a Vue implementation)
    return !!this.$state.params.merchantId && this.userRoles.length > 1;
  }

  onRoleChange(): void {
    const { $state, userCurrentRole } = this;
    const { contractId, merchantId } = $state.params;
    const roleIndex = this.userRoles.indexOf(this.userCurrentRole);

    getToken().setCurrentRole(userCurrentRole);

    const routeState = contractId
      ? ROLES_TO_DEFAULT_CONTRACT_ROUTE_STATE_MAP[userCurrentRole]
      : ROLES_TO_DEFAULT_MERCHANT_ROUTE_STATE_MAP[userCurrentRole];
    const url = new URL(
      $state.href(routeState, { merchantId, contractId }, { absolute: true })
    );

    // Set a query parameter to achieve full page reload
    url.searchParams.set('randomK', roleIndex.toString());
    window.location.assign(url.toString());
  }

  toggleSubMenu(): void {
    if (!this.showNav) {
      this.showSubMenu();
    } else {
      this.hideSubMenu();
    }
  }

  showSubMenu(): void {
    this.userCurrentRole = getToken().getCurrentRole();
    this.showNav = true;
  }

  hideSubMenu(): void {
    this.showNav = false;
  }

  logout(): void {
    this.McAuthentication.logout().then(() => {
      this.$window.location.reload();
    });
  }
}
