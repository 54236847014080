import {
  MCP_MERCHANT_STATUSES,
  MCP_MERCHANT_STATUS_LABELS,
} from '../../constants/merchant-statuses.constant';
import template from './merchant-status-label.html';

export class MerchantStatusLabelComponent implements ng.IComponentOptions {
  static Factory() {
    return new MerchantStatusLabelComponent();
  }

  bindings: { [binding: string]: string } = {
    status: '@',
  };
  controller = MerchantStatusController;
  template: string = template;
}

class MerchantStatusController implements ng.IController {
  private cssClassesMap = {};

  constructor() {
    let cssClassesMap = this.cssClassesMap;

    cssClassesMap[MCP_MERCHANT_STATUSES.IN_PROGRESS] = 'dc-status--inactive';
    cssClassesMap[MCP_MERCHANT_STATUSES.IN_FINANCIAL_REVIEW] = 'dc-status--new';
    cssClassesMap[MCP_MERCHANT_STATUSES.FINANCIAL_APPROVED] =
      'dc-status--active';
    cssClassesMap[MCP_MERCHANT_STATUSES.FINANCIAL_REJECTED] =
      'dc-status--error';
    cssClassesMap[MCP_MERCHANT_STATUSES.DEACTIVATED] = 'dc-status--error';
  }

  $onInit() {
    // Empty block
  }

  getStatusCssClass(status: string): string {
    return this.cssClassesMap[status] || 'dc-status--error';
  }

  getStatusLabel(status: string): string {
    return MCP_MERCHANT_STATUS_LABELS[status] || 'Unknown';
  }
}
