import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "legalEntitiesContainer" },
    [
      _c(
        VMenu,
        {
          attrs: {
            "content-class": "entityMenuContent",
            attach: ".legalEntitiesContainer",
            bottom: "",
            "nudge-bottom": "58",
            "close-on-click": true,
            "close-on-content-click": false,
          },
          on: { "update:return-value": _vm.onMenuClose },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    VTextField,
                    _vm._g(
                      {
                        directives: [
                          {
                            name: "stream",
                            rawName: "v-stream:input",
                            value: _vm.searchInput$,
                            expression: "searchInput$",
                            arg: "input",
                          },
                        ],
                        ref: "input",
                        attrs: {
                          label: "Search Legal Entities",
                          hint: "Search to select legal entities to associate with merchant account",
                          "persistent-hint": "",
                          filled: "",
                          clearable: "",
                          loading: _vm.itemsLoading,
                          disabled: _vm.disabled,
                        },
                        on: {
                          keydown: function ($event) {
                            return _vm.onKeydown($event, on)
                          },
                          blur: _vm.onBlur,
                        },
                        model: {
                          value: _vm.searchQuery,
                          callback: function ($$v) {
                            _vm.searchQuery = $$v
                          },
                          expression: "searchQuery",
                        },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.isMenuVisible,
            callback: function ($$v) {
              _vm.isMenuVisible = $$v
            },
            expression: "isMenuVisible",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardText, { staticClass: "text--primary" }, [
                !_vm.searchQuery ||
                _vm.searchQuery.length < _vm.minSearchQueryLength
                  ? _c("div", [
                      _c("div", [
                        _vm._v(
                          "Type at least " +
                            _vm._s(_vm.minSearchQueryLength) +
                            " characters to start searching"
                        ),
                      ]),
                    ])
                  : _c("div", [
                      _vm.items.assignedItems.length ||
                      _vm.items.unassignedItems.length
                        ? _c(
                            "div",
                            [
                              _c("div", [
                                _vm._v("Legal entities with similar names:"),
                              ]),
                              _vm.items.unassignedItems.length
                                ? _c(
                                    "div",
                                    { staticClass: "unassignedItems" },
                                    _vm._l(
                                      _vm.items.unassignedItems,
                                      function (item) {
                                        return _c(
                                          "div",
                                          [
                                            _c(VCheckbox, {
                                              staticClass: "mt-2 primary--text",
                                              attrs: {
                                                color: "primary",
                                                "hide-details": "",
                                                label: item.name,
                                                "input-value": _vm.value.has(
                                                  item.id
                                                ),
                                                disabled:
                                                  !_vm.removable &&
                                                  _vm.defaultValue.has(item.id),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onChange(
                                                    item,
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm.items.assignedItems.length &&
                              _vm.items.unassignedItems.length
                                ? _c(VDivider, { staticClass: "mt-2" })
                                : _vm._e(),
                              _vm.items.assignedItems.length
                                ? _c(
                                    "div",
                                    { staticClass: "assignedItems" },
                                    _vm._l(
                                      _vm.items.assignedItems,
                                      function (item) {
                                        return _c(
                                          "div",
                                          { staticClass: "mt-2" },
                                          [
                                            _vm._v(
                                              _vm._s(item.name) +
                                                ' - Already added in "' +
                                                _vm._s(
                                                  item.merchantAccount.name
                                                ) +
                                                '" merchant account'
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm.itemsLoading
                        ? _c("div", { staticClass: "mb-4" }, [
                            _vm._v("Loading"),
                          ])
                        : _c("div", { staticClass: "mb-4" }, [
                            _vm._v(
                              "No Legal Entities with similar names found"
                            ),
                          ]),
                    ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.notify === "NOTIFY_SEARCH_FAILED"
        ? _c(
            VSnackbar,
            {
              attrs: { color: "reject", timeout: 3000 },
              model: {
                value: _vm.notify,
                callback: function ($$v) {
                  _vm.notify = $$v
                },
                expression: "notify",
              },
            },
            [
              _vm._v(" Error while searching for legal entities"),
              _c(
                VBtn,
                {
                  attrs: { text: "", dark: "" },
                  on: {
                    click: function ($event) {
                      _vm.notify = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }