import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { staticClass: "root", attrs: { id: "masterdata" } },
    [
      _c("Masterdata", {
        directives: [
          {
            name: "stream",
            rawName: "v-stream:update:sizeSearchInput",
            value: _vm.sizeSearchInput$,
            expression: "sizeSearchInput$",
            arg: "update:sizeSearchInput",
          },
        ],
        attrs: {
          "saved-values": _vm.savedValues,
          attributes: _vm.attributes,
          "size-attrs": _vm.sizeAttrs,
          "size-attrs-cache-map": _vm.sizeAttrsCacheMap,
          "size-attrs-loading": _vm.sizeAttrsLoading,
          state: _vm.state,
          "can-export": _vm.exportLang !== null,
          "export-logs": _vm.exportLogs,
          "show-saved-not-exported-warning": _vm.showSavedNotExportedWarning,
        },
        on: { save: _vm.onSave, export: _vm.onExport },
      }),
      _vm.notify === "NOTIFY_INIT_FAILED"
        ? _c(
            VSnackbar,
            {
              attrs: { color: "error", bottom: "", timeout: 2000 },
              model: {
                value: _vm.notify,
                callback: function ($$v) {
                  _vm.notify = $$v
                },
                expression: "notify",
              },
            },
            [_vm._v(" Masterdata initialisation failed")]
          )
        : _vm._e(),
      _vm.notify === "NOTIFY_SAVE_SUCCESS"
        ? _c(
            VSnackbar,
            {
              attrs: { color: "success", bottom: "", timeout: 2000 },
              model: {
                value: _vm.notify,
                callback: function ($$v) {
                  _vm.notify = $$v
                },
                expression: "notify",
              },
            },
            [_vm._v(" Masterdata successfully saved")]
          )
        : _vm._e(),
      _vm.notify === "NOTIFY_SAVE_FAILED"
        ? _c(
            VSnackbar,
            {
              attrs: { color: "error", bottom: "", timeout: 3000 },
              model: {
                value: _vm.notify,
                callback: function ($$v) {
                  _vm.notify = $$v
                },
                expression: "notify",
              },
            },
            [
              _vm._v(" Unable to save masterdata"),
              _c(
                VBtn,
                {
                  attrs: { dark: "", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.notify = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.notify === "NOTIFY_EXPORT_SUCCESS"
        ? _c(
            VSnackbar,
            {
              attrs: { color: "success", bottom: "", timeout: 2000 },
              model: {
                value: _vm.notify,
                callback: function ($$v) {
                  _vm.notify = $$v
                },
                expression: "notify",
              },
            },
            [_vm._v("Master data sent for export successfully")]
          )
        : _vm._e(),
      _vm.notify === "NOTIFY_EXPORT_FAILED"
        ? _c(
            VSnackbar,
            {
              attrs: { color: "error", bottom: "", timeout: 3000 },
              model: {
                value: _vm.notify,
                callback: function ($$v) {
                  _vm.notify = $$v
                },
                expression: "notify",
              },
            },
            [
              _vm._v("Unable to mark masterdata as completed"),
              _c(
                VBtn,
                {
                  attrs: { dark: "", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.notify = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.notify === "NOTIFY_FETCH_SIZE_ATTRS_FAILED"
        ? _c(
            VSnackbar,
            {
              attrs: { color: "error", bottom: "", timeout: 3000 },
              model: {
                value: _vm.notify,
                callback: function ($$v) {
                  _vm.notify = $$v
                },
                expression: "notify",
              },
            },
            [
              _vm._v(" Unable to fetch sizing attributes"),
              _c(
                VBtn,
                {
                  attrs: { dark: "", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.notify = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }