const VALUES = {
  MODIFIED_AT_DOWN: '-modified_at',
  MODIFIED_AT_UP: 'modified_at',
  NAME_UP: 'name',
  NAME_DOWN: '-name',
  COMPANY_NAME_UP: 'company_name',
  COMPANY_NAME_DOWN: '-company_name',
};

const LABELS = {
  [VALUES.MODIFIED_AT_DOWN]: 'Last Modified (Newest first)',
  [VALUES.MODIFIED_AT_UP]: 'Last Modified (Oldest first)',
  [VALUES.NAME_UP]: 'Shop Name (Ascending)',
  [VALUES.NAME_DOWN]: 'Shop Name (Descending)',
  [VALUES.COMPANY_NAME_UP]: 'Legal Name (Ascending)',
  [VALUES.COMPANY_NAME_DOWN]: 'Legal Name (Descending)',
};

const OPTIONS = Object.values(VALUES).map((value) => ({
  value,
  text: LABELS[value],
}));

export const MCP_MERCHANT_SORT_OPTIONS = OPTIONS;
