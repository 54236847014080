import { ApiService } from 'Api/services/api.service';
import { Retailer } from 'Api/models/Retailer';
import { Contract } from 'Api/models/Contract';
import { SalesChannel, SalesChannelExt } from 'Api/models/SalesChannel';

const RETAILER_SORT_ORDER = [
  'Zalando Fashion Store',
  'Zalando Lounge',
  'Fleek',
  'Movmnt',
];

export interface RetailerToContracts {
  retailerId: string;
  name: string;
  contracts: Contract[];
  channels: SalesChannelExt[];
}

export class RetailerService {
  static $inject = ['mcpAdminApi'];

  /**
   * Group contracts by Sales Channel
   *
   * @param contracts
   */
  private static convertToChannels(contracts: Contract[]): SalesChannelExt[] {
    const channelsToContractsMap = contracts.reduce((channels, contract) => {
      channels[contract.salesChannelId] =
        channels[contract.salesChannelId] || [];

      channels[contract.salesChannelId].push(contract);

      return channels;
    }, {});

    return Object.keys(channelsToContractsMap).map((channelId) => {
      const channelContracts: Contract[] = channelsToContractsMap[channelId];
      return {
        ...channelContracts[0].salesChannel,
        status: channelContracts[0].status,
        approverComment: channelContracts[0].approverComment,
        retailerId: channelContracts[0].retailerId,
        contracts: channelContracts,
      };
    });
  }

  constructor(private api: ApiService) {}

  fetchRetailers(): ng.IPromise<Retailer[]> {
    return this.api.retailer.fetchRetailers().then((retailers: Retailer[]) => {
      return this.sortRetailers(retailers);
    });
  }

  mapRetailersToContracts(
    contracts: Contract[]
  ): ng.IPromise<RetailerToContracts[]> {
    return this.fetchRetailers().then((retailers: Retailer[]) => {
      let retailersToContracts: RetailerToContracts[] = [];
      retailers.forEach((retailer: Retailer) => {
        let currentContracts = contracts.filter(
          (contract) => retailer.$id === contract.retailerId
        );

        if (currentContracts.length > 0) {
          retailersToContracts.push({
            name: retailer.name,
            retailerId: retailer.$id,
            contracts: currentContracts,
            channels: RetailerService.convertToChannels(currentContracts),
          });
        }
      });
      return retailersToContracts;
    });
  }
  getRetailerById(retailerId: string): ng.IPromise<Retailer> {
    return this.fetchRetailers().then((retailers: Retailer[]) => {
      return retailers.filter((retailer: Retailer) => {
        return retailer.$id === retailerId;
      })[0];
    });
  }

  getSalesChannelById(salesChannelId: string): ng.IPromise<SalesChannel> {
    return this.fetchRetailers().then((retailers: Retailer[]) => {
      let targetSalesChannel: SalesChannel = null;
      retailers.forEach((retailer: Retailer) => {
        retailer.salesChannels.forEach((salesChannel: SalesChannel) => {
          if (salesChannel.$id === salesChannelId) {
            targetSalesChannel = salesChannel;
          }
        });
      });
      return targetSalesChannel;
    });
  }

  private sortRetailers(retailers: Retailer[]): Retailer[] {
    return retailers.sort((a, b) => {
      if (
        RETAILER_SORT_ORDER.indexOf(a.name) >
        RETAILER_SORT_ORDER.indexOf(b.name)
      ) {
        return 1;
      }
      return 0;
    });
  }
}
