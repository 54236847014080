import utilModule from '../../core/util/util';
import routerModule from '../router/router';
import {
  $$mcbFirewallRegistryProvider,
  mcbFirewallProvider,
} from './firewallProvider';
import FirewallManager from './firewallManager';

export default angular
  .module('mcb.components.firewall', [utilModule.name, routerModule.name])

  // @ts-ignore
  .provider('$$mcbFirewallRegistry', $$mcbFirewallRegistryProvider)

  .provider('mcbFirewall', [
    '$$mcbFirewallRegistryProvider',
    mcbFirewallProvider,
  ])

  .run([
    'mcbFirewall',
    function (mcbFirewall) {
      mcbFirewall.$$registerOnTransitionStartHook();
    },
  ])

  .factory('$$mcbFirewallManager', [
    'mcbRouterUtil',
    'mcbReduceFrom',
    '$$mcbFirewallRegistry',
    function (mcbRouterUtil, mcbReduceFrom, $$mcbFirewallRegistry) {
      return new FirewallManager(
        mcbRouterUtil,
        mcbReduceFrom,
        $$mcbFirewallRegistry
      );
    },
  ]);
