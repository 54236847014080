import { uniq } from 'ramda';
import { MCP_MERCHANT_STATUS_LABELS } from 'Common/constants/merchant-statuses.constant';

const statusSortingFn = (statusA: string, statusB: string): number => {
  const sortedStatuses = Object.keys(MCP_MERCHANT_STATUS_LABELS);
  return sortedStatuses.indexOf(statusA) - sortedStatuses.indexOf(statusB);
};

type ContractT = {
  status: string;
  retailer: { name: string };
  salesChannel: string;
};

type ReturnT = {
  status: string;
  shouldDisplayMerchant: boolean;
  retailers: { name: string; contracts: ContractT[] }[];
}[];

export const groupContractsByRetailerAndStatus = (
  contracts: ContractT[],
  merchantStatus: string
): ReturnT => {
  const statusesToInclude = uniq([
    merchantStatus,
    ...contracts.map((contract) => contract.status),
  ]).sort(statusSortingFn);

  return statusesToInclude.map((status) => {
    const contractsWithCurrentStatus = contracts.filter(
      (contract) => contract.status === status
    );
    const uniqueRetailerNames = uniq(
      contractsWithCurrentStatus.map((contract) => contract.retailer.name)
    );

    return {
      status,
      shouldDisplayMerchant: status === merchantStatus,
      retailers: uniqueRetailerNames.map((retailerName) => {
        return {
          name: retailerName,
          contracts: contractsWithCurrentStatus
            .filter((c) => c.retailer.name === retailerName)
            .sort((country1, country2) => {
              const nameA = country1.salesChannel;
              const nameB = country2.salesChannel;
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              return 0;
            }),
        };
      }),
    };
  });
};
