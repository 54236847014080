export default class McbRouterUtil {
  private _$state: any;

  constructor($state) {
    this._$state = $state;
  }

  /**
   *
   * @param stateRef - an ui router state reference
   * @returns {Object|undefined} parentStateRef - the parent ui router stateRef if exists otherwise undefined
   */
  getParentStateRef(stateRef) {
    let name;
    let parentNames;
    let parentName;

    if (!stateRef) {
      return;
    }

    name = stateRef.name;

    if (!name) {
      return;
    }

    if (stateRef.parent && this._$state.get(stateRef.parent)) {
      return this._$state.get(stateRef.parent);
    }

    parentNames = name.split('.');
    parentName = parentNames.slice(0, parentNames.length - 1).join('.');

    if (!this._$state.get(parentName) || parentName === name) {
      return;
    }

    return this._$state.get(parentName);
  }
}

McbRouterUtil.$inject = ['$state'];
