import axios from 'axios';
import { pipe } from 'ramda';
import ErrorHandler from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import LegalEntity from 'Api/models/LegalEntity';
import LegalEntityDetailed from 'Api/models/LegalEntityDetailed';
import { LegalEntityDetailsT } from 'Api/models/LegalEntityDetails';

const legalEntityApi = axios.create({
  baseURL: 'api/merchant-profile/legal-entities',
});
legalEntityApi.interceptors.request.use(decamelizeRequestHandler);
legalEntityApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

export interface LegalEntitiesCombinedResponse {
  assignedItems: LegalEntity[];
  unassignedItems: LegalEntity[];
}

export function fetchLegalEntity(id: string): Promise<LegalEntity> {
  return legalEntityApi.get(id, {
    params: {
      include: 'merchants',
    },
  });
}

export function fetchLegalEntities({
  name = '',
  includeUnassigned = null,
  merchantAccountId = null,
  includeMerchants = false,
}: {
  name?: string;
  includeUnassigned?: boolean;
  merchantAccountId?: string;
  includeMerchants?: boolean;
}): Promise<LegalEntity[]> {
  return legalEntityApi
    .get<LegalEntity[], { items: LegalEntity[] }>('', {
      params: {
        name: name || null,
        merchantAccountId: merchantAccountId || null,
        hasAccount: !includeUnassigned,
        include: !includeUnassigned ? 'merchant-account' : null,
      },
    })
    .then(({ items }) =>
      includeMerchants
        ? (Promise.all(
            items.map((entity) => fetchLegalEntityDetailed(entity.id))
          ) as Promise<LegalEntity[]>)
        : items
    );
}

export function fetchLegalEntitiesCombined(
  accountId: string,
  name = ''
): Promise<LegalEntitiesCombinedResponse> {
  const assignedPromise = fetchLegalEntities({
    name,
    includeUnassigned: false,
  });

  const unassignedPromise = fetchLegalEntities({
    name,
    includeUnassigned: true,
  });

  return Promise.all([assignedPromise, unassignedPromise]).then(
    ([assignedItems, unassignedItems]) =>
      mvCurrentAccountLEToUnassigned(accountId, assignedItems, unassignedItems)
  );
}

export function fetchLegalEntityDetailed(
  id: string
): Promise<LegalEntityDetailed> {
  return legalEntityApi.get(`${id}`, {
    params: {
      include: 'merchants,legal-entity-contacts',
    },
  });
}

export function fetchPartnerLegalEntityDetails(
  legalEntityId: string
): Promise<LegalEntityDetailsT> {
  return legalEntityApi.get(`${legalEntityId}/details`);
}

export const updatePartnerLegalEntityDetails = (
  legalEntityId: string,
  data: LegalEntityDetailsT
): Promise<void> => {
  return legalEntityApi.put(
    `${legalEntityId}/details?is_pre_approved=true`,
    data
  );
};
/**
 * In the context of current account (e.g. edit account page),
 * it should be possible to select/deselect its legal entities.
 * To enable that we should treat current account' legal entities
 * as unassigned
 */
function mvCurrentAccountLEToUnassigned(
  accountId: string,
  assigned: LegalEntity[],
  unassigned: LegalEntity[]
): LegalEntitiesCombinedResponse {
  const filteredAssigned = assigned.filter(
    (item) => item.merchantAccountId !== accountId
  );
  const newUnassigned = assigned.filter(
    (item) => item.merchantAccountId === accountId
  );

  return {
    assignedItems: filteredAssigned,
    unassignedItems: [...unassigned, ...newUnassigned],
  };
}
