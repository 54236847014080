interface DownloadFilePropsT {
  fileContent: BlobPart;
  fileName: string;
  mediaType: string;
}

export const downloadFile = ({
  fileContent,
  fileName,
  mediaType,
}: DownloadFilePropsT): void => {
  const fileBlob = new File([fileContent], fileName, {
    type: mediaType,
  });

  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  downloadLink.href = window.URL.createObjectURL(fileBlob);
  downloadLink.click();
};
