var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Appbar",
    {
      attrs: {
        env: _vm.env,
        roles: _vm.roles,
        currentRole: _vm.currentRole,
        username: _vm.username,
        tabs: _vm.tabsNames,
        activeTab: _vm.activeTab,
        hideTabs: _vm.hideTabs,
      },
      on: {
        "tab-changed": _vm.changeTab,
        "role-changed": _vm.switchRole,
        logout: _vm.logout,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }