import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    [
      _c(VContainer, [
        _vm.isFetching
          ? _c("div", { staticClass: "dc-spinner dc-spinner--small" })
          : _c(
              "div",
              [
                _vm.showForm
                  ? _c("BillingAndBankDetailsForm", {
                      attrs: {
                        "billing-mode": _vm.billingMode,
                        "invoice-language": _vm.invoiceLanguage,
                        "bank-account-list": _vm.existingBankAccountList,
                        "merchant-id": _vm.merchantId,
                        "currency-sales-channels": _vm.currencySalesChannels,
                        "legal-entity-name": _vm.legalEntityName,
                      },
                      on: {
                        "on-close": function ($event) {
                          _vm.showForm = false
                        },
                        "on-success": _vm.handleFormSubmitSuccess,
                      },
                    })
                  : _c("BillingAndBankDetailsReadonly", {
                      attrs: {
                        "billing-mode": _vm.billingMode,
                        "invoice-language": _vm.invoiceLanguage,
                        "bank-account-list": _vm.bankAccountList,
                        "merchant-id": _vm.merchantId,
                      },
                      on: { "change-confirm": _vm.handleConfirm },
                    }),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }