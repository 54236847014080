var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mcp-admin-merchant-details-section" },
    [
      _c(
        "div",
        { staticClass: "mcp-admin-merchant-details-section__header" },
        [
          _vm._v("Billing Data"),
          _vm.readonly
            ? _c("span", [
                _vm.hasMarketBillingData
                  ? _c(
                      "span",
                      {
                        staticClass: "mcp-admin-merchant-detail__header-label",
                      },
                      [_vm._v("Sales channel specific data")]
                    )
                  : _vm._e(),
                _vm.showDataChangedLabel
                  ? _c(
                      "span",
                      {
                        staticClass: "mcp-admin-merchant-detail__header-label",
                      },
                      [_vm._v("Data for review")]
                    )
                  : _vm._e(),
              ])
            : !_vm.isFormVisible && !_vm.isDataLoading
            ? _c("ConfirmDataChange", {
                attrs: {
                  confirmCb: _vm.onEditHandler,
                  customEditText: _vm.hasMarketBillingData
                    ? ""
                    : "Use different billing data",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isDataLoading
        ? _c("div", { staticClass: "mcp-admin__spinner-container" }, [
            _c("div", {
              staticClass: "dc-spinner",
              attrs: { "data-testid": "billing-details-loading" },
            }),
          ])
        : _vm.isFormVisible
        ? _c("BillingDataForm", {
            attrs: {
              "country-name": _vm.countryName,
              "use-default": _vm.useMerchantBillingData,
              "is-submitting": _vm.isSubmitting,
              "billing-data": _vm.billingData,
              "has-market-billing-data": _vm.hasMarketBillingData,
            },
            on: { save: _vm.onSave, cancel: _vm.onFormCancel },
          })
        : _vm.hasEmptyData
        ? _c("div", [_vm._v("No billing data available")])
        : _c("BillingData", { attrs: { "billing-data": _vm.billingData } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }