import 'angulartics';
import 'angulartics-google-analytics';
import UiRouterIntegration from './UiRouterIntergration';

export default angular
  .module('mcb.components.analytics', [
    'angulartics',
    'angulartics.google.analytics',
  ])
  .service('$$mcbAnalyticsUiRouterIntegration', UiRouterIntegration)
  .config([
    '$analyticsProvider',
    function ($analyticsProvider) {
      UiRouterIntegration.config($analyticsProvider);
    },
  ])
  .run([
    '$$mcbAnalyticsUiRouterIntegration',
    function (uiRouterIntegration) {
      uiRouterIntegration.run();
    },
  ]);
