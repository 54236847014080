import axios from 'axios';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import ErrorHandler from 'Api/api-errors-handler';
import { pipe, path, prop } from 'ramda';

export interface ShipmentCarrierT {
  code: string;
  name: string;
}

export interface AvailableCarrierResponseItemT {
  id: string;
  countryCode: string;
  carrier: string;
  deliveryDestinationType: string;
  capabilities: string[];
  optionalCapabilities: string[];
}

export interface ConfiguredCarrierResponseItemT
  extends Omit<AvailableCarrierResponseItemT, 'optionalCapabilities'> {
  configuredOptionalDeliveryCapabilities: string[];
}

export interface CarrierRequestItemT {
  id: string;
  configuredOptionalDeliveryCapabilities: string[];
}

type DeliveryProductT = 'HOME' | 'PICKUP_POINT' | 'PACKSTATION';

export interface CarrierInfoT {
  shipmentCarrier: string;
  deliverySpeed: 'STANDARD';
  deliveryProducts: DeliveryProductT[];
}

export interface StocksRequirementsResponseT {
  carrierProductsSpecified: boolean;
  carrierProductsSynced: boolean;
}

const carriersApi = axios.create({
  baseURL: '/api/merchant-profile/shipment-carriers',
});

carriersApi.interceptors.request.use(decamelizeRequestHandler);
carriersApi.interceptors.response.use(
  pipe(camelizeResponseHandler, path(['data'])),
  ErrorHandler
);

const carrierInfoApi = axios.create({
  baseURL: '/api/merchant-profile/merchants',
});
carrierInfoApi.interceptors.request.use(decamelizeRequestHandler);
carrierInfoApi.interceptors.response.use(
  pipe(camelizeResponseHandler, path(['data'])),
  ErrorHandler
);

const availableCarriersApi = axios.create({
  baseURL: '/api/carrier-products/carrier-products/sales-channels',
});

availableCarriersApi.interceptors.request.use(decamelizeRequestHandler);
availableCarriersApi.interceptors.response.use(
  pipe(camelizeResponseHandler, path(['data'])),
  ErrorHandler
);

const configuredCarriersApi = axios.create({
  baseURL: '/api/carrier-products/merchants',
});
configuredCarriersApi.interceptors.request.use(decamelizeRequestHandler);
configuredCarriersApi.interceptors.response.use(
  pipe(camelizeResponseHandler, path(['data'])),
  ErrorHandler
);

export function fetchCarriers(
  salesChannelId: string
): Promise<ShipmentCarrierT[]> {
  return (
    carriersApi
      .get(`/?sales_channel_id=${salesChannelId}`)
      // @ts-ignore
      .then(prop('items')) as Promise<ShipmentCarrierT[]>
  );
}

export function fetchCarrierByCode(code: string): Promise<ShipmentCarrierT> {
  return carriersApi.get(`/${code}`);
}

export function fetchCarrierInfo(
  merchantId: string,
  salesChannelId: string
): Promise<CarrierInfoT | null> {
  return carrierInfoApi
    .get(`${merchantId}/sales-channels/${salesChannelId}/carrier-info`)
    .catch((err) => {
      return err.response?.status === 404 ? null : Promise.reject(err);
    }) as Promise<CarrierInfoT | null>;
}

export function putCarrierInfo(
  merchantId: string,
  salesChannelId: string,
  data: CarrierInfoT
): Promise<CarrierInfoT> {
  return carrierInfoApi.put(
    `${merchantId}/sales-channels/${salesChannelId}/carrier-info`,
    data
  );
}

export function fetchAvailableCarriersForSalesChannel(
  salesChannelId: string
): Promise<AvailableCarrierResponseItemT[]> {
  return (
    availableCarriersApi
      .get(`/${salesChannelId}`)
      // @ts-ignore
      .then(prop('items'))
  );
}

export function fetchConfiguredCarriers(
  merchantId: string,
  salesChannelId: string
): Promise<ConfiguredCarrierResponseItemT[] | null> {
  return (
    configuredCarriersApi
      .get(`${merchantId}/sales-channels/${salesChannelId}/delivery-products`)
      // @ts-ignore
      .then(prop('items'))
      .catch((err) => {
        return err.response?.status === 404 ? null : Promise.reject(err);
      }) as Promise<ConfiguredCarrierResponseItemT[] | null>
  );
}

export function putConfiguredCarriers(
  merchantId: string,
  salesChannelId: string,
  data: CarrierRequestItemT[]
): Promise<void> {
  return configuredCarriersApi.put(
    `${merchantId}/sales-channels/${salesChannelId}/delivery-products`,
    { items: data }
  );
}

export function fetchCarriersRequirements(
  merchantId: string,
  salesChannelId: string
): Promise<StocksRequirementsResponseT> {
  return configuredCarriersApi.get(
    `${merchantId}/sales-channels/${salesChannelId}/requirements`
  );
}
