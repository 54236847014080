<template lang="pug">
  div
    ReviewSectionTitle(v-if="!hideTitle" title="Billing Details")

    div.py-3
      div.field
        div.label.text-caption.text-uppercase Billing mode
        div {{billingMode | formatBillingMode}}

      div.field
        div.label.text-caption.text-uppercase Invoice Language
        div {{invoiceLanguage | formatInvoiceLanguage}}
</template>

<script lang="ts">
import Vue from 'vue';
import ReviewSectionTitle from 'Merchant/common/components/review-section-title/review-section-title.vue';
import { BillingModeT } from 'Api/models/BillingMode';
import { PreferredLanguageT } from 'Api/models/MerchantProfile';

export default Vue.extend({
  components: {
    ReviewSectionTitle,
  },
  props: {
    billingMode: {
      type: String as () => BillingModeT,
      required: true,
    },
    invoiceLanguage: {
      type: String as () => PreferredLanguageT,
      required: false,
      default: 'Not defined',
    },
    hideTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>
