/**
 * @name FirewallRule
 * @param {string} name
 * @param {Object} config
 *
 * @constructor
 */
function FirewallRule(name, config) {
  angular.extend(this, config);

  let _enabled = true;
  let _name = name;

  /**
   * FirewallRule#isEnabled
   * @returns {boolean}
   */
  this.isEnabled = function () {
    return _enabled === true;
  };

  /**
   * FirewallRule#isDisabled
   * @returns {boolean}
   */
  this.isDisabled = function () {
    return this.isEnabled() !== true;
  };

  /**
   * @name FirewallRule#disable
   */
  this.disable = function () {
    _enabled = false;
  };

  /**
   * @name FirewallRule#getName
   * @returns {string}
   */
  this.getName = function () {
    return _name;
  };
}

/**
 * @param name
 * @param config
 * @returns {FirewallRule}
 */
FirewallRule.create = function (name, config) {
  return new FirewallRule(name, config);
};

/**
 * Create from state firewalls definition
 * @param firewalls
 * @returns {Array<FirewallRule>}
 */
FirewallRule.createFromDefinitions = function (firewalls) {
  let rules = [];
  let rule;

  Object.keys(firewalls).forEach(function (name) {
    rule = FirewallRule.create(name, firewalls[name]);
    if (firewalls[name] === false) {
      rule.disable();
    }
    rules.push(rule);
  });

  return rules;
};

/**
 * @name FirewallManager
 * @param {mcb.components.router.mcbRouterUtil} mcbRouterUtil
 * @param {mcb.core.util.mcbReduceFrom} mcbReduceFrom
 * @param {FirewallRegistry} registry
 * @constructor
 */
function FirewallManager(mcbRouterUtil, reduceFrom, registry) {
  /**
   * @type {mcb.components.router.mcbRouterUtil}
   * @private
   */
  this._routerUtil = mcbRouterUtil;
  this._reduceFrom = reduceFrom;
  this._registry = registry;
}

/**
 * Get the parent state for a given state
 *
 * @private
 * @param value - a ui router state config like object
 * @returns {Object|undefined} -the parent ui router state config like object or undefined if not found
 */
FirewallManager.prototype.$getParentState = function (value) {
  return this._routerUtil.getParentStateRef(value);
};

/**
 * Get an array of firewall rules for this state
 *
 * @param {Object} state - an ui router state definition
 * @returns [Array<FirewallRule>]
 */
FirewallManager.prototype.getRules = function (state) {
  // extract all the rules traversing the tree by parent
  let rulesMap = this._reduceFrom(
    state,
    (value) => {
      return this.$getParentState(value);
    },
    (prev, curr) => {
      if (!curr || !curr.firewalls) {
        return prev;
      }
      return prev.concat(
        FirewallRule.createFromDefinitions(curr.firewalls).reverse()
      );
    },
    []
  )
    .reverse()
    // group rules by name
    .reduce(function (prev, curr, index, self) {
      if (prev[curr.getName()]) {
        return prev;
      }
      prev[curr.getName()] = self.filter(function (r) {
        return r.getName() === curr.getName();
      });
      return prev;
    }, {});

  // the last rule wins or check if we can merge (the firewall support merge)
  return Object.keys(rulesMap)
    .map(
      function (name) {
        let rules = rulesMap[name];

        // the last rule always win if is disabled
        if (rules[rules.length - 1].isDisabled()) {
          return rules[rules.length - 1];
        }
        if (this._registry.supportMerge(name)) {
          return new FirewallRule(name, this._registry.get(name).merge(rules));
        }

        // by default return the last rule
        return rules[rules.length - 1];

        // filter only enabled rules
      }.bind(this)
    )
    .filter(function (rule: any) {
      return rule.isEnabled();
    });
};

/**
 * @param {String} name
 * @param {Object} config
 * @returns {FirewallRule}
 */
FirewallManager.prototype.createRule = function (name, config) {
  return FirewallRule.create(name, config);
};

export default FirewallManager;
