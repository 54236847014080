<template lang="pug">
  div.mcp-admin-merchant-details-section-form
    p.dc-p
      strong Important:&nbsp;
      | This data will be used only for {{countryName}}

    form(novalidate)
      div.mcp-admin__form-field
        label.dc-label.dc-label--required(
          for="return-address-field-name"
          :class="{'dc-label--is-error': shouldShowFieldError('name')}"
        ) Name

        div.mcp-admin__field-error-message(role="alert" v-if="shouldShowFieldError('name')")
          span.dc--text-error Please don't leave this empty.

        input.dc-input.dc-input--block(
          type="text"
          :class="{'dc-input--is-error': shouldShowFieldError('name')}"
          v-model.trim="formData.name"
          name="name"
          id="return-address-field-name"
          required
        )

      div.mcp-admin__form-field
        label.dc-label.dc-label--required(
          for="return-address-field-address-line-1"
          :class="{'dc-label--is-error': shouldShowFieldError('addressLine1')}"
        ) Street and house number

        div.mcp-admin__field-error-message(role="alert" v-if="shouldShowFieldError('addressLine1')")
          span.dc--text-error Please don't leave this empty.

        input.dc-input.dc-input--block(
          type="text"
          :class="{'dc-input--is-error': shouldShowFieldError('addressLine1') }"
          v-model.trim="formData.addressLine1"
          name="street-and-house"
          id="return-address-field-address-line-1"
          required
        )

      div.mcp-admin__form-field
        label.dc-label(
          for="return-address-field-address-line-2"
        ) Address Line 2

        input.dc-input.dc-input--block(
          type="text"
          v-model.trim="formData.addressLine2"
          name="address-line-2"
          id="return-address-field-address-line-2"
          required
        )

      div.mcp-admin__form-field
        label.dc-label(
          for="return-address-field-address-line-3"
        ) Address Line 3

        input.dc-input.dc-input--block(
          type="text"
          v-model.trim="formData.addressLine3"
          name="address-line-3"
          id="return-address-field-address-line-3"
          required
        )

      div.mcp-admin__form-field
        label.dc-label.dc-label--required(
          for="return-address-field-zip"
          :class="{'dc-label--is-error': shouldShowFieldError('zipCode')}"
        ) Zip

        div.mcp-admin__field-error-message(role="alert" v-if="shouldShowFieldError('zipCode')")
          span.dc--text-error Please don't leave this empty.

        input.dc-input.dc-input--block(
          type="text"
          :class="{'dc-input--is-error': shouldShowFieldError('zipCode') }"
          v-model.trim="formData.zipCode"
          name="zip-code"
          id="return-address-field-zip"
          required
        )

      div.mcp-admin__form-field
        label.dc-label.dc-label--required(
          for="return-address-field-city"
          :class="{'dc-label--is-error': shouldShowFieldError('city')}"
        ) City

        div.mcp-admin__field-error-message(role="alert" v-if="shouldShowFieldError('city')")
          span.dc--text-error Please don't leave this empty.

        input.dc-input.dc-input--block(
          type="text"
          :class="{'dc-input--is-error': shouldShowFieldError('city') }"
          v-model.trim="formData.city"
          name="city"
          id="return-address-field-city"
          required
        )

      div.mcp-admin__form-field
        label.dc-label.dc-label--required(
          for="return-address-field-country"
          :class="{'dc-label--is-error': shouldShowFieldError('countryCode')}"
        ) Country

        div.mcp-admin__field-error-message(role="alert" v-if="shouldShowFieldError('countryCode')")
          span.dc--text-error Please don't leave this empty.

        select.dc-select.mcp-admin-merchant-details-select(
          :class="{'dc-input--is-error': shouldShowFieldError('countryCode')}"
          v-model.trim="formData.countryCode"
          name="country-code"
          id="return-address-field-country"
          required
        )
          option(v-for="country in countries" :value="country.code") {{country.name}}

    div.dc-row.dc-row--align--justify
      div.dc-column.dc-column--shrink
        button.dc-btn.dc-btn--link(
          type="button"
          v-if="address && address.source === 'SALES_CHANNEL'"
          @click="$emit('use-merchant-address')"
          :disabled="isSubmitting"
        )
          span Use return address from General Company Data
          i.dc-icon.dc-icon--undo.dc-icon--btn.dc-icon--btn--left

      div.dc-column.dc-column--shrink
        button.dc-btn.dc-btn--link(type="button" @click="$emit('cancel')" :disabled="isSubmitting") Cancel
        button.dc-btn.dc-btn--primary(type="button" @click="submit" :disabled="isSubmitting") Save
</template>
<script lang="ts">
import Vue from 'vue';
import { COUNTRIES } from 'Common/constants/misc.constant';

export default Vue.extend({
  props: {
    countryName: {
      type: String,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      required: true,
    },
    address: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      showFormErrors: false,
      countries: COUNTRIES,
    };
  },
  mounted() {
    if (this.address && this.address.source === 'SALES_CHANNEL') {
      this.formData = { ...this.address };
    }
  },
  methods: {
    isFieldEmpty(fieldName: string): boolean {
      const fieldValue = this.formData[fieldName];
      return !fieldValue || fieldValue.trim() === '';
    },
    shouldShowFieldError(fieldName: string): boolean {
      return this.showFormErrors && this.isFieldEmpty(fieldName);
    },
    submit(): void {
      this.showFormErrors = true;

      if (
        ['name', 'addressLine1', 'zipCode', 'city', 'countryCode'].some(
          (fieldName) => this.isFieldEmpty(fieldName)
        )
      ) {
        return;
      }

      this.$emit('submit', this.formData);
    },
  },
});
</script>
