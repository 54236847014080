import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import VueRouter from 'vue-router';
import routes from '@/vue-routes';

Vue.config.productionTip = false;

export default function wrapVue(VueComponent, name = 'Root') {
  const props = Object.keys(VueComponent.options.props || {});

  // Create a controller for the angular component
  class Ctrl {
    static $inject = ['$element', '$scope', '$state'];

    app: any; // Vue instance

    constructor(private $element, private $scope, private $state) {}

    $onInit(): void {
      const goto = this.$state.go.bind(this.$state);
      const getUrl = this.$state.href.bind(this.$state);

      // Initialise Vue app
      this.app = new Vue({
        // @ts-ignore
        vuetify,
        // @ts-ignore
        router: new VueRouter({ routes }),
        el: this.$element.children()[0],
        name,
        components: { VueComponent },
        data: {
          propsPassThrough: this.getData(),
          // @ts-ignore
          clientConfig: window.__MC_INITIAL_STATE__.clientConfig,
        },
        methods: {
          // Allow vue components to change state using ui-router $state's methods
          goto,
          getUrl,
        },
        render(h) {
          return h(VueComponent, { props: this.propsPassThrough });
        },
      });
    }

    $onChanges() {
      if (!this.app) {
        return;
      }

      // Give updated data to the Vue app
      this.app.propsPassThrough = this.getData();
    }

    $onDestroy() {
      // Destroy Vue app
      if (this.app) {
        this.app.$destroy();
      }
    }

    private getData() {
      return props.reduce(
        (res, prop) => ({ ...res, [prop]: this.wrapProp(this[prop], prop) }),
        {}
      );
    }

    /**
     * Some of the passed properties need special treatment
     */
    private wrapProp(val, name: string) {
      // if the passed property is not a function, then pass it throuh as it is.
      if (typeof val !== 'function') {
        return val;
      }

      // if the passed function's name starts either with 'is' or 'get',
      // then we assume there is no side effects and
      // its fine if Agnular knows nothing about it
      // and we can pass the function through as it is
      if (name.startsWith('is') || name.startsWith('get')) {
        return val;
      }

      // Otherwise we assume that the passed function might have some side affects
      // and therefore Angular should know about the function call in order to re-render
      // For that to happpen we need to wrap it into $applyAsync
      // (to call AngularJS's $digest cycle and let Angular know).
      return (...args) => this.$scope.$applyAsync(() => val(...args));
    }
  }

  return {
    bindings: props.reduce((res, prop) => ({ ...res, [prop]: '<' }), {}),
    controller: Ctrl,
    template: '<div></div>',
  };
}
