<template lang="pug">
div
  v-autocomplete(
    filled
    hide-no-data
    single-line
    clearable
    label="Select zDirect Merchant Admin"
    hint="Select zDirect Merchant Admin user"
    persistent-hint
    item-value="id"
    return-object
    autocomplete="off"
    :disabled="disabled"
    :items="contacts"
    :filter="filter"
    :menu-props="{ closeOnContentClick: true }"
    @input="onContactSelect"
    v-model="selectedContact"
    )
    template(#item="{ item }")
      span {{ item.firstName }} {{ item.lastName }} - {{ item.email }}

    template(#selection="{ item }")
      span {{ item.firstName }} {{ item.lastName }} - {{ item.email }}

    template(#append-item v-if="!isFormVisible")
      v-divider
      div.py-3.px-4
        span.body-2.font-weight-bold Correct contact not available?
        | &nbsp;
        a.body-2(href="#" @click.prevent="showForm") Add another contact

  Form(
    v-if="isFormVisible"
    @input="onContactChange"
    :disabled="disabled"
    :default-value="defaultValue"
  )
</template>

<script lang="ts">
import Vue from 'vue';
import LegalEntityContact from 'Api/models/LegalEntityContact';
import Form from '../contact-form/merchant-account-contact-form.vue';

export default Vue.extend({
  components: {
    Form,
  },
  props: {
    contacts: {
      type: Array as () => LegalEntityContact[],
      required: true,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedContact: null as LegalEntityContact | null,
      isFormVisible: false,
    };
  },
  watch: {
    // If contacts list gets updated and the selected contact is not there anymore,
    // then we shoud null it and emit an event
    contacts(value): void {
      if (!this.selectedContact || !this.selectedContact.id) {
        return;
      }

      const availableContactsIds = value.map((contact) => contact.id);

      if (!availableContactsIds.includes(this.selectedContact.id)) {
        this.selectedContact = null;
        this.$emit('input');
      }
    },
  },
  mounted() {
    if (this.defaultValue) {
      this.isFormVisible = true;
    }
  },
  methods: {
    filter(
      { firstName, lastName, email }: LegalEntityContact,
      queryText: string
    ): boolean {
      return `${firstName}${lastName}${email}`
        .toLowerCase()
        .includes(queryText.toLowerCase());
    },
    showForm(): void {
      this.selectedContact = null;
      this.isFormVisible = true;
      this.$emit('input');
    },
    onContactSelect(contact: LegalEntityContact): void {
      this.isFormVisible = false;
      this.$emit('input', contact);
    },
    onContactChange(contact: LegalEntityContact): void {
      this.$emit('input', contact);
    },
  },
});
</script>

<style lang="scss" scoped></style>
