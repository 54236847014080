import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    [
      _c(
        "Appbar",
        { attrs: { "hide-tabs": "" } },
        [
          _vm.merchantAccount
            ? _c(
                "BackLink",
                {
                  staticClass: "back-link",
                  attrs: { href: "/#/merchant-accounts" },
                },
                [_vm._v(_vm._s(_vm.merchantAccount.name))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        VMain,
        { staticStyle: { "background-color": "backgroundColor" } },
        [
          _c(
            VSnackbar,
            {
              attrs: { timeout: -1, bottom: "", color: "reject" },
              model: {
                value: _vm.hasError,
                callback: function ($$v) {
                  _vm.hasError = $$v
                },
                expression: "hasError",
              },
            },
            [
              _vm._v(_vm._s(this.errorMessage)),
              _vm.error && _vm.error.backLink
                ? _c(
                    "a",
                    {
                      staticClass: "text-center",
                      attrs: { href: _vm.error.backLink },
                    },
                    [
                      _c(
                        VBtn,
                        { staticClass: "ml-1", attrs: { text: "" } },
                        [_vm._v("Back")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VBtn,
                {
                  staticClass: "text-center ml-1",
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.error = null
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
          _vm.notify === "INVITE_SENT_SUCCESS"
            ? _c(
                VSnackbar,
                {
                  attrs: { color: "success", bottom: "", timeout: 2000 },
                  model: {
                    value: _vm.notify,
                    callback: function ($$v) {
                      _vm.notify = $$v
                    },
                    expression: "notify",
                  },
                },
                [
                  _vm._v("zDirect invite has been sent successfully."),
                  _c(
                    VBtn,
                    {
                      staticClass: "text-center ml-1",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.notify = null
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.loading
            ? _c(VContainer, [_c("PageLoader")], 1)
            : _c(
                VFlex,
                { attrs: { "d-flex": "", "fill-height": "" } },
                [
                  _c("SidePanel", {
                    attrs: {
                      merchantAccount: _vm.merchantAccount,
                      selectedLegalEntityId: _vm.selectedLegalEntityId,
                    },
                  }),
                  _c(VDivider, { attrs: { vertical: "" } }),
                  _c(
                    VContainer,
                    { staticClass: "px-6 pt-6" },
                    [
                      _c("mp-alert", { attrs: { type: "info" } }, [
                        _vm._v(
                          "Legal entities cannot be modified or deleted after zDirect invite has been sent."
                        ),
                      ]),
                      !_vm.invitationRequirements.requirementsCompleted &&
                      !_vm.isInvitationSent
                        ? _c("mp-alert", { attrs: { type: "info" } }, [
                            _c("span", [
                              _vm._v(
                                "Invitation cannot be sent because the below merchant/s are missing certain configurations. Please find below the required information:"
                              ),
                            ]),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.incompleteMerchants,
                                function (merchant) {
                                  return _c(
                                    "li",
                                    { key: merchant.businessPartnerId },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.getMerchantUrl(
                                              merchant.businessPartnerId
                                            ),
                                          },
                                        },
                                        [_vm._v(_vm._s(merchant.name))]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          ": " +
                                            _vm._s(
                                              _vm.getIncompleteMerchantReason(
                                                merchant
                                              )
                                            )
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "mt-6" },
                        [
                          _c("MerchantAccountDetails", {
                            attrs: {
                              merchantAccount: _vm.merchantAccount,
                              isInvitationSent: _vm.isInvitationSent,
                              isInvitationRequirementsCompleted:
                                _vm.invitationRequirements
                                  .requirementsCompleted,
                              inviteHidden:
                                !!_vm.merchantAccount.isConnectedRetailOnly,
                            },
                            on: {
                              "send-zdirect-invite": function ($event) {
                                return _vm.sendInvite()
                              },
                              "edit-details": _vm.onEdit,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }