<template lang="pug">
div.root.mcp-admin-merchant-details-section(:class="{'is-loading':isLoading}")
  div.mcp-admin-merchant-details-section__header Company Bank Data
    ConfirmDataChange(
      v-if="!isLoading && showEditLink"
      :confirmCb="onEditHandler"
      :customEditText="isMarketSpecific ? '' : 'Use Different Bank Account'"
    )

    span.mcp-admin-merchant-detail__header-label(v-if="showMarketSpecificLabel && isMarketSpecific")
      | Sales channel specific data
    span.mcp-admin-merchant-detail__header-label(v-if="showDataChangedLabel")
      | Data for review

  //- Loading indicator
  div.mcp-admin__spinner-container(v-if="isLoading" data-testid="loading")
      div.dc-spinner

  BankDataForm(
    v-else-if="isEditMode"
    :show-use-merchant-bank-data-link="isMarketSpecific"
    :channel-country="countryName"
    :is-submitting="isSubmitting"
    :initial-data="initialFormData"
    :company-name="companyName"
    @cancel="cancel"
    @save="save"
    @useMerchantData="useMerchantData"
  )

  div(v-else-if="!data") No bank account data available

  //- Read Mode
  div(v-else)
    div.field
      div.label Bank
      div {{ data.bankName }}

    div.field
      div.label IBAN
      div {{ iban }}

    div.field
      div.label SWIFT/BIC
      div {{ data.bic || 'Not defined' }}

    div.field
      div.label Account holder
      div {{ data.accountHolder }}

    div.field
      div.label Country
      div {{ country }}
</template>

<script lang="ts">
import Vue from 'vue';
import { pick } from 'ramda';
import {
  fetchBankData,
  useMerchantBankData,
  updateBankData,
} from 'Api/endpoints/sales-channel/bank.endpoint';
import { stringToIbanFormat } from 'Common/services/iban.helper';
import {
  COUNTRIES,
  USER_DID_NOT_CONFIRM_ERROR,
} from 'Common/constants/misc.constant';
import BankDataForm from './bank-data-form.container.vue';
import ConfirmDataChange from '@/common/components/confirm-data-change/confirm-data-change.vue';
import { sendUpdateMerchantDataReason } from '@/api/endpoints/merchant/merchant.endpoint.es';

export interface BankDataT {
  bankName: string;
  iban: string;
  bic: string;
  accountHolder: string;
  countryCode: string;
}

export const DEFAULT_DATA: BankDataT = {
  bankName: '',
  iban: '',
  bic: '',
  accountHolder: '',
  countryCode: '',
};

export default Vue.extend({
  components: {
    BankDataForm,
    ConfirmDataChange,
  },
  props: {
    merchantId: {
      type: String,
      required: true,
    },
    salesChannelId: {
      type: String,
      required: true,
    },
    fulfillmentType: {
      type: String,
      required: false,
      default: '',
    },
    companyName: {
      type: String,
      required: false, // required for non-readonly version only
      default: '',
    },
    countryName: {
      type: String,
      required: false, // required for non-readonly version only
      default: '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDataChangedLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMarketSpecificLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    getConfirm: {
      type: Function,
      required: false, // required for non-readonly version only
      default: null,
    },
  },

  data() {
    return {
      isLoading: true,
      isEditMode: false,
      isSubmitting: false,
      data: null,
      editReason: null,
    };
  },

  computed: {
    iban() {
      return stringToIbanFormat(this.data.iban);
    },
    country() {
      return COUNTRIES.find((country) => country.code === this.data.countryCode)
        .name;
    },
    isMarketSpecific(): boolean {
      return this.data && this.data.source === 'SALES_CHANNEL';
    },
    showEditLink(): boolean {
      return !this.readonly && !this.isEditMode;
    },
    initialFormData() {
      return this.isMarketSpecific ? { ...this.data } : { ...DEFAULT_DATA };
    },
  },

  mounted() {
    fetchBankData(this.merchantId, this.salesChannelId)
      .then((data) => {
        this.data = data;
      })
      .finally(() => (this.isLoading = false));
  },

  methods: {
    onEditHandler(reason: string) {
      this.editReason = reason;
      this.isEditMode = true;
    },
    useMerchantData(): void {
      this.isSubmitting = true;

      this.getConfirm()
        .then(() => useMerchantBankData(this.merchantId, this.salesChannelId))
        .then((data) => {
          this.data = data;
          this.isEditMode = false;
          this.$emit('save', 'Company Bank data were successfully saved');
        })
        .catch((err) => {
          if (err !== USER_DID_NOT_CONFIRM_ERROR) {
            this.$emit('error', 'Unable to save Bank Data');
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    cancel(): void {
      this.isEditMode = false;
      this.editReason = '';
    },
    save(data: BankDataT): void {
      this.isSubmitting = true;

      this.getConfirm()
        .then(() =>
          updateBankData(
            this.merchantId,
            this.salesChannelId,
            pick(
              ['bankName', 'iban', 'bic', 'accountHolder', 'countryCode'],
              data
            )
          )
        )
        .then((data) => {
          this.data = data;
          this.isEditMode = false;
          this.$emit('save', 'Company Bank data were successfully saved');
        })
        .catch((err) => {
          if (err !== USER_DID_NOT_CONFIRM_ERROR) {
            this.$emit('error', 'Unable to save Bank Data');
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });

      if (this.editReason) {
        sendUpdateMerchantDataReason(this.merchantId, this.editReason, {
          salesChannelId: this.salesChannelId,
          fulfilmentType: this.fulfillmentType,
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.root.is-loading {
  min-height: 250px;
}
.mcp-admin-merchant-details-section {
  border-bottom: 1px solid #d1d1d1;
}
.field {
  display: flex;
  margin-bottom: 18px;
}
.label {
  width: 145px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
}
.mcp-admin-merchant-details-section__header ::v-deep div.v-application--wrap {
  margin-top: 0;
  min-height: auto;
}
</style>
