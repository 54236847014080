import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VFlex,
    {
      staticClass: "align-center justify-end",
      attrs: { "d-flex": "", "data-testid": "pagination" },
    },
    [
      _c("div", { staticClass: "px-4 caption secondary--text text-no-wrap" }, [
        _vm._v("Rows per page:"),
      ]),
      _c(
        "div",
        { staticClass: "select-container" },
        [
          _c(VSelect, {
            ref: "pageSizeSelect",
            staticClass: "pt-5",
            attrs: {
              items: _vm.numberOfItemsPerPageOptions,
              value: _vm.defaultNumberOfItemsPerPage,
              dense: "",
            },
            on: { change: (val) => _vm.$emit("select-page-size", val) },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function ({ item }) {
                  return [
                    _c("div", { staticClass: "caption secondary--text" }, [
                      _vm._v(_vm._s(item)),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "from-to-indicator text-center caption secondary--text text-no-wrap",
        },
        [
          _c("span", { attrs: { "data-testid": "pagination-display-from" } }, [
            _vm._v(_vm._s(_vm.showingFrom)),
          ]),
          _vm._v("-"),
          _c("span", { attrs: { "data-testid": "pagination-display-to" } }, [
            _vm._v(_vm._s(_vm.showingTo)),
          ]),
          _vm._v(" of "),
          _c("span", { attrs: { "data-testid": "pagination-total-count" } }, [
            _vm._v(_vm._s(_vm.totalCount)),
          ]),
        ]
      ),
      !_vm.previousButtonDisabled
        ? _c(
            VBtn,
            {
              ref: "previous",
              attrs: { text: "", icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("previous-page")
                },
              },
            },
            [_c(VIcon, [_vm._v("mdi-chevron-left")])],
            1
          )
        : _vm._e(),
      _vm.previousButtonDisabled
        ? _c(
            VBtn,
            {
              staticStyle: { "pointer-events": "none" },
              attrs: { text: "", icon: "" },
            },
            [
              _c(VIcon, { attrs: { color: "#9b9b9b" } }, [
                _vm._v("mdi-chevron-left"),
              ]),
            ],
            1
          )
        : _vm._e(),
      !_vm.nextButtonDisabled
        ? _c(
            VBtn,
            {
              ref: "next",
              staticClass: "ml-4",
              attrs: { text: "", icon: "", disabled: _vm.nextButtonDisabled },
              on: {
                click: function ($event) {
                  return _vm.$emit("next-page")
                },
              },
            },
            [_c(VIcon, [_vm._v("mdi-chevron-right")])],
            1
          )
        : _vm._e(),
      _vm.nextButtonDisabled
        ? _c(
            VBtn,
            {
              staticClass: "ml-4",
              staticStyle: { "pointer-events": "none" },
              attrs: { text: "", icon: "" },
            },
            [
              _c(VIcon, { attrs: { color: "#9b9b9b" } }, [
                _vm._v("mdi-chevron-right"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }