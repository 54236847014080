import template from './contract-header.html';

export class ContractHeaderComponent implements ng.IComponentOptions {
  static Factory() {
    return new ContractHeaderComponent();
  }

  bindings = {
    title: '<',
  };

  controller = ContractHeaderComponentController;
  controllerAs = 'ctrl';
  template: string = template;
}

export class ContractHeaderComponentController implements ng.IController {
  static $inject = [];

  title: string;

  $onInit() {
    // Empty block
  }
}
