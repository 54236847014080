<template lang="pug">
  div.mcp-admin-merchant-details-section
    div.mcp-admin-merchant-details-section__header Billing Data
      //- Financial approver mode
      span(v-if="readonly")
        span.mcp-admin-merchant-detail__header-label(v-if="hasMarketBillingData")
          | Sales channel specific data
        span.mcp-admin-merchant-detail__header-label(v-if="showDataChangedLabel")
          | Data for review

      //- Edit mode
      ConfirmDataChange(
        v-else-if="!isFormVisible && !isDataLoading"
        :confirmCb="onEditHandler"
        :customEditText="hasMarketBillingData ? '' : 'Use different billing data'"
      )


    div.mcp-admin__spinner-container(v-if="isDataLoading")
      div.dc-spinner(data-testid="billing-details-loading")

    BillingDataForm(
      v-else-if="isFormVisible"
      :country-name="countryName"
      :use-default="useMerchantBillingData"
      :is-submitting="isSubmitting"
      @save="onSave"
      @cancel="onFormCancel"
      :billing-data="billingData"
      :has-market-billing-data="hasMarketBillingData"
    )

    div(v-else-if="hasEmptyData") No billing data available

    BillingData(
      v-else
      :billing-data="billingData"
    )
</template>

<script lang="ts">
import Vue from 'vue';
import BillingDataForm from 'Merchant/common/components/billing-data-form/billing-data-form.vue';
import BillingData from 'Merchant/common/components/billing-data/billing-data.vue';
import ConfirmDataChange from '@/common/components/confirm-data-change/confirm-data-change.vue';
import {
  fetchBillingDetails,
  useMerchantBillingDetails,
  updateBillingDetails,
} from 'Api/endpoints/sales-channel/billing.endpoint';
import { USER_DID_NOT_CONFIRM_ERROR } from 'Common/constants/misc.constant';
import { sendUpdateMerchantDataReason } from '@/api/endpoints/merchant/merchant.endpoint.es';

export default Vue.extend({
  components: {
    BillingDataForm,
    BillingData,
    ConfirmDataChange,
  },

  props: {
    salesChannelId: {
      type: String,
      required: true,
    },
    merchantId: {
      type: String,
      required: true,
    },
    fulfillmentType: {
      type: String,
      required: true,
    },
    getConfirm: {
      type: Function,
      required: false,
      default: null,
    },
    countryName: {
      type: String,
      required: true,
    },
    showDataChangedLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isDataLoading: true,
      billingData: null,
      isFormVisible: false,
      hasEmptyData: true,
      isSubmitting: false,
      editReason: '',
    };
  },

  computed: {
    hasMarketBillingData: function () {
      return this.billingData?.source === 'SALES_CHANNEL';
    },
  },

  mounted() {
    fetchBillingDetails(this.merchantId, this.salesChannelId)
      .then((response) => {
        this.billingData = response;
        // In a special case, the response do not contain the fulfilment type billing details for market specific data
        this.hasEmptyData = !response.billingCurrency;
      })
      .catch((err) => {
        if (err.response.status === 404) {
          this.hasEmptyData = true;
        }
      })
      .finally(() => {
        this.isDataLoading = false;
      });
  },

  methods: {
    onEditHandler(reason: string) {
      this.editReason = reason;
      this.isFormVisible = true;
    },
    onFormCancel() {
      this.isFormVisible = false;
      this.editReason = '';
    },
    useMerchantBillingData() {
      this.isSubmitting = true;
      this.getConfirm() // TODO: Investigate porting this to vue js
        .then(() => {
          return useMerchantBillingDetails(
            this.merchantId,
            this.salesChannelId
          );
        })
        .then((response) => {
          this.billingData = response;
          this.isFormVisible = false;
          this.$emit('save', 'Company billing data were successfully saved');
        })
        .catch((error) => {
          if (error !== USER_DID_NOT_CONFIRM_ERROR) {
            this.$emit('error', 'Unable to save Billing Data');
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    onSave(billingData) {
      this.isSubmitting = true;
      this.getConfirm()
        .then(() => {
          return updateBillingDetails(this.merchantId, this.salesChannelId, {
            ...billingData,
            source: 'SALES_CHANNEL',
          });
        })
        .then((response) => {
          this.billingData = response;
          this.isFormVisible = false;
          this.$emit('save', 'Company billing data were successfully saved');
        })
        .catch((err) => {
          if (err !== USER_DID_NOT_CONFIRM_ERROR) {
            this.$emit('error', 'Unable to save Billing Data');
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
      if (this.editReason) {
        sendUpdateMerchantDataReason(this.merchantId, this.editReason, {
          fulfilmentType: this.fulfillmentType,
          salesChannelId: this.salesChannelId,
        });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.mcp-admin-merchant-details-section__header ::v-deep div.v-application--wrap {
  margin-top: 0;
  min-height: auto;
}
</style>
