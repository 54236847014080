<template lang="pug">
div.silhouette-field-wrapper.flex-grow-1
  v-text-field(
    v-model="search"
    hint="Select silhouettes which the partner is allowed to sell"
    persistent-hint
    filled
    ref="textfield"
    :label="label"
    :disabled="readonly"
    :append-icon="inputIcon"
    @focus="onFocus"
    @blur="onBlur"
  )

  v-menu(
    v-if="el"
    v-model="menuOpened"
    max-height="350"
    disable-keys
    :nudge-top="-58"
    :open-on-click="false"
    :close-on-content-click="false"
    :attach="el"
    @update:returnValue="onMenuClose"
    )
    v-sheet()
      v-treeview(
        light
        selectable
        selected-color="primary"
        hoverable
        transition
        open-on-click
        :items="attributesTree"
        :search="search"
        :value="localValue"
        @input="onInput"
      )
        template(v-slot:label="{ item, selected }")
          div.tree-item-label-wrapper(@click="onItemClick(item, selected)")
            label.tree-item-label.v-label.theme--light {{item.name}}

  div.green--text(v-if="valuesAdded.length")
    span.font-weight-bold.mr-1 Added ({{valuesAdded.length}}):
    span.font-weight-medium {{ valuesAdded.join(', ') }}
  div.red--text(v-if="valuesRemoved.length")
    span.font-weight-bold.mr-1 Removed ({{valuesRemoved.length}}):
    span.font-weight-medium {{ valuesRemoved.join(', ') }}
</template>

<script lang="ts">
import Vue from 'vue';
import {
  difference,
  equals,
  groupBy,
  prop,
  map,
  mapObjIndexed,
  values,
  pipe,
} from 'ramda';

const categoryPrefix = 'category-';

export default Vue.extend({
  props: {
    attributes: {
      type: Array as () => Array<any>,
      required: true,
    },
    value: { type: Array as () => Array<string>, required: true },
    savedValue: {
      type: Array,
      required: false,
      default: () => [],
    },
    readonly: Boolean,
  },
  data() {
    return {
      search: '',
      menuOpened: false,
      localValue: [...this.value],
      el: null,
      inputIcon: 'arrow_drop_down',
    };
  },
  computed: {
    label(): string {
      return `Silhouette (${this.localValue.length} selected)`;
    },
    valuesAdded() {
      return difference(this.value, this.savedValue).sort();
    },
    valuesRemoved() {
      return difference(this.savedValue, this.value).sort();
    },
    /**
     * Present attributes list as a tree of categories
     * and silhouettes as children nodes
     */
    attributesTree() {
      const unifyMapFn = map(({ label, name, category }) => ({
        id: label,
        name,
        category,
      }));
      // @ts-ignore
      const groupByCatFn = groupBy(prop('category'));
      const mapFn = mapObjIndexed((val, i) => ({
        id: `${categoryPrefix}${i}`,
        name: i,
        children: val,
      }));
      // @ts-ignore
      return pipe(unifyMapFn, groupByCatFn, mapFn, values)(this.attributes);
    },
  },

  watch: {
    /**
     * Assign the new value only if it really changed
     */
    value(value): void {
      if (!equals(this.localValue, value)) {
        this.localValue = [...value];
      }
    },
    menuOpened(opened): void {
      if (!opened) {
        this.onBlur();
      }
    },
  },

  mounted() {
    this.el = this.$refs.textfield.$el;
  },

  methods: {
    onMenuClose(val): void {
      this.menuOpened = false;
      this.search = '';
      this.$refs.textfield.blur();
    },
    onFocus(): void {
      this.inputIcon = 'arrow_drop_up';

      if (!this.menuOpened) {
        // Without delay it doesn't work, because of the menu's intervening close-on-click option
        setTimeout(() => (this.menuOpened = true), 150);
      }
    },
    onBlur(): void {
      this.inputIcon = 'arrow_drop_down';
      if (this.menuOpened) {
        this.$refs.textfield.focus();
      } else {
        this.$refs.textfield.blur();
      }
    },
    /**
     * Emit the new value only if it changed
     */
    onInput(values): void {
      const filteredValues = this.filterCategories(values);

      if (!equals(filteredValues, this.value)) {
        this.$emit('input', filteredValues);
      }
    },
    filterCategories(values: Array<string>): Array<string> {
      return values.filter((item) => !item.startsWith(categoryPrefix));
    },
    /**
     * Toggle the item selection on click
     */
    onItemClick(item, selected): void {
      // Do nothing if it's just a category
      if (item.id.startsWith(categoryPrefix)) {
        return;
      }

      const valueSet = new Set(this.value);

      // Toggle value selection
      if (valueSet.has(item.id)) {
        valueSet.delete(item.id);
      } else {
        valueSet.add(item.id);
      }

      // @ts-ignore
      this.$emit('input', [...valueSet]);
    },
  },
});
</script>

<style lang="scss" scoped>
.silhouette-field-wrapper {
  .v-menu__content {
    min-width: 100% !important;
  }
}
.tree-item-label {
  font-weight: 400;
}
.tree-item-label-wrapper {
  padding: 12px 0;
}
</style>
