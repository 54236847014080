import template from './root.html';
import { mcb } from 'mcb';
import { HealthJSON } from 'Api/models/HealthJSON';

export const VERSION_CHECK_INTERVAL = 5 * 60 * 1000; // 5 min

export class RootComponent implements ng.IComponentOptions {
  static Factory() {
    return new RootComponent();
  }

  controller = MerchantsProfileController;
  controllerAs = 'ctrl';
  template: string = template;
}

class MerchantsProfileController {
  static $inject = ['$http', '$window', '$q', '$interval', 'mcbToast'];

  newVersionsCheck: any;
  intervalPromise: ng.IPromise<any>;

  constructor(
    private $http: ng.IHttpService,
    private $window: ng.IWindowService,
    private $q: ng.IQService,
    private $interval: ng.IIntervalService,
    private toast: mcb.IToast
  ) {}

  $onInit(): void {
    this.newVersionsCheck =
      this.$window.__MC_INITIAL_STATE__.clientConfig.newVersionsCheck;

    if (this.newVersionsCheck.status) {
      this.loadManifests().then(this.watchForNewVersions.bind(this));
    }
  }

  $onDestroy(): void {
    const { intervalPromise, $interval } = this;

    if (!intervalPromise) {
      return;
    }

    $interval.cancel(intervalPromise);
  }

  /**
   * Load manifests of the Fronted Server and Backend Server.
   *
   * We need those manifests because they contain versions
   */
  loadManifests(): ng.IPromise<any> {
    const { $q, $http } = this;
    const urls = this.newVersionsCheck.manifestUrls;

    return $q.all({
      clientVersion: $http
        .get(urls.client)
        .then((res) => res.data)
        .then((res: HealthJSON) => res.commitId),
      backendVersion: $http
        .get(urls.backend)
        .then((res) => res.data)
        .then((res: HealthJSON) => res.commitId),
    });
  }

  /**
   * Watch for new versions of the Frontend Server and Backend Server
   *
   * Notify the user in case of changes
   * @param clientVersion
   * @param backendVersion
   */
  watchForNewVersions({ clientVersion, backendVersion }): void {
    const { $interval, toast } = this;

    this.intervalPromise = $interval(
      () =>
        this.loadManifests().then(
          ({
            clientVersion: newClientVersion,
            backendVersion: newBackendVersion,
          }) => {
            // Do nothing if versions have not been changed
            if (
              newClientVersion === clientVersion &&
              newBackendVersion === backendVersion
            ) {
              return;
            }

            toast.warning({
              content:
                'New version of Merchant Profile is available. Please reload the page',
              dismissOnTimeout: false,
            });

            // Turn off the watcher
            $interval.cancel(this.intervalPromise);
          }
        ),
      VERSION_CHECK_INTERVAL
    );
  }
}
