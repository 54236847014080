import { render, staticRenderFns } from "./legal-entity.vue?vue&type=template&id=a4d34386&scoped=true&lang=pug"
import script from "./legal-entity.vue?vue&type=script&lang=ts"
export * from "./legal-entity.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4d34386",
  null
  
)

export default component.exports