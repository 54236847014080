var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    {
      staticClass: "dc-status mcp-admin-contract-status",
      class: _vm.statusCssClass,
    },
    [
      _c("span", { staticClass: "mcp-admin-contract-status__label" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }