function deepFind(obj, path) {
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, ''); // strip a leading dot
  let a = path.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    try {
      if (k in obj) {
        obj = obj[k];
      } else {
        return;
      }
    } catch (e) {
      return;
    }
  }
  return obj;
}

export default function mcbConfigProvider() {
  function Configurator(config) {
    this._config = config;
  }

  Configurator.prototype.get = function (path) {
    if (!path) {
      return angular.extend({}, this._config);
    }

    const value = deepFind(angular.extend({}, this._config), path);

    if (!angular.isUndefined(value)) {
      return value;
    } else {
      throw (
        '[mcbConfig] path: "' +
        path +
        '" doesn\'t exist or the value is undefined!'
      );
    }
  };

  let provider = this;
  let pluginConfigurators = {};

  provider.setMainConfigurator = function (config) {
    provider.$$mainConfigurator = new Configurator(config);
    return this;
  };

  provider.addPluginConfigurator = function (name, config) {
    pluginConfigurators[name] = new Configurator(config);
    return this;
  };

  provider.get = function (path) {
    return provider.$$mainConfigurator.get(path);
  };

  provider.plugin = function (name) {
    if (!name) {
      return pluginConfigurators;
    }
    if (!pluginConfigurators[name]) {
      throw 'Package configurator for name: ' + name + " doesn't exists!";
    }
    return pluginConfigurators[name];
  };

  // FIXME: deprecated
  provider.package = provider.plugin.bind(provider);

  // FIXME: deprecated
  provider.addPackageConfigurator =
    provider.addPluginConfigurator.bind(provider);

  /**
   * @ngdoc factory
   * @name mcbConfig
   */
  provider.$get = function () {
    return {
      get: provider.get.bind(provider),
      plugin: provider.plugin.bind(provider),
      // FIXME: deprecated
      package: provider.plugin.bind(provider),
    };
  };

  return provider;
}
