var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "root mcp-admin-merchant-details-section",
      class: { "is-loading": _vm.isLoading },
    },
    [
      _c(
        "div",
        { staticClass: "mcp-admin-merchant-details-section__header" },
        [
          _vm._v("Company Contacts"),
          !_vm.readonly && !_vm.isEditMode
            ? _c("ConfirmDataChange", {
                attrs: {
                  confirmCb: _vm.onEditHandler,
                  customEditText: _vm.isMarketSpecific
                    ? ""
                    : "Use Different Contact",
                },
              })
            : _vm._e(),
          _vm.showMarketSpecificLabel && _vm.isMarketSpecific
            ? _c(
                "span",
                { staticClass: "mcp-admin-merchant-detail__header-label" },
                [_vm._v("Sales channel specific data")]
              )
            : _vm._e(),
          _vm.showDataChangedLabel
            ? _c(
                "span",
                {
                  staticClass: "mcp-admin-merchant-detail__header-label",
                  attrs: { "data-testid": "data-change-label" },
                },
                [_vm._v("Data for review")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isLoading
        ? _c(
            "div",
            {
              staticClass: "mcp-admin__spinner-container",
              attrs: { "data-testid": "loading-indicator" },
            },
            [_c("div", { staticClass: "dc-spinner" })]
          )
        : _vm.isEditMode
        ? _c("ContactsForm", {
            attrs: {
              "data-testid": "contacts-form",
              "is-submitting": _vm.isSubmitting,
              "initial-contacts": _vm.contacts,
              "show-use-merchant-contacts-link": _vm.isMarketSpecific,
              "is-market": "",
            },
            on: {
              cancel: _vm.cancel,
              save: _vm.save,
              "form-changed": function ($event) {
                _vm.formChanged = true
              },
              useMerchantContacts: _vm.useMerchantContacts,
            },
          })
        : _vm.contacts.length === 0
        ? _c("div", { attrs: { "data-testid": "no-contacts" } }, [
            _vm._v("No contacts data available"),
          ])
        : _c(
            "div",
            _vm._l(_vm.groupedContacts, function (cGroup) {
              return _c(
                "div",
                {
                  staticClass: "contact-group",
                  attrs: { "data-testid": "cgroup" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "dc-h4 ctype-label",
                      attrs: { "data-testid": "cgroup-title" },
                    },
                    [_vm._v(_vm._s(_vm.typeLabels[cGroup.ctype]))]
                  ),
                  _c(
                    "div",
                    _vm._l(cGroup.items, function (contact) {
                      return _c(
                        "div",
                        {
                          staticClass: "contact",
                          attrs: { "data-testid": "contact" },
                        },
                        [
                          _c("div", { staticClass: "contact-field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("First name"),
                            ]),
                            _c("div", [_vm._v(_vm._s(contact.firstName))]),
                          ]),
                          _c("div", { staticClass: "contact-field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Last name"),
                            ]),
                            _c("div", [_vm._v(_vm._s(contact.lastName))]),
                          ]),
                          _c("div", { staticClass: "contact-field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Email"),
                            ]),
                            _c("div", [_vm._v(_vm._s(contact.email))]),
                          ]),
                          _c("div", { staticClass: "contact-field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Phone"),
                            ]),
                            _c("div", [_vm._v(_vm._s(contact.phone || "-"))]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            }),
            0
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }