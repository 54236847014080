import template from './configuration-readonly.html';
import { Configuration } from 'Api/models/Configuration';
import { ConfigFieldsVisibilityT } from 'Merchant/contract/configuration.helper';

export class ContractConfigurationReadonlyComponent
  implements ng.IComponentOptions
{
  static Factory() {
    return new ContractConfigurationReadonlyComponent();
  }

  bindings: { [binding: string]: string } = {
    configuration: '<',
    fieldsVisibility: '<',
    partners: '<',
    partner: '<',
    onPartnerChange: '<',
    integrationTypeLabel: '<',
    isZfs: '<',
  };
  controller = ContractConfigurationReadonlyController;
  controllerAs = 'ctrl';
  template: string = template;
}

class ContractConfigurationReadonlyController implements ng.IController {
  configuration: Configuration;
  fieldsVisibility: ConfigFieldsVisibilityT | null;

  $onInit() {
    // Empty block
  }
}
