import { Address } from 'Api/models/Address';

export enum ValidationResultT {
  VALID = 'VALID',
  INVALID = 'INVALID',
  NOT_VALIDATED = 'NOT_VALIDATED',
}

export interface VatAddressT extends Address {
  certificateId?: string;
  validationResult?: ValidationResultT;
}

export interface VatDetailsT {
  vatId: string;
  certificateId: string;
  validationResult: ValidationResultT;
  lastValidatedAt: string;
}

export interface VatGroupDetailsT {
  belongsToVatGroup?: boolean;
  name?: string;
  certificateId?: string;
}

export interface VatCertificateDetailsT {
  certificateId: string;
}

export interface VatCertificateMetaDataResponseT {
  certificateId: string;
  filename: string;
}
