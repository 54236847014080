import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "a",
    { attrs: { href: _vm.href } },
    [
      _c(
        VFlex,
        { attrs: { "d-flex": "" } },
        [
          _c(
            VIcon,
            { staticClass: "mr-5 mb-1", attrs: { color: "secondary" } },
            [_vm._v("mdi-arrow-left")]
          ),
          _c(
            "span",
            { staticClass: "title secondary--text" },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }