<template lang="pug">
div(style="width:100%")
  VAutocomplete.field-wrapper(
    filled
    attach
    multiple
    no-data-text="No items found"
    :messages="helpText"
    :item-value="valueProp"
    :item-text="nameProp"
    :value="value"
    :search-input.sync="search"
    :items="computedAttributes"
    :label="label"
    :disabled="readonly"
    @input="$emit('input', $event)"
    @change="onBrandChange"
    ref="autocomplete"
    v-on:keydown.delete="onBackspaceClicked"
  )
    //- A slot for displaying selected items in the input field.
    //- Items in this slot should only be selected in the menu is hidden.
    //- Otherwise the field should be used as a search field.
    template(v-slot:selection="{ item, index }")
      template(v-if="!isMenuActive()")
        template(v-if="index > 0") ,
        | {{ getItemLabel(item) }}

  div.green--text(v-if="valuesAdded.length")
    span.font-weight-bold.mr-1 Added ({{valuesAdded.length}}):
    span.font-weight-medium {{ valuesAdded.join(', ') }}
  div.red--text(v-if="valuesRemoved.length")
    span.font-weight-bold.mr-1 Removed ({{valuesRemoved.length}}):
    span.font-weight-medium {{ valuesRemoved.join(', ') }}
</template>

<script lang="ts">
import Vue from 'vue';
import { difference } from 'ramda';

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    savedValue: {
      type: Array,
      required: false,
      default: () => [],
    },
    attributes: {
      type: Array,
      required: true,
    },
    helpText: {
      type: String,
      required: true,
    },
    valueProp: {
      type: String,
      default: 'value',
    },
    nameProp: {
      type: [String, Function],
      default: 'name',
    },
    readonly: Boolean,
    onBrandChange: {
      type: Function,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      search: '',
    };
  },

  computed: {
    label() {
      const { name, value } = this;
      return value.length ? `${name} (${value.length} selected)` : name;
    },
    valuesAdded() {
      return difference(this.value, this.savedValue).sort();
    },
    valuesRemoved() {
      return difference(this.savedValue, this.value).sort();
    },
    computedAttributes() {
      const { attributes, valueProp, value } = this;
      const itemIncludedFn = (item) => value.includes(item[valueProp]);
      const itemNotIncludedFn = (item) => !value.includes(item[valueProp]);
      const selected = attributes.filter(itemIncludedFn);
      const unselected = attributes.filter(itemNotIncludedFn);
      return [...selected, ...unselected];
    },
  },
  watch: {
    // fix from: https://github.com/vuetifyjs/vuetify/issues/11969
    value: function (newVal) {
      try {
        this.changedListItemIndex = this.getChangedListItemIndex(newVal);
      } catch {
        this.changedListItemIndex = -1;
      }
      this.$nextTick(() => {
        this.$refs.autocomplete.setMenuIndex(this.changedListItemIndex);
      });
    },
  },

  methods: {
    getChangedListItemIndex(newVal) {
      let lastChangedValue = newVal[newVal.length - 1];
      return this.computedAttributes.findIndex(
        (item) => item[this.valueProp] === lastChangedValue
      );
    },
    getItemLabel(item): string {
      // @ts-ignore
      const { nameProp } = this;
      return typeof nameProp === 'function' ? nameProp(item) : item[nameProp];
    },
    isMenuActive(): boolean {
      const { autocomplete } = this.$refs;
      // @ts-ignore
      return !!(autocomplete && autocomplete.isMenuActive);
    },
    onBackspaceClicked() {
      if (this.search === '' || this.search === null) {
        this.$refs.autocomplete.blur();
      }
    },
  },
});
</script>

<style lang="scss">
// Long names in the dropdown should be trimmed and display ellipsis
.app-msel-chbox {
  width: 100%;
  .v-input__control {
    width: 100%;
  }
  &.v-input--selection-controls.v-input .v-label {
    display: block;
  }
}

.field-wrapper .v-select__selections {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100px;
}
</style>
