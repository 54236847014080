import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VCard,
    { attrs: { "min-height": "136px", flat: "", outlined: "" } },
    [
      _c(
        VFlex,
        { staticClass: "px-6", attrs: { "fill-height": "" } },
        [
          _c("div", { staticClass: "pt-6 pb-4" }, [
            _c(
              "a",
              {
                staticClass:
                  "headline primary--text font-weight-medium pl-0 pr-4",
                attrs: { href: _vm.account.href },
              },
              [_vm._v(_vm._s(_vm.account.name))]
            ),
            _c("span", { staticClass: "caption disabled--text" }, [
              _vm._v(
                "Last modified on " +
                  _vm._s(_vm._f("formatDate")(_vm.account.modifiedAt)) +
                  " by " +
                  _vm._s(_vm.account.modifiedBy)
              ),
            ]),
          ]),
          _c("div", { staticClass: "caption disabled--text" }, [
            _vm._v("Legal Entities"),
          ]),
          _c(
            VFlex,
            { staticClass: "flex-wrap pt-4 pb-6", attrs: { "d-flex": "" } },
            _vm._l(_vm.account.legalEntities, function ({ name, href }) {
              return _c(
                "div",
                {
                  ref: "legalEntitiesContainer",
                  refInFor: true,
                  staticClass: "mr-6",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "body-2 primary--text",
                      attrs: { href: href },
                    },
                    [_vm._v(_vm._s(name))]
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }