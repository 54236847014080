var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "VatReviewSection",
    { attrs: { title: _vm.title, status: "PENDING" } },
    [
      _c("div", { staticClass: "mb-3 text-body-1" }, [
        _vm._v(_vm._s(_vm.infoMessage)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }