import { NavbarLoadingTrackerComponent } from './navbar-loading-tracker.component';
import { NavbarComponent } from './navbar.component';

export default angular
  .module('mc.navbar', ['mcb', 'mc.authentication'])
  .component('mcNavbarLoadingTracker', NavbarLoadingTrackerComponent.Factory())
  .component('mcNavbar', NavbarComponent.Factory())
  .config([
    '$stateProvider',
    ($stateProvider): void => {
      // main state for the mc portal views
      $stateProvider.state('mc', {
        abstract: true,
        template: `
          <mc-navbar-loading-tracker delay="2500"></mc-navbar-loading-tracker>
          <mc-navbar ng-if="ctrl.isOldHeaderVisible()"></mc-navbar>
          <div ng-class="{\'mc-view-container\':ctrl.isOldHeaderVisible()}">
            <div ui-view id="mc-view"></div>
          </div>
          `,
        controller: [
          '$state',
          function ($state: any): void {
            this.isOldHeaderVisible = (): boolean =>
              !$state.is('mcp.admin.legal-entity-details') &&
              !$state.is('mcp.admin.merchants') &&
              !$state.is('mcp.admin.merchant-accounts') &&
              !$state.is('mcp.admin.merchant-account-edit') &&
              !$state.is('mcp.admin.merchant-account-details') &&
              !$state.is('mcp.admin.merchant-account-create');
          },
        ],
        controllerAs: 'ctrl',
        firewalls: {
          iam: {}, // by default iam firewall active
        },
      });

      $stateProvider.state('mc-public', {
        abstract: true,
        template: '<div ui-view id="mc-public-view"></div>',
      });

      // main state for the mc packages inside the portal
      $stateProvider.state('mcp', {
        abstract: true,
        parent: 'mc',
        template: '<div ui-view id="mcp-view"></div>',
      });

      $stateProvider.state('mcp-public', {
        abstract: true,
        parent: 'mc-public',
        template: '<div ui-view id="mcp-public-view"></div>',
      });
    },
  ]);
