import { ICountry } from 'mcb';
import { BankAccount } from 'Api/models/BankAccount';
import { COUNTRIES } from 'Common/constants/misc.constant';
import template from './bank-account-form.html';

export class BankAccountFormComponent implements ng.IComponentOptions {
  static Factory() {
    return new BankAccountFormComponent();
  }

  bindings: { [binding: string]: string } = {
    bankAccount: '<',
    vatId: '<', // this is optional prop. Only Merchants provide vatId, not Contracts.
    taxId: '<', // this is optional prop. Only Merchants provide taxId, not Contracts.
    showTaxVat: '<', // We need to display taxId/vatId fields only on Merchant Level, not Contract Level
    onSave: '<',
    onCancel: '<',
    onUseDefault: '&?',
    companyName: '<',
    companyCountry: '<', // this is optional prop. It makes sense only when showTaxVat is True, that is on Merchant level
    retailerName: '<',
    countryName: '<',
    isVatEnabled: '<',
  };

  controller = BankAccountFormController;
  controllerAs = 'ctrl';
  template: string = template;
}

class BankAccountFormController implements angular.IComponentController {
  static $inject = [];

  // injected by component
  bankAccount: BankAccount;
  vatId: string;
  taxId: string;
  showTaxVat: boolean;
  contractId: string;
  bankAccountForm: ng.IFormController;
  onSave: Function;
  onCancel: Function;
  onUseDefault: Function;
  companyName: string;
  companyCountry: string;
  countries: ICountry[];
  retailerName: string;
  countryName: string;
  isVatEnabled: boolean;

  formData: { bankAccount?: BankAccount; vatId: string; taxId: string };

  constructor() {
    this.countries = [...COUNTRIES];
  }

  /**
   * $onInit - angular's lifecycle method
   *
   * Here we are creating a scoped copy of merchant object to simulate a one-time (+one-way) binding,
   * so that editing properties in the scoped object doesn't change the property in parent controller.
   */
  $onInit(): void {
    const { vatId = '', taxId = '', bankAccount = {} } = this;

    this.formData = {
      vatId,
      taxId,
      bankAccount: { ...bankAccount } as BankAccount,
    };
  }

  save(): void {
    if (!this.isVatEnabled && this.showTaxVat) {
      // Set 'mcpOneRequired' validity status on taxId/vatId fields
      this.onTaxVatChange();
    }

    if (this.bankAccountForm.$invalid) {
      return;
    }

    const { bankAccount, vatId, taxId } = this.formData;
    const params = this.showTaxVat
      ? [bankAccount, vatId.replace(/\s/g, ''), taxId.replace(/\s/g, '')]
      : [bankAccount];

    this.onSave(...params);
  }

  cancel(): void {
    this.onCancel();
  }

  useDefault(): void {
    this.onUseDefault();
  }

  isFieldErrorDisplayed(field): boolean {
    const form = this.bankAccountForm;
    return form.$submitted && form[field].$invalid;
  }

  /**
   * Verify that either taxId or vatId are filled
   */
  onTaxVatChange() {
    const { taxId, vatId } = this.bankAccountForm;
    const oneFilled = !!(taxId.$viewValue.trim() || vatId.$viewValue.trim());
    taxId.$setValidity('mcpOneRequired', oneFilled);
    vatId.$setValidity('mcpOneRequired', oneFilled);
  }

  onUseLegalNameAsAccountHolder() {
    if (!this.formData.bankAccount) {
      this.formData.bankAccount = <BankAccount>{};
    }
    this.formData.bankAccount.accountHolder = this.companyName;
  }

  onCountryDetected(countryCode: string) {
    if (this.countries.filter((country) => country.code === countryCode)[0]) {
      this.formData.bankAccount.countryCode = countryCode;
    }
  }
}
