<template lang="pug">
  div.ma-6
    div.heading.text-h6.font-weight-regular.mb-3 Legal entity address
    div.field
      div.label.text-caption.text-uppercase Legal name
      div(v-if="showNameLink")
        a.dc-link(
          :href="legalEntityLink"
        )
          | {{details.name.value}}
      div(v-else) {{details.name.value}}

    VatAddress(
      :address="details.address"
      address-label="Company Address"
    )

    v-divider.my-6

    div.heading.text-h6.font-weight-regular.mb-3 Signer details
    div(v-for="(signer, index) in details.signers")
      div.field
        div.label.text-caption.text-uppercase Signer {{isMoreThanOneSigner ? index + 1 : ''}} First Name
        div {{signer.firstName}}
      div.field
        div.label.text-caption.text-uppercase Signer {{isMoreThanOneSigner ? index + 1: ''}} Last Name
        div {{signer.lastName}}

    v-divider.my-6

    div.heading.text-h6.font-weight-regular.mb-3 Legal VAT details
    VatIdDetails(
      :details="details.vat"
    )

</template>

<script lang="ts">
import Vue from 'vue';
import VatAddress from 'Merchant/common/components/vat-section/vat-address.vue';
import VatIdDetails from 'Merchant/common/components/vat-section/vat-id-details.vue';
import { LegalEntityDetailsT } from 'Api/models/LegalEntityDetails';

export default Vue.extend({
  components: { VatIdDetails, VatAddress },
  props: {
    details: {
      type: Object as () => LegalEntityDetailsT,
      required: true,
    },
    showNameLink: {
      type: Boolean,
      required: true,
    },
    legalEntityId: {
      type: String,
      required: true,
    },
  },

  computed: {
    isMoreThanOneSigner(): boolean {
      return this.details.signers.length > 1;
    },
    legalEntityLink(): string {
      return this.$router.resolve({
        name: 'mcp.admin.legal-entity-details',
        params: { legalEntityId: this.legalEntityId },
      }).href;
    },
  },
});
</script>

<style lang="scss" scoped>
.v-application a {
  color: #26aafe;
}

.heading {
  line-height: 1;
}

::v-deep .field {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}

::v-deep .label {
  width: 191px;
  font-weight: 700;
}
</style>
