<template lang="pug">
  div
    div(v-if="showName").field
      div.label Name
      div {{address.name}}

    div.field
      div.label Address
      div {{address.addressLine1}} {{address.addressLine2}} {{address.addressLine3}}

    div.field
      div.label Zip
      div {{address.zipCode}}

    div.field
      div.label City
      div {{address.city}}

    div.field
      div.label Country
      div {{countryName}}
</template>
<script lang="ts">
import Vue from 'vue';
import { COUNTRIES } from 'Common/constants/misc.constant';

export default Vue.extend({
  props: {
    address: {
      type: Object,
      required: true,
    },
    showName: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    countryName(): string {
      return COUNTRIES.find(
        (country) => country.code === this.address.countryCode
      ).name;
    },
  },
});
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  margin-bottom: 18px;
}
.label {
  width: 145px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 11px;
}
</style>
