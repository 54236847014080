import routerModule from './router/router';
import httpModule from './http/http';
import httpServerErrorModule from './httpServerError/httpServerError';
import httpLoadingTrackerModule from './httpLoadingTracker/httpLoadingTracker';
import viewTitleModule from './viewTitle/viewTitle';
import dashboardModule from './dashboard/dashboard';
import menuModule from './menu/menu';
import toastModule from './toast/toast';
import dialogModule from './dialog/dialog';
import merchantModule from './merchant/merchant';
import firewallModule from './firewall/firewall';
import breadcrumbsModule from './breadcrumbs/breadcrumbs';
import analyticsModule from './analytics/analytics';

/**
 * @ngdoc module
 * @name mcb.components
 */
export default angular.module('mcb.components', [
  routerModule.name,
  httpModule.name,
  httpServerErrorModule.name,
  httpLoadingTrackerModule.name,
  viewTitleModule.name,
  dashboardModule.name,
  menuModule.name,
  toastModule.name,
  dialogModule.name,
  merchantModule.name,
  firewallModule.name,
  breadcrumbsModule.name,
  analyticsModule.name,
]);
