import { mcb } from 'mcb';
import { Retailer } from 'Api/models/Retailer';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { FulfillmentTypeT } from 'Common/constants/misc.constant';
import { ContractsSelection, Item } from '../../services/contracts-selection';
import { Contract } from 'Api/models/Contract';
import template from './sales-channel-selection.html';
import { ContractActions } from 'Merchant/common/state/actions/contract.actions';
import { Unsubscribe } from 'redux';
import { FlowState } from 'Merchant/common/state/flow.state';
import { FlowStateSelector } from 'Merchant/common/state/selectors/flow-state.selector';
import { RetailerService } from 'Merchant/common/services/retailer.service';
import { ApiService } from 'Api/services/api.service';
import { ZALANDO_FASHION_STORE_RETAILERID } from 'Common/constants/retailer.constant';

export class SalesChannelSelectionComponent implements ng.IComponentOptions {
  static Factory() {
    return new SalesChannelSelectionComponent();
  }

  controller = SalesChannelsController;
  controllerAs = 'ctrl';
  template: string = template;
}

interface SalesChannelsControllerVm {
  retailers: Retailer[];
  merchant: MerchantProfile;
}

export class SalesChannelsController implements SalesChannelsControllerVm {
  static $inject = [
    'mcpAdminRetailerService',
    'mcpAdminContractActions',
    'mcpAdminApi',
    'mcbToast',
    '$state',
    'mcbConfig',
    '$ngRedux',
    'mcpAdminFlowStateSelector',
  ];

  retailers: Retailer[] = [];
  merchant: MerchantProfile;
  isFormSubmitted = false;
  unsubscribe: Unsubscribe;
  imagesPath: string;

  private contracts: Contract[];
  private contractsSelection: ContractsSelection;

  constructor(
    private retailerService: RetailerService,
    private contractActions: ContractActions,
    private api: ApiService,
    private toast: mcb.IToast,
    private state: ng.ui.IStateService,
    private mcbConfig: mcb.config.IConfigProvider,
    private store,
    private flowSelector: FlowStateSelector
  ) {
    this.submit = this.submit.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
    this.selectFFType = this.selectFFType.bind(this);
    this.deselectFFType = this.deselectFFType.bind(this);
    this.getSelectedItems = this.getSelectedItems.bind(this);
  }

  $onInit(): void {
    this.imagesPath =
      this.mcbConfig.package('mc-package-admin').get('mountURL') + '/images';

    // Init new contracts selection
    this.contractsSelection = new ContractsSelection();

    this.listenStore();
  }

  $onDestroy(): void {
    this.unsubscribe();
  }

  submit(): void {
    const { contractsSelection, contractActions, toast, state } = this;
    const contractsToCreate = contractsSelection.getContractsToCreate();
    const contractsToDelete = contractsSelection.getContractsToDelete();
    const saveContractsAction = contractActions.saveContractsSelection(
      contractsToCreate,
      contractsToDelete
    );

    this.isFormSubmitted = true;

    this.store
      .dispatch(saveContractsAction)
      .then((): void => {
        toast.show('Merchant sale channels saved!');
        state.go('mcp.admin.merchant.general.company-details', {
          merchantId: this.merchant.$id,
        });
      })
      .catch((): void => {
        this.isFormSubmitted = false;
        toast.error('Something went terribly wrong!');
        this.fetchContractsAndRetailers();
      });
  }

  getSelectedItems() {
    return this.contractsSelection.getItems();
  }

  /**
   * Select all contracts for the (Retailer, Fulfillment type) combination
   * @param retailer
   * @param ffType
   */
  selectFFType(retailer: Partial<Retailer>, ffType: FulfillmentTypeT): void {
    const selectionItems: Item[] = retailer.salesChannels.map((channel) => ({
      retailerId: retailer.$id,
      salesChannelId: channel.$id,
      fulfillmentType: ffType.$id,
    }));

    this.contractsSelection.selectItems(selectionItems);
  }

  /**
   * Deselect all contracts for the (Retailer, Fulfillment type) combination
   * @param retailer
   * @param ffType
   */
  deselectFFType(retailer: Partial<Retailer>, ffType: FulfillmentTypeT): void {
    const selectionItems: Item[] = retailer.salesChannels.map((channel) => ({
      retailerId: retailer.$id,
      salesChannelId: channel.$id,
      fulfillmentType: ffType.$id,
    }));

    this.contractsSelection.deselectItems(selectionItems);
  }

  toggleSelection(
    retailerId: string,
    salesChannelId: string,
    fulfillmentType: string
  ): void {
    this.contractsSelection.toggle({
      retailerId,
      salesChannelId,
      fulfillmentType,
    });
  }

  isSelected(
    retailerId: string,
    salesChannelId: string,
    fulfillmentType: string
  ): boolean {
    return this.contractsSelection.isSelected({
      retailerId,
      salesChannelId,
      fulfillmentType,
    });
  }

  isDisabled(
    retailerId: string,
    salesChannelId: string,
    fulfillmentType: string
  ): boolean {
    return this.contractsSelection.isDisabled({
      retailerId,
      salesChannelId,
      fulfillmentType,
    });
  }

  isSalesForceMerchant(): boolean {
    const { store, flowSelector } = this;
    const state: FlowState = store.getState();
    const merchant = flowSelector.getFlowMerchant(state);

    return merchant.salesforceIntegration !== 'MISSING';
  }

  private listenStore(): void {
    this.unsubscribe = this.store.subscribe(() => {
      this.fetchContractsAndRetailers();
    });
    this.fetchContractsAndRetailers();
  }

  private fetchContractsAndRetailers(): void {
    const { store, flowSelector } = this;
    const state: FlowState = store.getState();
    const merchant = flowSelector.getFlowMerchant(state);

    if (!merchant) {
      return;
    }

    this.merchant = merchant;
    this.contracts = flowSelector.getFlowContracts(state);

    this.retailerService
      .fetchRetailers()
      .then((retailers: Retailer[]) => {
        this.retailers = retailers.filter((retailer) => {
          /**
           * Every merchant can work only with 2 retailers at most:
           * - Zalando Fashion Store Retailer, which is displayed for all the merchants
           * - a custom retailer which is a temporary solution for ZFS multichannel pilot project.
           * (such retailer has the same id as the merchant's id). E.g. "adidas FR"
           *
           * See https://github.bus.zalan.do/WILMA/mc-package-admin/issues/522
           */

          return (
            retailer.$id === ZALANDO_FASHION_STORE_RETAILERID ||
            retailer.$id === this.merchant.$id
          );
        });
      })
      .then(() => {
        this.contractsSelection.init(this.contracts, this.merchant);
      });
  }
}
