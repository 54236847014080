import template from './contract-navigation.html';
import { Contract } from 'Api/models/Contract';
import { Store, Unsubscribe } from 'redux';
import { FlowState } from 'Merchant/common/state/flow.state';
import { FlowStateSelector } from 'Merchant/common/state/selectors/flow-state.selector';
import { MerchantRequirements } from 'Api/models/MerchantRequiremenets';
import { ContractRequirements } from 'Api/models/ContractRequirements';
import permissionsService from 'Common/services/permissions.service';
import {
  fetchCarriersRequirements,
  StocksRequirementsResponseT,
} from 'Api/endpoints/sales-channel/carrier.endpoint';
import { canEnableBankDetails } from '@/feature-flags';
import { fetchContacts } from 'Api/endpoints/sales-channel/contact.endpoint';
import { MarketContactT } from 'Api/models/MerchantContact';

export class ContractNavigationComponent implements ng.IComponentOptions {
  static Factory() {
    return new ContractNavigationComponent();
  }

  bindings = {
    contractId: '<',
    merchantStatus: '<',
    contractStatus: '<',
  };

  controller = ContractNavigationComponentController;
  controllerAs = 'ctrl';
  template: string = template;
}

export class ContractNavigationComponentController
  implements angular.IComponentController
{
  static $inject = ['mcpAdminFlowStateSelector', '$ngRedux'];

  contractId: boolean;
  merchantStatus: MerchantRequirements;
  contractStatus: ContractRequirements;

  isReadOnlyUser = false;
  isIntegrationManager = false;
  isContractLoading = true;
  unsubscribe: Unsubscribe;
  contract: Contract;
  contacts: MarketContactT[] = [];
  isCompanyDataDefined: boolean;
  private stocksRequirements: StocksRequirementsResponseT;

  constructor(
    private flowSelector: FlowStateSelector,
    private store: Store<FlowState>
  ) {}

  $onInit() {
    this.isReadOnlyUser = permissionsService.isReadOnly();
    this.isIntegrationManager = permissionsService.hasEditPermissions();

    this.unsubscribe = this.store.subscribe(() => {
      const state: FlowState = this.store.getState();
      this.contract = this.flowSelector.getFlowContract(state);
      this.isContractLoading =
        this.flowSelector.getFlowContractLoadingStatus(state);
      this.fetchCarriersRequirementDetails(
        state.flow.general.merchant.data.$id
      );
      this.getContacts(state.flow.general.merchant.data.$id).then(() => {
        this.isCompanyDataDefined = this.isCompanyDataPresent();
      });
    });
  }

  $onDestroy(): void {
    this.unsubscribe();
  }

  isCarrierDetailsTabVisible(): boolean {
    return this.isContractLoading
      ? false
      : this.contract.fulfillmentType === 'PARTNER';
  }

  fetchCarriersRequirementDetails(merchantId: string): void {
    if (this.contract?.salesChannel) {
      fetchCarriersRequirements(
        merchantId,
        this.contract.salesChannel.$id
      ).then((res) => {
        this.stocksRequirements = res;
      });
    }
  }

  isCarrierDetailsCompleted(): boolean {
    return this.stocksRequirements?.carrierProductsSpecified;
  }

  async getContacts(merchantId: string): Promise<void> {
    if (this.contract?.salesChannelId) {
      this.contacts = await fetchContacts(
        merchantId,
        this.contract.salesChannelId
      );
    }
  }

  isContractCompanyDataPresent(): boolean {
    return this.contacts.length > 0 && this.contractStatus.returnAddressDefined;
  }

  isCompanyDataPresent(): boolean {
    const isBankDetailsChangesEnabled = canEnableBankDetails();

    return isBankDetailsChangesEnabled
      ? this.isContractCompanyDataPresent()
      : this.merchantStatus.generalDataDefined;
  }
}
