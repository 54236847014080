import utilModule from './util/util';
import configModule from './config/config';
import pluginsModule from './plugins/plugins';
/**
 * @ngdoc module
 * @name mcb.core
 */
export default angular.module('mcb.core', [
  utilModule.name,
  configModule.name,
  pluginsModule.name,
]);
