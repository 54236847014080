import { MerchantProfile } from 'Api/models/MerchantProfile';
import template from './merchant-name.html';
import { canEnableVatCollection } from '@/feature-flags';

export class MerchantNameComponent implements ng.IComponentOptions {
  static Factory() {
    return new MerchantNameComponent();
  }

  bindings: { [binding: string]: string } = {
    merchant: '=',
  };

  controller = MerchantNameController;
  controllerAs = 'ctrl';
  template: string = template;
}

class MerchantNameController implements ng.IController {
  // injected by component
  merchant: MerchantProfile;
  isVatEnabled: boolean;

  $onInit() {
    this.isVatEnabled = canEnableVatCollection(this.merchant);
    // Empty block
  }
}
