export function IamAuthTokenService($cookies) {
  function McIamAuthToken(iamToken, roles) {
    this._iamToken = iamToken;
    this._iamToken.roles = roles;
    this._cookieName = 'MCP_ADMIN_PREF_ROLE:' + iamToken.id;
  }

  McIamAuthToken.prototype.getUser = function () {
    return this._iamToken;
  };

  McIamAuthToken.prototype.getUsername = function () {
    return this._iamToken.fullName;
  };

  McIamAuthToken.prototype.isAuthenticated = function () {
    return true;
  };

  McIamAuthToken.prototype.getAccessToken = function () {
    return this._iamToken.accessToken;
  };

  McIamAuthToken.prototype.getRoles = function () {
    return this._iamToken.roles || [];
  };

  McIamAuthToken.prototype.getCurrentRole = function () {
    return this._iamToken.currentRole;
  };

  McIamAuthToken.prototype.clearPreferredRole = function () {
    $cookies.remove(this._cookieName);
  };

  McIamAuthToken.prototype.getPreferredRole = function () {
    return $cookies.get(this._cookieName);
  };

  McIamAuthToken.prototype.setCurrentRole = function (role) {
    this._iamToken.currentRole = role;
    $cookies.put(this._cookieName, role);
  };

  return McIamAuthToken;
}

IamAuthTokenService.$inject = ['$cookies'];
