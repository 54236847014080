const config =
  ((<any>window).__MC_INITIAL_STATE__ &&
    (<any>window).__MC_INITIAL_STATE__.clientConfig) ||
  null;

export function isSentryActive(): boolean {
  return (config && config.monitoring.sentryActive) || false;
}

export function getSentryDSN(): string | null {
  return (config && config.monitoring.sentryDSN) || null;
}

export function getEnv(): string | null {
  return (config && config.env) || null;
}
