/**
 * @ngdoc service
 * @name mcbHttpServerErrorInterceptor
 * @module mcb.components.httpServerError
 *
 * @requires $rootScope
 * @requires $q
 * @requires mcbHttpServerErrorFormatter
 */
let httpServerErrorInterceptor = function httpServerErrorInterceptor(
  $rootScope,
  $q,
  mcbHttpServerErrorFormatter
) {
  return {
    responseError: function (rejection) {
      if (
        rejection &&
        rejection.config &&
        rejection.config.ignoreHttpServerError
      ) {
        return $q.reject(rejection);
      }

      $rootScope.$broadcast('mcbHttpServerError', {
        message: mcbHttpServerErrorFormatter.format(rejection),
        rejection: rejection,
      });

      return $q.reject(rejection);
    },
  };
};

httpServerErrorInterceptor.$inject = [
  '$rootScope',
  '$q',
  'mcbHttpServerErrorFormatter',
];

export default httpServerErrorInterceptor;
