import 'angular-ui-router';

// here to allow Backward Compatibility for $state* events (they are deprecated... FIXME: we should use the new transitions service)
import 'angular-ui-router/release/stateEvents.js';

import McbRouterUtil from './McbRouterUtil';

/**
 * @ngdoc module
 * @name mcb.components.router
 */
export default angular
  .module('mcb.components.router', ['ui.router', 'ui.router.state.events'])
  /**
   * @ngdoc service
   * @module mcb.components.router
   * @name mcb.components.router.mcbRouterUtil
   */
  .service('mcbRouterUtil', McbRouterUtil);
