var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    {
      staticClass:
        "mcp-admin__field-info dc--has-tooltip dc--has-tooltip--top-right dc--has-tooltip--large",
      attrs: { "data-dc-has-tooltip": _vm.infoText },
    },
    [_c("i", { staticClass: "dc-icon dc-icon--info" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }