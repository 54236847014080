interface MCOption {
  template?: any;
  ariaAuto?: any;
  ariaRole?: any;
  ariaLabelledBySelector?: any;
  ariaDescribedBySelector?: any;
  ariaLabelledById?: any;
  ariaDescribedById?: any;
  scope?: any;
  className?: any;
  plain?: any;
  showClose?: any;
  closeByDocument?: any;
  closeByEscape?: any;
  closeByNavigation?: any;
  appendTo?: any;
  preCloseCallback?: any;
  overlay?: any;
  cache?: any;
  trapFocus?: any;
  preserveFocus?: any;
  name?: any;
  bindToController?: any;
  controller?: any;
  controllerAs?: any;
  data?: any;
  templateUrl?: any;
  resolve?: any;
}

interface MCStyle {
  WebkitAnimation?: any;
  animation?: any;
  MozAnimation?: any;
  MsAnimation?: any;
  OAnimation?: any;
}

interface MCElement {
  body?: any;
  html?: any;
}

let $el = angular.element;
let isDef = angular.isDefined;
let style = (document.body || document.documentElement).style as MCStyle;

let animationEndSupport =
  isDef(style.animation) ||
  isDef(style.WebkitAnimation) ||
  isDef(style.MozAnimation) ||
  isDef(style.MsAnimation) ||
  isDef(style.OAnimation);
let animationEndEvent =
  'animationend webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend';
let focusableElementSelector =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), ' +
  'textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';
let forceElementsReload = { html: false, body: false };
let scopes = {};
let openIdStack = [];
let keydownIsBound = false;

export default function mcbDialogProvider() {
  let defaults = (this.defaults = {
    className: '',
    plain: false,
    showClose: true,
    closeByDocument: true,
    closeByEscape: true,
    closeByNavigation: false,
    appendTo: false,
    preCloseCallback: false,
    overlay: true,
    cache: true,
    trapFocus: true,
    preserveFocus: true,
    ariaAuto: true,
    ariaRole: null,
    ariaLabelledById: null,
    ariaLabelledBySelector: null,
    ariaDescribedById: null,
    ariaDescribedBySelector: null,
  });

  this.setForceHtmlReload = (_useIt) => {
    forceElementsReload.html = _useIt || false;
  };

  this.setForceBodyReload = (_useIt) => {
    forceElementsReload.body = _useIt || false;
  };

  this.setDefaults = (newDefaults) => {
    angular.extend(defaults, newDefaults);
  };

  let globalID = 0;
  let dialogsCount = 0;
  let closeByDocumentHandler;
  let defers = {};

  this.$get = [
    '$document',
    '$templateCache',
    '$compile',
    '$q',
    '$http',
    '$rootScope',
    '$timeout',
    '$window',
    '$controller',
    '$injector',
    (
      $document,
      $templateCache,
      $compile,
      $q,
      $http,
      $rootScope,
      $timeout,
      $window,
      $controller,
      $injector
    ) => {
      let $elements = [] as MCElement;

      let privateMethods = {
        onDocumentKeydown: (event) => {
          if (event.keyCode === 27) {
            // @ts-ignore
            publicMethods.close('$escape');
          }
        },

        activate: ($dialog) => {
          let options = $dialog.data('$mcbDialogOptions');

          if (options.trapFocus) {
            $dialog.on('keydown', privateMethods.onTrapFocusKeydown);

            // Catch rogue changes (eg. after unfocusing everything by clicking a non-focusable element)
            $elements.body.on('keydown', privateMethods.onTrapFocusKeydown);
          }
        },

        deactivate: ($dialog) => {
          $dialog.off('keydown', privateMethods.onTrapFocusKeydown);
          $elements.body.off('keydown', privateMethods.onTrapFocusKeydown);
        },

        deactivateAll: (els) => {
          angular.forEach(els, (el) => {
            let $dialog = angular.element(el);
            privateMethods.deactivate($dialog);
          });
        },

        setBodyPadding: (width) => {
          let originalBodyPadding = parseInt(
            $elements.body.css('padding-right') || 0,
            10
          );
          $elements.body.css(
            'padding-right',
            originalBodyPadding + width + 'px'
          );
          $elements.body.data(
            'ng-dialog-original-padding',
            originalBodyPadding
          );
          $rootScope.$broadcast('mcbDialog.setPadding', width);
        },

        resetBodyPadding: () => {
          let originalBodyPadding = $elements.body.data(
            'mcb-dialog-original-padding'
          );
          if (originalBodyPadding) {
            $elements.body.css('padding-right', originalBodyPadding + 'px');
          } else {
            $elements.body.css('padding-right', '');
          }
          $rootScope.$broadcast('mcbDialog.setPadding', 0);
        },

        performCloseDialog: ($dialog, value) => {
          // let options = $dialog.data('$mcbDialogOptions');
          let id = $dialog.attr('id');
          let scope = scopes[id];

          if (!scope) {
            // Already closed
            return;
          }

          if (typeof $window.Hammer !== 'undefined') {
            let hammerTime = scope.hammerTime;
            /*eslint no-unused-expressions: 0 */
            hammerTime.off('tap', closeByDocumentHandler);
            if (hammerTime.destroy) {
              hammerTime.destroy();
            }
            delete scope.hammerTime;
          } else {
            $dialog.unbind('click');
          }

          if (dialogsCount === 1) {
            $elements.body.unbind('keydown', privateMethods.onDocumentKeydown);
          }

          if (!$dialog.hasClass('mcb-dialog--closing')) {
            dialogsCount -= 1;
          }

          let previousFocus = $dialog.data('$mcbDialogPreviousFocus');
          if (previousFocus && previousFocus.focus) {
            previousFocus.focus();
          }

          $rootScope.$broadcast('mcbDialog.closing', $dialog, value);
          dialogsCount = dialogsCount < 0 ? 0 : dialogsCount;
          if (animationEndSupport) {
            scope.$destroy();
            $dialog
              .unbind(animationEndEvent)
              .bind(animationEndEvent, () => {
                privateMethods.closeDialogElement($dialog, value);
              })
              .addClass('mcb-dialog--closing');
          } else {
            scope.$destroy();
            privateMethods.closeDialogElement($dialog, value);
          }
          if (defers[id]) {
            defers[id].resolve({
              id: id,
              value: value,
              $dialog: $dialog,
              remainingDialogs: dialogsCount,
            });
            delete defers[id];
          }
          if (scopes[id]) {
            delete scopes[id];
          }
          openIdStack.splice(openIdStack.indexOf(id), 1);
          if (!openIdStack.length) {
            $elements.body.unbind('keydown', privateMethods.onDocumentKeydown);
            keydownIsBound = false;
          }
        },

        closeDialogElement: ($dialog, value) => {
          $dialog.remove();
          if (dialogsCount === 0) {
            $elements.html.removeClass('mcb-dialog--open');
            $elements.body.removeClass('mcb-dialog--open');
            privateMethods.resetBodyPadding();
          }
          $rootScope.$broadcast('mcbDialog.closed', $dialog, value);
        },

        closeDialog: ($dialog, value) => {
          let preCloseCallback = $dialog.data('$mcbDialogPreCloseCallback');

          if (preCloseCallback && angular.isFunction(preCloseCallback)) {
            let preCloseCallbackResult = preCloseCallback.call($dialog, value);

            if (angular.isObject(preCloseCallbackResult)) {
              if (preCloseCallbackResult.closePromise) {
                preCloseCallbackResult.closePromise.then(() => {
                  privateMethods.performCloseDialog($dialog, value);
                });
              } else {
                preCloseCallbackResult.then(
                  () => {
                    privateMethods.performCloseDialog($dialog, value);
                  },
                  () => {
                    return;
                  }
                );
              }
            } else if (preCloseCallbackResult !== false) {
              privateMethods.performCloseDialog($dialog, value);
            }
          } else {
            privateMethods.performCloseDialog($dialog, value);
          }
        },

        onTrapFocusKeydown: (ev) => {
          let el = angular.element(ev.currentTarget);
          let $dialog;

          if (el.hasClass('mcb-dialog')) {
            $dialog = el;
          } else {
            $dialog = privateMethods.getActiveDialog();

            if ($dialog === null) {
              return;
            }
          }

          let isTab = ev.keyCode === 9;
          let backward = ev.shiftKey === true;

          if (isTab) {
            privateMethods.handleTab($dialog, ev, backward);
          }
        },

        handleTab: ($dialog, ev, backward) => {
          let focusableElements = privateMethods.getFocusableElements($dialog);

          if (focusableElements.length === 0) {
            if (document.activeElement) {
              // @ts-ignore
              document.activeElement.blur();
            }
            return;
          }

          let currentFocus = document.activeElement;
          let focusIndex = Array.prototype.indexOf.call(
            focusableElements,
            currentFocus
          );

          let isFocusIndexUnknown = focusIndex === -1;
          let isFirstElementFocused = focusIndex === 0;
          let isLastElementFocused =
            focusIndex === focusableElements.length - 1;

          let cancelEvent = false;

          if (backward) {
            if (isFocusIndexUnknown || isFirstElementFocused) {
              focusableElements[focusableElements.length - 1].focus();
              cancelEvent = true;
            }
          } else {
            if (isFocusIndexUnknown || isLastElementFocused) {
              focusableElements[0].focus();
              cancelEvent = true;
            }
          }

          if (cancelEvent) {
            ev.preventDefault();
            ev.stopPropagation();
          }
        },

        autoFocus: ($dialog) => {
          let dialogEl = $dialog[0];

          // Browser's (Chrome 40, Forefix 37, IE 11) don't appear to honor autofocus on the dialog, but we should
          let autoFocusEl = dialogEl.querySelector('*[autofocus]');
          if (autoFocusEl !== null) {
            autoFocusEl.focus();

            if (document.activeElement === autoFocusEl) {
              return;
            }

            // Autofocus element might was display: none, so let's continue
          }

          let focusableElements = privateMethods.getFocusableElements($dialog);

          if (focusableElements.length > 0) {
            focusableElements[0].focus();
            return;
          }

          // We need to focus something for the screen readers to notice the dialog
          let contentElements = privateMethods.filterVisibleElements(
            dialogEl.querySelectorAll('h1,h2,h3,h4,h5,h6,p,span')
          );

          if (contentElements.length > 0) {
            let contentElement = contentElements[0];
            $el(contentElement).attr('tabindex', '-1').css('outline', '0');
            contentElement.focus();
          }
        },

        getFocusableElements: ($dialog) => {
          let dialogEl = $dialog[0];

          let rawElements = dialogEl.querySelectorAll(focusableElementSelector);

          // Ignore untabbable elements, ie. those with tabindex = -1
          let tabbableElements =
            privateMethods.filterTabbableElements(rawElements);

          return privateMethods.filterVisibleElements(tabbableElements);
        },

        filterTabbableElements: (els) => {
          let tabbableFocusableElements = [];

          for (let i = 0; i < els.length; i++) {
            let el = els[i];

            if ($el(el).attr('tabindex') !== '-1') {
              tabbableFocusableElements.push(el);
            }
          }

          return tabbableFocusableElements;
        },

        filterVisibleElements: (els) => {
          let visibleFocusableElements = [];

          for (let i = 0; i < els.length; i++) {
            let el = els[i];

            if (el.offsetWidth > 0 || el.offsetHeight > 0) {
              visibleFocusableElements.push(el);
            }
          }

          return visibleFocusableElements;
        },

        getActiveDialog: () => {
          let dialogs = document.querySelectorAll('.mcb-dialog');

          if (dialogs.length === 0) {
            return null;
          }

          // TODO: This might be incorrect if there are a mix of open dialogs with different 'appendTo' values
          return $el(dialogs[dialogs.length - 1]);
        },

        applyAriaAttributes: ($dialog, options: MCOption) => {
          if (options.ariaAuto) {
            if (!options.ariaRole) {
              let detectedRole =
                privateMethods.getFocusableElements($dialog).length > 0
                  ? 'dialog'
                  : 'alertdialog';

              options.ariaRole = detectedRole;
            }

            if (!options.ariaLabelledBySelector) {
              options.ariaLabelledBySelector = 'h1,h2,h3,h4,h5,h6';
            }

            if (!options.ariaDescribedBySelector) {
              options.ariaDescribedBySelector = 'article,section,p';
            }
          }

          if (options.ariaRole) {
            $dialog.attr('role', options.ariaRole);
          }

          privateMethods.applyAriaAttribute(
            $dialog,
            'aria-labelledby',
            options.ariaLabelledById,
            options.ariaLabelledBySelector
          );

          privateMethods.applyAriaAttribute(
            $dialog,
            'aria-describedby',
            options.ariaDescribedById,
            options.ariaDescribedBySelector
          );
        },

        applyAriaAttribute: ($dialog, attr, id, selector) => {
          if (id) {
            $dialog.attr(attr, id);
          }

          if (selector) {
            let dialogId = $dialog.attr('id');

            let firstMatch = $dialog[0].querySelector(selector);

            if (!firstMatch) {
              return;
            }

            let generatedId = dialogId + '-' + attr;

            $el(firstMatch).attr('id', generatedId);

            $dialog.attr(attr, generatedId);

            return generatedId;
          }
        },

        detectUIRouter: () => {
          // Detect if ui-router module is installed if not return false
          try {
            angular.module('ui.router');
            return true;
          } catch (err) {
            return false;
          }
        },

        getRouterLocationEventName: () => {
          if (privateMethods.detectUIRouter()) {
            return '$stateChangeSuccess';
          }
          return '$locationChangeSuccess';
        },
      };

      let publicMethods = {
        __PRIVATE__: privateMethods,

        /*
         * @param {Object} options:
         * - template {String} - id of ng-template, url for partial, plain string (if enabled)
         * - plain {Boolean} - enable plain string templates, default false
         * - scope {Object}
         * - controller {String}
         * - controllerAs {String}
         * - className {String} - dialog theme class
         * - disableAnimation {Boolean} - set to true to disable animation
         * - showClose {Boolean} - show close button, default true
         * - closeByEscape {Boolean} - default true
         * - closeByDocument {Boolean} - default true
         * - preCloseCallback {String|Function} - user supplied function name/function called before closing dialog (if set)
         *
         * @return {Object} dialog
         */
        open: (opts) => {
          let options: MCOption = angular.copy(defaults);
          let localID = ++globalID;
          let dialogID = 'mcb-dialog' + localID;
          openIdStack.push(dialogID);

          opts = opts || {};
          angular.extend(options, opts);

          let defer;
          defers[dialogID] = defer = $q.defer();

          let scope;

          scopes[dialogID] = scope = angular.isObject(options.scope)
            ? options.scope.$new()
            : $rootScope.$new();

          let $dialog;
          let $dialogParent;

          let resolve = angular.extend({}, options.resolve);

          angular.forEach(resolve, (value, key) => {
            resolve[key] = angular.isString(value)
              ? $injector.get(value)
              : $injector.invoke(value, null, null, key);
          });

          $q.all({
            template: loadTemplate(options.template || options.templateUrl),
            locals: $q.all(resolve),
          }).then((setup) => {
            let template = setup.template;
            let locals = setup.locals;

            $dialog = $el(
              '<div id="mcb-dialog' +
                localID +
                '" class="dc-dialog mcb-dialog"></div>'
            );
            $dialog.html(
              '<div class="dc-dialog__overlay mcb-dialog-overlay"></div>' +
                '<div class="dc-dialog__content mcb-dialog-content" role="document">' +
                template +
                '</div>'
            );

            $dialog.data('$mcbDialogOptions', options);

            scope.mcbDialogId = dialogID;

            if (options.data && angular.isString(options.data)) {
              let firstLetter = options.data.replace(/^\s*/, '')[0];
              scope.mcbDialogData =
                firstLetter === '{' || firstLetter === '['
                  ? angular.fromJson(options.data)
                  : options.data;
              scope.mcbDialogData.mcbDialogId = dialogID;
            } else if (options.data && angular.isObject(options.data)) {
              scope.mcbDialogData = options.data;
              scope.mcbDialogData.mcbDialogId = dialogID;
            }

            if (options.className) {
              $dialog.addClass(options.className);
            }

            if (options.appendTo && angular.isString(options.appendTo)) {
              $dialogParent = angular.element(
                document.querySelector(options.appendTo)
              );
            } else {
              $dialogParent = $elements.body;
            }

            privateMethods.applyAriaAttributes($dialog, options);

            if (options.preCloseCallback) {
              let preCloseCallback;

              if (angular.isFunction(options.preCloseCallback)) {
                preCloseCallback = options.preCloseCallback;
              } else if (angular.isString(options.preCloseCallback)) {
                if (scope) {
                  if (angular.isFunction(scope[options.preCloseCallback])) {
                    preCloseCallback = scope[options.preCloseCallback];
                  } else if (
                    scope.$parent &&
                    angular.isFunction(scope.$parent[options.preCloseCallback])
                  ) {
                    preCloseCallback = scope.$parent[options.preCloseCallback];
                  } else if (
                    $rootScope &&
                    angular.isFunction($rootScope[options.preCloseCallback])
                  ) {
                    preCloseCallback = $rootScope[options.preCloseCallback];
                  }
                }
              }

              if (preCloseCallback) {
                $dialog.data('$mcbDialogPreCloseCallback', preCloseCallback);
              }
            }

            scope.closeThisDialog = (value) => {
              privateMethods.closeDialog($dialog, value);
            };

            if (
              options.controller &&
              (angular.isString(options.controller) ||
                angular.isArray(options.controller) ||
                angular.isFunction(options.controller))
            ) {
              let label;

              if (
                options.controllerAs &&
                angular.isString(options.controllerAs)
              ) {
                label = options.controllerAs;
              }

              let controllerInstance = $controller(
                options.controller,
                angular.extend(locals, {
                  $scope: scope,
                  $element: $dialog,
                }),
                true,
                label
              );

              if (options.bindToController) {
                angular.extend(controllerInstance.instance, {
                  mcbDialogId: scope.mcbDialogId,
                  mcbDialogData: scope.mcbDialogData,
                  closeThisDialog: scope.closeThisDialog,
                });
              }

              $dialog.data(
                '$mcbDialogControllerController',
                controllerInstance()
              );
            }

            $timeout(() => {
              let $activeDialogs = document.querySelectorAll('.mcb-dialog');
              privateMethods.deactivateAll($activeDialogs);

              $compile($dialog)(scope);
              let widthDiffs =
                $window.innerWidth - $elements.body.prop('clientWidth');
              $elements.html.addClass('mcb-dialog-open');
              $elements.body.addClass('mcb-dialog-open');
              let scrollBarWidth =
                widthDiffs -
                ($window.innerWidth - $elements.body.prop('clientWidth'));
              if (scrollBarWidth > 0) {
                privateMethods.setBodyPadding(scrollBarWidth);
              }
              $dialogParent.append($dialog);

              privateMethods.activate($dialog);

              if (options.trapFocus) {
                privateMethods.autoFocus($dialog);
              }

              if (options.name) {
                $rootScope.$broadcast('mcbDialog.opened', {
                  dialog: $dialog,
                  name: options.name,
                });
              } else {
                $rootScope.$broadcast('mcbDialog.opened', $dialog);
              }
            });

            if (!keydownIsBound) {
              $elements.body.bind('keydown', privateMethods.onDocumentKeydown);
              keydownIsBound = true;
            }

            if (options.closeByNavigation) {
              let eventName = privateMethods.getRouterLocationEventName();
              $rootScope.$on(eventName, () => {
                // @ts-ignore
                privateMethods.closeDialog($dialog);
              });
            }

            if (options.preserveFocus) {
              $dialog.data('$mcbDialogPreviousFocus', document.activeElement);
            }

            closeByDocumentHandler = (event) => {
              let isOverlay = options.closeByDocument
                ? $el(event.target).hasClass('mcb-dialog-overlay')
                : false;
              let isCloseBtn = $el(event.target).hasClass('mcb-dialog-close');

              if (isOverlay || isCloseBtn) {
                publicMethods.close(
                  $dialog.attr('id'),
                  isCloseBtn ? '$closeButton' : '$document'
                );
              }
            };

            if (typeof $window.Hammer !== 'undefined') {
              /*eslint new-cap: 0 */
              let hammerTime = (scope.hammerTime = $window.Hammer($dialog[0]));
              hammerTime.on('tap', closeByDocumentHandler);
            } else {
              $dialog.bind('click', closeByDocumentHandler);
            }

            dialogsCount += 1;

            return publicMethods;
          });

          return {
            id: dialogID,
            closePromise: defer.promise,
            close: (value) => {
              privateMethods.closeDialog($dialog, value);
            },
          };

          function loadTemplateUrl(tmpl, config) {
            $rootScope.$broadcast('mcbDialog.templateLoading', tmpl);
            return $http.get(tmpl, config || {}).then((res) => {
              $rootScope.$broadcast('mcbDialog.templateLoaded', tmpl);
              return res.data || '';
            });
          }

          function loadTemplate(tmpl) {
            if (!tmpl) {
              return 'Empty template';
            }

            if (angular.isString(tmpl) && options.plain) {
              return tmpl;
            }

            if (typeof options.cache === 'boolean' && !options.cache) {
              return loadTemplateUrl(tmpl, { cache: false });
            }

            return loadTemplateUrl(tmpl, { cache: $templateCache });
          }
        },

        /*
         * @param {Object} options:
         * - template {String} - id of ng-template, url for partial, plain string (if enabled)
         * - plain {Boolean} - enable plain string templates, default false
         * - name {String}
         * - scope {Object}
         * - controller {String}
         * - controllerAs {String}
         * - className {String} - dialog theme class
         * - showClose {Boolean} - show close button, default true
         * - closeByEscape {Boolean} - default false
         * - closeByDocument {Boolean} - default false
         * - preCloseCallback {String|Function} - user supplied function name/function called before closing dialog (if set); not called on confirm
         *
         * @return {Object} dialog
         */
        openConfirm: (opts) => {
          /*eslint no-use-before-define: 0 */
          let defer = $q.defer();

          let options: MCOption = {
            closeByEscape: false,
            closeByDocument: false,
          };
          angular.extend(options, opts);

          options.scope = angular.isObject(options.scope)
            ? options.scope.$new()
            : $rootScope.$new();
          options.scope.confirm = (value) => {
            defer.resolve(value);
            let $dialog = $el(document.getElementById(openResult.id));
            privateMethods.performCloseDialog($dialog, value);
          };

          let openResult = publicMethods.open(options);
          openResult.closePromise.then((data) => {
            if (data) {
              return defer.reject(data.value);
            }
            return defer.reject();
          });

          return defer.promise;
        },

        isOpen: (id) => {
          let $dialog = $el(document.getElementById(id));
          return $dialog.length > 0;
        },

        /*
         * @param {String} id
         * @return {Object} dialog
         */
        close: (id, value) => {
          let $dialog = $el(document.getElementById(id));

          if ($dialog.length) {
            privateMethods.closeDialog($dialog, value);
          } else {
            if (id === '$escape') {
              let topDialogId = openIdStack[openIdStack.length - 1];
              $dialog = $el(document.getElementById(topDialogId));
              if ($dialog.data('$mcbDialogOptions').closeByEscape) {
                privateMethods.closeDialog($dialog, '$escape');
              }
            } else {
              publicMethods.closeAll(value);
            }
          }

          return publicMethods;
        },

        closeAll: (value) => {
          let $all = document.querySelectorAll('.mcb-dialog');

          // Reverse order to ensure focus restoration works as expected
          for (let i = $all.length - 1; i >= 0; i--) {
            let dialog = $all[i];
            privateMethods.closeDialog($el(dialog), value);
          }
        },

        getOpenDialogs: () => {
          return openIdStack;
        },

        getDefaults: () => {
          return defaults;
        },
      };

      angular.forEach(['html', 'body'], (elementName) => {
        $elements[elementName] = $document.find(elementName);
        if (forceElementsReload[elementName]) {
          let eventName = privateMethods.getRouterLocationEventName();
          $rootScope.$on(eventName, () => {
            $elements[elementName] = $document.find(elementName);
          });
        }
      });

      return publicMethods;
    },
  ];
}
