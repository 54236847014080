var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._l(_vm.contacts, function (contactTypeContacts) {
        return _c("div", [
          _c("div", { staticClass: "dc-h4 ctype-label" }, [
            _vm._v(_vm._s(_vm.typeLabels[contactTypeContacts.contactType])),
          ]),
          !contactTypeContacts.isGeneral
            ? _c(
                "div",
                _vm._l(contactTypeContacts.items, function (contact, i) {
                  return _c("div", [
                    _c("div", { staticClass: "itemNoWrapper" }, [
                      i == 1
                        ? _c("div", { staticClass: "itemNo" }, [
                            _vm._v("Second Contact"),
                          ])
                        : _vm._e(),
                      i == 2
                        ? _c("div", { staticClass: "itemNo" }, [
                            _vm._v("Third Contact"),
                          ])
                        : _vm._e(),
                      i == 1 || i == 2
                        ? _c(
                            "a",
                            {
                              staticClass: "dc-btn dc-btn--link",
                              on: {
                                click: function ($event) {
                                  return _vm.onDelete(contactTypeContacts, i)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "dc-icon dc-icon--trash dc-icon--btn",
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "form",
                      {
                        staticClass: "mcp-admin-merchant-details-section-form",
                        attrs: { novalidate: "" },
                      },
                      [
                        _c("div", { staticClass: "mcp-admin__form-field" }, [
                          _c(
                            "label",
                            {
                              staticClass: "dc-label",
                              class: {
                                "dc-label--is-error": _vm.hasErrorForFieldName(
                                  contact.contactType,
                                  contact.firstName
                                ),
                                "dc-label--required": _vm.isNameFieldRequired(
                                  contact.contactType
                                ),
                              },
                            },
                            [_vm._v("First name")]
                          ),
                          _vm.hasErrorForFieldName(
                            contact.contactType,
                            contact.firstName
                          )
                            ? _c("div", { staticClass: "errors" }, [
                                _c("span", { staticClass: "dc--text-error" }, [
                                  _vm._v("Please don't leave this empty."),
                                ]),
                              ])
                            : _vm._e(),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: contact.firstName,
                                expression: "contact.firstName",
                              },
                            ],
                            staticClass: "dc-input dc-input--block",
                            class: {
                              "dc-input--is-error": _vm.hasErrorForFieldName(
                                contact.contactType,
                                contact.firstName
                              ),
                            },
                            attrs: { type: "text", required: "" },
                            domProps: { value: contact.firstName },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("form-changed")
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  contact,
                                  "firstName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "mcp-admin__form-field" }, [
                          _c(
                            "label",
                            {
                              staticClass: "dc-label",
                              class: {
                                "dc-label--is-error": _vm.hasErrorForFieldName(
                                  contact.contactType,
                                  contact.lastName
                                ),
                                "dc-label--required": _vm.isNameFieldRequired(
                                  contact.contactType
                                ),
                              },
                            },
                            [_vm._v("Last name")]
                          ),
                          _vm.hasErrorForFieldName(
                            contact.contactType,
                            contact.lastName
                          )
                            ? _c("div", { staticClass: "errors" }, [
                                _c("span", { staticClass: "dc--text-error" }, [
                                  _vm._v("Please don't leave this empty."),
                                ]),
                              ])
                            : _vm._e(),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: contact.lastName,
                                expression: "contact.lastName",
                              },
                            ],
                            staticClass: "dc-input dc-input--block",
                            class: {
                              "dc-input--is-error": _vm.hasErrorForFieldName(
                                contact.contactType,
                                contact.lastName
                              ),
                            },
                            attrs: { type: "text", required: "" },
                            domProps: { value: contact.lastName },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("form-changed")
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  contact,
                                  "lastName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "mcp-admin__form-field" }, [
                          _c(
                            "label",
                            {
                              staticClass: "dc-label dc-label--required",
                              class: {
                                "dc-label--is-error":
                                  _vm.isSubmitted &&
                                  !_vm.isEmailValid(contact.email),
                              },
                            },
                            [_vm._v("Email")]
                          ),
                          _vm.isSubmitted && !_vm.isEmailValid(contact.email)
                            ? _c("div", { staticClass: "errors" }, [
                                _vm.isEmpty(contact.email)
                                  ? _c(
                                      "span",
                                      { staticClass: "dc--text-error" },
                                      [_vm._v("Please don't leave this empty.")]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "dc--text-error" },
                                      [
                                        _vm._v(
                                          "Please enter a valid email address."
                                        ),
                                      ]
                                    ),
                              ])
                            : _vm._e(),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: contact.email,
                                expression: "contact.email",
                              },
                            ],
                            staticClass: "dc-input dc-input--block",
                            class: {
                              "dc-input--is-error":
                                _vm.isSubmitted &&
                                !_vm.isEmailValid(contact.email),
                            },
                            attrs: { type: "text", required: "" },
                            domProps: { value: contact.email },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("form-changed")
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(contact, "email", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "mcp-admin__form-field" }, [
                          _c(
                            "label",
                            {
                              staticClass: "dc-label",
                              class: {
                                "dc-label--is-error":
                                  _vm.isSubmitted &&
                                  !_vm.isPhoneValid(contact.phone),
                              },
                            },
                            [_vm._v("Phone")]
                          ),
                          _vm.isSubmitted && !_vm.isPhoneValid(contact.phone)
                            ? _c("div", { staticClass: "errors" }, [
                                !_vm.isPhoneValid(contact.phone)
                                  ? _c(
                                      "span",
                                      { staticClass: "dc--text-error" },
                                      [_vm._v("Please enter a valid phone.")]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: contact.phone,
                                expression: "contact.phone",
                              },
                            ],
                            staticClass: "dc-input dc-input--block",
                            class: {
                              "dc-input--is-error":
                                _vm.isSubmitted &&
                                !_vm.isPhoneValid(contact.phone),
                            },
                            attrs: { type: "text", required: "" },
                            domProps: { value: contact.phone },
                            on: {
                              change: function ($event) {
                                return _vm.$emit("form-changed")
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(contact, "phone", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm.showAddAnotherBtn(contactTypeContacts)
            ? _c("div", { staticClass: "add-another" }, [
                _c(
                  "button",
                  {
                    staticClass: "dc-btn dc-btn--link",
                    on: {
                      click: function ($event) {
                        return _vm.addAnotherContact(contactTypeContacts)
                      },
                    },
                  },
                  [_vm._v("Add another contact")]
                ),
              ])
            : _vm._e(),
        ])
      }),
      _c("div", { staticClass: "buttons" }, [
        _c("div", [
          _c(
            "button",
            {
              staticClass: "dc-btn dc-btn--link",
              attrs: { "data-testid": "cancel-btn" },
              on: {
                click: function ($event) {
                  return _vm.$emit("cancel")
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "button",
            {
              staticClass: "dc-btn dc-btn--primary",
              class: { "dc-btn--disabled": _vm.isSubmitting },
              attrs: { "data-testid": "save-btn", disabled: _vm.isSubmitting },
              on: { click: _vm.save },
            },
            [_vm._v("Save")]
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }