<template lang="pug">
  div.my-3
    ReviewSectionTitle(title="Shop details")

    div.py-3
      div.field
        div.label.text-caption.text-uppercase Name
        div {{shopName}}
</template>

<script lang="ts">
import Vue from 'vue';
import ReviewSectionTitle from 'Merchant/common/components/review-section-title/review-section-title.vue';

export default Vue.extend({
  components: { ReviewSectionTitle },
  props: {
    shopName: {
      type: String,
      required: false,
      default: 'Not defined',
    },
  },
});
</script>
