<template lang="pug">
  div.my-3
    ReviewSectionTitle(title="Contact details")

    div(v-if="groupedContacts.length == 0") No contacts data available

    div(v-else)
      div.mb-4(v-for="cGroup in groupedContacts" data-testid="cgroup")
        div.my-4
          ReviewSectionTitle(:title="typeLabels[cGroup.ctype]" is-small)
        div
          div.mb-3(v-for="contact in cGroup.items" data-testid="contact")
            div.field
              div.label First name
              div {{ contact.firstName }}
            div.field
              div.label Last name
              div {{ contact.lastName }}
            div.field
              div.label Email
              div {{ contact.email }}
            div.field
              div.label Phone
              div {{ contact.phone || '-' }}

</template>

<script lang="ts">
import Vue from 'vue';
import ContactsForm from './company-details-contacts-form-container.vue';
import ConfirmDataChange from '@/common/components/confirm-data-change/confirm-data-change.vue';
import {
  COMPANY_CONTACT_TYPES,
  CTYPE_GENERAL,
  CTYPE_LABELS,
  ZFS_CONTACT_TYPES,
} from './company-details-contacts.constant';
import { groupBy, prop } from 'ramda';
import ReviewSectionTitle from 'Merchant/common/components/review-section-title/review-section-title.vue';
import MerchantContactT, { ContactTypeT } from 'Api/models/MerchantContact';

interface GroupedContactItemT {
  ctype: ContactTypeT;
  items: MerchantContactT[];
}

export default Vue.extend({
  name: 'CompanyDetailsContactsContainer',
  components: {
    ReviewSectionTitle,
    ContactsForm,
    ConfirmDataChange,
  },

  props: {
    contacts: {
      type: Array as () => Array<MerchantContactT>,
      required: true,
    },
    hasZfs: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      typeLabels: CTYPE_LABELS,
    };
  },

  computed: {
    groupedContacts(): GroupedContactItemT[] {
      // Group contacts by type and ensure the sorting is according CONTACT_TYPES
      const contactsByType = groupBy<MerchantContactT>(
        prop('contactType'),
        this.contacts
      );

      const zfsContactTypes = (this.hasZfs && ZFS_CONTACT_TYPES) || [];

      const contactTypes = [...COMPANY_CONTACT_TYPES, ...zfsContactTypes];
      const companyMainContact = contactsByType[CTYPE_GENERAL];

      return contactTypes.map((ctype) => {
        const typeContacts = contactsByType[ctype] || companyMainContact || [];

        return {
          ctype,
          items: typeContacts,
        };
      });
    },
  },
});
</script>
