import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(VTextField, {
    ref: "textInput",
    attrs: {
      label: _vm.label,
      disabled: _vm.disabled,
      loading: _vm.loading,
      height: "40px",
      "background-color": "secondaryLight",
      "single-line": "",
    },
    on: { change: _vm.change, input: _vm.input, keydown: _vm.keydown },
    scopedSlots: _vm._u([
      {
        key: "prepend-inner",
        fn: function () {
          return [
            _c(
              VContainer,
              {
                staticClass: "search-icon-container",
                attrs: { "fill-height": "" },
              },
              [
                _c(VIcon, { attrs: { disabled: _vm.disabled } }, [
                  _vm._v("search"),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "append",
        fn: function () {
          return [
            _vm.searchQuery
              ? _c(
                  VContainer,
                  {
                    staticClass: "clear-icon-container",
                    attrs: { "fill-height": "" },
                    on: {
                      click: function ($event) {
                        return _vm.clear()
                      },
                    },
                  },
                  [
                    _c(VIcon, { attrs: { disabled: _vm.disabled } }, [
                      _vm._v("close"),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.searchQuery,
      callback: function ($$v) {
        _vm.searchQuery = $$v
      },
      expression: "searchQuery",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }