<template lang="pug">
div
  div(v-for="contactTypeContacts in contacts")
    div.dc-h4.ctype-label {{ typeLabels[contactTypeContacts.contactType] }}

    div(v-if="!contactTypeContacts.isGeneral")
      div(v-for="(contact, i) in contactTypeContacts.items")
        div.itemNoWrapper
          div.itemNo(v-if="i==1") Second Contact
          div.itemNo(v-if="i==2") Third Contact
          a.dc-btn.dc-btn--link(
            v-if="i == 1 || i == 2"
            @click="onDelete(contactTypeContacts, i)"
          )
            i.dc-icon.dc-icon--trash.dc-icon--btn

        form.mcp-admin-merchant-details-section-form(novalidate)
          //- First Name
          div.mcp-admin__form-field
            label.dc-label(
                :class="{'dc-label--is-error': hasErrorForFieldName(contact.contactType, contact.firstName), 'dc-label--required': isNameFieldRequired(contact.contactType)}"
              ) First name

            div.errors(v-if="hasErrorForFieldName(contact.contactType, contact.firstName)")
              span.dc--text-error Please don't leave this empty.

            input.dc-input.dc-input--block(
              type="text"
              :class="{'dc-input--is-error': hasErrorForFieldName(contact.contactType, contact.firstName)}"
              v-model="contact.firstName"
              required
              @change="$emit('form-changed')"
            )

          //- Last Name
          div.mcp-admin__form-field
            label.dc-label(
              :class="{'dc-label--is-error': hasErrorForFieldName(contact.contactType, contact.lastName), 'dc-label--required': isNameFieldRequired(contact.contactType)}"
              ) Last name

            div.errors(v-if="hasErrorForFieldName(contact.contactType, contact.lastName)")
              span.dc--text-error Please don't leave this empty.

            input.dc-input.dc-input--block(
              type="text"
              :class="{'dc-input--is-error': hasErrorForFieldName(contact.contactType, contact.lastName)}"
              v-model = "contact.lastName"
              required
              @change="$emit('form-changed')"
            )

          //- Email
          div.mcp-admin__form-field
            label.dc-label.dc-label--required(
              :class="{'dc-label--is-error': isSubmitted && !isEmailValid(contact.email)}"
              ) Email

            div.errors(v-if="isSubmitted && !isEmailValid(contact.email)")
              span.dc--text-error(v-if="isEmpty(contact.email)") Please don't leave this empty.
              span.dc--text-error(v-else="!isEmailValid(contact.email)") Please enter a valid email address.

            input.dc-input.dc-input--block(
              type="text"
              :class="{'dc-input--is-error': isSubmitted && !isEmailValid(contact.email)}"
              v-model="contact.email"
              required
              @change="$emit('form-changed')"
            )

          //- Phone
          div.mcp-admin__form-field
            label.dc-label(
              :class="{'dc-label--is-error': isSubmitted && !isPhoneValid(contact.phone)}"
              ) Phone

            div.errors(v-if="isSubmitted && !isPhoneValid(contact.phone)")
              span.dc--text-error(v-if="!isPhoneValid(contact.phone)") Please enter a valid phone.

            input.dc-input.dc-input--block(
              type="text"
              :class="{'dc-input--is-error': isSubmitted && !isPhoneValid(contact.phone)}"
              v-model="contact.phone"
              required
              @change="$emit('form-changed')"
            )

    //- Do not show the btn if the contact Type is General/Main or the contact is "Same as Main"
    div.add-another(v-if="showAddAnotherBtn(contactTypeContacts)")
      button.dc-btn.dc-btn--link(
          @click="addAnotherContact(contactTypeContacts)"
      ) Add another contact


  div.buttons
    div
      button.dc-btn.dc-btn--link(data-testid="cancel-btn" @click="$emit('cancel')") Cancel

      button.dc-btn.dc-btn--primary(
        data-testid="save-btn"
        :class="{'dc-btn--disabled': isSubmitting}"
        :disabled="isSubmitting"
        @click="save"
     ) Save
</template>

<script lang="ts">
import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { groupBy, prop } from 'ramda';
import {
  CTYPE_GENERAL,
  ZFS_CONTACT_TYPES,
  CTYPE_LABELS,
  CTYPE_CUSTOMER_ESCALATION,
  COMPANY_CONTACT_TYPES,
  COUNTRY_CONTACT_TYPES,
} from './company-details-contacts.constant';
import MerchantContactT, { ContactTypeT } from 'Api/models/MerchantContact';

type NonEmptyArray<T> = [T, ...T[]];

interface ContactTypeListForm {
  isGeneral: boolean;
  contactType: ContactTypeT;
  items: NonEmptyArray<MerchantContactT>;
}

function getNewContact(ctype: ContactTypeT): MerchantContactT {
  return {
    contactType: ctype,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };
}

export default Vue.extend({
  name: 'CompanyDetailsContactsFormContainer',

  props: {
    initialContacts: Array as () => MerchantContactT[],
    isSubmitting: Boolean,
    showUseMerchantContactsLink: Boolean,
    hasZfs: Boolean,
    isMarket: Boolean,
  },
  data() {
    const specificContactTypes = this.isMarket
      ? COUNTRY_CONTACT_TYPES
      : COMPANY_CONTACT_TYPES;
    // Group contacts by type and ensure the sorting is according CONTACT_TYPES
    const contactsByType = groupBy(
      prop('contactType'),
      cloneDeep(this.initialContacts)
    );
    const contactTypes = [
      ...specificContactTypes,
      ...((this.hasZfs && ZFS_CONTACT_TYPES) || []),
    ];

    const mainContact = contactsByType[CTYPE_GENERAL];

    const contacts = contactTypes.map((ctype) => {
      const fallbackContact = mainContact
        ? [
            {
              ...(mainContact[0] as MerchantContactT),
              contactType: ctype,
              id: undefined,
            },
          ]
        : [getNewContact(ctype)];

      return {
        isGeneral: false,
        contactType: ctype,
        items: contactsByType[ctype] || fallbackContact,
      };
    });

    return {
      isSubmitted: false,
      typeLabels: CTYPE_LABELS,
      contacts,
    };
  },
  methods: {
    showAddAnotherBtn(contact: ContactTypeListForm): boolean {
      return !this.isGeneral(contact.contactType) && contact.items.length < 3;
    },
    isGeneral(ctype: string): boolean {
      return ctype === CTYPE_GENERAL;
    },
    isEmpty(str: string): boolean {
      return str.trim() === '';
    },
    isEmailValid(email: string): boolean {
      const re =
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      return email.trim().length > 0 && re.test(email);
    },
    isPhoneValid(str: string): boolean {
      return this.isEmpty(str) || str.trim().length > 6;
    },
    isContactValid({
      firstName,
      lastName,
      email,
      phone,
      contactType,
    }: MerchantContactT): boolean {
      return (
        // If name fields are not required, then they must not be empty
        (!this.isNameFieldRequired(contactType) ||
          (!this.isEmpty(firstName) && !this.isEmpty(lastName))) &&
        this.isEmailValid(email) &&
        this.isPhoneValid(phone)
      );
    },
    isNameFieldRequired(contactType: ContactTypeT): boolean {
      return [CTYPE_GENERAL, CTYPE_CUSTOMER_ESCALATION].includes(contactType);
    },
    hasErrorForFieldName(
      contactType: ContactTypeT,
      fieldValue: string
    ): boolean {
      return (
        this.isSubmitted &&
        this.isNameFieldRequired(contactType) &&
        this.isEmpty(fieldValue)
      );
    },
    addAnotherContact(contactTypeContacts: ContactTypeListForm): void {
      contactTypeContacts.items.push(
        getNewContact(contactTypeContacts.contactType)
      );
    },
    save(): void {
      this.isSubmitted = true;
      const contacts = this.contacts
        .filter((contactTypeForm) => !contactTypeForm.isGeneral)
        .map((contactTypeForm) => contactTypeForm.items)
        .reduce((res, typeContacts) => [...res, ...typeContacts]);

      // Check if every contact is valid
      if (!contacts.every((contact) => this.isContactValid(contact))) {
        return;
      }
      this.$emit('save', contacts);
    },
    onDelete(contactTypeContacts: ContactTypeListForm, index): void {
      contactTypeContacts.items.splice(index, 1);
      this.$emit('form-changed');
    },
  },
});
</script>

<style lang="scss" scoped>
.itemNoWrapper {
  display: flex;
  margin: 10px 0 5px;
  align-items: center;
}
.itemNo {
  font-weight: 500;
}
.sameAsToggle {
  margin-bottom: 5px;
}
.ctype-label {
  margin-top: 20px;
  margin-bottom: 10px;
}
.errors {
  margin: 0;
  position: absolute;
  bottom: 0;
  font-size: 12px;
}
.add-another {
  display: flex;
  justify-content: flex-end;
}
.dc-input {
  margin-bottom: 24px;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
</style>
