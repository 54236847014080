import { Contract, NewContract } from 'Api/models/Contract';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { CONTRACT_REQ_LAUNCH_STATUSES } from 'Common/constants/contract-statuses.constant';

export interface Item {
  retailerId: string;
  salesChannelId: string;
  fulfillmentType: string;
}

const delimiter = '<::>';

export class ContractsSelection {
  private merchantId: string; // Current merchant id
  private contracts: Contract[]; // Existing contracts
  // Selected contracts - a Set of strings in the following format: <retailerId><delimiter><salesChannelId>
  private selectedItemsSet: Set<string>;
  private launchedSelectedItemsSet: Set<string>;

  // Initialise selectedItemsSet using contracts
  init(contracts: Contract[], merchant: MerchantProfile) {
    this.merchantId = merchant.$id;
    this.contracts = contracts;
    const selectedItemsArray = contracts
      .map(convertContractToItem)
      .map(serialiseItem);

    const launchedSelectedItemsArray = contracts
      .filter(
        (contract) =>
          contract.launchStatus === CONTRACT_REQ_LAUNCH_STATUSES.DONE
      )
      .map(convertContractToItem)
      .map(serialiseItem);

    this.selectedItemsSet = new Set(selectedItemsArray);
    this.launchedSelectedItemsSet = new Set(launchedSelectedItemsArray);
  }

  isSelected(item: Item): boolean {
    return this.selectedItemsSet.has(serialiseItem(item));
  }

  isDisabled(item: Item): boolean {
    return this.launchedSelectedItemsSet.has(serialiseItem(item));
  }

  toggle(item: Item): void {
    const key = serialiseItem(item);

    if (this.selectedItemsSet.has(key)) {
      this.selectedItemsSet.delete(key);
    } else {
      this.selectedItemsSet.add(key);
    }

    this.selectedItemsSet = new Set(this.selectedItemsSet);
  }

  /**
   * Get selected items
   */
  getItems() {
    return this.selectedItemsSet;
  }

  selectItems(items: Item[]) {
    items
      .map(serialiseItem)
      .forEach((itemKey) => this.selectedItemsSet.add(itemKey));

    this.selectedItemsSet = new Set(this.selectedItemsSet);
  }

  deselectItems(items: Item[]) {
    items
      .filter((item) => !this.isDisabled(item))
      .map(serialiseItem)
      .forEach((itemKey) => this.selectedItemsSet.delete(itemKey));

    this.selectedItemsSet = new Set(this.selectedItemsSet);
  }

  /**
   * Compound a list of contracts ids to delete, i.e. contracts that were unselected
   */
  getContractsToDelete(): string[] {
    return this.contracts
      .filter(
        (contract) =>
          !this.selectedItemsSet.has(
            serialiseItem(convertContractToItem(contract))
          )
      )
      .map((contract) => contract.$id);
  }

  /**
   * Compound a list of contracts data to create
   * (selected items with no existing contracts)
   */
  getContractsToCreate(): NewContract[] {
    // Compound a list of Items out of existing contracts
    const selectedItemsWithContractsSet = new Set(
      this.contracts.map((contract) =>
        serialiseItem(convertContractToItem(contract))
      )
    );

    return Array.from(this.selectedItemsSet)
      .filter((item) => !selectedItemsWithContractsSet.has(item))
      .map((item) => ({
        ...deserialiseItem(item),
        merchantId: this.merchantId,
      }));
  }
}

function serialiseItem({
  retailerId,
  salesChannelId,
  fulfillmentType,
}: Item): string {
  return [retailerId, salesChannelId, fulfillmentType].join(delimiter);
}

function deserialiseItem(itemKey: string): Item {
  const [retailerId, salesChannelId, fulfillmentType] =
    itemKey.split(delimiter);
  return { retailerId, salesChannelId, fulfillmentType };
}

function convertContractToItem({
  retailerId,
  salesChannelId,
  fulfillmentType,
}: Contract): Item {
  return { retailerId, salesChannelId, fulfillmentType };
}
