import { MerchantProfile } from 'Api/models/MerchantProfile';
import { Address } from 'Api/models/Address';
import { BankAccount } from 'Api/models/BankAccount';
import { MerchantFlowActions } from './merchant-flow.actions';
import { FlowStateSelector } from '../selectors/flow-state.selector';
import { FlowState } from '../flow.state';
import {
  MERCHANT_ADDRESS_LOADING,
  MERCHANT_BANK_ACCOUNT_LOADING,
  MERCHANT_NAME_LOADING,
  MERCHANT_CONTACT_LOADING,
  MERCHANT_DATA_LOADING,
} from '../reducers/merchant.reducer';
import { MerchantService } from '../../services/merchant.service';
import { ApiService } from 'Api/services/api.service';
import { RejectionScopes } from '@/api/models/Contract';
import { canEnableVatCollection } from '@/feature-flags';

export class MerchantActions {
  static $inject = [
    '$q',
    'mcpAdminFlowStateSelector',
    'mcpAdminMerchantFlowActions',
    'mcpAdminApi',
    'mcpAdminMerchantService',
    'mcpAdminDialogService',
  ];

  constructor(
    private q: ng.IQService,
    private flowSelector: FlowStateSelector,
    private merchantFlowActions: MerchantFlowActions,
    private api: ApiService,
    private merchantService: MerchantService
  ) {}

  updateMerchantAddress(address: Address) {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const merchant = this.flowSelector.getFlowMerchant(state);
      return this.merchantService
        .confirmMerchantStatusChangeToInProgress()
        .then(() => dispatch({ type: MERCHANT_ADDRESS_LOADING }))
        .then(() => {
          // Skip if the country stays the same or the address doesn't exist yet (newly created merchant)
          if (
            !merchant.address ||
            address.countryCode === merchant.address.countryCode
          ) {
            return;
          }

          // Otherwise make sure TaxId and VatId fields are emptied.
          // It is required, because their validation depends on the Merchant country
          return this.api.merchant.updateMerchant(merchant.$id, {
            vatId: '',
            taxId: '',
          });
        })
        .then(() =>
          this.api.merchantAddress.putMerchantAddress(merchant, address)
        )
        .then(() => dispatch(this.merchantFlowActions.setCurrentMerchant()))
        .then(() => dispatch(this.merchantFlowActions.setFlowStatus()));
    };
  }

  updateMerchantBankAccount(
    bankAccount: BankAccount,
    vatId: string,
    taxId: string
  ) {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const merchant = this.flowSelector.getFlowMerchant(state);
      const { q, api, merchantFlowActions } = this;

      return this.merchantService
        .confirmMerchantStatusChangeToInProgress()
        .then(() => dispatch({ type: MERCHANT_BANK_ACCOUNT_LOADING }))
        .then(() => {
          const isVatEnabled = canEnableVatCollection(merchant);
          const requests = [
            ...(isVatEnabled
              ? []
              : [api.merchant.updateMerchant(merchant.$id, { vatId, taxId })]),
            api.merchantBankAccount.putMerchantBankAccount(
              merchant,
              bankAccount
            ),
          ];
          return q.all(requests);
        })
        .then(() => dispatch(merchantFlowActions.setCurrentMerchant()))
        .then(() => dispatch(merchantFlowActions.setFlowStatus()));
    };
  }

  updateMerchantName(merchantData: MerchantProfile) {
    return (dispatch) => {
      return this.merchantService
        .confirmMerchantStatusChangeToInProgress()
        .then(() => dispatch({ type: MERCHANT_NAME_LOADING }))
        .then(() => dispatch(this.updateMerchant(merchantData)));
    };
  }

  updateMerchantContact(merchantData: MerchantProfile) {
    return (dispatch) => {
      return this.merchantService
        .confirmMerchantStatusChangeToInProgress()
        .then(() => dispatch({ type: MERCHANT_CONTACT_LOADING }))
        .then(() => dispatch(this.updateMerchant(merchantData)));
    };
  }

  updateMerchantData(merchantData: MerchantProfile) {
    return (dispatch) => {
      return this.merchantService
        .confirmMerchantStatusChangeToInProgress()
        .then(() => dispatch({ type: MERCHANT_DATA_LOADING }))
        .then(() => dispatch(this.updateMerchant(merchantData)));
    };
  }

  updateMerchant(merchantData: MerchantProfile) {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const currentMerchant = this.flowSelector.getFlowMerchant(state);
      const merchantDataToUpdate = { ...merchantData } as MerchantProfile;
      delete merchantDataToUpdate.status;
      return this.api.merchant
        .updateMerchant(currentMerchant.$id, merchantDataToUpdate)
        .then(() => dispatch(this.merchantFlowActions.setCurrentMerchant()))
        .then(() => dispatch(this.merchantFlowActions.setFlowStatus()));
    };
  }

  onMerchantContactUpdate() {
    return (dispatch): ng.IPromise<unknown> => {
      return dispatch(this.merchantFlowActions.setCurrentMerchant()).then(() =>
        dispatch(this.merchantFlowActions.setFlowStatus())
      );
    };
  }

  sendMerchantToReview() {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const currentMerchant = this.flowSelector.getFlowMerchant(state);
      return this.api.merchantStatus
        .markAsInFinancialReview(currentMerchant.$id)
        .then(() => dispatch(this.merchantFlowActions.setCurrentMerchant()))
        .then(() => dispatch(this.merchantFlowActions.setFlowStatus()));
    };
  }

  markMerchantAsFinancialApproved() {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const currentMerchant = this.flowSelector.getFlowMerchant(state);
      return this.api.merchantStatus
        .markAsFinancialApproved(currentMerchant.$id)
        .then(() => dispatch(this.merchantFlowActions.setCurrentMerchant()))
        .then(() => dispatch(this.merchantFlowActions.setCurrentContracts()))
        .then(() => dispatch(this.merchantFlowActions.setFlowStatus()));
    };
  }

  markMerchantAsFinancialRejected(
    reason: string,
    scope?: RejectionScopes,
    sections?: string[]
  ) {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const currentMerchant = this.flowSelector.getFlowMerchant(state);
      return this.api.merchantStatus
        .markAsFinancialRejected(currentMerchant.$id, reason, scope, sections)
        .then(() => dispatch(this.merchantFlowActions.setCurrentMerchant()))
        .then(() => dispatch(this.merchantFlowActions.setCurrentContracts()))
        .then(() => dispatch(this.merchantFlowActions.setFlowStatus()));
    };
  }
}
