<template lang="pug">
div
  div.field
    div.label Send email
    div {{ sendDummyEmail ? 'Dummy' : 'Customer\'s' }}
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    sendDummyEmail: {
      type: Boolean,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.field {
  margin-bottom: 15px;
}
.label {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
}
</style>
