import { mcb, ICountry } from 'mcb';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { Address } from 'Api/models/Address';
import { BankAccount } from 'Api/models/BankAccount';
import { MerchantActions } from 'Merchant/common/state/actions/merchant.actions';
import { MerchantFlowActions } from 'Merchant/common/state/actions/merchant-flow.actions';
import template from './company-details.html';
import { Unsubscribe } from 'redux';
import { FlowState } from 'Merchant/common/state/flow.state';
import { FlowStateSelector } from 'Merchant/common/state/selectors/flow-state.selector';
import { MerchantService } from 'Merchant/common/services/merchant.service';
import { USER_DID_NOT_CONFIRM_ERROR } from 'Common/constants/misc.constant';
import { ApiService } from 'Api/services/api.service';
import { Contract } from 'Api/models/Contract';
import { sendUpdateMerchantDataReason } from '@/api/endpoints/merchant/merchant.endpoint.es';
import { canEnableBankDetails, canEnableVatCollection } from '@/feature-flags';

export class CompanyDetailsComponent implements ng.IComponentOptions {
  static Factory() {
    return new CompanyDetailsComponent();
  }

  bindings: { [binding: string]: string } = {
    merchant: '<',
    isBusinessDetails: '<',
  };

  controller = EditDetailsController;
  controllerAs = 'ctrl';
  template: string = template;
}

interface DetailsFormsVisibility {
  companyName: boolean;
  address: boolean;
  returnAddress: boolean;
  bankAccount: boolean;
  merchantData: boolean;
}
interface DetailsFormsChangeReason {
  companyName: string;
  address: string;
  returnAddress: string;
  bankAccount: string;
  merchantData: string;
}

interface EditDetailsControllerVm {
  countries: ICountry[];
  merchant: MerchantProfile;
  formsVisibility: DetailsFormsVisibility;
  formsChangeReason: DetailsFormsChangeReason;
  currentEditField: string;
}

export class EditDetailsController implements EditDetailsControllerVm {
  static $inject = [
    'mcbToast',
    '$ngRedux',
    'mcpAdminMerchantActions',
    'mcpAdminMerchantFlowActions',
    'mcpAdminFlowStateSelector',
    'mcpAdminMerchantService',
    'mcpAdminApi',
    '$rootScope',
    '$state',
  ];

  // injected by component

  countries: ICountry[];
  merchant: MerchantProfile;
  returnAddress: Address;
  pfContracts: Contract[];
  zfsContracts: Contract[];
  isBusinessDetails: boolean;
  formsVisibility = {
    companyName: false,
    address: false,
    returnAddress: false,
    bankAccount: false,
    merchantData: false,
  };
  formsChangeReason = {
    companyName: '',
    address: '',
    returnAddress: '',
    bankAccount: '',
    merchantData: '',
  };
  unsubscribe: Unsubscribe;
  isNameLoading: boolean;
  isMerchantLoading: boolean;
  isAddressLoading: boolean;
  isReturnAddressLoading: boolean;
  isBankAccountLoading: boolean;
  isDataLoading: boolean;
  companyDataChangeReason: string;
  currentEditField: string;
  isVatEnabled: boolean;
  isBankDetailsChangesEnabled: boolean;
  private setFlowStatus: Function;

  constructor(
    private toast: mcb.IToast,
    private store,
    private merchantActions: MerchantActions,
    private merchantFlowActions: MerchantFlowActions,
    private flowSelector: FlowStateSelector,
    private merchantService: MerchantService,
    private api: ApiService,
    private $rootScope: ng.IScope,
    private state: ng.ui.IStateService
  ) {
    this.onSaveMerchantName = this.onSaveMerchantName.bind(this);
    this.onCancelMerchantName = this.onCancelMerchantName.bind(this);
    this.onEditMerchantName = this.onEditMerchantName.bind(this);
    this.onSaveAddress = this.onSaveAddress.bind(this);
    this.onCancelAddress = this.onCancelAddress.bind(this);
    this.onEditAddress = this.onEditAddress.bind(this);
    this.onSaveReturnAddress = this.onSaveReturnAddress.bind(this);
    this.onCancelReturnAddress = this.onCancelReturnAddress.bind(this);
    this.onEditReturnAddress = this.onEditReturnAddress.bind(this);
    this.onSaveBankAccount = this.onSaveBankAccount.bind(this);
    this.onCancelBankAccount = this.onCancelBankAccount.bind(this);
    this.onEditBankAccount = this.onEditBankAccount.bind(this);
    this.onSaveMerchantData = this.onSaveMerchantData.bind(this);
    this.onCancelMerchantData = this.onCancelMerchantData.bind(this);
    this.onEditMerchantData = this.onEditMerchantData.bind(this);
    this.confirmCb = this.confirmCb.bind(this);
    this.onContactsSaveSuccess = this.onContactsSaveSuccess.bind(this);
    this.onContactsSaveError = this.onContactsSaveError.bind(this);
  }

  $onInit(): void {
    this.unsubscribe = this.store.connect(
      this.mapStateToThis.bind(this),
      this.mapDispatchToThis()
    )(this);

    this.isVatEnabled = canEnableVatCollection(this.merchant);
    this.isBankDetailsChangesEnabled = canEnableBankDetails();

    if (!this.isCorrectUrl()) {
      this.state.go('mcp.admin.merchant.general.company-details', null, {
        location: 'replace',
      });
    }

    this.initReturnAddress();
  }

  $onDestroy(): void {
    this.unsubscribe();
  }

  isCorrectUrl(): boolean {
    return this.isVatEnabled && this.isBusinessDetails;
  }

  onSaveMerchantName(merchantData: MerchantProfile): void {
    this.store
      .dispatch(this.merchantActions.updateMerchantName(merchantData))
      .then(() => {
        this.formsVisibility.companyName = false;
        this.toast.show('Merchant company name successfully saved');
      })
      .catch(() => this.toast.error('Unable to save merchant company name'));

    this.sendCompanyDataChangeReason(this.formsChangeReason.companyName);
  }

  onCancelMerchantName(): void {
    this.formsVisibility.companyName = false;
  }

  onEditMerchantName(reason: string): void {
    this.formsVisibility.companyName = true;
    this.formsChangeReason.companyName = reason;
  }

  confirmCb(): ng.IPromise<unknown> {
    const merchant = this.flowSelector.getFlowMerchant(this.store.getState());
    return this.merchantService
      .confirmMerchantStatusChangeToInProgress()
      .then(() => this.api.merchantStatus.markAsInProgress(merchant.$id));
  }

  onContactsSaveSuccess(): void {
    this.store
      .dispatch(this.merchantActions.onMerchantContactUpdate())
      .then(() => this.toast.show('Merchant contacts successfully saved'));
  }

  onContactsSaveError(): void {
    this.toast.error('Unable to save merchant contacts');
  }

  /**
   * If the country is being change, then
   * - we need to show a confirmation message
   * - once confirmed, we need to empty taxId/vatId fields
   *
   * @param address
   */
  onSaveAddress(address: Address, vatId: string, taxId: string): void {
    const { toast, merchantActions, merchant } = this;
    const country = address.countryCode;
    // Set oldCountry to empty string if the address doesn't exist yet (newly created merchant)
    const oldCountry = (merchant.address && merchant.address.countryCode) || '';

    this.merchantService
      .confirmVatTaxDestroy(country, oldCountry)
      .then(() => {
        this.store.dispatch(merchantActions.updateMerchantAddress(address));
        if (vatId || taxId) {
          this.store.dispatch(
            merchantActions.updateMerchantData({
              vatId,
              taxId,
            } as MerchantProfile)
          );
        }
      })
      .then(() => {
        this.formsVisibility.address = false;
        toast.show('Merchant address successfully saved');
      })
      .catch((err) => {
        if (err !== USER_DID_NOT_CONFIRM_ERROR) {
          toast.error('Unable to save merchant address');
        }
      });

    this.sendCompanyDataChangeReason(this.formsChangeReason.address);
  }

  onCancelAddress(): void {
    this.formsVisibility.address = false;
  }

  onEditAddress(reason: string): void {
    this.formsVisibility.address = true;
    this.formsChangeReason.address = reason;
  }

  onSaveReturnAddress(address: Address): void {
    const { toast, merchant } = this;

    this.isReturnAddressLoading = true;

    this.api.rightsOfWithdrawal
      .changeReturnAddress(merchant.$id, address)
      .then((address) => {
        // @ts-ignore
        this.returnAddress = address;
        this.formsVisibility.returnAddress = false;
        toast.show('Return address address successfully saved');

        // Update requirements check marks
        return this.setFlowStatus();
      })
      .catch(() => toast.error('Unable to save return address'))
      .finally(() => {
        this.isReturnAddressLoading = false;
        this.$rootScope.$applyAsync();
      });

    this.sendCompanyDataChangeReason(this.formsChangeReason.returnAddress);
  }

  onCancelReturnAddress(): void {
    this.formsVisibility.returnAddress = false;
  }

  onEditReturnAddress(reason: string): void {
    this.formsVisibility.returnAddress = true;
    this.formsChangeReason.returnAddress = reason;
  }

  onSaveBankAccount(
    bankAccount: BankAccount,
    vatId: string,
    taxId: string
  ): void {
    this.store
      .dispatch(
        this.merchantActions.updateMerchantBankAccount(
          bankAccount,
          vatId,
          taxId
        )
      )
      .then(() => {
        this.formsVisibility.bankAccount = false;
        this.toast.show('Merchant bank account successfully saved');
      })
      .catch(() => this.toast.error('Unable to save merchant bank account'));

    this.sendCompanyDataChangeReason(this.formsChangeReason.bankAccount);
  }

  onCancelBankAccount(): void {
    this.formsVisibility.bankAccount = false;
  }

  onEditBankAccount(reason: string): void {
    this.formsVisibility.bankAccount = true;
    this.formsChangeReason.bankAccount = reason;
  }

  onSaveMerchantData(merchant: MerchantProfile): void {
    this.store
      .dispatch(this.merchantActions.updateMerchantData(merchant))
      .then(() => {
        this.formsVisibility.merchantData = false;
        this.toast.show('Merchant data successfully saved');
      })
      .catch(() => this.toast.error('Unable to save merchant data'));

    this.sendCompanyDataChangeReason(this.formsChangeReason.merchantData);
  }

  onCancelMerchantData(): void {
    this.formsVisibility.merchantData = false;
  }

  onEditMerchantData(reason: string): void {
    this.formsVisibility.merchantData = true;
    this.formsChangeReason.merchantData = reason;
  }

  sendCompanyDataChangeReason(reason: string): void {
    if (reason) {
      sendUpdateMerchantDataReason(this.merchant.$id, reason);
    }
  }

  private initReturnAddress(): void {
    this.isReturnAddressLoading = true;

    this.api.rightsOfWithdrawal
      .fetchReturnAddress(this.merchant.$id)
      .then((address) => (this.returnAddress = address))
      .finally(() => {
        this.isReturnAddressLoading = false;
        this.$rootScope.$applyAsync();
      });
  }

  private mapStateToThis(state: FlowState) {
    const { flowSelector } = this;
    return {
      isNameLoading: flowSelector.getFlowMerchantNameLoadingStatus(state),
      isMerchantLoading: flowSelector.getFlowMerchantLoadingStatus(state),
      isAddressLoading: flowSelector.getFlowMerchantAddressLoadingStatus(state),
      isBankAccountLoading:
        flowSelector.getFlowMerchantBankAccountLoadingStatus(state),
      isDataLoading: flowSelector.getFlowMerchantDataLoadingStatus(state),
      pfContracts: flowSelector.getFlowPFContracts(state),
      zfsContracts: flowSelector.getFlowZFSContracts(state),
    };
  }

  private mapDispatchToThis() {
    const { merchantFlowActions } = this;

    return {
      setFlowStatus: () => merchantFlowActions.setFlowStatus(),
    };
  }
}
