/**
 * @typedef {Object} FirewallHandler
 */

/**
 * A on-the fly service declaration, you can inject services, must return
 * a function that return a promise.
 * The function will take a FirewallRule as an argument
 *
 * @name FirewallHandler#isGranted
 * @type {Array|Function}
 * @returns {Function}
 */

/**
 * This function should be able to merge multiple configuration in one.
 *
 * @name FirewallHandler#merge
 * @function
 * @param {Array<FirewallRule>}
 * @return FirewallRule
 */

/**
 * @name mcbFirewallProvider
 * @description
 * This provider is used to configure available firewall handlers
 * to prevent transitions to secured states (ui router).
 * Must be used with the ui router, you can define a state firewalled
 * by adding a "firewalls" key into the state definition.
 *
 * @example
 * ```
 * // register a firewall handler inline:
 * mcbFirewallProvider.add('oauth2', {
 *    isGranted: ['oauthService', function (oauthService) {
 *      return function (firewallRule) {
 *        // ... do your logic to login
 *        // and return a Promise
 *      }
 *    }]
 * });
 *
 * // or register a firewall handler as a service:
 * mcbFirewallProvider.add('oauth2', 'oauth2FirewallHandler');
 *
 * // declare a state as firewalled:
 * $stateProvider.state('mySecuredState', {
 *   url: '/my-secured-url',
 *   // ...
 *   firewalls: { // firewalls rules
 *      'oauth2' : {
 *        scopes: ['basic', 'user.info']
 *      }
 *   }
 * });
 * ```
 *
 * When a state contains a firewall definition it will fire the firewall process:
 *
 * - Check if the firewall handler with name = 'oauth2' exists
 * - Invoke **isGranted** method of the related firewall handlers
 */
let mcbFirewallProvider = function mcbFirewallProvider(
  $$mcbFirewallRegistryProvider
) {
  /**
   * @type FirewallRegistry
   */
  let registry = $$mcbFirewallRegistryProvider.get();

  /**
   * Get a firewall handler by name
   * @param {String} name - the firewall name
   * @returns {FirewallHandler|undefined} - the firewall or undefined if it doesn't exist
   */
  this.get = function (name) {
    return registry.get(name);
  };

  /**
   * Add a firewall handler
   * @param {string} name - the firewall name
   * @param {FirewallHandler} firewallHandler - firewall must implement the FirewallHandler "interface"
   */
  this.add = function (name, firewallHandler) {
    registry.add(name, firewallHandler);
    return this;
  };

  this.$get = [
    '$q',
    '$$mcbFirewallManager',
    '$transitions',
    '$$mcbFirewallRegistry',
    function ($q, $$mcbFirewallManager, $transitions, $$mcbFirewallRegistry) {
      let cache = {};

      /**
       * @param {FirewallRule} rule
       * @returns {boolean}
       */
      let hasFirewallHandler = function (rule) {
        return $$mcbFirewallRegistry.has(rule.getName());
      };

      /**
       * @param {FirewallRule} rule
       * @returns {{rule: FirewallRule, isGranted: (Function)}}
       */
      let buildHandler = function (rule) {
        let firewallHandler = $$mcbFirewallRegistry.get(rule.getName());
        return {
          rule: rule,
          isGranted: firewallHandler.isGranted.bind(firewallHandler),
        };
      };

      /**
       * @param {object} state
       * @returns {{rule: FirewallRule, isGranted: (Function)}}
       */
      let buildHandlers = function (state) {
        if (cache[state.name]) {
          return cache[state.name];
        }

        cache[state.name] = $$mcbFirewallManager
          .getRules(state)
          .filter(hasFirewallHandler)
          .map(buildHandler);

        return cache[state.name];
      };

      return {
        /**
         * Check if we have one or multiple firewalls applied to this state
         * @param state
         * @returns {boolean}
         */
        hasHandlers: function (state) {
          return buildHandlers(state).length > 0;
        },
        /**
         * Call the stack of firewall handlers
         * @param state
         * @returns {Promise}
         */
        isGranted: function (state) {
          let handlers = buildHandlers(state);
          let promises = handlers.map(function (obj) {
            return obj.isGranted(obj.rule);
          });
          return $q.all(promises);
        },
        /**
         * This function is called in the run block of the firewall module.
         * Registers a transition lifecycle hook, which is invoked as a transition starts running.
         * The HookMatchCriteria { to: (state) => { // ... } } is used to understand if the hook should be invoked
         * or not (invoked if the state has firewall handlers. NOTE: we are traversing also the parent states to find handlers)
         */
        $$registerOnTransitionStartHook: function () {
          $transitions.onStart(
            { to: (state) => this.hasHandlers(state) },
            (trans) => this.isGranted(trans.$to())
          );
        },
      };
    },
  ];
};
mcbFirewallProvider.$inject = ['$$mcbFirewallRegistryProvider'];

/**
 * FirewallRegistry
 * @description The firewall registry is responsible to register available firewall handlers
 * @constructor
 */
function FirewallRegistry() {
  this._cache = {};
  this._registry = {};
}

/**
 * Validate the "interface" of a firewall handler
 * @param {String} name
 * @param {FirewallHandler} firewallHandler
 */
FirewallRegistry.prototype.validate = function (name, firewallHandler) {
  if (angular.isString(firewallHandler)) {
    // is a service, we can't validate now
    return;
  }
  if (this.has(name)) {
    throw new Error(
      'Firewall handler name must be unique. "' +
        name +
        '" firewall handler already registered.'
    );
  }
  if (!angular.isObject(firewallHandler)) {
    throw new Error('The "' + name + '" firewall handler must be an object.');
  }
  if (
    !angular.isArray(firewallHandler.isGranted) &&
    !angular.isFunction(firewallHandler.isGranted)
  ) {
    throw new Error(
      'The "' +
        name +
        '" firewall handler must have a "isGranted" method that return a function that when invoked return a promise.'
    );
  }
  if (firewallHandler.merge && !angular.isFunction(firewallHandler.merge)) {
    throw new Error(
      'If the "' +
        name +
        '" firewall handler has a "merge" property, it must be a function.'
    );
  }
};

/**
 * Check if a firewall handler with provided name exists.
 * @param {String} name - the firewall name
 * @returns {boolean}
 */
FirewallRegistry.prototype.has = function (name) {
  return this.get(name) ? true : false;
};

/**
 * Add a firewall handler to the registry
 * @param {String} name - the firewall name
 * @param {FirewallHandler} firewallHandler
 */
FirewallRegistry.prototype.add = function (name, firewallHandler) {
  this.validate(name, firewallHandler);
  this._registry[name] = firewallHandler;
  return this;
};

/**
 * Get a firewall handler by name
 * @param {String} name
 * @returns {Firewall|undefined} the firewall or undefined it it doesn't exist
 */
FirewallRegistry.prototype.get = function (name) {
  return this._registry[name];
};

/**
 *
 */
let $$mcbFirewallRegistryProvider = function $$mcbFirewallRegistryProvider() {
  let registry = new FirewallRegistry();

  /**
   * Get the registry
   * @returns {FirewallRegistry}
   */
  this.get = function () {
    return registry;
  };

  this.$get = [
    '$injector',
    function ($injector) {
      let cache = {};

      return {
        /**
         * Check if firewall handler for the given name exists
         * @param {String} name
         * @returns {boolean}
         */
        has: function (name) {
          return registry.has(name);
        },
        /**
         * Get a firewall handler by name
         * @param name
         * @returns {FirewallHandler|undefined}
         */
        get: function (name) {
          let firewall;
          if (!this.has(name)) {
            return;
          }

          firewall = registry.get(name);
          if (cache[name]) {
            return cache[name];
          }

          if (angular.isString(firewall)) {
            cache[name] = $injector.get(firewall);
          } else {
            firewall.isGranted = $injector.invoke(firewall.isGranted);
            cache[name] = firewall;
          }
          return cache[name];
        },
        /**
         * Check if a firewall handler support merge
         * @param {string} name
         * @returns {boolean}
         */
        supportMerge: function (name) {
          let firewallHandler = this.get(name);
          return firewallHandler && typeof firewallHandler.merge === 'function';
        },
      };
    },
  ];
};

export { $$mcbFirewallRegistryProvider, mcbFirewallProvider };
