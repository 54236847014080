<template lang="pug">
v-card(width="858")
  v-toolbar(flat height="72" color="white")
    v-toolbar-title.secondary--text Size Chart Preview
    v-spacer
    v-btn(icon @click="$emit('close')")
      v-icon(color="secondary") close

  v-divider

  v-card-text.loading.text-center(v-if="loading")
    v-progress-circular(indeterminate color="secondaryDark")

  v-card-text.px-6.pt-4.secondary--text(v-else)
    div.subtitle-1.pb-4 {{ title }}

    table.table
      thead
        tr
          th.caption.header-col Supplier Size
          th.caption.header-col.header-col--country-header(v-for="country in conversions") {{ country }}
      tbody.table-body
        tr.table-row(v-for="sizeItem in sizeChart.sizes")
          td.body-col {{ sizeItem.supplierSize }}
          td.body-col(v-for="conversion in sizeItem.conversions") {{ conversion.raw }}

    v-list(v-if="isSizeChartToggleEnabled && sizeChart.certificateIds && sizeChart.certificateIds.length" flat)
      div.subtitle-1 Proof Documents:
      v-list-item(
        v-for="certificateId in sizeChart.certificateIds"
        :key="certificateId"
      )
        ConfirmationCertificate(:certificate-id="certificateId")
</template>

<script lang="ts">
import Vue from 'vue';
import SizeChartGrid from 'Api/models/SizeChartGrid';
import ConfirmationCertificate from 'Common/components/masterdata/size-chart-preview/confirmation-certificate.vue';
import { canEnableSizeChartChanges } from '@/feature-flags';

interface SizeChartInfo extends SizeChartGrid {
  certificateIds: string[];
}

export default Vue.extend({
  components: { ConfirmationCertificate },
  props: {
    sizeChart: {
      type: Object as () => SizeChartInfo,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      const { legacyCode, description } = this.sizeChart;
      return `${legacyCode} - ${description}`;
    },
    conversions(): Array<string> {
      return this.sizeChart.sizes[0].conversions.map(
        (conversion) => conversion.cluster
      );
    },
    isSizeChartToggleEnabled(): boolean {
      return canEnableSizeChartChanges();
    },
  },
});
</script>

<style lang="scss" scoped>
.loading {
  padding: 150px 0;
}
.table {
  border-collapse: collapse;
}
.table-body {
  display: block;
  max-height: 400px;
  overflow-y: scroll;
}
thead tr {
  display: block;
}
.table-row {
  height: 52px;
  border-bottom: 1px solid #e0e0e0;
}
.table-row:last-child {
  border-bottom: none;
}
.header-col {
  width: 134px;
  text-align: center;
}
.header-col--country-header {
  text-transform: uppercase;
}
.body-col {
  min-width: 134px;
  text-align: center;
}
</style>
