import { mcb } from 'mcb';
import coreModule from 'Common/core.module';
import MerchantRegisterComponent from './components/merchant-register/merchant-register.component.vue';
import { MCP_ADMIN_ROLES } from 'Common/constants/roles.constant';
import wrapVue from 'Common/wrap-vue.converter';

export default angular
  .module('mcp.admin.register', [coreModule.name])

  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider): void => {
      $stateProvider.state('mcp.admin.register', <mcb.firewall.IMcbState>{
        url: '/register',
        template: `<mp-merchant-register></mp-merchant-register>`,
        firewalls: {
          iam: {
            roles: [
              MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
              MCP_ADMIN_ROLES.STOCK_MANAGER,
              MCP_ADMIN_ROLES.SUPER_ADMIN,
            ],
          },
        },
      });
    },
  ])
  .component(
    'mpMerchantRegister',
    wrapVue(MerchantRegisterComponent, 'mpMerchantRegister')
  );
