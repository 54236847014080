import { ValidationResultT } from 'Api/models/Vat';
import { ApprovalStatusT } from 'Api/models/ApprovalStatus';

export enum FixedEstablishmentTypeT {
  EU = 'EU',
  UK = 'UK',
}

export interface FixedEstablishmentAddressT {
  addressLine1: string;
  addressLine2: string;
  city: string;
  countryCode: string;
  zipCode: string;
  certificateId: string;
  lastValidatedAt: string;
  validationResult: ValidationResultT;
}

export interface FixedEstablishmentVatT {
  vatId: string;
  certificateId: string;
  lastValidatedAt: string;
  validationResult: ValidationResultT;
}

export interface FixedEstablishmentVatRegistryStatusT {
  lastValidatedAt: string;
  validationResult: ValidationResultT;
}

export interface FixedEstablishmentAllStatusT {
  vat: FixedEstablishmentVatRegistryStatusT;
  approvalStatus: ApprovalStatusT;
  rejectionReason: string;
}

export interface FixedEstablishmentResponseT {
  address?: FixedEstablishmentAddressT;
  vat?: FixedEstablishmentVatT;
  hasFixedEstablishment: boolean;
  approvalStatus: ApprovalStatusT;
  rejectionReason: string;
}
