/*eslint no-use-before-define: 0 */

let mcbJsonapiResourceBehavior = function mcbJsonapiResourceBehavior(
  mcbHttpTransformers,
  McbJsonapiResourceCollection,
  mcbReflection,
  $http,
  $log
) {
  let types = {};
  let service;

  /**
   * Add $links, $meta and $included methods to an array of mcbResource(s)
   *
   * @param {Array} specialArrayFrom
   * @param {Array} specialArrayTo
   */
  function copySpecialPropsFrom(specialArrayFrom, specialArrayTo) {
    if (
      angular.isArray(specialArrayFrom) &&
      angular.isArray(specialArrayFrom)
    ) {
      ['$links', '$meta', '$included'].forEach(function (specialProp) {
        if (specialArrayFrom[specialProp]) {
          specialArrayTo[specialProp] = specialArrayFrom[specialProp];
        }
      });
    }
  }

  /**
   * Add relationships http methods
   *
   * @param {Resource} resource
   * @returns {Resource} resource - the modified resource
   */
  function addRelationshipsMethods(resource) {
    if (!resource.$relationships) {
      return resource;
    }
    let schema = service.schema(resource.$type);

    Object.keys(resource.$relationships).forEach(function (prop) {
      if (!schema.relationships || !schema.relationships[prop]) {
        return resource;
      }
      let relationship = resource.$relationships[prop];
      let RelationshipClass = service.type(schema.relationships[prop].type);
      addRelationshipHttpGetMethod(RelationshipClass, relationship);
    });
    return resource;
  }

  /**
   * Add $get method to the relationship to actually fetch the related resource with HTTP
   *
   * @param {Resource} Resource - Resource class represents relationship type
   * @param {object} relationship - relationship json api object
   */
  function addRelationshipHttpGetMethod(Resource, relationship) {
    relationship.$get = function (params) {
      return $http({
        method: 'GET',
        url: relationship.links.related,
        params: params,
        headers: Resource.DEFAULTS.headers,
        timeout: Resource.DEFAULTS.timeout,
        transformResponse: Resource.DEFAULTS.transformResponse,
      }).then(function (response) {
        if (angular.isArray(response.data)) {
          let collection =
            McbJsonapiResourceCollection.createFromResponse(response);
          collection.forEach(addRelationshipsMethods);
          return collection;
        }
        let resource = mcbReflection.genesis(Resource, [response.data]);
        resource = addRelationshipsMethods(resource);
        return resource;
      });
    };
  }

  function UnregisteredTypeError(type) {
    this.name = 'UnregisteredTypeError';
    this.message = 'The requested type "' + type + '" is not registered.';
    $log.error('mcbJsonapiResourceBehavior: ' + this.message, type);
  }

  UnregisteredTypeError.prototype = Error.prototype;

  service = {
    UnregisteredTypeError: UnregisteredTypeError,
    /**
     * Register a "Class" for a type
     * or get a "Class" for a type
     * @param {String} type
     * @param {Function} [Class]
     * @param {Object} [schema]
     * @returns {service}
     */
    type: function (type, Class, schema) {
      if (arguments.length === 1) {
        if (!service.hasType(type)) {
          throw new service.UnregisteredTypeError(type);
        }
        return types[type];
      }

      types[type] = Class;
      types[type].SCHEMA = schema || {};
      return this;
    },
    /**
     * Get the schema for an existent registered type
     * @param {string} type
     * @returns {Object|{}|*}
     */
    schema: function (type) {
      return this.type(type).SCHEMA;
    },
    /**
     * Check if a type is registered
     * @param {string} type
     * @returns {boolean}
     */
    hasType: function (type) {
      return types[type] ? true : false;
    },
    // behavior
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
    transformRequest: [mcbHttpTransformers.dejsonapify],
    transformResponse: [mcbHttpTransformers.jsonapify],
    interceptor: {
      response: function (response) {
        if (angular.isUndefined(response.resource)) {
          return response;
        }
        if (angular.isArray(response.resource)) {
          copySpecialPropsFrom(response.data, response.resource);
          let collection =
            McbJsonapiResourceCollection.createFromResponse(response);
          collection.forEach(addRelationshipsMethods);
          return collection;
        }
        let resource = response.resource;
        resource = addRelationshipsMethods(resource);
        return resource;
      },
    },
    resourceDecorator: function (Resource) {
      let super$update = Resource.prototype.$update;

      /**
       * Proxy/override $update original method
       * allowing partial updates by providing attributes
       * that you wanna include when PATCH
       *
       * @param {Object} [params]
       * @param {Array<String>} [attributes]
       */
      Resource.prototype.$update = function (params, attributes) {
        let copy = angular.copy(this);
        let includeAttributes = ['$id', '$type'];

        if (angular.isArray(attributes)) {
          includeAttributes = includeAttributes.concat(attributes);
          for (let prop in copy) {
            if (
              copy.hasOwnProperty(prop) &&
              includeAttributes.indexOf(prop) === -1
            ) {
              delete copy[prop];
            }
          }
        }
        return super$update.apply(copy, [params]);
      };
    },
  };

  return service;
};

mcbJsonapiResourceBehavior.$inject = [
  'mcbHttpTransformers',
  'McbJsonapiResourceCollection',
  'mcbReflection',
  '$http',
  '$log',
];

export default mcbJsonapiResourceBehavior;
