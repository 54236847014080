const VALUES = {
  IN_PROGRESS: 'IN_PROGRESS',
  IN_FINANCIAL_REVIEW: 'IN_FINANCIAL_REVIEW',
  FINANCIAL_APPROVED: 'FINANCIAL_APPROVED',
  FINANCIAL_REJECTED: 'FINANCIAL_REJECTED',
  DEACTIVATED: 'DEACTIVATED',
};

const LABELS = {
  [VALUES.IN_PROGRESS]: 'In progress',
  [VALUES.IN_FINANCIAL_REVIEW]: 'Awaiting financial review',
  [VALUES.FINANCIAL_APPROVED]: 'Financial data approved',
  [VALUES.FINANCIAL_REJECTED]: 'Financial data rejected',
  [VALUES.DEACTIVATED]: 'Deactivated',
};

const COLORS = {
  [VALUES.IN_PROGRESS]: '#9b9b9b',
  [VALUES.IN_FINANCIAL_REVIEW]: '#f58b00',
  [VALUES.FINANCIAL_APPROVED]: '#7ed321',
  [VALUES.FINANCIAL_REJECTED]: '#d0021b',
  [VALUES.DEACTIVATED]: '#d0021b',
};

const OPTIONS = Object.values(VALUES).map((value) => ({
  value,
  text: LABELS[value],
}));

OPTIONS.unshift({ value: '', text: 'All' });

export const MCP_MERCHANT_STATUSES = VALUES;
export const MCP_MERCHANT_STATUS_LABELS = LABELS;
export const MCP_MERCHANT_STATUS_OPTIONS = OPTIONS;
export const MCP_MERCHANT_STATUS_COLORS = COLORS;
