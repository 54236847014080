import { mcb } from 'mcb';
import template from './configuration-form.html';
import generateConfigFormTemplate from './generate-config-form.html';
import { Configuration } from 'Api/models/Configuration';
import { Partner, Contract } from 'Api/models/Contract';
import { ConfigurationService } from 'Merchant/common/services/configuration.service';
import { ApiService } from 'Api/services/api.service';
import { DialogService } from 'Common/services/dialog.service';
import { USER_DID_NOT_CONFIRM_ERROR } from 'Common/constants/misc.constant';
import { PROVIDERS } from 'Merchant/merchant.constant';
import { ContextParams } from 'Common/constants/configuration.constant';
import { ConfigFieldsVisibilityT } from 'Merchant/contract/configuration.helper';

export class ContractConfigurationFormComponent
  implements ng.IComponentOptions
{
  static Factory() {
    return new ContractConfigurationFormComponent();
  }

  bindings: { [binding: string]: string } = {
    configuration: '<',
    contractConfig: '<',
    merchantName: '<',
    fieldsVisibility: '<',
    channelCountryCode: '<',
    partnerProviderId: '<',
    partners: '<',
    partner: '<',
    isConfigurationLoading: '<',
    isZfs: '<',
    onPartnerChange: '<',
    onSave: '<',
  };
  controller = ContractConfigurationFormController;
  controllerAs = 'ctrl';
  template: string = template;
}

class ContractConfigurationFormController
  implements angular.IComponentController
{
  static $inject = [
    '$q',
    'mcpAdminApi',
    'mcbToast',
    'mcpAdminConfigurationService',
    'mcpAdminDialogService',
  ];

  form: ng.IFormController;
  formData: Configuration;
  contractFormData: Contract;
  providers: any[] = [];
  disableUrls = false;
  disableASR = false;

  // From bindings
  configuration: Configuration;
  contractConfig: Contract;
  merchantName: string;
  channelCountryCode: string;
  partnerProviderId: number;
  partners: Partner[];
  partner: Partner;
  isConfigurationLoading: boolean;
  isZfs: boolean;
  fieldsVisibility: ConfigFieldsVisibilityT | null;
  onPartnerChange: Function;
  onSave: Function;

  constructor(
    private q: ng.IQService,
    private api: ApiService,
    private toast: mcb.IToast,
    private configurationService: ConfigurationService,
    private dialogService: DialogService
  ) {}

  $onInit(): void {
    this.providers = PROVIDERS;
  }

  /**
   * Whenever configuration changes from the above component
   * (tab changed, configuration refetched after update),
   * we need to reset our form
   * @param changes
   */
  $onChanges(changes): void {
    if (changes.configuration) {
      this.formData = angular.copy(this.configuration);
      this.disableUrls = this.isUrlGenerationAvailable();
      this.disableASR = this.isUrlGenerationAvailable();

      if (this.form) {
        this.form.$setPristine();
      }
    }

    if (changes.contractConfig) {
      this.contractFormData = { ...this.contractConfig };
    }
  }

  isUrlGenerationAvailable(): boolean {
    const { partnerProviderId } = this;

    return (
      !!partnerProviderId &&
      this.configurationService.isConfigGenerationAvailable(partnerProviderId)
    );
  }

  openUrlGenerationDialog(): void {
    const { partnerProviderId } = this;
    const { merchantName, toast, q, channelCountryCode, isZfs } = this;
    const { locale } = this.partner;

    // Fetch PartnerProviders data used to generate configuration
    const providersDefaultsPromise =
      this.api.configuration.fetchPartnerProviders();

    const catchFn = (error) => {
      if (error === USER_DID_NOT_CONFIRM_ERROR) {
        return;
      }

      toast.error('Something went wrong while generating the config');

      return q.reject(error);
    };

    providersDefaultsPromise
      .then((providersDefaults) => {
        const providerDefaults = providersDefaults.get(partnerProviderId);

        const fields = this.configurationService.getProviderFormFields(
          partnerProviderId,
          {
            countryCode: channelCountryCode,
            isZfs,
            locale,
            locales: this.partners.map((partner) => partner.locale),
            providerDefaults,
          }
        );

        return [providerDefaults, fields];
      })
      .then(([providerDefaults, fields]) => {
        this.dialogService
          .openConfirmationDialog(generateConfigFormTemplate, { fields })
          // Fetch PartnerProviders data used to generate configuration
          // and merge with form parameters and context parameters
          .then((formParams) => {
            const contextParams: ContextParams = {
              merchantName,
              channelCountryCode,
              isZfs,
              locale,
            };

            return {
              ...formParams,
              ...providerDefaults,
              ...contextParams,
            };
          })
          // Generate the config and assign to the form
          .then(
            (params) =>
              (this.formData = this.configurationService.generateConfig(
                partnerProviderId,
                params,
                this.formData
              ))
          )
          .catch(catchFn);
      })
      .catch(catchFn);
  }

  save(): void {
    const { form } = this;

    // Set the form submitted to show possible errors
    form.$setSubmitted();

    if (!form.$valid) {
      return;
    }

    this.onSave(this.contractFormData, this.formData);
  }
}
