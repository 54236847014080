/**
 * @ngdoc provider
 * @name mcbMenu
 *
 * @requires mcbUtil
 */
let menuProvider = function menuProvider(mcbUtil) {
  function MenuMap() {
    // smth
  }

  MenuMap.prototype.toSortedArray = function () {
    let array = [];

    for (let prop in this) {
      if (this.hasOwnProperty(prop)) {
        let o = this[prop];
        o.key = prop;
        array.push(o);
      }
    }
    return mcbUtil.sortBy(array, (o) => {
      return o.index;
    });
  };
  let entries = new MenuMap();
  let sections = new MenuMap();

  this.entry = function (name, entryDefinition) {
    entries[name] = entryDefinition;
    return this;
  };

  this.section = function (name, sectionDefinition) {
    sections[name] = sectionDefinition;
    return this;
  };

  this.$get = [
    function () {
      return {
        entries: function () {
          return entries;
        },
        sections: function () {
          return sections;
        },
        getList: function () {
          let sections = this.sections().toSortedArray();
          let entries = this.entries().toSortedArray();
          let items = [];
          sections.forEach((sectionDefinition) => {
            let section = angular.copy(sectionDefinition);
            section.isSection = true;

            items.push(section);

            items = items.concat(
              mcbUtil.filter(entries, (entry) => {
                return entry.section === section.key;
              })
            );
          });
          return items;
        },
      };
    },
  ];

  return this;
};

menuProvider.$inject = ['mcbUtil'];

export default menuProvider;
