import { MerchantProfile } from 'Api/models/MerchantProfile';
import template from './merchant-data.html';

export class MerchantDataComponent implements ng.IComponentOptions {
  static Factory() {
    return new MerchantDataComponent();
  }

  bindings: { [binding: string]: string } = {
    merchant: '=',
  };

  controller = MerchantDataController;
  controllerAs = 'ctrl';
  template: string = template;
}

class MerchantDataController implements ng.IController {
  static $inject = [];

  // injected by component
  merchant: MerchantProfile;
  languagesMap = {
    'en-GB': 'English',
    'de-DE': 'German',
  };

  $onInit() {
    // Empty block
  }
}
