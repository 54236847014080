import { BankAccountListItemT } from 'Merchant/common/components/bank-account-details/bank-account-review-item.vue';
import { ApprovalStatusT } from 'Api/models/ApprovalStatus';
import { updateBankAccountStatus } from 'Api/endpoints/merchant/merchant-bank-accounts.endpoint';

interface CompanyDetailsReviewSubmitHandlerPropT {
  merchantId: string;
  bankAccountList: BankAccountListItemT[];
}

export const handleReviewSubmit = async ({
  merchantId,
  bankAccountList,
}: CompanyDetailsReviewSubmitHandlerPropT): Promise<void> => {
  for (let i = 0; i < bankAccountList.length; i++) {
    const bankAccount = bankAccountList[i];
    if (bankAccount.status === ApprovalStatusT.IN_REVIEW) {
      const data = {
        status: bankAccount.selectedStatus,
        ...(bankAccount.selectedStatus === ApprovalStatusT.REJECTED && {
          rejectionReason: bankAccount.providedRejectionReason,
        }),
      };
      await updateBankAccountStatus({
        merchantId,
        currencyCode: bankAccount.currencyCode,
        data,
      });
    }
  }
};
