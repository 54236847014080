import * as Raven from 'raven-js';
import * as configService from 'Common/services/config.service';

const isMonitoringActive = configService.isSentryActive();

const monitoringHelper = {
  reportMessage(msg: string, options: any) {
    if (isMonitoringActive) {
      Raven.captureMessage(msg, options);
    }
  },
};

export default monitoringHelper;
