import coreModule from 'Common/core.module';
import generalModule from './general/general.module';
import merchantContractModule from './contract/contract.module';
import salesChannelModule from './sales-channel/channel.module';
import { AddressFormComponent } from './common/components/address-form/address-form.component';
import { BankAccountFormComponent } from './common/components/bank-account-form/bank-account-form.component';
import { MerchantDataFormComponent } from './common/components/merchant-data-form/merchant-data-form.component';
import { AddressComponent } from './common/components/address/address.component';
import { BankAccountComponent } from './common/components/bank-account/bank-account.component';
import ContractBillingData from './common/components/billing-data/billing-data.vue';
import { MerchantDataComponent } from './common/components/merchant-data/merchant-data.component';
import { MerchantNameComponent } from './common/components/merchant-name/merchant-name.component';
import { MerchantNameFormComponent } from './common/components/merchant-name-form/merchant-name-form.component';
import { MerchantActions } from './common/state/actions/merchant.actions';
import { ContractActions } from './common/state/actions/contract.actions';
import { MerchantFlowActions } from './common/state/actions/merchant-flow.actions';
import { FlowStateSelector } from './common/state/selectors/flow-state.selector';
import { MerchantContainer } from './merchant.container';
import { SidebarContractMenuComponent } from './common/components/sidebar-contract-menu/sidebar-contract-menu.component';
import { SidebarContractMenuItemComponent } from './common/components/sidebar-contract-menu-item/sidebar-contract-menu-item.component';
import { SidebarContractMenuChannelItemComponent } from './common/components/sidebar-contract-menu-channel-item/sidebar-contract-menu-channel-item.component';
import { ConfigurationActions } from './common/state/actions/configuration.actions';
import { MerchantService } from './common/services/merchant.service';
import { RetailerService } from './common/services/retailer.service';
import { ConfigurationService } from './common/services/configuration.service';
import wrapVue from '../common/wrap-vue.converter';
import MerchantHeader from './header/Header.vue';
import ComplianceStatusLabel from './common/components/compliance-status-label/compliance-status-label.vue';
import BillingAndBankDetailsContainer from 'Merchant/common/components/billing-and-bank-details-form/billing-and-bank-details-container.vue';

export default angular
  .module('mcp.merchant', [
    merchantContractModule.name,
    salesChannelModule.name,
    generalModule.name,
    coreModule.name,
  ])
  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider): void => {
      $stateProvider.state('mcp.admin.merchant', {
        url: '/merchants/{merchantId: [A-z0-9-]+}',
        abstract: true,
        template: `<mcp-admin-merchant-container></mcp-admin-merchant-container>`,
      });
    },
  ])
  .component('mcpAdminMerchantContainer', MerchantContainer.Factory())
  .component('mcpAdminAddress', AddressComponent.Factory())
  .component('mcpAdminAddressForm', AddressFormComponent.Factory())
  .component('mcpAdminBankAccount', BankAccountComponent.Factory())
  .component('mcpAdminBankAccountForm', BankAccountFormComponent.Factory())
  .component(
    'mcpAdminContractBillingData',
    wrapVue(ContractBillingData, 'mcpAdminContractBillingData')
  )
  .component('mcpAdminMerchantDataForm', MerchantDataFormComponent.Factory())
  .component('mcpAdminMerchantData', MerchantDataComponent.Factory())
  .component('mcpAdminMerchantName', MerchantNameComponent.Factory())
  .component('mcpAdminMerchantNameForm', MerchantNameFormComponent.Factory())
  .component('mcpAdminMerchantSidebar', SidebarContractMenuComponent.Factory())
  .component('mcpMerchantHeader', wrapVue(MerchantHeader, 'mcpMerchantHeader'))
  .component(
    'mcpAdminMerchantSidebarItem',
    SidebarContractMenuItemComponent.Factory()
  )
  .component(
    'mcpAdminMerchantSidebarChannelItem',
    SidebarContractMenuChannelItemComponent.Factory()
  )
  .component(
    'mcpAdminComplianceStatusLabel',
    wrapVue(ComplianceStatusLabel, 'mcpAdminComplianceStatusLabel')
  )
  .component(
    'mcpBillingAndBankDetails',
    wrapVue(BillingAndBankDetailsContainer, 'mcpBillingAndBankDetails')
  )
  .service('mcpAdminMerchantService', MerchantService)
  .service('mcpAdminRetailerService', RetailerService)
  .service('mcpAdminConfigurationService', ConfigurationService)
  .service('mcpAdminMerchantActions', MerchantActions)
  .service('mcpAdminContractActions', ContractActions)
  .service('mcpAdminConfigurationActions', ConfigurationActions)
  .service('mcpAdminMerchantFlowActions', MerchantFlowActions)
  .service('mcpAdminFlowStateSelector', FlowStateSelector);
