import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ContractRowItem", {
        attrs: {
          locale: _vm.locale,
          html: _vm.html,
          status: _vm.status,
          isPdfLoading: _vm.isPdfLoading,
        },
        on: { downloadPdf: _vm.downloadPdf },
      }),
      _c(
        VSnackbar,
        {
          attrs: { color: "error", bottom: "", timeout: 3000 },
          model: {
            value: _vm.isPdfDownloadError,
            callback: function ($$v) {
              _vm.isPdfDownloadError = $$v
            },
            expression: "isPdfDownloadError",
          },
        },
        [_vm._v("Document download failed")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }