import utilModule from '../util/util';
import pluginManagerProvider from './pluginManagerProvider';

export default angular
  .module('mcb.core.plugins', [utilModule.name])
  .provider('mcbPluginManager', pluginManagerProvider);

// FIXME: deprecated
const packagesModule = angular
  .module('mcb.core.packages', [utilModule.name])
  .provider('mcbPackageManager', pluginManagerProvider)
  .provider('mcbPluginManager', pluginManagerProvider);

export { packagesModule };
