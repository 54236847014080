import axios from 'axios';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
  resolveRelationships,
} from 'Api/api-mutations';
import ErrorHandler from 'Api/api-errors-handler';
import { pipe, prop } from 'ramda';
import { RetailerJsonT } from 'Api/models/Retailer';

const retailersApi = axios.create({
  baseURL: '/api/merchant-profile/retailers',
});
retailersApi.interceptors.request.use(decamelizeRequestHandler);
retailersApi.interceptors.response.use(
  // @ts-ignore
  pipe(
    camelizeResponseHandler,
    prop('data'),
    resolveRelationships,
    prop('items')
  ),
  ErrorHandler
);

export function fetchRetailers(): Promise<RetailerJsonT[]> {
  return retailersApi.get('', {
    params: { include: 'sales-channels' },
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
  });
}
