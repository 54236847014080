import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "legend-section pb-10" },
    [
      _c("div", { staticClass: "title text-lg-h4 ml-2 mb-3" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c(
        VContainer,
        { staticClass: "pa-0" },
        [
          _c(
            VRow,
            { attrs: { "no-gutters": "" } },
            _vm._l(_vm.statusesInfo, function (statusInfo) {
              return _c(
                VCol,
                {
                  key: statusInfo.icon,
                  staticClass: "text-no-wrap",
                  attrs: { cols: "4" },
                },
                [
                  _c(
                    VIcon,
                    { staticClass: "mr-2", attrs: { color: statusInfo.color } },
                    [_vm._v("| " + _vm._s(statusInfo.icon))]
                  ),
                  _c("span", [_vm._v(_vm._s(statusInfo.text))]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }