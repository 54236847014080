export const CHANNELS = {
  AUSTRIA: 'at',
  BELGIUM: 'be',
  CZECH_REPUBLIC: 'cz',
  DENMARK: 'dk',
  FINLAND: 'fi',
  FRANCE: 'fr',
  GERMANY: 'de',
  IRELAND: 'ie',
  ITALY: 'it',
  NETHERLANDS: 'nl',
  NORWAY: 'no',
  POLAND: 'pl',
  SPAIN: 'es',
  SWEDEN: 'se',
  SWITZERLAND: 'ch',
  UNITED_KINGDOM: 'uk',
  LITHUANIA: 'lt',
  SLOVAKIA: 'sk',
  SLOVENIA: 'si',
  CROATIA: 'hr',
  ESTONIA: 'ee',
  LATVIA: 'lv',
  HUNGARY: 'hu',
  ROMANIA: 'ro',
  PORTUGAL: 'pt',
};

export const LOCALES = {
  AUSTRIA: { DE_AT: 'de-AT' },
  BELGIUM: { NL_BE: 'nl-BE', FR_BE: 'fr-BE' },
  CZECH_REPUBLIC: { CS_CZ: 'cs-CZ' },
  DENMARK: { DA_DK: 'da-DK' },
  FINLAND: { FI_FI: 'fi-FI' },
  FRANCE: { FR_FR: 'fr-FR' },
  GERMANY: { DE_DE: 'de-DE' },
  IRELAND: { EN_IE: 'en-IE' },
  ITALY: { IT_IT: 'it-IT' },
  NETHERLANDS: { NL_NL: 'nl-NL' },
  NORWAY: { NO_NO: 'no-NO' },
  POLAND: { PL_PL: 'pl-PL' },
  SPAIN: { ES_ES: 'es-ES' },
  SWEDEN: { SV_SE: 'sv-SE' },
  SWITZERLAND: { FR_CH: 'fr-CH', DE_CH: 'de-CH' },
  UNITED_KINGDOM: { EN_GB: 'en-GB' },
  LITHUANIA: { LT_LT: 'lt-LT' },
  SLOVAKIA: { SK_SK: 'sk-SK' },
  SLOVENIA: { SL_SI: 'sl-SI' },
  CROATIA: { HR_HR: 'hr-HR' },
  ESTONIA: { ET_EE: 'et-EE' },
  LATVIA: { LV_LV: 'lv-LV' },
  HUNGARY: { HU_HU: 'hu-HU' },
  ROMANIA: { RO_RO: 'ro-RO' },
  PORTUGAL: { PT_PT: 'pt-PT' },
};
