<template lang="pug">
  div.my-3
    ReviewSectionTitle(title="Factoring Details")

    div.py-3
      div.field.mb-3
        div.label.text-caption.text-uppercase Factoring Type
        div {{merchant.factoring}}

      v-divider
</template>

<script lang="ts">
import Vue from 'vue';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import ReviewSectionTitle from 'Merchant/common/components/review-section-title/review-section-title.vue';

interface StoreStateT {
  merchant: MerchantProfile;
}

export default Vue.extend({
  components: { ReviewSectionTitle },

  data() {
    return {
      // @ts-ignore
      ...(this.mapState({
        merchant: 'flow.general.merchant.data',
      }) as StoreStateT),
    };
  },
});
</script>
