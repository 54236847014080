import { BankAccount } from 'Api/models/BankAccount';
import template from './bank-account.html';

export class BankAccountComponent implements ng.IComponentOptions {
  static Factory() {
    return new BankAccountComponent();
  }

  bindings: { [binding: string]: string } = {
    bankAccount: '<',
    vatId: '<', // this is optional prop. Only Merchants provide vatId, not Contracts.
    taxId: '<', // this is optional prop. Only Merchants provide taxId, not Contracts.
    showTaxVat: '<', // We need to display taxId/vatId fields only on Merchant Level, not Contract Level
    isVatEnabled: '<',
  };

  controller = BankAccountController;
  controllerAs = 'ctrl';
  template: string = template;
}

class BankAccountController implements ng.IController {
  static $inject = [];

  // injected by component
  bankAccount: BankAccount;
  vatId: string;
  taxId: string;
  showTaxVat: boolean;

  $onInit() {
    // Empty block
  }
}
