import routerModule from '../router/router';
import utilModule from '../../core/util/util';
import BreadcrumbsRegistry from './BreadcrumbsStore';
import breadcrumbsComponent from './breadcrumbsComponent';

/**
 * @ngdoc module
 * @name mcb.components.breadcrumbs
 *
 * @usage
 * To add a breadcrumb to a state, simply add a breadcrumbs attribute to the state definition object where it is defined. For example:
 *
 * ```js
 * $stateProvider
 *   .state('home', {
 *     url: '/home',
 *     template: 'home',
 *     breadcrumbs: {
 *       label: 'Home'
 *     }
 *   })
 *   .state('users', {
 *      url: '/users',
 *      template: 'users',
 *      breadcrumbs: {
 *        label: 'Users',
 *        parent: 'home'
 *      }
 *   })
 *   .state('user-detail', {
 *      url: '/users/{userId}',
 *      template: 'user detail',
 *      breadcrumbs: {
 *        label: function ($stateParams) {
 *          return 'User detail ' + $stateParams.userId;
 *        },
 *        parent: 'app.users'
 *      }
 *   });
 * ```
 *
 * And then add the ```mcb-breadcrumbs``` directive where you wanna display the breadcrumbs, for example:
 *
 * ```html
 * <mcb-breadcrumbs></mcb-breadcrumbs>
 * <ui-view></ui-view>
 * ```
 *
 * At this point when you navigate for example to user.detail({userId:'aaaa-9102-9829-9a9a'})
 * We gonna display something like this to the user:
 *
 * Home > Users > User detail aaaa-9102-9829-9a9a
 *
 */
let run = ($transitions, mcbBreadcrumbsStore) => {
  $transitions.onSuccess(
    {
      to: () => {
        return true;
      }, // match every state transition
    },
    mcbBreadcrumbsStore.onTransitionSuccess.bind(mcbBreadcrumbsStore)
  );
};
run.$inject = ['$transitions', 'mcbBreadcrumbsStore'];

export default angular
  .module('mcb.components.breadcrumbs', [routerModule.name, utilModule.name])

  .component('mcbBreadcrumbs', breadcrumbsComponent)

  .service('mcbBreadcrumbsStore', BreadcrumbsRegistry)

  .run(run);
