import {
  MCP_FF_TYPE_LABELS,
  MCP_FF_TYPE_NAMES,
} from '../constants/misc.constant';

export function fullfillmentTypeLabelFilter() {
  return getFullfillmentTypeLabel;
}

export function fullfillmentTypeNameFilter() {
  return getFullfillmentTypeName;
}

export function getFullfillmentTypeLabel(fullfillmentType: string): string {
  return MCP_FF_TYPE_LABELS[fullfillmentType];
}

export function getFullfillmentTypeName(fullfillmentType: string): string {
  return MCP_FF_TYPE_NAMES[fullfillmentType];
}
