let _plugins = [];

function pluginManagerFactory($rootScope, $location, mcbUtil) {
  let currentPlugin;
  let onCurrentUpdateStack = [];

  function $$onStateChangeSuccess() {
    let path = $location.path();
    let newCurrentPlugin = mcbUtil.find(this.all(), function (pkg) {
      return path.match(new RegExp('^' + pkg.path));
    });

    if (newCurrentPlugin !== currentPlugin) {
      currentPlugin = newCurrentPlugin;
      onCurrentUpdateStack.forEach(
        function (cb) {
          cb(this.current());
        }.bind(this)
      );
    }
  }

  const service = {
    current: function () {
      return angular.copy(currentPlugin);
    },
    all: function () {
      return _plugins.concat();
    },
    onCurrentUpdated: function (cb) {
      onCurrentUpdateStack.push(cb);
      return this;
    },
  };

  if (_plugins.length) {
    $rootScope.$on('$stateChangeSuccess', $$onStateChangeSuccess.bind(service));
  }

  return service;
}
pluginManagerFactory.$inject = ['$rootScope', '$location', 'mcbUtil'];

function pluginManagerProvider() {
  this.setPlugins = function (plugins) {
    _plugins = plugins.concat();
  };

  this.getPlugins = function () {
    return _plugins.concat();
  };

  // FIXME: deprecated
  this.setPackages = this.setPlugins.bind(this);

  // FIXME: deprecated
  this.getPackages = this.getPlugins.bind(this);

  this.$get = pluginManagerFactory;

  return this;
}

export default pluginManagerProvider;
