<template lang="pug">
v-app
  Appbar

  v-main
    //- Error notification
    v-snackbar(v-model="hasError" :timeout="-1" bottom color="reject")
      | {{ errorMessage }}
      v-btn(@click="retrySearch" text) Retry

    v-container(fluid)
      Toolbar

      div.mt-n1(style="width: 400px; margin-top: -12px;")
        Search(
          :loading="searchQuery !== '' && searchInProgress"
          label="Search for merchant accounts"
          @input="searchChange"
        )
      div.pb-6.title.secondary--text(v-if="searchQuery")
          | {{ totalNumberOfAccounts }} merchant accounts found matching your search
      div.mb-4(
        v-for="account in merchantAccounts"
        :key="account.href"
      )
        MerchantAccountSummaryCard(:account="account")
      Pagination(
        v-if="totalNumberOfAccounts > 0"
        :showingFrom="showingFrom"
        :showingTo="showingTo"
        :totalCount="totalNumberOfAccounts"
        @select-page-size="setPageSize"
        @previous-page="previousPage"
        @next-page="nextPage"
      )
</template>

<script lang="ts">
import Vue from 'vue';
import debounce from 'lodash/debounce';
import Appbar from 'Common/components/appbar/appbar.container.vue';
import MerchantAccountSummaryCard from 'Common/components/merchant-account-summary-card/merchant-account-summary-card.vue';
import Pagination from 'Common/components/merchant-account-pagination/pagination.vue';
import Search from 'Common/components/search/search.vue';
import Toolbar from 'Common/components/toolbar/toolbar.container.vue';
import {
  fetchMerchantAccounts,
  MerchantAccountsResponse,
} from 'Api/endpoints/merchant-account/merchant-account.endpoint';

export default Vue.extend({
  components: {
    Appbar,
    MerchantAccountSummaryCard,
    Pagination,
    Search,
    Toolbar,
  },
  data() {
    return {
      merchantAccounts: [],
      totalNumberOfAccounts: 0,
      paginationOffset: 0,
      rowsPerPage: 10,
      searchQuery: '',
      searchInProgress: false,
      errorMessage: '',
    };
  },
  computed: {
    showingFrom(): number {
      return this.paginationOffset + 1;
    },
    showingTo(): number {
      return Math.min(
        this.totalNumberOfAccounts,
        this.paginationOffset + this.rowsPerPage
      );
    },
    hasError(): boolean {
      return this.errorMessage !== '';
    },
  },
  mounted() {
    this.getMerchantAccounts();
  },
  methods: {
    getMerchantAccounts(): Promise<void> {
      this.errorMessage = '';
      return fetchMerchantAccounts({
        name: this.searchQuery,
        offset: this.paginationOffset,
        limit: this.rowsPerPage,
      })
        .then((accounts: MerchantAccountsResponse) => {
          this.merchantAccounts = (accounts.items || []).map((account) => ({
            ...account,
            href: `/#/merchant-accounts/${account.id}`,
            legalEntities: account.legalEntities.map((entity) => ({
              ...entity,
              href: `/#/legal-entities/${entity.id}`,
            })),
          }));

          this.totalNumberOfAccounts = accounts.count;
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            this.errorMessage =
              'An internal system error has occurred. Please try again after some time.';
          } else {
            this.errorMessage =
              'An unknown error has occurred. Please try again after some time.';
          }
        })
        .finally(() => {
          this.searchInProgress = false;
        });
    },
    getMerchantAccountsDebounced: debounce(function () {
      this.getMerchantAccounts();
    }, 700),
    searchChange(query): void {
      this.paginationOffset = 0;
      this.searchQuery = query;
      this.searchInProgress = true;
      this.getMerchantAccountsDebounced();
    },
    setPageSize(rowsPerPage): void {
      this.rowsPerPage = rowsPerPage;
      this.paginationOffset = 0;
      this.getMerchantAccounts();
    },
    previousPage(): void {
      this.paginationOffset -= this.rowsPerPage;
      this.getMerchantAccounts();
    },
    nextPage(): void {
      this.paginationOffset += this.rowsPerPage;
      this.getMerchantAccounts();
    },
    retrySearch(): void {
      this.errorMessage = '';
      this.getMerchantAccountsDebounced();
    },
  },
});
</script>

<style lang="scss" scoped></style>
