import apiModule from 'Api/api.module';
import Vue from 'vue';
import { MerchantStatusLabelComponent } from './components/merchant-status-label/merchant-status-label.component';
import { CountryLabelComponent } from './components/country-label/country-label.component';
import BillingCycleLabelComponent from './components/billing-cycle-label/billing-cycle-label.component.vue';
import { DialogService } from './services/dialog.service';
import { HeaderService } from './services/header.service'; // Header service for the new Vue Header component
import { MessageComponent } from './components/message/message.component';
import { ContractStatusLabelComponent } from './components/contract-status-label/contract-status-label.component';
import { MCP_ADMIN_ROLES } from './constants/roles.constant';
import { ibanFormatter } from './directives/iban-formatter.directive';
import { taxVatValidator } from './directives/tax-vat-validator.directive';
import { IbanLabelComponent } from './components/iban-label/iban-label.component';
import { HighlightSubstringComponent } from './components/hightlight-substring/hightlight-substring.component';
import { accessibleForm } from './directives/accessible-form.directive';
import { fixedOnScroll } from './directives/fixed-on-scroll.directive';
import { PermissionAreaComponent } from './components/permission-area/permission-area.component';
import ApproveRejectComponent from './components/approve-reject/approve-reject.vue';
import ConfirmDataChange from './components/confirm-data-change/confirm-data-change.vue';
import Alert from './components/alert/alert.vue';
import HighlightSubstring from './components/hightlight-substring/highlight-substring.component.vue';
import ConfirmationModal from './components/confirmation-modal/confirmation-modal.vue';
import InfoTooltip from './components/info-tooltip/info-tooltip.vue';
import LabeledText from './components/labeled-text/labeled-text.vue';

import {
  fullfillmentTypeLabelFilter,
  fullfillmentTypeNameFilter,
} from './filters/fulfillment-type.filter';
import wrapVue from '../common/wrap-vue.converter';
import storeModule from '@/store/angular-store.module';

export default angular
  .module('mcp.admin.core', [
    'mcb',
    'mc.authentication',
    apiModule.name,
    storeModule.name,
  ])
  .constant('MCP_ADMIN_ROLES', MCP_ADMIN_ROLES)
  .constant(
    'mcEnv',
    ((window as any).__MC_INITIAL_STATE__ &&
      (window as any).__MC_INITIAL_STATE__.clientConfig.env) ||
      'unknown'
  )
  .service('mcpAdminDialogService', DialogService)
  .service('mcpHeaderService', HeaderService)
  .filter('mcpFtypeLabel', fullfillmentTypeLabelFilter)
  .filter('mcpFtypeName', fullfillmentTypeNameFilter)
  .component(
    'mcpAdminMerchantStatusLabel',
    MerchantStatusLabelComponent.Factory()
  )
  .component(
    'mcpAdminContractStatusLabel',
    ContractStatusLabelComponent.Factory()
  )
  .component('mcpAdminCountryLabel', CountryLabelComponent.Factory())
  .component('mcpAdminPermissionArea', PermissionAreaComponent.Factory())
  .component(
    'mpBillingCycleLabel',
    wrapVue(BillingCycleLabelComponent, 'mpBillingCycleLabel')
  )
  .component('mcpAdminMessage', MessageComponent.Factory())
  .component('mcpAdminIbanLabel', IbanLabelComponent.Factory())
  .component(
    'mcpAdminHighlightSubstring',
    HighlightSubstringComponent.Factory()
  )
  .component(
    'mpApproveReject',
    wrapVue(ApproveRejectComponent, 'mpApproveReject')
  )
  .component(
    'mpConfirmDataChange',
    wrapVue(ConfirmDataChange, 'mpConfirmDataChange')
  )
  .directive('mcpAdminIbanFormatter', ibanFormatter)
  .directive('mcpAdminTaxVatValidator', taxVatValidator)
  .directive('mcpAdminAccessibleForm', accessibleForm)
  .directive('mcpAdminFixedOnScroll', fixedOnScroll);

// Global Vue compoents
Vue.component('mpAlert', Alert);
Vue.component('mpHighlightSubstring', HighlightSubstring);
Vue.component('mpConfirmationModal', ConfirmationModal);
Vue.component('mpInfoTooltip', InfoTooltip);
Vue.component('mpLabeledText', LabeledText);
