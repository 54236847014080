import { Configuration } from 'Api/models/Configuration';
import { ConfigProviderFlagsT } from 'Api/models/ConfigPartnerProviderDefaults';
import { clone } from 'ramda';

export interface ConfigFieldsVisibilityT {
  articleUrl: boolean;
  stockUrl: boolean;
  ftpConfig: boolean;
  orderUrls: boolean;
}

export function getConfigFieldsVisibility(
  flags: ConfigProviderFlagsT
): ConfigFieldsVisibilityT {
  return {
    articleUrl: !!(
      flags.articlePsaArticleOnboardingActive ||
      flags.articlePsaPriceConsumptionActive ||
      flags.articlePsaStockConsumptionActive
    ),
    stockUrl: !!flags.articlePsaStockConsumptionActive,
    ftpConfig: !!(
      flags.masterDataTransferActive || flags.ftpStatusReportTransferActive
    ),
    orderUrls: !!flags.orderPsaActive,
  };
}

export function getConfigFieldsVisibilityFromConfig(
  config: Configuration
): ConfigFieldsVisibilityT {
  const { articleConfig, ftpConfig, orderConfig } = config;
  const flags: ConfigProviderFlagsT = {
    articlePsaArticleOnboardingActive:
      articleConfig.articlePsaArticleOnboardingActive,
    articlePsaPriceConsumptionActive:
      articleConfig.articlePsaPriceConsumptionActive,
    articlePsaStockConsumptionActive:
      articleConfig.articlePsaStockConsumptionActive,
    ftpStatusReportTransferActive: ftpConfig.ftpStatusReportTransferActive,
    masterDataTransferActive: articleConfig.masterDataTransferActive,
    orderPsaActive: orderConfig.orderPsaActive,
  };

  return getConfigFieldsVisibility(flags);
}

export function getConfigWithNullifiedInvisibleFields(
  config: Configuration,
  fieldsVisibility: ConfigFieldsVisibilityT
): Configuration {
  const configClone = clone(config);

  // Base URLs
  if (!fieldsVisibility.articleUrl) {
    configClone.articleConfig.articleUrl = null;
  }
  if (!fieldsVisibility.stockUrl) {
    configClone.articleConfig.stockUrl = null;
  }
  if (!fieldsVisibility.orderUrls) {
    configClone.orderConfig.orderExportUrl = null; // Put Orders
    configClone.orderConfig.orderConfirmationUrl = null; // Approve Orders
    configClone.orderConfig.orderCancellationUrl = null;
    configClone.orderConfig.orderSingleStatusUrl = null;
    configClone.orderConfig.orderMessagesUrl = null; // Get Messages
    configClone.orderConfig.orderMessagesProcessUrl = null; // Approve Messages
  }

  // Security Information
  if (
    !fieldsVisibility.articleUrl &&
    !fieldsVisibility.stockUrl &&
    !fieldsVisibility.orderUrls
  ) {
    configClone.username = null;
    configClone.password = null;
  }

  // Article Status Report
  if (!fieldsVisibility.ftpConfig) {
    configClone.ftpConfig.ftpUsername = null;
    configClone.ftpConfig.ftpPassword = null;
    configClone.ftpConfig.ftpUrl = null;
    configClone.ftpConfig.ftpPath = null;
    configClone.ftpConfig.ftpExternalPartnerName = null;
    configClone.ftpConfig.ftpExternalChannel = null;
    configClone.ftpConfig.ftpExternalSubChannel = null;
  }

  return configClone;
}
