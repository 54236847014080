/**
 * @ngdoc directive
 * @name mcbViewTitle
 * @module mcb.components.viewTitle
 *
 * @requires mcbViewTitle
 */
let viewTitleDirective = function viewTitleDirective(mcbViewTitle) {
  return {
    restrict: 'A',
    scope: {
      mcbViewTitle: '=',
    },
    link: (scope) => {
      scope.$watch('mcbViewTitle', (val) => {
        if (val) {
          mcbViewTitle(val);
        }
      });
    },
  };
};

viewTitleDirective.$inject = ['mcbViewTitle'];

export default viewTitleDirective;
