var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "mcp-admin-merchant-details__item" }, [
      _c(
        "div",
        {
          staticClass: "mcp-admin-merchant-details__item-label",
          attrs: { "data-testId": "billing-cycle-field" },
        },
        [_vm._v("Billing cycle")]
      ),
      _c(
        "div",
        { staticClass: "mcp-admin-merchant-details__item-value" },
        [_c("BillingCycleLabel", { attrs: { code: "P1M" } })],
        1
      ),
    ]),
    _c("div", { staticClass: "mcp-admin-merchant-details__item" }, [
      _c("div", { staticClass: "mcp-admin-merchant-details__item-label" }, [
        _vm._v("Billing currency"),
      ]),
      _c("div", { staticClass: "mcp-admin-merchant-details__item-value" }, [
        _vm._v(_vm._s(_vm.billingData.billingCurrency || "Not defined")),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }