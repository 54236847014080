<template lang="pug">
v-row(align-content="center" style="height: 100vh")
  v-col.text-center
    v-progress-circular(indeterminate color="secondaryDark")
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'PageLoader',
});
</script>

<style lang="scss" scoped></style>
