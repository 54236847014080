import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { attrs: { id: "vue-merchants-app" } },
    [
      _c("Appbar"),
      _c(
        VMain,
        [
          _c(
            VContainer,
            { attrs: { fluid: "" } },
            [
              _c("Toolbar"),
              _c("Filterbar", {
                staticClass: "mt-n1",
                attrs: {
                  disabled: false,
                  "default-search": _vm.filters.name,
                  "default-type": _vm.filters.types,
                  "default-status": _vm.filters.status,
                  "default-sort": _vm.filters.sort,
                  "default-factoring": _vm.filters.factoring,
                },
                on: {
                  "search-input": _vm.onSearchInputChangeDebounced,
                  "switch-merchant": _vm.goToMerchant,
                  "type-change": function ($event) {
                    _vm.filters.types = $event
                  },
                  "status-change": function ($event) {
                    _vm.filters.status = $event
                  },
                  "sort-change": function ($event) {
                    _vm.filters.sort = $event
                  },
                  "factoring-change": function ($event) {
                    _vm.filters.factoring = $event
                  },
                },
              }),
              !_vm.totalCount
                ? _c("div", { staticClass: "pb-6 title secondary--text" }, [
                    _vm._v("No merchants found"),
                  ])
                : _vm._e(),
              _vm.merchants
                ? _c(
                    "div",
                    _vm._l(_vm.merchants, function (merchant) {
                      return _c("SummaryCard", {
                        key: merchant.id,
                        staticClass: "mb-4",
                        attrs: {
                          "data-testid": "merchant-summary-card",
                          merchant: merchant,
                          activeNameFilter: _vm.filters.name,
                          merchantPageRouteState:
                            _vm.getMerchantPageRouteState(merchant),
                          contractPageRouteState: _vm.contractPageRouteState,
                          canSeeGeneralData: "",
                          canEdit: "",
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.merchants && _vm.merchants.length
                ? _c("Pagination", {
                    attrs: {
                      showingFrom: _vm.showingFrom,
                      showingTo: _vm.showingTo,
                      totalCount: _vm.totalCount,
                    },
                    on: {
                      "select-page-size": function ($event) {
                        _vm.pageSize = $event
                      },
                      "previous-page": function ($event) {
                        _vm.page--
                      },
                      "next-page": function ($event) {
                        _vm.page++
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }