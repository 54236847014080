/**
 * @ngdoc directive
 * @name mcbViewTitleReference
 *
 * @requires mcbViewTitle
 */
let viewTitleReferenceDirective = function viewTitleReferenceDirective(
  mcbViewTitle
) {
  return {
    restrict: 'EA',
    scope: {},
    link: function (scope) {
      scope.$watch(
        function () {
          return mcbViewTitle.title;
        },
        function (val) {
          scope.title = val;
        }
      );
    },
    template: '<span class="mcb-view-title-reference">{{ title }}</span>',
  };
};

viewTitleReferenceDirective.$inject = ['mcbViewTitle'];

export default viewTitleReferenceDirective;
