<template lang="pug">
  div.review-status-selector
    v-select(
      style="width: 200px"
      v-if="items.length"
      :value="selectedStatus"
      :items="items"
      @change="$emit('on-status-select', $event)"
      outlined
      hide-details
      single-line
      placeholder="Select data status"
    )
</template>

<script lang="ts">
import Vue from 'vue';
import { ApprovalStatusT } from 'Api/models/ApprovalStatus';

export default Vue.extend({
  props: {
    selectedStatus: {
      type: String as () => ApprovalStatusT,
      required: false,
      default: '',
    },
    hideRejectionOption: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    let items = [{ text: 'Approved', value: ApprovalStatusT.APPROVED }];

    if (!this.hideRejectionOption) {
      items.push({ text: 'Rejected', value: ApprovalStatusT.REJECTED });
    }

    return {
      items,
    };
  },
});
</script>

<style lang="scss" scoped>
.review-status-selector ::v-deep .v-input__slot {
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}
.review-status-selector ::v-deep .v-input__append-inner {
  margin-top: 5px;
}
</style>
