var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "my-3" },
    [
      _c("ReviewSectionTitle", { attrs: { title: "Contact details" } }),
      _vm.groupedContacts.length == 0
        ? _c("div", [_vm._v("No contacts data available")])
        : _c(
            "div",
            _vm._l(_vm.groupedContacts, function (cGroup) {
              return _c(
                "div",
                { staticClass: "mb-4", attrs: { "data-testid": "cgroup" } },
                [
                  _c(
                    "div",
                    { staticClass: "my-4" },
                    [
                      _c("ReviewSectionTitle", {
                        attrs: {
                          title: _vm.typeLabels[cGroup.ctype],
                          "is-small": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    _vm._l(cGroup.items, function (contact) {
                      return _c(
                        "div",
                        {
                          staticClass: "mb-3",
                          attrs: { "data-testid": "contact" },
                        },
                        [
                          _c("div", { staticClass: "field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("First name"),
                            ]),
                            _c("div", [_vm._v(_vm._s(contact.firstName))]),
                          ]),
                          _c("div", { staticClass: "field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Last name"),
                            ]),
                            _c("div", [_vm._v(_vm._s(contact.lastName))]),
                          ]),
                          _c("div", { staticClass: "field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Email"),
                            ]),
                            _c("div", [_vm._v(_vm._s(contact.email))]),
                          ]),
                          _c("div", { staticClass: "field" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Phone"),
                            ]),
                            _c("div", [_vm._v(_vm._s(contact.phone || "-"))]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            }),
            0
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }