var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17",
        height: "17",
        viewBox: "0 0 17 17",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            fill: "#FF6900",
            d: "M15.64 6.624c-1.213-1.485-2.91-2.926-5.13-4.226l-.009-.004C8.261 1.124 6.163.38 4.267.074c-1.183-.19-1.748.03-2.005.18-.257.147-.733.525-1.158 1.642C.42 3.686.018 5.873 0 8.442v.01c.018 2.568.421 4.755 1.104 6.545.425 1.118.9 1.495 1.158 1.643.257.148.822.37 2.005.18 1.896-.306 3.995-1.051 6.234-2.32l.009-.005c2.22-1.3 3.917-2.741 5.13-4.226.757-.927.847-1.526.847-1.822 0-.297-.09-.896-.847-1.823",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }