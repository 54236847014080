import { mcb } from 'mcb';
import template from './sidebar-contract-menu.html';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { MCP_CONTRACT_STATUSES } from 'Common/constants/contract-statuses.constant';
import { MCP_MERCHANT_STATUSES } from 'Common/constants/merchant-statuses.constant';
import { Contract, ContractStatusT } from 'Api/models/Contract';
import {
  RetailerToContracts,
  RetailerService,
} from '../../services/retailer.service';
import permissionsService from 'Common/services/permissions.service';

export class SidebarContractMenuComponent implements ng.IComponentOptions {
  static Factory() {
    return new SidebarContractMenuComponent();
  }

  bindings: { [binding: string]: string } = {
    contracts: '<',
    merchant: '<',
  };

  controller = SidebarContractMenuController;
  controllerAs = 'ctrl';
  template: string = template;
}

export interface ContractsFinalStatuses {
  [contractId: string]: ContractStatusT;
}
export interface SalesChannelFinalStatues {
  [salesChannelId: string]: ContractStatusT;
}

export class SidebarContractMenuController
  implements angular.IComponentController
{
  static $inject = ['mcbConfig', 'mcpAdminRetailerService'];

  contracts: Contract[];
  merchant: MerchantProfile;

  isFinancialApprover = false;
  retailersToContracts: RetailerToContracts[];
  contractsFinalStatuses: ContractsFinalStatuses;
  salesChannelsFinalStatues: SalesChannelFinalStatues;
  imagesPath: string;

  constructor(
    private mcbConfig: mcb.config.IConfigProvider,
    private retailerService: RetailerService
  ) {}

  $onInit(): void {
    this.imagesPath =
      this.mcbConfig.package('mc-package-admin').get('mountURL') + '/images';
    this.isFinancialApprover =
      permissionsService.hasFinancialApprovalPermissions();
  }

  $onChanges(): void {
    this.contractsFinalStatuses = this.defineContractsFinalStatuses();
    this.salesChannelsFinalStatues = this.defineSalesChannelFinalStatues();
    this.retailerService
      .mapRetailersToContracts(this.contracts)
      .then((mapping: RetailerToContracts[]) => {
        this.retailersToContracts = mapping;
      });
  }

  private defineContractsFinalStatuses(): ContractsFinalStatuses {
    const mStatus = this.merchant.status;

    return this.contracts.reduce(
      (contractsFinalStatuses: ContractsFinalStatuses, contract: Contract) => {
        let cStatus: ContractStatusT;

        switch (mStatus) {
          case MCP_MERCHANT_STATUSES.IN_PROGRESS:
            cStatus = MCP_CONTRACT_STATUSES.IN_PROGRESS;
            break;
          case MCP_MERCHANT_STATUSES.FINANCIAL_REJECTED:
            cStatus = MCP_CONTRACT_STATUSES.FINANCIAL_REJECTED;
            break;
          default:
            cStatus = contract.status;
        }

        contractsFinalStatuses[contract.$id] = cStatus;

        return contractsFinalStatuses;
      },
      {}
    );
  }
  private defineSalesChannelFinalStatues(): SalesChannelFinalStatues {
    const mStatus = this.merchant.status;

    return this.contracts.reduce(
      (
        salesChannelsFinalStatuses: SalesChannelFinalStatues,
        contract: Contract
      ) => {
        let cStatus: ContractStatusT;

        switch (mStatus) {
          case MCP_MERCHANT_STATUSES.IN_PROGRESS:
            cStatus = MCP_CONTRACT_STATUSES.IN_PROGRESS;
            break;
          case MCP_MERCHANT_STATUSES.FINANCIAL_REJECTED:
            cStatus = MCP_CONTRACT_STATUSES.FINANCIAL_REJECTED;
            break;
          default:
            cStatus = contract.status;
        }

        salesChannelsFinalStatuses[contract.salesChannelId] = cStatus;

        return salesChannelsFinalStatuses;
      },
      {}
    );
  }
}
