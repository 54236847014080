<template lang="pug">
div
  Summary(
    :readonly="readonly"
    :attributes-maps="attributesMaps"
    :values="values"
    v-stream:showPreview="showPreview$"
  )

  //- Size Chart preview dialog
  v-dialog(
    width="858"
    :value="displaySizeChartDialog"
    v-stream:input="closeDialog$"
  )
    SizeChartPreview(
      v-if="displaySizeChartDialog"
      v-stream:close="closeDialog$"
      :loading="sizeChartLoading"
      :sizeChart="sizeChart"
    )

  //- Error message if fetch size grid failed
  v-snackbar(
    v-if="notify === 'NOTIFY_FETCH_SIZE_GRID_FAILED'"
    v-model="notify"
    color="error"
    bottom
    :timeout="3000"
   )
    |  Unable to fetch size grid data
    v-btn(dark text @click="notify = false") Close
</template>

<script lang="ts">
import Vue from 'vue';
import { Subject, from, merge, of, ConnectableObservable } from 'rxjs';
import {
  pluck,
  filter,
  switchMap,
  mapTo,
  startWith,
  multicast,
  catchError,
} from 'rxjs/operators';
import Summary from '../summary/masterdata-summary.vue';
import SizeChartPreview from '../size-chart-preview/masterdata-size-chart-preview.vue';
import { fetchSizeChart } from 'Api/endpoints/masterdata/masterdata.endpoint';

const NOTIFY_FETCH_SIZE_GRID_FAILED = 'NOTIFY_FETCH_SIZE_GRID_FAILED';
const FETCH_SIZE_GRID_ERROR = 'FETCH_SIZE_GRID_ERROR';

export default Vue.extend({
  components: {
    Summary,
    SizeChartPreview,
  },
  props: {
    readonly: Boolean,
    /** @type {MasterdataAttributesMaps} */
    attributesMaps: Object,
    /** @type {Masterdata} */
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      notify: false,
    };
  },
  subscriptions() {
    // @ts-ignore
    const showPreview$ = (this.showPreview$ = new Subject().pipe(
      pluck('event', 'msg')
    ));

    // @ts-ignore
    const closeDialog$ = (this.closeDialog$ = new Subject());

    const sizeChartResults$ = showPreview$.pipe(
      // Take fetch results from the latest click only
      // @ts-ignore
      switchMap((sizeChartId: string) => {
        const sizeChartInfo = fetchSizeChart(sizeChartId).then((sizeChart) => {
          const certificateIds =
            // @ts-ignore
            this.attributesMaps.sizeCharts.get(sizeChartId)?.certificateIds;
          return { ...sizeChart, certificateIds };
        });

        return from(sizeChartInfo).pipe(
          catchError((val) => {
            // @ts-ignore
            this.notify = NOTIFY_FETCH_SIZE_GRID_FAILED;
            return of(FETCH_SIZE_GRID_ERROR);
          })
        );
      }),
      // Convert a regular Observable into a ConnectableObservable
      // with shared subscription between subscribers
      // in order to avoid duplicated api fetchSizeAttrs requests
      multicast(new Subject())
    ) as ConnectableObservable<any>;

    const subscription = sizeChartResults$.connect();

    // Unsubscribe on component destroy
    this.$once('hook:beforeDestroy', () => subscription.unsubscribe());

    return {
      // When to show the dialog
      displaySizeChartDialog: merge(
        showPreview$.pipe(mapTo(true)),
        sizeChartResults$.pipe(
          filter((val) => val === FETCH_SIZE_GRID_ERROR),
          mapTo(false)
        ),
        closeDialog$.pipe(mapTo(false))
      ).pipe(startWith(false)),

      // Size Chart data
      sizeChart: sizeChartResults$.pipe(
        filter((val) => val !== FETCH_SIZE_GRID_ERROR),
        startWith(null)
      ),

      // When to show Size Chart loading indicator
      sizeChartLoading: merge(
        sizeChartResults$.pipe(mapTo(false)),
        showPreview$.pipe(mapTo(true))
      ).pipe(startWith(false)),
    };
  },
});
</script>

<style lang="scss" scoped></style>
