<template lang="pug">
v-container(fluid)
  //- Loading
  div.text-center.mt-2(
    v-if="isLoading"
  )
    v-progress-circular(
      :size="50"
      color="primary"
      indeterminate
    )

  //- Return Address API error
  v-alert(
    v-else-if="isInitFailed"
    :value="true"
    type="error"
  ) Right of Withdrawal initialisation failed

  //- Return Address not defined
  v-alert(
    v-else-if="isAddressNotDefined"
    :value="true"
    type="warning"
  ) Return Address needs to be defined in order for Rights of Withdrawal to be generated.

  //- Return Address exists
  template(v-else-if="isInitialised")
    //- Several locales
    div(v-if="locales.length > 1")
      v-tabs(slider-color="primary" v-model="activeLocale")
        v-tab(
          v-for="locale in locales"
          :key="locale"
        ) {{ locale }}
        
      v-tabs-items(v-model="activeLocale")
        v-tab-item.grey.lighten-5(
          v-for="locale in locales"
          :key="locale"
        )
          ContractRowItemContainer.py-3.px-3(
            :locale="locale"
            :merchantId="merchantId",
            :salesChannelId="salesChannelId"
            :useDraft="useDraft"
            :fulfillmentType="fulfillmentType"
            )
    
    //- A single locale
    ContractRowItemContainer(
      v-else
      :locale="locales[0]"
      :merchantId="merchantId",
      :salesChannelId="salesChannelId"
      :useDraft="useDraft"
      :fulfillmentType="fulfillmentType"
      )
</template>

<script lang="ts">
import Vue from 'vue';
import ContractRowItemContainer from '../row-item-container/contract-row-item.container.vue';
import {
  ROW_PAGE_STATUS_LOADING,
  ROW_PAGE_STATUS_INIT_FAILED,
  ROW_PAGE_STATUS_NO_ADDRESS,
  ROW_PAGE_STATUS_OK,
} from '../row.constants';
export default Vue.extend({
  components: { ContractRowItemContainer },
  props: {
    locales: Array,
    merchantId: String,
    salesChannelId: String,
    useDraft: Boolean,
    fulfillmentType: String,
    status: String,
  },
  data() {
    return {
      activeLocale: null,
    };
  },
  computed: {
    isInitFailed() {
      return this.status === ROW_PAGE_STATUS_INIT_FAILED;
    },
    isLoading() {
      return this.status === ROW_PAGE_STATUS_LOADING;
    },
    isAddressNotDefined() {
      return this.status === ROW_PAGE_STATUS_NO_ADDRESS;
    },
    isInitialised() {
      return this.status === ROW_PAGE_STATUS_OK;
    },
  },
});
</script>

<style lang="scss" scoped></style>
