import { VFlex } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VFlex,
    {
      staticClass: "d-flex flex-column ml-4",
      staticStyle: { height: "38px", "justify-content": "space-evenly" },
    },
    [
      _c("div", { staticClass: "caption secondary--text" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _c("div", { staticClass: "body-1 secondaryDark--text" }, [
        _vm._v(_vm._s(_vm.value || "-")),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }