import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ma-6" },
    [
      _c("div", { staticClass: "heading text-h6 font-weight-regular mb-3" }, [
        _vm._v("Legal entity address"),
      ]),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "label text-caption text-uppercase" }, [
          _vm._v("Legal name"),
        ]),
        _vm.showNameLink
          ? _c("div", [
              _c(
                "a",
                {
                  staticClass: "dc-link",
                  attrs: { href: _vm.legalEntityLink },
                },
                [_vm._v(_vm._s(_vm.details.name.value))]
              ),
            ])
          : _c("div", [_vm._v(_vm._s(_vm.details.name.value))]),
      ]),
      _c("VatAddress", {
        attrs: {
          address: _vm.details.address,
          "address-label": "Company Address",
        },
      }),
      _c(VDivider, { staticClass: "my-6" }),
      _c("div", { staticClass: "heading text-h6 font-weight-regular mb-3" }, [
        _vm._v("Signer details"),
      ]),
      _vm._l(_vm.details.signers, function (signer, index) {
        return _c("div", [
          _c("div", { staticClass: "field" }, [
            _c("div", { staticClass: "label text-caption text-uppercase" }, [
              _vm._v(
                "Signer " +
                  _vm._s(_vm.isMoreThanOneSigner ? index + 1 : "") +
                  " First Name"
              ),
            ]),
            _c("div", [_vm._v(_vm._s(signer.firstName))]),
          ]),
          _c("div", { staticClass: "field" }, [
            _c("div", { staticClass: "label text-caption text-uppercase" }, [
              _vm._v(
                "Signer " +
                  _vm._s(_vm.isMoreThanOneSigner ? index + 1 : "") +
                  " Last Name"
              ),
            ]),
            _c("div", [_vm._v(_vm._s(signer.lastName))]),
          ]),
        ])
      }),
      _c(VDivider, { staticClass: "my-6" }),
      _c("div", { staticClass: "heading text-h6 font-weight-regular mb-3" }, [
        _vm._v("Legal VAT details"),
      ]),
      _c("VatIdDetails", { attrs: { details: _vm.details.vat } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }