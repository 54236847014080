/**
 *
 * @description
 * Reduce a traversal operation to a single value by accumulating like Array.prototype.reduce.
 * P.s. useful when you wanna reduce a tree structure to something else.
 *
 * Starting from the value, this function will call the ```nextCallback``` to continue.
 * The ```nextCallback``` takes the current value as an argument; write a function that return the next truthy value or a falsy value to stop traversing.
 * The return value is the the accumulated result of running each nvalue through the ```callback``` function.
 * The ```callback``` function is invoked with three arguments: ```accumulator```, ```currValue``` and the ```nextValue```.
 * The ```accumulator``` represent the starting point to accumulate values.
 *
 * @param {Object} value
 * @param {function} nextCallback - Function that returns the next value
 * @param {function} callback - Reduce iterator callback
 * @param {*} accumulator - The initial value
 * @returns {*}
 *
 * @example
 *
 * let tree = {
 *   label: 'foo',
 *   child: {
 *     label: 'bar',
 *     child: {
 *       label: 'john'
 *     }
 *   }
 * };
 *
 * let expected = ['foo', 'bar', 'john'];
 *
 * let actual = reduceFrom(tree, (curr) => {
 *     return curr.child;
 * }, (acc, curr) => {
 *  if(curr.label) { acc.push(curr.label); }
 *  return acc;
 * }, []);
 *
 * expect(actual).toEqual(expected);
 */

export default function reduceFrom(value, nextCallback, callback, accumulator) {
  return (function recurse(currValue, _nextCallback, _callback, _accumulator) {
    let nextValue = _nextCallback(currValue);
    let accumulated = _callback(_accumulator, currValue, nextValue);

    if (nextValue) {
      // if next value continue is truthy
      return recurse(nextValue, _nextCallback, _callback, accumulated);
    }

    return accumulated;
  })(value, nextCallback, callback, accumulator);
}
