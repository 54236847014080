import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mr-2" },
    [
      _c(VIcon, { attrs: { color: _vm.statusIcon.color } }, [
        _vm._v(_vm._s(_vm.statusIcon.icon)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }