var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "label text-caption text-uppercase" }, [
        _vm._v("Belongs to Vat group?"),
      ]),
      _vm.details.belongsToVatGroup
        ? _c("div", [_vm._v("Yes")])
        : _c("div", [_vm._v("No")]),
    ]),
    _vm.details.name
      ? _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "label text-caption text-uppercase" }, [
            _vm._v("Group Vat Name"),
          ]),
          _c("div", [_vm._v(_vm._s(_vm.details.name))]),
        ])
      : _vm._e(),
    _vm.details.certificateId
      ? _c(
          "div",
          { staticClass: "field" },
          [
            _c("div", { staticClass: "label text-caption text-uppercase" }, [
              _vm._v("Group Vat Proof"),
            ]),
            _c("VatCertificate", {
              attrs: { "certificate-id": _vm.details.certificateId },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }