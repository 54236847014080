import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { attrs: { id: "approve-reject" } },
    [
      _c("div", { staticClass: "dc-row dc-row--align--right" }, [
        _c("div", { staticClass: "dc-column dc-column--shrink" }, [
          _c(
            "button",
            {
              staticClass: "dc-btn",
              class: { "dc-btn--disabled": _vm.buttonsDisabled },
              attrs: {
                disabled: _vm.buttonsDisabled,
                "data-testid": "approve-btn",
              },
              on: { click: _vm.approve },
            },
            [_vm._v("Approve")]
          ),
        ]),
        _c("div", { staticClass: "dc-column dc-column--shrink" }, [
          _c(
            "button",
            {
              staticClass: "dc-btn dc-btn--destroy",
              class: { "dc-btn--disabled": _vm.buttonsDisabled },
              attrs: {
                disabled: _vm.buttonsDisabled,
                "data-testid": "reject-btn",
              },
              on: { click: _vm.confirmRejection },
            },
            [_vm._v("Reject")]
          ),
        ]),
      ]),
      _c(
        VDialog,
        {
          attrs: { width: "800" },
          model: {
            value: _vm.showConfirmRejectionModal,
            callback: function ($$v) {
              _vm.showConfirmRejectionModal = $$v
            },
            expression: "showConfirmRejectionModal",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "title" }, [
                _vm._v("Provide a reason for rejection"),
              ]),
              _c("mpAlert", { attrs: { type: "warning" } }, [
                _vm._v("You are about to reject "),
                _c("b", [_vm._v(_vm._s(_vm.merchant.companyName) + "'s")]),
                _vm._v(
                  " data on zDirect.\nThe reason may be displayed to the partner on zDirect, based on your selection.\nPlease proceed with caution and specify accurate reasons for the rejection"
                ),
              ]),
              _c(
                VCardText,
                [
                  _c(
                    VRadioGroup,
                    {
                      model: {
                        value: _vm.rejectionScope,
                        callback: function ($$v) {
                          _vm.rejectionScope = $$v
                        },
                        expression: "rejectionScope",
                      },
                    },
                    [
                      _c(
                        VRow,
                        [
                          _c(VCol, { attrs: { md: "12" } }, [
                            _c("h4", [
                              _vm._v("Who should the rejection reason go to?"),
                            ]),
                          ]),
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c(VRadio, {
                                attrs: {
                                  value: _vm.rejectionScopeOptions.partners,
                                  label: "Partners",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c(VRadio, {
                                attrs: {
                                  value: _vm.rejectionScopeOptions.internal,
                                  label: "Internal teams",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isScopePartners
                    ? _c(
                        VRow,
                        [
                          _c(VCol, { attrs: { md: "12" } }, [
                            _c("h4", [_vm._v("Provide rejection detail(s):")]),
                            _c("span", [
                              _vm._v(
                                "Please select where there may be incomplete\nor incorrect details provided by the Partner"
                              ),
                            ]),
                          ]),
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c(VCheckbox, {
                                attrs: { value: "NAME", label: "Company name" },
                                model: {
                                  value: _vm.rejectedSections,
                                  callback: function ($$v) {
                                    _vm.rejectedSections = $$v
                                  },
                                  expression: "rejectedSections",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c(VCheckbox, {
                                attrs: {
                                  value: "CONTACT",
                                  label: "Company contacts",
                                },
                                model: {
                                  value: _vm.rejectedSections,
                                  callback: function ($$v) {
                                    _vm.rejectedSections = $$v
                                  },
                                  expression: "rejectedSections",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c(VCheckbox, {
                                attrs: {
                                  value: "ADDRESS",
                                  label: "Company address",
                                },
                                model: {
                                  value: _vm.rejectedSections,
                                  callback: function ($$v) {
                                    _vm.rejectedSections = $$v
                                  },
                                  expression: "rejectedSections",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c(VCheckbox, {
                                attrs: {
                                  value: "BILLING_DATA",
                                  label: "Billing currency",
                                },
                                model: {
                                  value: _vm.rejectedSections,
                                  callback: function ($$v) {
                                    _vm.rejectedSections = $$v
                                  },
                                  expression: "rejectedSections",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { md: "4" } },
                            [
                              _c(VCheckbox, {
                                attrs: {
                                  value: "BANK_DATA",
                                  label: "Bank details",
                                },
                                model: {
                                  value: _vm.rejectedSections,
                                  callback: function ($$v) {
                                    _vm.rejectedSections = $$v
                                  },
                                  expression: "rejectedSections",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(VTextarea, {
                    attrs: {
                      filled: "",
                      autofocus: "",
                      label: "A reason for rejection",
                      hint:
                        "Please specify at least " +
                        _vm.minWordsCount +
                        " words",
                    },
                    model: {
                      value: _vm.rejectionReason,
                      callback: function ($$v) {
                        _vm.rejectionReason = $$v
                      },
                      expression: "rejectionReason",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.showConfirmRejectionModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        color: "primary",
                        disabled: !_vm.reasonSpecified,
                      },
                      on: { click: _vm.reject },
                    },
                    [_vm._v("Reject")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }