import * as humps from 'humps';
import lodash from 'lodash';
import reflection from './reflection';
import reduceFrom from './reduceFrom';

/**
 * @ngdoc module
 * @name mcb.core.util
 */
export default angular
  .module('mcb.core.util', [])
  .constant('mcbReflection', reflection)
  /**
   * @name mcb.core.util.mcbReduceFrom
   */
  .constant('mcbReduceFrom', reduceFrom)
  /**
   * @description
   * Underscore-to-camelCase converter (and vice versa) for strings and object keys in JavaScript.
   * https://github.com/domchristie/humps
   *
   * @ngdoc constant
   * @name mcbHumps
   * @module mcb.core.util
   */
  .constant('mcbHumps', humps)
  .constant('mcbUtil', lodash);
