<template lang="pug">
v-app#masterdata.root
  Masterdata(
    :saved-values="savedValues"
    :attributes="attributes"
    :size-attrs="sizeAttrs"
    :size-attrs-cache-map="sizeAttrsCacheMap"
    :size-attrs-loading="sizeAttrsLoading"
    :state="state"
    :can-export="exportLang !== null"
    :export-logs="exportLogs"
    :show-saved-not-exported-warning="showSavedNotExportedWarning"
    @save="onSave"
    @export="onExport"
    v-stream:update:sizeSearchInput="sizeSearchInput$"
  )

  //- NOTIFICATIONS
  //- Initialisation error
  v-snackbar(
    v-if="notify === 'NOTIFY_INIT_FAILED'"
    v-model="notify"
    color="error"
    bottom
    :timeout="2000"
  )  Masterdata initialisation failed

  //- Submit success
  v-snackbar(
    v-if="notify === 'NOTIFY_SAVE_SUCCESS'"
    v-model="notify"
    color="success"
    bottom
    :timeout="2000"
   )  Masterdata successfully saved

  //- Submit error
  v-snackbar(
    v-if="notify === 'NOTIFY_SAVE_FAILED'"
    v-model="notify"
    color="error"
    bottom
    :timeout="3000"
   )
    |  Unable to save masterdata
    v-btn(dark text @click="notify = false") Close

  //- Export success
  v-snackbar(
    v-if="notify === 'NOTIFY_EXPORT_SUCCESS'"
    v-model="notify"
    color="success"
    bottom
    :timeout="2000"
   ) Master data sent for export successfully

  //- Export error
  v-snackbar(
    v-if="notify === 'NOTIFY_EXPORT_FAILED'"
    v-model="notify"
    color="error"
    bottom
    :timeout="3000"
   )
    | Unable to mark masterdata as completed
    v-btn(dark text @click="notify = false") Close

  //- Fetching sizing attributes error
  v-snackbar(
    v-if="notify === 'NOTIFY_FETCH_SIZE_ATTRS_FAILED'"
    v-model="notify"
    color="error"
    bottom
    :timeout="3000"
   )
    |  Unable to fetch sizing attributes
    v-btn(dark text @click="notify = false") Close
</template>

<script lang="ts">
/**
 * WARNING: Masterdata component is intentionally built
 * using "dirty" approaches.
 * It means that properties passed to children are mutable.
 * It was done for the sake of performance, because "pure"
 * approach is significantly slower (at least I was not be able
 * to achive good performance).
 */
import Vue from 'vue';
import { pick, clone } from 'ramda';
import { Subject, from, merge, ConnectableObservable } from 'rxjs';
import {
  pluck,
  debounceTime,
  switchMap,
  mapTo,
  startWith,
  multicast,
  scan,
} from 'rxjs/operators';
import {
  fetchAttribues,
  fetchMasterdata,
  fetchMerchantSizeCharts,
  fetchExportLang,
  fetchSizechartsAttributes,
  fetchExportLogs,
  fetchIsSavedNotExported,
  changeMasterdata,
  exportMasterdata,
  emptyMasterdata,
} from 'Api/endpoints/masterdata/masterdata.endpoint';
import MasterdataInterface from 'Api/models/Masterdata';
import MasterdataAttributes from 'Api/models/MasterdataAttributes';
import Masterdata, { STATE } from 'Common/components/masterdata/masterdata.vue';
import { getMerchant } from 'Merchant/common/state/selectors/flow-state.selector';
import { canEnableACUChanges } from '@/feature-flags';

const isACUChangeFlagEnabled = canEnableACUChanges();
const pickMasterdata = pick([
  'seasons',
  'brands',
  'silhouettes',
  'sizeCharts',
  ...(isACUChangeFlagEnabled ? ['ownerBrands'] : []),
]);

export const NOTIFY_INIT_FAILED = 'NOTIFY_INIT_FAILED';
export const NOTIFY_SAVE_SUCCESS = 'NOTIFY_SAVE_SUCCESS';
export const NOTIFY_SAVE_FAILED = 'NOTIFY_SAVE_FAILED';
export const NOTIFY_EXPORT_SUCCESS = 'NOTIFY_EXPORT_SUCCESS';
export const NOTIFY_EXPORT_FAILED = 'NOTIFY_EXPORT_FAILED';
export const NOTIFY_FETCH_SIZE_ATTRS_FAILED = 'NOTIFY_FETCH_SIZE_ATTRS_FAILED';

export default Vue.extend({
  components: {
    Masterdata,
  },
  props: {
    onExportCb: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      state: STATE.INIT_INPROGRESS,
      notify: false,
      exportLang: null,
      exportLogs: [],
      attributes: {
        seasons: [],
        brands: [],
        silhouettes: [],
        sizeCharts: [],
        ...(isACUChangeFlagEnabled ? { ownerBrands: [] } : {}),
      } as MasterdataAttributes,
      merchantSizeCharts: [],
      savedValues: clone(emptyMasterdata), // A copy of masterdata that should be in sync with Backend
      showSavedNotExportedWarning: false,
      ...(this as any).mapState({
        merchantId: (state) => getMerchant(state).$id,
      }),
    };
  },
  computed: {
    sizeAttrsCacheMap() {
      const { $_sizeAttrsCache, merchantSizeCharts } = this;

      const values = [...$_sizeAttrsCache, ...merchantSizeCharts].map(
        (value) => [value.id, value]
      );

      // @ts-ignore
      return new Map(values);
    },
  },
  subscriptions() {
    // @ts-ignore
    const fetchSizeAttrs = this.$_fetchSizeAttrs.bind(this);
    const sizeSearchInput$ = new Subject().pipe(pluck('event', 'msg'));

    // @ts-ignore
    this.sizeSearchInput$ = sizeSearchInput$;

    const attrsResults$ = sizeSearchInput$.pipe(
      debounceTime(500),
      // Take results from the latest input only
      // @ts-ignore
      switchMap((query) =>
        query ? from(fetchSizeAttrs(query)) : from(Promise.resolve([]))
      ),
      // Convert a regular Observable into a ConnectableObservable
      // with shared subscription between subscribers
      // in order to avoid duplicated api fetchSizeAttrs requests
      multicast(new Subject())
    ) as ConnectableObservable<any>;

    const subscription = attrsResults$.connect();

    // Unsubscribe on component destroy
    this.$once('hook:beforeDestroy', () => subscription.unsubscribe());

    return {
      $_sizeAttrsCache: attrsResults$.pipe(
        startWith([]),
        scan((acc, curr) => [...acc, ...curr])
      ),
      sizeAttrs: attrsResults$.pipe(startWith([])),
      sizeAttrsLoading: merge(
        sizeSearchInput$.pipe(mapTo(true)),
        attrsResults$.pipe(mapTo(false))
      ).pipe(startWith(false)),
    };
  },
  mounted() {
    Promise.all([
      fetchAttribues(),
      fetchMasterdata(this.merchantId),
      fetchExportLang(this.merchantId),
      fetchExportLogs(this.merchantId),
      fetchIsSavedNotExported(this.merchantId),
      fetchMerchantSizeCharts(this.merchantId),
    ])
      .then(
        ([
          attributes,
          values,
          exportLang,
          logs,
          isMDSavedNotExported,
          merchantSizeCharts,
        ]) => {
          this.attributes = attributes;
          this.savedValues = pickMasterdata(values);
          this.state = STATE.DEFAULT;
          this.exportLang = exportLang;
          this.exportLogs = logs;
          this.showSavedNotExportedWarning = isMDSavedNotExported;
          this.merchantSizeCharts = merchantSizeCharts;
        }
      )
      .catch(() => {
        this.state = STATE.INIT_FAILED;
        this.notify = NOTIFY_INIT_FAILED;
      });
  },
  methods: {
    onSave(data: MasterdataInterface) {
      this.state = STATE.SAVE_INPROGRESS;

      changeMasterdata(this.merchantId, data)
        .then((newData) => {
          this.state = STATE.DEFAULT;
          // Update savedValues, so that Save and Cancel button become disabled
          this.savedValues = pickMasterdata(newData);
          this.notify = NOTIFY_SAVE_SUCCESS;
        })
        .catch(() => {
          this.state = STATE.DEFAULT;
          this.notify = NOTIFY_SAVE_FAILED;
        });
    },
    onExport() {
      this.state = STATE.EXPORT_INPROGRESS;

      exportMasterdata(this.merchantId)
        .then(() => {
          this.state = STATE.DEFAULT;
          this.notify = NOTIFY_EXPORT_SUCCESS;
          this.showSavedNotExportedWarning = false;

          this.onExportCb();
        })
        .catch(() => {
          this.state = STATE.DEFAULT;
          this.notify = NOTIFY_EXPORT_FAILED;
        })
        .finally(() => {
          // Update logs
          fetchExportLogs(this.merchantId).then(
            (logs) => (this.exportLogs = logs)
          );
        });
    },
    $_fetchSizeAttrs(q) {
      return fetchSizechartsAttributes(q).catch((err) => {
        // @ts-ignore
        this.notify = NOTIFY_FETCH_SIZE_ATTRS_FAILED;
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.root {
  overflow: hidden;
  border: 1px solid rgba(128, 128, 128, 0.2);
}
</style>
