<template lang="pug">
  div
    div.mcp-admin-merchant-details__item
      div.mcp-admin-merchant-details__item-label(data-testId="billing-cycle-field")
        | Billing cycle
      div.mcp-admin-merchant-details__item-value
        BillingCycleLabel(code="P1M")
    div.mcp-admin-merchant-details__item
      div.mcp-admin-merchant-details__item-label
        | Billing currency
      div.mcp-admin-merchant-details__item-value
        | {{billingData.billingCurrency || 'Not defined'}}
</template>

<script lang="ts">
import Vue from 'vue';
import BillingCycleLabel from 'Common/components/billing-cycle-label/billing-cycle-label.component.vue';

export default Vue.extend({
  components: {
    BillingCycleLabel,
  },
  props: {
    billingData: {
      type: Object,
      required: true,
    },
  },
});
</script>
