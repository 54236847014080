import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VCard,
    { staticClass: "pa-6", attrs: { flat: "", outlined: "" } },
    [
      _c(
        VRow,
        { staticClass: "my-n3" },
        [
          _c(
            VCol,
            { attrs: { cols: "4" } },
            [
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "headline primary--text font-weight-medium",
                    attrs: { href: _vm.merchantUrl },
                  },
                  [
                    _c("mp-highlight-substring", {
                      attrs: {
                        text: _vm.merchant.name,
                        match: _vm.activeNameFilter,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.merchant.companyName)),
              ]),
              _c("div", { staticClass: "caption disabled--text" }, [
                _vm._v(
                  "Last modified on " +
                    _vm._s(_vm._f("formatDate")(_vm.merchant.modifiedAt)) +
                    " - by " +
                    _vm._s(_vm.merchant.modifiedBy)
                ),
              ]),
              _vm.merchant.serviceLevel
                ? _c(
                    VChip,
                    {
                      staticClass: "mt-6 service-level",
                      attrs: { color: "secondaryLight" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "subtitle-2 service-level-text" },
                        [_vm._v(_vm._s(_vm.merchant.serviceLevel))]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.merchant.factoring
                ? _c(
                    VChip,
                    {
                      staticClass: "mt-6 service-level ml-1",
                      attrs: { color: "secondaryLight" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "subtitle-2 service-level-text" },
                        [_vm._v("Factoring " + _vm._s(_vm.merchant.factoring))]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            VCol,
            [
              _vm.merchant.contracts.length === 0
                ? _c(VFlex, { staticClass: "d-flex pt-3" }, [
                    _c(
                      "span",
                      { staticClass: "subtitle-2 no-sales-channels-label" },
                      [_vm._v("No sales channel(s) selected")]
                    ),
                    _vm.canEdit
                      ? _c(
                          "a",
                          {
                            staticClass: "subtitle-2",
                            attrs: {
                              href: _vm.salesChannelsUrl,
                              "data-testid": "select-sales-channel",
                            },
                          },
                          [_vm._v("SELECT SALES CHANNEL")]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._l(_vm.retailersByStatus, function (statusData) {
                return _c(
                  "div",
                  { key: statusData.status },
                  [
                    statusData.retailers.length > 0 ||
                    (statusData.shouldDisplayMerchant && _vm.canSeeGeneralData)
                      ? _c(
                          VFlex,
                          { staticClass: "d-flex pt-1" },
                          [
                            _c(
                              "div",
                              { staticClass: "subtitle-2 status-label" },
                              [
                                _c("span", {
                                  staticClass: "status-label-circle",
                                  style: {
                                    color:
                                      _vm.MCP_MERCHANT_STATUS_COLORS[
                                        statusData.status
                                      ],
                                  },
                                }),
                                _vm._v(
                                  _vm._s(
                                    _vm.MCP_MERCHANT_STATUS_LABELS[
                                      statusData.status
                                    ]
                                  )
                                ),
                              ]
                            ),
                            _c(
                              VFlex,
                              [
                                statusData.shouldDisplayMerchant &&
                                _vm.canSeeGeneralData
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "subtitle-2",
                                        attrs: {
                                          href: _vm.merchantUrl,
                                          "data-testid": "general-company-data",
                                        },
                                      },
                                      [_vm._v("GENERAL COMPANY DATA")]
                                    )
                                  : _vm._e(),
                                _vm._l(
                                  statusData.retailers,
                                  function (retailer) {
                                    return _c(
                                      "div",
                                      {
                                        key: retailer.name,
                                        attrs: { "data-testid": "retailers" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "subtitle-2 text-uppercase retailer",
                                          },
                                          [_vm._v(_vm._s(retailer.name) + " ")]
                                        ),
                                        _vm._l(
                                          retailer.contracts,
                                          function (contract, index) {
                                            return _c(
                                              "span",
                                              {
                                                key: contract.id,
                                                staticClass:
                                                  "subtitle-2 text-uppercase",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: _vm.getContractUrl(
                                                        contract.id
                                                      ),
                                                      "data-testid":
                                                        "`sales-channel-${contract.salesChannel}`",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        contract.salesChannel
                                                      )
                                                    ),
                                                    index <
                                                    retailer.contracts.length -
                                                      1
                                                      ? _c("span", [
                                                          _vm._v(", "),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }