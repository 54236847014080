import {
  ProviderFormField,
  ContextParams,
} from 'Common/constants/configuration.constant';
import { Configuration } from 'Api/models/Configuration';
import { ConfigPartnerProvider } from 'Api/models/ConfigPartnerProvider';

interface ChAdvisorConfigFormParams {
  merchantId: string;
  channelId: string;
}

interface ChAdvisorUrlParams
  extends ConfigPartnerProvider,
    ContextParams,
    ChAdvisorConfigFormParams {}

export function generateChAdvisorConfig(
  config: Configuration, // current config
  params: ChAdvisorUrlParams
): Configuration {
  const {
    merchantId,
    channelId,
    ftpPassword,
    merchantName,
    channelCountryCode,
    isZfs,
  } = params;
  const extChannelPrefix = isZfs ? 'zfs' : 'za';
  const ftpExternalChannel = `${extChannelPrefix}${channelCountryCode}`;
  const articleURLSuffix = isZfs ? 'zfsarticles' : 'articles';

  return {
    ...config,
    articleConfig: {
      ...config.articleConfig,
      articleUrl: `https://webadapters.channeladvisor.com/zalandoapi/v1/zalandonew/${merchantId}/${channelId}/${articleURLSuffix}`,
      stockUrl: `https://webadapters.channeladvisor.com/zalandoapi/v1/zalandonew/${merchantId}/${channelId}/stocks?unixTimestamp={LastUpdateUnixTimeStamp}`,
    },
    ftpConfig: {
      ...config.ftpConfig,
      ftpUsername: 'zalando',
      ftpPassword,
      ftpUrl: 'ftp://ftp2.channeladvisor.com',
      ftpPath: `/${merchantId}/`,
      ftpExternalPartnerName: merchantName,
      ftpExternalChannel,
      ftpExternalSubChannel: merchantId,
    },
    username: 'zalando',
  };
}

export function getChAdvisorFormFields(): ProviderFormField[] {
  return [
    {
      label: 'Channel Advisor Merchant ID',
      name: 'merchantId',
      required: true,
    },
    { label: 'Channel Advisor Channel ID', name: 'channelId', required: true },
  ];
}
