<template lang="pug">
div.legalEntitiesContainer
  //- `content-class` and `attach` are needed to add CSS (for wrapping) to the menu content.
  v-menu(
    content-class="entityMenuContent"
    attach=".legalEntitiesContainer"
    bottom
    nudge-bottom="58"
    :close-on-click="true"
    :close-on-content-click="false"
    @update:return-value="onMenuClose"
    v-model="isMenuVisible"
    )
    //- Input field
    template(v-slot:activator="{ on }")
      v-text-field(
        label="Search Legal Entities"
        hint="Search to select legal entities to associate with merchant account"
        persistent-hint
        filled
        clearable
        v-on="on"
        v-model="searchQuery"
        v-stream:input="searchInput$"
        :loading="itemsLoading"
        :disabled="disabled"
        @keydown="onKeydown($event, on)"
        @blur="onBlur"
        ref="input"
      )

    //- Dropdown Menu
    v-card
      v-card-text.text--primary
        div(v-if="(!searchQuery || searchQuery.length < minSearchQueryLength)")
          div Type at least {{ minSearchQueryLength }} characters to start searching
        div(v-else)
          div(v-if="items.assignedItems.length || items.unassignedItems.length")
            div Legal entities with similar names:

            //- Unassigned Items
            div.unassignedItems(v-if="items.unassignedItems.length")
              div(v-for="item in items.unassignedItems")
                v-checkbox.mt-2.primary--text(
                  color="primary"
                  hide-details
                  :label="item.name"
                  :input-value="value.has(item.id)"
                  :disabled="!removable && defaultValue.has(item.id)"
                  @change="onChange(item, $event)"
                  )

            v-divider.mt-2(v-if="items.assignedItems.length && items.unassignedItems.length")

            //- Assigned Items
            div.assignedItems(v-if="items.assignedItems.length")
              div.mt-2(v-for="item in items.assignedItems") {{ item.name }} - Already added in "{{ item.merchantAccount.name }}" merchant account

          div.mb-4(v-else-if="itemsLoading") Loading
          div.mb-4(v-else) No Legal Entities with similar names found

  //- Submit error
  v-snackbar(
    v-if="notify === 'NOTIFY_SEARCH_FAILED'"
    v-model="notify"
    color="reject"
    :timeout="3000"
   )
    |  Error while searching for legal entities
    v-btn(text dark @click="notify = false") Close
</template>

<script lang="ts">
import Vue from 'vue';
import { Subject, from, merge, ConnectableObservable } from 'rxjs';
import {
  filter,
  pluck,
  debounceTime,
  switchMap,
  mapTo,
  startWith,
  multicast,
} from 'rxjs/operators';
import {
  fetchLegalEntitiesCombined,
  LegalEntitiesCombinedResponse,
} from 'Api/endpoints/legal-entity/legal-entity.endpoint';
import LegalEntity from 'Api/models/LegalEntity';

const itemsResponseDefault = { assignedItems: [], unassignedItems: [] };

export default Vue.extend({
  props: {
    value: {
      type: Set,
      default: () => new Set(),
    },
    defaultValue: {
      type: Set,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    removable: {
      type: Boolean,
      required: true,
    },
    accountId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      searchQuery: '' as string | null,
      minSearchQueryLength: 3,
      notify: null,
      isMenuVisible: false,
    };
  },
  subscriptions() {
    // @ts-ignore
    const fetchItems = this.$_fetchItems.bind(this);
    const searchInput$ = new Subject().pipe(
      pluck('event', 'msg'),
      filter(
        // @ts-ignore
        (query: string) => query && query.length >= this.minSearchQueryLength
      )
    );

    // @ts-ignore
    this.searchInput$ = searchInput$;

    const results$ = searchInput$.pipe(
      debounceTime(500),
      // Take results from the latest input only
      switchMap((query: string) =>
        query
          ? from(fetchItems(query))
          : from(Promise.resolve(itemsResponseDefault))
      ),
      // Convert a regular Observable into a ConnectableObservable
      // with shared subscription between subscribers
      // in order to avoid duplicated api fetchMerchantAccounts requests
      multicast(new Subject())
    ) as ConnectableObservable<any>;

    const subscription = results$.connect();

    // Unsubscribe on component destroy
    this.$once('hook:beforeDestroy', () => subscription.unsubscribe());

    return {
      items: results$.pipe(startWith(itemsResponseDefault)),
      itemsLoading: merge(
        searchInput$.pipe(mapTo(true)),
        results$.pipe(mapTo(false))
      ).pipe(startWith(false)),
    };
  },
  methods: {
    /**
     * Remove focus from the field when menu is being closed
     */
    onMenuClose(): void {
      this.$refs.input.blur();
    },
    /**
     * Input field should always be focused while menu is open
     */
    onBlur(): void {
      if (this.isMenuVisible) {
        this.$refs.input.focus();
      } else {
        this.searchQuery = '';
      }
    },
    /**
     * Menu should always be visible while a user interacts with the field
     */
    onKeydown(): void {
      this.isMenuVisible = true;
    },
    onChange(item: LegalEntity, selected: boolean): void {
      const selectedItems = new Set(this.value);

      if (selected) {
        selectedItems.add(item.id);
        this.$emit('add', item.id);
      } else {
        selectedItems.delete(item.id);
      }

      this.$emit('input', selectedItems);
    },
    $_fetchItems(q: string): Promise<LegalEntitiesCombinedResponse> {
      return fetchLegalEntitiesCombined(this.accountId, q).catch(() => {
        this.notify = 'NOTIFY_SEARCH_FAILED';
        return itemsResponseDefault;
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.unassignedItems {
  max-height: 190px;
  overflow-y: scroll;
}
.assignedItems {
  max-height: 150px;
  overflow-y: scroll;
}
.entityMenuContent {
  max-width: min-content;
}
</style>
