import {
  ProviderFormField,
  ContextParams,
  ConfigFormFieldParams,
} from 'Common/constants/configuration.constant';
import { CHANNELS, LOCALES } from 'Common/constants/channels.constant';
import { MCP_FF_TYPES } from 'Common/constants/misc.constant';
import { ConfigPartnerProvider } from 'Api/models/ConfigPartnerProvider';
import {
  Configuration,
  ArticleConfig,
  OrderConfig,
} from 'Api/models/Configuration';
import { map } from 'ramda';

interface TradebyteConfigFormParams {
  merchantId: string;
  channelId: string;
  subChannelId?: string;
  ftpUsername?: string;
  ftpPassword?: string;
}

export interface TradebyteUrlParams
  extends ConfigPartnerProvider,
    ContextParams,
    TradebyteConfigFormParams {}

export function generateTradebyteConfig(
  config: Configuration, // current config
  params: TradebyteUrlParams
): Configuration {
  const {
    merchantId,
    channelId,
    subChannelId,
    locale,
    isZfs,
    ftpUsername = '', // Optional, maybe not provided by a user
    ftpPassword = '', // Optional, maybe not provided by a user
    passwordsPerChannel = [],
  } = params;

  const passwordsForChannel = passwordsPerChannel.find(
    (item) => item.locale === locale
  );

  const articleConfig: ArticleConfig = {
    articleUrl: `https://api.prod.dl.tradebyte.com/${merchantId}/products?channel=${channelId}`,
    stockUrl: `https://api.prod.dl.tradebyte.com/${merchantId}/stocks?channel=${channelId}&delta={LastUpdateUnixTimeStamp}`,
  };

  const orderConfig: OrderConfig = {
    orderExportUrl: `https://rest.trade-server.net/${merchantId}/orders/?channel=${channelId}`,
    orderConfirmationUrl: `https://rest.trade-server.net/${merchantId}/orders/{partnerOrderNumber}/approved?channel=${channelId}`,
    orderCancellationUrl: `https://rest.trade-server.net/${merchantId}/orders/{partnerOrderNumber}?channel=${channelId}`,
    orderMessagesUrl: `https://rest.trade-server.net/${merchantId}/messages/?channel=${channelId}`,
    orderMessagesProcessUrl: `https://rest.trade-server.net/${merchantId}/messages/{messageId}/processed?channel=${channelId}`,
    orderSingleStatusUrl: `https://rest.trade-server.net/${merchantId}/orders/?channel=${channelId}&channel_no={zalandoOrderNumber}&exported=null&pending=null&mode=tborder`,
  };

  return {
    ...config,
    articleConfig: {
      ...config.articleConfig,
      ...(subChannelId ? map(addSubchannelFn, articleConfig) : articleConfig),
    },
    orderConfig: {
      ...config.orderConfig,
      ...(subChannelId ? map(addSubchannelFn, orderConfig) : orderConfig),
    },
    ftpConfig: {
      ...config.ftpConfig,
      ftpUsername,
      ftpPassword,
      ftpUrl: 'ftps://ftps.trade-server.net',
      ftpPath: '/in/',
      ftpExternalPartnerName: merchantId,
      ftpExternalChannel: getFtpExternalChannel(),
      ftpExternalSubChannel: '',
    },
    username:
      passwordsForChannel && passwordsForChannel[isZfs ? 'zfsUser' : 'pfUser'],
    password:
      passwordsForChannel &&
      passwordsForChannel[isZfs ? 'zfsPassword' : 'pfPassword'],
  };

  // Add subChannelId to every non-ftp prop
  function addSubchannelFn(fieldTemplate: string): string {
    return (
      fieldTemplate +
      (fieldTemplate.includes('?') ? '&' : '?') +
      'sub_channel=' +
      subChannelId
    );
  }

  /**
   * Derive ftpExternalChannel from ftpUsername
   * - if there is '_' in "FTP Username", then use the substring after _
   * - if there is 'za' in "FTP Username", then use the substring starting with za
   */
  function getFtpExternalChannel(): string {
    if (!ftpUsername) {
      return '';
    } else if (ftpUsername.includes('_')) {
      return ftpUsername.slice(ftpUsername.indexOf('_') + 1);
    } else if (ftpUsername.includes('za')) {
      return ftpUsername.slice(ftpUsername.indexOf('za'));
    }

    return '';
  }
}

export function getTradebyteFormFields({
  countryCode,
  isZfs,
  locale,
  locales,
}: ConfigFormFieldParams): ProviderFormField[] {
  return [
    {
      label: 'Tradebyte merchant Id',
      name: 'merchantId',
      required: true,
    },
    {
      label: 'Tradebyte channel Id',
      name: 'channelId',
      required: true,
      defaultValue: getChannelId(countryCode, isZfs, locale),
    },
    {
      label: 'Tradebyte subchannel Id',
      name: 'subChannelId',
      required: !isZfs && new Set(locales).size > 1, // locales.length > 1 doesn't always work because sometimes there are duplicated locales (e.g. one is active and the other is inactive)
      defaultValue: getSubChannelId(countryCode, isZfs, locale),
    },
    {
      label: 'FTP Username',
      name: 'ftpUsername',
    },
    {
      label: 'FTP Password',
      name: 'ftpPassword',
    },
  ];
}

function getChannelId(
  countryCode: string,
  isZfs: boolean,
  locale: string
): number {
  const { PF, ZFS } = MCP_FF_TYPES;
  const ffType = isZfs ? ZFS : PF;
  const data = {
    [CHANNELS.AUSTRIA + LOCALES.AUSTRIA.DE_AT + PF]: 42,
    [CHANNELS.AUSTRIA + LOCALES.AUSTRIA.DE_AT + ZFS]: 194,
    [CHANNELS.BELGIUM + LOCALES.BELGIUM.FR_BE + PF]: 72,
    [CHANNELS.BELGIUM + LOCALES.BELGIUM.NL_BE + PF]: 72,
    [CHANNELS.BELGIUM + LOCALES.BELGIUM.FR_BE + ZFS]: 212,
    [CHANNELS.BELGIUM + LOCALES.BELGIUM.NL_BE + ZFS]: 213,
    [CHANNELS.SWITZERLAND + LOCALES.SWITZERLAND.FR_CH + PF]: 63,
    [CHANNELS.SWITZERLAND + LOCALES.SWITZERLAND.DE_CH + PF]: 63,
    [CHANNELS.SWITZERLAND + LOCALES.SWITZERLAND.FR_CH + ZFS]: 224,
    [CHANNELS.SWITZERLAND + LOCALES.SWITZERLAND.DE_CH + ZFS]: 223,
    [CHANNELS.GERMANY + LOCALES.GERMANY.DE_DE + PF]: 30,
    [CHANNELS.GERMANY + LOCALES.GERMANY.DE_DE + ZFS]: 176,
    [CHANNELS.DENMARK + LOCALES.DENMARK.DA_DK + PF]: 113,
    [CHANNELS.DENMARK + LOCALES.DENMARK.DA_DK + ZFS]: 221,
    [CHANNELS.IRELAND + LOCALES.IRELAND.EN_IE + PF]: 328,
    [CHANNELS.IRELAND + LOCALES.IRELAND.EN_IE + ZFS]: 285,
    [CHANNELS.SPAIN + LOCALES.SPAIN.ES_ES + PF]: 171,
    [CHANNELS.SPAIN + LOCALES.SPAIN.ES_ES + ZFS]: 203,
    [CHANNELS.FINLAND + LOCALES.FINLAND.FI_FI + PF]: 199,
    [CHANNELS.FINLAND + LOCALES.FINLAND.FI_FI + ZFS]: 220,
    [CHANNELS.FRANCE + LOCALES.FRANCE.FR_FR + PF]: 57,
    [CHANNELS.FRANCE + LOCALES.FRANCE.FR_FR + ZFS]: 195,
    [CHANNELS.UNITED_KINGDOM + LOCALES.UNITED_KINGDOM.EN_GB + PF]: 142,
    [CHANNELS.UNITED_KINGDOM + LOCALES.UNITED_KINGDOM.EN_GB + ZFS]: 196,
    [CHANNELS.ITALY + LOCALES.ITALY.IT_IT + PF]: 88,
    [CHANNELS.ITALY + LOCALES.ITALY.IT_IT + ZFS]: 214,
    [CHANNELS.NETHERLANDS + LOCALES.NETHERLANDS.NL_NL + PF]: 39,
    [CHANNELS.NETHERLANDS + LOCALES.NETHERLANDS.NL_NL + ZFS]: 211,
    [CHANNELS.POLAND + LOCALES.POLAND.PL_PL + PF]: 172,
    [CHANNELS.POLAND + LOCALES.POLAND.PL_PL + ZFS]: 222,
    [CHANNELS.SWEDEN + LOCALES.SWEDEN.SV_SE + PF]: 173,
    [CHANNELS.SWEDEN + LOCALES.SWEDEN.SV_SE + ZFS]: 219,
    [CHANNELS.CZECH_REPUBLIC + LOCALES.CZECH_REPUBLIC.CS_CZ + PF]: 225,
    [CHANNELS.CZECH_REPUBLIC + LOCALES.CZECH_REPUBLIC.CS_CZ + ZFS]: 284,
    [CHANNELS.NORWAY + LOCALES.NORWAY.NO_NO + PF]: 340,
    [CHANNELS.ESTONIA + LOCALES.ESTONIA.ET_EE + ZFS]: 359,
    [CHANNELS.CROATIA + LOCALES.CROATIA.HR_HR + ZFS]: 360,
    [CHANNELS.LITHUANIA + LOCALES.LITHUANIA.LT_LT + ZFS]: 361,
    [CHANNELS.LATVIA + LOCALES.LATVIA.LV_LV + ZFS]: 362,
    [CHANNELS.SLOVENIA + LOCALES.SLOVENIA.SL_SI + ZFS]: 363,
    [CHANNELS.SLOVAKIA + LOCALES.SLOVAKIA.SK_SK + ZFS]: 364,
    [CHANNELS.HUNGARY + LOCALES.HUNGARY.HU_HU + ZFS]: 432,
    [CHANNELS.ROMANIA + LOCALES.ROMANIA.RO_RO + ZFS]: 433,
  };

  return data[countryCode + locale + ffType];
}

function getSubChannelId(
  countryCode: string,
  isZfs: boolean,
  locale: string
): number {
  const { PF, ZFS } = MCP_FF_TYPES;
  const ffType = isZfs ? ZFS : PF;

  const data = {
    [CHANNELS.BELGIUM + LOCALES.BELGIUM.FR_BE + PF]: 73,
    [CHANNELS.BELGIUM + LOCALES.BELGIUM.NL_BE + PF]: 74,
    [CHANNELS.SWITZERLAND + LOCALES.SWITZERLAND.FR_CH + PF]: 65,
    [CHANNELS.SWITZERLAND + LOCALES.SWITZERLAND.DE_CH + PF]: 64,
  };

  return data[countryCode + locale + ffType];
}
