<template lang="pug">
  div.mr-2
    v-icon(:color="statusIcon.color")
      | {{statusIcon.icon}}
</template>

<script lang="ts">
import Vue from 'vue';
import { ApprovalStatusT } from 'Api/models/ApprovalStatus';

export const VAT_STATUS_MAP: {
  [key: string]: { icon: string; color: string };
} = {
  pending: { icon: 'mdi-account-clock-outline', color: 'black' },
  rejected: { icon: 'mdi-close-circle', color: 'error' },
  inReview: { icon: 'mdi-timer-outline', color: 'info' },
  inReviewAndManualValidationPending: {
    icon: 'mdi-timer-star-outline',
    color: 'warning',
  },
  approved: { icon: 'mdi-check-circle', color: 'success' },
};
export default Vue.extend({
  props: {
    status: {
      type: String as () => ApprovalStatusT,
      required: true,
    },
    manualReviewRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    statusIcon(): { icon: string; color: string } | null {
      switch (this.status) {
        case ApprovalStatusT.PENDING:
          return VAT_STATUS_MAP.pending;
        case ApprovalStatusT.REJECTED:
          return VAT_STATUS_MAP.rejected;
        case ApprovalStatusT.IN_REVIEW:
          if (this.manualReviewRequired) {
            return VAT_STATUS_MAP.inReviewAndManualValidationPending;
          } else {
            return VAT_STATUS_MAP.inReview;
          }
        case ApprovalStatusT.APPROVED:
          return VAT_STATUS_MAP.approved;
        default:
          return VAT_STATUS_MAP.pending;
      }
    },
  },
});
</script>
