import { MerchantComplianceRequirementT } from 'Api/models/ComplianceRequirement';
import {
  updateFixedEstablishmentReviewStatus,
  updateOssReviewStatus,
  updateZfsVatReviewStatus,
} from 'Api/endpoints/legal-entity/compliance.endpoint';
import { FixedEstablishmentTypeT } from 'Api/models/FixedEstablishment';
import { updateOutboundWarehouseReviewStatus } from 'Api/endpoints/merchant/merchant-compliance.endpoint';
import {
  ComplianceDataListT,
  ProvidedRejectionReasonT,
  SelectedStatusesT,
} from 'Merchant/general/components/company-compliance-details/util';
import {
  ApprovalStatusT,
  SelectableReviewStatusT,
} from 'Api/models/ApprovalStatus';

interface SubmitHandlerPropsT extends ComplianceDataListT {
  legalEntityId: string;
  merchantId: string;
  requirements: MerchantComplianceRequirementT[];
  selectedStatuses: SelectedStatusesT;
  providedRejectionReasons: ProvidedRejectionReasonT;
}

const createRequestBody = (
  status: SelectableReviewStatusT,
  rejectionReason: string
) => {
  if (status === ApprovalStatusT.REJECTED) {
    return {
      status,
      rejectionReason,
    };
  }

  return { status };
};

export const handleReviewSubmit = ({
  legalEntityId,
  merchantId,
  requirements,
  selectedStatuses,
  providedRejectionReasons,
  outboundWarehouseData,
  zfsVatData,
  euFixedEstablishmentData,
  ukFixedEstablishmentData,
  ossData,
}: SubmitHandlerPropsT): Promise<void[]> => {
  const submitRequests = [];
  requirements.forEach((requirement) => {
    switch (requirement) {
      case MerchantComplianceRequirementT.FIXED_ESTABLISHMENT_EU:
        if (
          euFixedEstablishmentData.approvalStatus === ApprovalStatusT.IN_REVIEW
        ) {
          submitRequests.push(
            updateFixedEstablishmentReviewStatus(
              legalEntityId,
              FixedEstablishmentTypeT.EU,
              createRequestBody(
                selectedStatuses.euFixedEstablishment,
                providedRejectionReasons.euFixedEstablishment
              )
            )
          );
        }
        break;
      case MerchantComplianceRequirementT.FIXED_ESTABLISHMENT_UK:
        if (
          ukFixedEstablishmentData.approvalStatus === ApprovalStatusT.IN_REVIEW
        ) {
          submitRequests.push(
            updateFixedEstablishmentReviewStatus(
              legalEntityId,
              FixedEstablishmentTypeT.UK,
              createRequestBody(
                selectedStatuses.ukFixedEstablishment,
                providedRejectionReasons.ukFixedEstablishment
              )
            )
          );
        }
        break;
      case MerchantComplianceRequirementT.OSS:
        if (ossData.approvalStatus === ApprovalStatusT.IN_REVIEW) {
          submitRequests.push(
            updateOssReviewStatus(
              legalEntityId,
              createRequestBody(
                selectedStatuses.oss,
                providedRejectionReasons.oss
              )
            )
          );
        }
        break;
      case MerchantComplianceRequirementT.ZFS_VAT:
        zfsVatData.forEach(({ key, approvalStatus }) => {
          if (approvalStatus === ApprovalStatusT.IN_REVIEW) {
            submitRequests.push(
              updateZfsVatReviewStatus(
                legalEntityId,
                key,
                createRequestBody(
                  selectedStatuses.zfsVat[key],
                  providedRejectionReasons.zfsVat[key]
                )
              )
            );
          }
        });
        break;
      case MerchantComplianceRequirementT.OUTBOUND_WAREHOUSES:
        outboundWarehouseData.forEach(({ warehouseId, approvalStatus }) => {
          if (approvalStatus === ApprovalStatusT.IN_REVIEW) {
            submitRequests.push(
              updateOutboundWarehouseReviewStatus(
                merchantId,
                warehouseId,
                createRequestBody(
                  selectedStatuses.outboundWarehouse[warehouseId],
                  providedRejectionReasons.outboundWarehouse[warehouseId]
                )
              )
            );
          }
        });
        break;
    }
  });

  return Promise.all(submitRequests);
};
