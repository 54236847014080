<template lang="pug">
v-app.root
  v-main
    ContractRow(
      :merchantId="merchant.$id"
      :salesChannelId="contract.salesChannelId"
      :locales="locales"
      :useDraft="!isLaunched"
      :status="status"
      :fulfillmentType="fulfillmentType"
      )
</template>

<script lang="ts">
import Vue from 'vue';
import { fetchReturnAddress } from 'Api/endpoints/rights-of-withdrawal/row.endpoint';
import { Contract } from 'Api/models/Contract';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { MCP_FF_TYPES } from 'Common/constants/misc.constant';
import { CONTRACT_REQ_LAUNCH_STATUSES } from 'Common/constants/contract-statuses.constant';
import ContractRow from './row/contract-row.vue';

import {
  ROW_PAGE_STATUS_LOADING,
  ROW_PAGE_STATUS_INIT_FAILED,
  ROW_PAGE_STATUS_NO_ADDRESS,
  ROW_PAGE_STATUS_OK,
} from './row.constants';

export const ADDRESS_STATUS_LOADING = 'RETURN_ADDRESS_STATUS_LOADING';
export const ADDRESS_STATUS_ERROR = 'RETURN_ADDRESS_STATUS_ERROR';
export const ADDRESS_STATUS_DEFINED = 'RETURN_ADDRESS_STATUS_DEFINED';
export const ADDRESS_STATUS_NOT_DEFINED = 'RETURN_ADDRESS_STATUS_NOT_DEFINED';

function isLaunchedFn(contract: Contract) {
  return contract.launchStatus === CONTRACT_REQ_LAUNCH_STATUSES.DONE;
}

export default Vue.extend({
  components: { ContractRow },
  props: {
    merchant: Object,
    contract: Object,
    contracts: Array,
  },
  data() {
    return {
      returnAddressStatus: ADDRESS_STATUS_LOADING, // Partner Fulfillment return address status
    };
  },
  computed: {
    /**
     * Should be set to
     * - null if ROW doc should contain return addresses for both fulfillment types: PF and ZFS
     * - the current contract's fulfillment type if ROW doc should contain return address for that fulfillment type only
     */
    fulfillmentType(): string {
      const { fulfillmentType } = this.contract as Contract;

      if (
        this.contracts.length > 1 &&
        this.returnAddressStatus === ADDRESS_STATUS_DEFINED &&
        (!this.isLaunched || this.contracts.every(isLaunchedFn))
      ) {
        return null;
      }

      return fulfillmentType;
    },
    locales(): string[] {
      return this.contract.partners.map((partner) => partner.locale);
    },
    isLaunched(): boolean {
      return isLaunchedFn(this.contract);
    },
    status(): string {
      const isZFS = this.contract.fulfillmentType === MCP_FF_TYPES.ZFS;

      switch (this.returnAddressStatus) {
        case ADDRESS_STATUS_LOADING:
          return ROW_PAGE_STATUS_LOADING;
        case ADDRESS_STATUS_ERROR:
          return ROW_PAGE_STATUS_INIT_FAILED;
        case ADDRESS_STATUS_NOT_DEFINED:
          if (isZFS) {
            return ROW_PAGE_STATUS_OK;
          }
          return ROW_PAGE_STATUS_NO_ADDRESS;
        case ADDRESS_STATUS_DEFINED:
          return ROW_PAGE_STATUS_OK;
        default:
          return ROW_PAGE_STATUS_INIT_FAILED;
      }
    },
  },
  mounted() {
    this.$_fetchReturnAddress();
  },
  methods: {
    $_fetchReturnAddress() {
      // @ts-ignore
      const merchant = this.merchant as MerchantProfile;

      return (
        fetchReturnAddress(merchant.$id)
          // @ts-ignore
          .catch(() => (this.returnAddressStatus = ADDRESS_STATUS_ERROR))
          .then((address) => {
            // @ts-ignore
            this.returnAddressStatus = address
              ? ADDRESS_STATUS_DEFINED
              : ADDRESS_STATUS_NOT_DEFINED;
          })
      );
    },
  },
});
</script>

<style lang="scss" scoped></style>
