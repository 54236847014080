import template from './company-metadata-md-export-lang.html';
import {
  MD_EXPORT_LANGS,
  MD_EXPORT_LANGS_MAP,
} from 'Common/constants/misc.constant';

const MD_EXPORT_LANGS_OPTS = MD_EXPORT_LANGS.map((value) => ({
  value,
  label: MD_EXPORT_LANGS_MAP[value],
}));

export class CompanyMetadataMDExportLangComponent
  implements ng.IComponentOptions
{
  static Factory() {
    return new CompanyMetadataMDExportLangComponent();
  }

  bindings: { [binding: string]: string } = {
    onSubmit: '<',
    value: '<',
    isSubmitInProgress: '<',
    isInitialized: '<',
  };

  controller = MetadataMDExportLangController;
  controllerAs = 'ctrl';
  template: string = template;
}

export class MetadataMDExportLangController implements ng.IController {
  static $inject = [];

  formValue: string;
  options: Array<any>;
  // From bindings
  isSubmitInProgress: boolean;
  isInitialized: boolean;
  value: string;
  private onSubmit: Function;

  $onInit(): void {
    // Initialize form
    this.formValue = this.value;
    this.options = this.getOptions(this.value);
  }

  $onChanges(changes): void {
    if (changes && changes.value) {
      this.formValue = this.value;
      this.options = this.getOptions(this.value);
    }
  }

  cancel(): void {
    this.formValue = this.value;
  }

  /**
   * Disable the button if nothing changed or the submit is already in progress
   * or the field is not initialized yet
   * @returns {boolean}
   */
  isCancelDisabled(): boolean {
    return (
      !this.isInitialized ||
      this.isSubmitInProgress ||
      this.formValue === this.value
    );
  }

  /**
   * Disable the button if nothing changed or the submit is already in progress
   * or the field is not initialized yet
   * @returns {boolean}
   */
  isSubmitDisabled(): boolean {
    return (
      !this.isInitialized ||
      this.isSubmitInProgress ||
      this.formValue === this.value
    );
  }

  submit(): void {
    this.onSubmit(this.formValue);
  }

  private getOptions(value) {
    return value
      ? MD_EXPORT_LANGS_OPTS
      : [
          { label: '-- NOT DEFINED --', value: null, disabled: true },
          ...MD_EXPORT_LANGS_OPTS,
        ];
  }
}
