import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    [
      _c("Appbar"),
      _c(
        VMain,
        [
          _c(
            VSnackbar,
            {
              attrs: { timeout: -1, bottom: "", color: "reject" },
              model: {
                value: _vm.hasError,
                callback: function ($$v) {
                  _vm.hasError = $$v
                },
                expression: "hasError",
              },
            },
            [
              _vm._v(_vm._s(_vm.errorMessage)),
              _c(
                VBtn,
                { attrs: { text: "" }, on: { click: _vm.retrySearch } },
                [_vm._v("Retry")]
              ),
            ],
            1
          ),
          _c(
            VContainer,
            { attrs: { fluid: "" } },
            [
              _c("Toolbar"),
              _c(
                "div",
                {
                  staticClass: "mt-n1",
                  staticStyle: { width: "400px", "margin-top": "-12px" },
                },
                [
                  _c("Search", {
                    attrs: {
                      loading: _vm.searchQuery !== "" && _vm.searchInProgress,
                      label: "Search for merchant accounts",
                    },
                    on: { input: _vm.searchChange },
                  }),
                ],
                1
              ),
              _vm.searchQuery
                ? _c("div", { staticClass: "pb-6 title secondary--text" }, [
                    _vm._v(
                      _vm._s(_vm.totalNumberOfAccounts) +
                        " merchant accounts found matching your search"
                    ),
                  ])
                : _vm._e(),
              _vm._l(_vm.merchantAccounts, function (account) {
                return _c(
                  "div",
                  { key: account.href, staticClass: "mb-4" },
                  [
                    _c("MerchantAccountSummaryCard", {
                      attrs: { account: account },
                    }),
                  ],
                  1
                )
              }),
              _vm.totalNumberOfAccounts > 0
                ? _c("Pagination", {
                    attrs: {
                      showingFrom: _vm.showingFrom,
                      showingTo: _vm.showingTo,
                      totalCount: _vm.totalNumberOfAccounts,
                    },
                    on: {
                      "select-page-size": _vm.setPageSize,
                      "previous-page": _vm.previousPage,
                      "next-page": _vm.nextPage,
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }