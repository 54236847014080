<template lang="pug">
v-text-field(
  :label="label"
  :disabled="disabled"
  :loading="loading"
  height="40px"
  background-color="secondaryLight"
  single-line
  v-model="searchQuery"
  ref="textInput"
  @change="change"
  @input="input"
  @keydown="keydown"
)
  template(v-slot:prepend-inner)
    v-container.search-icon-container(fill-height)
      v-icon(:disabled="disabled") search
  //- Why using `append` slot instead of `clearable` prop?
  //- Because we need some custom styling that can't be done otherwise.
  template(v-slot:append)
    v-container.clear-icon-container(v-if="searchQuery" fill-height @click="clear()")
      v-icon(:disabled="disabled") close
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'Search',
  props: {
    label: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: this.defaultValue,
    };
  },
  methods: {
    change(event): void {
      this.$emit('change', event);
    },
    input(event): void {
      this.$emit('input', event);
    },
    keydown(event): void {
      this.$emit('keydown', event);
    },
    clear(): void {
      this.searchQuery = '';
      this.$emit('input', this.searchQuery);
    },
  },
});
</script>

<style lang="scss" scoped>
.search-icon-container {
  height: 32px;
  padding-left: 24px;
  padding-right: 16px;
  flex-wrap: nowrap;
}

.clear-icon-container {
  height: 32px;
  padding: 0;
  margin-right: 16px;
  cursor: pointer;
}
</style>
