<template lang="pug">
  div.mcp-admin-merchant-details-section-form
    p.dc-p
      strong Important: 
      | This data will be used only for {{countryName}}.

    form(novalidate)
      div.mcp-admin__form-field
        label.dc-label.dc-label--required(for="billing-cycle") Billing cycle

      select.dc-select.mcp-admin-merchant-details-select(
        name="billing-cycle"
        id="billing-cycle"
        required
        :disabled="true"
        v-model="data.billingCycle"
      )
        option(
          v-for="{name, value} in billingCycleOptions"
          :value="value"
        ) {{name}}

      div.mcp-admin__form-field
        label.dc-label.dc-label--required(
          :class="{'dc-label--is-error': isSubmitted && !isBillingCurrencySelected}"
          for="billing-currency"
        ) Billing currency
        div.mcp-admin__field-error-message(
          role="alert"
          v-if="isSubmitted && !isBillingCurrencySelected"
        )
          span.dc--text-error Please don't leave this empty.
        select.dc-select.mcp-admin-merchant-details-select(
          :class="{'dc-input--is-error': isSubmitted && !isBillingCurrencySelected}"
          id="billing-currency"
          name="billing-currency"
          v-model="data.billingCurrency"
          required
        )
          option(value="EUR") EUR
          option(value="CHF") CHF
          option(value="DKK") DKK
          option(value="SEK") SEK
          option(value="GBP") GBP
          option(value="NOK") NOK
          option(value="PLN") PLN
          option(value="CZK") CZK
          option(value="HUF") HUF
          option(value="RON") RON

    div.dc-row.dc-row--align--justify
      div.dc-column.dc-column--shrink
        button.dc-btn.dc-btn--link(
          :class="{'dc-btn--disabled': isSubmitting}"
          v-if="hasMarketBillingData"
          :disabled="isSubmitting"
          @click="useDefault"
        )
          span Use billing data from General Company Data
            i.dc-icon.dc-icon--undo.dc-icon--btn.dc-icon--btn--left
      div.dc-column.dc-column--shrink
        button.dc-btn.dc-btn--link(
          :disabled="isSubmitting"
          @click="$emit('cancel')"
        ) Cancel
        button.dc-btn.dc-btn--primary(
          :class="{'dc-btn--disabled': isSubmitting}"
          :disabled="isSubmitting"
          @click="submit"
        ) Save

</template>

<script lang="ts">
import Vue from 'vue';
import { BILLING_CYCLE_FORM_OPTIONS } from 'Common/constants/billing.constant';

export default Vue.extend({
  props: {
    useDefault: {
      type: Function,
      required: true,
    },
    countryName: {
      type: String,
      required: true,
    },
    billingData: {
      type: Object,
      required: false,
      default: function () {
        return {
          billingCurrency: '',
        };
      },
    },
    hasMarketBillingData: {
      type: Boolean,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      data: {
        billingCycle: 'P1M',
        billingCurrency: this.billingData.billingCurrency,
      },
      billingCycleOptions: BILLING_CYCLE_FORM_OPTIONS,
      isSubmitted: false,
    };
  },
  computed: {
    isBillingCurrencySelected: function () {
      return this.data.billingCurrency !== '';
    },
  },
  methods: {
    submit() {
      this.isSubmitted = true;

      if (!this.isBillingCurrencySelected) {
        return;
      }

      this.$emit('save', this.data);
    },
  },
});
</script>
