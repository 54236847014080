export default angular
  .module('mc.config', ['mcb.core.plugins', 'mcb.core.config'])
  .constant('MC_INITIAL_STATE', (<any>window).__MC_INITIAL_STATE__)
  .config([
    'mcbConfigProvider',
    'MC_INITIAL_STATE',
    (mcbConfigProvider, MC_INITIAL_STATE) => {
      mcbConfigProvider.setMainConfigurator(MC_INITIAL_STATE.clientConfig);

      mcbConfigProvider.addPluginConfigurator(
        'mc-package-admin',
        MC_INITIAL_STATE.clientConfig.urls
      );
    },
  ]);
