import { SalesChannel } from './SalesChannel';
import { MarketAddressT } from './Address';
import { Retailer } from './Retailer';
import { MarketBillingDataT } from './BillingData';
import { PreferredLanguageT } from 'Api/models/MerchantProfile';

export type ContractStatusT =
  | 'IN_PROGRESS'
  | 'IN_FINANCIAL_REVIEW'
  | 'FINANCIAL_APPROVED'
  | 'FINANCIAL_REJECTED'
  | 'AFFECTED'
  | 'DEACTIVATED';

export type IntegrationObjectStageT =
  | 'Brand Readiness'
  | 'Admin Integration'
  | 'Article Onboarding'
  | 'Delivery Test'
  | 'Commercial Launch'
  | 'Cancelled';
export interface Contract {
  $id?: string;
  $type?: string;
  // acceptor
  merchantId?: string;

  // giver
  retailerId?: string;

  salesChannelId?: string;
  launchStatus?: string;
  preferredLanguage?: PreferredLanguageT;
  salesChannel?: SalesChannel;
  sendDummyMail?: boolean;
  fulfillmentType?: 'ZALANDO' | 'PARTNER';
  integrationType?: 'DIRECT' | 'PARTNER_PROVIDER';
  address?: MarketAddressT;
  retailer?: Retailer;
  partnerProviderId?: number;
  partners?: Partner[];
  status?: ContractStatusT;
  approverComment?: string;
  live?: boolean;
  integrationObjectStage?: IntegrationObjectStageT;
  integrationObjectId?: string;
  isContractHasActiveStock?: boolean;
  liveStatusModifiedAt?: string;
  liveStatusModifiedBy?: string;
  offlineReason?: string;
}

export interface ContractJsonT {
  id: string;
  merchantId: string;
  retailerId: string;

  salesChannelId: string;
  billingData?: MarketBillingDataT;
  launchStatus?: string;
  preferredLanguage?: PreferredLanguageT;
  sendDummyMail?: boolean;
  fulfillmentType?: 'ZALANDO' | 'PARTNER';
  integrationType?: 'DIRECT' | 'PARTNER_PROVIDER';
  integrationObjectStage?: IntegrationObjectStageT;
  status?: ContractStatusT;
}

export interface Partner {
  $type: string;
  $id: string;
  appdomainId: number;
  businessPartnerId: string;
  locale: string;
  salesChannelId: string;
  isZfsPartner: boolean;
  contractId: number;
  partnerLaunchStatus: 'INACTIVE' | 'LAUNCH_PENDING' | 'LAUNCH_DONE';
}

export interface NewContract {
  merchantId: string;
  retailerId: string;
  salesChannelId: string;
  fulfillmentType: string;
}

export enum RejectionScopes {
  partners = 'PARTNER_FACING',
  internal = 'INTERNAL',
}
