/**
 * This class is used as a service to expose an array of breadcrumbs
 * that is updated every time a router transition succeed
 */
class BreadcrumbsStore {
  private _$injector: any;
  private _reduceFrom: any;
  private _$state: any;
  private _$stateParams: any;
  private _cb: any;
  private _breadcrumbs: any;

  constructor($injector) {
    this._$injector = $injector;
    this._reduceFrom = this._$injector.get('mcbReduceFrom');
    this._$state = this._$injector.get('$state');
    this._$stateParams = this._$injector.get('$stateParams');
    this._cb = {
      onBreadcrumbsUpdate: [],
    };
    this._breadcrumbs = [];
  }

  /**
   * @returns {Array}
   */
  getBreadcrumbs() {
    return this._breadcrumbs;
  }

  /**
   * Triggered when router transition succeeded,
   * updating breadcrumbs array accordingly
   *
   * @returns void
   */
  onTransitionSuccess() {
    let nextCb = (curr) => {
      if (
        curr.breadcrumbs &&
        curr.breadcrumbs.parent &&
        this._$state.get(curr.breadcrumbs.parent)
      ) {
        return this._$state.get(curr.breadcrumbs.parent);
      }
    };

    let accumulateCb = (acc, curr) => {
      if (curr.breadcrumbs) {
        acc.unshift({
          label: this._getBreadcrumbLabel(curr.breadcrumbs.label),
          href: this._getBreadcrumbHref(curr),
        });
      }
      return acc;
    };

    let breadcrumbs = this._reduceFrom(
      this._$state.current,
      nextCb,
      accumulateCb,
      []
    );

    this._updateBreadcrumbs(breadcrumbs);
  }

  /**
   * Register a callback fired when breadcrumbs were updated
   * @param {Function} cb
   * @returns {BreadcrumbsStore}
   */
  onBreadcrumbsUpdate(cb) {
    this._cb.onBreadcrumbsUpdate.push(cb);
    return this;
  }

  /**
   * Remove a registered callback
   * @param {Function} cb - the registered callback
   * @returns {BreadcrumbsStore}
   */
  offBreadcrumbsUpdate(cb) {
    let index = this._cb.onBreadcrumbsUpdate.indexOf(cb);
    if (index > -1) {
      this._cb.onBreadcrumbsUpdate.splice(index, 1);
    }
    return this;
  }

  /**
   * Get the label by invoking the label argument
   * if the argument it's an Injectable.
   *
   * @param {String|Function|Array} label
   * @returns {string}
   * @private
   */
  _getBreadcrumbLabel(label) {
    if (angular.isFunction(label) || angular.isArray(label)) {
      return this._$injector.invoke(label);
    }
    return label;
  }

  /**
   * Get breadcrumb href
   *
   * @param stateRef
   * @returns {string}
   * @private
   */
  _getBreadcrumbHref(stateRef) {
    return this._$state.href(stateRef.name, this._$stateParams || {});
  }

  /**
   * Updates breadcrumbs with new ones without loosing the reference
   * and notify/invoke onBreadcrumbsUpdate handlers
   *
   * @param breadcrumbs
   * @private
   */
  _updateBreadcrumbs(breadcrumbs) {
    this._breadcrumbs.length = 0;
    breadcrumbs.forEach((breadcrumb) => {
      this._breadcrumbs.push(breadcrumb);
    });

    this._cb.onBreadcrumbsUpdate.forEach((cb) => {
      cb(this._breadcrumbs);
    });
  }
}

BreadcrumbsStore.$inject = ['$injector'];

export default BreadcrumbsStore;
