import toastTemplate from './toast.html';

let mcbToastDirective = function mcbToastDirective(
  mcbToast,
  $templateCache,
  $log
) {
  return {
    replace: true,
    restrict: 'EA',
    template: toastTemplate, // TODO: make it configurable
    compile: function (tElem, tAttrs) {
      if (tAttrs.template) {
        let template = $templateCache.get(tAttrs.template);
        if (template) {
          tElem.replaceWith(template);
        } else {
          $log.warn(
            'mcbToast: Provided template could not be loaded. ' +
              'Please be sure that it is populated before the <mcb-toast> element is represented.'
          );
        }
      }

      return function (scope) {
        scope.hPos = mcbToast.settings.horizontalPosition;
        scope.vPos = mcbToast.settings.verticalPosition;
        scope.animation = mcbToast.settings.animation;
        scope.messages = mcbToast.messages;
      };
    },
  };
};

mcbToastDirective.$inject = ['mcbToast', '$templateCache', '$log'];

export default mcbToastDirective;
