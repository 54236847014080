<template lang="pug">
  span {{getBillingCycleNameByCode()}}
</template>

<script lang="ts">
import Vue from 'vue';
import {
  BILLING_CYCLE_MAP,
  BILLING_CYCLE_ONCE_A_MONTH,
} from 'Common/constants/billing.constant';

export default Vue.extend({
  methods: {
    getBillingCycleNameByCode() {
      return BILLING_CYCLE_MAP[BILLING_CYCLE_ONCE_A_MONTH];
    },
  },
});
</script>
