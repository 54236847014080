<template lang="pug">
v-card(min-height="136px" flat outlined)
  v-flex.px-6(fill-height)
    //- Account name
    div.pt-6.pb-4
      a.headline.primary--text.font-weight-medium.pl-0.pr-4(:href="account.href") {{ account.name }}
      span.caption.disabled--text
        | Last modified on {{ account.modifiedAt | formatDate }} by {{ account.modifiedBy }}

    //- Legal entities label
    div.caption.disabled--text
      | Legal Entities

    v-flex.flex-wrap.pt-4.pb-6(d-flex)
      div.mr-6(
        v-for="{ name, href } in account.legalEntities" ref="legalEntitiesContainer"
      )
        a.body-2.primary--text(:href="href") {{ name }}

</template>

<script lang="ts">
import Vue from 'vue';
import MerchantAccount from 'Api/models/MerchantAccount';

export default Vue.extend({
  name: 'MerchantAccountSummaryCard',
  props: {
    account: {
      type: Object as () => MerchantAccount,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
</style>
