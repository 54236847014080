import axios from 'axios';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import { pipe } from 'ramda';
import ErrorHandler from 'Api/api-errors-handler';
import { SizeChartMetadata } from 'Api/models/SizeChart';

const masterdataApi = axios.create({ baseURL: 'api/masterdata/merchants/' });
masterdataApi.interceptors.request.use(decamelizeRequestHandler);
masterdataApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

const certificateApi = axios.create({
  baseURL: 'api/masterdata/merchants/',
});
certificateApi.interceptors.response.use(({ data }) => data, ErrorHandler);

export const fetchSizeChartCertificateMetadata = (
  merchantId: string,
  certificateId: string
): Promise<SizeChartMetadata> => {
  return masterdataApi.get(
    `${merchantId}/size-charts/certificates/${certificateId}/metadata`
  );
};

export function fetchSizeChartCertificate(
  merchantId: string,
  certificateId: string,
  mediaType: string
): Promise<BlobPart> {
  return certificateApi.get(
    `${merchantId}/size-charts/certificates/${certificateId}`,
    {
      responseType: 'arraybuffer',
      headers: {
        'content-type': mediaType,
      },
    }
  );
}
