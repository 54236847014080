import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { attrs: { id: "confirm-data-change" } },
    [
      _c(
        VDialog,
        {
          attrs: { width: "550" },
          model: {
            value: _vm.showConfirmDataChangeModal,
            callback: function ($$v) {
              _vm.showConfirmDataChangeModal = $$v
            },
            expression: "showConfirmDataChangeModal",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "title" }, [
                _vm._v(
                  "Apply " +
                    _vm._s(_vm.choiceLabel) +
                    " Marketplace Service fee?"
                ),
              ]),
              _c(VCardText, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.choiceLabel) +
                      " Marketplace Service fee will be applied starting from\n"
                  ),
                  _c("strong", [_vm._v("(" + _vm._s(_vm.period) + ")")]),
                  _vm._v(
                    ".\nYou can change this selection at any time before the upcoming period begins, however any changes will only apply to the upcoming period.\u2028"
                  ),
                ]),
                _vm.choice === "OPT_IN"
                  ? _c("ul", [
                      _c("li", [
                        _vm._v(
                          "The reduced fees will apply only to overlapping articles also being offered by Zalando Wholesale."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Standard fees apply for the rest of the assortment."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "This update will apply to all merchants under the same legal entity."
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.choice === "OPT_OUT"
                  ? _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Standard fees will be applied to your entire assortment."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "This update will apply to all merchants under the same legal entity."
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("p", [
                  _vm._v(
                    "Do you want to apply this fee to the upcoming period?"
                  ),
                ]),
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    { attrs: { text: "" }, on: { click: _vm.cancelCb } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", loading: _vm.loading },
                      on: { click: _vm.confirmCb },
                    },
                    [_vm._v("Yes, apply fee")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }