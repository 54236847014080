import { mcb } from 'mcb';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { Store, Unsubscribe } from 'redux';
import { FlowState } from 'Merchant/common/state/flow.state';
import { MerchantActions } from 'Merchant/common/state/actions/merchant.actions';
import template from './company-details-approval.html';
import { MCP_MERCHANT_STATUSES } from 'Common/constants/merchant-statuses.constant';
import {
  FlowStateSelector,
  getMerchantFactoringMigrationStatus,
} from 'Merchant/common/state/selectors/flow-state.selector';
import { Contract, RejectionScopes } from 'Api/models/Contract';
import { MerchantFlowActions } from 'Merchant/common/state/actions/merchant-flow.actions';
import permissionsService from 'Common/services/permissions.service';
import { ApiService } from 'Api/services/api.service';
import { DataSections } from 'Api/models/DataSections';
import { canEnableVatCollection } from '@/feature-flags';

export class CompanyDetailsApprovalComponent implements ng.IComponentOptions {
  static Factory() {
    return new CompanyDetailsApprovalComponent();
  }

  bindings: { [binding: string]: string } = {
    merchant: '<',
    isBusinessDetails: '<',
  };

  controller = CompanyDetailsApprovalComponentController;
  controllerAs = 'ctrl';
  template: string = template;
}

interface DetailsControllerVm {
  merchant: MerchantProfile;
  languagesMap: { [key: string]: any };
}

export class CompanyDetailsApprovalComponentController
  implements DetailsControllerVm
{
  static $inject = [
    'mcpAdminFlowStateSelector',
    '$ngRedux',
    'mcpAdminMerchantActions',
    'mcpAdminMerchantFlowActions',
    'mcbToast',
    'mcpAdminApi',
    '$state',
  ];

  merchant: MerchantProfile;
  changedData: DataSections[];
  changedDataError: boolean;
  currentFactoringType: string;
  migrationStatus: string;
  validityDate: string;
  migrationVersion: string;
  isVatEnabled: boolean;
  zfsContracts: Contract[];
  unsubscribe: Unsubscribe;
  isBusinessDetails: boolean;
  languagesMap = {
    'en-GB': 'English',
    'de-DE': 'German',
  };

  private userCanEdit: boolean;

  constructor(
    private flowSelector: FlowStateSelector,
    private store: Store<FlowState>,
    private merchantActions: MerchantActions,
    private merchantFlowActions: MerchantFlowActions,
    private toast: mcb.IToast,
    private merchantApi: ApiService,
    private state: ng.ui.IStateService
  ) {
    this.approveGeneralData = this.approveGeneralData.bind(this);
    this.rejectGeneralData = this.rejectGeneralData.bind(this);
    this.changedData = [];
    this.changedDataError = false;
  }

  $onInit(): void {
    this.isVatEnabled = canEnableVatCollection(this.merchant);
    this.listenStore();
    if (!this.isCorrectUrl()) {
      this.state.go(
        'mcp.admin.merchant.general.company-details-approval',
        null,
        { location: 'replace' }
      );
    }
    this.userCanEdit = permissionsService.hasEditPermissions();
    this.setMerchantDataChanges(this.merchant.$id);
  }

  $onDestroy(): void {
    this.unsubscribe();
  }

  isCorrectUrl(): boolean {
    return this.isVatEnabled && this.isBusinessDetails;
  }

  canEdit(): boolean {
    return this.userCanEdit;
  }

  isApproved(): boolean {
    return this.merchant.status === MCP_MERCHANT_STATUSES.FINANCIAL_APPROVED;
  }

  isRejected(): boolean {
    return this.merchant.status === MCP_MERCHANT_STATUSES.FINANCIAL_REJECTED;
  }

  canBeApproved(): boolean {
    return this.merchant.status === MCP_MERCHANT_STATUSES.IN_FINANCIAL_REVIEW;
  }

  migrationCanBeApproved(): boolean {
    return this.migrationStatus === 'PENDING';
  }

  approveGeneralData(): void {
    this.store
      // @ts-ignore
      .dispatch(this.merchantActions.markMerchantAsFinancialApproved())
      .then(() => this.toast.show('Merchant data successfully approved'))
      .catch(() => this.toast.error('Unable to approve merchant data'));
  }

  rejectGeneralData(
    reason: string,
    scope: RejectionScopes,
    sections: string[]
  ): void {
    this.store
      .dispatch(
        // @ts-ignore
        this.merchantActions.markMerchantAsFinancialRejected(
          reason,
          scope,
          sections
        )
      )
      .then(() => this.toast.show('Merchant data rejected'))
      .catch(() => this.toast.error('Unable to reject merchant data'));
  }

  isContactDataChanged(): boolean {
    return this.changedData.includes(DataSections.Contact);
  }

  isAddressDataChanged(): boolean {
    return this.changedData.includes(DataSections.Address);
  }

  isNameDataChanged(): boolean {
    return this.changedData.includes(DataSections.Name);
  }

  isBankDataChanged(): boolean {
    return this.changedData.includes(DataSections.Bank);
  }

  isBillingDataDataChanged(): boolean {
    return this.changedData.includes(DataSections.Billing);
  }

  private listenStore(): void {
    this.unsubscribe = this.store.subscribe(() => {
      const state: FlowState = this.store.getState();
      const factoringMigrationStatus =
        getMerchantFactoringMigrationStatus(state);
      this.zfsContracts = this.flowSelector.getFlowZFSContracts(state);
      this.currentFactoringType = this.merchant.factoring;
      this.migrationStatus = factoringMigrationStatus.status;
      this.validityDate = factoringMigrationStatus.startDate;
      this.migrationVersion = factoringMigrationStatus.version;
    });
  }

  private setMerchantDataChanges(merchantId: string) {
    return this.merchantApi.merchant
      .fetchMerchantDataChanges(merchantId)
      .then((merchantDataChanges) => {
        this.changedData = merchantDataChanges.items;
        this.changedDataError = false;
      })
      .catch((err) => {
        this.changedDataError = true;
        return err;
      });
  }
}
