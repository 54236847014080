<template lang="pug">
  v-card(
    outlined
    tile
    rounded="sm"
  ).pa-6.rounded
    v-flex.d-flex.justify-space-between
      div.font-weight-bold.black--text {{carrierInfo.carrier}}
      v-chip(color="#d6deec", text-color="#283b8a", small)
        span.font-weight-bold {{carrierInfo.deliveryDestinationType}}
    v-divider.my-4
    div.mb-4
      span.text-subtitle-2.font-weight-bold.mr-2.black--text Opt-in services:
      span.text-body-2
        | {{carrierInfo.configuredOptionalDeliveryCapabilities.join(', ') || 'Not Specified'}}

    div
      span.text-subtitle-2.font-weight-bold.mr-2.black--text
        | {{carrierInfo.capabilities.length > 1 ? 'Parcel Services' : 'Parcel Service'}}:
      span.text-body-2 {{carrierInfo.capabilities.join(', ') || 'Not Specified'}}


    v-flex.d-flex.justify-end
      v-btn.mt-4.white--text.text-none.rounded.font-weight-bold(
        :loading="isLoading"
        color="#1a1a1a"
        @click="handleDelete(carrierInfo.id)"
        :disabled="disableDelete"
      ) Delete
</template>
<script lang="ts">
import Vue from 'vue';
import {
  DELIVERY_DESTINATIONS,
  OPTIONAL_CAPABILITIES,
  PARCEL_SERVICES,
} from 'Common/constants/carriers.constant';
import { ConfiguredCarrierResponseItemT } from 'Api/endpoints/sales-channel/carrier.endpoint';

export default Vue.extend({
  name: 'CarrierCard',
  props: {
    details: {
      type: Object as () => ConfiguredCarrierResponseItemT,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    disableDelete: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      carrierInfo: this.details,
      isLoading: false,
    };
  },
  mounted() {
    this.carrierInfo = {
      ...this.details,
      deliveryDestinationType:
        DELIVERY_DESTINATIONS[this.details.deliveryDestinationType],
      capabilities: this.details.capabilities.map((cp) => PARCEL_SERVICES[cp]),
      configuredOptionalDeliveryCapabilities:
        this.details.configuredOptionalDeliveryCapabilities.map(
          (cp) => OPTIONAL_CAPABILITIES[cp]
        ),
    };
  },
  methods: {
    handleDelete(id: string) {
      this.isLoading = true;
      this.onDelete(id).then(() => (this.loading = false));
    },
  },
});
</script>
<style lang="scss" scoped></style>
