import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("h1", { staticClass: "display-1 mx-6" }, [
      _vm._v(_vm._s(_vm.accountId ? "Edit" : "Create") + " Merchant Account"),
    ]),
    _c(
      "div",
      [
        _c("MerchantAccountNameContainer", {
          staticClass: "mx-6 mb-12",
          attrs: {
            "default-value": _vm.defaultName,
            disabled: _vm.isSaveInProgress,
          },
          on: {
            validate: function ($event) {
              _vm.isNameValid = $event
            },
            input: function ($event) {
              _vm.name = $event
            },
          },
        }),
        _c(
          "div",
          { staticClass: "mx-6 mb-12" },
          [
            _c("div", { staticClass: "headline mb-6" }, [
              _vm._v("Add Legal Entities"),
            ]),
            _c("LegalEntitiesContainer", {
              attrs: {
                "default-value": _vm.defaultLegalEntitiesIds,
                "account-id": _vm.accountId,
                disabled: _vm.isSaveInProgress,
                removable: _vm.canDeleteLegalEntities,
              },
              on: { add: _vm.cacheLegalEntity },
              model: {
                value: _vm.legalEntitiesIds,
                callback: function ($$v) {
                  _vm.legalEntitiesIds = $$v
                },
                expression: "legalEntitiesIds",
              },
            }),
            _vm.legalEntitiesIds.size
              ? _c("div", [
                  _vm._v("Added Legal Entities"),
                  _vm.name
                    ? _c("span", [
                        _vm._v(" under "),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(_vm._s(_vm.name)),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              VRow,
              _vm._l(_vm.legalEntities, function (legalEntity, index) {
                return _c(
                  VCol,
                  {
                    key: (legalEntity && legalEntity.id) || index,
                    staticClass: "py-0",
                    attrs: { cols: "6" },
                  },
                  [
                    _c("LegalEntity", {
                      attrs: {
                        removable:
                          _vm.canDeleteLegalEntities ||
                          (legalEntity &&
                            !_vm.defaultLegalEntitiesIds.has(legalEntity.id)),
                        "show-divider": index + 2 < _vm.legalEntities.length,
                        legalEntity: legalEntity,
                      },
                      on: { remove: _vm.removeLegalEntity },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mx-6" },
          [
            _c("div", { staticClass: "headline mb-6" }, [
              _vm._v("Primary Contact Details"),
            ]),
            _c("Contact", {
              attrs: {
                "default-value": _vm.defaultContact,
                contacts: _vm.contacts,
                disabled: _vm.isSaveInProgress || !_vm.canEditContact,
              },
              on: {
                input: function ($event) {
                  _vm.contact = $event
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "float-right mx-6 mt-12 mb-5" },
      [
        _c(
          VBtn,
          {
            attrs: { text: "" },
            on: {
              click: function ($event) {
                return _vm.$emit("canceled")
              },
            },
          },
          [_vm._v("Cancel")]
        ),
        _c(
          VBtn,
          {
            staticClass: "ml-4",
            attrs: {
              color: "primary",
              depressed: "",
              disabled: _vm.isSaveDisabled,
              loading: _vm.isSaveInProgress,
            },
            on: { click: _vm.save },
          },
          [
            _vm._v(
              _vm._s(_vm.accountId ? "Save" : "Create") + " Merchant Account"
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _vm.notify === "NOTIFY_LOAD_LEGAL_ENTITIES_DETAILED_FAILED"
          ? _c(
              VSnackbar,
              {
                attrs: { color: "reject", bottom: "", timeout: -1 },
                model: {
                  value: _vm.notify,
                  callback: function ($$v) {
                    _vm.notify = $$v
                  },
                  expression: "notify",
                },
              },
              [
                _vm._v(
                  "Error while loading legal entities detailed information"
                ),
                _c(
                  VBtn,
                  {
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        _vm.notify = false
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.notify === "NOTIFY_SAVE_FAILED"
          ? _c(
              VSnackbar,
              {
                attrs: { color: "reject", bottom: "", timeout: -1 },
                model: {
                  value: _vm.notify,
                  callback: function ($$v) {
                    _vm.notify = $$v
                  },
                  expression: "notify",
                },
              },
              [
                _vm.accountId
                  ? [_vm._v("Error while updating the account")]
                  : [_vm._v("Error while creating the account")],
                _c(
                  VBtn,
                  {
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        _vm.notify = false
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
              ],
              2
            )
          : _vm._e(),
        _vm.notify === "NOTIFY_SAVE_SUCCESS"
          ? _c(
              VSnackbar,
              {
                attrs: { color: "success", bottom: "", timeout: 2000 },
                model: {
                  value: _vm.notify,
                  callback: function ($$v) {
                    _vm.notify = $$v
                  },
                  expression: "notify",
                },
              },
              [
                _vm.accountId
                  ? [
                      _vm._v(
                        "The account " +
                          _vm._s(_vm.name) +
                          " has been updated successfully"
                      ),
                    ]
                  : [
                      _vm._v(
                        "The account " +
                          _vm._s(_vm.name) +
                          " has been created successfully"
                      ),
                    ],
              ],
              2
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }