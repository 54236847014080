// ng1
import * as angularSanitize from 'angular-sanitize';
import * as angularMessages from 'angular-messages';
import * as angularAria from 'angular-aria';
import * as angularTouch from 'angular-touch';
import * as angularAnimate from 'angular-animate';
import * as angularResource from 'angular-resource';
import * as angularCookies from 'angular-cookies';

import coreModule from './core/core';
import componentsModule from './components/components';

// expose angular as global
(function (root) {
  root['angular'] = root['angular'] || angular;
})(window || {});

const getPluginNgModule = ({ definition }) => {
  if (definition.moduleName) {
    /* BC*/
    return definition.moduleName;
  }

  if (definition.ng) {
    if (definition.ng.moduleName) {
      /* BC */
      return definition.ng.moduleName;
    }

    if (definition.ng.module) {
      return definition.ng.module;
    }
  }
};

const createMcpModule = (plugins = []) => {
  const ngRequires = plugins
    .filter((plugin) => plugin.definition.lazyLoad !== true)
    .filter(getPluginNgModule)
    .map(getPluginNgModule)
    .filter((moduleName) => {
      angular.module(moduleName);
      return true;
    });

  const module = angular.module('mcp', ngRequires);

  return module;
};

const bootstrap = (element, modules = [], config = {}) => {
  const MC_INITIAL_STATE = window['__MC_INITIAL_STATE__'] || {};
  const plugins = MC_INITIAL_STATE['plugins'] || [];

  return angular.bootstrap(
    element,
    modules.concat(createMcpModule(plugins).name),
    config
  );
};

/**
 * @ngdoc module
 * @name mcb
 */
const mcb = angular.module('mcb', [
  angularSanitize,
  angularMessages,
  angularAria,
  angularTouch,
  angularAnimate,
  angularCookies,
  angularResource,
  coreModule.name,
  componentsModule.name,
]);

// expose our exports as global
(function (root) {
  root['mc'] = root['mc'] || {};
  root['mc']['ng'] = root['mc']['ng'] || {};
  root['mc']['ng']['bundle'] = {
    angular,
    mcb,
    bootstrap,
    createMcpModule,
  };
})(window || {});
