import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VToolbar,
    { staticClass: "root grey lighten-5", attrs: { flat: "" } },
    [
      _c(VToolbarTitle, { staticClass: "headline" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c(VSpacer),
      _vm.hasNewMerchantBtn
        ? _c(
            VBtn,
            {
              ref: "new-merchant",
              attrs: { depressed: "", color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("add-merchant")
                },
              },
            },
            [
              _c(VIcon, { attrs: { small: "" } }, [_vm._v("add")]),
              _c("span", [_vm._v("New merchant")]),
            ],
            1
          )
        : _vm._e(),
      _vm.hasNewMerchantAccountBtn
        ? _c(
            VBtn,
            {
              ref: "new-merchant-account",
              staticClass: "ml-4",
              attrs: { depressed: "", color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("add-merchant-account")
                },
              },
            },
            [
              _c(VIcon, { attrs: { small: "" } }, [_vm._v("add")]),
              _c("span", [_vm._v("New Merchant Account")]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }