var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.showValidationMessage
      ? _c("p", { staticClass: "green--text text-caption" }, [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v("Address matched:"),
          ]),
          _c("span", [
            _vm._v(
              " Submitted VAT ID proof is allowed to be older than 6 months"
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "label text-caption text-uppercase" }, [
        _vm._v(_vm._s(_vm.addressLabel)),
      ]),
      _c("span", [
        _vm._v(
          _vm._s(_vm.address.addressLine1) +
            " " +
            _vm._s(_vm.address.addressLine2)
        ),
      ]),
      _vm.isInvalid
        ? _c("span", { staticClass: "body-2 ml-4 red--text text--lighten-2" }, [
            _vm._v("(Address does not match with registry response)"),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "label text-caption text-uppercase" }, [
        _vm._v("Postcode"),
      ]),
      _c("div", [_vm._v(_vm._s(_vm.address.zipCode))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "label text-caption text-uppercase" }, [
        _vm._v("City"),
      ]),
      _c("div", [_vm._v(_vm._s(_vm.address.city))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "label text-caption text-uppercase" }, [
        _vm._v("Country"),
      ]),
      _c("div", [_vm._v(_vm._s(_vm.countryName))]),
    ]),
    this.address.certificateId
      ? _c(
          "div",
          { staticClass: "field" },
          [
            _c("div", { staticClass: "label text-caption text-uppercase" }, [
              _vm._v("Address Proof"),
            ]),
            _c("VatCertificate", {
              attrs: { "certificate-id": this.address.certificateId },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }