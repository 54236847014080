import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VLayout,
    { attrs: { column: "" } },
    [
      _c(
        VFlex,
        { staticClass: "mb-6", attrs: { xs12: "", "d-flex": "" } },
        [
          _c("AppMultiselect", {
            attrs: {
              name: "Season",
              "help-text":
                "Select seasons in which the partner is allowed to sell",
              "value-prop": "label",
              "saved-value": _vm.savedValues.seasons,
              attributes: _vm.attributes.seasons,
              readonly: _vm.isSubmitting || _vm.isReadonly || _vm.isExporting,
            },
            model: {
              value: _vm.values.seasons,
              callback: function ($$v) {
                _vm.$set(_vm.values, "seasons", $$v)
              },
              expression: "values.seasons",
            },
          }),
        ],
        1
      ),
      _c(
        VFlex,
        { staticClass: "mb-6", attrs: { xs12: "", "d-flex": "" } },
        [
          _c("AppMultiselect", {
            attrs: {
              name: "Brand",
              "help-text": "Select brands which the partner is allowed to sell",
              "value-prop": "code",
              "saved-value": _vm.savedValues.brands,
              "name-prop": _vm.brandText,
              attributes: _vm.attributes.brands,
              readonly: _vm.isSubmitting || _vm.isReadonly || _vm.isExporting,
              "on-brand-change": _vm.onBrandChange,
            },
            model: {
              value: _vm.values.brands,
              callback: function ($$v) {
                _vm.$set(_vm.values, "brands", $$v)
              },
              expression: "values.brands",
            },
          }),
        ],
        1
      ),
      _vm.isACUChangesEnabled
        ? _c(
            VFlex,
            { staticClass: "mb-6", attrs: { xs12: "", "d-flex": "" } },
            [
              _c("AppMultiselect", {
                attrs: {
                  name: "Brand ownership",
                  "help-text":
                    "Select brands which the partner is allowed to update",
                  "value-prop": "code",
                  "saved-value": _vm.savedValues.ownerBrands,
                  "name-prop": _vm.ownerBrandText,
                  attributes: _vm.modifiedOwnerBrandAttributes,
                  readonly:
                    _vm.isSubmitting ||
                    _vm.isReadonly ||
                    _vm.isExporting ||
                    _vm.values.brands.length === 0,
                },
                model: {
                  value: _vm.values.ownerBrands,
                  callback: function ($$v) {
                    _vm.$set(_vm.values, "ownerBrands", $$v)
                  },
                  expression: "values.ownerBrands",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        VFlex,
        { staticClass: "mb-6", attrs: { xs12: "", "d-flex": "" } },
        [
          _c("SilhouetteField", {
            attrs: {
              "saved-value": _vm.savedValues.silhouettes,
              attributes: _vm.attributes.silhouettes,
              readonly: _vm.isSubmitting || _vm.isReadonly || _vm.isExporting,
            },
            model: {
              value: _vm.values.silhouettes,
              callback: function ($$v) {
                _vm.$set(_vm.values, "silhouettes", $$v)
              },
              expression: "values.silhouettes",
            },
          }),
        ],
        1
      ),
      _c(
        VFlex,
        { staticClass: "mb-6", attrs: { xs12: "", "d-flex": "" } },
        [
          _c("AppMultiselectAsync", {
            attrs: {
              "help-text":
                "Select the size charts which the partner is allowed to sell",
              name: "Size Chart",
              "value-prop": "id",
              "name-prop": "legacyCode",
              "saved-value": _vm.savedValues.sizeCharts,
              "value-to-attributes-map": _vm.sizeAttrsCacheMap,
              attributes: _vm.sizeAttrs,
              valueAttrs: _vm.valueAttrs,
              loading: _vm.sizeAttrsLoading,
              readonly: _vm.isSubmitting || _vm.isReadonly || _vm.isExporting,
            },
            on: {
              "update:search-input": function ($event) {
                return _vm.$emit("update:sizeSearchInput", $event)
              },
            },
            model: {
              value: _vm.values.sizeCharts,
              callback: function ($$v) {
                _vm.$set(_vm.values, "sizeCharts", $$v)
              },
              expression: "values.sizeCharts",
            },
          }),
        ],
        1
      ),
      !_vm.isReadonly
        ? _c(
            VFlex,
            { attrs: { "xs-12": "" } },
            [
              _c(
                VLayout,
                { attrs: { "justify-end": "" } },
                [
                  _c(
                    VBtn,
                    {
                      ref: "cancel",
                      staticClass: "my-0 mx-2",
                      attrs: {
                        color: "primary",
                        disabled:
                          !_vm.hasChanges ||
                          _vm.isSubmitting ||
                          _vm.isExporting,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("clear")
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    VBtn,
                    {
                      ref: "save",
                      staticClass: "my-0 mx-2",
                      attrs: {
                        color: "primary",
                        loading: _vm.isSubmitting,
                        disabled:
                          !_vm.hasChanges ||
                          _vm.isSubmitting ||
                          _vm.isExporting,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("save")
                        },
                      },
                    },
                    [_vm._v("Save")]
                  ),
                  _c(
                    VBtn,
                    {
                      ref: "export",
                      staticClass: "my-0 mr-0 mx-2",
                      attrs: {
                        depressed: "",
                        color: "primary",
                        loading: _vm.isExporting,
                        disabled:
                          !_vm.canExport ||
                          _vm.hasChanges ||
                          !_vm.isComplete ||
                          _vm.isSubmitting ||
                          _vm.isExporting,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("export")
                        },
                      },
                    },
                    [_vm._v("Send to export")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }