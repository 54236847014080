import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VCard,
    {
      staticClass: "pa-6 rounded",
      attrs: { outlined: "", tile: "", rounded: "sm" },
    },
    [
      _c(
        VFlex,
        { staticClass: "d-flex justify-space-between" },
        [
          _c("div", { staticClass: "font-weight-bold black--text" }, [
            _vm._v(_vm._s(_vm.carrierInfo.carrier)),
          ]),
          _c(
            VChip,
            { attrs: { color: "#d6deec", "text-color": "#283b8a", small: "" } },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.carrierInfo.deliveryDestinationType)),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(VDivider, { staticClass: "my-4" }),
      _c("div", { staticClass: "mb-4" }, [
        _c(
          "span",
          { staticClass: "text-subtitle-2 font-weight-bold mr-2 black--text" },
          [_vm._v("Opt-in services:")]
        ),
        _c("span", { staticClass: "text-body-2" }, [
          _vm._v(
            _vm._s(
              _vm.carrierInfo.configuredOptionalDeliveryCapabilities.join(
                ", "
              ) || "Not Specified"
            )
          ),
        ]),
      ]),
      _c("div", [
        _c(
          "span",
          { staticClass: "text-subtitle-2 font-weight-bold mr-2 black--text" },
          [
            _vm._v(
              _vm._s(
                _vm.carrierInfo.capabilities.length > 1
                  ? "Parcel Services"
                  : "Parcel Service"
              ) + ":"
            ),
          ]
        ),
        _c("span", { staticClass: "text-body-2" }, [
          _vm._v(
            _vm._s(_vm.carrierInfo.capabilities.join(", ") || "Not Specified")
          ),
        ]),
      ]),
      _c(
        VFlex,
        { staticClass: "d-flex justify-end" },
        [
          _c(
            VBtn,
            {
              staticClass:
                "mt-4 white--text text-none rounded font-weight-bold",
              attrs: {
                loading: _vm.isLoading,
                color: "#1a1a1a",
                disabled: _vm.disableDelete,
              },
              on: {
                click: function ($event) {
                  return _vm.handleDelete(_vm.carrierInfo.id)
                },
              },
            },
            [_vm._v("Delete")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }