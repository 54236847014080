import { FlowState } from '../flow.state';
import { Contract } from 'Api/models/Contract';
import { MerchantGroup } from 'Api/models/MerchantGroup';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { MerchantRequirements } from 'Api/models/MerchantRequiremenets';
import { ContractRequirements } from 'Api/models/ContractRequirements';
import { MCP_FF_TYPES } from 'Common/constants/misc.constant';
import { FactoringMigrationStatus } from '@/api/models/FactoringMigrationStatus';

export function getMerchant(state: FlowState): MerchantProfile {
  return state.flow.general.merchant.data;
}

export function getMerchantGroups(state: FlowState): MerchantGroup[] {
  return state.flow.general.merchantGroups.data;
}

export function getMerchantGroupName(state: FlowState): string {
  const groupId = state.flow.general.merchant.data.merchantGroupId;
  const merchantGroups = state.flow.general.merchantGroups.data;
  const merchantGroup = merchantGroups.find((group) => group.$id === groupId);

  return merchantGroup ? merchantGroup.name : '';
}

export function getMerchantSupplierCode(state: FlowState): string {
  return state.flow.general.merchant.data.supplierCode;
}

export function getFlowStatus(state: FlowState): MerchantRequirements {
  return state.flow.general.requirements;
}

/**
 * Get Merchant contracts
 *
 * @param state
 */
export function getContracts(state: FlowState): Contract[] {
  return state.flow.general.contracts.data;
}

/**
 * Get Merchant contracts with Partner Fullfillment
 *
 * @param state
 */
export function getFlowPFContracts(state: FlowState): Contract[] {
  return getContracts(state).filter(
    (contract) => contract.fulfillmentType === MCP_FF_TYPES.PF
  );
}

/**
 * Get Merchant contracts with Zalando Fullfillment
 *
 * @param state
 */
export function getFlowZFSContracts(state: FlowState): Contract[] {
  return getContracts(state).filter(
    (contract) => contract.fulfillmentType === MCP_FF_TYPES.ZFS
  );
}

/**
 * Get contracts belonging to the provided channel
 *
 * @param state
 * @param channelId
 */
export function getChannelContracts(
  state: FlowState,
  channelId: string
): Contract[] {
  return getContracts(state).filter(
    ({ salesChannelId }) => salesChannelId === channelId
  );
}

export function getMerchantFactoringMigrationStatus(
  state: FlowState
): FactoringMigrationStatus {
  return state.flow.specific.factoringMigrationStatus.data;
}

export class FlowStateSelector {
  getFlowMerchant = getMerchant;

  getFlowContract(state: FlowState): Contract {
    return state.flow.specific.contract.data;
  }

  getFlowContractStatus(state: FlowState): ContractRequirements {
    return state.flow.specific.requirements.data;
  }

  getFlowContracts(state: FlowState): Contract[] {
    return getContracts(state);
  }

  getFlowChannelContracts(state: FlowState, channelId: string): Contract[] {
    return getChannelContracts(state, channelId);
  }

  getFlowPFContracts(state: FlowState): Contract[] {
    return getFlowPFContracts(state);
  }

  getFlowZFSContracts(state: FlowState): Contract[] {
    return getFlowZFSContracts(state);
  }

  getFlowStatus(state: FlowState): MerchantRequirements {
    return getFlowStatus(state);
  }

  getFlowMerchantLoadingStatus(state: FlowState): boolean {
    return state.flow.general.merchant.merchantLoading;
  }

  getFlowMerchantNameLoadingStatus(state: FlowState): boolean {
    return state.flow.general.merchant.nameLoading;
  }

  getFlowMerchantContactLoadingStatus(state: FlowState): boolean {
    return state.flow.general.merchant.contactLoading;
  }

  getFlowContractsLoadingStatus(state: FlowState): boolean {
    return state.flow.general.contracts.loading;
  }

  getFlowMerchantAddressLoadingStatus(state: FlowState): boolean {
    return state.flow.general.merchant.addressLoading;
  }

  getFlowMerchantBankAccountLoadingStatus(state: FlowState): boolean {
    return state.flow.general.merchant.bankAccountLoading;
  }

  getFlowMerchantDataLoadingStatus(state: FlowState): boolean {
    return state.flow.general.merchant.dataLoading;
  }

  getFlowContractDataLoadingStatus(state: FlowState): boolean {
    return state.flow.specific.contract.dataLoading;
  }

  getFlowContractLoadingStatus(state: FlowState): boolean {
    return state.flow.specific.contract.contractLoading;
  }

  getFlowContractRequirementsLoadingStatus(state: FlowState): boolean {
    return state.flow.specific.requirements.loading;
  }
}
