import { combineReducers } from 'redux';
import { contractReducer } from './contract.reducer';
import { contractsReducer } from './contracts.reducer';
import { merchantReducer } from './merchant.reducer';
import { merchantGroupsReducer } from './merchant-groups.reducer';
import { statusReducer } from './status.reducer';
import { contractStatusReducer } from './contract-status.reducer';
import { factoringMigrationStatusReducer } from './factoring-migration-status.reducer';

export const HYDRATE_STATE = 'HYDRATE_STATE';

function makeHydratable(reducer, hydrateActionType) {
  return function (state, action) {
    switch (action.type) {
      case hydrateActionType:
        return reducer(action.payload, action);
      default:
        return reducer(state, action);
    }
  };
}

const reducer = combineReducers({
  flow: combineReducers({
    general: combineReducers({
      requirements: statusReducer,
      merchant: merchantReducer,
      contracts: contractsReducer,
      merchantGroups: merchantGroupsReducer,
    }),
    specific: combineReducers({
      contract: contractReducer,
      requirements: contractStatusReducer,
      factoringMigrationStatus: factoringMigrationStatusReducer,
    }),
  }),
});
export const rootReducer = makeHydratable(reducer, HYDRATE_STATE);
