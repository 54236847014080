import { FactoringMigrationStatus } from '@/api/models/FactoringMigrationStatus';
import { Endpoint } from '../../services/endpoint';
import { RequestConfig } from '../../services/http.service';
export const RESOURCE_TYPE = 'MerchantStatus';

export class MerchantFactoringMigrationStatusEndpoint extends Endpoint {
  fetchFactoringMigrationStatus(
    merchantId: string
  ): ng.IPromise<{ migration: FactoringMigrationStatus }> {
    const url =
      this.endpointsService.getFactoringMigrationStatusUrl(merchantId);
    const config: RequestConfig = { method: 'GET', url: url };
    return this.http.requestJson(config);
  }
}
