<template lang="pug">
  v-expansion-panel.root.secondaryLight
    v-expansion-panel-header
      div {{ label }}

    v-expansion-panel-content
      v-layout.white(light v-if="!data.length")
        p.px-6.pt-6 No values were specified

      template(v-else)
        template(v-for="(item, index) in data")
          v-hover.px-6.white(:key="item.value" v-slot:default="{ hover }")
            div(:class="{'grey lighten-4': hover, 'clickable': clickable}")
              div.list-item.py-2(
                @click="$emit('click', item.value)"
                )
                div(style="overflow: hidden")
                  div.text-uppercase.text-truncate
                    | {{ item.label }}
                  div {{ item.description }}
                v-btn.mr-0(
                    v-if="!readonly"
                    small
                    icon
                    @click.stop="$emit('remove', item.value)"
                  )
                  v-icon(light small v-show="hover" color="reject") cancel
              v-divider(
                :key="`divider-${item.value}`"
                :class="hover ? 'grey lighten-2': ''"
                v-if="index !== (data.length -1)"
              )
</template>

<script lang="ts">
import Vue from 'vue';
import { MasterdataSummaryItemValue } from 'Common/components/masterdata/masterdata-helper';

export default Vue.extend({
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    data: {
      type: Array as () => Array<MasterdataSummaryItemValue>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.root {
  border-color: white !important; // We need !important to overwrite !important of .grey.lighten-3
}

.close-icon {
  font-size: 16px;
  font-weight: 900;
}

.list-item {
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clickable {
  cursor: pointer;
}
</style>
