<template lang="pug">
//- No data case
v-layout(v-if="!logs.length" justify-space-around column align-center)
  v-icon.no-data-icon(size="56" style="margin-top: 80px")  history
  p.subtitle-1.pt-6.copy.text-center No export history found for Master Data Configuration

//- The data exist case
v-list.px-6(v-else three-line)
  template(v-for="(item, index) in logs")
    v-list-item(:key="item.createdAt")
      v-list-item-content
        v-list-item-title {{ item.createdAt | formatDateTime }}
        v-list-item-subtitle Master Data sent to export
        v-list-item-subtitle {{ item.createdBy }}
    
    v-divider(
      v-if="index < (logs.length-1)"
      :key="index"
    )
</template>

<script lang="ts">
import Vue from 'vue';
import dayjs from 'dayjs';

export default Vue.extend({
  props: {
    logs: {
      type: Array as () => Array<any>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.copy {
  width: 200px;
}
</style>
