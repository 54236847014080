import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("Summary", {
        directives: [
          {
            name: "stream",
            rawName: "v-stream:showPreview",
            value: _vm.showPreview$,
            expression: "showPreview$",
            arg: "showPreview",
          },
        ],
        attrs: {
          readonly: _vm.readonly,
          "attributes-maps": _vm.attributesMaps,
          values: _vm.values,
        },
      }),
      _c(
        VDialog,
        {
          directives: [
            {
              name: "stream",
              rawName: "v-stream:input",
              value: _vm.closeDialog$,
              expression: "closeDialog$",
              arg: "input",
            },
          ],
          attrs: { width: "858", value: _vm.displaySizeChartDialog },
        },
        [
          _vm.displaySizeChartDialog
            ? _c("SizeChartPreview", {
                directives: [
                  {
                    name: "stream",
                    rawName: "v-stream:close",
                    value: _vm.closeDialog$,
                    expression: "closeDialog$",
                    arg: "close",
                  },
                ],
                attrs: {
                  loading: _vm.sizeChartLoading,
                  sizeChart: _vm.sizeChart,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.notify === "NOTIFY_FETCH_SIZE_GRID_FAILED"
        ? _c(
            VSnackbar,
            {
              attrs: { color: "error", bottom: "", timeout: 3000 },
              model: {
                value: _vm.notify,
                callback: function ($$v) {
                  _vm.notify = $$v
                },
                expression: "notify",
              },
            },
            [
              _vm._v(" Unable to fetch size grid data"),
              _c(
                VBtn,
                {
                  attrs: { dark: "", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.notify = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }