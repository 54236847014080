import { Address } from '../../models/Address';
import { MerchantProfile } from '../../models/MerchantProfile';
import { Endpoint } from '../../services/endpoint';
import { RequestConfig } from '../../services/http.service';

export class MerchantAddressEndpoint extends Endpoint {
  protected resourceType = 'Address';

  saveMerchantAddress(
    merchant: MerchantProfile,
    address: Address
  ): ng.IPromise<void> {
    let url = this.endpointsService.getAddressUrlTemplate(merchant.$id);
    let config: RequestConfig = {
      method: 'POST',
      url: url,
      data: address,
      resourceType: this.resourceType,
    };
    return this.http.request(config);
  }

  updateMerchantAddress(
    merchant: MerchantProfile,
    address: Address
  ): ng.IPromise<void> {
    let url = this.endpointsService.getAddressUrlTemplate(merchant.$id);
    let config: RequestConfig = {
      method: 'PATCH',
      url: url,
      data: address,
      resourceType: this.resourceType,
    };
    return this.http.request(config);
  }

  putMerchantAddress(
    merchant: MerchantProfile,
    address: Address
  ): ng.IPromise<void> {
    if (address.$id) {
      return this.updateMerchantAddress(merchant, address);
    } else {
      return this.saveMerchantAddress(merchant, address);
    }
  }

  fetchMerchantAddress(merchant: MerchantProfile): ng.IPromise<Address> {
    let url = this.endpointsService.getAddressUrlTemplate(merchant.$id);
    let config: RequestConfig = { method: 'GET', url: url };
    return this.http.request(config);
  }
}
