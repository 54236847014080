<template lang="pug">
div.mcp-admin-merchant-details-section-form
  p.dc-p
    strong Important:
    |  This data will be used only for {{ channelCountry }}.

  form(novalidate)
    //- Bank Name
    div.mcp-admin__form-field
      label.dc-label.dc-label--required(
        :class="{'dc-label--is-error': isSubmitted && !isBankNameFieldCorrect}"
        for="bank-name"
      ) Bank name

      div.errors(v-if="isSubmitted && !isBankNameFieldCorrect")
        span.dc--text-error Please don't leave this empty.

      input.dc-input.dc-input--block(
        type="text"
        id="bank-name"
        :class="{'dc-input--is-error': isSubmitted && !isBankNameFieldCorrect}"
        v-model.trim="data.bankName"
        required
      )

    //- IBAN
    div.mcp-admin__form-field
      label.dc-label.dc-label--required(
        :class="{'dc-label--is-error': isSubmitted && !isIbanFieldNonEmpty}"
        for="iban"
      ) IBAN

      div.errors(v-if="isSubmitted && !isIbanFieldCorrect")
        span.dc--text-error(
          v-if="!isIbanFieldNonEmpty"
        ) Please don't leave this empty.
        span.dc--text-error(
          v-else-if="!isIbanMinLengthCorrect"
        ) IBAN should be at least 15 characters long.
        span.dc--text-error(
          v-else-if="!isIbanMaxLengthCorrect"
        ) IBAN should be maximum 28 characters long.
        span.dc--text-error(
          v-else-if="!isIbanFormatCorrect"
        ) IBAN should begin by country code.


      input.dc-input.dc-input--block(
        type="text"
        id="iban"
        :class="{'dc-input--is-error': isSubmitted && !isIbanFieldCorrect}"
        v-model="formattedIban"
        required
      )

    //- BIC
    div.mcp-admin__form-field
      label.dc-label.dc-label--required(
        :class="{'dc-label--is-error': isSubmitted && !isBicFieldCorrect}"
        for="bic"
      ) SWIFT/BIC

      div.errors(v-if="isSubmitted && !isBicFieldCorrect")
        span.dc--text-error Please don't leave this empty.

      input.dc-input.dc-input--block(
        type="text"
        id="bic"
        :class="{'dc-input--is-error': isSubmitted && !isBicFieldCorrect}"
        v-model.trim="data.bic"
        required
      )


    //- Account holder
    div.mcp-admin__form-field
      label.dc-label.dc-label--required.mcp-admin-label--inline-block(
        :class="{'dc-label--is-error': isSubmitted && !isAccountHolderFieldCorrect}"
        for="account-holder"
      ) Account holder

      span.mcp-admin__field-info.dc--has-tooltip.dc--has-tooltip--top-right(
        data-dc-has-tooltip="If unsure, use the company legal name"
      )
        i.dc-icon.dc-icon--info

      div.errors(v-if="isSubmitted && !isAccountHolderFieldCorrect")
        span.dc--text-error Please don't leave this empty.

      div.dc-input-group.mcp-admin__group-input
        input.dc-input.dc-input--block.dc-input--in-input-group(
          type="text"
          id="account-holder"
          :class="{'dc-input--is-error': isSubmitted && !isAccountHolderFieldCorrect}"
          v-model.trim="data.accountHolder"
          required
        )

        button.mcp-admin-btn--use-legal-name.dc-btn.dc-btn--in-input-group(
          :class="{'dc-btn--disabled': data.accountHolder === companyName}"
          :disabled="data.accountHolder === companyName"
          @click="useLegalNameAsAccountHolder"
        ) Use legal name

    //- Bank country
    div.mcp-admin__form-field
      label.dc-label.dc-label--required(
        :class="{'dc-label--is-error': isSubmitted && !isCountryFieldCorrect}"
        for="bank-country"
      ) Bank country

      div.errors(v-if="isSubmitted && !isCountryFieldCorrect")
        span.dc--text-error Please don't leave this empty.

      select.dc-select.mcp-admin-merchant-details-select(
        id="bank-country"
        :class="{'dc-input--is-error': isSubmitted && !isCountryFieldCorrect}"
        v-model.trim="data.countryCode"
        required
      )
        option(v-for="country in countries" :value="country.code")
          | {{ country.name }}

  div.buttons
    div
      button.dc-btn.dc-btn--link(
        v-if="showUseMerchantBankDataLink"
        :class="{'dc-btn--disabled': isSubmitting}"
        :disabled="isSubmitting"
        @click="$emit('useMerchantData')"
      )
        span Use Bank Account From General Company Data
        i.dc-icon.dc-icon--undo.dc-icon--btn.dc-icon--btn--left

    div
      button.dc-btn.dc-btn--link(
        @click="$emit('cancel')"
      ) Cancel

      button.dc-btn.dc-btn--primary(
        :class="{'dc-btn--disabled': isSubmitting}"
        :disabled="isSubmitting"
        @click="save"
     ) Save
</template>

<script lang="ts">
import Vue from 'vue';
import { BankDataT, DEFAULT_DATA } from './bank-data.container.vue';
import { COUNTRIES } from 'Common/constants/misc.constant';
import {
  stringToIbanFormat,
  isMinLengthCorrect,
  isMaxLengthCorrect,
  isFormatCorrect,
} from 'Common/services/iban.helper';

function isFieldEmpty(value: string) {
  return value.trim() === '';
}

export default Vue.extend({
  name: 'BankDataFormContainer',
  props: {
    showUseMerchantBankDataLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    channelCountry: {
      type: String,
      required: false, // required for non-readonly version only
      default: '',
    },
    companyName: {
      type: String,
      required: false, // required for non-readonly version only
      default: '',
    },
    isSubmitting: Boolean,
    initialData: {
      type: Object as () => BankDataT,
      required: true,
    },
  },
  data() {
    return {
      isSubmitted: false,
      countries: [...COUNTRIES],
      data: { ...DEFAULT_DATA } as BankDataT,
    };
  },

  computed: {
    formattedIban: {
      get(): string {
        return stringToIbanFormat(this.data.iban);
      },
      set(value: string): void {
        this.data.iban = value.replace(/\s/g, '').toUpperCase();
      },
    },
    isIbanFieldNonEmpty(): boolean {
      return this.data.iban.trim() !== '';
    },
    isIbanFormatCorrect(): boolean {
      return isFormatCorrect(this.data.iban);
    },
    isIbanMinLengthCorrect(): boolean {
      return isMinLengthCorrect(this.data.iban);
    },
    isIbanMaxLengthCorrect(): boolean {
      return isMaxLengthCorrect(this.data.iban);
    },
    isIbanFieldCorrect(): boolean {
      return (
        this.isIbanFieldNonEmpty &&
        this.isIbanFormatCorrect &&
        this.isIbanMinLengthCorrect &&
        this.isIbanMaxLengthCorrect
      );
    },
    isBankNameFieldCorrect(): boolean {
      return !isFieldEmpty(this.data.bankName);
    },
    isBicFieldCorrect(): boolean {
      return !isFieldEmpty(this.data.bic);
    },
    isAccountHolderFieldCorrect(): boolean {
      return !isFieldEmpty(this.data.accountHolder);
    },
    isCountryFieldCorrect(): boolean {
      return !isFieldEmpty(this.data.countryCode);
    },
  },

  mounted() {
    this.data = { ...this.initialData };
  },
  methods: {
    useLegalNameAsAccountHolder() {
      this.data.accountHolder = this.companyName;
    },
    save(): void {
      this.isSubmitted = true;

      // Check if data is valid
      if (
        !this.isBankNameFieldCorrect ||
        !this.isIbanFieldCorrect ||
        !this.isBicFieldCorrect ||
        !this.isAccountHolderFieldCorrect ||
        !this.isCountryFieldCorrect
      ) {
        return;
      }

      this.$emit('save', this.data);
    },
  },
});
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.errors {
  margin: 0;
  position: absolute;
  bottom: 0;
  font-size: 12px;
}
.mcp-admin__form-field {
  overflow: hidden;
}
</style>
