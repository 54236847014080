<template lang="pug">
div
  v-menu(
    bottom
    nudge-bottom="58"
    max-height="260"
    :value="menuVisible"
    :close-on-click="false"
    )
    template(v-slot:activator="{}")
      v-text-field(
        label="Merchant Account Name"
        hint="Enter merchant account name for the business entity"
        persistent-hint
        filled
        v-stream:input="searchInput$"
        :loading="itemsLoading"
        :value="value"
        :disabled="disabled"
        @input="onInput"
        @focus="isFocused = true"
        @blur="isFocused = false"
        :error-messages="errorMessage"
      )

    //- Dropdown menu with similar accounts
    v-card
      v-card-text.text--primary
        div.mb-4 Merchant accounts with similar names already exist:
        div.mt-2(v-for="item in items") {{ item }}

  //- Submit error
  v-snackbar(
    v-if="notify === 'NOTIFY_SEARCH_FAILED'"
    top
    v-model="notify"
    color="error"
    :timeout="3000"
   )
    |  Error while searching for merchant accounts
    v-btn(text dark @click="notify = false") Close    
</template>

<script lang="ts">
import Vue from 'vue';
import { Subject, from, merge, ConnectableObservable } from 'rxjs';
import {
  filter,
  pluck,
  debounceTime,
  switchMap,
  mapTo,
  startWith,
  multicast,
} from 'rxjs/operators';
import { fetchSimilarMerchantAccountNames } from 'Api/endpoints/merchant-account/merchant-account.endpoint';

export default Vue.extend({
  props: {
    defaultValue: String,
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      minSearchQueryLength: 3,
      notify: null,
      isFocused: false,
      value: '',
    };
  },
  subscriptions() {
    const fetchSimilarMerchantAccountNames =
      // @ts-ignore
      this.$_fetchSimilarMerchantAccountNames.bind(this);
    const searchInput$ = new Subject().pipe(
      pluck('event', 'msg'),
      filter(
        // @ts-ignore
        (query: string) => query && query.length >= this.minSearchQueryLength
      )
    );

    // @ts-ignore
    this.searchInput$ = searchInput$;

    const results$ = searchInput$.pipe(
      debounceTime(500),
      // Take results from the latest input only
      switchMap((query) =>
        query
          ? from(fetchSimilarMerchantAccountNames(query))
          : from(Promise.resolve([]))
      ),
      // Convert a regular Observable into a ConnectableObservable
      // with shared subscription between subscribers
      // in order to avoid duplicated API requests
      multicast(new Subject())
    ) as ConnectableObservable<any>;

    const subscription = results$.connect();

    // Unsubscribe on component destroy
    this.$once('hook:beforeDestroy', () => subscription.unsubscribe());

    return {
      items: results$.pipe(startWith([])),
      itemsLoading: merge(
        searchInput$.pipe(mapTo(true)),
        results$.pipe(mapTo(false))
      ).pipe(startWith(false)),
    };
  },
  computed: {
    menuVisible(): boolean {
      return (
        this.searchQuery.length >= this.minSearchQueryLength &&
        !this.itemsLoading &&
        this.items &&
        this.items.length &&
        this.isFocused
      );
    },
    errorMessage(): string {
      return this.items.length > 0
        ? 'Merchant accounts with similar names already exist'
        : null;
    },
  },
  mounted() {
    this.value = this.defaultValue;
    this.searchQuery = this.defaultValue || '';

    this.$watch(
      function (): boolean {
        return (
          !this.itemsLoading &&
          this.searchQuery.length >= this.minSearchQueryLength &&
          this.items.length === 0
        );
      },
      function (newValue): void {
        this.$emit('validate', newValue);
      },
      { immediate: true }
    );
  },
  methods: {
    onInput(q): void {
      this.searchQuery = q;
      this.$emit('input', q);
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    $_fetchSimilarMerchantAccountNames(q): Promise<string | string[]> {
      if (q === this.defaultValue) {
        // If the value is the same as the old one, there's no need to call the backend.
        return Promise.resolve([]);
      }
      return fetchSimilarMerchantAccountNames(q)
        .then((items) =>
          // Never show the old value, so small changes (like
          // single-character difference, or capitalization) are possible!
          items.filter((name) => name !== this.defaultValue)
        )
        .catch(() => (this.notify = 'NOTIFY_SEARCH_FAILED'));
    },
  },
});
</script>

<style lang="scss" scoped></style>
