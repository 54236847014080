/**
 * Simple promise tracker
 */
export default function McPromiseTracker($q) {
  /**
   * @constructor
   */
  function McPromiseTracker() {
    this._tracked = [];
  }

  /**
   * Add a promise to the tracking stack
   *
   * @param {Promise} promise
   * @returns {Promise}
   */
  McPromiseTracker.prototype.addPromise = function (promise) {
    this._tracked.push(promise);
    return promise
      .then(this._onDone(promise, false))
      .catch(this._onDone(promise, true));
  };

  /**
   * Check if there are pending promises
   *
   * @returns {boolean}
   */
  McPromiseTracker.prototype.hasPending = function () {
    return this._tracked.length > 0;
  };

  /**
   * Return a promise executor callback
   *
   * @param {Promise} promise - the original tracked promise
   * @param {bool} error - true for rejection executor
   * @returns {Function}
   * @private
   */
  McPromiseTracker.prototype._onDone = function (promise, error) {
    const self = this;
    return function (arg) {
      self._removePromise(promise);
      return error ? $q.reject(arg) : $q.when(arg);
    };
  };

  /**
   * Remove the tracked promise from the stack
   *
   * @param {Promise} promise
   * @returns {number|undefined}
   * @private
   */
  McPromiseTracker.prototype._removePromise = function (promise) {
    if (this._tracked.length === 0) {
      return;
    }

    const index = this._tracked.indexOf(promise);

    if (index === -1) {
      return index;
    }

    this._tracked.splice(index, 1);
  };

  return McPromiseTracker;
}

McPromiseTracker.$inject = ['$q'];
