import axios from 'axios';
import { pipe } from 'ramda';
import ErrorHandler from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';

const merchantApi = axios.create({
  baseURL: 'api/merchant-profile/merchants',
});
merchantApi.interceptors.request.use(decamelizeRequestHandler);
merchantApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

export const sendMerchantForFinancialReview = (merchantId: string) => {
  return merchantApi.post(`/${merchantId}/bank-accounts/financial-review`);
};
