export default function mcbToastProvider() {
  let messages = [];
  let messageStack = [];

  let defaults = {
    animation: 'slide', // or false
    className: 'success',
    additionalClasses: null,
    dismissOnTimeout: true,
    timeout: 3000,
    dismissButton: false,
    dismissButtonHtml: '&times;', // TODO: XXX
    dismissOnClick: true,
    compileContent: false,
    combineDuplications: false,
    horizontalPosition: 'center', // right, center, left
    verticalPosition: 'top', // top, bottom,
    maxNumber: 0,
  };

  function Message(msg) {
    let id = Math.floor(Math.random() * 1000);
    while (messages.indexOf(id) > -1) {
      id = Math.floor(Math.random() * 1000);
    }

    this.id = id;
    this.count = 0;
    this.animation = defaults.animation;
    this.className = defaults.className;
    this.additionalClasses = defaults.additionalClasses;
    this.dismissOnTimeout = defaults.dismissOnTimeout;
    this.timeout = defaults.timeout;
    this.dismissButton = defaults.dismissButton;
    this.dismissButtonHtml = defaults.dismissButtonHtml;
    this.dismissOnClick = defaults.dismissOnClick;
    this.compileContent = defaults.compileContent;

    angular.extend(this, msg);
  }

  this.options = function (config) {
    angular.extend(defaults, config);
  };

  /**
   * @ngdoc service
   * @name mcbToast
   * @module mcb.components.toast
   *
   * @requires $log
   */
  this.$get = [
    '$log',
    function ($log) {
      let _createWithClassName = function (className, msg) {
        msg = typeof msg === 'object' ? msg : { content: msg };
        msg.className = className;

        return this.show(msg);
      };

      return {
        settings: defaults,
        messages: messages,
        defaultPreset: function () {
          // FIXME
          $log.warn('mcbToast.defaultPreset is not implemented.');
          return {
            content: angular.noop,
          };
        },
        dismiss: function (id) {
          if (id) {
            for (let i = messages.length - 1; i >= 0; i--) {
              if (messages[i].id === id) {
                messages.splice(i, 1);
                messageStack.splice(messageStack.indexOf(id), 1);
                return;
              }
            }
          } else {
            while (messages.length > 0) {
              messages.pop();
            }
            messageStack = [];
          }
        },
        show: function (msg) {
          msg = typeof msg === 'object' ? msg : { content: msg };

          if (defaults.combineDuplications) {
            for (let i = messageStack.length - 1; i >= 0; i--) {
              let _msg = messages[i];
              let _className = msg.className || 'success';

              if (
                _msg.content === msg.content &&
                _msg.className === _className
              ) {
                messages[i].count++;
                return;
              }
            }
          }

          if (
            defaults.maxNumber > 0 &&
            messageStack.length >= defaults.maxNumber
          ) {
            this.dismiss(messageStack[0]);
          }

          let newMsg = new Message(msg);
          if (defaults.verticalPosition === 'bottom') {
            messages.unshift(newMsg);
          } else {
            messages.push(newMsg);
          }
          messageStack.push(newMsg.id);
          return newMsg.id;
        },
        success: function (msg) {
          return _createWithClassName.call(this, 'success', msg);
        },
        info: function (msg) {
          return _createWithClassName.call(this, 'info', msg);
        },
        warning: function (msg) {
          return _createWithClassName.call(this, 'warning', msg);
        },
        error: function (msg) {
          return _createWithClassName.call(this, 'error', msg);
        },
      };
    },
  ];
}
