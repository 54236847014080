import axios from 'axios';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
  resolveRelationships,
} from 'Api/api-mutations';
import ErrorHandler from 'Api/api-errors-handler';
import { pipe, prop } from 'ramda';
import {
  FactoringTypeT,
  FulfillmentTypeT,
  MerchantProfile,
  MerchantProfileJsonT,
} from 'Api/models/MerchantProfile';

const BASE_URL = '/api/merchant-profile/merchants';

const vendorJsonContentType = {
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json',
};

const merchantsApi = axios.create({
  baseURL: BASE_URL,
  paramsSerializer: (params) =>
    Object.entries(params)
      .filter((p) => p[1] !== null)
      .map((p) => p.map(encodeURIComponent).join('='))
      .join('&'),
});
merchantsApi.interceptors.request.use(decamelizeRequestHandler);
merchantsApi.interceptors.response.use(
  // @ts-ignore
  pipe(camelizeResponseHandler, prop('data'), resolveRelationships),
  ErrorHandler
);

export const fetchMerchants = (params: {
  name?: string;
  types?: string;
  status?: string;
  sort?: string;
  factoring?: FactoringTypeT;
  'page[number]'?: number;
  'page[size]'?: number;
}): Promise<{
  items: MerchantProfileJsonT[];
  meta: { count: number };
}> => {
  return merchantsApi.get(``, {
    params: {
      name: params.name || null,
      types: params.types || null,
      status: params.status || null,
      sort: params.sort || null,
      'page[number]': params['page[number]'] || 0,
      'page[size]': params['page[size]'] || 10,
      factoring: params.factoring || null,
    },
  });
};

export const saveMerchant = (
  merchant: Partial<MerchantProfile>
): Promise<{ id: string }> => {
  return merchantsApi.post(
    '',
    {
      data: { attributes: merchant, type: 'MerchantProfile' },
    },
    {
      headers: vendorJsonContentType,
    }
  );
};

export function sendReviewReason(merchantId: string, reason: string): void {
  merchantsApi.put(
    `/${merchantId}/change-reasons`,
    {
      reason,
    },
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        type: 'SENT_FOR_REVIEW',
      },
    }
  );
}

export function sendUpdateMerchantDataReason(
  merchantId: string,
  reason: string,
  salesChannelData?: {
    fulfilmentType: FulfillmentTypeT;
    salesChannelId: string;
  }
): Promise<void> {
  return axios.put(
    `/${merchantId}/change-reasons`,
    {
      reason,
    },
    {
      baseURL: BASE_URL,
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
      params: {
        type: 'DATA_CHANGE',
        ...(salesChannelData?.fulfilmentType && {
          'fulfillment-type': salesChannelData.fulfilmentType,
        }),
        ...(salesChannelData?.salesChannelId && {
          'sales-channel-id': salesChannelData.salesChannelId,
        }),
      },
    }
  );
}
export function isMerchantZPO(merchant: MerchantProfile): boolean {
  const { merchantType } = merchant;
  return merchantType !== 'CONNECTED_RETAIL';
}

export const updateMerchant = (
  merchantId: string,
  data: Partial<MerchantProfile>
): Promise<{ id: string }> => {
  return merchantsApi.patch(
    `/${merchantId}`,
    {
      data: { attributes: data, type: 'MerchantProfile' },
    },
    {
      headers: vendorJsonContentType,
    }
  );
};

export const fetchMerchant = (merchantId: string): Promise<MerchantProfile> => {
  return merchantsApi.get(`/${merchantId}`, {
    headers: vendorJsonContentType,
  });
};
