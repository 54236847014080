import { Contract } from 'Api/models/Contract';
export const CONTRACTS_LOADED = 'CONTRACTS_LOADED';
export const CONTRACTS_LOADING = 'CONTRACTS_LOADING';

export interface ContractsState {
  loading: boolean;
  data: Contract[];
  error: any;
}

export const contractsInitialState: ContractsState = {
  loading: false,
  data: [] as Contract[],
  error: null,
};

export const contractsReducer = (
  state = { ...contractsInitialState },
  { type, payload }
) => {
  switch (type) {
    case CONTRACTS_LOADED:
      return {
        ...state,
        data: (payload as Contract[])?.filter(
          (contract) => contract.integrationObjectStage !== 'Cancelled'
        ),
        loading: false,
      } as ContractsState;
    case CONTRACTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
