import { BankAccountInfoT } from 'Api/models/BankAccount';
import { find, pluck, propEq, uniq } from 'ramda';
import { BillingModeT } from 'Api/models/BillingMode';
import { CurrencySalesChannelT } from 'Api/models/CurrencySalesChannel';

export type BankAccountFormDataT = Partial<BankAccountInfoT>;

export const EURO_CURRENCY_CODE = 'EUR';
export const getInitialBankAccountInfo = (
  overrides: BankAccountFormDataT = {}
): BankAccountFormDataT => ({
  accountHolder: '',
  bankName: '',
  confirmationDocumentId: '',
  currencyCode: '',
  iban: '',
  ...overrides,
});

export const findBankAccountByCurrencyCode = (
  bankAccountList: BankAccountInfoT[],
  currencyCode: string
): BankAccountInfoT => {
  return find<BankAccountInfoT>(propEq('currencyCode', currencyCode))(
    bankAccountList
  );
};

interface GetBankAccountsForBillingModePropsT {
  billingMode: BillingModeT;
  bankAccounts: BankAccountInfoT[];
  currencySalesChannels: CurrencySalesChannelT[];
  legalEntityName: string;
}

export const getBankAccountsForBillingMode = ({
  billingMode,
  bankAccounts,
  currencySalesChannels,
  legalEntityName,
}: GetBankAccountsForBillingModePropsT): BankAccountFormDataT[] => {
  const currencyCodes = uniq(pluck('currencyCode')(currencySalesChannels));

  if (billingMode === BillingModeT.LOCAL_CURRENCY_MODE) {
    return currencyCodes.map((currencyCode: string) => {
      const matchingBankAccount = findBankAccountByCurrencyCode(
        bankAccounts,
        currencyCode
      );
      return (
        matchingBankAccount ||
        getInitialBankAccountInfo({
          currencyCode,
          accountHolder: legalEntityName,
        })
      );
    });
  } else {
    const bankAccount = findBankAccountByCurrencyCode(
      bankAccounts,
      EURO_CURRENCY_CODE
    );

    return [
      bankAccount ||
        getInitialBankAccountInfo({
          currencyCode: 'EUR',
          accountHolder: legalEntityName,
        }),
    ];
  }
};

export const findCurrencyCodeForSalesChannel = (
  currencySalesChannels: CurrencySalesChannelT[],
  salesChannelId: string
): string => {
  return find<CurrencySalesChannelT>(propEq('salesChannelId', salesChannelId))(
    currencySalesChannels
  ).currencyCode;
};
