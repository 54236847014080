export default function mcLoadingScreen(
  mcLoadingScreenPromiseTracker,
  MC_LOADING_SCREEN
) {
  return {
    restrict: 'A',
    link: function (scope, el) {
      scope.$watch(
        () => mcLoadingScreenPromiseTracker.hasPending(),
        (newVal) => {
          if (angular.isUndefined(newVal)) {
            return;
          }

          if (newVal === false) {
            el.addClass(MC_LOADING_SCREEN.HIDE_CSS_CLASS);
          } else {
            el.removeClass(MC_LOADING_SCREEN.HIDE_CSS_CLASS);
          }
        }
      );
    },
  };
}

mcLoadingScreen.$inject = [
  'mcLoadingScreenPromiseTracker',
  'MC_LOADING_SCREEN',
];
