<template lang="pug">
v-app.root
  h2.dc-h2 Select sales channels

  form(novalidate)
    //- Retailer
    div(v-for="retailer in retailers")
      h3.dc-h3  {{retailer.name}}

      div.dc-msg.dc-msg--warning(v-if="isFormControlDisabled(retailer)")
        div.dc-msg__inner
          div.dc-msg__icon-frame
            i.dc-icon.dc-msg__icon.dc-icon--warning

          div.dc-msg__bd
            h1.dc-msg__title Sales channels are disabled for merchants created through Salesforce. Please use Salesforce to automatically add the sales channels.

      div.channels
        //- Header
        div.channels__header
          div.channels__1col

          div.channels__col(
            v-for="fulfillmentType in fulfillmentTypes"
          )
            div {{ fulfillmentType.name }}
            div(v-if="!isFormControlDisabled(retailer)")
              button.dc-link(
                href
                @click="onSelectFType(retailer, fulfillmentType)"
              ) All

              span &nbsp;/&nbsp;

              button.dc-link(
                href
                @click="onDeselectFType(retailer, fulfillmentType)"
              ) None

        //- Row
        div.channel(
          v-for="salesChannel in sortChannelsByCountryName(retailer.salesChannels)"
          :key="retailer.$id + salesChannel.$id"
        )
          //- Flag and country name
          div.channels__1col
            img.channel__flag(
              v-if="salesChannel.countryCode.indexOf(',') === -1"
              :src="imagesPath + '/flags/flag--' + salesChannel.countryCode + '.svg'"
              width="26"
              height="17"
            )

            img.channel__flag(
              v-if="salesChannel.countryCode.indexOf(',') > -1"
              v-for="countryCode in salesChannel.countryCode.split(',')"
              :key="countryCode"
              :src="imagesPath + '/flags/flag--' + countryCode + '.svg'"
              width="26"
              height="17"
            )

            span.channel__name  {{ salesChannel.countryName }}

          //- Fulfillment types columns (checkboxes)
          div.channels__col-val-container(
            :key="retailer.$id + salesChannel.$id + fulfillmentType.$id"
            v-for="fulfillmentType in fulfillmentTypes"
          )
            v-checkbox.channels__col-val(
              :ripple="false"
              :hide-details="true"
              :input-value="isSelected(retailer.$id, salesChannel.$id, fulfillmentType.$id)"
              :disabled="isDisabled(retailer.$id, salesChannel.$id, fulfillmentType.$id) || isFormControlDisabled(retailer)"
              @change="onContractToggle(retailer.$id, salesChannel.$id, fulfillmentType.$id)"
            )

      hr.dc-divider

  div.channels-buttons(v-if="retailers.length > 1 || !shouldDisableZalandoRetailControls")
    button.dc-btn.dc-btn--primary(
      :class="{'dc-btn--disabled': isSubmitEnabled}"
      :disabled="isSubmitEnabled"
      @click="onSave"
    ) Save sales channels
</template>

<script lang="ts">
import Vue from 'vue';
import { SalesChannel } from 'Api/models/SalesChannel';
import { Retailer } from 'Api/models/Retailer';
import { FULFILLMENT_TYPES } from 'Common/constants/misc.constant';

export default Vue.extend({
  props: {
    retailers: {
      type: Array,
      required: true,
    },
    imagesPath: {
      type: String,
      required: true,
    },
    isSubmitEnabled: {
      type: Boolean,
      required: true,
    },
    isSelected: {
      type: Function,
      required: true,
    },
    isDisabled: {
      type: Function,
      required: true,
    },
    selectedItems: {
      type: Set,
      required: true,
    },
    // We need it just to trigger component re-render and expressions re-evaluation whenever selection changes
    onSelectFType: {
      type: Function,
      required: true,
    },
    onDeselectFType: {
      type: Function,
      required: true,
    },
    onContractToggle: {
      type: Function,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    shouldDisableZalandoRetailControls: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      fulfillmentTypes: FULFILLMENT_TYPES,
    };
  },
  methods: {
    sortChannelsByCountryName(channels: SalesChannel[]): SalesChannel[] {
      return [...channels].sort((a, b) => {
        if (a.countryName < b.countryName) {
          return -1;
        }
        if (a.countryName > b.countryName) {
          return 1;
        }

        return 0;
      });
    },
    isFormControlDisabled(retailer: Retailer): boolean {
      return (
        this.shouldDisableZalandoRetailControls &&
        retailer.name === 'Zalando Fashion Store'
      );
    },
  },
});
</script>

<style lang="scss" scoped>
$grey: #eeeeee;
$document-font-size: 10px;

.root {
  background-color: white;
}

.channels {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.channels__header {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.channels__1col {
  flex: 0 0 250px;
}

.channels__col {
  flex: 0 0 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.channels__col-val-container {
  display: flex;
  flex: 0 0 150px;
  justify-content: center;
}

.channels__col-val {
  width: fit-content;
  margin-top: 0;
  padding-top: 0;
}

.channel {
  max-width: 100%;
  flex: 0 0 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 0;

  &:nth-child(odd) {
    background-color: $grey;
  }
}

.channel__name {
  padding-left: 0.5 * $document-font-size;
}

.channel__flag {
  margin-left: 0.5 * $document-font-size;
  vertical-align: sub;
}

.channels-buttons {
  text-align: right;
}
</style>
