import Vue from 'vue';
import dayjs from 'dayjs';
import { getFullfillmentTypeName } from 'Common/filters/fulfillment-type.filter';
import { BillingModeT } from 'Api/models/BillingMode';
import { PreferredLanguageT } from 'Api/models/MerchantProfile';

Vue.filter('formatDateTime', (date): string => {
  return dayjs(date).format('DD.MM.YYYY (HH:mm)');
});

Vue.filter('formatDate', (date): string => {
  return dayjs(date).format('DD.MM.YYYY');
});

Vue.filter('formatCurrency', (currencyCode: string): string =>
  currencyCode
    .replace('EUR', '€')
    .replace('GBP', '£')
    .replace('HUF', 'ft')
    .replace('RON', 'lei')
);

Vue.filter('mcpGetFFTypeName', getFullfillmentTypeName);

Vue.filter('formatBillingMode', (billingMode: BillingModeT): string => {
  return {
    [BillingModeT.LOCAL_CURRENCY_MODE]: 'Local',
    [BillingModeT.EURO_MODE]: 'Euro',
  }[billingMode];
});

Vue.filter(
  'formatInvoiceLanguage',
  (invoiceLanguage: PreferredLanguageT): string => {
    return {
      [PreferredLanguageT.English]: 'English',
      [PreferredLanguageT.German]: 'German',
    }[invoiceLanguage];
  }
);

Vue.filter('formFieldRequired', (value: string): string | boolean => {
  return Boolean(value) || 'Required';
});
