import { mcb } from 'mcb';
import wrapVue from '../common/wrap-vue.converter';
import coreModule from 'Common/core.module';
import { MCP_ADMIN_ROLES } from 'Common/constants/roles.constant';
import { HeaderService } from 'Common/services/header.service';
import MerchantAccountDetailsContainerComponent from './merchant-account-details.container.vue';

export default angular
  .module('mcp.admin.merchant-account-details', [coreModule.name])

  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider): void => {
      $stateProvider.state('mcp.admin.merchant-account-details', {
        url: '/merchant-accounts/:merchantAccountId',
        template: `<mcp-admin-merchant-account-details-wrapper
          on-edit="ctrl.edit"
          merchant-account-id="ctrl.mcpHeaderService.getCurrentMerchantAccountId()"
        ></mcp-admin-merchant-account-details-wrapper>`,
        controller: [
          'mcpHeaderService',
          '$state',
          function (
            mcpHeaderService: HeaderService,
            $state: ng.ui.IStateService
          ): void {
            this.mcpHeaderService = mcpHeaderService;
            this.edit = (): any =>
              $state.go('mcp.admin.merchant-account-edit', {
                merchantAccountId:
                  this.mcpHeaderService.getCurrentMerchantAccountId(),
              });
          },
        ],
        controllerAs: 'ctrl',
        firewalls: {
          iam: {
            roles: [
              MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
              MCP_ADMIN_ROLES.STOCK_MANAGER,
              MCP_ADMIN_ROLES.SUPER_ADMIN,
            ],
          },
        },
      } as mcb.firewall.IMcbState);
    },
  ])
  .component(
    'mcpAdminMerchantAccountDetailsWrapper',
    wrapVue(
      MerchantAccountDetailsContainerComponent,
      'mcpAdminMerchantAccountDetailsWrapper'
    )
  );
