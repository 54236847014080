import { ICountry } from 'mcb';
import { Address } from 'Api/models/Address';
import { COUNTRIES } from 'Common/constants/misc.constant';
import template from './address-form.html';

export class AddressFormComponent implements ng.IComponentOptions {
  static Factory() {
    return new AddressFormComponent();
  }

  bindings: { [binding: string]: string } = {
    address: '<',
    onSave: '<',
    onCancel: '<',
    onUseDefault: '&?',
    useName: '<?',
    useAddrLine3: '<?',
    retailerName: '<',
    countryName: '<',
    showTaxVat: '<?',
    taxId: '<?',
    vatId: '<?',
    companyCountry: '<?',
  };

  controller = AddressFormController;
  controllerAs = 'ctrl';
  template: string = template;
}

class AddressFormController implements angular.IComponentController {
  static $inject = [];

  // injected by component
  address: Address;
  addressForm: ng.IFormController;
  contractId: string;
  onSave: Function;
  onCancel: Function;
  onUseDefault: Function;
  retailerName: string;
  countryName: string;
  useName: boolean;
  useAddrLine3: boolean;
  showTaxVat: boolean;
  taxId: string;
  vatId: string;
  companyCountry: string;

  countries: ICountry[];
  formData: { address?: Address; vatId?: string; taxId?: string };

  constructor() {
    this.countries = [...COUNTRIES];
  }

  /**
   * $onInit - angular's lifecycle method
   *
   * Here we are creating a scoped copy of merchant object to simulate a one-time (+one-way) binding,
   * so that editing properties in the scoped object doesn't change the property in parent controller.
   */
  $onInit(): void {
    this.formData = {};
    if (this.address) {
      this.formData.address = angular.copy(this.address);
    }

    if (this.showTaxVat) {
      this.formData.vatId = this.vatId || '';
      this.formData.taxId = this.taxId || '';
    }
  }

  save(): void {
    if (this.showTaxVat) {
      // Set 'mcpOneRequired' validity status on taxId/vatId fields
      this.onTaxVatChange();
    }

    if (this.addressForm.$invalid) {
      return;
    }

    const { address, vatId, taxId } = this.formData;

    const params = this.showTaxVat
      ? [address, vatId.replace(/\s/g, ''), taxId.replace(/\s/g, '')]
      : [address];

    this.onSave(...params);
  }

  cancel(): void {
    this.onCancel();
  }

  useDefault(): void {
    this.onUseDefault();
  }

  isFieldErrorDisplayed(field: string): boolean {
    const form = this.addressForm;
    return form.$submitted && form[field].$invalid;
  }

  onTaxVatChange() {
    const { taxId, vatId } = this.addressForm;
    const oneFilled = !!(taxId.$viewValue.trim() || vatId.$viewValue.trim());
    taxId.$setValidity('mcpOneRequired', oneFilled);
    vatId.$setValidity('mcpOneRequired', oneFilled);
  }
}
