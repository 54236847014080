import { Address } from './Address';
import { BankAccount } from './BankAccount';
import { Contract, ContractJsonT } from './Contract';
export type FulfillmentTypeT = 'ZALANDO' | 'PARTNER' | 'ALL';
export type IntegrationTypeT = 'DIRECT' | 'PARTNER_PROVIDER';
export type MerchantTypeT =
  | 'BRAND'
  | 'RETAILER'
  | 'CONNECTED_RETAIL'
  | 'DISTRIBUTOR'
  | 'SERVICE_PROVIDER';
export type FactoringTypeT = '1.0' | '2.0';

export enum PreferredLanguageT {
  German = 'de-DE',
  English = 'en-GB',
}

export interface MerchantProfile {
  $id: string;
  $type: string;
  billingCurrency: string;
  billingCycle: string;
  companyName: string;
  contactFirstName: string;
  contactLastName: string;
  created_by: string;
  factoring: FactoringTypeT;
  email: string;
  fulfillmentType?: FulfillmentTypeT;
  integrationType?: IntegrationTypeT;
  merchantGroupId?: string;
  merchantType?: MerchantTypeT;
  name: string;
  preferredLanguage: PreferredLanguageT;
  serviceLevel?: string;
  status: string;
  supplierCode: string;
  taxId: string;
  vatId?: string;
  address?: Address;
  bankAccount?: BankAccount;
  contracts?: Contract[];
  salesforceIntegration?: 'FULL' | 'PARTIAL' | 'MISSING';
  hasBeenOnboarded: boolean;
  legalEntityId: string;
}

export interface MerchantProfileJsonT {
  id: string;
  billingCurrency: string;
  billingCycle: string;
  companyName: string;
  contactFirstName: string;
  contactLastName: string;
  created_by: string;
  factoring: FactoringTypeT;
  email: string;
  merchantGroupId?: string;
  merchantType?: string;
  name: string;
  preferredLanguage: PreferredLanguageT;
  serviceLevel?: string;
  status: string;
  supplierCode: string;
  taxId: string;
  vatId?: string;
  address?: Address;
  bankAccount?: BankAccount;
  contracts?: ContractJsonT[];
  modifiedBy: string;
  modifiedAt: string;
  legalEntityId: string;
}

// This interface is used for cases when we use parts of the MerchantProfile
export interface MerchantProfileOptional {
  $id?: string;
  $type?: string;
  billingCurrency?: string;
  billingCycle?: string;
  companyName?: string;
  contactFirstName?: string;
  contactLastName?: string;
  created_by?: string;
  email?: string;
  merchantGroupId?: string;
  merchantType?: string;
  name?: string;
  preferredLanguage?: PreferredLanguageT;
  serviceLevel?: string;
  status?: string;
  supplierCode?: string;
  taxId?: string;
  vatId?: string;
  address?: Address;
  bankAccount?: BankAccount;
  contracts?: Contract[];
}
