import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.legalEntityId
    ? _c(
        "div",
        { staticClass: "my-3" },
        [
          _c("ReviewSectionTitle", {
            attrs: { title: "Legal Entity Details" },
          }),
          _c(
            "div",
            { staticClass: "py-3" },
            [
              _c("div", { staticClass: "field" }, [
                _c(
                  "div",
                  { staticClass: "label text-caption text-uppercase" },
                  [_vm._v("Legal Entity Name")]
                ),
                _c("div", [_vm._v(_vm._s(_vm.merchant.companyName))]),
              ]),
              _c("VatAddress", { attrs: { address: _vm.merchant.address } }),
              _c("div", { staticClass: "field" }, [
                _c(
                  "div",
                  { staticClass: "label text-caption text-uppercase" },
                  [_vm._v("Vat Id")]
                ),
                _c("div", [_vm._v(_vm._s(_vm.merchant.vatId))]),
              ]),
              _c("div", { staticClass: "field mb-3" }, [
                _c(
                  "div",
                  { staticClass: "label text-caption text-uppercase" },
                  [_vm._v("Tax Id")]
                ),
                _c("div", [_vm._v(_vm._s(_vm.merchant.taxId))]),
              ]),
              _c(VDivider),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }