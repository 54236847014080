<template lang="pug">
form(novalidate @submit.prevent)
  div.mcp-admin-merchant-details-section
    div.mcp-admin-merchant-details-section-form
      div.mcp-admin__form-field.field-wrapper
        label.dc-label Validity Date for Migration to Factoring 2.0
        div.mcp-admin-factoring__validity-date
          Datepicker(
            data-testid="migration-validity-date"
            format="yyyy-MM-dd"
            placeholder="Date"
            :disabled-dates="disabledDates"
            v-model="validityDate"
            :disabled="true"
            :wrapper-class="fms !== 'POSSIBLE' ? 'disabled-date' : ''"
          )
        div.dc--text.info(
          v-if="fms === 'PENDING'"
          data-testid="pending-message"
        ) The migration to Factoring 2.0 is already initiated for the partner and waiting for financial approval. Contact the <a href = "mailto: accounting-partnerprogramm@zalando.de">Partner Program Accounting Team</a> for details.
        div.dc--text.success(
          v-if="fms === 'APPROVED'"
          data-testid="approved-message"
        ) Yay! Migration to new Factoring format will start from the above mentioned date.

</template>

<script lang="ts">
import Vue from 'vue';
import Datepicker from 'vuejs-datepicker';
import dayjs from 'dayjs';

export default Vue.extend({
  components: {
    Datepicker,
  },
  props: {
    saveCb: { type: Function, required: true },
    fms: { type: String, default: '' },
    fvd: { type: String, default: null },
    mv: { type: String, default: '' },
  },

  data() {
    return {
      isSubmitInProgress: false,
      hasClickedMigrateBtn: false,
      validityDate: this.fvd,
      disabledDates: {
        to: dayjs().subtract(0, 'day').toDate(),
      },
    };
  },

  computed: {
    hasChanges(): boolean {
      return this.validityDate !== null;
    },
  },
});
</script>

<style lang="scss" scoped>
.mcp-admin-factoring__validity-date {
  background-color: white;
  width: fit-content;
}
.field {
  width: 100%;
}
.field-wrapper {
  margin-bottom: 24px;
}
.errors {
  position: absolute;
  bottom: 0;
}
.disabled-date {
  background-color: #f7f7f7;
  border-color: #ededed;
  background-color: #f7f7f7;
  color: #b6b6b6;
}
.info {
  color: #1e92f4;
  a {
    color: #1e92f4;
  }
}
.success {
  color: #376e37;
}
</style>
