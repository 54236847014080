import {
  ApprovalStatusT,
  SelectableReviewStatusT,
} from 'Api/models/ApprovalStatus';
import { BankAccountListItemT } from 'Merchant/common/components/bank-account-details/bank-account-review-item.vue';
import { BankAccountInfoT } from 'Api/models/BankAccount';
import { CurrencySalesChannelT } from 'Api/models/CurrencySalesChannel';

export const REJECTION_REASON_MIN_WORD_COUNT = 5;

const getSalesChannelsForCurrency = (
  currencySalesChannelMap: CurrencySalesChannelT[],
  currencyCode: string
): string[] => {
  return currencySalesChannelMap.reduce(
    (applicableSalesChannels, salesChannelCurrencyMap) => {
      if (salesChannelCurrencyMap.currencyCode === currencyCode) {
        applicableSalesChannels.push(salesChannelCurrencyMap.salesChannelId);
      }
      return applicableSalesChannels;
    },
    []
  );
};

export const getBankAccountList = (
  bankAccountList: BankAccountInfoT[],
  currencySalesChannelMap: CurrencySalesChannelT[]
): BankAccountListItemT[] => {
  return bankAccountList.map((bankAccountDetails, index) => {
    return {
      id: String(index),
      ...bankAccountDetails,
      salesChannelIds: getSalesChannelsForCurrency(
        currencySalesChannelMap,
        bankAccountDetails.currencyCode
      ),
      providedRejectionReason: '',
    };
  });
};

const hasValidRejectionReason = (rejectionReason?: string) => {
  return (
    rejectionReason?.trim().split(/\s+/).length >=
    REJECTION_REASON_MIN_WORD_COUNT
  );
};

export const isDataMissingForTask = (
  approvalStatus: ApprovalStatusT,
  selectedStatus: SelectableReviewStatusT,
  rejectionReason?: string
) => {
  return (
    approvalStatus === ApprovalStatusT.IN_REVIEW &&
    (!selectedStatus ||
      (selectedStatus === ApprovalStatusT.REJECTED &&
        !hasValidRejectionReason(rejectionReason)))
  );
};

export const isReviewSubmitDisabled = (
  bankDetailsList: BankAccountListItemT[]
): boolean => {
  return bankDetailsList.some(
    ({ status, selectedStatus, providedRejectionReason }) => {
      return isDataMissingForTask(
        status,
        selectedStatus,
        providedRejectionReason
      );
    }
  );
};
