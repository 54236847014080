import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { staticClass: "root" },
    [
      _c(
        VMain,
        [
          _c("ContractRow", {
            attrs: {
              merchantId: _vm.merchant.$id,
              salesChannelId: _vm.contract.salesChannelId,
              locales: _vm.locales,
              useDraft: !_vm.isLaunched,
              status: _vm.status,
              fulfillmentType: _vm.fulfillmentType,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }