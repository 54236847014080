import template from './address.html';
import { Address } from 'Api/models/Address';

export class AddressComponent implements ng.IComponentOptions {
  static Factory() {
    return new AddressComponent();
  }

  bindings: { [binding: string]: string } = {
    address: '=',
    useName: '<?',
    showTaxVat: '<?',
    taxId: '<?',
    vatId: '<?',
  };

  controller = AddressController;
  controllerAs = 'ctrl';
  template: string = template;
}

class AddressController implements ng.IController {
  static $inject = [];

  // injected by component
  address: Address;
  useName: boolean;
  showTaxVat: boolean;
  taxId: string;
  vatId: string;

  $onInit() {
    // Empty block
  }
}
