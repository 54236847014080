let viewTitleProvider = function viewTitleProvider() {
  let opt = {
    title: 'Loading...',
  };

  this.options = function (options) {
    if (!options) {
      return opt;
    }
    opt = angular.extend(opt, options);
  };

  /**
   * @ngdoc service
   * @name mcViewTitle
   * @module mc.components.viewTitle
   */
  this.$get = function () {
    function viewTitle(val) {
      if (!val) {
        return viewTitle.title;
      }
      viewTitle.title = val;
    }

    viewTitle.title = opt.title;

    viewTitle.isDefaultTitle = function () {
      return viewTitle.title === opt.title;
    };

    return viewTitle;
  };
};

export default viewTitleProvider;
