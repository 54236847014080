import { mcb } from 'mcb';
import { SalesChannel } from 'Api/models/SalesChannel';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { Contract } from 'Api/models/Contract';
import { MerchantStateParams } from 'Api/models/MerchantStateParams';
import template from './contract.html';
import { Store, Unsubscribe } from 'redux';
import { FlowState } from 'Merchant/common/state/flow.state';
import { MerchantFlowActions } from 'Merchant/common/state/actions/merchant-flow.actions';
import { FlowStateSelector } from 'Merchant/common/state/selectors/flow-state.selector';
import { MerchantRequirements } from 'Api/models/MerchantRequiremenets';
import { ContractActions } from 'Merchant/common/state/actions/contract.actions';
import { ContractRequirements } from 'Api/models/ContractRequirements';
import { getToken } from '@/authentication/token.helper';

export class ContractContainer implements ng.IComponentOptions {
  static Factory() {
    return new ContractContainer();
  }

  bindings: { [binding: string]: string } = {
    merchant: '<',
    merchantStatus: '<',
  };

  controller = ContractContainerController;
  controllerAs = 'contractCtrl';
  template: string = template;
}

interface ContractControllerVm {
  merchant: MerchantProfile;
  salesChannel: SalesChannel;
  retailerName: string;
}

interface ContractStateParams extends MerchantStateParams {
  contractId: string;
}

export class ContractContainerController implements ContractControllerVm {
  static $inject = [
    'mcpAdminFlowStateSelector',
    '$stateParams',
    'mcpAdminMerchantFlowActions',
    'mcpAdminContractActions',
    '$ngRedux',
    '$state',
    'mcbToast',
  ];

  merchant: MerchantProfile;
  merchantStatus: MerchantRequirements;
  contractStatus: ContractRequirements;
  salesChannel: SalesChannel;
  contract: Contract;
  retailerName: string;
  unsubscribe: Unsubscribe;
  channelContracts: Contract[];
  isContractLoading: boolean;
  userCurrentRole: string;

  private contractId: string;

  constructor(
    private flowSelector: FlowStateSelector,
    private stateParams: ContractStateParams,
    private merchantFlowActions: MerchantFlowActions,
    private contractActions: ContractActions,
    private store: Store<FlowState>,
    private state: ng.ui.IStateService,
    private toast: mcb.IToast
  ) {
    this.contractId = stateParams.contractId;
    this.onSave = this.onSave.bind(this);
    this.onApprove = this.onApprove.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onLaunch = this.onLaunch.bind(this);
    this.onLaunchError = this.onLaunchError.bind(this);
  }

  $onInit(): void {
    this.unsubscribe = this.store.subscribe(() => {
      const state: FlowState = this.store.getState();
      this.contract = this.flowSelector.getFlowContract(state);
      this.isContractLoading =
        this.flowSelector.getFlowContractLoadingStatus(state);
      this.channelContracts = this.flowSelector.getFlowChannelContracts(
        state,
        this.contract.salesChannelId
      );
      this.contractStatus = this.flowSelector.getFlowContractStatus(state);
      if (this.contract) {
        this.salesChannel = this.contract.salesChannel;
      }
    });

    if (!this.contractId) {
      const state: FlowState = this.store.getState();
      const contracts = this.flowSelector
        .getFlowContracts(state)
        .sort((a, b) =>
          a.salesChannel.countryName < b.salesChannel.countryName ? -1 : 1
        );
      if (contracts[0]) {
        this.state.go('mcp.admin.merchant.contract.overview', {
          contractId: contracts[0].$id,
        });
      } else {
        this.state.go('mcp.admin.merchant.general.company-details-readonly');
      }
    }

    this.store
      // @ts-ignore
      .dispatch(this.merchantFlowActions.setCurrentContract(this.contractId))
      .then(() => {
        // @ts-ignore
        this.store.dispatch(this.contractActions.setContractStatus());
      });
    this.userCurrentRole = getToken().getCurrentRole();
  }

  $onDestroy(): void {
    this.unsubscribe();
  }

  getTitle() {
    if (!this.contract.retailer || !this.salesChannel) {
      return '';
    }

    return `${this.contract.retailer.name} - ${this.salesChannel.countryName}`;
  }

  onSave(msg: string): void {
    this.store
      // @ts-ignore
      .dispatch(this.contractActions.onSaveMarketData())
      .then(() => this.toast.success(msg))
      .catch(() => this.toast.error('Something went wrong!'));
  }

  onApprove(): void {
    this.store
      // @ts-ignore
      .dispatch(this.contractActions.onReview())
      .then(() => this.toast.show('Contract data successfully approved'))
      .catch(() => this.toast.error('Unable to approve contract data'));
  }

  onReject(): void {
    this.store
      // @ts-ignore
      .dispatch(this.contractActions.onReview())
      .then(() => this.toast.show('Contract data rejected'))
      .catch(() => this.toast.error('Unable to reject contract data'));
  }

  onLaunch(): void {
    this.store
      // @ts-ignore
      .dispatch(this.contractActions.onLaunch())
      .then(() => this.toast.show(`Contract successfully launched`))
      .catch(() =>
        this.toast.error(
          'Something went wrong. Try reloading the page to see up-to-date information'
        )
      );
  }

  onLaunchError(): void {
    this.store
      // @ts-ignore
      .dispatch(this.contractActions.onLaunch())
      .then(() =>
        this.toast.error(`Something went wrong. Contract wasn't launched`)
      )
      .catch(() =>
        this.toast.error(
          'Something went wrong. Try reload the page to see up-to-date information'
        )
      );
  }
}
