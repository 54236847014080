<template lang="pug">
div
  ContractRowItem(
    :locale="locale"
    :html="html"
    :status="status"
    :isPdfLoading="isPdfLoading"
    @downloadPdf="downloadPdf"
  )
  //- PDF Download Failed
  v-snackbar(
    v-model="isPdfDownloadError"
    color="error"
    bottom
    :timeout="3000"
  ) Document download failed
</template>

<script lang="ts">
import Vue from 'vue';
import {
  fetchRow,
  fetchRowPdf,
} from 'Api/endpoints/rights-of-withdrawal/row.endpoint';
import ContractRowItem from '../row-item/contract-row-item.vue';
import {
  ROW_STATUS_LOADING,
  ROW_STATUS_API_ERROR,
  ROW_STATUS_OK,
} from '../row.constants';

export default Vue.extend({
  components: { ContractRowItem },
  props: {
    merchantId: String,
    salesChannelId: String,
    locale: String,
    useDraft: Boolean,
    fulfillmentType: String,
  },
  data() {
    return {
      html: null,
      status: ROW_STATUS_LOADING,
      isPdfLoading: false,
      isPdfDownloadError: false,
    };
  },
  mounted() {
    fetchRow(
      this.merchantId,
      this.salesChannelId,
      this.locale,
      this.useDraft,
      this.fulfillmentType
    )
      .then((data) => {
        this.html = data;
        this.status = ROW_STATUS_OK;
      })
      .catch(() => (this.status = ROW_STATUS_API_ERROR));
  },
  methods: {
    downloadPdf() {
      this.isPdfLoading = true;
      fetchRowPdf(
        this.merchantId,
        this.salesChannelId,
        this.locale,
        this.useDraft,
        this.fulfillmentType
      )
        .catch(() => (this.isPdfDownloadError = true))
        .finally(() => (this.isPdfLoading = false));
    },
  },
});
</script>

<style lang="scss" scoped></style>
