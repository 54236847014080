import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "caption disabled--text ml-6 pt-4" }, [
        _vm._v("Merchant Account"),
      ]),
      _c(
        VSheet,
        {
          staticClass: "my-1 mx-2",
          attrs: {
            "min-height": "40px",
            color: !_vm.selectedLegalEntityId ? "primaryLight" : "",
          },
        },
        [
          _c(
            VFlex,
            {
              staticClass: "subtitle-1 primary--text py-2 ml-4 align-center",
              class: { "font-weight-bold": !_vm.selectedLegalEntityId },
              attrs: { "d-flex": "" },
            },
            [
              _c(
                "a",
                {
                  class: {
                    "primary--text": !_vm.selectedLegalEntityId,
                    "secondary--text": !!_vm.selectedLegalEntityId,
                  },
                  attrs: {
                    href: "/#/merchant-accounts/" + _vm.merchantAccount.id,
                  },
                },
                [_vm._v(_vm._s(_vm.merchantAccount.name))]
              ),
            ]
          ),
        ],
        1
      ),
      _c(VDivider, { attrs: { light: "" } }),
      _c(
        "div",
        { staticClass: "mt-6" },
        [
          _c("div", { staticClass: "caption disabled--text mb-4 ml-6" }, [
            _vm._v("Legal Entities"),
          ]),
          _vm._l(_vm.merchantAccount.legalEntities, function ({ name, id }) {
            return _c(
              VSheet,
              {
                key: id,
                staticClass: "mx-2 my-1",
                attrs: {
                  "min-height": "40px",
                  color: _vm.selectedLegalEntityId === id ? "primaryLight" : "",
                },
              },
              [
                _c(
                  VFlex,
                  {
                    staticClass:
                      "subtitle-1 primary--text py-2 ml-4 align-center",
                    class: {
                      "font-weight-bold": _vm.selectedLegalEntityId === id,
                    },
                    attrs: { "d-flex": "" },
                  },
                  [
                    _c(
                      "a",
                      {
                        class: {
                          "primary--text": _vm.selectedLegalEntityId === id,
                          "secondary--text": _vm.selectedLegalEntityId !== id,
                        },
                        attrs: { href: "/#/legal-entities/" + id },
                      },
                      [_vm._v(_vm._s(name))]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }