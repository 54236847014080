import { getToken, isAuthenticated } from '@/authentication/token.helper';

export function IamAuthenticationErrorService() {
  function McIamAuthenticationError(message) {
    this.message = message || 'User is not authenticated';

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(this.message).stack;
    }
  }

  McIamAuthenticationError.prototype = Object.create(Error.prototype);
  McIamAuthenticationError.prototype.name = 'McIamAuthenticationError';
  McIamAuthenticationError.prototype.message = '';

  McIamAuthenticationError.prototype.constructor = McIamAuthenticationError;

  return McIamAuthenticationError;
}

export function IamAuthorizationError() {
  function McIamAuthorizationError(message, errorStateType) {
    this.message = message || 'User is not authorized';
    this.errorStateType = errorStateType || 'FORBIDDEN';

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(this.message).stack;
    }
  }

  McIamAuthorizationError.prototype = Object.create(Error.prototype);
  McIamAuthorizationError.prototype.name = 'McIamAuthorizationError';
  McIamAuthorizationError.prototype.message = '';
  McIamAuthorizationError.prototype.getErrorStateType = function () {
    return this.errorStateType;
  };
  McIamAuthorizationError.prototype.constructor = McIamAuthorizationError;

  return McIamAuthorizationError;
}

export function IamFirewallService(
  mcLoadingScreenPromiseTracker,
  mcIamCheckRoles,
  McIamAuthorizationError,
  McIamAuthenticationError,
  McAuthentication,
  McIamAuthToken,
  $log,
  $q
) {
  function authorize(rule) {
    const userCurrentRole = getToken().getCurrentRole();
    // check role
    if (rule.roles && rule.roles.indexOf(userCurrentRole) < 0) {
      let rolesString = '';

      if (angular.isArray(rule.roles)) {
        rolesString = '(' + rule.roles.join(', ') + ')';
      } else if (angular.isString(rule.roles)) {
        rolesString = '(' + rule.roles + ')';
      } else if (rule.roles instanceof RegExp) {
        rolesString = '( dont\'t match "' + rule.roles.toString() + '")';
      }

      return $q.reject(
        new McIamAuthorizationError(
          'You ' +
            "don't have required roles to access that page. " +
            rolesString
        )
      );
    }
    return $q.when(getToken());
  }

  function checkCurrentRole() {
    const authToken = getToken();
    const roles = authToken.getRoles();

    // check number of roles
    if (roles.length < 1) {
      // logout the user
      return McAuthentication.logout().then(function () {
        return $q.reject(
          new McIamAuthorizationError(
            'No roles for merchant profile access',
            'UNAUTHORIZED'
          )
        );
      });
    } else if (roles.length === 1) {
      // exactly one role, set as current:
      authToken.setCurrentRole(roles[0]);
    } else {
      // more than one role, check for preferred role (the one from the cookies)
      const preferredRole = authToken.getPreferredRole();

      authToken.setCurrentRole(
        roles.indexOf(preferredRole) > -1 ? preferredRole : roles[0]
      );
    }

    return $q.when(authToken);
  }

  return {
    isGranted: function (rule) {
      // user is already authenticated don't call sc APIs to verify
      if (isAuthenticated()) {
        return mcLoadingScreenPromiseTracker.addPromise(
          McAuthentication.verifyToken()
            .then(() => authorize(rule))
            .catch((error) => {
              if (error instanceof McIamAuthorizationError) {
                // user is authenticated but not authorized
                return $q.reject(error);
              }

              return McAuthentication.refreshToken()
                .then(() => authorize(rule))
                .catch((refreshTokenError) => {
                  if (refreshTokenError instanceof McIamAuthorizationError) {
                    // user is authenticated but not authorized
                    return $q.reject(refreshTokenError);
                  }
                  return $q.reject(new McIamAuthenticationError());
                });
            })
        );
      }

      return mcLoadingScreenPromiseTracker.addPromise(
        McAuthentication.authenticate()
          .then(() => checkCurrentRole())
          .then(() => authorize(rule))
          .catch((error) => {
            $log.error(
              'mcIamFirewall: Error while checking for authenticated/authorized user.',
              error
            );
            return $q.reject(
              error instanceof McIamAuthenticationError ||
                error instanceof McIamAuthorizationError
                ? error
                : new McIamAuthenticationError()
            );
          })
      );
    },
  };
}

IamFirewallService.$inject = [
  'mcLoadingScreenPromiseTracker',
  'mcIamCheckRoles',
  'McIamAuthorizationError',
  'McIamAuthenticationError',
  'McAuthentication',
  'McIamAuthToken',
  '$log',
  '$q',
];
