import loadingModule from '../loading/loading.module';
import configModule from '../mc-config/config.module';
import { AuthenticationService } from './authentication.service';
import { IamAuthTokenService } from './iam-auth-token.service';
import {
  IamFirewallService,
  IamAuthorizationError,
  IamAuthenticationErrorService,
} from './iam-firewall.service';
import { IamCheckRolesService } from './iam-roles-check';

export default angular
  .module('mc.authentication', [
    configModule.name,
    loadingModule.name,
    'mcb.core.util',
    'mcb.components.firewall',
    'mcb.components.toast',
  ])
  .factory('McAuthentication', AuthenticationService)
  .factory('McIamAuthToken', IamAuthTokenService)
  .factory('mcIamFirewall', IamFirewallService)
  .factory('McIamAuthorizationError', IamAuthorizationError)
  .factory('McIamAuthenticationError', IamAuthenticationErrorService)
  .factory('mcIamCheckRoles', IamCheckRolesService)
  .config([
    'mcbFirewallProvider',
    (mcbFirewallProvider) => {
      mcbFirewallProvider.add('iam', 'mcIamFirewall');
    },
  ]);
