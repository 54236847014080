<template lang="pug">
  div.pb-3
    div.d-flex.justify-space-between
      div.field.icon-field
        ReviewStatusIcon(
          :status="details.status"
          :manual-review-required="showNameMismatchNote"
        )
        div.label.text-caption.text-uppercase Currency
        div {{details.currencyCode}}

      div(v-if="isStatusSelectorVisible")
        StatusSelector(
          :selected-status="details.selectedStatus"
          @on-status-select="$emit('on-status-change', $event)"
        )

    div.field.align-start(v-if="!hideSalesChannels")
      div.label.text-caption.text-uppercase Sales Channel ID
      div
        div(v-for="(salesChannelId) in details.salesChannelIds")
          div {{salesChannelId}}

    div.field
      div.label.text-caption.text-uppercase IBAN
      div {{details.iban}}

    div.field
      div.label.text-caption.text-uppercase Account holder
      div {{details.accountHolder}}

    div.field
      div.label.text-caption.text-uppercase Banking Country
      div {{country || '-'}}

    div.field(v-if="details.confirmationDocumentId")
      div.label.text-caption.text-uppercase Confirmation Document
      ConfirmationCertificate(:certificate-id="details.confirmationDocumentId")

    p.orange--text.text-caption.mt-2.mb-0(
      v-if="showNameMismatchNote"
    )
      span.font-weight-bold.mr-2
        | Note:
      span
        | Account holder name needs to be validated manually and compared against the confirmation document.

    v-card(
      v-if="isDataRejected"
      outlined
    ).px-4.pt-4
      div.mb-4.text-caption.font-weight-bold Please provide exact reasons for your rejection and the next steps for partners:
      v-textarea(
        no-resize
        outlined
        persistent-hint
        height="80"
        placeholder="Write your reasons and next steps in detail"
        :value="details.providedRejectionReason"
        :hint="'Please specify at least '+minWordsCount+' words'"
        v-on:change="$emit('on-rejection-reason-change', $event)"
      )

</template>

<script lang="ts">
import Vue from 'vue';
import ConfirmationCertificate from 'Merchant/common/components/bank-account-details/confirmation-certificate.vue';
import { COUNTRIES } from 'Common/constants/misc.constant';
import {
  ApprovalStatusT,
  SelectableReviewStatusT,
} from 'Api/models/ApprovalStatus';
import { BankAccountInfoT } from 'Api/models/BankAccount';
import ReviewStatusIcon from 'Merchant/common/components/review-status-icon/review-status-icon.vue';
import StatusSelector from 'Merchant/common/components/review-status-selector/review-status-selector.vue';
import { REJECTION_REASON_MIN_WORD_COUNT } from 'Merchant/general/components/company-details-approval/util';

export interface BankAccountListItemT extends BankAccountInfoT {
  id: string;
  selectedStatus?: SelectableReviewStatusT;
  salesChannelIds: string[];
  providedRejectionReason: string;
}

export default Vue.extend({
  components: { ReviewStatusIcon, ConfirmationCertificate, StatusSelector },
  props: {
    details: {
      type: Object as () => BankAccountListItemT,
      required: true,
    },
    legalEntityName: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    hideSalesChannels: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      minWordsCount: REJECTION_REASON_MIN_WORD_COUNT,
      showNameMismatchNote:
        this.details.accountHolder !== this.legalEntityName && !this.readonly,
    };
  },

  computed: {
    country() {
      const country = COUNTRIES.find(
        (country) => country.code === this.details.countryCode
      );

      return country?.name || '';
    },
    isStatusSelectorVisible() {
      return (
        this.details.status === ApprovalStatusT.IN_REVIEW && !this.readonly
      );
    },
    isDataRejected() {
      return this.details.selectedStatus === ApprovalStatusT.REJECTED;
    },
  },
});
</script>

<style lang="scss" scoped>
.icon-field {
  margin-left: -32px;
}
</style>
