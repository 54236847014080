import { VApp } from 'vuetify/lib/components/VApp';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(VApp, { staticClass: "root" }, [
    _c("h2", { staticClass: "dc-h2" }, [_vm._v("Select sales channels")]),
    _c(
      "form",
      { attrs: { novalidate: "" } },
      _vm._l(_vm.retailers, function (retailer) {
        return _c("div", [
          _c("h3", { staticClass: "dc-h3" }, [
            _vm._v(" " + _vm._s(retailer.name)),
          ]),
          _vm.isFormControlDisabled(retailer)
            ? _c("div", { staticClass: "dc-msg dc-msg--warning" }, [
                _c("div", { staticClass: "dc-msg__inner" }, [
                  _c("div", { staticClass: "dc-msg__icon-frame" }, [
                    _c("i", {
                      staticClass: "dc-icon dc-msg__icon dc-icon--warning",
                    }),
                  ]),
                  _c("div", { staticClass: "dc-msg__bd" }, [
                    _c("h1", { staticClass: "dc-msg__title" }, [
                      _vm._v(
                        "Sales channels are disabled for merchants created through Salesforce. Please use Salesforce to automatically add the sales channels."
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "channels" },
            [
              _c(
                "div",
                { staticClass: "channels__header" },
                [
                  _c("div", { staticClass: "channels__1col" }),
                  _vm._l(_vm.fulfillmentTypes, function (fulfillmentType) {
                    return _c("div", { staticClass: "channels__col" }, [
                      _c("div", [_vm._v(_vm._s(fulfillmentType.name))]),
                      !_vm.isFormControlDisabled(retailer)
                        ? _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "dc-link",
                                attrs: { href: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onSelectFType(
                                      retailer,
                                      fulfillmentType
                                    )
                                  },
                                },
                              },
                              [_vm._v("All")]
                            ),
                            _c("span", [_vm._v(" / ")]),
                            _c(
                              "button",
                              {
                                staticClass: "dc-link",
                                attrs: { href: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDeselectFType(
                                      retailer,
                                      fulfillmentType
                                    )
                                  },
                                },
                              },
                              [_vm._v("None")]
                            ),
                          ])
                        : _vm._e(),
                    ])
                  }),
                ],
                2
              ),
              _vm._l(
                _vm.sortChannelsByCountryName(retailer.salesChannels),
                function (salesChannel) {
                  return _c(
                    "div",
                    {
                      key: retailer.$id + salesChannel.$id,
                      staticClass: "channel",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "channels__1col" },
                        [
                          salesChannel.countryCode.indexOf(",") === -1
                            ? _c("img", {
                                staticClass: "channel__flag",
                                attrs: {
                                  src:
                                    _vm.imagesPath +
                                    "/flags/flag--" +
                                    salesChannel.countryCode +
                                    ".svg",
                                  width: "26",
                                  height: "17",
                                },
                              })
                            : _vm._e(),
                          _vm._l(
                            salesChannel.countryCode.split(","),
                            function (countryCode) {
                              return salesChannel.countryCode.indexOf(",") > -1
                                ? _c("img", {
                                    key: countryCode,
                                    staticClass: "channel__flag",
                                    attrs: {
                                      src:
                                        _vm.imagesPath +
                                        "/flags/flag--" +
                                        countryCode +
                                        ".svg",
                                      width: "26",
                                      height: "17",
                                    },
                                  })
                                : _vm._e()
                            }
                          ),
                          _c("span", { staticClass: "channel__name" }, [
                            _vm._v(" " + _vm._s(salesChannel.countryName)),
                          ]),
                        ],
                        2
                      ),
                      _vm._l(_vm.fulfillmentTypes, function (fulfillmentType) {
                        return _c(
                          "div",
                          {
                            key:
                              retailer.$id +
                              salesChannel.$id +
                              fulfillmentType.$id,
                            staticClass: "channels__col-val-container",
                          },
                          [
                            _c(VCheckbox, {
                              staticClass: "channels__col-val",
                              attrs: {
                                ripple: false,
                                "hide-details": true,
                                "input-value": _vm.isSelected(
                                  retailer.$id,
                                  salesChannel.$id,
                                  fulfillmentType.$id
                                ),
                                disabled:
                                  _vm.isDisabled(
                                    retailer.$id,
                                    salesChannel.$id,
                                    fulfillmentType.$id
                                  ) || _vm.isFormControlDisabled(retailer),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onContractToggle(
                                    retailer.$id,
                                    salesChannel.$id,
                                    fulfillmentType.$id
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                }
              ),
            ],
            2
          ),
          _c("hr", { staticClass: "dc-divider" }),
        ])
      }),
      0
    ),
    _vm.retailers.length > 1 || !_vm.shouldDisableZalandoRetailControls
      ? _c("div", { staticClass: "channels-buttons" }, [
          _c(
            "button",
            {
              staticClass: "dc-btn dc-btn--primary",
              class: { "dc-btn--disabled": _vm.isSubmitEnabled },
              attrs: { disabled: _vm.isSubmitEnabled },
              on: { click: _vm.onSave },
            },
            [_vm._v("Save sales channels")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }