<template lang="pug">
  div.container
    v-snackbar(v-model="showError" :timeout="5000" color="reject" bottom right)
      | {{errorMessage}}

    form(novalidate).ma-5
      div.heading.text-h6.font-weight-regular.mb-3 Legal entity address
      div.mcp-admin__form-field
        label.dc-label.dc-label--required.mt-2(
          for="legal-entity-field-name"
          :class="{'dc-label--is-error': !isNameValid}"
        ) Legal entity name

        div.mcp-admin__field-error-message(role="alert" v-if="!isNameValid")
          span.dc--text-error Please don't leave this empty.

        input.dc-input.dc-input--block(
          type="text"
          :class="{'dc-input--is-error': !isNameValid}"
          v-model.trim="formData.name"
          name="legal-entity-name"
          id="legal-entity-field-name"
          required
        )
      div.mcp-admin__form-field
        label.dc-label.dc-label--required.mt-2(
          for="legal-entity-field-address-line-1"
          :class="{'dc-label--is-error': !isAddressLine1Valid}"
        ) Street and house number

        div.mcp-admin__field-error-message(role="alert" v-if="!isAddressLine1Valid")
          span.dc--text-error Please don't leave this empty.

        input.dc-input.dc-input--block(
          type="text"
          :class="{'dc-input--is-error': !isAddressLine1Valid }"
          v-model.trim="formData.addressLine1"
          name="street-and-house"
          id="legal-entity-field-address-line-1"
          required
        )
      div.mcp-admin__form-field
        label.dc-label.mt-2(
          for="legal-entity-field-address-line-2"
        ) Address Line 2

        input.dc-input.dc-input--block(
          type="text"
          v-model.trim="formData.addressLine2"
          name="address-line-2"
          id="legal-entity-field-address-line-2"
          required
        )
      div.d-flex
        div.mcp-admin__form-field.flex-grow-1
          label.dc-label.dc-label--required.mt-2(
            for="legal-entity-field-zip"
            :class="{'dc-label--is-error': !isZipCodeValid}"
          ) Zip

          div.mcp-admin__field-error-message(role="alert" v-if="!isZipCodeValid")
            span.dc--text-error Please don't leave this empty.

          input.dc-input.dc-input--block(
            type="text"
            :class="{'dc-input--is-error': !isZipCodeValid }"
            v-model.trim="formData.zipCode"
            name="zip-code"
            id="legal-entity-field-zip"
            required
          )
        div.mcp-admin__form-field.flex-grow-1.ml-3
          label.dc-label.dc-label--required.mt-2(
            for="legal-entity-field-city"
            :class="{'dc-label--is-error': !isCityValid}"
          ) City

          div.mcp-admin__field-error-message(role="alert" v-if="!isCityValid")
            span.dc--text-error Please don't leave this empty.

          input.dc-input.dc-input--block(
            type="text"
            :class="{'dc-input--is-error': !isCityValid }"
            v-model.trim="formData.city"
            name="city"
            id="legal-entity-field-city"
            required
          )
      div.mcp-admin__form-field
        label.dc-label.dc-label--required.mt-2(
          for="legal-entity-field-country"
          :class="{'dc-label--is-error': !isCountryCodeValid}"
        ) Country

        div.mcp-admin__field-error-message(role="alert" v-if="!isCountryCodeValid")
          span.dc--text-error Please don't leave this empty.

        select.dc-select.mcp-admin-merchant-details-select(
          :class="{'dc-input--is-error': !isCountryCodeValid}"
          v-model.trim="formData.countryCode"
          name="country-code"
          id="legal-entity-field-country"
          required
        )
          option(v-for="country in countries" :value="country.code") {{country.name}}

      v-divider.my-6

      div.heading.text-h6.font-weight-regular.mb-3 Signer details
      div.text-body-1.mb-2
        | Signer 1:
      div.d-flex
        div.mcp-admin__form-field.flex-grow-1
          label.dc-label.dc-label--required.mt-2(
            for="signer1-field-first-name"
            :class="{'dc-label--is-error': !isSigner1FirstNameValid}"
          ) First name

          div.mcp-admin__field-error-message(role="alert" v-if="!isSigner1FirstNameValid")
            span.dc--text-error Please don't leave this empty.

          input.dc-input.dc-input--block(
            type="text"
            :class="{'dc-input--is-error': !isSigner1FirstNameValid}"
            v-model.trim="formData.signer1FirstName"
            name="signer1-first-name"
            id="signer1-field-first-name"
            required
          )
        div.mcp-admin__form-field.flex-grow-1.ml-3
          label.dc-label.dc-label--required.mt-2(
            for="signer1-field-last-name"
            :class="{'dc-label--is-error': !isSigner1LastNameValid}"
          ) Last name

          div.mcp-admin__field-error-message(role="alert" v-if="!isSigner1LastNameValid")
            span.dc--text-error Please don't leave this empty.

          input.dc-input.dc-input--block(
            type="text"
            :class="{'dc-input--is-error': !isSigner1LastNameValid}"
            v-model.trim="formData.signer1LastName"
            name="signer1-last-name"
            id="signer1-field-last-name"
            required
          )

      div.d-flex
        div.mcp-admin__form-field.flex-grow-1
          label.dc-label.dc-label--required.mt-2(
            for="signer1-field-title"
            :class="{'dc-label--is-error': !isSigner1TitleValid}"
          ) Signer Title

          div.mcp-admin__field-error-message(role="alert" v-if="!isSigner1TitleValid")
            span.dc--text-error Please don't leave this empty.

          input.dc-input.dc-input--block(
            type="text"
            :class="{'dc-input--is-error': !isSigner1TitleValid}"
            v-model.trim="formData.signer1Title"
            name="signer1-title"
            id="signer1-field-title"
            required
          )
        div.mcp-admin__form-field.flex-grow-1.ml-3
          label.dc-label.dc-label--required.mt-2(
            for="signer1-field-email"
            :class="{'dc-label--is-error': !isSigner1EmailValid}"
          ) Signer Email

          div.mcp-admin__field-error-message(role="alert" v-if="!isSigner1EmailValid")
            span.dc--text-error Please add correct email.

          input.dc-input.dc-input--block(
            type="text"
            :class="{'dc-input--is-error': !isSigner1EmailValid}"
            v-model.trim="formData.signer1Email"
            name="signer1-email"
            id="signer1-field-email"
            required
          )

      v-divider.my-6

      div.text-body-1.mb-2
        | Signer 2 (optional):
      div.d-flex
        div.mcp-admin__form-field.flex-grow-1
          label.dc-label.mt-2(
            for="signer2-field-first-name"
            :class="{'dc-label--is-error': !isSigner2FirstNameValid, 'dc-label--required': isSigner2FieldsRequired}"
          ) First name

          div.mcp-admin__field-error-message(role="alert" v-if="!isSigner2FirstNameValid")
            span.dc--text-error Please don't leave this empty.

          input.dc-input.dc-input--block(
            type="text"
            :class="{'dc-input--is-error': !isSigner2FirstNameValid}"
            v-model.trim="formData.signer2FirstName"
            name="signer2-first-name"
            id="signer2-field-first-name"
            required
          )
        div.mcp-admin__form-field.flex-grow-1.ml-3
          label.dc-label.mt-2(
            for="signer2-field-last-name"
            :class="{'dc-label--is-error': !isSigner2LastNameValid, 'dc-label--required': isSigner2FieldsRequired}"
          ) Last name

          div.mcp-admin__field-error-message(role="alert" v-if="!isSigner2LastNameValid")
            span.dc--text-error Please don't leave this empty.

          input.dc-input.dc-input--block(
            type="text"
            :class="{'dc-input--is-error': !isSigner2LastNameValid}"
            v-model.trim="formData.signer2LastName"
            name="signer2-last-name"
            id="signer2-field-last-name"
            required
          )

      div.d-flex
        div.mcp-admin__form-field.flex-grow-1
          label.dc-label.mt-2(
            for="signer2-field-title"
            :class="{'dc-label--is-error': !isSigner2TitleValid, 'dc-label--required': isSigner2FieldsRequired}"
          ) Signer Title

          div.mcp-admin__field-error-message(role="alert" v-if="!isSigner2TitleValid")
            span.dc--text-error Please don't leave this empty.

          input.dc-input.dc-input--block(
            type="text"
            :class="{'dc-input--is-error': !isSigner2TitleValid}"
            v-model.trim="formData.signer2Title"
            name="signer2-title"
            id="signer2-field-title"
            required
          )
        div.mcp-admin__form-field.flex-grow-1.ml-3
          label.dc-label.mt-2(
            for="signer2-field-email"
            :class="{'dc-label--is-error': !isSigner2EmailValid, 'dc-label--required': isSigner2FieldsRequired}"
          ) Signer Email

          div.mcp-admin__field-error-message(role="alert" v-if="!isSigner2EmailValid")
            span.dc--text-error Please add correct email.

          input.dc-input.dc-input--block(
            type="text"
            :class="{'dc-input--is-error': !isSigner2EmailValid}"
            v-model.trim="formData.signer2Email"
            name="signer2-email"
            id="signer2-field-email"
            required
          )

      div.mcp-admin__form-field.d-inline-block
        label.dc-label.dc-label--required.mt-2(
          for="legal-entity-field-address-cert"
          :class="{'dc-label--is-error': !isAddressCertificateIdValid}"
        ) Address Proof

        div.mcp-admin__field-error-message(role="alert" v-if="!isAddressCertificateIdValid")
          span.dc--text-error Please don't leave this empty.

        div(v-if="isUploadingAddressCertificate" ).dc-spinner.dc-spinner--small

        div(v-else)
          input.dc-input.dc-input--block(
            accept="application/pdf"
            v-if="!formData.addressCertificateId"
            type="file"
            :class="{'dc-input--is-error': !isAddressCertificateIdValid}"
            name="address-cert"
            id="legal-entity-field-address-cert"
            required
            @change="onAddressCertificateUpload"
          )
          div.d-flex.align-center(v-else)
            VatCertificate(
              :certificate-id="formData.addressCertificateId"
            )
            v-btn(
              icon
              @click="formData.addressCertificateId = ''"
            )
              v-icon.ml-3 mdi-trash-can-outline

      v-divider.my-6
      div.heading.text-h6.font-weight-regular.mb-3 Legal VAT details
      div.mcp-admin__form-field.width-half
          label.dc-label.dc-label--required.mt-2(
            for="legal-entity-field-vat-id"
            :class="{'dc-label--is-error': !isVatIdValid}"
          ) Company Value Added Tax (VAT)

          div.mcp-admin__field-error-message(role="alert" v-if="!isVatIdValid")
            span.dc--text-error Please don't leave this empty.

          input.dc-input.dc-input--block(
            type="text"
            :class="{'dc-input--is-error': !isVatIdValid}"
            v-model.trim="formData.vatId"
            name="vat-id"
            id="legal-entity-field-vat-id"
            required
          )
      div.mcp-admin__form-field.d-inline-block
        label.dc-label.dc-label--required.mt-2(
          for="legal-entity-field-vat-cert"
          :class="{'dc-label--is-error': !isVatCertificateIdValid}"
        ) Vat Id Proof

        div.mcp-admin__field-error-message(role="alert" v-if="!isVatCertificateIdValid")
          span.dc--text-error Please don't leave this empty.

        div(v-if="isUploadingVatCertificate" ).dc-spinner.dc-spinner--small

        div(v-else)
          input(
            accept="application/pdf"
            v-if="!formData.certificateId"
            type="file"
            :class="{'dc-input--is-error': !isVatCertificateIdValid}"
            name="address-cert"
            id="legal-entity-field-vat-cert"
            @change="onVatCertificateUpload"
            required
          )
          div.d-flex.align-center(v-else)
            VatCertificate(
              :certificate-id="formData.certificateId"
            )
            v-btn(
              icon
              @click="formData.certificateId = ''"
            )
              v-icon.ml-3 mdi-trash-can-outline

    div.d-flex.justify-end.mr-5
      button.dc-btn.dc-btn--link(
        @click="$emit('on-cancel')"
      ) Cancel
      button.dc-btn.dc-btn--primary(
        type="submit"
        v-on:click="onSave"
      ) Update

</template>

<script lang="ts">
import Vue from 'vue';
import { FACTORING_TWO_ALLOWED_COUNTRIES } from 'Common/constants/misc.constant';
import { LegalEntityDetailsT } from 'Api/models/LegalEntityDetails';
import { updatePartnerLegalEntityDetails } from 'Api/endpoints/legal-entity/legal-entity.endpoint';
import VatCertificate from 'Merchant/common/components/vat-section/vat-certificate.vue';
import { uploadCertificate } from 'Api/endpoints/legal-entity/vat-certificate.endpoint';
import { VatCertificateMetaDataResponseT } from 'Api/models/Vat';

enum FormFieldsT {
  name = 'name',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  countryCode = 'countryCode',
  zipCode = 'zipCode',
  addressCertificateId = 'addressCertificateId',
  signer1FirstName = 'signer1FirstName',
  signer1LastName = 'signer1LastName',
  signer1Title = 'signer1Title',
  signer1Email = 'signer1Email',
  signer2FirstName = 'signer2FirstName',
  signer2LastName = 'signer2LastName',
  signer2Title = 'signer2Title',
  signer2Email = 'signer2Email',
  vatId = 'vatId',
  certificateId = 'certificateId',
}

type FormDataT = {
  [key in FormFieldsT]: string;
};

const CERTIFICATE_FILE_SIZE_LARGE =
  'File size exceeds. Please select a PDF file with a max size of 10 MB';
const DATA_UPDATE_ERROR = 'Failed to update the legal entity data.';
const CERTIFICATE_UPLOAD_ERROR = 'Failed to upload certificate.';

export default Vue.extend({
  components: { VatCertificate },
  props: {
    legalEntityId: {
      type: String,
      required: true,
    },
    details: {
      type: Object as () => LegalEntityDetailsT,
      required: true,
    },
  },
  data() {
    const { name, address, vat, signers = [] } = this.details;

    const data = {
      name: name?.value,
      addressLine1: address?.addressLine1,
      addressLine2: address?.addressLine2,
      city: address?.city,
      countryCode: address?.countryCode,
      zipCode: address?.zipCode,
      addressCertificateId: address?.certificateId,
      signer1FirstName: signers.length > 0 ? signers[0].firstName : '',
      signer1LastName: signers.length > 0 ? signers[0].lastName : '',
      signer1Title: signers.length > 0 ? signers[0].title : '',
      signer1Email: signers.length > 0 ? signers[0].email : '',
      signer2FirstName: signers.length > 1 ? signers[1].firstName : '',
      signer2LastName: signers.length > 1 ? signers[1].lastName : '',
      signer2Title: signers.length > 1 ? signers[1].title : '',
      signer2Email: signers.length > 1 ? signers[1].email : '',
      vatId: vat?.vatId,
      certificateId: vat?.certificateId,
    } as FormDataT;

    return {
      formData: data,
      showFormErrors: false,
      countries: FACTORING_TWO_ALLOWED_COUNTRIES,
      showError: false,
      errorMessage: '',
      signer2Fields: [
        FormFieldsT.signer2FirstName,
        FormFieldsT.signer2LastName,
        FormFieldsT.signer2Title,
        FormFieldsT.signer2Email,
      ],
      isUploadingAddressCertificate: false,
      isUploadingVatCertificate: false,
    };
  },
  computed: {
    isMoreThanOneSigner(): boolean {
      return this.details.signers.length > 1;
    },
    isSigner2FieldsRequired(): boolean {
      if (!this.formData) {
        return false;
      }
      return this.signer2Fields.some((fieldName) => {
        return this.formData[fieldName];
      });
    },
    isNameValid(): boolean {
      return this.isInputValid(FormFieldsT.name);
    },
    isAddressLine1Valid(): boolean {
      return this.isInputValid(FormFieldsT.addressLine1);
    },
    isAddressLine2Valid(): boolean {
      return this.isInputValid(FormFieldsT.addressLine2);
    },
    isCityValid(): boolean {
      return this.isInputValid(FormFieldsT.city);
    },
    isCountryCodeValid(): boolean {
      return this.isInputValid(FormFieldsT.countryCode);
    },
    isZipCodeValid(): boolean {
      return this.isInputValid(FormFieldsT.zipCode);
    },
    isAddressCertificateIdValid(): boolean {
      return this.isInputValid(FormFieldsT.addressCertificateId);
    },
    isVatIdValid(): boolean {
      return this.isInputValid(FormFieldsT.vatId);
    },
    isVatCertificateIdValid(): boolean {
      return this.isInputValid(FormFieldsT.certificateId);
    },
    isSigner1FirstNameValid(): boolean {
      return this.isInputValid(FormFieldsT.signer1FirstName);
    },
    isSigner1LastNameValid(): boolean {
      return this.isInputValid(FormFieldsT.signer1LastName);
    },
    isSigner1TitleValid(): boolean {
      return this.isInputValid(FormFieldsT.signer1Title);
    },
    isSigner1EmailValid(): boolean {
      return (
        this.isInputValid(FormFieldsT.signer1Email) &&
        this.isEmailValid(FormFieldsT.signer1Email)
      );
    },
    isSigner2FirstNameValid(): boolean {
      return this.isSigner2FieldsRequired
        ? this.isInputValid(FormFieldsT.signer2FirstName)
        : true;
    },
    isSigner2LastNameValid(): boolean {
      return this.isSigner2FieldsRequired
        ? this.isInputValid(FormFieldsT.signer2LastName)
        : true;
    },
    isSigner2TitleValid(): boolean {
      return this.isSigner2FieldsRequired
        ? this.isInputValid(FormFieldsT.signer2Title)
        : true;
    },
    isSigner2EmailValid(): boolean {
      return this.isSigner2FieldsRequired
        ? this.isInputValid(FormFieldsT.signer2Email) &&
            this.isEmailValid(FormFieldsT.signer2Email)
        : true;
    },
  },

  methods: {
    isInputValid(fieldName: FormFieldsT): boolean {
      if (!this.showFormErrors) return true;
      const fieldValue = this.formData[fieldName];

      return !!fieldValue && fieldValue.trim() !== '';
    },
    isEmailValid(fieldName: FormFieldsT): boolean {
      if (!this.showFormErrors) return true;
      const fieldValue = this.formData[fieldName];
      const re =
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      return re.test(fieldValue);
    },
    isSigner2Valid(): boolean {
      return this.isSigner2FieldsRequired
        ? this.isSigner2FirstNameValid &&
            this.isSigner2LastNameValid &&
            this.isSigner2TitleValid &&
            this.isSigner2EmailValid
        : true;
    },
    isFormValid(): boolean {
      const areRequiredFieldsValid =
        this.isNameValid &&
        this.isAddressLine1Valid &&
        this.isAddressLine2Valid &&
        this.isCityValid &&
        this.isCountryCodeValid &&
        this.isZipCodeValid &&
        this.isAddressCertificateIdValid &&
        this.isSigner1FirstNameValid &&
        this.isSigner1LastNameValid &&
        this.isSigner1TitleValid &&
        this.isSigner1EmailValid &&
        this.isVatIdValid &&
        this.isVatCertificateIdValid;

      return areRequiredFieldsValid && this.isSigner2Valid();
    },
    async uploadCertificate(e): Promise<VatCertificateMetaDataResponseT> {
      const file = e.target.files[0];
      if (file) {
        const fileSize = file.size;
        const fileMb = (fileSize / 1048576).toFixed(1) as unknown as number;
        const isFileSizeLarge = fileMb > 10;
        if (isFileSizeLarge) {
          this.showErrorSnackbar(CERTIFICATE_FILE_SIZE_LARGE);
        } else {
          // handle file upload here
          try {
            return await uploadCertificate(this.legalEntityId, file);
          } catch (e) {
            this.showErrorSnackbar(CERTIFICATE_UPLOAD_ERROR);
          }
        }
      }
    },
    async onVatCertificateUpload(e): Promise<void> {
      this.isUploadingVatCertificate = true;
      const metaData = await this.uploadCertificate(e);
      this.formData.certificateId = metaData.certificateId;
      this.isUploadingVatCertificate = false;
    },
    async onAddressCertificateUpload(e): Promise<void> {
      this.isUploadingAddressCertificate = true;
      const metaData = await this.uploadCertificate(e);
      this.formData.addressCertificateId = metaData.certificateId;
      this.isUploadingAddressCertificate = false;
    },
    async onSave(): Promise<void> {
      if (this.isFormValid()) {
        const firstSigner = {
          firstName: this.formData.signer1FirstName,
          lastName: this.formData.signer1LastName,
          email: this.formData.signer1Email,
          title: this.formData.signer1Title,
        };

        const getSecondSigner = () => {
          return this.isSigner2FieldsRequired
            ? [
                {
                  firstName: this.formData.signer2FirstName,
                  lastName: this.formData.signer2LastName,
                  email: this.formData.signer2Email,
                  title: this.formData.signer2Title,
                },
              ]
            : [];
        };

        const data = {
          name: {
            value: this.formData.name,
          },
          address: {
            addressLine1: this.formData.addressLine1,
            addressLine2: this.formData.addressLine2,
            city: this.formData.city,
            countryCode: this.formData.countryCode,
            zipCode: this.formData.zipCode,
            certificateId: this.formData.addressCertificateId,
          },
          signers: [firstSigner, ...getSecondSigner()],
          vat: {
            vatId: this.formData.vatId,
            certificateId: this.formData.certificateId,
          },
        } as LegalEntityDetailsT;
        try {
          await updatePartnerLegalEntityDetails(this.legalEntityId, data);
          this.$emit('on-success');
        } catch (e) {
          this.showErrorSnackbar(DATA_UPDATE_ERROR);
        }
      } else {
        this.showFormErrors = true;
      }
    },
    showErrorSnackbar(errorMessage): void {
      this.showError = true;
      this.errorMessage = errorMessage;
    },
  },
});
</script>

<style lang="scss" scoped>
.heading {
  line-height: 1;
}

.width-half {
  width: 50%;
}

input[type='file'] {
  border: none;
  background-color: inherit;
}
</style>
