export const BILLING_CYCLE_ONCE_A_MONTH = 'P1M';
export const BILLING_CYCLE_TWICE_A_MONTH = 'P1Y1M14D';
export const BILLING_CYCLE_WEEKLY = 'P1Y1M7D';

export const BILLING_CYCLE_MAP = {
  [BILLING_CYCLE_ONCE_A_MONTH]: 'Once a month',
  [BILLING_CYCLE_TWICE_A_MONTH]: 'Twice a month',
  [BILLING_CYCLE_WEEKLY]: 'Weekly',
};

export const BILLING_CYCLE_FORM_OPTIONS = [BILLING_CYCLE_ONCE_A_MONTH].map(
  (value) => ({ value, name: BILLING_CYCLE_MAP[value] })
);
