<template>
  <span><span v-html="highlightedText" /></span>
</template>

<script lang="ts">
import Vue from 'vue';
import _ from 'lodash';

export default Vue.extend({
  name: 'HighlightSubstring',
  props: {
    text: String,
    match: String,
    // Use dresscode prop when the component is used with Dress-code styles
    dresscode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    highlightedText() {
      const text = this.text;
      const match = this.match;
      if (!match || match === '') {
        return text;
      }
      const escapedMatch = _.escapeRegExp(match);
      const regExp = new RegExp(`(${escapedMatch})`, 'gi');
      const style = this.dresscode
        ? 'font-weight: 500; color: #186698'
        : 'font-weight: 600; color: #e65100';

      return text.replace(regExp, `<span style="${style}">$1</span>`);
    },
  },
});
</script>

<style lang="scss" scoped></style>
