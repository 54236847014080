import httpLoadingTracker from './httpLoadingTrackerTool';
import httpLoadingTrackerInterceptor from './httpLoadingTrackerInterceptor';
/**
 * @ngdoc module
 * @name mcb.components.httpLoadingTracker
 */
export default angular
  .module('mcb.components.httpLoadingTracker', [])
  .config([
    '$httpProvider',
    function ($httpProvider) {
      $httpProvider.interceptors.push('mcbHttpLoadingTrackerInterceptor');
    },
  ])
  .factory('mcbHttpLoadingTracker', httpLoadingTracker)
  .factory('mcbHttpLoadingTrackerInterceptor', httpLoadingTrackerInterceptor);
