import { render, staticRenderFns } from "./legal-entities.container.vue?vue&type=template&id=561fa7bf&scoped=true&lang=pug"
import script from "./legal-entities.container.vue?vue&type=script&lang=ts"
export * from "./legal-entities.container.vue?vue&type=script&lang=ts"
import style0 from "./legal-entities.container.vue?vue&type=style&index=0&id=561fa7bf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "561fa7bf",
  null
  
)

export default component.exports