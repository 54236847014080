import axios from 'axios';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import { pipe } from 'ramda';
import ErrorHandler from 'Api/api-errors-handler';
import { BillingModeT } from 'Api/models/BillingMode';

const merchantsApi = axios.create({
  baseURL: '/api/merchant-profile/merchants',
});
merchantsApi.interceptors.request.use(decamelizeRequestHandler);
merchantsApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);
export async function fetchBillingMode(
  merchantId: string
): Promise<BillingModeT> {
  const response: { billingMode: BillingModeT } = await merchantsApi.get(
    `/${merchantId}/billing-mode`
  );

  return response.billingMode;
}

export function updateBillingMode(
  merchantId: string,
  billingMode: BillingModeT
): Promise<void> {
  return merchantsApi.put(`/${merchantId}/billing-mode`, { billingMode });
}
