<template lang="pug">
  v-app.root
    v-snackbar(v-model="showError" :timeout="5000" color="reject" bottom right)
      | Failed to fetch partner contract data

    div.d-flex.justify-center(v-if="isLoading")
      v-progress-circular(
        color="secondaryDark"
        indeterminate
      )

    div(v-else)
      PartnerContractDetailsEditable(
        v-if="canEdit && this.isEditModeEnabled"
        :details="details"
        :legal-entity-id="legalEntityId"
        @on-cancel="toggleEditMode"
        @on-success="handleOnSuccess"
      )
      div(v-else)
        a.edit-button.dc-btn.dc-btn--link.mcp-admin-merchant-button--edit(
          v-if="this.canEdit"
          @click="toggleEditMode"
        )
          span
            | Edit
            i.dc-icon.dc-icon--pencil.dc-icon--btn.dc-icon--btn--left
        PartnerContractDetailsReadonly(
          :details="details"
          :show-name-link="!this.readOnly"
          :legal-entity-id="legalEntityId"
        )

</template>

<script lang="ts">
import Vue from 'vue';
import PartnerContractDetailsReadonly from 'Merchant/general/components/partner-contract-details/partner-contract-details-readonly.vue';
import { fetchPartnerLegalEntityDetails } from 'Api/endpoints/legal-entity/legal-entity.endpoint';
import PartnerContractDetailsEditable from 'Merchant/general/components/partner-contract-details/partner-contract-details-editable.vue';
import { canEnableLegalEntityDataEdit } from '@/feature-flags';
import { LegalEntityDetailsT } from 'Api/models/LegalEntityDetails';

interface StoreStateT {
  legalEntityId: string;
  hasBeenOnboarded: boolean;
}
interface DataT extends StoreStateT {
  isLoading: boolean;
  showError: boolean;
  isEditModeEnabled: boolean;
  details: LegalEntityDetailsT;
}
export default Vue.extend({
  components: {
    PartnerContractDetailsEditable,
    PartnerContractDetailsReadonly,
  },
  props: {
    readOnly: {
      type: Boolean,
      required: true,
    },
  },
  data(): DataT {
    return {
      isLoading: true,
      showError: false,
      details: {} as LegalEntityDetailsT,
      isEditModeEnabled: false,
      // @ts-ignore
      ...(this.mapState({
        legalEntityId: 'flow.general.merchant.data.legalEntityId',
        hasBeenOnboarded: 'flow.general.merchant.data.hasBeenOnboarded',
        contracts: 'flow.general.contracts.data',
      }) as StoreStateT),
    };
  },

  computed: {
    canEdit(): boolean {
      if (this.readOnly) return false;

      return canEnableLegalEntityDataEdit()
        ? this.contracts.length
        : this.hasBeenOnboarded;
    },
  },

  async mounted() {
    await this.fetchLegalEntityData();
  },

  methods: {
    toggleEditMode() {
      this.isEditModeEnabled = !this.isEditModeEnabled;
    },
    async fetchLegalEntityData(): Promise<void> {
      try {
        this.isLoading = true;
        this.details = await fetchPartnerLegalEntityDetails(this.legalEntityId);
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.showError = true;
      }
    },
    async handleOnSuccess(): Promise<void> {
      this.toggleEditMode();
      await this.fetchLegalEntityData();
    },
  },
});
</script>

<style lang="scss" scoped>
.edit-button.dc-btn {
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
  transition: color 0.1s linear;
  color: #26aafe;
  &:hover {
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;
    color: #1e87cb;
  }
}
</style>
