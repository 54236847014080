import { FactoringMigrationStatus } from '@/api/models/FactoringMigrationStatus';

export const FACTORING_MIGRATION_LOADED = 'FACTORING_MIGRATION_LOADED';
export const FACTORING_MIGRATION_LOADING = 'FACTORING_MIGRATION_LOADING';

export interface FactoringMigrationStatusState {
  dataLoading: boolean;
  data: FactoringMigrationStatus;
  error: null;
}

export const initialFMSState: FactoringMigrationStatusState = {
  dataLoading: false,
  data: {} as FactoringMigrationStatus,
  error: null,
};

export const factoringMigrationStatusReducer = (
  state = { ...initialFMSState },
  { type, payload }
) => {
  switch (type) {
    case FACTORING_MIGRATION_LOADING:
      return {
        ...state,
        dataLoading: true,
      };
    case FACTORING_MIGRATION_LOADED:
      return {
        ...state,
        data: payload,
        dataLoading: false,
      };
    default:
      return state;
  }
};
