export class HeaderService {
  static $inject = ['$state'];

  constructor(private $state: ng.ui.IStateService) {
    // no empty body
  }

  getCurrentMerchantAccountId(): string {
    return this.$state.params.merchantAccountId;
  }

  getCurrentLegalEntityId(): string {
    return this.$state.params.legalEntityId;
  }
}
