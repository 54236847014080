<template lang="pug">
v-app#contract-overview.root
  v-container
    ContractOverview(
      :partners="contract.partners"
      :merchant-id="merchant.$id"
      :channel-id="contract.salesChannel.$id"
      :fulfillment-type="contract.fulfillmentType"
      :launch-checks="contractTimeline"
      :is-live="contract.live"
      :is-channel-live="isChannelLive"
      :live-status-modified-at="contract.liveStatusModifiedAt"
      :live-status-modified-by="contract.liveStatusModifiedBy"
      :offline-reason="contract.offlineReason"
      :has-been-launched="arePartnersLaunched"
      :is-launch-enabled="canContractBeLaunched"
      :can-edit="canEdit"
      :country-name="contract.salesChannel.countryName"
      @contract-launch="launch"
    )
    Stocks(
      v-if="arePartnersLaunched"
      :merchant-id="merchant.$id"
      :channel-id="contract.salesChannel.$id"
      :fulfillment-type="contract.fulfillmentType"
    )
</template>

<script lang="ts">
import Vue from 'vue';
import ContractOverview from './contract-overview.vue';
import Stocks from './stocks.vue';
import { Contract } from 'Api/models/Contract';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { ContractRequirements } from 'Api/models/ContractRequirements';
import { MerchantRequirements } from 'Api/models/MerchantRequiremenets';
import { ENV_CURRENT, MCP_FF_TYPES } from 'Common/constants/misc.constant';
import {
  TIMELINE_ELEMENT_STATUSES,
  TIMELINE_STATUS_T,
} from 'Common/constants/timeline-element-statuses.constant';
import {
  CONTRACT_PARTNERS_LAUNCH_STATUSES,
  CONTRACT_REQ_LAUNCH_STATUSES,
  MCP_CONTRACT_STATUSES,
} from 'Common/constants/contract-statuses.constant';
import { MCP_MERCHANT_STATUSES } from 'Common/constants/merchant-statuses.constant';
import {
  fetchContractRequirements,
  markAsLaunched,
} from 'Api/endpoints/contract/contract-new.endpoint';
import permissionsService from 'Common/services/permissions.service';
import { fetchCarriersRequirements } from '@/api/endpoints/sales-channel/carrier.endpoint';
import {
  fetchSalesChannelComplianceDetailsRequirements,
  fetchSalesChannelComplianceStatus,
} from '@/api/endpoints/sales-channel/compliance.endpoint';
import { fetchMerchantComplianceStatus } from '@/api/endpoints/merchant/merchant-compliance.endpoint';
import { canEnableBankDetails, canEnableVatCollection } from '@/feature-flags';
import {
  fetchBankAccountList,
  fetchCurrencySalesChannels,
} from 'Api/endpoints/merchant/merchant-bank-accounts.endpoint';
import { BankAccountInfoT } from 'Api/models/BankAccount';
import {
  findBankAccountByCurrencyCode,
  findCurrencyCodeForSalesChannel,
} from 'Merchant/common/components/billing-and-bank-details-form/util';
import { fetchBillingMode } from 'Api/endpoints/merchant/merchant-billing-mode.endpoint';
import { BillingModeT } from 'Api/models/BillingMode';
import { ApprovalStatusT } from 'Api/models/ApprovalStatus';
import { fetchContacts } from 'Api/endpoints/sales-channel/contact.endpoint';
import { MarketContactT } from 'Api/models/MerchantContact';

interface TimelineElement {
  title: string;
  status: TIMELINE_STATUS_T; // completed|active|failed
  isHidden?: boolean;
  info?: string | null; // Help text to be displayed under the status
}

const launchedLabels = {
  [CONTRACT_REQ_LAUNCH_STATUSES.PENDING]: 'Launched',
  [CONTRACT_REQ_LAUNCH_STATUSES.IN_PROGRESS]: 'Launch in progress',
  [CONTRACT_REQ_LAUNCH_STATUSES.DONE]: 'Launched',
  [CONTRACT_REQ_LAUNCH_STATUSES.FAILED]: 'Launch failed',
  [CONTRACT_REQ_LAUNCH_STATUSES.LOADING]: 'Loading...',
};

const launchedStatusToTimelineStatusMap = {
  [CONTRACT_REQ_LAUNCH_STATUSES.PENDING]: TIMELINE_ELEMENT_STATUSES.PENDING,
  [CONTRACT_REQ_LAUNCH_STATUSES.IN_PROGRESS]:
    TIMELINE_ELEMENT_STATUSES.IN_PROGRESS,
  [CONTRACT_REQ_LAUNCH_STATUSES.DONE]: TIMELINE_ELEMENT_STATUSES.COMPLETED,
  [CONTRACT_REQ_LAUNCH_STATUSES.FAILED]: TIMELINE_ELEMENT_STATUSES.FAILED,
  [CONTRACT_REQ_LAUNCH_STATUSES.LOADING]: TIMELINE_ELEMENT_STATUSES.LOADING,
};
const cepLink =
  ENV_CURRENT === 'PRODUCTION'
    ? 'https://cep-platform-frontend.logistics.zalan.do/partners'
    : 'https://cep-platform-frontend.logistics-test.zalan.do/partners';

interface StoreStateT {
  merchant: MerchantProfile;
  contract: Contract;
  merchantContracts: Contract[];
  merchantRequirements: MerchantRequirements;
  contractRequirementsIsLoading: boolean;
  contractRequirements: ContractRequirements;
}

export default Vue.extend({
  components: { ContractOverview, Stocks },

  props: {
    onLaunchCb: { type: Function, required: true },
    onLaunchErrorCb: { type: Function, required: true },
  },

  data() {
    return {
      isDestroyed: false,
      canEdit: permissionsService.hasEditPermissions(),
      stocksRequirements: null,
      complianceRequirements: null,
      merchantComplianceStatus: null,
      salesChannelComplianceStatus: null,
      bankAccount: null as BankAccountInfoT | null,
      contacts: [] as MarketContactT[],
      // @ts-ignore
      ...(this.mapState({
        merchant: 'flow.general.merchant.data',
        contract: 'flow.specific.contract.data',
        merchantContracts: 'flow.general.contracts.data',
        merchantRequirements: 'flow.general.requirements',
        contractRequirementsIsLoading: 'flow.specific.requirements.loading',
        contractRequirements: 'flow.specific.requirements.data',
      }) as StoreStateT),
    };
  },
  computed: {
    arePartnersLaunched(): boolean {
      return this.contract.partners.every(
        (partner) =>
          partner.partnerLaunchStatus ===
            CONTRACT_PARTNERS_LAUNCH_STATUSES.INACTIVE ||
          partner.partnerLaunchStatus === CONTRACT_PARTNERS_LAUNCH_STATUSES.DONE
      );
    },

    isCompanyDataDefined(): boolean {
      const contractRequirements = this
        .contractRequirements as ContractRequirements;

      return (
        this.contacts.length > 0 && contractRequirements.returnAddressDefined
      );
    },
    isChannelLive(): boolean {
      const channelContracts = this.merchantContracts.filter(
        ({ salesChannelId }) => salesChannelId === this.contract.salesChannelId
      );

      return channelContracts.some(({ live }) => live);
    },
    canContractBeLaunched(): boolean {
      const isBankDetailsChangesEnabled = canEnableBankDetails();
      const contract = this.contract as Contract;
      const merchant = this.merchant as MerchantProfile;
      const merchantRequirements = this
        .merchantRequirements as MerchantRequirements;
      const contractRequirements = this
        .contractRequirements as ContractRequirements;
      const isZFSContract = contract.fulfillmentType === MCP_FF_TYPES.ZFS;

      const isGeneralDataDefined = isBankDetailsChangesEnabled
        ? true
        : merchantRequirements.generalDataDefined;

      const isContractCompanyDataDefined = isBankDetailsChangesEnabled
        ? this.isCompanyDataDefined
        : contractRequirements.returnAddressDefined;

      const isMerchantFinancialStatusApproved = isBankDetailsChangesEnabled
        ? true
        : merchant.status === MCP_MERCHANT_STATUSES.FINANCIAL_APPROVED;

      const isContractFinancialStatusInReviewOrApproved =
        contract.status === MCP_CONTRACT_STATUSES.IN_FINANCIAL_REVIEW ||
        contract.status === MCP_CONTRACT_STATUSES.FINANCIAL_APPROVED;

      const isLaunchNotInProgress =
        contractRequirements.launchStatus !==
        CONTRACT_REQ_LAUNCH_STATUSES.IN_PROGRESS;

      const arePartnerContractsNotLaunched = this.arePartnersLaunched === false;

      return !!(
        isGeneralDataDefined &&
        isContractFinancialStatusInReviewOrApproved &&
        isMerchantFinancialStatusApproved &&
        isLaunchNotInProgress &&
        arePartnerContractsNotLaunched &&
        isContractCompanyDataDefined &&
        merchantRequirements.metadataDefined &&
        contractRequirements.technicalConfigurationDefined &&
        (isZFSContract || this.stocksRequirements?.carrierProductsSpecified)
      );
    },

    contractTimeline(): TimelineElement[] {
      const isBankingDetailsChangesEnabled = canEnableBankDetails();
      const contract = this.contract as Contract;
      const contractRequirementsIsLoading = this
        .contractRequirementsIsLoading as boolean;
      const contractRequirements = this
        .contractRequirements as ContractRequirements;
      const merchantRequirements = this
        .merchantRequirements as MerchantRequirements;
      const isZFSContract = contract.fulfillmentType === MCP_FF_TYPES.ZFS;
      const { LOADING, COMPLETED, PENDING, FAILED, ON_HOLD } =
        TIMELINE_ELEMENT_STATUSES;
      const isFinanciallyApprovedStatus = (() => {
        switch (contract.status) {
          case MCP_CONTRACT_STATUSES.FINANCIAL_APPROVED:
            return COMPLETED;
          case MCP_CONTRACT_STATUSES.FINANCIAL_REJECTED:
            return FAILED;
          default:
            return PENDING;
        }
      })();
      const isProductDeliverySpecifiedStatus = (() => {
        const productDeliveryStatus = contractRequirements.cepConfiguration;
        switch (productDeliveryStatus) {
          case 'COMPLETED':
            return COMPLETED;
          case 'CONFLICT':
            return FAILED;
          default:
            return PENDING;
        }
      })();
      const contractStatus = (() => {
        const { live, isContractHasActiveStock } = contract;
        if (contractRequirementsIsLoading) {
          return ['Loading...', LOADING];
        }
        if (live && isContractHasActiveStock) {
          return ['Online', COMPLETED];
        }
        if (live) {
          return ['On Hold', ON_HOLD];
        }
        return ['Online', PENDING];
      })();

      const bankApprovalDisplayStatus = {
        [ApprovalStatusT.PENDING]: PENDING,
        [ApprovalStatusT.IN_REVIEW]: PENDING,
        [ApprovalStatusT.APPROVED]: COMPLETED,
        [ApprovalStatusT.REJECTED]: FAILED,
      }[this.bankAccount?.status];

      return [
        ['Registration', TIMELINE_ELEMENT_STATUSES.COMPLETED],
        ...(isBankingDetailsChangesEnabled
          ? [
              [
                'Data input completed',
                this.isCompanyDataDefined ? COMPLETED : PENDING,
              ],
              [
                'Bank Data approved',
                bankApprovalDisplayStatus,
                false,
                bankApprovalDisplayStatus === TIMELINE_ELEMENT_STATUSES.FAILED
                  ? 'Reason: ' + this.bankAccount.rejectionReason
                  : null,
              ],
            ]
          : [
              [
                'Data input completed',
                merchantRequirements.generalDataDefined ? COMPLETED : PENDING,
              ],
              [
                'Sent for financial approval',
                [
                  MCP_CONTRACT_STATUSES.FINANCIAL_APPROVED,
                  MCP_CONTRACT_STATUSES.FINANCIAL_REJECTED,
                  MCP_CONTRACT_STATUSES.IN_FINANCIAL_REVIEW,
                ].includes(contract.status)
                  ? COMPLETED
                  : PENDING,
              ],
              [
                'Financially approved',
                isFinanciallyApprovedStatus,
                false,
                isFinanciallyApprovedStatus === TIMELINE_ELEMENT_STATUSES.FAILED
                  ? 'Reason: ' + contract.approverComment
                  : null,
              ],
            ]),
        [
          contractRequirementsIsLoading
            ? 'Loading...'
            : 'Return address defined',
          contractRequirementsIsLoading
            ? LOADING
            : contractRequirements.returnAddressDefined
            ? COMPLETED
            : PENDING,
          isZFSContract,
        ],
        [
          contractRequirementsIsLoading ? 'Loading..' : 'Configuration defined',
          contractRequirementsIsLoading
            ? LOADING
            : contractRequirements.technicalConfigurationDefined
            ? COMPLETED
            : PENDING,
        ],
        [
          'Metadata defined',
          merchantRequirements.metadataDefined ? COMPLETED : PENDING,
        ],
        [
          'Product Delivery Specified',
          isProductDeliverySpecifiedStatus,
          true,
          null,
          cepLink,
        ],
        ...(isZFSContract
          ? []
          : [
              [
                'Carrier Product specified',
                this.stocksRequirements?.carrierProductsSpecified
                  ? COMPLETED
                  : PENDING,
                false,
              ],
            ]),
        [
          launchedLabels[
            contractRequirementsIsLoading
              ? CONTRACT_REQ_LAUNCH_STATUSES.LOADING
              : contractRequirements.launchStatus
          ],
          launchedStatusToTimelineStatusMap[
            contractRequirementsIsLoading
              ? CONTRACT_REQ_LAUNCH_STATUSES.LOADING
              : contractRequirements.launchStatus
          ],
        ],
        [
          'Compliance Data',
          this.complianceRequirements !== null &&
          this.complianceRequirements.length !== 0
            ? this.salesChannelComplianceStatus?.status === 'APPROVED' &&
              this.merchantComplianceStatus?.status === 'APPROVED'
              ? COMPLETED
              : PENDING
            : this.merchantComplianceStatus?.status === 'APPROVED'
            ? COMPLETED
            : PENDING,
          !canEnableVatCollection(this.merchant),
        ],
        ...(isZFSContract
          ? []
          : [
              [
                'Carrier Product synced',
                this.stocksRequirements?.carrierProductsSynced
                  ? COMPLETED
                  : PENDING,
                false,
              ],
            ]),
        contractStatus,
      ].map(
        ([title, status, isHidden, info, titleLink]) =>
          ({ title, status, isHidden, info, titleLink } as TimelineElement)
      );
    },
  },
  mounted() {
    fetchCarriersRequirements(
      this.merchant.$id,
      this.contract.salesChannel.$id
    ).then((res) => {
      this.stocksRequirements = res;
    });
    fetchMerchantComplianceStatus(this.merchant.$id).then((res) => {
      this.merchantComplianceStatus = res;
    });
    fetchSalesChannelComplianceDetailsRequirements(
      this.merchant.$id,
      this.contract.salesChannel.$id
    ).then((res) => {
      this.complianceRequirements = res;
      fetchSalesChannelComplianceStatus(
        this.merchant.$id,
        this.contract.salesChannel.$id
      ).then((res) => {
        this.salesChannelComplianceStatus = res;
      });
    });

    this.getBankAccountInfo().then((res: BankAccountInfoT) => {
      this.bankAccount = res;
    });

    this.getContacts();
  },

  destroyed(): void {
    this.isDestroyed = true;
  },

  methods: {
    launch(): void {
      markAsLaunched(this.contract.$id)
        // Continously re-check contract's launch status if current one is IN_PROGRESS
        // Because launching is async operation and it maybe hasn't had time to be updated
        .then(() => this.waitForContractToBeMarkedAsLaunched())
        .then(
          (resp) => resp !== 'USER_MOVED_TO_OTHER_PAGE' && this.onLaunchCb()
        )
        .catch(() => this.onLaunchErrorCb());
    },

    waitForContractToBeMarkedAsLaunched(): Promise<void | string> {
      return fetchContractRequirements(this.contract.$id).then(
        ({ launchStatus }) => {
          if (this.isDestroyed) {
            return Promise.resolve('USER_MOVED_TO_OTHER_PAGE');
          }
          if (launchStatus === CONTRACT_REQ_LAUNCH_STATUSES.DONE) {
            return Promise.resolve();
          }
          if (launchStatus === CONTRACT_REQ_LAUNCH_STATUSES.FAILED) {
            return Promise.reject();
          }

          return new Promise((resolve) =>
            setTimeout(
              () => resolve(this.waitForContractToBeMarkedAsLaunched()),
              1000
            )
          );
        }
      );
    },

    async getBankAccountInfo(): Promise<BankAccountInfoT> {
      const merchantId = this.merchant.$id;
      const salesChannelId = this.contract.salesChannelId;
      const billingMode = await fetchBillingMode(merchantId);
      const bankAccounts = await fetchBankAccountList(merchantId);

      if (billingMode === BillingModeT.EURO_MODE) {
        return findBankAccountByCurrencyCode(bankAccounts, 'EUR');
      } else {
        const currencySalesChannels = await fetchCurrencySalesChannels(
          merchantId
        );
        const currencyCode = findCurrencyCodeForSalesChannel(
          currencySalesChannels,
          salesChannelId
        );
        return findBankAccountByCurrencyCode(bankAccounts, currencyCode);
      }
    },
    async getContacts(): Promise<void> {
      this.contacts = await fetchContacts(
        this.merchant.$id,
        this.contract.salesChannelId
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.root ::v-deep .v-application--wrap {
  min-height: inherit;
}
</style>
