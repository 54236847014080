export enum ApprovalStatusT {
  PENDING = 'PENDING',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type SelectableReviewStatusT =
  | ApprovalStatusT.APPROVED
  | ApprovalStatusT.REJECTED;
