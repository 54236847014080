import httpServerErrorFormatter from './httpServerErrorFormatter';
import httpServerErrorInterceptor from './httpServerErrorInterceptor';

/**
 * @ngdoc module
 * @name mcb.components.httpServerError
 */
export default angular
  .module('mcb.components.httpServerError', [])
  .config([
    '$httpProvider',
    function ($httpProvider) {
      $httpProvider.interceptors.push('mcbHttpServerErrorInterceptor');
    },
  ])
  .factory('mcbHttpServerErrorFormatter', httpServerErrorFormatter)
  .factory('mcbHttpServerErrorInterceptor', httpServerErrorInterceptor);
