import { MerchantProfile } from 'Api/models/MerchantProfile';
import { Contract } from 'Api/models/Contract';
import template from './general.html';

export class GeneralContainer implements ng.IComponentOptions {
  static Factory() {
    return new GeneralContainer();
  }

  bindings: { [binding: string]: string } = {
    merchant: '<',
    contracts: '<',
  };

  controller = GeneralContainerController;
  controllerAs = 'generalCtrl';
  template: string = template;
}

export class GeneralContainerController implements ng.IController {
  static $inject = ['$scope'];

  merchant: MerchantProfile;
  contracts: Contract[];

  constructor(private $scope: ng.IScope) {
    this.onMasterDataExport = this.onMasterDataExport.bind(this);
  }

  $onInit() {
    // Empty block
  }

  onMasterDataExport() {
    // eslint-disable-next-line
    this.$scope.$emit('MasterdataExport');
  }
}
