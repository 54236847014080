import util from '../../core/util/util';
import menuProvider from './menuProvider';

/**
 * @ngdoc module
 * @name mcb.components.menu
 *
 * @requires mcb.core.util
 */
export default angular
  .module('mcb.components.menu', [util.name])
  .provider('mcbMenu', menuProvider);
