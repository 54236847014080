import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "text-center mt-2" },
            [
              _c(VProgressCircular, {
                attrs: { size: 50, color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _vm.isApiError
        ? _c(VAlert, { attrs: { value: true, type: "error" } }, [
            _vm._v("Right of Withdrawal initialisation failed"),
          ])
        : _vm.isInitialised
        ? _c(
            "div",
            [
              _c(
                VBtn,
                {
                  staticClass: "ml-0 mb-6 mt-0",
                  attrs: {
                    depressed: "",
                    color: "primary",
                    loading: _vm.isPdfLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("downloadPdf")
                    },
                  },
                },
                [_vm._v("Download PDF")]
              ),
              _c(VCard, [
                _c("div", {
                  staticClass: "px-4 pt-1 pb-2",
                  domProps: { innerHTML: _vm._s(_vm.html) },
                }),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }