import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VAppBar,
    {
      attrs: {
        color: "white",
        height: "60",
        flat: "",
        app: "",
        elevation: "1",
      },
    },
    [
      _c("ZalandoLogo"),
      _c("div", { staticClass: "ml-2 text-h6 font-weight-light" }, [
        _vm._v("Merchant Profile Cockpit"),
      ]),
      _c("div", { staticClass: "ml-2 env-label warning" }, [
        _c("span", [_vm._v(_vm._s(_vm._f("envLabel")(_vm.env)))]),
      ]),
      _vm._t("default"),
      !_vm.hideTabs
        ? _c(
            "div",
            { staticClass: "ml-4" },
            [
              _c(
                VTabs,
                {
                  attrs: {
                    "active-class": "v-tabs__item--active--header",
                    height: "60",
                    color: "secondaryDark",
                    "slider-color": "secondaryDark",
                    optional: "",
                  },
                  on: {
                    change: (tabIndex) => _vm.$emit("tab-changed", tabIndex),
                  },
                  model: {
                    value: _vm.activeTabModel,
                    callback: function ($$v) {
                      _vm.activeTabModel = $$v
                    },
                    expression: "activeTabModel",
                  },
                },
                _vm._l(_vm.tabs, function (tab, index) {
                  return _c(
                    VTab,
                    {
                      key: tab,
                      staticClass: "subtitle-1",
                      class: { "first-tab": index === 0 },
                      attrs: { disabled: index === _vm.activeTabModel },
                    },
                    [_vm._v(_vm._s(tab))]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(VSpacer),
      _vm.roles.length > 1
        ? _c(VSelect, {
            staticClass: "flex-grow-0 mr-4",
            staticStyle: { width: "350px" },
            attrs: { items: _vm.roles, "hide-details": "" },
            on: {
              change: function ($event) {
                return _vm.$emit("role-changed", $event)
              },
            },
            model: {
              value: _vm.currentRoleModel,
              callback: function ($$v) {
                _vm.currentRoleModel = $$v
              },
              expression: "currentRoleModel",
            },
          })
        : _vm._e(),
      _c("UserProfile", {
        attrs: { username: _vm.username },
        on: {
          logout: function ($event) {
            return _vm.$emit("logout")
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }