// @ts-ignored
import mitt from 'mitt';

// @ts-ignored
const emitter = mitt();

const run = (state) => {
  return Promise.resolve(state)
    .then(({ state }) => {
      try {
        emitter.emit('ready', { state });
      } catch (error) {
        error.state = state;
        return Promise.reject(error);
      }
      return state;
    })
    .catch((e) => {
      let error = e.error ? e.error : e;
      let state = e.state;

      console.error(error); // eslint-disable-line no-console

      try {
        emitter.emit('error', {
          error,
          state,
        });
      } catch (e) {
        console.error(error); // eslint-disable-line no-console
        return Promise.reject(e);
      }

      return Promise.reject(error);
    });
};

// @ts-ignore
window.mc = { emitter, run };
