import template from './sidebar-contract-menu-channel-item.html';
import { SalesChannelExt } from 'Api/models/SalesChannel';
import { ZALANDO_FASHION_STORE_RETAILERID } from 'Common/constants/retailer.constant';

export class SidebarContractMenuChannelItemComponent
  implements ng.IComponentOptions
{
  static Factory() {
    return new SidebarContractMenuChannelItemComponent();
  }

  bindings: { [binding: string]: string } = {
    channel: '<',
    imagesPath: '<',
    contractsFinalStatuses: '<',
  };

  controller = SidebarContractMenuChannelItemController;
  controllerAs = 'ctrl';
  template: string = template;
}

export class SidebarContractMenuChannelItemController
  implements angular.IComponentController
{
  static $inject = ['$stateParams'];

  channel: SalesChannelExt;
  contractsFinalStatuses: any[];
  imagesPath: string;

  constructor(private params) {}

  $onInit(): void {
    // Initialisation
  }

  isActive(): boolean {
    return this.channel.$id === this.params.salesChannelId;
  }

  isOnline(): boolean {
    return this.channel.contracts.some((contract) => contract.live);
  }

  // Only needed if it's not a Zalando sales channel
  isChannelNameNeeded(): boolean {
    return this.channel.contracts.some(
      (contract) => contract.retailerId !== ZALANDO_FASHION_STORE_RETAILERID
    );
  }
}
