<template lang="pug">
div
  div.mcp-admin-merchant-details-section
    div.mcp-admin-merchant-details-section-form
      //- Send dummy/customer email
      div
        span.dc-label Send Email

        input.dc-radio(
          type="radio"
          id="config-send-customer-email"
          v-model="sendDummyEmailField"
          v-bind:value="false"
        )
        label.dc-label(for="config-send-customer-email") Customer's email

        input.dc-radio(
          type="radio"
          id="config-send-dummy-email"
          v-model="sendDummyEmailField"
          v-bind:value="true"
          )
        label.dc-label(for="config-send-dummy-email") Dummy email

  //- Save configuration
  div.button-wrapper
    button.dc-btn.dc-btn--primary(
      type="submit"
      v-on:click="save"
    ) Save configuration

</template>

<script lang="ts">
import Vue from 'vue';
import { Contract } from 'Api/models/Contract';

export default Vue.extend({
  props: {
    sendDummyEmail: {
      type: Boolean,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      sendDummyEmailField: this.sendDummyEmail,
    };
  },
  methods: {
    save(): void {
      this.onSave({
        sendDummyMail: this.sendDummyEmailField,
      } as Contract);
    },
  },
});
</script>

<style lang="scss" scoped>
.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
}
</style>
