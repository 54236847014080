import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "text-h6 mb-2" },
        [
          _vm._v("Outbound locations"),
          _c("mp-info-tooltip", {
            staticClass: "ml-1",
            attrs: {
              infoText:
                "Outbound locations are ids of locations from where the order will be fulfilled. For ZFS they are real locations since Zalando does have the data about the warehouses. For Partner Fulfillment - they are virtual ones, without being tied to a location in the real world",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "text--primary mb-4 mt-4" }, [
        _vm._v("Live - " + _vm._s(_vm.displayedFulfillmentType)),
      ]),
      _c(VDataTable, {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.activeStockLocations,
          loading: _vm.loadingStocks,
          "hide-default-footer": "",
          "disable-pagination": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  VIcon,
                  {
                    attrs: { small: "" },
                    on: {
                      click: function ($event) {
                        return _vm.offboardStock(item)
                      },
                    },
                  },
                  [_vm._v("mdi-delete")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("div", { staticClass: "text--primary mb-4 mt-4" }, [
        _vm._v("Offboarded - " + _vm._s(_vm.displayedFulfillmentType)),
      ]),
      _c(VDataTable, {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.availableStockLocations,
          loading: _vm.loadingStocks,
          "hide-default-footer": "",
          "disable-pagination": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  VIcon,
                  {
                    attrs: { small: "" },
                    on: {
                      click: function ($event) {
                        return _vm.onboardStock(item)
                      },
                    },
                  },
                  [_vm._v("mdi-plus")]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.canEdit
        ? _c("div", { staticClass: "dc-row dc-row--align--right mt-5" }, [
            _c("div", { staticClass: "dc-column dc-column--shrink" }, [
              !_vm.isEditingEnabled
                ? _c(
                    "button",
                    {
                      staticClass: "dc-btn",
                      class: { "dc-btn--disabled": false },
                      attrs: { type: "reset" },
                      on: { click: _vm.onEnableEdit },
                    },
                    [_vm._v("Edit")]
                  )
                : _vm._e(),
              _vm.isEditingEnabled
                ? _c(
                    "button",
                    {
                      staticClass: "dc-btn",
                      class: { "dc-btn--disabled": false },
                      attrs: { type: "reset" },
                      on: { click: _vm.onCancelEdit },
                    },
                    [_vm._v("Cancel")]
                  )
                : _vm._e(),
              _vm.isEditingEnabled
                ? _c(
                    "button",
                    {
                      staticClass: "dc-btn dc-btn--primary",
                      staticStyle: { "margin-left": "3px" },
                      attrs: { disabled: !_vm.canSaveChanges, type: "submit" },
                      on: { click: _vm.onSave },
                    },
                    [_vm._v("Save")]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _c(
        VDialog,
        {
          attrs: { width: "550" },
          model: {
            value: _vm.showConfirmModal,
            callback: function ($$v) {
              _vm.showConfirmModal = $$v
            },
            expression: "showConfirmModal",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "title" }, [
                _vm._v("Provide a business reason"),
              ]),
              _c(
                VCardText,
                [
                  _c(VTextarea, {
                    attrs: {
                      filled: "",
                      autofocus: "",
                      label: "Business Reason for the change",
                    },
                    model: {
                      value: _vm.businessReason,
                      callback: function ($$v) {
                        _vm.businessReason = $$v
                      },
                      expression: "businessReason",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    { attrs: { text: "" }, on: { click: _vm.onCancelEdit } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        color: "primary",
                        disabled: !_vm.businessReason,
                      },
                      on: { click: _vm.updateStocksLocations },
                    },
                    [_vm._v("Confirm")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }