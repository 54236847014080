<template lang="pug">
div(style="height: 100%")
  v-hover(v-slot:default="{ hover }" v-if="legalEntity")
    div.pt-4.pb-3(style="height: 100%")
      //- Legal Entity title
      div.d-flex.justify-space-between.align-center
        div.title.secondary--text {{ legalEntity.name }}
        v-btn(
          icon
          v-if="removable"
          @click.stop="$emit('remove', legalEntity.id)"
        )
          v-icon(light v-show="hover" color="reject") cancel

      //- Merchants list
      div.d-flex.flex-wrap(v-if="legalEntity.merchants.length")
        span.body-2.secondary--text.mr-6.mb-2.merchant(v-for="merchant in legalEntity.merchants")
          | {{ merchant.name }}
      div.body-1.secondaryDark--text(v-else) -

  //- Show Loading indicator if legalEntity has not been loaded yet
  v-row(align-content="center" v-if="!legalEntity" style="height: 100%")
    v-col.text-center
      v-progress-circular(indeterminate color="secondaryDark")

  v-divider(light v-if="showDivider")
</template>

<script lang="ts">
import Vue from 'vue';
import LegalEntity from 'Api/models/LegalEntity';

export default Vue.extend({
  name: 'LegalEntity',
  props: {
    legalEntity: Object as () => LegalEntity | null,
    removable: Boolean,
    showDivider: Boolean,
  },
});
</script>

<style lang="scss" scoped></style>
