import axios from 'axios';
import { pipe } from 'ramda';
import { MarketBankAccountT } from 'Api/models/BankAccount';
import ErrorHandler, { nullIf404Handler } from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';

const bankApi = axios.create({ baseURL: 'api/merchant-profile/merchants' });

bankApi.interceptors.request.use(decamelizeRequestHandler);
bankApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

/**
 * Function returns null if Bank Details are not defined
 * neither on Merchant Level nor on Market level
 *
 */
export function fetchBankData(
  merchantId: string,
  salesChannelId: string
): Promise<MarketBankAccountT | null> {
  return bankApi
    .get(`/${merchantId}/sales-channels/${salesChannelId}/bank-accounts`)
    .catch(nullIf404Handler) as Promise<MarketBankAccountT | null>;
}

export function useMerchantBankData(
  merchantId: string,
  salesChannelId: string
): Promise<MarketBankAccountT> {
  return bankApi.post(
    `/${merchantId}/sales-channels/${salesChannelId}/bank-accounts/use-merchant`
  ) as Promise<MarketBankAccountT>;
}

export function updateBankData(
  merchantId: string,
  salesChannelId: string,
  data: MarketBankAccountT
): Promise<MarketBankAccountT> {
  return bankApi.put(
    `/${merchantId}/sales-channels/${salesChannelId}/bank-accounts`,
    data
  ) as Promise<MarketBankAccountT>;
}
