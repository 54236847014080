<template lang="pug">
v-flex.details-container(d-flex)
  div
    div.display-1.secondary--text.mb-6
      | Merchant Account Details

    //- Account name
    mp-labeled-text(label="Merchant Account name" :value="merchantAccount.name")
    v-divider.mt-3(light)

    //- Legal entities
    div(style="width: 688px")
      div.display-1.secondary--text.mt-8.mb-6
        | Legal Entities

      div.d-flex.flex-wrap.justify-space-between
        //- No divider under last 2 items
        LegalEntity(
          style="width: 320px"
          v-for="(legalEntity, index) in merchantAccount.legalEntities"
          :key="legalEntity.id"
          :show-divider="index + 2 < merchantAccount.legalEntities.length"
          :legalEntity="legalEntity"
        )

    v-divider.mt-6.mb-8(light)

    //- Contact details
    div
      div.display-1.secondary--text.mb-6
        | Primary Contact Details

      v-row
        v-col(cols="6")
          mp-labeled-text(label="First Name" :value="merchantAccount.firstName")
        v-col(cols="6")
          mp-labeled-text(label="Last Name" :value="merchantAccount.lastName")
        v-col
          mp-labeled-text(label="Email" :value="merchantAccount.email")

      v-divider.mt-5(v-if="!inviteHidden" light)

      v-flex(v-if="!inviteHidden" d-flex style="justify-content: flex-end")
        v-btn.mt-6(
          color="primary"
          depressed
          ref="sendZdirectInviteButton"
          :disabled="isInvitationSent || !isInvitationRequirementsCompleted"
          @click="$emit('send-zdirect-invite')"
        ) {{ isInvitationSent ? 'Invite already sent' : 'Send zDirect invite' }}

  v-btn.ml-6(outlined color="primary" ref="editDetailsButton" @click="$emit('edit-details')")
    v-icon.mr-2(small) mdi-square-edit-outline
    | Edit details
</template>

<script lang="ts">
import Vue from 'vue';
import LegalEntity from 'Common/components/legal-entity/legal-entity.vue';
import MerchantAccount from 'Api/models/MerchantAccount';

export default Vue.extend({
  name: 'MerchantAccountDetails',
  components: { LegalEntity },
  props: {
    merchantAccount: {
      type: Object as () => MerchantAccount,
      required: true,
    },
    isInvitationSent: {
      type: Boolean,
      required: true,
    },
    inviteHidden: {
      type: Boolean,
      required: true,
    },
    isInvitationRequirementsCompleted: {
      type: Boolean,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.details-container {
  background-color: white;
  padding: 48px 0 48px 98px;
}
</style>
