import axios from 'axios';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import { pipe } from 'ramda';
import ErrorHandler from 'Api/api-errors-handler';
import {
  BankAccountConfirmationMetaData,
  BankAccountInfoT,
} from 'Api/models/BankAccount';
import { CurrencySalesChannelT } from 'Api/models/CurrencySalesChannel';
import { SelectableReviewStatusT } from 'Api/models/ApprovalStatus';

const merchantApi = axios.create({
  baseURL: '/api/merchant-profile/merchants',
});
merchantApi.interceptors.request.use(decamelizeRequestHandler);
merchantApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

const certificateApi = axios.create({
  baseURL: '/api/merchant-profile/merchants',
});
certificateApi.interceptors.response.use(({ data }) => data, ErrorHandler);

export async function fetchBankAccountList(
  merchantId: string
): Promise<BankAccountInfoT[]> {
  const response: { bankAccounts: BankAccountInfoT[] } = await merchantApi.get(
    `${merchantId}/bank-accounts`
  );
  return response.bankAccounts;
}

export function fetchBankConfirmationCertificateMetaData(
  merchantId: string,
  confirmationId: string
): Promise<BankAccountConfirmationMetaData> {
  return merchantApi.get(
    `${merchantId}/bank-account-confirmations/${confirmationId}/metadata`
  );
}

export function fetchBankConfirmationCertificate(
  merchantId: string,
  confirmationId: string
): Promise<BlobPart> {
  return certificateApi.get(
    `${merchantId}/bank-account-confirmations/${confirmationId}`,
    {
      responseType: 'arraybuffer',
      headers: {
        'content-type': 'application/pdf',
      },
    }
  );
}

export async function uploadBankConfirmationCertificate(
  merchantId: string,
  values: Blob
): Promise<BankAccountConfirmationMetaData> {
  const uploadForm = new FormData();
  uploadForm.append('bank-account-confirmation', values);
  return await certificateApi
    .post(`${merchantId}/bank-account-confirmations`, uploadForm)
    .then((data) => {
      return (
        camelizeResponseHandler({ data }) as {
          data: BankAccountConfirmationMetaData;
        }
      ).data;
    });
}

export async function fetchCurrencySalesChannels(
  merchantId: string
): Promise<CurrencySalesChannelT[]> {
  const response: { currencySalesChannels: CurrencySalesChannelT[] } =
    await merchantApi.get(`${merchantId}/currency-sales-channels`);

  return response.currencySalesChannels;
}

interface StatusUpdateDataT {
  status: SelectableReviewStatusT;
  rejectionReason?: string;
}

interface UpdateBankAccountStatusPropsT {
  merchantId: string;
  currencyCode: string;
  data: StatusUpdateDataT;
}

export async function updateBankAccountStatus({
  merchantId,
  currencyCode,
  data,
}: UpdateBankAccountStatusPropsT): Promise<void> {
  await merchantApi.put(
    `${merchantId}/bank-accounts/${currencyCode}/status`,
    data
  );
}

interface CreateBankAccountPropsT {
  merchantId: string;
  data: BankAccountInfoT;
}

export async function createBankAccount({
  merchantId,
  data,
}: CreateBankAccountPropsT): Promise<void> {
  await merchantApi.post(`${merchantId}/bank-accounts`, data);
}

interface UpdateBankAccountPropsT extends CreateBankAccountPropsT {
  currencyCode: string;
}

export async function updateBankAccount({
  merchantId,
  data,
}: UpdateBankAccountPropsT): Promise<void> {
  await merchantApi.put(
    `${merchantId}/bank-accounts/${data.currencyCode}`,
    data
  );
}
