<template lang="pug">
  v-app(id="confirm-data-change")
    v-dialog(
      v-model="showConfirmDataChangeModal"
      width="550"
    )
      v-card
        v-card-title.title Apply {{ choiceLabel }} Marketplace Service fee?



        v-card-text
          p
            | {{ choiceLabel }} Marketplace Service fee will be applied starting from
            |
            strong ({{ period }})
            | .
            | You can change this selection at any time before the upcoming period begins, however any changes will only apply to the upcoming period. 

          ul(v-if="choice === 'OPT_IN'")
            li The reduced fees will apply only to overlapping articles also being offered by Zalando Wholesale.
            li Standard fees apply for the rest of the assortment.
            li This update will apply to all merchants under the same legal entity.

          ul(v-if="choice === 'OPT_OUT'")
            li Standard fees will be applied to your entire assortment.
            li This update will apply to all merchants under the same legal entity.

          p Do you want to apply this fee to the upcoming period?

        v-card-actions
          v-spacer
          v-btn(
            text
            @click="cancelCb"
          ) Cancel

          v-btn(
            color="primary"
            @click="confirmCb"
            :loading="loading"
          ) Yes, apply fee
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    confirmCb: {
      type: Function,
      required: true,
    },
    cancelCb: {
      type: Function,
      required: true,
    },
    choice: {
      type: String,
      required: true,
    },
    showConfirmDataChangeModal: {
      type: Boolean,
      default: false,
    },
    period: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...this.mapState({
        merchantProfile: 'flow.general.merchant.data',
      }),
    };
  },
  computed: {
    choiceLabel() {
      if (this.choice === 'OPT_IN') {
        return 'Reduced';
      }
      return 'Standard';
    },
  },
});
</script>

<style lang="scss" scoped>
.v-application {
  background: inherit;
  float: right;
  & ::v-deep &--wrap {
    margin-top: 0;
    min-height: auto;
  }
}
.dc-btn {
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
  transition: color 0.1s linear;
  color: #26aafe;
  &:hover {
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;
    color: #1e87cb;
  }
}
</style>
