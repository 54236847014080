import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        VSnackbar,
        {
          attrs: { timeout: 5000, color: "reject", bottom: "", right: "" },
          model: {
            value: _vm.showError,
            callback: function ($$v) {
              _vm.showError = $$v
            },
            expression: "showError",
          },
        },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _vm.isFetchingMetaData
        ? _c("div", { staticClass: "dc-spinner dc-spinner--small" })
        : _c(
            "div",
            [
              _c("DocumentUpload", {
                attrs: {
                  "document-id": _vm.documentId,
                  "file-name": _vm.filename,
                  label: "Upload Confirmation Document",
                },
                on: { "upload-document": _vm.handleDocumentUpload },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }