import McPromiseTrackerService from './promise-tracker.service';
import mcLoadingScreenPromiseTrackerService from './loading-screen-promise-tracker.service';
import mcLoadingScreenDirective from './loading-screen.directive';

export default angular
  .module('mc.loading', [])
  .constant('MC_LOADING_SCREEN', {
    HIDE_CSS_CLASS: 'mc-loading-screen--hide',
  })
  .factory('McPromiseTracker', McPromiseTrackerService)
  .factory(
    'mcLoadingScreenPromiseTracker',
    mcLoadingScreenPromiseTrackerService
  )
  .directive('mcLoadingScreen', mcLoadingScreenDirective);
