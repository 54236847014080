import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(VAutocomplete, {
        ref: "autocomplete",
        staticClass: "field-wrapper",
        attrs: {
          filled: "",
          attach: "",
          multiple: "",
          messages: _vm.helpText,
          "item-value": _vm.valueProp,
          "item-text": _vm.nameProp,
          value: _vm.value,
          "search-input": _vm.search,
          items: _vm.isMenuActive() ? _vm.computedAttributes : _vm.valueAttrs,
          label: _vm.label,
          "no-data-text": _vm.noDataText,
          disabled: _vm.readonly,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
          "update:search-input": _vm.onSearch,
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                "Backspace",
                "Delete",
                "Del",
              ])
            )
              return null
            return _vm.onBackspaceClicked.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "prepend-item",
            fn: function () {
              return [
                _vm.computedAttributes.length && !_vm.loading
                  ? [
                      !_vm.search
                        ? _c("div", { staticClass: "px-4" }, [
                            _vm._v("Start typing to see more values"),
                          ])
                        : [
                            _vm.computedAttributes.length < 100
                              ? _c("div", { staticClass: "px-4" }, [
                                  _vm._v(
                                    "Found " +
                                      _vm._s(_vm.computedAttributes.length) +
                                      " items"
                                  ),
                                ])
                              : _c("div", { staticClass: "px-4" }, [
                                  _vm._v(
                                    "Search results are limited to 100 items"
                                  ),
                                ]),
                          ],
                    ]
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "selection",
            fn: function ({ item, index }) {
              return [
                !_vm.isMenuActive()
                  ? [
                      index > 0 ? [_vm._v(",")] : _vm._e(),
                      _vm._v(_vm._s(_vm.getItemLabel(item))),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.valuesAdded.length
        ? _c("div", { staticClass: "green--text" }, [
            _c("span", { staticClass: "font-weight-bold mr-1" }, [
              _vm._v("Added (" + _vm._s(_vm.valuesAdded.length) + "):"),
            ]),
            _c("span", { staticClass: "font-weight-medium" }, [
              _vm._v(_vm._s(_vm.valuesAdded.join(", "))),
            ]),
          ])
        : _vm._e(),
      _vm.valuesRemoved.length
        ? _c("div", { staticClass: "red--text" }, [
            _c("span", { staticClass: "font-weight-bold mr-1" }, [
              _vm._v("Removed (" + _vm._s(_vm.valuesRemoved.length) + "):"),
            ]),
            _c("span", { staticClass: "font-weight-medium" }, [
              _vm._v(_vm._s(_vm.valuesRemoved.join(", "))),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }