import { MerchantProfile } from 'Api/models/MerchantProfile';
import template from './company-details-readonly.html';
import { Unsubscribe } from 'redux';
import { FlowState } from 'Merchant/common/state/flow.state';
import { FlowStateSelector } from 'Merchant/common/state/selectors/flow-state.selector';

export class CompanyDetailsReadonlyComponent implements ng.IComponentOptions {
  static Factory() {
    return new CompanyDetailsReadonlyComponent();
  }

  bindings: { [binding: string]: string } = {
    merchant: '<',
  };

  controller = CompanyDetailsReadonlyController;
  controllerAs = 'ctrl';
  template: string = template;
}

export class CompanyDetailsReadonlyController {
  static $inject = ['$ngRedux', 'mcpAdminFlowStateSelector'];

  merchant: MerchantProfile;
  unsubscribe: Unsubscribe;
  isNameLoading: boolean;

  constructor(private store, private flowSelector: FlowStateSelector) {}

  $onInit(): void {
    this.listenStore();
  }

  $onDestroy(): void {
    this.unsubscribe();
  }

  private listenStore() {
    this.unsubscribe = this.store.subscribe(() => {
      const state: FlowState = this.store.getState();
      this.isNameLoading =
        this.flowSelector.getFlowMerchantNameLoadingStatus(state);
    });
  }
}
