import { VApp } from 'vuetify/lib/components/VApp';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { staticClass: "root" },
    [
      _c(
        VSnackbar,
        {
          attrs: { timeout: 5000, color: "reject", bottom: "", right: "" },
          model: {
            value: _vm.showError,
            callback: function ($$v) {
              _vm.showError = $$v
            },
            expression: "showError",
          },
        },
        [_vm._v("Failed to fetch partner contract data")]
      ),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c(VProgressCircular, {
                attrs: { color: "secondaryDark", indeterminate: "" },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.canEdit && this.isEditModeEnabled
                ? _c("PartnerContractDetailsEditable", {
                    attrs: {
                      details: _vm.details,
                      "legal-entity-id": _vm.legalEntityId,
                    },
                    on: {
                      "on-cancel": _vm.toggleEditMode,
                      "on-success": _vm.handleOnSuccess,
                    },
                  })
                : _c(
                    "div",
                    [
                      this.canEdit
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "edit-button dc-btn dc-btn--link mcp-admin-merchant-button--edit",
                              on: { click: _vm.toggleEditMode },
                            },
                            [
                              _c("span", [
                                _vm._v("Edit"),
                                _c("i", {
                                  staticClass:
                                    "dc-icon dc-icon--pencil dc-icon--btn dc-icon--btn--left",
                                }),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c("PartnerContractDetailsReadonly", {
                        attrs: {
                          details: _vm.details,
                          "show-name-link": !this.readOnly,
                          "legal-entity-id": _vm.legalEntityId,
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }