<template lang="pug">
form(novalidate @submit.prevent)
  mpConfirmationModal(
    v-if="isConfirmMerchantTypeChangeVisible"
    show-warning
    no-btn="Cancel"
    yes-btn="Yes, Change"
    @reject="isConfirmMerchantTypeChangeVisible = false"
    @confirm="save"
    data-testid="confirmMerchantTypeModal"
  )
    div(v-if="merchantTypeInitial !== data.merchantType")
      | Changing the Merchant Type will also change the associated compensations for this merchant.

    div(v-if="(factoringInitial !== data.factoring) && data.merchantType === 'CONNECTED_RETAIL'")
      | Please be aware that changing the factoring type would also mean that you need to change the compensation rates for this merchant.

    div(v-if="(factoringInitial !== data.factoring) && data.merchantType !== 'CONNECTED_RETAIL'")
      | Changing the Factoring Type will also change the associated compensations for this merchant.

    div(v-if="integrationTypeInitial !== data.integrationType")
      | Changing the integration type will affect partner experience on zDirect.
    br
    | Please proceed only when you are sure of this action.

  div.mcp-admin-merchant-details-section
    div.mcp-admin-merchant-details-section-form
      //- Service level
      div.mcp-admin__form-field.field-wrapper
        label.dc-label(for="service-level") Service level
        select.dc-select.field(
          v-model="data.serviceLevel"
          :disabled="isSubmitInProgress"
          name="serviceLevel"
          data-testid="service-level"
          id="service-level"
        )
          option(v-for="opt in serviceLevelOptions" :value="opt.value" :key="opt.value")
            | {{ opt.label }}

      //- Merchant Type
      div.mcp-admin__form-field.field-wrapper
        label.dc-label(for="merchant-type") Merchant Type

        select.dc-select.field(
          v-model="data.merchantType"
          :disabled="isSubmitInProgress || isMerchantTypeDisabled"
          name="merchantType"
          data-testid="merchant-type"
          id="merchant-type"
        )
          option(v-for="opt in merchantTypeOptions" :value="opt.value" :key="opt.value")
            | {{ opt.label }}

        div.dc--text-error(
          v-if="isMerchantTypeDisabled"
        ) The field is disabled to prevent automatic change of the active compensation. Contact Team Wilma for further details.

      //- Factoring
      div.mcp-admin__form-field.field-wrapper
        label.dc-label(for="factoring-type") Factoring Type

        select.dc-select.field(
          v-model="data.factoring"
          :disabled="isSubmitInProgress || isFactoringDisabled"
          name="factoringType"
          data-testid="factoring-type"
          id="factoring-type"
        )
          option(v-for="opt in factoringOptions" :value="opt.value" :key="opt.value")
            | {{ opt.text }}

      //- Integration Type
      div.mcp-admin__form-field.field-wrapper
        span.dc-label Integration Type
        input(
          type="radio"
          class="dc-radio"
          id="direct-integration"
          :disabled="isSubmitInProgress"
          v-model="data.integrationType"
          value="DIRECT"
        )
        label.dc-label(for="direct-integration") Direct

        input(
          type="radio"
          class="dc-radio"
          id="non-direct-integration"
          :disabled="isSubmitInProgress"
          v-model="data.integrationType"
          value="PARTNER_PROVIDER"
        )
        label.dc-label(for="non-direct-integration") Partner Provider

      //- Supplier Code
      div.mcp-admin__form-field
        label.dc-label(for="supplier-code") Supplier Code
        input.dc-input.dc-input--block(
          :class="{'error-input-field': !isSupplierCodeValid}"
          type="string"
          :disabled="isSubmitInProgress"
          v-model="data.supplierCode"
          name="supplierCode"
          data-testid="supplier-code"
          id="supplier-code"
        )

        div.errors.dc--text-error(
          data-testid="supplier-code-error"
          v-if="!isSupplierCodeValid"
        )
          span(v-if="!isEmpty(supplierCodeInitial) && isEmpty(data.supplierCode)")
            | Please don't leave this empty.
          span(v-else)
            | Invalid format. It should start with "K" followed by 5 or 6 numbers. Also, first digit cannot be 0.

      //- Buttons
      div.dc-row.dc-row--align--right
        div.dc-column.dc-column--shrink
          button.dc-btn(
            @click="cancel"
            type="reset"
            :class="{'dc-btn--disabled': !hasChanges}"
            :disabled="!hasChanges"
            data-testid="cancel-btn"
          ) Cancel

          button.dc-btn.dc-btn--primary(
            style="margin-left: 3px"
            type="submit"
            @click="confirmChange"
            :class="{'dc-btn--disabled': isSubmitInProgress || !hasChanges || !isValid}"
            :disabled="isSubmitInProgress || !hasChanges || !isValid"
            data-testid="save-btn"
          ) Save
</template>

<script lang="ts">
import Vue from 'vue';
import {
  MCP_MERCHANT_SERVICE_LEVELS_LIST,
  MERCHANT_TYPES,
  MERCHANT_TYPES_MAP,
} from 'Common/constants/misc.constant';
import { MCP_FACTORING_TYPE_OPTIONS_WITHOUT_ALL } from '@/common/constants/factoring.constant';

const MERCHANT_TYPES_OPTS = MERCHANT_TYPES.map((value) => ({
  value,
  label: MERCHANT_TYPES_MAP[value],
}));

const SERVICE_LEVEL_OPTS = MCP_MERCHANT_SERVICE_LEVELS_LIST.map((val) => ({
  label: val,
  value: val,
}));
export default Vue.extend({
  props: {
    serviceLevelInitial: { type: String, required: false, default: '' },
    merchantTypeInitial: { type: String, required: false, default: '' },
    supplierCodeInitial: { type: String, required: false, default: '' },
    integrationTypeInitial: { type: String, required: false, default: '' },
    factoringInitial: { type: String, required: false, default: '' },
    saveCb: { type: Function, required: true },
    isMerchantTypeDisabled: Boolean,
    isFactoringDisabled: Boolean,
  },

  data() {
    return {
      isSubmitInProgress: false,
      serviceLevelOptions: this.serviceLevelInitial
        ? SERVICE_LEVEL_OPTS
        : [{ label: '-- NOT DEFINED --', value: '' }, ...SERVICE_LEVEL_OPTS],
      merchantTypeOptions: this.merchantTypeInitial
        ? MERCHANT_TYPES_OPTS
        : [{ label: '-- NOT DEFINED --', value: '' }, ...MERCHANT_TYPES_OPTS],
      factoringOptions: MCP_FACTORING_TYPE_OPTIONS_WITHOUT_ALL,
      data: {
        serviceLevel: this.serviceLevelInitial,
        merchantType: this.merchantTypeInitial,
        supplierCode: this.supplierCodeInitial,
        integrationType: this.integrationTypeInitial,
        factoring: this.factoringInitial,
      },
      isConfirmMerchantTypeChangeVisible: false,
    };
  },

  computed: {
    hasChanges(): boolean {
      return !(
        this.data.serviceLevel === this.serviceLevelInitial &&
        this.data.merchantType === this.merchantTypeInitial &&
        this.data.factoring === this.factoringInitial &&
        this.data.integrationType === this.integrationTypeInitial &&
        this.data.supplierCode.trim() === this.supplierCodeInitial
      );
    },
    isSupplierCodeValid(): boolean {
      return (
        // It can be left empty only when there was no initial value
        (this.supplierCodeInitial === '' &&
          this.data.supplierCode.trim() === '') ||
        this.data.supplierCode.trim().match(/^K[1-9][0-9]{4,5}$/)
      );
    },
    isValid(): boolean {
      return !!(
        this.data.serviceLevel &&
        this.data.merchantType &&
        ['DIRECT', 'PARTNER_PROVIDER'].includes(this.data.integrationType) &&
        this.isSupplierCodeValid
      );
    },
  },

  methods: {
    confirmChange(): void {
      if (
        (this.merchantTypeInitial &&
          this.data.merchantType !== this.merchantTypeInitial) ||
        (this.factoringInitial &&
          this.data.factoring !== this.factoringInitial) ||
        (this.integrationTypeInitial &&
          this.data.integrationType !== this.integrationTypeInitial)
      ) {
        this.isConfirmMerchantTypeChangeVisible = true;
        return;
      }
      this.save();
    },
    isEmpty(str: string): boolean {
      return str.trim() === '';
    },
    save(): void {
      this.isConfirmMerchantTypeChangeVisible = false; // Make sure the modal is closed
      this.isSubmitInProgress = true;
      this.saveCb({
        ...this.data,
        supplierCode: this.data.supplierCode.trim(),
      });
    },
    cancel(): void {
      this.data.serviceLevel = this.serviceLevelInitial;
      this.data.merchantType = this.merchantTypeInitial;
      this.data.supplierCode = this.supplierCodeInitial;
      this.data.integrationType = this.integrationTypeInitial;
      this.data.factoring = this.factoringInitial;
    },
  },
});
</script>

<style lang="scss" scoped>
.field {
  width: 100%;
}
.field-wrapper {
  margin-bottom: 24px;
}
.error-input-field {
  margin-bottom: 0;
}
</style>
