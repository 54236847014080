<template lang="pug">
  div
    div.mb-2
      ReviewSectionTitle(
        v-if="!hideTitle"
        title="Bank details"
      )
    div.py-3(v-for="(details, index) in list", :key="details.id", :data-testid="`${details.id}`")
      BankAccountReviewItem(
        :readonly="readonly"
        :hideSalesChannels="hideSalesChannels"
        :details="details"
        :legal-entity-name="legalEntityName"
        @on-status-change="$emit('on-status-change', {status: $event, id: details.id})"
        @on-rejection-reason-change="$emit('on-rejection-reason-change', {message: $event, id: details.id})"
      )
      v-divider
</template>

<script lang="ts">
import Vue from 'vue';
import ReviewSectionTitle from 'Merchant/common/components/review-section-title/review-section-title.vue';
import BankAccountReviewItem, {
  BankAccountListItemT,
} from 'Merchant/common/components/bank-account-details/bank-account-review-item.vue';

export default Vue.extend({
  components: { BankAccountReviewItem, ReviewSectionTitle },
  props: {
    list: {
      type: Array as () => Array<BankAccountListItemT>,
      required: true,
    },
    legalEntityName: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSalesChannels: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped></style>
