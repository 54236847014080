import axios from 'axios';
import ErrorHandler from 'Api/api-errors-handler';
import { VatCertificateMetaDataResponseT } from 'Api/models/Vat';
import { camelizeResponseHandler } from 'Api/api-mutations';

const legalEntityApi = axios.create({
  baseURL: 'api/merchant-profile/legal-entities',
});
legalEntityApi.interceptors.response.use(({ data }) => data, ErrorHandler);

export function fetchCertificate(
  legalEntityId: string,
  certificateId: string
): Promise<BlobPart> {
  return legalEntityApi.get(`${legalEntityId}/certificates/${certificateId}`, {
    responseType: 'arraybuffer',
    headers: {
      'content-type': 'application/pdf',
    },
  });
}

export function uploadCertificate(
  legalEntityId: string,
  values: Blob
): Promise<VatCertificateMetaDataResponseT> {
  const uploadForm = new FormData();
  uploadForm.append('certificate', values);
  return legalEntityApi
    .post(`${legalEntityId}/certificates`, uploadForm)
    .then((data) => {
      return (
        camelizeResponseHandler({ data }) as {
          data: VatCertificateMetaDataResponseT;
        }
      ).data;
    });
}
