/**
 * @ngdoc service
 * @name McbJsonapiResourceCollection
 *
 * @requires $http
 */
let mcbJsonapiResourceCollectionFactory =
  function mcbJsonapiResourceCollectionFactory(
    $http,
    mcbReflection,
    $log,
    $injector
  ) {
    function CannotCreateFromResponseError(response) {
      this.name = 'CannotCreateFromResponseError';
      this.message = 'Cannot create an instance from the provided response.';
      $log.error(this.message, response);
    }

    CannotCreateFromResponseError.prototype = Error.prototype;

    function NoNextItemsError(collection) {
      this.name = 'NoNextItemsError';
      this.message = "This collection doesn't have next items";
      $log.error(this.message, collection);
    }

    NoNextItemsError.prototype = Error.prototype;

    function NoPrevItemsError(collection) {
      this.name = 'NoPrevItemsError';
      this.message = "This collection doesn't have prev items";
      $log.error(this.message, collection);
    }

    NoNextItemsError.prototype = Error.prototype;

    function McbJsonapiResourceCollection(items) {
      this.$promise = null;
      this.$resolved = false;
      this.$response = null;
      this.$links = {};
      this.$included = [];
      this.$meta = {};

      this.$push(items);
    }

    McbJsonapiResourceCollection.SPECIAL_PROPS = [
      '$links',
      '$meta',
      '$included',
    ];
    McbJsonapiResourceCollection.SPECIAL_RESOURCE_PROPS = [
      '$promise',
      '$resolved',
      '$response',
    ];
    McbJsonapiResourceCollection.CannotCreateFromResponseError =
      CannotCreateFromResponseError;
    McbJsonapiResourceCollection.NoNextItemsError = NoNextItemsError;
    McbJsonapiResourceCollection.NoPrevItemsError = NoPrevItemsError;

    McbJsonapiResourceCollection.createFromResponse = function (response) {
      let collection = new this();
      collection.$resolved = true;
      collection.$response = response || {};

      if (response && collection.$response.resource) {
        collection.$resolved = collection.$response.resource.$resolved;
        collection.$promise = collection.$response.resource.$promise;
        collection.$props(
          McbJsonapiResourceCollection.SPECIAL_PROPS,
          collection.$response.resource
        );
        collection.$push(collection.$response.resource);
        return collection;
      } else if (response && !collection.$response.resource) {
        let typeRegistry = $injector.get('mcbJsonapiResourceBehavior');
        collection.$resolved = true;
        collection.$promise = collection.$response.$promise;
        collection.$props(
          McbJsonapiResourceCollection.SPECIAL_PROPS,
          collection.$response.data
        );
        collection.$push(
          collection.$response.data.map(function (raw) {
            return mcbReflection.genesis(typeRegistry.type(raw.$type), [raw]);
          })
        );
        return collection;
      }
      throw new CannotCreateFromResponseError(response);
    };

    McbJsonapiResourceCollection.prototype = [] as any;
    McbJsonapiResourceCollection.prototype.constructor =
      McbJsonapiResourceCollection;

    McbJsonapiResourceCollection.prototype.$hasNext = function () {
      return this.$links.next ? true : false;
    };

    McbJsonapiResourceCollection.prototype.$hasPrev = function () {
      return this.$links.prev ? true : false;
    };

    McbJsonapiResourceCollection.prototype.$next = function () {
      if (!this.$hasNext()) {
        throw new NoNextItemsError(this);
      }
      let promise = $http({
        method: 'GET',
        headers: this.$response.config.headers,
        paramSerializer: this.$response.config.paramSerializer,
        transformResponse: this.$response.config.transformResponse,
        transformRequest: this.$response.config.transformRequest,
        url: this.$links.next,
      }).then((response) => {
        response.$promise = promise;
        return McbJsonapiResourceCollection.createFromResponse(response);
      });
      return promise;
    };

    McbJsonapiResourceCollection.prototype.$prev = function () {
      if (!this.$hasPrev()) {
        throw new NoPrevItemsError(this);
      }
      let promise = $http({
        method: 'GET',
        headers: this.$response.config.headers,
        paramSerializer: this.$response.config.paramSerializer,
        transformResponse: this.$response.config.transformResponse,
        transformRequest: this.$response.config.transformRequest,
        url: this.$links.prev,
      }).then((response) => {
        response.$promise = promise;
        return McbJsonapiResourceCollection.createFromResponse(response);
      });

      return promise;
    };

    McbJsonapiResourceCollection.prototype.$props = function (props, from) {
      props.forEach((prop) => {
        if (from[prop]) {
          this[prop] = from[prop];
        } else {
          delete this[prop];
        }
      });
      return this;
    };

    McbJsonapiResourceCollection.prototype.$push = function (items) {
      if (items instanceof McbJsonapiResourceCollection) {
        // @ts-ignore
        this.push.apply(this, items.$toArray());
        return this;
      }
      this.push.apply(this, items);
      return this;
    };

    McbJsonapiResourceCollection.prototype.$toArray = function () {
      return this.map(function (val) {
        return val;
      });
    };

    return McbJsonapiResourceCollection;
  };

mcbJsonapiResourceCollectionFactory.$inject = [
  '$http',
  'mcbReflection',
  '$log',
  '$injector',
];

export default mcbJsonapiResourceCollectionFactory;
