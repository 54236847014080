import template from './iban-label.html';
import { stringToIbanFormat } from 'Common/services/iban.helper';

export class IbanLabelComponent implements ng.IComponentOptions {
  static Factory() {
    return new IbanLabelComponent();
  }

  bindings: { [binding: string]: string } = {
    iban: '@',
  };
  controller = IbanLabelController;
  template: string = template;
}

class IbanLabelController implements ng.IController {
  public static $inject = [];

  $onInit() {
    // Empty block
  }

  getFormattedIban(iban: string): string {
    return stringToIbanFormat(iban);
  }
}
