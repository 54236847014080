import { getUser } from '@/authentication/token.helper';
import { mcb } from 'mcb';
import { HttpNotFoundException } from '../exceptions/http-not-found.exception';
import { HttpConflictException } from '../exceptions/http-conflict.exception';
import { HttpException } from '../exceptions/http.exception';
import monitoringHelper from '@/monitoring/monitoring.helper';
import { uniq } from 'ramda';

export interface RequestConfig extends ng.IRequestConfig {
  resourceType?: string;
  include?: string[];
}

export class HttpService {
  static $inject = ['$q', '$http', '$state', '$window', 'mcbHttpTransformers'];

  protected resourceType: string;

  constructor(
    private q: ng.IQService,
    private http: ng.IHttpService,
    private $state: ng.ui.IStateService,
    private $window: ng.IWindowService,
    private transformers: mcb.http.ITransformers
  ) {}

  public request(config: RequestConfig): ng.IPromise<any> {
    const defer = this.q.defer<any>();

    if (Array.isArray(config.include)) {
      config.params = angular.extend(config.params || {}, {
        include: config.include.join(','),
      });
    }

    this.requestAndGetRawResponse(config)
      .then((response: ng.IHttpPromiseCallbackArg<any>) => {
        if (response && response.data) {
          let data = response.data;
          this.processRelationships(data);
          defer.resolve(data);
        } else {
          defer.resolve(response);
        }
      })
      .catch(
        (response: ng.IHttpPromiseCallbackArg<mcb.http.IError[]>): void => {
          defer.reject(this.analyzeFailedRequestResponse(response));
        }
      );
    return defer.promise;
  }

  public requestAndGetRawResponse(config: RequestConfig): ng.IPromise<any> {
    const { decamelize, dejsonapify, toJson, fromJson, camelize, jsonapify } =
      this.transformers;

    const newConfig = {
      ...config,
      transformRequest: [decamelize, dejsonapify, toJson],
      transformResponse: [fromJson, camelize, jsonapify],
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        ...config.headers,
      },
    };

    if (newConfig.data && !newConfig.data.$type && newConfig.resourceType) {
      newConfig.data.$type = newConfig.resourceType;
    }

    return this.requestWithSentry(newConfig);
  }

  /**
   * For apis returning plain json
   * @param config
   */
  public requestJson(config: RequestConfig): ng.IPromise<any> {
    const { decamelize, toJson, fromJson, camelize } = this.transformers;

    const newConfig = {
      ...config,
      transformRequest: [decamelize, toJson],
      transformResponse: [fromJson, camelize],
      headers: {
        'Content-Type': 'application/json',
        ...config.headers,
      },
    };

    return this.requestWithSentry(newConfig).then(({ data }) => data);
  }

  public all(...args) {
    return this.q.all.apply(this, args);
  }

  public when(...args) {
    return this.q.when.apply(this, args);
  }

  public defer(...args) {
    return this.q.defer.apply(this, args);
  }

  public reject(...args) {
    return this.q.reject.apply(this, args);
  }

  /**
   * Report http errors to Sentry
   *
   * @param {RequestConfig} config
   * @returns {angular.IPromise<any>}
   */
  private requestWithSentry(config: RequestConfig): ng.IPromise<any> {
    const { http, q } = this;

    return http(config).catch(
      (
        response: ng.IHttpPromiseCallbackArg<
          mcb.http.IError[] | mcb.http.IError
        >
      ): any => {
        const data = (response.data && (response.data[0] || response.data)) || {
          title: 'NO TITLE PROVIDED',
          detail: JSON.stringify(response),
        };
        const user = getUser();
        const curState = this.$state.current;
        monitoringHelper.reportMessage(`API Error ${response.status}`, {
          level: 'error',
          tags: {
            userRole: user && user.currentRole,
            uiState: curState && curState.name,
            uiUrl: this.$window.location.href,
          },
          extra: {
            httpStatus: response.status,
            errorTitle: data.title,
            errorDescr: data.detail,
          },
        });

        // Pass the error forward
        return q.reject(response);
      }
    );
  }

  /**
   * Very raw implementation of processing resource relationships
   * @param rootData
   * @returns {any}
   */
  private processRelationships(rootData): any {
    if (!rootData.$included) {
      return rootData;
    }
    if (Array.isArray(rootData)) {
      rootData.forEach((obj) => {
        this.extractRelationships(obj, rootData['$included']);
      });
    } else {
      this.extractRelationships(rootData, rootData['$included']);
    }
    delete rootData.$included;
    return rootData;
  }

  private analyzeFailedRequestResponse(
    response: ng.IHttpPromiseCallbackArg<mcb.http.IError[]>
  ): HttpException {
    switch (response.status) {
      case 404: {
        return new HttpNotFoundException(response);
      }
      case 409: {
        return new HttpConflictException(response);
      }
      default: {
        return new HttpException(response);
      }
    }
  }

  /**
   * Extracts relationship data from included and put it in the object
   * @param rootData
   * @param included
   * @returns {any}
   */
  private extractRelationships(rootData, included): any {
    if (!rootData.$relationships) {
      return rootData;
    }

    let relationships = rootData.$relationships;
    for (let key in relationships) {
      if (relationships.hasOwnProperty(key)) {
        let relationship = relationships[key];
        if (relationship.data) {
          if (Array.isArray(relationship.data)) {
            let resolvedRelationshipData = [];
            relationship.data.forEach((relationshipData) => {
              included.forEach((includedData) => {
                if (
                  this.checkIncludedAndRelationship(
                    includedData,
                    relationshipData
                  )
                ) {
                  resolvedRelationshipData.push(
                    this.transformers.jsonapify({ data: includedData })
                  );
                }
              });
            });
            // Use "uniq" because there is case where a pair {id, type} doesn't work as a uniq identifier (look at Zircle merchant and its partner objects)
            // https://github.bus.zalan.do/WILMA/mc-package-admin/issues/1479
            rootData[key] = uniq(resolvedRelationshipData);
          } else {
            let relationshipData = relationship.data;
            included.forEach((includedData) => {
              if (
                this.checkIncludedAndRelationship(
                  includedData,
                  relationshipData
                )
              ) {
                rootData[key] = this.transformers.jsonapify({
                  data: includedData,
                });
              }
            });
          }
        }
      }
    }
    delete rootData.$relationships;
    return rootData;
  }

  private checkIncludedAndRelationship(
    includedData,
    relationshipData
  ): boolean {
    return (
      includedData.id === relationshipData.id &&
      includedData.type === relationshipData.type
    );
  }
}
