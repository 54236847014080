import viewTitleDirective from './viewTitleDirective';
import viewTitleProvider from './viewTitleProvider';
import viewTitleReferenceDirective from './viewTitleReferenceDirective';
/**
 * @ngdoc module
 * @name mcb.components.viewTitle
 */
export default angular
  .module('mcb.components.viewTitle', [])
  .directive('mcbViewTitle', viewTitleDirective)
  // @ts-ignore
  .provider('mcbViewTitle', viewTitleProvider)
  .directive('mcbViewTitleReference', viewTitleReferenceDirective);
