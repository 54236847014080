<template lang="pug">
  span(:data-dc-has-tooltip="infoText").mcp-admin__field-info.dc--has-tooltip.dc--has-tooltip--top-right.dc--has-tooltip--large
    i.dc-icon.dc-icon--info
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'InfoTooltip',
  props: {
    infoText: String,
  },
});
</script>
