<template lang="pug">
v-app(id="vue-merchants-app")
  Appbar

  v-main
    v-container(fluid)
      Toolbar

      Filterbar.mt-n1(
        :disabled="false"
        :default-search="filters.name"
        :default-type="filters.types"
        :default-status="filters.status"
        :default-sort="filters.sort"
        :default-factoring="filters.factoring"
        @search-input="onSearchInputChangeDebounced"
        @switch-merchant="goToMerchant"
        @type-change="filters.types=$event"
        @status-change="filters.status=$event"
        @sort-change="filters.sort=$event"
        @factoring-change="filters.factoring=$event"
        )

      div.pb-6.title.secondary--text(v-if="!totalCount")
          | No merchants found

      div(v-if="merchants")
        SummaryCard.mb-4(
          data-testid="merchant-summary-card"
          v-for="merchant in merchants"
          :key="merchant.id"
          :merchant="merchant"
          :activeNameFilter="filters.name"
          :merchantPageRouteState="getMerchantPageRouteState(merchant)"
          :contractPageRouteState="contractPageRouteState"
          canSeeGeneralData
          canEdit
        )

      Pagination(
        v-if="merchants && merchants.length"
        :showingFrom="showingFrom"
        :showingTo="showingTo"
        :totalCount="totalCount"
        @select-page-size="pageSize=$event"
        @previous-page="page--"
        @next-page="page++"
      )
</template>

<script lang="ts">
import Vue from 'vue';
import debounce from 'lodash/debounce';
import permissionsService from 'Common/services/permissions.service';
import Toolbar from 'Common/components/toolbar/toolbar.container.vue';
import Appbar from 'Common/components/appbar/appbar.container.vue';
import Pagination from 'Common/components/merchant-account-pagination/pagination.vue';
import SummaryCard, {
  MerchantSummaryCardT,
  MerchantSummaryCardContractT,
} from './summary/merchant-summary-card.vue';
import Filterbar from './filterbar/filterbar.vue';
import { fetchRetailers } from 'Api/endpoints/contract/retailer.endpoint.es';
import { fetchMerchants } from 'Api/endpoints/merchant/merchant.endpoint.es';
import {
  MerchantProfile,
  MerchantProfileJsonT,
} from 'Api/models/MerchantProfile';
import { canEnableVatCollection } from '@/feature-flags';

function getRetailerName(name: string, fulfilmentType: string): string {
  const retailerName = name === 'Zalando Fashion Store' ? 'ZALANDO' : name;
  const fulfilmentTypeToLabelMap = {
    ZALANDO: 'ZFS',
    PARTNER: 'PF',
  };
  return retailerName + ' ' + fulfilmentTypeToLabelMap[fulfilmentType];
}
const filters = {
  sort: '-modified_at',
  status: '',
  types: '',
  name: '',
  factoring: '',
};

export default Vue.extend({
  components: {
    Appbar,
    Toolbar,
    Filterbar,
    SummaryCard,
    Pagination,
  },
  data() {
    return {
      rawMerchants: [],
      retailers: [],
      page: 0,
      pageSize: 10,
      totalCount: 0,
      filters,
      merchantPageRouteState: null,
      contractPageRouteState: null,
      isVatCollectionEnabled: canEnableVatCollection(null),
    };
  },
  computed: {
    showingFrom(): number {
      return this.pageSize * this.page + 1;
    },
    showingTo(): number {
      return Math.min(
        this.totalCount,
        this.pageSize * this.page + this.pageSize
      );
    },
    merchants(): MerchantSummaryCardT[] {
      const retailers = this.retailers;
      const rawMerchants = this.rawMerchants as MerchantProfileJsonT[];

      if (!rawMerchants.length || !retailers.length) {
        return [];
      }

      return rawMerchants.map((merchant): MerchantSummaryCardT => {
        return {
          legalEntityId: merchant.legalEntityId,
          merchantType: merchant.merchantType,
          id: merchant.id,
          companyName: merchant.companyName,
          name: merchant.name,
          modifiedAt: merchant.modifiedAt,
          modifiedBy: merchant.modifiedBy,
          serviceLevel: merchant.serviceLevel,
          factoring: merchant.factoring,
          status: merchant.status,
          contracts: merchant.contracts
            // there are contracts that refer to non-existing retailers (Backend needs cleenup)
            // thus we need exclude those contracts
            // https://github.bus.zalan.do/WILMA/merchant-profile/issues/1600
            .filter((contract): boolean => {
              return !!retailers.find(
                ({ id }): boolean => contract.retailerId === id
              );
            })
            // there are contracts that refer to non-existing sales channels (Backend needs cleenup)
            // thus we need to exclude those contracts
            // https://github.bus.zalan.do/WILMA/merchant-profile/issues/1600
            .filter((contract): boolean => {
              if (contract?.integrationObjectStage === 'Cancelled') {
                return false;
              }
              const retailer = retailers.find(
                ({ id }): boolean => contract.retailerId === id
              );
              return !!retailer.salesChannels.find(
                ({ id }): boolean => contract.salesChannelId === id
              );
            })
            .map((contract): MerchantSummaryCardContractT => {
              const retailer = retailers.find(
                ({ id }): boolean => contract.retailerId === id
              );
              const salesChannel = retailer.salesChannels.find(
                ({ id }): boolean => contract.salesChannelId === id
              );

              return {
                id: contract.id,
                status: contract.status,
                salesChannel: salesChannel.countryCode,
                retailer: {
                  name: getRetailerName(
                    retailer.name,
                    contract.fulfillmentType
                  ),
                },
              };
            }),
        };
      });
    },
  },
  watch: {
    page: 'reloadMerchants',
    pageSize: 'reloadMerchants',
    'filters.sort': 'reloadMerchants',
    'filters.types': 'reloadMerchants',
    'filters.status': 'reloadMerchants',
    'filters.name': 'reloadMerchants',
    'filters.factoring': 'reloadMerchants',
  },
  beforeCreate() {
    if (this.$route.query.search) {
      filters.name = this.$route.query.search as string;
    }
  },
  mounted() {
    this.merchantPageRouteState = ((): string => {
      if (permissionsService.hasEditPermissions()) {
        return this.isVatCollectionEnabled
          ? 'mcp.admin.merchant.general.business-details'
          : 'mcp.admin.merchant.general.company-details';
      }

      if (permissionsService.hasFinancialApprovalPermissions()) {
        return this.isVatCollectionEnabled
          ? 'mcp.admin.merchant.general.business-details-approval'
          : 'mcp.admin.merchant.general.company-details-approval';
      }

      if (permissionsService.isReadOnly()) {
        return 'mcp.admin.merchant.general.metadata-readonly';
      }

      return null;
    })();

    this.contractPageRouteState =
      permissionsService.hasFinancialApprovalPermissions()
        ? 'mcp.admin.merchant.contract.approval'
        : 'mcp.admin.merchant.contract.overview';

    fetchRetailers().then((data) => {
      this.retailers = data;
    });

    this.reloadMerchants();
  },
  methods: {
    reloadMerchants(): void {
      fetchMerchants({
        'page[size]': this.pageSize,
        'page[number]': this.page,
        ...this.filters,
      }).then((data) => {
        this.rawMerchants = data.items;
        this.totalCount = data.meta.count;
      });
    },

    onSearchInputChangeDebounced: debounce(function (searchString) {
      this.filters.name = searchString;
    }, 500),

    // TODO: bring it back after the release of VAT.
    goToMerchant(merchantId: string): void {
      window.location.assign(
        this.$router.resolve({
          name: this.merchantPageRouteState,
          params: { merchantId },
        }).href
      );
    },
    getMerchantPageRouteState(merchant: MerchantSummaryCardT) {
      if (permissionsService.hasEditPermissions()) {
        return canEnableVatCollection(merchant)
          ? 'mcp.admin.merchant.general.business-details'
          : 'mcp.admin.merchant.general.company-details';
      }

      if (permissionsService.hasFinancialApprovalPermissions()) {
        return canEnableVatCollection(merchant)
          ? 'mcp.admin.merchant.general.business-details-approval'
          : 'mcp.admin.merchant.general.company-details-approval';
      }

      if (permissionsService.isReadOnly()) {
        return 'mcp.admin.merchant.general.metadata-readonly';
      }

      return null;
    },
  },
});
</script>

<style lang="scss" scoped></style>
