import permissionsService from 'Common/services/permissions.service';
export class PermissionAreaComponent implements ng.IComponentOptions {
  static Factory() {
    return new PermissionAreaComponent();
  }

  bindings: { [binding: string]: string } = {
    check: '<',
  };
  transclude = true;
  controller = PermissionAreaController;
  template = '<div ng-if="$ctrl.allow"><ng-transclude></ng-transclude></div>';
}

class PermissionAreaController {
  allow = false;
  check: string | string[];
  constructor() {}

  $onChanges() {
    if (typeof this.check === 'string' || this.check instanceof String) {
      this.allow = this.checkPermission(this.check);
    }
    if (this.check instanceof Array) {
      this.allow = (<string[]>this.check).some((role) => {
        return this.checkPermission(role);
      });
    }
  }

  private checkPermission(role): boolean {
    switch (role) {
      case 'canView':
        return permissionsService.hasViewPermission();
      case 'canEdit':
        return permissionsService.hasEditPermissions();
      case 'canEditMasterdata':
        return permissionsService.hasEditMDPermissions();
      case 'canApprove':
        return permissionsService.hasFinancialApprovalPermissions();
      case 'readonly':
        return permissionsService.isReadOnly();
      default:
        return false;
    }
  }
}
