var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      attrs: { novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("div", { staticClass: "mcp-admin-merchant-details-section" }, [
        _c("div", { staticClass: "mcp-admin-merchant-details-section-form" }, [
          _c("div", { staticClass: "mcp-admin__form-field field-wrapper" }, [
            _c("label", { staticClass: "dc-label" }, [
              _vm._v("Validity Date for Migration to Factoring 2.0"),
            ]),
            _c(
              "div",
              { staticClass: "mcp-admin-factoring__validity-date" },
              [
                _c("Datepicker", {
                  attrs: {
                    "data-testid": "migration-validity-date",
                    format: "yyyy-MM-dd",
                    placeholder: "Date",
                    "disabled-dates": _vm.disabledDates,
                    disabled: true,
                    "wrapper-class":
                      _vm.fms !== "POSSIBLE" ? "disabled-date" : "",
                  },
                  model: {
                    value: _vm.validityDate,
                    callback: function ($$v) {
                      _vm.validityDate = $$v
                    },
                    expression: "validityDate",
                  },
                }),
              ],
              1
            ),
            _vm.fms === "PENDING"
              ? _c(
                  "div",
                  {
                    staticClass: "dc--text info",
                    attrs: { "data-testid": "pending-message" },
                  },
                  [
                    _vm._v(
                      "The migration to Factoring 2.0 is already initiated for the partner and waiting for financial approval. Contact the "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "mailto: accounting-partnerprogramm@zalando.de",
                        },
                      },
                      [_vm._v("Partner Program Accounting Team")]
                    ),
                    _vm._v(" for details."),
                  ]
                )
              : _vm._e(),
            _vm.fms === "APPROVED"
              ? _c(
                  "div",
                  {
                    staticClass: "dc--text success",
                    attrs: { "data-testid": "approved-message" },
                  },
                  [
                    _vm._v(
                      "Yay! Migration to new Factoring format will start from the above mentioned date."
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }