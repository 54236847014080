export default {
  /**
   * Create a new instance of an object
   * from an existing instance of a "class-like" like object or from a "class-like" reference
   * May not work with "Native Types" as Array, Date .... etc.
   *
   * @param {Object|Function} adamo
   * @param {Array} [args] - constructor args
   * @returns {Object} eva
   */
  genesis: (adamo, args) => {
    let eva;
    let prototype = angular.isFunction(adamo)
      ? adamo.prototype
      : Object.getPrototypeOf(adamo);
    eva = Object.create(prototype);
    eva.constructor.apply(eva, args);
    return eva;
  },
};
