<template lang="pug">
div
  h1.display-1.mx-6 {{ accountId ? 'Edit' : 'Create' }} Merchant Account

  div
    //- Name
    MerchantAccountNameContainer.mx-6.mb-12(
      :default-value="defaultName"
      @validate="isNameValid = $event"
      :disabled="isSaveInProgress"
      @input="name = $event"
      )

    //- Legal Entities
    div.mx-6.mb-12
      div.headline.mb-6 Add Legal Entities
      LegalEntitiesContainer(
        v-model="legalEntitiesIds"
        :default-value="defaultLegalEntitiesIds"
        :account-id="accountId"
        :disabled="isSaveInProgress"
        :removable = "canDeleteLegalEntities"
        @add="cacheLegalEntity"
        )

      div(v-if="legalEntitiesIds.size")
        | Added Legal Entities
        span(v-if="name") &nbsp;under&nbsp;
          span.font-weight-bold {{ name }}

      //- Selected Legal Entities list
      v-row
        v-col.py-0(
          v-for="(legalEntity, index) in legalEntities"
          cols="6"
          :key="(legalEntity && legalEntity.id) || index"
          )
          //- No divider under last 2 items
          //- Legal entities that are added but not saved can always be un-added.
          LegalEntity(
            :removable = "canDeleteLegalEntities || (legalEntity && !defaultLegalEntitiesIds.has(legalEntity.id))"
            :show-divider="index + 2 < legalEntities.length"
            :legalEntity="legalEntity"
            @remove="removeLegalEntity"
          )

    //- Contact Details
    div.mx-6
      div.headline.mb-6 Primary Contact Details
      Contact(
        :default-value="defaultContact"
        :contacts="contacts"
        :disabled="isSaveInProgress || !canEditContact"
        @input="contact = $event"
      )

  //- Action items
  div.float-right.mx-6.mt-12.mb-5
    v-btn(text @click="$emit('canceled')") Cancel

    v-btn.ml-4(
      color="primary"
      depressed
      :disabled="isSaveDisabled"
      :loading="isSaveInProgress"
      @click="save"
      ) {{ accountId ? 'Save' : 'Create' }} Merchant Account

  //- Notifications
  div
    v-snackbar(
      v-if="notify === 'NOTIFY_LOAD_LEGAL_ENTITIES_DETAILED_FAILED'"
      v-model="notify"
      color="reject"
      bottom
      :timeout="-1"
    )
      | Error while loading legal entities detailed information
      v-btn(@click="notify = false" text) Close

    v-snackbar(
      v-if="notify === 'NOTIFY_SAVE_FAILED'"
      v-model="notify"
      color="reject"
      bottom
      :timeout="-1"
    )
      template(v-if="accountId") Error while updating the account
      template(v-else) Error while creating the account
      v-btn(@click="notify = false" text) Close

    v-snackbar(
      v-if="notify === 'NOTIFY_SAVE_SUCCESS'"
      v-model="notify"
      color="success"
      bottom
      :timeout="2000"
    )
      template(v-if="accountId") The account {{ name }} has been updated successfully
      template(v-else) The account {{ name }} has been created successfully
</template>

<script lang="ts">
import Vue from 'vue';
import LegalEntity from 'Common/components/legal-entity/legal-entity.vue';
import MerchantAccountNameContainer from '../merchant-account-name/merchant-account-name.container.vue';
import LegalEntitiesContainer from '../legal-entities/legal-entities.container.vue';
import Contact from '../contact/merchant-account-contact.vue';
import { fetchLegalEntityDetailed } from 'Api/endpoints/legal-entity/legal-entity.endpoint';
import {
  saveMerchantAccount,
  updateMerchantAccount,
} from 'Api/endpoints/merchant-account/merchant-account.endpoint';
import LegalEntityDetailed from 'Api/models/LegalEntityDetailed';
import LegalEntityContact from 'Api/models/LegalEntityContact';

export default Vue.extend({
  components: {
    MerchantAccountNameContainer,
    LegalEntitiesContainer,
    LegalEntity,
    Contact,
  },
  props: {
    accountId: {
      type: String,
      required: false,
      default: null,
    },
    defaultName: {
      type: String,
      required: true,
    },
    defaultContact: {
      type: Object as () => LegalEntityContact | null,
      required: false,
      default: null,
    },
    defaultLegalEntities: {
      type: Array as () => LegalEntityDetailed[],
      required: true,
    },
    canDeleteLegalEntities: {
      type: Boolean,
      required: true,
    },
    canEditContact: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      notify: false,
      name: '',
      legalEntitiesIds: new Set() as Set<string>,
      defaultLegalEntitiesIds: new Set() as Set<string>,
      contact: null as LegalEntityContact,
      // A cache of legal entities detailed info that have ever been selected
      cachedDetailedLegalEntities: new Map() as Map<
        string,
        LegalEntityDetailed | null
      >,
      isSaveInProgress: false,
      isNameValid: false,
    };
  },
  computed: {
    legalEntities(): LegalEntityDetailed[] {
      // [...this.legalEntitiesIds] doesn't work in tests for some reasone
      return Array.from(this.legalEntitiesIds).map((id) =>
        this.cachedDetailedLegalEntities.get(id)
      );
    },
    contacts(): LegalEntityContact[] {
      return Array.from(this.legalEntitiesIds) // [...this.legalEntitiesIds] doesn't work in tests for some reasone
        .map((id) => this.cachedDetailedLegalEntities.get(id))
        .filter((item) => !!item)
        .map((item) => item.contacts)
        .reduce((accum, val) => [...accum, ...val], []);
    },
    isSaveDisabled(): boolean {
      return (
        !(this.legalEntitiesIds.size && this.isNameValid && this.contact) ||
        this.isSaveInProgress
      );
    },
  },
  mounted() {
    this.name = this.defaultName;
    this.isNameValid = !!this.defaultName;
    this.contact = this.defaultContact;

    this.legalEntitiesIds = new Set(this.legalEntitiesIds);
    this.cachedDetailedLegalEntities = new Map(
      this.cachedDetailedLegalEntities
    );

    this.defaultLegalEntities.forEach((entity) => {
      this.legalEntitiesIds.add(entity.id);
      this.cachedDetailedLegalEntities.set(entity.id, entity);
    });
    this.defaultLegalEntitiesIds = new Set(this.legalEntitiesIds);
  },
  methods: {
    cacheLegalEntity(id: string): Promise<void> {
      if (!this.cachedDetailedLegalEntities.has(id)) {
        this.cachedDetailedLegalEntities = new Map(
          this.cachedDetailedLegalEntities
        ).set(id, null);

        return fetchLegalEntityDetailed(id)
          .then((data) => {
            this.cachedDetailedLegalEntities = new Map(
              this.cachedDetailedLegalEntities
            ).set(id, data);
          })
          .catch(() => {
            this.notify = 'NOTIFY_LOAD_LEGAL_ENTITIES_DETAILED_FAILED';
          });
      }

      return Promise.resolve();
    },
    removeLegalEntity(id: string): void {
      this.legalEntitiesIds = new Set(this.legalEntitiesIds);
      this.legalEntitiesIds.delete(id);
    },
    save(): Promise<void> {
      const { name, contact, legalEntitiesIds: leSet, accountId } = this;
      const legalEntitiesIds = Array.from(leSet) as string[]; // [...leSet] doesn't work in tests for some reason

      this.isSaveInProgress = true;

      let promise;

      if (accountId) {
        let isNameChanged = name !== this.defaultName;
        let requestPayload = {
          accountId,
          name: isNameChanged ? name : undefined,
          legalEntitiesIds,
          ...(this.canEditContact && { contact }),
        };

        promise = updateMerchantAccount(requestPayload);
      } else {
        promise = saveMerchantAccount(name, contact, legalEntitiesIds);
      }

      return promise
        .then(() => {
          this.notify = 'NOTIFY_SAVE_SUCCESS';
          // Delay (wait a bit and show notification)
          return new Promise<void>((resolve): void => {
            setTimeout(() => resolve(), 1000);
          });
        })
        .then(() => this.$emit('saved'))
        .catch(() => (this.notify = 'NOTIFY_SAVE_FAILED'))
        .finally(() => (this.isSaveInProgress = false));
    },
  },
});
</script>

<style lang="scss" scoped></style>
