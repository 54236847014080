<template>
  <v-app>
    <div
      class="mcp-admin-register mx-auto"
      style="max-width: 800px; min-width: 600px"
    >
      <v-breadcrumbs :items="breadcrumbItems" class="ml-n5" />

      <v-card>
        <v-card-title>Create Connected Retail Merchant</v-card-title>

        <v-card-text>
          <form novalidate>
            <input-component
              :merchants="shopNameSuggestions"
              :label="`SHOP NAME`"
              :placeholder="`e.g. Zalando`"
              :is-submitted="isSubmitted"
              :additional-merchants-count="additionalMerchantsCount"
              @input="handleShopNameChange"
              @blur="resetSuggestions"
            />

            <input-component
              class="mt-2"
              :merchants="nameSuggestions"
              :label="`LEGAL NAME`"
              :placeholder="`e.g. Zalando SE`"
              :is-submitted="isSubmitted"
              :additional-merchants-count="additionalMerchantsCount"
              @input="handleLegalNameChange"
              @blur="resetSuggestions"
            />

            <div class="mt-2">
              <label for="factoring-type" class="dc-label dc-label--required">
                FACTORING TYPE
              </label>
              <v-select
                id="factoring-type"
                v-model="selectedFactoringType"
                :items="factoringTypes"
              />
            </div>
          </form>
        </v-card-text>

        <div class="d-flex justify-end pb-4 px-4">
          <v-btn
            class="primary"
            :loading="isSubmitDisabled"
            @click="handleSubmit"
          >
            Save
          </v-btn>
        </div>
      </v-card>
    </div>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import MerchantRegisterInputVue from './merchant-register-input.component.vue';
import { MerchantTypeT, FactoringTypeT } from 'Api/models/MerchantProfile';
import {
  fetchMerchants,
  saveMerchant,
} from 'Api/endpoints/merchant/merchant.endpoint.es';
import debounce from 'lodash/debounce';
import Swal from 'sweetalert2';
import { MCP_MERCHANT_TYPE_OPTIONS_WITHOUT_ALL } from '@/common/constants/merchant-types.constant';

const MERCHANT_SUGGESTION_LIMIT = 5;
const DEBOUNCE_DELAY_MS = 500;

interface FactoringTypeOptionT {
  value: FactoringTypeT;
  text: string;
}
const factoringTypeOptions: FactoringTypeOptionT[] = [
  { value: '1.0', text: '1.0' },
  { value: '2.0', text: '2.0' },
];

export default Vue.extend({
  name: 'MerchantRegister',
  components: {
    'input-component': MerchantRegisterInputVue,
  },
  props: {
    type: String,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Merchants List',
          disabled: false,
          // @ts-ignore
          href: this.$root.getUrl('mcp.admin.merchants'),
        },
        {
          text: 'New Merchant',
          disabled: true,
        },
      ],
      additionalMerchantsCount: 0,
      shopNameSuggestions: [],
      nameSuggestions: [],
      shopName: '',
      name: '',
      selectedMerchantType: 'CONNECTED_RETAIL' as MerchantTypeT,
      selectedFactoringType: '2.0' as FactoringTypeT,
      isSubmitted: false,
      isSubmitDisabled: false,
      merchantTypes: MCP_MERCHANT_TYPE_OPTIONS_WITHOUT_ALL,
      factoringTypes: factoringTypeOptions,
    };
  },
  methods: {
    handleSubmit(event): void {
      event.preventDefault();
      this.isSubmitted = true;
      if (!this.shopName || !this.name) {
        return;
      }
      this.isSubmitDisabled = true;

      saveMerchant({
        name: this.shopName,
        companyName: this.name,
        merchantType: this.selectedMerchantType,
        factoring: this.selectedFactoringType,
      })
        .then((data) => {
          Swal.fire({
            html: 'Merchant has been successfully saved!',
            timer: 2000,
            position: 'top',
            showConfirmButton: false,
            toast: true,
          });

          this.$root.goto('mcp.admin.merchant.general.sales-channels', {
            merchantId: data.id,
          });
        })
        .catch(() => {
          this.isSubmitDisabled = false;
          Swal.fire({
            html: 'Something went wrong while creating new merchant. Please try again.',
            timer: 2000,
            position: 'top',
            showConfirmButton: false,
            toast: true,
          });
        });
    },
    // eslint-disable-next-line max-len
    // It's debounced so the user have a chance to click on suggested links before pop-up disappears due to blur event which triggers this method
    resetSuggestions: debounce(function () {
      this.shopNameSuggestions = [];
      this.nameSuggestions = [];
      this.additionalMerchantsCount = 0;
    }, DEBOUNCE_DELAY_MS),

    handleShopNameChange(value): void {
      this.shopName = value;
      value
        ? this.fetchSuggestions('shopName', value)
        : this.resetSuggestions();
    },

    handleLegalNameChange(value): void {
      this.name = value;
      value ? this.fetchSuggestions('name', value) : this.resetSuggestions();
    },

    fetchSuggestions: debounce(function (fieldName, value) {
      fetchMerchants({ name: value, 'page[size]': MERCHANT_SUGGESTION_LIMIT })
        .then(({ items, meta }) => {
          this[`${fieldName}Suggestions`] = items;
          this.additionalMerchantsCount =
            meta.count - MERCHANT_SUGGESTION_LIMIT;
        })
        .catch(() => {
          Swal.fire({
            html: 'Something went wrong while fetching suggestions. Please try again.',
            timer: 2000,
            position: 'top',
            showConfirmButton: false,
            toast: true,
          });
        });
    }, DEBOUNCE_DELAY_MS),
  },
});
</script>

<style lang="scss" scoped>
.v-alert.v-sheet {
  background-color: #fdebe2 !important;
  font-size: 12px;
}
.v-input.v-select::v-deep {
  padding-top: 0;
  div.v-select__slot {
    background-color: #f6f6f6;
    height: 36px;
  }
  div.v-select__selection {
    padding-left: 8px;
  }
}
</style>
