<template lang="pug">
v-toolbar.root.grey.lighten-5(flat)
  v-toolbar-title.headline {{ title }}

  v-spacer

  v-btn(
    v-if="hasNewMerchantBtn"
    depressed
    color="primary"
    @click="$emit('add-merchant')"
    ref="new-merchant"
    )
    v-icon(small) add
    span New merchant

  v-btn.ml-4(
    v-if="hasNewMerchantAccountBtn"
    depressed
    color="primary"
    @click="$emit('add-merchant-account')"
    ref="new-merchant-account"
    )
    v-icon(small) add
    span New Merchant Account
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    hasNewMerchantBtn: Boolean,
    hasNewMerchantAccountBtn: Boolean,
  },
});
</script>

<style lang="scss" scoped>
.root ::v-deep .v-toolbar__content {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
