import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        VRow,
        [
          _c(VCol, { staticClass: "pb-0", attrs: { md: "12" } }, [
            _c("h4", [
              _vm._v("Bank Account: " + _vm._s(_vm.bankAccount.currencyCode)),
            ]),
          ]),
          _c(
            VCol,
            { staticClass: "pb-0", attrs: { md: "6" } },
            [
              _c(VTextField, {
                attrs: {
                  label: "IBAN",
                  value: _vm.bankAccount.iban,
                  rules: [_vm.$options.filters.formFieldRequired],
                  filled: "",
                },
                on: {
                  change: (value) =>
                    _vm.$emit(
                      "on-change",
                      _vm.bankAccount.currencyCode,
                      "iban",
                      value
                    ),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VRow,
        [
          _c(
            VCol,
            { staticClass: "pb-0" },
            [
              _c(VTextField, {
                attrs: {
                  label: "Bank name",
                  value: _vm.bankAccount.bankName,
                  rules: [_vm.$options.filters.formFieldRequired],
                  filled: "",
                },
                on: {
                  input: (value) =>
                    _vm.$emit(
                      "on-change",
                      _vm.bankAccount.currencyCode,
                      "bankName",
                      value
                    ),
                },
              }),
            ],
            1
          ),
          _c(
            VCol,
            { staticClass: "pb-0" },
            [
              _c(VTextField, {
                attrs: {
                  label: "Account holder name",
                  value: _vm.bankAccount.accountHolder,
                  rules: [_vm.$options.filters.formFieldRequired],
                  filled: "",
                },
                on: {
                  input: (value) =>
                    _vm.$emit(
                      "on-change",
                      _vm.bankAccount.currencyCode,
                      "accountHolder",
                      value
                    ),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VRow,
        [
          _c(
            VCol,
            { staticClass: "pt-0", attrs: { md: "6" } },
            [
              _c("BankDocumentUpload", {
                attrs: {
                  "document-id": _vm.bankAccount.confirmationDocumentId,
                },
                on: {
                  "on-upload-finish": (documentId) =>
                    _vm.$emit(
                      "on-change",
                      _vm.bankAccount.currencyCode,
                      "confirmationDocumentId",
                      documentId
                    ),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }