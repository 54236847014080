import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(VSelect, {
    ref: "select",
    attrs: {
      items: _vm.items,
      value: _vm.value,
      disabled: _vm.disabled,
      height: "40px",
      "background-color": "secondaryLight",
      "hide-details": "",
    },
    on: { change: _vm.change },
    scopedSlots: _vm._u([
      {
        key: "prepend",
        fn: function () {
          return [
            _c(
              VContainer,
              { staticClass: "prepended", attrs: { "fill-height": "" } },
              [
                _c("span", { staticClass: "caption secondary--text" }, [
                  _vm._v(_vm._s(_vm.label)),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "selection",
        fn: function ({ item }) {
          return [
            _c(
              "span",
              {
                staticClass: "selection",
                class: {
                  "secondary--text": !_vm.disabled,
                  "disabled--text": _vm.disabled,
                },
              },
              [_vm._v(_vm._s(item.text))]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }