var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "mcp-admin-merchant-details-section" }, [
      _c("div", { staticClass: "mcp-admin-merchant-details-section-form" }, [
        _c("div", [
          _c("span", { staticClass: "dc-label" }, [_vm._v("Send Email")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.sendDummyEmailField,
                expression: "sendDummyEmailField",
              },
            ],
            staticClass: "dc-radio",
            attrs: { type: "radio", id: "config-send-customer-email" },
            domProps: {
              value: false,
              checked: _vm._q(_vm.sendDummyEmailField, false),
            },
            on: {
              change: function ($event) {
                _vm.sendDummyEmailField = false
              },
            },
          }),
          _c(
            "label",
            {
              staticClass: "dc-label",
              attrs: { for: "config-send-customer-email" },
            },
            [_vm._v("Customer's email")]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.sendDummyEmailField,
                expression: "sendDummyEmailField",
              },
            ],
            staticClass: "dc-radio",
            attrs: { type: "radio", id: "config-send-dummy-email" },
            domProps: {
              value: true,
              checked: _vm._q(_vm.sendDummyEmailField, true),
            },
            on: {
              change: function ($event) {
                _vm.sendDummyEmailField = true
              },
            },
          }),
          _c(
            "label",
            {
              staticClass: "dc-label",
              attrs: { for: "config-send-dummy-email" },
            },
            [_vm._v("Dummy email")]
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "button-wrapper" }, [
      _c(
        "button",
        {
          staticClass: "dc-btn dc-btn--primary",
          attrs: { type: "submit" },
          on: { click: _vm.save },
        },
        [_vm._v("Save configuration")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }