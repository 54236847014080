type EnvironmentT = 'PRODUCTION' | 'STAGING' | 'TEST' | 'DEV';

export const ENVIRONMENT: Record<EnvironmentT, string> = {
  PRODUCTION: 'Production',
  STAGING: 'Staging',
  TEST: 'Test',
  DEV: 'Development',
};

export const ENV_PRODUCTION: EnvironmentT = 'PRODUCTION';
export const ENV_STAGING: EnvironmentT = 'STAGING';
export const ENV_CURRENT: EnvironmentT =
  ((<any>window).__MC_INITIAL_STATE__ &&
    (<any>window).__MC_INITIAL_STATE__.clientConfig.env) ||
  'unknown';

export const MCP_INTEGRATION_TYPES = {
  DIRECT: 'DIRECT',
  PARTNER_PROVIDER: 'PARTNER_PROVIDER',
};

export const MCP_INTEGRATION_TYPE_LABELS = {
  [MCP_INTEGRATION_TYPES.DIRECT]: 'Direct',
  [MCP_INTEGRATION_TYPES.PARTNER_PROVIDER]: 'Partner Provider',
};

export const MCP_FF_TYPES = {
  ZFS: 'ZALANDO',
  PF: 'PARTNER',
};

export const MCP_FF_TYPE_NAMES = {
  [MCP_FF_TYPES.ZFS]: 'ZFS',
  [MCP_FF_TYPES.PF]: 'Partner Fulfillment',
};

export interface FulfillmentTypeT {
  $id: string;
  name: string;
}

export const FULFILLMENT_TYPES = [
  {
    $id: 'ZALANDO',
    name: 'ZFS',
  },
  {
    $id: 'PARTNER',
    name: 'Partner Fulfillment',
  },
] as FulfillmentTypeT[];

export const MCP_FF_TYPE_LABELS = {
  [MCP_FF_TYPES.ZFS]: 'ZFS',
  [MCP_FF_TYPES.PF]: 'PF',
};

export const MCP_MERCHANT_SERVICE_LEVELS = {
  PLATINUM: 'PLATINUM',
  GOLD: 'GOLD',
  SILVER: 'SILVER',
};

export const MCP_MERCHANT_SERVICE_LEVELS_LIST = [
  MCP_MERCHANT_SERVICE_LEVELS.PLATINUM,
  MCP_MERCHANT_SERVICE_LEVELS.GOLD,
  MCP_MERCHANT_SERVICE_LEVELS.SILVER,
];

const MERCHANT_TYPES_BRAND = 'BRAND';
const MERCHANT_TYPES_RETAILER = 'RETAILER';
const MERCHANT_TYPES_CONNECTED_RETAIL = 'CONNECTED_RETAIL';
const MERCHANT_TYPES_DISTRIBUTOR = 'DISTRIBUTOR';
const MERCHANT_TYPES_SERVICE_PROVIDER = 'SERVICE_PROVIDER';

export const MERCHANT_TYPES_MAP = {
  [MERCHANT_TYPES_BRAND]: 'BRAND',
  [MERCHANT_TYPES_RETAILER]: 'RETAILER',
  [MERCHANT_TYPES_CONNECTED_RETAIL]: 'CONNECTED RETAIL',
  [MERCHANT_TYPES_DISTRIBUTOR]: 'DISTRIBUTOR',
  [MERCHANT_TYPES_SERVICE_PROVIDER]: 'SERVICE PROVIDER',
};

export const MERCHANT_TYPES = [
  MERCHANT_TYPES_BRAND,
  MERCHANT_TYPES_RETAILER,
  MERCHANT_TYPES_CONNECTED_RETAIL,
  MERCHANT_TYPES_DISTRIBUTOR,
  MERCHANT_TYPES_SERVICE_PROVIDER,
];

const MD_EXPORT_LANGS_DE = 'de';
const MD_EXPORT_LANGS_EN = 'en';

export const MD_EXPORT_LANGS = [MD_EXPORT_LANGS_DE, MD_EXPORT_LANGS_EN];

export const MD_EXPORT_LANGS_MAP = {
  [MD_EXPORT_LANGS_DE]: 'German',
  [MD_EXPORT_LANGS_EN]: 'English',
};

// The constant is used to denote when a user pressed Cancel in the
// Confirmation dialog
export const USER_DID_NOT_CONFIRM_ERROR = 'USER_DID_NOT_CONFIRM_ERROR';

export const COUNTRY_AUSTRIA = 'AT';
export const COUNTRY_AUSTRALIA = 'AU';
export const COUNTRY_BELGIUM = 'BE';
export const COUNTRY_BULGARIA = 'BG';
export const COUNTRY_CANADA = 'CA';
export const COUNTRY_CHINA = 'CN';
export const COUNTRY_CROATIA = 'HR';
export const COUNTRY_CYPRUS = 'CY';
export const COUNTRY_CZECH_REPUBLIC = 'CZ';
export const COUNTRY_DENMARK = 'DK';
export const COUNTRY_ESTONIA = 'EE';
export const COUNTRY_FINLAND = 'FI';
export const COUNTRY_FRANCE = 'FR';
export const COUNTRY_GERMANY = 'DE';
export const COUNTRY_GIBRALTAR = 'GI';
export const COUNTRY_GREECE = 'GR';
export const COUNTRY_HONG_KONG = 'HK';
export const COUNTRY_HUNGARY = 'HU';
export const COUNTRY_IRELAND = 'IE';
export const COUNTRY_ITALY = 'IT';
export const COUNTRY_INDIA = 'IN';
export const COUNTRY_JAPAN = 'JP';
export const COUNTRY_LATVIA = 'LV';
export const COUNTRY_LEBANON = 'LB';
export const COUNTRY_LIECHTENSTEIN = 'LI';
export const COUNTRY_LITHUANIA = 'LT';
export const COUNTRY_LUXEMBOURG = 'LU';
export const COUNTRY_MALTA = 'MT';
export const COUNTRY_MAURITIUS = 'MU';
export const COUNTRY_MONACO = 'MC';
export const COUNTRY_NETHERLANDS = 'NL';
export const COUNTRY_NORWAY = 'NO';
export const COUNTRY_POLAND = 'PL';
export const COUNTRY_PORTUGAL = 'PT';
export const COUNTRY_ROMANIA = 'RO';
export const COUNTRY_SINGAPORE = 'SG';
export const COUNTRY_SLOVENIA = 'SI';
export const COUNTRY_SLOVAKIA = 'SK';
export const COUNTRY_SPAIN = 'ES';
export const COUNTRY_SWEDEN = 'SE';
export const COUNTRY_SWITZERLAND = 'CH';
export const COUNTRY_UK = 'GB';
export const COUNTRY_TR = 'TR';
export const COUNTRY_US = 'US';
export const COUNTRY_MEXICO = 'MX';
export const COUNTRY_LKA = 'LK';
export const COUNTRY_SAU = 'SA';
export const COUNTRY_UAE = 'AE';

export interface Country {
  code: string;
  name: string;
  vatIdChecks: never[] | [number, string, string?];
  taxIdChecks: never[] | [number, string, string?];
}

export const CHECK_MAX_LENGTH = 'maxLength';
export const CHECK_EXACT_LENGTH = 'minLength';
export const CHECK_NUMERICAL = 'numerical';

const UNSORTED_COUNTRIES: Country[] = [
  {
    code: COUNTRY_AUSTRALIA,
    name: 'Australia',
    vatIdChecks: [],
    taxIdChecks: [16, CHECK_MAX_LENGTH],
  },
  {
    code: COUNTRY_AUSTRIA,
    name: 'Austria',
    vatIdChecks: [11, CHECK_EXACT_LENGTH],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_BELGIUM,
    name: 'Belgium',
    vatIdChecks: [12, CHECK_MAX_LENGTH],
    taxIdChecks: [10, CHECK_MAX_LENGTH],
  },
  {
    code: COUNTRY_BULGARIA,
    name: 'Bulgaria',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_CANADA,
    name: 'Canada',
    vatIdChecks: [],
    taxIdChecks: [10, CHECK_MAX_LENGTH],
  },
  {
    code: COUNTRY_CHINA,
    name: 'China',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_CROATIA,
    name: 'Croatia',
    vatIdChecks: [13, CHECK_EXACT_LENGTH],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_CZECH_REPUBLIC,
    name: 'Czech Republic',
    vatIdChecks: [],
    taxIdChecks: [14, CHECK_MAX_LENGTH],
  },
  {
    code: COUNTRY_CYPRUS,
    name: 'Cyprus',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_DENMARK,
    name: 'Denmark',
    vatIdChecks: [11, CHECK_MAX_LENGTH],
    taxIdChecks: [7, CHECK_MAX_LENGTH, CHECK_NUMERICAL],
  },
  {
    code: COUNTRY_ESTONIA,
    name: 'Estonia',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_FINLAND,
    name: 'Finland',
    vatIdChecks: [10, CHECK_EXACT_LENGTH],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_FRANCE,
    name: 'France',
    vatIdChecks: [16, CHECK_MAX_LENGTH],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_GERMANY,
    name: 'Germany',
    vatIdChecks: [12, CHECK_MAX_LENGTH],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_GIBRALTAR,
    name: 'Gibraltar',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  { code: COUNTRY_GREECE, name: 'Greece', vatIdChecks: [], taxIdChecks: [] },
  {
    code: COUNTRY_HONG_KONG,
    name: 'Hong Kong',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_HUNGARY,
    name: 'Hungary',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_IRELAND,
    name: 'Ireland',
    vatIdChecks: [11, CHECK_MAX_LENGTH],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_ITALY,
    name: 'Italy',
    vatIdChecks: [13, CHECK_MAX_LENGTH],
    taxIdChecks: [16, CHECK_MAX_LENGTH],
  },
  {
    code: COUNTRY_INDIA,
    name: 'India',
    vatIdChecks: [15, CHECK_EXACT_LENGTH],
    taxIdChecks: [15, CHECK_EXACT_LENGTH],
  },
  {
    code: COUNTRY_JAPAN,
    name: 'Japan',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  { code: COUNTRY_LATVIA, name: 'Latvia', vatIdChecks: [], taxIdChecks: [] },
  {
    code: COUNTRY_LEBANON,
    name: 'Lebanon',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_LIECHTENSTEIN,
    name: 'Liechtenstein',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_LITHUANIA,
    name: 'Lithuania',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_LUXEMBOURG,
    name: 'Luxembourg',
    vatIdChecks: [10, CHECK_EXACT_LENGTH],
    taxIdChecks: [],
  },
  { code: COUNTRY_MALTA, name: 'Malta', vatIdChecks: [], taxIdChecks: [] },
  {
    code: COUNTRY_MAURITIUS,
    name: 'Mauritius',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_MONACO,
    name: 'Monaco',
    vatIdChecks: [13, CHECK_EXACT_LENGTH],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_NETHERLANDS,
    name: 'Netherlands',
    vatIdChecks: [14, CHECK_MAX_LENGTH],
    taxIdChecks: [],
  },
  { code: COUNTRY_NORWAY, name: 'Norway', vatIdChecks: [], taxIdChecks: [] },
  {
    code: COUNTRY_POLAND,
    name: 'Poland',
    vatIdChecks: [12, CHECK_MAX_LENGTH],
    taxIdChecks: [10, CHECK_MAX_LENGTH],
  },
  {
    code: COUNTRY_PORTUGAL,
    name: 'Portugal',
    vatIdChecks: [11, CHECK_EXACT_LENGTH],
    taxIdChecks: [9, CHECK_EXACT_LENGTH, CHECK_NUMERICAL],
  },
  {
    code: COUNTRY_ROMANIA,
    name: 'Romania',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_SINGAPORE,
    name: 'Singapore',
    vatIdChecks: [],
    taxIdChecks: [16, CHECK_MAX_LENGTH],
  },
  {
    code: COUNTRY_SLOVENIA,
    name: 'Slovenia',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_SLOVAKIA,
    name: 'Slovakia',
    vatIdChecks: [],
    taxIdChecks: [14, CHECK_MAX_LENGTH],
  },
  {
    code: COUNTRY_SPAIN,
    name: 'Spain',
    vatIdChecks: [11, CHECK_EXACT_LENGTH],
    taxIdChecks: [9, CHECK_EXACT_LENGTH],
  },
  {
    code: COUNTRY_SWEDEN,
    name: 'Sweden',
    vatIdChecks: [14, CHECK_EXACT_LENGTH],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_SWITZERLAND,
    name: 'Switzerland',
    vatIdChecks: [],
    taxIdChecks: [15, CHECK_MAX_LENGTH],
  },
  {
    code: COUNTRY_TR,
    name: 'Turkey',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_UK,
    name: 'United Kingdom',
    vatIdChecks: [14, CHECK_MAX_LENGTH],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_US,
    name: 'USA',
    vatIdChecks: [],
    taxIdChecks: [11, CHECK_MAX_LENGTH],
  },
  {
    code: COUNTRY_MEXICO,
    name: 'Mexico',
    vatIdChecks: [12, CHECK_EXACT_LENGTH],
    taxIdChecks: [12, CHECK_EXACT_LENGTH],
  },
  {
    code: COUNTRY_UAE,
    name: 'United Arab Emirate',
    vatIdChecks: [15, CHECK_EXACT_LENGTH],
    taxIdChecks: [15, CHECK_EXACT_LENGTH],
  },
  {
    code: COUNTRY_LKA,
    name: 'Sri Lanka',
    vatIdChecks: [],
    taxIdChecks: [],
  },
  {
    code: COUNTRY_SAU,
    name: 'Saudi Arabia',
    vatIdChecks: [],
    taxIdChecks: [],
  },
];

export const COUNTRIES = UNSORTED_COUNTRIES.sort((a, b) =>
  a.name.localeCompare(b.name)
);

export const FACTORING_TWO_ALLOWED_COUNTRIES: Country[] = COUNTRIES.filter(
  (c) =>
    [
      'AT',
      'BE',
      'CH',
      'CZ',
      'DE',
      'DK',
      'EE',
      'ES',
      'FI',
      'FR',
      'HR',
      'IE',
      'IT',
      'LT',
      'LU',
      'LV',
      'NL',
      'PL',
      'SE',
      'SI',
      'SK',
      'GB',
    ].includes(c.code)
);
