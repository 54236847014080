import { mcb } from 'mcb';
import wrapVue from '../common/wrap-vue.converter';
import coreModule from 'Common/core.module';
import { MCP_ADMIN_ROLES } from 'Common/constants/roles.constant';
import { HeaderService } from 'Common/services/header.service';
import MerchantAccountEditContainerComponent from '../merchant-account-edit/merchant-account-edit.container.vue';

export default angular
  .module('mcp.admin.merchant-account-edit', [coreModule.name])

  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider): void => {
      $stateProvider
        .state('mcp.admin.merchant-account-create', {
          url: '/create-merchant-account',
          template: `<mcp-admin-merchant-account-edit-wrapper
          on-cancel="ctrl.goToMerchantAccountsList"
          on-save="ctrl.goToMerchantAccountsList"
        ></mcp-admin-merchant-account-edit-wrapper>`,
          controller: [
            '$state',
            function ($state: ng.ui.IStateService): void {
              this.goToMerchantAccountsList = (): void => {
                $state.go('mcp.admin.merchant-accounts');
              };
            },
          ],
          controllerAs: 'ctrl',
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant-account-edit', {
          url: '/merchant-accounts/:merchantAccountId/edit',
          template: `<mcp-admin-merchant-account-edit-wrapper
          on-cancel="ctrl.goToMerchantDetails"
          on-save="ctrl.goToMerchantDetails"
          account-id="ctrl.mcpHeaderService.getCurrentMerchantAccountId()"
        ></mcp-admin-merchant-account-edit-wrapper>`,
          controller: [
            'mcpHeaderService',
            '$state',
            function (
              mcpHeaderService: HeaderService,
              $state: ng.ui.IStateService
            ): void {
              this.mcpHeaderService = mcpHeaderService;
              this.goToMerchantDetails = (): void => {
                $state.go('mcp.admin.merchant-account-details', {
                  merchantAccountId:
                    this.mcpHeaderService.getCurrentMerchantAccountId(),
                });
              };
            },
          ],
          controllerAs: 'ctrl',
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState);
    },
  ])
  .component(
    'mcpAdminMerchantAccountEditWrapper',
    wrapVue(
      MerchantAccountEditContainerComponent,
      'mcpAdminMerchantAccountEditWrapper'
    )
  );
