import ng from 'angular';
import template from './commission.html';

export class CommissionComponent implements ng.IComponentOptions {
  static Factory() {
    return new CommissionComponent();
  }
  controller = CommissionController;
  controllerAs = 'ctrl';
  template: string = template;
}

class CommissionController implements angular.IComponentController {}
