import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(VSheet, { staticClass: "padded-container" }, [
        _c(
          "div",
          { staticStyle: { width: "688px" } },
          [
            _c("div", { staticClass: "display-1 secondary--text mb-6" }, [
              _vm._v("Legal Entity Information"),
            ]),
            _c(
              VRow,
              [
                _c(
                  VCol,
                  { attrs: { cols: "6" } },
                  [
                    _c("mp-labeled-text", {
                      attrs: {
                        label: "Legal Name",
                        value: _vm.legalEntity.name,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  VCol,
                  { attrs: { cols: "6" } },
                  [
                    _c("mp-labeled-text", {
                      attrs: {
                        label: "Supplier ID",
                        value: _vm.legalEntity.supplierId,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  VCol,
                  { attrs: { cols: "6" } },
                  [
                    _c("mp-labeled-text", {
                      attrs: { label: "VAT ID", value: _vm.legalEntity.vatId },
                    }),
                  ],
                  1
                ),
                _c(
                  VCol,
                  { attrs: { cols: "6" } },
                  [
                    _c("mp-labeled-text", {
                      attrs: { label: "Tax ID", value: _vm.legalEntity.taxId },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(VDivider, { staticClass: "mt-3", attrs: { light: "" } }),
            _c("div", { staticClass: "display-1 secondary--text mb-6 mt-8" }, [
              _vm._v("Billing Information"),
            ]),
            _c(
              VRow,
              [
                _c(
                  VCol,
                  { attrs: { cols: "6" } },
                  [
                    _c("mp-labeled-text", {
                      staticClass: "text-capitalize",
                      attrs: {
                        label: "Billing Cycle",
                        value: _vm.legalEntity.billingCycle,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  VCol,
                  { attrs: { cols: "6" } },
                  [
                    _c("mp-labeled-text", {
                      staticClass: "text-capitalize",
                      attrs: {
                        label: "Billing Currency",
                        value: _vm.legalEntity.billingCurrency,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  VCol,
                  { attrs: { cols: "6" } },
                  [
                    _c("mp-labeled-text", {
                      attrs: {
                        label: "Invoice Language",
                        value: _vm.legalEntity.invoiceLanguage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        VSheet,
        { staticClass: "padded-container mt-6" },
        [
          _c("div", { staticClass: "display-1 secondary--text mb-6" }, [
            _vm._v("Merchants"),
          ]),
          _vm.legalEntity.merchants.length
            ? _c(
                VFlex,
                { staticClass: "flex-wrap", attrs: { "d-flex": "" } },
                [
                  _vm._l(
                    _vm.legalEntity.merchants,
                    function ({ name, businessPartnerId }) {
                      return _c("div", [
                        _c(
                          "a",
                          {
                            staticClass: "title mr-11",
                            attrs: {
                              href: _vm.getMerchantUrl(businessPartnerId),
                            },
                          },
                          [_vm._v(_vm._s(name))]
                        ),
                      ])
                    }
                  ),
                  !_vm.legalEntity.merchants.length
                    ? _c("div", { staticClass: "ml-2" }, [_vm._v("-")])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }