import { NewContract } from 'Api/models/Contract';
import { MerchantFlowActions } from './merchant-flow.actions';
import { FlowStateSelector } from '../selectors/flow-state.selector';
import { FlowState } from '../flow.state';
import { CONTRACT_DATA_LOADING } from '../reducers/contract.reducer';
import { MCP_CONTRACT_STATUSES } from 'Common/constants/contract-statuses.constant';
import { ContractRequirements } from 'Api/models/ContractRequirements';
import {
  CONTRACT_REQUIREMENTS_LOADED,
  CONTRACT_REQUIREMENTS_LOADING,
} from '../reducers/contract-status.reducer';
import { ApiService } from 'Api/services/api.service';

export class ContractActions {
  static $inject = [
    '$q',
    'mcpAdminFlowStateSelector',
    'mcpAdminMerchantFlowActions',
    'mcpAdminApi',
  ];

  constructor(
    private q: ng.IQService,
    private flowSelector: FlowStateSelector,
    private merchantFlowActions: MerchantFlowActions,
    private api: ApiService
  ) {}
  saveContractsSelection(
    contractsToCreate: NewContract[],
    contractsToDelete: string[]
  ): Function {
    const { api, merchantFlowActions } = this;

    return (dispatch) =>
      this.q
        .all([
          api.contract.saveContracts(contractsToCreate),
          api.contract.deleteContracts(contractsToDelete),
        ])
        .finally(() => {
          dispatch(merchantFlowActions.setCurrentContracts());
          // We need to refetch Merchant data because its fulfillmentType prop might change
          dispatch(merchantFlowActions.setCurrentMerchant());
          dispatch(merchantFlowActions.setFlowStatus());
        });
  }

  onSaveMarketData() {
    return (dispatch, getState) => {
      dispatch({ type: CONTRACT_DATA_LOADING });

      return dispatch(this.merchantFlowActions.setCurrentContract())
        .then(() => dispatch(this.merchantFlowActions.setCurrentContracts()))
        .then(() => dispatch(this.merchantFlowActions.setFlowStatus()));
    };
  }

  sendContractToReview() {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const contract = this.flowSelector.getFlowContract(state);
      return this.api.contractStatus
        .markAsInFinancialReview(contract.$id)
        .then(() => dispatch(this.merchantFlowActions.setCurrentContract()))
        .then(() => dispatch(this.merchantFlowActions.setCurrentContracts()));
    };
  }

  sendContractsToReview(contractIds: string[]) {
    return (dispatch) => {
      return this.api.contractStatus
        .processMultipleContracts(
          contractIds,
          MCP_CONTRACT_STATUSES.IN_FINANCIAL_REVIEW
        )
        .then(() => dispatch(this.merchantFlowActions.setCurrentContracts()))
        .then(() => dispatch(this.merchantFlowActions.setCurrentContract()));
    };
  }

  onReview() {
    return (dispatch, getState) => {
      return dispatch(this.merchantFlowActions.setCurrentContract()).then(() =>
        dispatch(this.merchantFlowActions.setCurrentContracts())
      );
    };
  }

  onLaunch() {
    return (dispatch, getState) => {
      return dispatch(this.merchantFlowActions.setCurrentContract()).then(() =>
        dispatch(this.merchantFlowActions.setCurrentContracts())
      );
    };
  }

  setContractStatus() {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const contract = this.flowSelector.getFlowContract(state);
      dispatch({ type: CONTRACT_REQUIREMENTS_LOADING });
      return this.api.contractStatus
        .fetchContractRequirements(contract.$id)
        .then((contractStatus: ContractRequirements) => {
          dispatch({
            type: CONTRACT_REQUIREMENTS_LOADED,
            payload: contractStatus,
          });
        });
    };
  }
}
