<template lang="pug">
  div.legend-section.pb-10
    div.title.text-lg-h4.ml-2.mb-3 {{title}}
    v-container.pa-0
      v-row(no-gutters)
        v-col.text-no-wrap(
          v-for="statusInfo in statusesInfo"
          :key="statusInfo.icon"
          cols="4"
        )
          v-icon.mr-2(:color="statusInfo.color") | {{statusInfo.icon}}
          span {{statusInfo.text}}
</template>

<script lang="ts">
import Vue from 'vue';
import VatStatusIcon, {
  VAT_STATUS_MAP,
} from 'Merchant/common/components/review-status-icon/review-status-icon.vue';

export default Vue.extend({
  components: { VatStatusIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      statusesInfo: [
        { ...VAT_STATUS_MAP.approved, text: 'Approved data' },
        { ...VAT_STATUS_MAP.rejected, text: 'Rejected data' },
        { ...VAT_STATUS_MAP.pending, text: 'Not provided by partner' },
        { ...VAT_STATUS_MAP.inReview, text: 'Approval pending' },
        {
          ...VAT_STATUS_MAP.inReviewAndManualValidationPending,
          text: 'Approval and manual validation pending',
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.legend-section {
  background-color: white;
}

.row .col {
  padding: 8px;
}

.row .col:nth-child(3) {
  display: flex;
  justify-content: flex-end;
}
</style>
