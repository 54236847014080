import 'angular-sanitize';
import mcbToastDirective from './toast-directive';
import mcbToastMessageDirective from './toastMessage';
import mcbToastProvider from './toastProvider';

/**
 * @ngdoc module
 * @name mcb.components.toast
 *
 * Toast service is implemented by using dress-code styles
 * and heavily inspired by ngToast
 */
export default angular
  .module('mcb.components.toast', ['ngSanitize'])
  // @ts-ignore
  .directive('mcbToast', mcbToastDirective)
  .directive('mcbToastMessage', mcbToastMessageDirective)
  // @ts-ignore
  .provider('mcbToast', mcbToastProvider);
