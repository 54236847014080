<template lang="pug">
v-app(
    id="vue-carrier"
    :style="{background: 'white'}"
  )
    v-main
      v-container(fluid).pa-0
        //- Loading
        div.text-center.mt-2(v-if="isLoading")
          v-progress-circular(
            :size="50"
            color="primary"
            indeterminate
          )
        //- Return Address API error
        v-alert(
          v-else-if="isLoadingError"
          :value="true"
          type="error"
        ) Something went wrong while fetching Carrier Details information
        template(v-else)
          div(
            v-if="!preferredCarrier && !configuredDeliveryProducts.length"
            class="d-flex mb-5 pa-3 mcp-admin-merchant-carrier-alert"
          )
            v-icon.mr-4.justify-center mdi-alert
            div.mt-2
              | <b>Not ready: </b>This partner does not have a carrier preference set yet.
              | If this is a hybrid partner (the merchant is using both ZFS and PF),
              | you will need to set their carrier for them. If this is not a hybrid partner,
              | please wait for the partner to set their carrier in zDirect.

          div(
            v-if="preferredCarrier && !configuredDeliveryProducts.length"
            class="d-flex mb-5 pa-3 mcp-admin-merchant-carrier-warning"
          )
            v-icon.mr-4.justify-center.blue--text.text--darken-3 mdi-information-outline
            div.mt-2
              | <b>Ready for carrier configuration:</b> You can begin adding the carrier configurations using the “Add delivery carrier configuration” or “Add return carrier configuration” buttons below. 
              | The partner has selected “Delivery Carrier: {{preferredCarrier}}" and  “Delivery method: {{preferredDeliveryProductsLabels}}" as their carrier preferences.

          div.grey.lighten-5.py-5.px-4.my-5
            h3.black--text
              | Delivery carrier configurations
            div(v-if="!preferredCarrier").text-dark.mt-2
              | The partner has not yet selected their carrier preferences on zDirect.
              | Please wait before adding configuration for delivery carriers.
            div(v-if="preferredCarrier").text-dark.mt-2
              | Add configuration for delivery carriers.
              | For any carriers not listed, contact the OrderX team for overrides or exceptions.
            v-btn(v-if="!configuredDeliveryProducts.length && !showAddNewDeliveryMethod").mt-5.white--text.text-none(
              color="black"
              @click="showAddNewDeliveryMethod = !showAddNewDeliveryMethod"
              :disabled="!preferredCarrier"
              :loading="isDeliveryMethodSubmitting"
            ) Add delivery carrier configuration

            div(v-if="configuredDeliveryProducts.length").mt-4
              v-card(
                outlined
                tile
                rounded="sm"
              ).pa-4.rounded.mt-4
                div(v-if="selectedCarrierConfiguration")
                  span.text-subtitle-2.font-weight-bold.mr-2.black--text Delivery carrier:
                  span.text-body-2 {{selectedCarrierConfiguration.carrier.name}}
                div(v-if="configuredDeliveryParcelPerformMapping").mt-2
                  span.text-subtitle-2.font-weight-bold.mr-2.black--text Track & trace reference:
                  span.text-body-2 {{configuredDeliveryParcelPerformMapping.reference}}
            div(v-if="configuredDeliveryProducts.length").mt-4
              span.text-subtitle-2.font-weight-bold.black--text
                | Delivery methods
              CarrierCards(
                :list="configuredDeliveryProducts"
                :on-delete="deleteDeliveryProductSelection"
              )
              v-btn.mt-5.white--text.text-none(
                v-if="!showAddNewDeliveryMethod"
                color="black"
                @click="showAddNewDeliveryMethod = !showAddNewDeliveryMethod"
                :disabled="!preferredCarrier"
                :loading="isDeliveryMethodSubmitting"
              ) Add delivery method

            div(v-if="showAddNewDeliveryMethod").my-5
              h3.black--text.mb-4
                | Add delivery method
              div(v-if="configuredDeliveryProducts.length === 0").mt-1.row
                v-select.ml-4(
                  label="Select delivery carrier"
                  :items="carriersList"
                  v-model="selectedDeliveryCarrier"
                  @change="onDeliveryCarrierChange"
                  :style="{maxWidth: '40%'}"
                )
                v-select.ml-4(
                  label="Select track & trace reference"
                  :items="parcelPerformMappingsList"
                  v-model="selectedDeliveryParcelPerformMapping"
                  :style="{maxWidth: '40%'}"
                )
              div.row
                v-select.ml-4(
                  label="Delivery method"
                  :items="deliveryDestinationTypes"
                  v-model="selectedDeliveryProductId"
                  @change="setParcelServices"
                  :disabled="!deliveryDestinationTypes.length"
                  :style="{maxWidth: '40%'}"
                )
                v-select.ml-4(
                  label="Opt-in services"
                  :items="optionalCapabilities"
                  v-model="selectedOptionalCapabilities"
                  @change="isAddDeliveryCarrierSelectionsChanged = true"
                  :multiple="true"
                  :disabled="!optionalCapabilities.length"
                  :style="{maxWidth: '40%'}"
                )

              div(:style="{maxWidth: '100%'}").d-flex.flex-column.ml-4
                label.text-caption.v-label.theme--light.font-weight-light Parcel service
                div.mt-2 {{selectedParcelServices.join(', ') || '&#8212;'}}


              v-btn.mt-5.mb-6.ml-2.white--text.text-none(
                color="black"
                @click="addNewDeliveryProductSelection"
                :disabled="shouldDisableShipmentCarrierSubmit"
                :loading="isDeliveryMethodSubmitting"
              ) Save

          div.grey.lighten-5.pa-5.my-5
            h3.black--text
              | Add return carrier configurations
            div(v-if="!preferredCarrier").text-dark.mt-2
              | The partner has not yet selected their carrier preferences on zDirect.
              | Please wait before adding configuration for delivery carriers.
            div(v-if="preferredCarrier").text-dark.mt-2
              | Add configuration for return carrier. For any carriers not listed,
              | contact the OrderX team for overrides or exceptions.
              | Note that you can only configure one return carrier at a time.
            div(v-if="configuredReturnCarrierSelection").mt-4
              v-card(
                outlined
                tile
                rounded="sm"
              ).pa-4.rounded.mt-4
                div(v-if="configuredReturnCarrier")
                  span.text-subtitle-2.font-weight-bold.mr-2.black--text Return carrier:
                  span.text-body-2 {{configuredReturnCarrierSelection.carrier.name}}
                div(v-if="configuredReturnParcelPerformMapping").mt-2
                  span.text-subtitle-2.font-weight-bold.mr-2.black--text Track & trace reference:
                  span.text-body-2 {{configuredReturnCarrierSelection.parcelPerformReference.reference}}
            v-btn.mt-5.white--text.text-none(
              color="black"
              @click="showAddNewReturnCarrier = !showAddNewReturnCarrier"
              :disabled="!!configuredReturnCarrierSelection || !preferredCarrier"
              :loading="isReturnCarrierSubmitting"
            ) Add return carrier configuration
            div(v-if="showAddNewReturnCarrier")
              div.mt-1.row
                v-select.ml-4(
                  label="Select return carrier"
                  :items="carriersList"
                  v-model="selectedReturnCarrier"
                  @change="onReturnCarrierChange"
                  :style="{maxWidth: '40%'}"
                )
                v-select.ml-4(
                  label="Select track & trace reference"
                  :items="parcelPerformMappingsList"
                  v-model="selectedReturnParcelPerformMapping"
                  :style="{maxWidth: '40%'}"
                  )
              v-btn.mt-5.mb-6.ml-2.white--text.text-none(
                color="black"
                @click="addNewReturnCarrier"
                :disabled="shouldDisableReturnCarrierSubmit"
                :loading="isReturnCarrierSubmitting"
              ) Save
</template>
<script lang="ts">
import Vue from 'vue';
import {
  ShipmentCarrierT,
  DeliveryProductRequestItemT,
  fetchParcelPerformMappings,
  fetchCarrierConfigurations,
  fetchDeliveryCarriersSelection,
  putDeliveryCarriersSelection,
  DeliveryCarrierSelectionRequestT,
  putReturnCarrierSelections,
  fetchReturnCarriersSelection,
  fetchCarrierInfo,
} from 'Api/endpoints/sales-channel/carrier.endpoint';
import {
  DELIVERY_DESTINATIONS,
  OPTIONAL_CAPABILITIES,
  PARCEL_SERVICES,
} from 'Common/constants/carriers.constant';
import CarrierCards from './carrier-cards.vue';

export default Vue.extend({
  components: { CarrierCards },
  props: {
    onSave: {
      type: Function,
      default: null,
      required: false, // Required only if not readonly
    },
    onError: {
      type: Function,
      default: null,
      required: false, // Required only if not readonly
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingError: false,
      isDeliveryMethodSubmitting: false,
      isReturnCarrierSubmitting: false,
      isAddDeliveryCarrierSelectionsChanged: false,
      carriers: [] as ShipmentCarrierT[],
      showAddNewDeliveryMethod: false,
      showAddNewReturnCarrier: false,
      deliveryProductsMap: {
        HOME: 'Home',
        PICKUP_POINT: 'Pick up Point',
        PACKSTATION: 'Packstation',
      },
      preferredCarrier: '',
      preferredDeliveryProducts: [],
      returnCarrier: '',
      deliverySpeed: 'STANDARD',
      deliveryProducts: [],
      // @ts-ignore
      ...this.mapState({
        merchantId: 'flow.specific.contract.data.merchantId', //maps state.todos to data.todoList
        salesChannelId: 'flow.specific.contract.data.salesChannelId', //maps state.todos to data.todoList
        contract: 'flow.specific.contract.data',
        requirements: 'flow.specific.requirements.data',
        requirementsLoading: 'flow.specific.requirements.loading',
      }),

      configuredDeliveryProducts: [],
      availableCarriers: [],
      availableCarrierConfigurations: [],
      availableParcelPerformMappings: [],
      selectedCarrierConfiguration: null,
      selectedDeliveryCarrier: '',
      selectedReturnCarrier: '',
      selectedDeliveryProductId: '',
      selectedDeliveryParcelPerformMapping: null,
      selectedReturnParcelPerformMapping: null,
      selectedOptionalCapabilities: [],
      selectedParcelServices: [],
      configuredDeliveryParcelPerformMapping: null,
      configuredReturnParcelPerformMapping: null,
      configuredReturnCarrier: '',
      configuredReturnCarrierSelection: null,
    };
  },
  computed: {
    carriersList() {
      return this.availableCarrierConfigurations.map((config) => ({
        value: config.carrier.id,
        text: config.carrier.name,
      }));
    },
    parcelPerformMappingsList() {
      return this.availableParcelPerformMappings
        .filter(
          (mapping) => mapping.id !== this.selectedDeliveryParcelPerformMapping
        )
        .map((mapping) => ({
          value: mapping,
          text: mapping.reference,
        }));
    },
    preferredDeliveryProductsLabels(): string {
      if (!this.preferredDeliveryProducts.length) {
        return 'Not defined';
      }
      return this.preferredDeliveryProducts
        .map((val) => this.deliveryProductsMap[val])
        .join(', ');
    },
    deliveryDestinationTypes() {
      if (!this.selectedDeliveryCarrier) return [];
      return this.availableCarrierConfigurations
        .find((config) => config.carrier.id === this.selectedDeliveryCarrier)
        .allowedDeliveryProducts.map((adp) => ({
          value: adp.id,
          text: `${
            DELIVERY_DESTINATIONS[
              adp.deliveryProduct.deliveryDestinationType
            ] || adp.deliveryProduct.deliveryDestinationType
          } (${this.getParcelServices(adp.deliveryProduct).join(', ')})`,
        }));
    },
    shouldDisableShipmentCarrierSubmit() {
      return (
        !this.isAddDeliveryCarrierSelectionsChanged ||
        this.isDeliveryMethodSubmitting ||
        !this.selectedDeliveryProductId ||
        this.isReturnCarrierSubmitting
      );
    },
    shouldDisableReturnCarrierSubmit() {
      return (
        this.isReturnCarrierSubmitting ||
        !this.selectedReturnCarrier ||
        !this.selectedReturnParcelPerformMapping
      );
    },
    optionalCapabilities() {
      if (!this.selectedCarrierConfiguration) return [];
      const selectedAllowedDeliveryProduct =
        this.selectedCarrierConfiguration?.allowedDeliveryProducts.find(
          (adp) => adp.id === this.selectedDeliveryProductId
        );

      if (!this.selectedDeliveryProductId) return [];

      return selectedAllowedDeliveryProduct.deliveryProduct.optionalCapabilities.map(
        (optionalCapability) => {
          return {
            value: optionalCapability,
            text:
              OPTIONAL_CAPABILITIES[optionalCapability] || optionalCapability,
          };
        }
      );
    },
  },
  mounted() {
    Promise.all([
      fetchCarrierConfigurations(),
      fetchParcelPerformMappings(),
      fetchDeliveryCarriersSelection(this.merchantId, this.salesChannelId),
      fetchReturnCarriersSelection(this.merchantId, this.salesChannelId),
      fetchCarrierInfo(this.merchantId, this.salesChannelId),
    ])
      .then(
        ([
          carrierConfigurations,
          availableParcelPerformMappings,
          configuredDeliveryCarriersSelection,
          configuredReturnCarrierSelection,
          carrierInfo,
        ]) => {
          // TODO: fix types, remove the hardcoded flow, remove the ignore after types are fixed
          const salesChannelCarrierConfigurations =
            // @ts-ignore
            carrierConfigurations.filter(
              (config) =>
                config.carrier.countryCode === 'IT' && config.carrier.isVisible
            );
          this.preferredCarrier = carrierInfo.shipmentCarrier;
          this.preferredDeliveryProducts = carrierInfo.deliveryProducts;
          this.availableCarrierConfigurations =
            salesChannelCarrierConfigurations;
          this.availableParcelPerformMappings = availableParcelPerformMappings;
          this.configuredDeliveryParcelPerformMapping =
            configuredDeliveryCarriersSelection.parcelPerformReference;
          this.configuredDeliveryProducts =
            configuredDeliveryCarriersSelection.deliveryProducts;
          this.configuredReturnParcelPerformMapping =
            configuredReturnCarrierSelection.parcelPerformReference;
          this.configuredReturnCarrier =
            configuredReturnCarrierSelection.carrier.id;
          this.configuredReturnCarrierSelection =
            configuredReturnCarrierSelection;
          if (this.configuredDeliveryProducts.length > 0) {
            this.selectedCarrierConfiguration =
              this.availableCarrierConfigurations.find(
                (config) =>
                  config.carrier.id ===
                  this.configuredDeliveryProducts[0].carrierId
              );
            this.selectedDeliveryCarrier =
              this.selectedCarrierConfiguration.carrier.id;
          }
        }
      )
      .then(() => {
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
        this.isLoadingError = true;
      });
  },
  methods: {
    setParcelServices() {
      this.isAddDeliveryCarrierSelectionsChanged = true;
      const selectedDeliveryProduct =
        this.selectedCarrierConfiguration.allowedDeliveryProducts.find(
          (adp) => adp.id === this.selectedDeliveryProductId
        );

      this.selectedParcelServices = this.getParcelServices(
        selectedDeliveryProduct.deliveryProduct
      );
    },
    getParcelServices(deliveryProduct) {
      return deliveryProduct
        ? deliveryProduct.capabilities.map(
            (capability) => PARCEL_SERVICES[capability] || capability
          )
        : [];
    },
    getNewDeliveryMethodsSelectionsList(
      configuredDeliveryProducts
    ): DeliveryProductRequestItemT[] {
      const list = configuredDeliveryProducts.map(
        ({ id, configuredOptionalDeliveryCapabilities }) => ({
          id,
          configuredOptionalDeliveryCapabilities,
        })
      );
      if (this.selectedDeliveryProductId) {
        list.push({
          id: this.selectedDeliveryProductId,
          configuredOptionalDeliveryCapabilities:
            this.selectedOptionalCapabilities,
        });
      }
      return list;
    },
    updateDeliveryProductsSelectionsList(list) {
      const payload: DeliveryCarrierSelectionRequestT = {
        deliveryProducts: this.getNewDeliveryMethodsSelectionsList(list),
        parcelPerformReferenceId:
          this.configuredDeliveryParcelPerformMapping.id,
      };

      return putDeliveryCarriersSelection(
        this.merchantId,
        this.salesChannelId,
        payload
      );
    },
    addNewDeliveryProductSelection(): void {
      this.isDeliveryMethodSubmitting = true;
      this.updateDeliveryProductsSelectionsList(this.configuredDeliveryProducts)
        .then(() => {
          this.isDeliveryMethodSubmitting = false;
          this.isAddDeliveryCarrierSelectionsChanged = false;
          this.onSave();
        })
        .catch(() => {
          this.isDeliveryMethodSubmitting = false;
          this.onError();
        });
    },
    addNewReturnCarrier(): void {
      this.isReturnCarrierSubmitting = true;
      const payload = {
        carrierId: this.selectedReturnCarrier,
        parcelPerformReferenceId: this.selectedReturnParcelPerformMapping.id,
      };
      putReturnCarrierSelections(this.merchantId, this.salesChannelId, payload)
        .then(() => {
          this.isReturnCarrierSubmitting = false;
          this.onSave();
        })
        .catch(() => {
          this.isReturnCarrierSubmitting = false;
          this.onError();
        });
    },
    deleteDeliveryProductSelection(id) {
      const list = this.configuredDeliveryProducts.filter(
        (crr) => crr.id !== id
      );

      this.updateDeliveryProductsSelectionsList(list)
        .then(() => this.onSave())
        .catch(() => this.onError());
    },
    onDeliveryCarrierChange(id) {
      this.selectedCarrierConfiguration =
        this.availableCarrierConfigurations.find(
          (config) => config.carrier.id === id
        );
      this.selectedDeliveryParcelPerformMapping =
        this.availableCarrierConfigurations.find(
          (config) => config.carrier.id === id
        ).carrier.parcelPerformReference;
      this.isAddDeliveryCarrierSelectionsChanged = true;
      this.selectedDeliveryProductId = '';
      this.selectedParcelServices = [];
    },
    onReturnCarrierChange(id) {
      this.selectedReturnCarrier = id;
      this.selectedReturnParcelPerformMapping =
        this.availableCarrierConfigurations.find(
          (config) => config.carrier.id === id
        ).carrier.parcelPerformReference;
    },
  },
});
</script>
