<template lang="pug">
  v-container.grey.lighten-5
    v-row()
      v-col(
        v-for="carrierInfo in list"
        :key="carrierInfo.id"
        cols="6"
        sm="6")
          CarrierCard(
            :details="carrierInfo"
            :on-delete="onDelete"
            :disable-delete="list.length === 1"
            )
</template>
<script lang="ts">
import Vue from 'vue';
import CarrierCard from 'Merchant/contract/components/contract-carrier/carrier-card.vue';

export default Vue.extend({
  name: 'CarrierCards',
  components: { CarrierCard },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    onDelete: {
      type: Function,
      required: true,
    },
  },
});
</script>
<style lang="scss" scoped></style>
