import {
  MC_STATE_ERRORS,
  ERROR_STATE_NAME,
  DEFAULT_ERROR_TYPE,
} from './error.constant';

export function errorHelper($injector: ng.auto.IInjectorService) {
  return {
    getError,
    onStateFirstLoadError(e, toState, toParams, fromState, fromParams, error) {
      let errorType = DEFAULT_ERROR_TYPE;

      if (toState.name === ERROR_STATE_NAME) {
        return;
      }

      // duck type checking, the error has a getErrorStateType method?
      if (error && angular.isFunction(error.getErrorStateType)) {
        errorType = getError(error.getErrorStateType()).type;
      }

      return $injector
        .get<ng.ui.IStateService>('$state')
        .go(ERROR_STATE_NAME, { errorType });
    },
  };
}

errorHelper.$inject = ['$injector'];

function getError(errorType) {
  return MC_STATE_ERRORS[errorType] || MC_STATE_ERRORS[DEFAULT_ERROR_TYPE];
}
