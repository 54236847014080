import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _vm.legalEntity
        ? _c(VHover, {
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ hover }) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "pt-4 pb-3",
                          staticStyle: { height: "100%" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "title secondary--text" },
                                [_vm._v(_vm._s(_vm.legalEntity.name))]
                              ),
                              _vm.removable
                                ? _c(
                                    VBtn,
                                    {
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.$emit(
                                            "remove",
                                            _vm.legalEntity.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: hover,
                                              expression: "hover",
                                            },
                                          ],
                                          attrs: { light: "", color: "reject" },
                                        },
                                        [_vm._v("cancel")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.legalEntity.merchants.length
                            ? _c(
                                "div",
                                { staticClass: "d-flex flex-wrap" },
                                _vm._l(
                                  _vm.legalEntity.merchants,
                                  function (merchant) {
                                    return _c(
                                      "span",
                                      {
                                        staticClass:
                                          "body-2 secondary--text mr-6 mb-2 merchant",
                                      },
                                      [_vm._v(_vm._s(merchant.name))]
                                    )
                                  }
                                ),
                                0
                              )
                            : _c(
                                "div",
                                { staticClass: "body-1 secondaryDark--text" },
                                [_vm._v("-")]
                              ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2813029562
            ),
          })
        : _vm._e(),
      !_vm.legalEntity
        ? _c(
            VRow,
            {
              staticStyle: { height: "100%" },
              attrs: { "align-content": "center" },
            },
            [
              _c(
                VCol,
                { staticClass: "text-center" },
                [
                  _c(VProgressCircular, {
                    attrs: { indeterminate: "", color: "secondaryDark" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showDivider ? _c(VDivider, { attrs: { light: "" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }