import {
  MerchantProfile,
  MerchantProfileOptional,
} from 'Api/models/MerchantProfile';
import template from './merchant-data-form.html';
import {
  BILLING_CYCLE_FORM_OPTIONS,
  BILLING_CYCLE_ONCE_A_MONTH,
} from 'Common/constants/billing.constant';

export class MerchantDataFormComponent implements ng.IComponentOptions {
  static Factory() {
    return new MerchantDataFormComponent();
  }

  bindings: { [binding: string]: string } = {
    merchant: '<',
    onSave: '<',
    onCancel: '<',
  };

  controller = MerchantDataFormController;
  controllerAs = 'ctrl';
  template: string = template;
}

class MerchantDataFormController implements angular.IComponentController {
  static $inject = [];

  // injected by component
  merchant: MerchantProfile;
  onSave: Function;
  onCancel: Function;

  dataForm: ng.IFormController;
  formData: MerchantProfileOptional;
  billingCycleOptions: Object[];

  $onInit(): void {
    this.formData = {};
    this.billingCycleOptions = BILLING_CYCLE_FORM_OPTIONS;

    if (this.merchant) {
      const { billingCurrency, preferredLanguage } = this.merchant;

      this.formData = { billingCurrency, preferredLanguage };

      this.formData.billingCycle = BILLING_CYCLE_ONCE_A_MONTH;
    }
  }

  save(): void {
    if (this.dataForm.$invalid) {
      return;
    }

    this.onSave(this.formData);
  }

  cancel(): void {
    this.onCancel();
  }
}
