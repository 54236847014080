import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            VRow,
            { attrs: { dense: "" } },
            [
              _c(
                VCol,
                { staticClass: "text-subtitle-1", attrs: { cols: "3" } },
                [_vm._v("Partner Id")]
              ),
              _c(
                VCol,
                _vm._l(_vm.partners, function (partner) {
                  return _c(
                    "div",
                    { key: partner.$id, staticClass: "mb-1" },
                    [
                      _c("span", { staticClass: "mono" }, [
                        _vm._v(
                          _vm._s(partner.$id) +
                            " (" +
                            _vm._s(partner.locale) +
                            ")"
                        ),
                      ]),
                      partner.isZfsPartner
                        ? _c(
                            VChip,
                            {
                              staticClass: "ml-2",
                              attrs: {
                                color: "cyan darken-2",
                                "text-color": "white",
                                small: "",
                              },
                            },
                            [_vm._v("ZFS PARTNER")]
                          )
                        : _vm._e(),
                      partner.partnerLaunchStatus === "INACTIVE"
                        ? _c(
                            VChip,
                            {
                              staticClass: "ml-2",
                              attrs: {
                                color: "red",
                                "text-color": "white",
                                small: "",
                              },
                            },
                            [_vm._v("INACTIVE")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            VRow,
            { staticClass: "mt-n1", attrs: { dense: "", align: "center" } },
            [
              _c(
                VCol,
                { staticClass: "text-subtitle-1", attrs: { cols: "3" } },
                [_vm._v("Business Partner Id")]
              ),
              _c(VCol, { staticClass: "mono" }, [
                _vm._v(_vm._s(_vm.merchantId)),
              ]),
            ],
            1
          ),
          _c(
            VRow,
            { attrs: { dense: "", align: "center" } },
            [
              _c(
                VCol,
                { staticClass: "text-subtitle-1", attrs: { cols: "3" } },
                [_vm._v("Sales Channel Id")]
              ),
              _c(VCol, { staticClass: "mono" }, [
                _vm._v(_vm._s(_vm.channelId)),
              ]),
            ],
            1
          ),
          _c(
            VRow,
            { attrs: { dense: "", align: "center" } },
            [
              _c(
                VCol,
                { staticClass: "text-subtitle-1", attrs: { cols: "3" } },
                [_vm._v("Fulfillment Type")]
              ),
              _c(VCol, [
                _vm._v(_vm._s(_vm._f("mcpGetFFTypeName")(_vm.fulfillmentType))),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "text-h6 mt-2" }, [_vm._v("Status")]),
          _vm._l(_vm.filteredChecks, function (check) {
            return _c("div", { key: check.title, staticClass: "mb-2" }, [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  check.status === "COMPLETED"
                    ? _c(VIcon, { attrs: { color: "green" } }, [
                        _vm._v("check_circle"),
                      ])
                    : check.status === "PENDING"
                    ? _c(VIcon, { attrs: { color: "gray" } }, [
                        _vm._v("check_circle"),
                      ])
                    : check.status === "IN_PROGRESS"
                    ? _c(VIcon, { attrs: { color: "orange" } }, [
                        _vm._v("check_circle"),
                      ])
                    : check.status === "ON_HOLD"
                    ? _c(VIcon, { attrs: { color: "orange" } }, [
                        _vm._v("pause_circle_filled"),
                      ])
                    : check.status === "FAILED"
                    ? _c(VIcon, { attrs: { color: "red" } }, [
                        _vm._v("cancel"),
                      ])
                    : _c(VIcon, { attrs: { color: "grey" } }, [
                        _vm._v("replay_circle_filled"),
                      ]),
                  !check.titleLink
                    ? _c("div", { staticClass: "ml-2 subtitle-1" }, [
                        _vm._v(_vm._s(check.title)),
                      ])
                    : _c("div", { staticClass: "ml-2 subtitle-1" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: check.titleLink, target: "_blank" },
                          },
                          [_vm._v(_vm._s(check.title))]
                        ),
                      ]),
                ],
                1
              ),
              _c("div", { staticClass: "ml-8" }, [
                check.title === "Online" || check.title === "On Hold"
                  ? _c("div", [
                      _vm.liveStatusModifiedAt && _vm.liveStatusModifiedBy
                        ? _c("div", [
                            _vm._v(
                              "Last modified on " +
                                _vm._s(
                                  _vm._f("formatDateTime")(
                                    _vm.liveStatusModifiedAt
                                  )
                                ) +
                                "\nby " +
                                _vm._s(_vm.liveStatusModifiedBy)
                            ),
                          ])
                        : _vm._e(),
                      !_vm.isLive && _vm.offlineReason
                        ? _c("div", [
                            _vm._v(
                              "Offline reason: " + _vm._s(_vm.offlineReason)
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                check.info ? _c("div", [_vm._v(_vm._s(check.info))]) : _vm._e(),
              ]),
            ])
          }),
          _vm.canEdit && !_vm.hasBeenLaunched
            ? _c(
                VCard,
                { staticClass: "mt-5 pb-2" },
                [
                  _c(VCardTitle, [
                    _vm.isLaunchEnabled
                      ? _c("div", [
                          _vm._v(
                            "Contract is currently NOT launched in " +
                              _vm._s(_vm.countryName)
                          ),
                        ])
                      : _c("div", [
                          _vm._v(
                            "Contract is currently NOT launched in " +
                              _vm._s(_vm.countryName)
                          ),
                        ]),
                  ]),
                  _c(
                    VCardActions,
                    [
                      _vm.isLaunchEnabled
                        ? _c(
                            VBtn,
                            {
                              staticClass: "ml-2",
                              attrs: {
                                loading: _vm.isLaunchInProgress,
                                color: "primary",
                                depressed: "",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.isChannelLive
                                    ? (_vm.isModalVisible = true)
                                    : _vm.launch()
                                },
                              },
                            },
                            [_vm._v("Launch " + _vm._s(_vm.countryName))]
                          )
                        : _c(
                            VBtn,
                            { staticClass: "ml-2", attrs: { disabled: "" } },
                            [_vm._v("Launch " + _vm._s(_vm.countryName))]
                          ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        VDialog,
        {
          attrs: { width: "550" },
          model: {
            value: _vm.isModalVisible,
            callback: function ($$v) {
              _vm.isModalVisible = $$v
            },
            expression: "isModalVisible",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                "mpAlert",
                { staticClass: "text-h6", attrs: { type: "warning" } },
                [_vm._v("Warning!")]
              ),
              _c(VCardText, [
                _c("div", [
                  _c("span", [
                    _vm._v("There is already another fulfilment type that is"),
                  ]),
                  _c("strong", [_vm._v("  ONLINE  ")]),
                  _c("span", [_vm._v("in " + _vm._s(_vm.countryName) + ".")]),
                  _c("br"),
                  _c("span", [_vm._v("Launching")]),
                  _c("strong", [
                    _vm._v(
                      "  " +
                        _vm._s(
                          _vm._f("mcpGetFFTypeName")(_vm.fulfillmentType)
                        ) +
                        "  "
                    ),
                  ]),
                  _c("span", [_vm._v("will put it directly to")]),
                  _c("strong", [_vm._v("  ONLINE  ")]),
                  _c("span", [_vm._v("status.")]),
                ]),
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.isModalVisible = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        color: "primary",
                        depressed: "",
                        loading: _vm.isLaunchInProgress,
                      },
                      on: { click: _vm.launch },
                    },
                    [_vm._v("Launch and put ONLINE")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }