import { Contract } from 'Api/models/Contract';
export const CONTRACT_LOADED = 'CONTRACT_LOADED';
export const CONTRACT_DATA_LOADING = 'CONTRACT_DATA_LOADING';
export const CONTRACT_LOADING = 'CONTRACT_LOADING';
export const CONTRACT_STOCK_STATUS = 'CONTRACT_STOCK_STATUS';

export interface ContractState {
  contractLoading: boolean;
  dataLoading: boolean;
  data: Contract;
  error: any;
}

export const contractInitialState: ContractState = {
  contractLoading: false,
  dataLoading: false,
  data: {} as Contract,
  error: null,
};

export const contractReducer = (
  state = { ...contractInitialState },
  { type, payload }
) => {
  switch (type) {
    case CONTRACT_STOCK_STATUS: {
      return {
        ...state,
        data: {
          ...state.data,
          isContractHasActiveStock: payload,
        },
      };
    }
    case CONTRACT_LOADED:
      return {
        ...state,
        contractLoading: false,
        dataLoading: false,
        data: {
          ...state.data,
          ...payload,
        },
      };

    case CONTRACT_DATA_LOADING:
      return {
        ...state,
        dataLoading: true,
      };
    case CONTRACT_LOADING:
      return {
        ...state,
        contractLoading: true,
      };
    default:
      return state;
  }
};
