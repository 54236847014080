import { OutboundWarehouseInfoT } from 'Api/models/OutboundWarehouse';
import axios from 'axios';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import { pipe } from 'ramda';
import ErrorHandler, { nullIf404Handler } from 'Api/api-errors-handler';
import {
  ComplianceReviewRequestT,
  ComplianceStatusResponseT,
} from 'Api/models/ComplianceStatus';
import {
  MerchantComplianceRequirementResponseT,
  MerchantComplianceRequirementT,
} from 'Api/models/ComplianceRequirement';

const merchantApi = axios.create({
  baseURL: '/api/merchant-profile/merchants',
});
merchantApi.interceptors.request.use(decamelizeRequestHandler);
merchantApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

export async function fetchMerchantComplianceDetailsRequirements(
  merchantId: string
): Promise<MerchantComplianceRequirementT[]> {
  const list: MerchantComplianceRequirementResponseT = await merchantApi.get(
    `${merchantId}/compliance-details/requirements`
  );

  return list.items
    .filter((item) => {
      return item.required;
    })
    .map((item) => {
      return item.section;
    });
}

export async function fetchOutboundWarehouseData(
  merchantId: string
): Promise<OutboundWarehouseInfoT[] | null> {
  try {
    const data: { items: OutboundWarehouseInfoT[] } = await merchantApi.get(
      `${merchantId}/outbound-warehouses`
    );
    return data.items;
  } catch (err) {
    return nullIf404Handler(err);
  }
}

export function fetchMerchantComplianceStatus(
  merchantId: string
): Promise<ComplianceStatusResponseT> {
  return merchantApi.get(`${merchantId}/compliance-details/status`);
}

// Status Update Endpoint
export function updateOutboundWarehouseReviewStatus(
  merchantId: string,
  warehouseId: string,
  data: ComplianceReviewRequestT
): Promise<void> {
  return merchantApi.post(
    `${merchantId}/outbound-warehouses/${warehouseId}/status`,
    data
  );
}
