/**
 * @ngdoc service
 * @name mcbHttpServerErrorFormatter
 * @module mcb.components.httpServerError
 */
export default function httpServerErrorFormatter() {
  return {
    format: function (error) {
      let message = 'Oops..';
      let serverMessage = '';

      if (error && angular.isString(error.data)) {
        serverMessage = error.data;
      }

      if (serverMessage !== '') {
        message = message + ' ' + serverMessage;
      } else {
        message = message + ' ' + 'Something went wrong. try again later.';
      }

      return message;
    },
  };
}
