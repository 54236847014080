import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mcp-admin-merchant-details-section__header" },
        [
          _vm._v("Billing and Bank details"),
          _c("ConfirmDataChange", {
            attrs: {
              "confirm-cb": (reason) => _vm.$emit("change-confirm", reason),
            },
          }),
        ],
        1
      ),
      _c("BillingDetailsReadonly", {
        attrs: {
          "hide-title": "",
          "billing-mode": _vm.billingMode,
          "invoice-language": _vm.invoiceLanguage,
        },
      }),
      _c(VDivider),
      _c("BankAccountReviewList", {
        attrs: {
          list: _vm.bankAccountList,
          "legal-entity-name": "",
          "hide-sales-channels": "",
          "hide-title": "",
          readonly: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }