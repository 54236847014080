import { BankAccountInfoT } from 'Api/models/BankAccount';

import { updateMerchant } from 'Api/endpoints/merchant/merchant.endpoint.es';
import { updateBillingMode } from 'Api/endpoints/merchant/merchant-billing-mode.endpoint';
import { BillingModeT } from 'Api/models/BillingMode';
import { PreferredLanguageT } from 'Api/models/MerchantProfile';
import {
  createBankAccount,
  updateBankAccount,
} from 'Api/endpoints/merchant/merchant-bank-accounts.endpoint';
import { any, pick, propEq } from 'ramda';

interface FormDataT {
  billingMode: BillingModeT;
  invoiceLanguage: PreferredLanguageT;
  bankAccountList: BankAccountInfoT[];
}

interface HandleFormSubmitPropsT {
  existingData: FormDataT;
  updatedData: FormDataT;
  merchantId: string;
}

type BankAccountInfoKeysT = (keyof BankAccountInfoT)[];

const bankAccountPayloadFields: BankAccountInfoKeysT = [
  'currencyCode',
  'iban',
  'bankName',
  'accountHolder',
  'confirmationDocumentId',
];

const isBillingModeChanged = (
  existingData: FormDataT,
  updatedData: FormDataT
) => existingData.billingMode !== updatedData.billingMode;

const isInvoiceLanguageChanged = (
  existingData: FormDataT,
  updatedData: FormDataT
) => existingData.invoiceLanguage !== updatedData.invoiceLanguage;

const isBankAccountChanged = (
  existingBankAccount: BankAccountInfoT,
  updatedBankAccount: BankAccountInfoT
) => {
  return bankAccountPayloadFields.some(
    (field) => existingBankAccount[field] !== updatedBankAccount[field]
  );
};

const getUpdatedBankAccounts = (
  existingBankAccounts: BankAccountInfoT[],
  updatedBankAccounts: BankAccountInfoT[]
) => {
  return updatedBankAccounts.filter((account) => {
    const bankAccountExistingData = existingBankAccounts.find(
      (existingBankAccount) =>
        existingBankAccount.currencyCode === account.currencyCode
    );
    return isBankAccountChanged(bankAccountExistingData, account);
  });
};

const createBankAccountPayload = (bankAccountInfo: BankAccountInfoT) =>
  pick(bankAccountPayloadFields, bankAccountInfo);

const getNewBankAccounts = (
  existingBankAccounts: BankAccountInfoT[],
  updatedBankAccounts: BankAccountInfoT[]
) => {
  return updatedBankAccounts.filter(({ currencyCode }) => {
    const existingBankAccountData = any(
      propEq('currencyCode', currencyCode),
      existingBankAccounts
    );

    return !existingBankAccountData;
  });
};

const handleFormSubmit = async ({
  existingData,
  updatedData,
  merchantId,
}: HandleFormSubmitPropsT): Promise<void> => {
  const isFirstTimeFormFilling = existingData.bankAccountList.length === 0;
  const requests = [];

  const triggerBankAccountsRequests = (
    bankAccountList: BankAccountInfoT[],
    requestFn: (props: {
      merchantId: string;
      data: BankAccountInfoT;
    }) => Promise<void>
  ) => {
    bankAccountList.forEach((bankAccount) => {
      requests.push(
        requestFn({
          merchantId,
          data: createBankAccountPayload(bankAccount),
        })
      );
    });
  };

  if (isInvoiceLanguageChanged(existingData, updatedData)) {
    requests.push(
      updateMerchant(merchantId, {
        preferredLanguage: updatedData.invoiceLanguage,
      })
    );
  }

  const isModeChanged = isBillingModeChanged(existingData, updatedData);

  if (isModeChanged) {
    await updateBillingMode(merchantId, updatedData.billingMode);
  }

  if (isFirstTimeFormFilling || isModeChanged) {
    triggerBankAccountsRequests(updatedData.bankAccountList, createBankAccount);
  } else {
    const newBankAccounts = getNewBankAccounts(
      existingData.bankAccountList,
      updatedData.bankAccountList
    );
    const updatedBankAccounts = getUpdatedBankAccounts(
      existingData.bankAccountList,
      updatedData.bankAccountList
    );

    triggerBankAccountsRequests(newBankAccounts, createBankAccount);
    triggerBankAccountsRequests(updatedBankAccounts, updateBankAccount);
  }

  await Promise.all(requests);
};

export default handleFormSubmit;
