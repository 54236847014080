/**
 * @ngdoc service
 * @name mcbHttpLoadingTrackerInterceptor
 * @module mcb.components.httpLoadingTracker
 *
 * @requires mcbHttpLoadingTracker
 * @requires $q
 */
let httpLoadingTrackerInterceptor = function httpLoadingTrackerInterceptor(
  mcbHttpLoadingTracker,
  $q
) {
  return {
    request: function (config) {
      mcbHttpLoadingTracker.addPending();
      return config;
    },
    requestError: function (rejection) {
      mcbHttpLoadingTracker.removePending();
      return $q.reject(rejection);
    },
    response: function (response) {
      mcbHttpLoadingTracker.removePending();
      return response;
    },
    responseError: function (rejection) {
      mcbHttpLoadingTracker.removePending();
      return $q.reject(rejection);
    },
  };
};

httpLoadingTrackerInterceptor.$inject = ['mcbHttpLoadingTracker', '$q'];

export default httpLoadingTrackerInterceptor;
