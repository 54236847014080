<template lang="pug">
  v-app
    v-container
      div(v-if="isFetching" ).dc-spinner.dc-spinner--small

      div(v-else)
        BillingAndBankDetailsForm(
          v-if="showForm"
          :billing-mode="billingMode"
          :invoice-language="invoiceLanguage"
          :bank-account-list="existingBankAccountList"
          :merchant-id="merchantId"
          :currency-sales-channels="currencySalesChannels"
          :legal-entity-name="legalEntityName"
          @on-close="showForm = false"
          @on-success="handleFormSubmitSuccess"
        )

        BillingAndBankDetailsReadonly(
          v-else
          :billing-mode="billingMode"
          :invoice-language="invoiceLanguage"
          :bank-account-list="bankAccountList"
          :merchant-id="merchantId"
          @change-confirm="handleConfirm"
        )

</template>

<script lang="ts">
import Vue from 'vue';
import { BankAccountListItemT } from 'Merchant/common/components/bank-account-details/bank-account-review-item.vue';
import {
  fetchBankAccountList,
  fetchCurrencySalesChannels,
} from 'Api/endpoints/merchant/merchant-bank-accounts.endpoint';
import BillingAndBankDetailsReadonly from 'Merchant/common/components/billing-and-bank-details-form/billing-and-bank-details-readonly.vue';
import BillingAndBankDetailsForm from 'Merchant/common/components/billing-and-bank-details-form/billing-and-bank-details-form.vue';
import { BillingModeT } from 'Api/models/BillingMode';
import {
  MerchantProfile,
  PreferredLanguageT,
} from 'Api/models/MerchantProfile';
import { getBankAccountsForBillingMode } from 'Merchant/common/components/billing-and-bank-details-form/util';
import { fetchBillingMode } from 'Api/endpoints/merchant/merchant-billing-mode.endpoint';
import { fetchPartnerLegalEntityDetails } from 'Api/endpoints/legal-entity/legal-entity.endpoint';
import {
  fetchMerchant,
  sendUpdateMerchantDataReason,
} from 'Api/endpoints/merchant/merchant.endpoint.es';

interface StoreState {
  merchantId: string;
  legalEntityId: string;
}

export default Vue.extend({
  components: {
    BillingAndBankDetailsForm,
    BillingAndBankDetailsReadonly,
  },

  props: {},

  data() {
    return {
      currencySalesChannels: [],
      isFetching: true,
      changeReason: '',
      showForm: false,
      billingMode: '' as BillingModeT,
      invoiceLanguage: '' as PreferredLanguageT,
      bankAccountList: [] as BankAccountListItemT[],
      existingBankAccountList: [] as BankAccountListItemT[],
      legalEntityName: '',
      // @ts-ignore
      ...(this.mapState({
        merchantId: 'flow.general.merchant.data.$id',
        legalEntityId: 'flow.general.merchant.data.legalEntityId',
      }) as StoreState),
    };
  },

  async mounted() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      const requests = [
        this.fetchInvoiceLanguage(),
        this.fetchLegalEntityName(),
        this.fetchApplicableCurrencies(),
        this.fetchBillingMode(),
        this.fetchBankAccounts(),
      ];

      await Promise.all(requests);
      this.resetBankAccountForBillingMode();
      this.isFetching = false;
    },
    async fetchInvoiceLanguage(): Promise<void> {
      const merchantData = await fetchMerchant(this.merchantId);

      this.invoiceLanguage = merchantData.preferredLanguage;
    },
    async fetchBankAccounts(): Promise<void> {
      this.existingBankAccountList = await fetchBankAccountList(
        this.merchantId
      );
    },
    async fetchApplicableCurrencies(): Promise<void> {
      this.currencySalesChannels = await fetchCurrencySalesChannels(
        this.merchantId
      );
    },
    async fetchBillingMode(): Promise<void> {
      const billingMode = await fetchBillingMode(this.merchantId);
      this.billingMode = billingMode || BillingModeT.LOCAL_CURRENCY_MODE;
    },
    async fetchLegalEntityName(): Promise<void> {
      if (this.legalEntityId) {
        const legalEntityData = await fetchPartnerLegalEntityDetails(
          this.legalEntityId
        );
        this.legalEntityName = legalEntityData.name.value;
      }
    },
    async handleFormSubmitSuccess(): Promise<void> {
      await sendUpdateMerchantDataReason(this.merchantId, this.changeReason);
      window.location.reload();
    },
    handleConfirm(changeReason: string): void {
      this.changeReason = changeReason;
      this.showForm = true;
    },
    resetBankAccountForBillingMode(): void {
      this.bankAccountList = getBankAccountsForBillingMode({
        billingMode: this.billingMode,
        bankAccounts: this.existingBankAccountList,
        currencySalesChannels: this.currencySalesChannels,
        legalEntityName: this.legalEntityName,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
  min-height: inherit;
}

.v-application {
  background: inherit;
}

::v-deep .field {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
  font-size: 14px !important;
  font-family: 'Ubuntu', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !important;
}

::v-deep .label {
  width: 191px;
  font-weight: 500;
  font-size: 11px !important;
  font-family: 'Ubuntu', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !important;
}
</style>
