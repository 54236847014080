<template lang="pug">
  v-app(
    id="vue-carrier"
    :style="{background: 'white'}"
  )
    v-main
      v-container(fluid).pa-0
        //- Loading
        div.text-center.mt-2(v-if="isLoading")
          v-progress-circular(
            :size="50"
            color="primary"
            indeterminate
          )
        //- Return Address API error
        v-alert(
          v-else-if="isLoadingError"
          :value="true"
          type="error"
        ) Something went wrong while fetching Carrier Details information
        template(v-else)
          template
            div(v-if="!shipmentCarrier" ).mb-5
              v-banner(
                outlined
                rounded
              )
                div.d-flex
                  v-icon.mr-4.justify-center mdi-information-outline

                  div.text-dark
                    | This partner does not have a carrier preference set yet.
                    | If this is a hybrid partner (the merchant is using both ZFS and PF),
                    | you will need to set their carrier for them. If this is not a hybrid partner,
                    | please wait for the partner to set their carrier in zDirect.

            div.mb-5.text-dark
              span.font-weight-bold Note:
              span
                |  Please refer to the
                |
                a(href="https://partnerportal.zalando.com/partners/s/article/Logistic-providers-per-country") carrier matrix
                |  before configuring a carrier. If the carrier is not in the list,
                | request an exception by asking the partner to fill out a form
                |
                a(href="https://docs.google.com/forms/d/e/1FAIpQLSfC9w77A7p_qqHLV3xTSrso3NaZA4NNMmudPxBrWMMB1jnNuQ/viewform") here
                | .
            div.grey.lighten-5.py-5.mb-5
              h3.black--text.ml-4
                | Outbound carrier details
              div.d-flex.mt-3.ml-0
                mp-labeled-text(label="Shipment carrier" :value="carrierName")
                mp-labeled-text(label="Delivery method" :value="deliveryProductsLabels")

            div.grey.lighten-5.px-4.py-5.mb-5
              h3.black--text
                | Add outbound carrier configuration
              div.mt-1.row
                v-select.ml-4(
                  :label="`${selectedCarrier ? 'Selected' : 'Select'} shipment carrier`"
                  :items="carriersList"
                  v-model="selectedCarrier"
                  @change="onCarrierChange"
                  :style="{maxWidth: '40%'}"
                )
                v-select.ml-4(
                  :label="`${selectedCarrierId ? 'Selected' : 'Select'} delivery method`"
                  :items="deliveryDestinationTypes"
                  v-model="selectedCarrierId"
                  @change="setParcelServices"
                  :disabled="!deliveryDestinationTypes.length"
                  :style="{maxWidth: '40%'}"
                )
              div.mt-1.row
                v-select.ml-4(
                  :label="`${selectedOptionalCapabilities.length ? 'Selected' : 'Select'} opt in service`"
                  :items="optionalCapabilities"
                  v-model="selectedOptionalCapabilities"
                  @change="isChanged = true"
                  :multiple="true"
                  :disabled="!optionalCapabilities.length"
                  :style="{maxWidth: '40%'}"
                )

                div(:style="{maxWidth: '40%'}").d-flex.flex-column.ml-4
                  label.text-caption.v-label.theme--light.font-weight-light Parcel service
                  div.mt-2 {{selectedParcelServices.join(', ') || '&#8212;'}}


              v-btn.mt-5.mb-6.ml-2.white--text.text-none(
                color="black"
                @click="addNewCarrier"
                :disabled="shouldDisableShipmentCarrierSubmit"
                :loading="isSubmitting"
              ) Add

            CarrierCards(
              v-if="configuredCarriers.length"
              :list="configuredCarriers"
              :on-delete="deleteCarrier"
            )
</template>
<script lang="ts">
import Vue from 'vue';
import { pick, find, propEq } from 'ramda';
import { ENV_CURRENT } from 'Common/constants/misc.constant';
import { Contract } from 'Api/models/Contract';
import { ContractRequirements as ContractRequirementsT } from 'Api/models/ContractRequirements';
import {
  fetchCarriers,
  fetchCarrierInfo,
  fetchCarrierByCode,
  putCarrierInfo,
  fetchAvailableCarriersForSalesChannel,
  fetchConfiguredCarriers,
  putConfiguredCarriers,
  ShipmentCarrierT,
  CarrierInfoT,
  CarrierRequestItemT,
} from 'Api/endpoints/sales-channel/carrier.endpoint';
import {
  DELIVERY_DESTINATIONS,
  OPTIONAL_CAPABILITIES,
  PARCEL_SERVICES,
} from 'Common/constants/carriers.constant';
import CarrierCards from './carrier-cards.vue';

export default Vue.extend({
  components: { CarrierCards },
  props: {
    onSave: {
      type: Function,
      default: null,
      required: false, // Required only if not readonly
    },
    onError: {
      type: Function,
      default: null,
      required: false, // Required only if not readonly
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingError: false,
      isSubmitting: false,
      isChanged: false,
      carriers: [] as ShipmentCarrierT[],
      deliveryProductsMap: {
        HOME: 'Home',
        PICKUP_POINT: 'Pick up Point',
        PACKSTATION: 'Packstation',
      },
      shipmentCarrier: '',
      deliverySpeed: 'STANDARD',
      deliveryProducts: [],
      // @ts-ignore
      ...this.mapState({
        merchantId: 'flow.specific.contract.data.merchantId', //maps state.todos to data.todoList
        salesChannelId: 'flow.specific.contract.data.salesChannelId', //maps state.todos to data.todoList
        contract: 'flow.specific.contract.data',
        requirements: 'flow.specific.requirements.data',
        requirementsLoading: 'flow.specific.requirements.loading',
      }),
      cepLink:
        ENV_CURRENT === 'PRODUCTION'
          ? 'https://cep-platform-frontend.logistics.zalan.do/partners'
          : 'https://cep-platform-frontend.logistics-test.zalan.do/partners',
      headers: [
        {
          text: 'Carrier',
          align: 'start',
          sortable: false,
          value: 'carrier',
        },
        {
          text: 'Delivery destination type',
          value: 'deliveryDestinationType',
        },
        { text: 'Capabilities', value: 'capabilities' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      configuredCarriers: [],
      availableCarriers: [],
      selectedCarrier: '',
      selectedCarrierId: '',
      selectedOptionalCapabilities: [],
      selectedParcelServices: [],
    };
  },
  computed: {
    carrierName(): string {
      if (!this.shipmentCarrier) {
        return 'Not defined';
      }

      return this.carriers.find(
        (carrier) => carrier.code === this.shipmentCarrier
      ).name;
    },
    deliveryProductsLabels(): string {
      if (!this.deliveryProducts.length) {
        return 'Not defined';
      }
      return this.deliveryProducts
        .map((val) => this.deliveryProductsMap[val])
        .join(', ');
    },
    isReadonly(): boolean {
      return (this.contract as Contract).launchStatus === 'LAUNCH_DONE';
    },
    isCepConfigMissing(): boolean {
      return this.requirementsLoading
        ? false
        : (this.requirements as ContractRequirementsT).cepConfiguration ===
            'MISSING';
    },
    isCepConfigConflict(): boolean {
      return this.requirementsLoading
        ? false
        : (this.requirements as ContractRequirementsT).cepConfiguration ===
            'CONFLICT';
    },
    carriersList() {
      return this.availableCarriers.map((carrierInfo) => carrierInfo.carrier);
    },
    deliveryDestinationTypes() {
      if (!this.selectedCarrier) return [];

      return this.availableCarriers
        .filter((carrierInfo) => carrierInfo.carrier === this.selectedCarrier)
        .map((carrierInfo) => ({
          value: carrierInfo.id,
          text: `${
            DELIVERY_DESTINATIONS[carrierInfo.deliveryDestinationType] ||
            carrierInfo.deliveryDestinationType
          } (${this.getParcelServices(carrierInfo).join(', ')})`,
        }));
    },
    shouldDisableShipmentCarrierSubmit() {
      return !this.isChanged || this.isSubmitting || !this.selectedCarrierId;
    },
    optionalCapabilities() {
      const selectedCarrier = this.availableCarriers.find(
        (carrier) => carrier.id === this.selectedCarrierId
      );
      return selectedCarrier
        ? selectedCarrier.optionalCapabilities.map((optionalCapability) => {
            return {
              value: optionalCapability,
              text:
                OPTIONAL_CAPABILITIES[optionalCapability] || optionalCapability,
            };
          })
        : [];
    },
  },
  mounted() {
    Promise.all([
      fetchCarriers(this.salesChannelId),
      fetchCarrierInfo(this.merchantId, this.salesChannelId),
      fetchAvailableCarriersForSalesChannel(this.salesChannelId),
      fetchConfiguredCarriers(this.merchantId, this.salesChannelId),
    ])
      .then(
        ([carriers, carrierInfo, availableCarriers, configuredCarriers]) => {
          Object.assign(this, carrierInfo);

          const carrierMap = configuredCarriers.reduce((acc, crr) => {
            acc[crr.id] = 1;
            return acc;
          }, {});

          this.configuredCarriers = configuredCarriers;
          this.availableCarriers = availableCarriers.filter(
            (carr) => !carrierMap[carr.id]
          );

          // Check if the carrier is in the carriers list (it can not be the case for some old merchants)
          if (
            carrierInfo?.shipmentCarrier &&
            !find(propEq('code', carrierInfo.shipmentCarrier), carriers)
          ) {
            // Get info about the missing carrier and add it to the list
            return fetchCarrierByCode(carrierInfo.shipmentCarrier).then(
              (val) => [...carriers, { ...val, disabled: true }]
            );
          }

          return carriers;
        }
      )
      .then((carriers) => {
        this.isLoading = false;
        this.carriers = carriers;
      })
      .catch(() => {
        this.isLoading = false;
        this.isLoadingError = true;
      });
  },
  methods: {
    setParcelServices() {
      this.isChanged = true;
      const selectedCarrier = this.availableCarriers.find(
        (carrierInfo) => carrierInfo.id === this.selectedCarrierId
      );

      this.selectedParcelServices = this.getParcelServices(selectedCarrier);
    },
    getParcelServices(carrierInfo) {
      return carrierInfo
        ? carrierInfo.capabilities.map(
            (capability) => PARCEL_SERVICES[capability] || capability
          )
        : [];
    },
    getNewCarriersList(configuredCarriers): CarrierRequestItemT[] {
      const list = configuredCarriers.map(
        ({ id, configuredOptionalDeliveryCapabilities }) => ({
          id,
          configuredOptionalDeliveryCapabilities,
        })
      );
      if (this.selectedCarrierId) {
        list.push({
          id: this.selectedCarrierId,
          configuredOptionalDeliveryCapabilities:
            this.selectedOptionalCapabilities,
        });
      }
      return list;
    },
    save(): void {
      this.isSubmitting = true;
      putCarrierInfo(
        this.merchantId,
        this.salesChannelId,
        pick(
          ['shipmentCarrier', 'deliverySpeed', 'deliveryProducts'],
          this as CarrierInfoT
        )
      )
        .then(() => {
          this.isSubmitting = false;
          this.isChanged = false;
          this.onSave();
        })
        .catch(() => {
          this.isSubmitting = false;
          this.isChanged = true;
          this.onError();
        });
    },
    updateCarrierList(list) {
      return putConfiguredCarriers(
        this.merchantId,
        this.salesChannelId,
        this.getNewCarriersList(list)
      );
    },
    addNewCarrier(): void {
      this.isSubmitting = true;
      this.updateCarrierList(this.configuredCarriers)
        .then(() => {
          this.isSubmitting = false;
          this.isChanged = false;
          this.onSave();
        })
        .catch(() => {
          this.isSubmitting = false;
          this.onError();
        });
    },
    deleteCarrier(id) {
      const list = this.configuredCarriers.filter((crr) => crr.id !== id);

      this.updateCarrierList(list)
        .then(() => this.onSave())
        .catch(() => this.onError());
    },
    onCarrierChange() {
      this.isChanged = true;
      this.selectedCarrierId = '';
      this.selectedParcelServices = [];
    },
  },
});
</script>
<style lang="scss" scoped>
.v-banner {
  background-color: #eeeeee !important;
  border: 1px solid #d8d8da !important;
}
.text-dark {
  color: #1a1a1a;
  font-weight: 400;
}
</style>
