import { render, staticRenderFns } from "./return-address-container.vue?vue&type=template&id=7e668ea6&scoped=true&lang=pug"
import script from "./return-address-container.vue?vue&type=script&lang=ts"
export * from "./return-address-container.vue?vue&type=script&lang=ts"
import style0 from "./return-address-container.vue?vue&type=style&index=0&id=7e668ea6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e668ea6",
  null
  
)

export default component.exports