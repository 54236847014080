import {
  COUNTRIES,
  CHECK_NUMERICAL,
  CHECK_EXACT_LENGTH,
  CHECK_MAX_LENGTH,
} from '../constants/misc.constant';

const NUMERIC_REGEXP = /^\d+$/;
const NO_GAPS_REGEXP = /^\S+$/;

const removeWhitespace = (val: string): string => val.replace(/\s/g, '');

const checkFns = {
  [CHECK_NUMERICAL]: (val) => NUMERIC_REGEXP.test(removeWhitespace(val)),
  [CHECK_EXACT_LENGTH]: (val, baseLength) =>
    removeWhitespace(val).length === baseLength,
  [CHECK_MAX_LENGTH]: (val, baseLength) =>
    removeWhitespace(val).length <= baseLength,
};

export function taxVatValidator() {
  return {
    require: 'ngModel',
    scope: {
      fieldType: '@mcpAdminTaxVatValidator',
      country: '@mcpAdminTaxVatValidatorCountry',
    },
    link,
  };
}

function link(scope, elem, attrs, ctrl) {
  Object.assign(ctrl.$validators, {
    mcpCountry,
    mcpMaxLength,
    mcpNumerical,
    mcpExactLength,
  });

  // Country is required for validation of both fields
  function mcpCountry(modelValue) {
    // Consider empty model to be valid
    if (ctrl.$isEmpty(modelValue)) {
      return true;
    }

    return !!scope.country;
  }

  // Max Length check (is it only when it is relevant)
  function mcpMaxLength(modelValue) {
    return validate(CHECK_MAX_LENGTH, modelValue);
  }

  // Numeric check
  function mcpNumerical(modelValue) {
    return validate(CHECK_NUMERICAL, modelValue);
  }

  function mcpExactLength(modelValue) {
    return validate(CHECK_EXACT_LENGTH, modelValue);
  }

  function validate(check: string, modelValue) {
    const { fieldType, country } = scope;

    // Consider empty models to be valid
    if (ctrl.$isEmpty(modelValue)) {
      return true;
    }

    // Country is being validated by a separate validation function (see $validators.mcpCountry)
    // Therefore consider empty country to be valid here
    if (!country) {
      return true;
    }

    const countryMetadata = COUNTRIES.find((data) => data.code === country);

    const [baseLength, ...checks] =
      fieldType === 'VAT'
        ? countryMetadata.vatIdChecks
        : countryMetadata.taxIdChecks;

    // Consider lack of maxLength check to be valid
    if (!checks.includes(check)) {
      return true;
    }

    return checkFns[check](modelValue, baseLength);
  }
}
