import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify, {
  VAlert,
  VApp,
  VAppBar,
  VAutocomplete,
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCheckbox,
  VChip,
  VContainer,
  VContent,
  VCol,
  VDialog,
  VDivider,
  VFlex,
  VHover,
  VIcon,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanels,
  VExpansionPanelContent,
  VLayout,
  VList,
  VListItem,
  VListItemContent,
  VListItemTitle,
  VListItemSubtitle,
  VMain,
  VMenu,
  VProgressCircular,
  VRow,
  VSelect,
  VSheet,
  VSnackbar,
  VSpacer,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTextField,
  VToolbar,
  VToolbarItems,
  VToolbarTitle,
  VTreeview,
} from 'vuetify/lib';

Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VAppBar,
    VAutocomplete,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VCheckbox,
    VChip,
    VContainer,
    VContent,
    VCol,
    VDialog,
    VDivider,
    VFlex,
    VHover,
    VIcon,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanels,
    VExpansionPanelContent,
    VLayout,
    VList,
    VListItem,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
    VMain,
    VMenu,
    VProgressCircular,
    VRow,
    VSelect,
    VSheet,
    VSnackbar,
    VSpacer,
    VTab,
    VTabItem,
    VTabs,
    VTabsItems,
    VTextField,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
    VTreeview,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FF6900',
        primaryLight: '#fdebe2',
        secondary: '#4A4A4A',
        warning: '#F58B00',
        secondaryDark: '#212121',
        secondaryLight: '#f6f6f6',
        disabled: '#9B9B9B',
        reject: '#D0021B',
        backgroundColor: '#fcfcfc',
      },
    },
  },
});
