<template lang="pug">
v-app(id="approve-reject")
  //- Buttons
  div.dc-row.dc-row--align--right
    div.dc-column.dc-column--shrink
      button.dc-btn(
        @click="approve"
        :disabled="buttonsDisabled"
        :class="{'dc-btn--disabled': buttonsDisabled}"
        data-testid="approve-btn"
      ) Approve

    div.dc-column.dc-column--shrink
      button.dc-btn.dc-btn--destroy(
        @click="confirmRejection"
        :disabled="buttonsDisabled"
        :class="{'dc-btn--disabled': buttonsDisabled}"
        data-testid="reject-btn"
      ) Reject

  //- Rejection confirmation modal window
  v-dialog(
      v-model="showConfirmRejectionModal"
      width="800"
    )
      v-card
        v-card-title.title Provide a reason for rejection
        mpAlert(type="warning")
          | You are about to reject <b>{{ merchant.companyName }}'s</b> data on zDirect.
          | The reason may be displayed to the partner on zDirect, based on your selection.
          | Please proceed with caution and specify accurate reasons for the rejection
        v-card-text
          v-radio-group(v-model="rejectionScope")
            v-row
              v-col(md=12)
                h4
                  | Who should the rejection reason go to?
              v-col(md=4)
                v-radio(:value="rejectionScopeOptions.partners" label="Partners")
              v-col(md=4)
                v-radio(:value="rejectionScopeOptions.internal" label="Internal teams")

          v-row(v-if="isScopePartners")
            v-col(md=12)
              h4
                | Provide rejection detail(s):
              span
                | Please select where there may be incomplete
                | or incorrect details provided by the Partner
            v-col(md=4)
              v-checkbox(v-model="rejectedSections" value="NAME" label="Company name")
            v-col(md=4)
              v-checkbox(v-model="rejectedSections" value="CONTACT" label="Company contacts")
            v-col(md=4)
              v-checkbox(v-model="rejectedSections" value="ADDRESS" label="Company address")
            v-col(md=4)
              v-checkbox(v-model="rejectedSections" value="BILLING_DATA" label="Billing currency")
            v-col(md=4)
              v-checkbox(v-model="rejectedSections" value="BANK_DATA" label="Bank details")
          v-textarea(
            filled
            autofocus
            label="A reason for rejection"
            :hint="'Please specify at least '+minWordsCount+' words'"
            v-model="rejectionReason"
          )

        v-card-actions
          v-spacer
          v-btn(
            text
            @click="showConfirmRejectionModal = false"
          ) Cancel

          v-btn(
            color="primary"
            @click="reject"
            :disabled="!reasonSpecified"
          ) Reject
</template>

<script lang="ts">
import { RejectionScopes } from '@/api/models/Contract';
import Vue from 'vue';

export default Vue.extend({
  props: {
    approveCb: Function,
    rejectCb: Function,
    merchant: Object,
  },
  data() {
    return {
      showConfirmRejectionModal: false,
      rejectionReason: '',
      minWordsCount: 5,
      buttonsDisabled: false,
      rejectionScopeOptions: {
        partners: RejectionScopes.partners,
        internal: RejectionScopes.internal,
      },
      rejectionScope: RejectionScopes.partners,
      rejectedSections: [],
    };
  },
  computed: {
    reasonSpecified(): boolean {
      return (
        this.rejectionReason.trim().split(/\s+/).length >= this.minWordsCount
      );
    },
    isScopePartners() {
      return this.rejectionScope === RejectionScopes.partners;
    },
  },
  methods: {
    approve(): void {
      this.buttonsDisabled = true;
      this.approveCb();
    },
    confirmRejection(): void {
      this.showConfirmRejectionModal = true;
    },
    reject(): void {
      this.showConfirmRejectionModal = false;
      this.buttonsDisabled = true;
      this.rejectCb(
        this.rejectionReason,
        this.rejectionScope,
        this.rejectedSections
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.dc-btn--destroy {
  border-color: #ff4a25;
  background: #ff6c4f !important;
  color: white;
}
</style>
