import { ContractRequirements } from 'Api/models/ContractRequirements';
export const CONTRACT_REQUIREMENTS_LOADED = 'CONTRACT_REQUIREMENTS_LOADED';
export const CONTRACT_REQUIREMENTS_LOADING = 'CONTRACT_REQUIREMENTS_LOADING';

export interface ContractRequirementsState {
  loading: boolean;
  data: ContractRequirements;
  error: any;
}

export const contractRequirementsInitialState = {
  loading: false,
  data: {} as ContractRequirements,
  error: null,
};

export const contractStatusReducer = (
  state = { ...contractRequirementsInitialState },
  { type, payload }
) => {
  switch (type) {
    case CONTRACT_REQUIREMENTS_LOADED:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case CONTRACT_REQUIREMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
