import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("div", { staticClass: "mt-8" }, [
        _vm.isLoading
          ? _c("div", [_vm._v("Loading...")])
          : _c(
              "div",
              [
                _c("h4", { staticClass: "pb-2" }, [
                  _vm._v("Optional outbound & return solutions"),
                ]),
                _c("p", [
                  _vm._v(
                    "Before configuring any optional solutions for partners using self fufilment,\nensure that relevant contracts are signed and partners have signed off on changes."
                  ),
                  _vm.isHybridChannel
                    ? _c("span", [
                        _vm._v(" Note that "),
                        _c("strong", [
                          _vm._v(
                            "ZRS Plus & ZRS Pure can not be enabled at the same time."
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _c(VSwitch, {
                  attrs: { label: "Zalando Shipping Solution (ZSS)" },
                  model: {
                    value: _vm.isZSS,
                    callback: function ($$v) {
                      _vm.isZSS = $$v
                    },
                    expression: "isZSS",
                  },
                }),
                _c(
                  VFlex,
                  {
                    staticStyle: { "flex-direction": "row" },
                    attrs: { "d-flex": "" },
                  },
                  [
                    _c(VSwitch, {
                      attrs: {
                        disabled: _vm.isZRSPlus,
                        label: "Zalando Return Solutions (ZRS) Pure",
                      },
                      model: {
                        value: _vm.isZRSPure,
                        callback: function ($$v) {
                          _vm.isZRSPure = $$v
                        },
                        expression: "isZRSPure",
                      },
                    }),
                    !_vm.isZRSPure &&
                    _vm.zrsPureOffboardedAt &&
                    _vm.zrsPureOffboardedAt !== new Date()
                      ? _c(
                          VFlex,
                          {
                            staticStyle: { "align-items": "center" },
                            attrs: { "d-flex": "" },
                          },
                          [
                            _c(
                              VChip,
                              {
                                staticClass: "left-margin",
                                attrs: {
                                  color: "#FCD999",
                                  "text-color": "#5C3A1E",
                                  small: "",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [_vm._v("GRACE PERIOD")]
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "mcp-admin__field-info dc--has-tooltip dc--has-tooltip--top-right side-margin",
                                attrs: {
                                  "data-dc-has-tooltip":
                                    "Partner has requested for offboarding and is in the process of getting deactivated from Zalando Returns Solutions (ZRS). During this grace period, the returns will continue to be handled by ZRS.",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "dc-icon dc-icon--info",
                                }),
                              ]
                            ),
                            _c("span", [
                              _vm._v(
                                "(Period end date: " +
                                  _vm._s(
                                    _vm.getDateOnly(_vm.zrsPureOffboardedAt)
                                  ) +
                                  ")"
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.isHybridChannel
                  ? _c(
                      VFlex,
                      {
                        staticStyle: { "flex-direction": "row" },
                        attrs: { "d-flex": "" },
                      },
                      [
                        _c(VSwitch, {
                          attrs: {
                            disabled:
                              !_vm.isZfsChannelLaunched ||
                              (_vm.isZfsChannelLaunched && _vm.isZRSPure),
                            label: "Zalando Return Solutions (ZRS) Plus",
                          },
                          model: {
                            value: _vm.isZRSPlus,
                            callback: function ($$v) {
                              _vm.isZRSPlus = $$v
                            },
                            expression: "isZRSPlus",
                          },
                        }),
                        !_vm.isZfsChannelLaunched
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "mcp-admin__field-info dc--has-tooltip dc--has-tooltip--top-right side-margin top-margin",
                                attrs: {
                                  "data-dc-has-tooltip":
                                    "ZRS Plus will be enabled once the ZFS contract is LAUNCHED.",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "dc-icon dc-icon--info",
                                }),
                              ]
                            )
                          : _vm._e(),
                        !_vm.isZRSPlus &&
                        _vm.zrsPlusOffboardedAt &&
                        _vm.zrsPlusOffboardedAt !== new Date()
                          ? _c(
                              VFlex,
                              {
                                staticStyle: { "align-items": "center" },
                                attrs: { "d-flex": "" },
                              },
                              [
                                _c(
                                  VChip,
                                  {
                                    staticClass: "left-margin",
                                    attrs: {
                                      color: "#FCD999",
                                      "text-color": "#5C3A1E",
                                      small: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("GRACE PERIOD")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "mcp-admin__field-info dc--has-tooltip dc--has-tooltip--top-right side-margin",
                                    attrs: {
                                      "data-dc-has-tooltip":
                                        "Partner has requested for offboarding and is in the process of getting deactivated from Zalando Returns Solutions (ZRS). During this grace period, the returns will continue to be handled by ZRS.",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "dc-icon dc-icon--info",
                                    }),
                                  ]
                                ),
                                _c("span", [
                                  _vm._v(
                                    "(Period end date: " +
                                      _vm._s(
                                        _vm.getDateOnly(_vm.zrsPlusOffboardedAt)
                                      ) +
                                      ")"
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(VSwitch, {
                  attrs: {
                    label: "Return Label Generation (RLG)",
                    disabled: !_vm.isRLGEligible,
                  },
                  model: {
                    value: _vm.isRlgEnabled,
                    callback: function ($$v) {
                      _vm.isRlgEnabled = $$v
                    },
                    expression: "isRlgEnabled",
                  },
                }),
                _c(
                  VFlex,
                  {
                    staticStyle: { "justify-content": "flex-end" },
                    attrs: { "d-flex": "" },
                  },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "mr-2",
                        attrs: {
                          "data-testid": "save-cta",
                          color: "primary",
                          disabled: !_vm.isInputDirty,
                        },
                        on: {
                          click: function ($event) {
                            _vm.showModal = true
                          },
                        },
                      },
                      [_vm._v("Save")]
                    ),
                    _c(
                      VBtn,
                      {
                        attrs: {
                          text: _vm.isInputDirty,
                          color: "grey",
                          elevation: "2",
                          disabled: !_vm.isInputDirty,
                        },
                        on: { click: _vm.resetState },
                      },
                      [_vm._v("Cancel")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
      ]),
      _c(
        VDialog,
        {
          attrs: { width: "550" },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                "mpAlert",
                { staticClass: "text-h6", attrs: { type: "warning" } },
                [_vm._v("Solutions configuration")]
              ),
              _c(
                VCardText,
                { staticClass: "pa-2" },
                [
                  _c("p", { staticClass: "p-4" }, [
                    _vm._v(
                      "You are about to modify the outbound or returns solutions configuration of a partner.\nThis change will be updated automatically by all other systems.\nPlease ensure that the partner has signed off on the configuration."
                    ),
                  ]),
                  _c(
                    VCardActions,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.showModal = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            "data-testid": "confirm-modal-save",
                            color: "primary",
                            depressed: "",
                            loading: _vm.isShipmentLoading,
                          },
                          on: { click: _vm.updateShipmentFlags },
                        },
                        [_vm._v("Save")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }