import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VMain,
    [
      _c(
        VContainer,
        { attrs: { fluid: "", "pa-0": "", "fill-height": "" } },
        [
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { attrs: { xs8: "", "pa-6": "" } },
                [
                  _c(
                    VLayout,
                    { staticClass: "white py-6 form-wrapper" },
                    [
                      _c(
                        VFlex,
                        { attrs: { xs10: "", "offset-xs1": "" } },
                        [
                          _c("h2", { staticClass: "title mb-2" }, [
                            _vm._v(" Master Data Configuration"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              !_vm.canExport &&
                              ![
                                "INIT_INPROGRESS",
                                "INIT_FAILED",
                                "READONLY",
                              ].includes(_vm.state)
                                ? _c("Alert", { attrs: { type: "warning" } }, [
                                    _vm._v("Export language is not set"),
                                  ])
                                : _vm._e(),
                              _vm.showSavedNotExportedWarning
                                ? _c("Alert", { attrs: { type: "warning" } }, [
                                    _vm._v(
                                      "There is saved master data that has not been exported yet. Please send to export"
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.showLastExportedMsg
                                ? _c("Alert", { attrs: { type: "info" } }, [
                                    _c("span", [
                                      _vm._v(
                                        "The master data was sent to export on"
                                      ),
                                    ]),
                                    _c("span", { staticClass: "time" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDateTime")(
                                            _vm.exportLogs[0].createdAt
                                          )
                                        )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          [
                            "DEFAULT",
                            "SAVE_INPROGRESS",
                            "EXPORT_INPROGRESS",
                            "READONLY",
                          ].includes(_vm.state)
                            ? _c("MasterdataForm", {
                                attrs: {
                                  attributes: _vm.attributes,
                                  values: _vm.values,
                                  "saved-values": _vm.savedValues,
                                  "size-attrs": _vm.sizeAttrs,
                                  "size-attrs-cache-map": _vm.sizeAttrsCacheMap,
                                  "size-attrs-loading": _vm.sizeAttrsLoading,
                                  "is-submitting":
                                    _vm.state === "SAVE_INPROGRESS",
                                  "is-exporting":
                                    _vm.state === "EXPORT_INPROGRESS",
                                  "is-readonly": _vm.state === "READONLY",
                                  "has-changes": _vm.hasChanges,
                                  "is-complete": _vm.isComplete,
                                  "can-export": _vm.canExport,
                                  "on-brand-change": _vm.onChangeofBrands,
                                },
                                on: {
                                  save: function ($event) {
                                    return _vm.$emit("save", _vm.values)
                                  },
                                  clear: _vm.clear,
                                  export: function ($event) {
                                    return _vm.$emit("export")
                                  },
                                  "update:sizeSearchInput": function ($event) {
                                    return _vm.$emit(
                                      "update:sizeSearchInput",
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm.state === "INIT_INPROGRESS"
                            ? _c(
                                VLayout,
                                { attrs: { "justify-space-around": "" } },
                                [
                                  _c(VProgressCircular, {
                                    staticClass: "init-in-progress-icon",
                                    attrs: {
                                      size: 50,
                                      color: "primary",
                                      indeterminate: "",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm.state === "INIT_FAILED"
                            ? _c(
                                VLayout,
                                [
                                  _c(
                                    VFlex,
                                    { staticClass: "mt-12" },
                                    [
                                      _c(
                                        "Alert",
                                        { attrs: { type: "error" } },
                                        [
                                          _vm._v(
                                            "Masterdata initialisation failed"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs4: "" } },
                [
                  _c(
                    VLayout,
                    { attrs: { "fill-height": "" } },
                    [
                      _c(VDivider, { attrs: { vertical: "" } }),
                      _c(
                        VFlex,
                        {
                          staticClass: "tab-items-container",
                          attrs: { grow: "" },
                        },
                        [
                          _c(
                            VTabs,
                            {
                              attrs: {
                                slot: "activator",
                                grow: "",
                                "slider-color": "primary",
                              },
                              slot: "activator",
                              model: {
                                value: _vm.sidebarTab,
                                callback: function ($$v) {
                                  _vm.sidebarTab = $$v
                                },
                                expression: "sidebarTab",
                              },
                            },
                            [
                              _c(VTab, [_vm._v("Summary")]),
                              _c(VTab, [_vm._v("History")]),
                              _c(
                                VTabItem,
                                [
                                  [
                                    "DEFAULT",
                                    "SAVE_INPROGRESS",
                                    "EXPORT_INPROGRESS",
                                    "READONLY",
                                  ].includes(_vm.state)
                                    ? _c("MasterdataSummaryContainer", {
                                        attrs: {
                                          readonly: [
                                            "SAVE_INPROGRESS",
                                            "EXPORT_INPROGRESS",
                                            "READONLY",
                                          ].includes(_vm.state),
                                          "attributes-maps": _vm.attributesMaps,
                                          values: _vm.values,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                VTabItem,
                                [
                                  _c("MasterdataHistory", {
                                    attrs: { logs: _vm.exportLogs },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }