<template lang="pug">
  v-app(id="confirm-data-change")
    a.dc-btn.dc-btn--link.mcp-admin-merchant-button--edit(
      @click="editForm"
    )
      span(v-if="!customEditText")
        | Edit
        i.dc-icon.dc-icon--pencil.dc-icon--btn.dc-icon--btn--left
      span(v-else) {{ customEditText }}
    v-dialog(
        v-model="showConfirmDataChangeModal"
        width="550"
      )
        v-card
          v-card-title.title Do you want to edit company information?

          mpAlert(type="warning") You are about to change the Company Information for {{ merchantProfile.name }} who is supposed to use zDirect to provide this information. Please confirm by specifying the reason why you would like to perform this action.

          v-card-text
            v-textarea(
              filled
              autofocus
              label="A reason for data change(s)"
              :hint="'Please specify at least '+minWordsCount+' words'"
              v-model="dataChangeReason"
            )

          v-card-actions
            v-spacer
            v-btn(
              text
              @click="cancel"
            ) Cancel

            v-btn(
              color="primary"
              @click="confirm"
              :disabled="!reasonSpecified"
            ) Confirm
</template>

<script lang="ts">
import Vue from 'vue';
import { isMerchantZPO } from '@/api/endpoints/merchant/merchant.endpoint.es';

export default Vue.extend({
  props: {
    confirmCb: {
      type: Function,
      required: true,
    },
    customEditText: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      dataChangeReason: '',
      minWordsCount: 5,
      showConfirmDataChangeModal: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...this.mapState({
        merchantProfile: 'flow.general.merchant.data',
      }),
    };
  },
  computed: {
    reasonSpecified(): boolean {
      return (
        this.dataChangeReason.trim().split(/\s+/).length >= this.minWordsCount
      );
    },
    isMerchantZPO(): boolean {
      return isMerchantZPO(this.merchantProfile);
    },
  },
  methods: {
    cancel(): void {
      this.showConfirmDataChangeModal = false;
    },
    confirm(): void {
      this.showConfirmDataChangeModal = false;
      this.confirmCb(this.dataChangeReason);
      this.dataChangeReason = '';
    },
    editForm(): void {
      if (this.isMerchantZPO) {
        this.showConfirmDataChangeModal = true;
      } else {
        this.confirmCb();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.v-application {
  background: inherit;
  float: right;
  & ::v-deep &--wrap {
    margin-top: 0;
    min-height: auto;
  }
}
.dc-btn {
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
  transition: color 0.1s linear;
  color: #26aafe;
  &:hover {
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;
    color: #1e87cb;
  }
}
</style>
