export class Endpoints {
  constructor(
    private merchantApiUrl: string = '',
    private configurationApiUrl: string = ''
  ) {}

  getContractUrlTemplate(contractId?: string): string {
    if (contractId) {
      return `${this.merchantApiUrl}/contracts/${contractId}`;
    }
    return `${this.merchantApiUrl}/contracts`;
  }

  getMerchantUrlTemplate(merchantId?: string): string {
    if (merchantId) {
      return `${this.merchantApiUrl}/merchants/${merchantId}`;
    }
    return `${this.merchantApiUrl}/merchants`;
  }

  getMerchantApprovalUrlTemplate(merchantId: string): string {
    return this.getMerchantUrlTemplate(merchantId) + '/approve';
  }

  getMerchantRejectionUrlTemplate(merchantId: string): string {
    return this.getMerchantUrlTemplate(merchantId) + '/reject';
  }

  getAddressUrlTemplate(merchantId: string): string {
    return this.getMerchantUrlTemplate(merchantId) + '/address';
  }

  getBankAccountUrlTemplate(merchantId: string): string {
    return this.getMerchantUrlTemplate(merchantId) + '/bank-account';
  }

  getRetailerTemplate(): string {
    return `${this.merchantApiUrl}/retailers`;
  }

  getMerchantContractsUrlTemplate(merchantId: string): string {
    return this.getMerchantUrlTemplate(merchantId) + '/contracts';
  }

  getMerchantGroupsUrlTemplate(): string {
    return `${this.merchantApiUrl}/merchant-groups`;
  }

  getMerchantGroupUrlTemplate(groupId: string): string {
    return `${this.merchantApiUrl}/merchant-groups/${groupId}`;
  }

  getMerchantStatusChangeUrlTemplate(merchantId: string): string {
    return this.getMerchantUrlTemplate(merchantId) + '/status';
  }

  getContractAddressUrlTemplate(contractId: string): string {
    return this.getContractUrlTemplate(contractId) + '/address';
  }

  getContractBankAccountUrlTemplate(contractId: string): string {
    return this.getContractUrlTemplate(contractId) + '/bank-account';
  }

  getMerchantRequirementsUrlTemplate(merchantId: string): string {
    return `${this.merchantApiUrl}/merchants/${merchantId}/requirements`;
  }

  getContractRequirementsUrlTemplate(contractId: string): string {
    return `${this.merchantApiUrl}/contracts/${contractId}/requirement`;
  }

  getContractStatusChangeUrlTemplate(contractId: string): string {
    return `${this.merchantApiUrl}/contracts/${contractId}/status`;
  }

  getConfigurationsUrlTemplate(partnerId?: string): string {
    return `${this.configurationApiUrl}/configurations/${partnerId}`;
  }

  getConfigPartnerProviderDefaultsUrlTemplate(providerId: string): string {
    return `${this.configurationApiUrl}/partner-providers/${providerId}/defaults`;
  }

  getConfigPartnerProvidersUrlTemplate(): string {
    return `${this.configurationApiUrl}/partner-providers`;
  }

  getContractDataChangesUrl(contractId: string): string {
    return `${this.getContractUrlTemplate(contractId)}/modified-ui-sections`;
  }

  getMerchantDataChangesUrl(merchantId: string): string {
    return `${this.getMerchantUrlTemplate(merchantId)}/modified-ui-sections`;
  }

  getFactoringMigrationStatusUrl(merchantId: string): string {
    return `${this.getMerchantUrlTemplate(
      merchantId
    )}/factoring-migration-status`;
  }

  getMigrateFactoringUrl(): string {
    return `${this.getMerchantUrlTemplate()}/migrate-factoring`;
  }
}
