<template lang="pug">
div.container
  div.caption.disabled--text.ml-6.pt-4
    | Merchant Account
  v-sheet.my-1.mx-2(min-height="40px" :color="!selectedLegalEntityId ? 'primaryLight' : ''")
    v-flex.subtitle-1.primary--text.py-2.ml-4.align-center(
      d-flex
      :class="{'font-weight-bold': !selectedLegalEntityId }"
    )
      a(
        :href="'/#/merchant-accounts/' + merchantAccount.id"
        :class="{'primary--text': !selectedLegalEntityId, 'secondary--text': !!selectedLegalEntityId }"
      )
        | {{ merchantAccount.name }}

  v-divider(light)

  div.mt-6
    div.caption.disabled--text.mb-4.ml-6
      | Legal Entities
    v-sheet.mx-2.my-1(
      min-height="40px"
      v-for="{ name, id } in merchantAccount.legalEntities"
      :color="selectedLegalEntityId === id ? 'primaryLight' : ''"
      :key="id"
    )
      v-flex.subtitle-1.primary--text.py-2.ml-4.align-center(
        d-flex
        :class="{'font-weight-bold': selectedLegalEntityId === id }"
      )
        a(
          :href="'/#/legal-entities/' + id"
          :class="{'primary--text': selectedLegalEntityId === id, 'secondary--text': selectedLegalEntityId !== id }"
        )
          | {{ name }}

</template>

<script lang="ts">
import Vue from 'vue';
import MerchantAccount from 'Api/models/MerchantAccount';

export default Vue.extend({
  name: 'SidePanel',
  props: {
    merchantAccount: {
      type: Object as () => MerchantAccount,
      required: true,
    },
    selectedLegalEntityId: {
      type: String,
      required: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.container {
  max-width: 240px;
  background-color: white;
  height: 100%;
}

a {
  text-decoration: none;
}
</style>
