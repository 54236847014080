var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.showName
      ? _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "label" }, [_vm._v("Name")]),
          _c("div", [_vm._v(_vm._s(_vm.address.name))]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "label" }, [_vm._v("Address")]),
      _c("div", [
        _vm._v(
          _vm._s(_vm.address.addressLine1) +
            " " +
            _vm._s(_vm.address.addressLine2) +
            " " +
            _vm._s(_vm.address.addressLine3)
        ),
      ]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "label" }, [_vm._v("Zip")]),
      _c("div", [_vm._v(_vm._s(_vm.address.zipCode))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "label" }, [_vm._v("City")]),
      _c("div", [_vm._v(_vm._s(_vm.address.city))]),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "label" }, [_vm._v("Country")]),
      _c("div", [_vm._v(_vm._s(_vm.countryName))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }