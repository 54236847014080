<template lang="pug">
v-app
  Appbar(hide-tabs)
    BackLink.back-link(v-if="!loading && !hasError" href="/#/merchant-accounts")
      | {{ merchantAccount ? merchantAccount.name : 'Merchant Accounts' }}

  v-main(style="background-color: backgroundColor")
    //- Error message
    v-snackbar(v-model="hasError" :timeout="-1" bottom color="reject")
      | {{ this.errorMessage }}
      v-btn.ml-1(@click="fetchData()" text) Retry
      a.text-center(href="/#/merchant-accounts")
        v-btn.ml-1(text) Back

    v-container(v-if="loading")
      PageLoader

    v-flex(v-if="!loading && !hasError" d-flex fill-height)
      SidePanel(
        v-if="merchantAccount"
        :merchantAccount="merchantAccount"
        :selectedLegalEntityId="legalEntityId"
        )
      v-divider(vertical)

      v-container.px-6.pt-6
        LegalEntityDetails(v-if="legalEntity" :legalEntity="legalEntity")

</template>

<script lang="ts">
import Vue from 'vue';
import Appbar from 'Common/components/appbar/appbar.container.vue';
import PageLoader from 'Common/components/page-loader/page-loader.vue';
import BackLink from 'Common/components/back-link/back-link.vue';
import SidePanel from 'Common/components/accounts-side-panel/side-panel.vue';
import { fetchLegalEntity } from 'Api/endpoints/legal-entity/legal-entity.endpoint';
import { fetchMerchantAccountDetails } from 'Api/endpoints/merchant-account/merchant-account.endpoint';
import MerchantAccount from 'Api/models/MerchantAccount';
import LegalEntity from 'Api/models/LegalEntity';
import LegalEntityDetails from './main/main.vue';

export default Vue.extend({
  name: 'LegalEntityDetailsContainer',
  components: {
    Appbar,
    BackLink,
    LegalEntityDetails,
    PageLoader,
    SidePanel,
  },
  props: {
    legalEntityId: {
      type: String,
      required: true,
    },
  },
  data() {
    const merchantAccount: MerchantAccount = null;
    const legalEntity: LegalEntity = null;

    return {
      errorMessage: '',
      loading: true,
      merchantAccount,
      legalEntity,
    };
  },
  computed: {
    hasError(): boolean {
      return this.errorMessage !== '';
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData(): void {
      this.errorMessage = '';
      this.loading = true;

      fetchLegalEntity(this.legalEntityId)
        .then((entity) => (this.legalEntity = entity))
        .then((entity) => {
          if (entity.merchantAccountId) {
            return fetchMerchantAccountDetails(entity.merchantAccountId);
          }
          return Promise.resolve(null);
        })
        .then((merchantAccount) => (this.merchantAccount = merchantAccount))
        .catch(
          () =>
            (this.errorMessage =
              'An internal system error has occurred. Please try again after some time.')
        )
        .finally(() => (this.loading = false));
    },
  },
});
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.back-link {
  margin-left: 90px;
}
</style>
