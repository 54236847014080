import { MerchantProfile } from 'Api/models/MerchantProfile';
import {
  MerchantChoiceSelection,
  MerchantChoiceSelections,
} from 'Api/models/MerchantChoiceSelection';
import { RequestConfig } from 'Api/services/http.service';
import axios from 'axios';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import { pipe } from 'ramda';
import ErrorHandler from 'Api/api-errors-handler';

const choiceAPI = axios.create({ baseURL: '/api/merchant-profile/merchants' });
choiceAPI.interceptors.request.use(decamelizeRequestHandler);
choiceAPI.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);
export function fetchMerchantChoices(
  merchantId: string
): Promise<MerchantChoiceSelections> {
  return choiceAPI.get(`/${merchantId}/choices`);
}
export function updateMerhchantChoice(
  merchantId: string,
  choice: {
    current?: { choice: MerchantChoiceSelection };
    next?: { choice: MerchantChoiceSelection };
  }
): Promise<void> {
  return choiceAPI.put(`/${merchantId}/choices`, {
    offerBackfill: {
      ...choice,
    },
  });
}
