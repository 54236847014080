import { mcb } from 'mcb';

export class HttpException {
  status: number;
  statusText: string;
  errors: mcb.http.IError[];

  constructor(response: ng.IHttpPromiseCallbackArg<mcb.http.IError[]>) {
    this.status = response.status;
    this.statusText = response.statusText;
    this.errors = response.data;
  }
}
