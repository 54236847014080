import { Address } from 'Api/models/Address';
import { Endpoint } from '../../services/endpoint';
import {
  fetchReturnAddress,
  changeReturnAddress,
} from 'Api/endpoints/rights-of-withdrawal/row.endpoint';

export class RightsOfWithdrawalEndpoint extends Endpoint {
  protected resourceType = 'RightOfWithdrawal';

  fetchReturnAddress(merchantId: string): Promise<Address> {
    return fetchReturnAddress(merchantId);
  }

  changeReturnAddress(merchantId: string, address: Address) {
    return changeReturnAddress(merchantId, address);
  }
}
