import axios, { AxiosPromise } from 'axios';
import { pipe } from 'ramda';
import { Address } from 'Api/models/Address';
import ErrorHandler from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';

const rowApi = axios.create({ baseURL: 'api/row/merchants' });
const rowPdfApi = axios.create({ baseURL: 'api/row/merchants' });

rowApi.interceptors.request.use(decamelizeRequestHandler);
rowApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

rowPdfApi.interceptors.request.use(decamelizeRequestHandler);
rowPdfApi.interceptors.response.use(({ data }) => data, ErrorHandler);

/**
 * 404 is an expected result if there is no return address specified yet.
 * We need to handle it and treat it NOT as an error
 *
 * @param businessPartnerId Merchant Id
 */
export function fetchReturnAddress(businessPartnerId: string): Promise<any> {
  return rowApi
    .get(`${businessPartnerId}/return-address`)
    .catch((err) =>
      err.response && err.response.status === 404
        ? Promise.resolve(null)
        : Promise.reject(err)
    );
}

export function changeReturnAddress(
  businessPartnerId: string,
  address: Address
): AxiosPromise<Address> {
  return rowApi.put(`${businessPartnerId}/return-address`, address);
}

/**
 * Fetch ROW as HTML text
 *
 * @param businessPartnerId
 * @param salesChannelId
 * @param locale
 * @param isDraft
 * @param fulfillmentType
 */
export function fetchRow(
  businessPartnerId: string,
  salesChannelId: string,
  locale: string,
  isDraft: boolean,
  fulfillmentType: string
): AxiosPromise<string> {
  let url = `${businessPartnerId}/right-of-withdrawal/${salesChannelId}`;

  url += isDraft ? '/draft' : '';

  return (
    rowApi
      .get(url, { params: { fulfillmentType, locale } })
      // @ts-ignore
      .then((data) => data.rightOfWithdrawal)
  );
}

/**
 * Fetch ROW and download it as a pdf file
 *
 * @param businessPartnerId
 * @param salesChannelId
 * @param locale
 * @param isDraft
 * @param fulfillmentType
 */
export function fetchRowPdf(
  businessPartnerId: string,
  salesChannelId: string,
  locale: string,
  isDraft: boolean,
  fulfillmentType: string
): Promise<any> {
  let url = `${businessPartnerId}/right-of-withdrawal/${salesChannelId}`;

  url += isDraft ? '/draft' : '';

  return rowPdfApi
    .get(url, {
      params: { fulfillmentType, locale },
      headers: { Accept: 'application/pdf' },
      responseType: 'arraybuffer',
    })
    .then((data) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(
        // @ts-ignore
        new Blob([data], { type: 'application/pdf' })
      );
      link.setAttribute('download', `ROW-${locale}.pdf`);

      document.body.appendChild(link);
      link.click();
    });
}
