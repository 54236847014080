export const PROVIDER_ANATWINE = 1;
export const PROVIDER_CHANNEL_ADVISOR = 2;
const PROVIDER_EFULFILLMENT = 3;
export const PROVIDER_FORTUNEGLOBE = 4;
const PROVIDER_INTERNETSTORES = 5;
const PROVIDER_LENGOW = 6;
export const PROVIDER_NETEVEN = 7;
export const PROVIDER_NOVOMIND = 8;
const PROVIDER_PLENTY_MARKETS = 9;
const PROVIDER_PROSTEPS = 10;
export const PROVIDER_TRADEBYTE = 11;
const PROVIDER_SELLER_CENTER = 12;
const PROVIDER_SC_F2C = 13;
const PROVIDER_OMS = 14;
const PROVIDER_B2C_DIRECT = 15;
const PROVIDER_MANGO = 16;

export const PROVIDERS = [
  { id: PROVIDER_ANATWINE, name: 'Anatwine' },
  { id: PROVIDER_CHANNEL_ADVISOR, name: 'Channel Advisor' },
  { id: PROVIDER_EFULFILLMENT, name: 'eFulfillment' },
  { id: PROVIDER_FORTUNEGLOBE, name: 'Fortuneglobe' },
  { id: PROVIDER_INTERNETSTORES, name: 'Internetstores' },
  { id: PROVIDER_LENGOW, name: 'Lengow' },
  { id: PROVIDER_NETEVEN, name: 'Neteven' },
  { id: PROVIDER_NOVOMIND, name: 'Novomind' },
  { id: PROVIDER_PLENTY_MARKETS, name: 'Plenty Markets' },
  { id: PROVIDER_PROSTEPS, name: 'Prosteps' },
  { id: PROVIDER_TRADEBYTE, name: 'TradeByte' },
  { id: PROVIDER_SELLER_CENTER, name: 'Seller Center' },
  { id: PROVIDER_SC_F2C, name: 'SC-F2C' },
  { id: PROVIDER_OMS, name: 'OMS' },
  { id: PROVIDER_B2C_DIRECT, name: 'B2C-Direct' },
  { id: PROVIDER_MANGO, name: 'Mango' },
];
