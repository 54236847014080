import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VFlex,
    { staticClass: "details-container", attrs: { "d-flex": "" } },
    [
      _c(
        "div",
        [
          _c("div", { staticClass: "display-1 secondary--text mb-6" }, [
            _vm._v("Merchant Account Details"),
          ]),
          _c("mp-labeled-text", {
            attrs: {
              label: "Merchant Account name",
              value: _vm.merchantAccount.name,
            },
          }),
          _c(VDivider, { staticClass: "mt-3", attrs: { light: "" } }),
          _c("div", { staticStyle: { width: "688px" } }, [
            _c("div", { staticClass: "display-1 secondary--text mt-8 mb-6" }, [
              _vm._v("Legal Entities"),
            ]),
            _c(
              "div",
              { staticClass: "d-flex flex-wrap justify-space-between" },
              _vm._l(
                _vm.merchantAccount.legalEntities,
                function (legalEntity, index) {
                  return _c("LegalEntity", {
                    key: legalEntity.id,
                    staticStyle: { width: "320px" },
                    attrs: {
                      "show-divider":
                        index + 2 < _vm.merchantAccount.legalEntities.length,
                      legalEntity: legalEntity,
                    },
                  })
                }
              ),
              1
            ),
          ]),
          _c(VDivider, { staticClass: "mt-6 mb-8", attrs: { light: "" } }),
          _c(
            "div",
            [
              _c("div", { staticClass: "display-1 secondary--text mb-6" }, [
                _vm._v("Primary Contact Details"),
              ]),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { attrs: { cols: "6" } },
                    [
                      _c("mp-labeled-text", {
                        attrs: {
                          label: "First Name",
                          value: _vm.merchantAccount.firstName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { attrs: { cols: "6" } },
                    [
                      _c("mp-labeled-text", {
                        attrs: {
                          label: "Last Name",
                          value: _vm.merchantAccount.lastName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    [
                      _c("mp-labeled-text", {
                        attrs: {
                          label: "Email",
                          value: _vm.merchantAccount.email,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.inviteHidden
                ? _c(VDivider, { staticClass: "mt-5", attrs: { light: "" } })
                : _vm._e(),
              !_vm.inviteHidden
                ? _c(
                    VFlex,
                    {
                      staticStyle: { "justify-content": "flex-end" },
                      attrs: { "d-flex": "" },
                    },
                    [
                      _c(
                        VBtn,
                        {
                          ref: "sendZdirectInviteButton",
                          staticClass: "mt-6",
                          attrs: {
                            color: "primary",
                            depressed: "",
                            disabled:
                              _vm.isInvitationSent ||
                              !_vm.isInvitationRequirementsCompleted,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("send-zdirect-invite")
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.isInvitationSent
                                ? "Invite already sent"
                                : "Send zDirect invite"
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VBtn,
        {
          ref: "editDetailsButton",
          staticClass: "ml-6",
          attrs: { outlined: "", color: "primary" },
          on: {
            click: function ($event) {
              return _vm.$emit("edit-details")
            },
          },
        },
        [
          _c(VIcon, { staticClass: "mr-2", attrs: { small: "" } }, [
            _vm._v("mdi-square-edit-outline"),
          ]),
          _vm._v("Edit details"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }