import { mcb } from 'mcb';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import { Contract, Partner } from 'Api/models/Contract';
import { ConfigProviderFlagsT } from 'Api/models/ConfigPartnerProviderDefaults';
import template from './configuration.html';
import { Store } from 'redux';
import { FlowState } from 'Merchant/common/state/flow.state';
import { Configuration } from 'Api/models/Configuration';
import { ConfigurationActions } from 'Merchant/common/state/actions/configuration.actions';
import { ConfigurationService } from 'Merchant/common/services/configuration.service';
import permissionsService from 'Common/services/permissions.service';
import { ApiService } from 'Api/services/api.service';
import { MCP_INTEGRATION_TYPE_LABELS } from 'Common/constants/misc.constant';
import { MCP_FF_TYPES } from 'Common/constants/misc.constant';
import { PROVIDERS } from 'Merchant/merchant.constant';
import {
  getConfigFieldsVisibility,
  getConfigFieldsVisibilityFromConfig,
  ConfigFieldsVisibilityT,
  getConfigWithNullifiedInvisibleFields,
} from 'Merchant/contract/configuration.helper';

const OUTDATED_CONFIGURATION_ERROR = 'OUTDATED_CONFIGURATION_ERROR';

export const CONFIGURATION_INITIAL_STATE = {
  articleConfig: {},
  orderConfig: { cashOnDelivery: false },
} as Configuration;

export class ContractConfigurationComponent implements ng.IComponentOptions {
  static Factory() {
    return new ContractConfigurationComponent();
  }

  bindings: { [binding: string]: string } = {
    merchant: '<',
    contract: '<', // Contract is always there defined
  };
  controller = ContractConfigurationController;
  controllerAs = 'ctrl';
  template: string = template;
}

class ContractConfigurationController implements angular.IComponentController {
  static $inject = [
    '$q',
    'mcpAdminApi',
    'mcbToast',
    '$ngRedux',
    'mcpAdminConfigurationActions',
    'mcpAdminConfigurationService',
  ];

  // From bindings
  merchant: MerchantProfile;
  contract: Contract;

  contractConfig: Contract;
  partner: Partner;
  integrationType: 'DIRECT' | 'PARTNER_PROVIDER';
  partnerProviderId: number | undefined;
  configuration: Configuration;
  fieldsVisibility: ConfigFieldsVisibilityT = null;
  flagsPromise: ng.IPromise<ConfigProviderFlagsT> | null;
  emptyConfig: Configuration;
  isConfigurationLoading: boolean;
  isZfs: boolean;
  providers: any[] = PROVIDERS;

  constructor(
    private q: ng.IQService,
    private api: ApiService,
    private toast: mcb.IToast,
    private store: Store<FlowState>,
    private configurationActions: ConfigurationActions,
    private configurationService: ConfigurationService
  ) {
    this.onTabChange = this.onTabChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  $onInit(): void {
    this.configuration = angular.copy(CONFIGURATION_INITIAL_STATE);
    this.emptyConfig = angular.copy(CONFIGURATION_INITIAL_STATE);
    this.partner = this.contract.partners[0];
    this.integrationType = this.contract.integrationType;
    this.partnerProviderId = this.contract.partnerProviderId;
    this.isConfigurationLoading = false;
    this.fetchConfiguration(this.partner.$id);
    this.isZfs = this.contract.fulfillmentType === MCP_FF_TYPES.ZFS;

    // If contract is launched, then flags from the configuration should be used
    // otherwise Provider specific flags defaults should be fetched and used
    if (
      this.contract.launchStatus !== 'LAUNCH_DONE' &&
      this.partnerProviderId
    ) {
      // get Config Fields Flags defaults for the selected Provider
      this.fetchProviderFlags(this.partnerProviderId);
    }
  }

  $onChanges(changes): void {
    if (changes.contract) {
      const { sendDummyMail } = this.contract;
      this.contractConfig = { sendDummyMail };
    }
  }

  getProviderName(): string {
    if (!this.partnerProviderId) {
      // in case partner provider has not been specified yet
      return 'Not defined';
    }

    return this.providers.find(
      (provider) => provider.id === this.partnerProviderId
    ).name;
  }

  onTabChange(partner: Partner): void {
    this.configuration = angular.copy(CONFIGURATION_INITIAL_STATE);
    this.partner = partner;
    this.fetchConfiguration(this.partner.$id);
  }

  onProviderChange(): void {
    this.fetchProviderFlags(this.partnerProviderId);
  }

  /**
   * Save configuration settings
   *
   * The method supports both use cases - Direct and Non-Direct Integration
   *
   * Assumptions:
   * - Configuration data is valid (form validation is done)
   * @param contractConfig  part of configuration for Contract object
   * @param configuration   part of configuration for Configuration object
   */
  onSave(contractConfig: Contract, configuration: Configuration = null): void {
    const { toast, store, contract, api, configurationService } = this;

    const isDirect = this.integrationType === 'DIRECT';

    this.isConfigurationLoading = true;

    api.contract
      .updateContract(contract.$id, {
        integrationType: this.integrationType,
        partnerProviderId: isDirect
          ? undefined
          : Number(this.partnerProviderId),
        ...contractConfig,
      } as Contract)
      .then(() => {
        // There is no Configuration for Direct Integration case
        if (configuration) {
          // Make sure we send to Backend only fields that are relevant for the selected partner provider
          // and other fields nullified so that Backend clean them up
          const cleanedUpConfig = getConfigWithNullifiedInvisibleFields(
            configuration,
            this.fieldsVisibility
          );
          return configurationService.updateConfiguration(cleanedUpConfig);
        }
      })
      // @ts-ignore
      .then(() => store.dispatch(this.configurationActions.updateState()))
      .then(() => {
        const { name: retailer } = contract.retailer;
        const { countryName } = contract.salesChannel;
        toast.success(
          `Configuration for ${retailer} - ${countryName} is saved`
        );
      })
      .catch(() => toast.error('Something went wrong!'))
      .finally(() => (this.isConfigurationLoading = false));
  }

  isReadOnlyView(): boolean {
    return (
      permissionsService.isReadOnly() ||
      this.contract.launchStatus === 'LAUNCH_DONE'
    );
  }

  getIntegrationTypeLabel(value): string {
    if (value === undefined) {
      return 'Not defined';
    }

    return MCP_INTEGRATION_TYPE_LABELS[value];
  }

  private fetchProviderFlags(providerId: number): void {
    this.isConfigurationLoading = true;

    const flagsPromise = (this.flagsPromise = this.configurationService
      .fetchProviderDefaults(this.partnerProviderId)
      .then((data) => {
        // Use received flags only if there is no other request initiated
        if (flagsPromise == this.flagsPromise) {
          this.fieldsVisibility = getConfigFieldsVisibility(data);
        }
        return data;
      })
      .catch((_err) => {
        this.toast.error(
          'Something went wrong while loading urls flags for the selected partner provider'
        );
        return null;
      })
      .finally(() => {
        // Remove loading indicator only if there is no other request initiated
        if (flagsPromise === this.flagsPromise) {
          this.isConfigurationLoading = false;
        }
      }));
  }

  private fetchConfiguration(partnerId: string): void {
    this.isConfigurationLoading = true;

    this.configurationService
      .fetchConfigurationByPartnerId(partnerId)
      .then((configuration: Configuration) => {
        // Check if the configuration match currently selected partnerId
        // (the user may move away to other partner or even other contract)
        if (configuration.$id !== this.partner.$id) {
          return this.q.reject(OUTDATED_CONFIGURATION_ERROR);
        }

        this.configuration = configuration;

        // Update FieldsVisibility with data from configuration if contract is launched
        if (this.contract.launchStatus === 'LAUNCH_DONE') {
          this.fieldsVisibility =
            getConfigFieldsVisibilityFromConfig(configuration);
        }

        this.isConfigurationLoading = false;

        return configuration;
      })
      .catch((err) => {
        if (err === OUTDATED_CONFIGURATION_ERROR) {
          return null;
        }

        this.isConfigurationLoading = false;

        this.toast.error('Something went wrong!');
      });
  }
}
