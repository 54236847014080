import { mcb } from 'mcb';
import template from './company-metadata-group-change-modal.html';
import { MerchantGroup } from 'Api/models/MerchantGroup';

export class CompanyMetadataGroupChangeModalComponent
  implements ng.IComponentOptions
{
  static Factory() {
    return new CompanyMetadataGroupChangeModalComponent();
  }

  bindings: { [binding: string]: string } = {
    onSubmit: '<',
    onClose: '<',
    group: '<',
    groups: '<',
  };

  controller = MetadataGroupChangeController;
  controllerAs = 'ctrl';
  template: string = template;
}

export class MetadataGroupChangeController {
  static $inject = ['$q', 'mcbToast'];

  newGroup: MerchantGroup;
  onClose: Function;
  isSubmitInProgress: boolean = false;
  private groups: MerchantGroup[];
  private group: MerchantGroup;
  private onSubmit: Function;

  constructor(private $q: ng.IQService, private toast: mcb.IToast) {}

  $onInit(): void {
    this.newGroup = { ...this.group };
  }

  submit(): void {
    if (this.isSubmitDisabled()) {
      return;
    }

    const name = this.newGroup.name.trim();
    const newGroupTrimmed: MerchantGroup = { ...this.newGroup, ...{ name } };

    if (this.isExists(name)) {
      this.toast.warning(
        `Group "<strong>${newGroupTrimmed.name}</strong>" already exists`
      );
      return;
    }

    this.isSubmitInProgress = true;

    this.onSubmit(newGroupTrimmed).catch(() => {
      this.isSubmitInProgress = false;
    });
  }

  isSubmitDisabled(): boolean {
    const oldName = this.group.name;
    const name = this.newGroup.name;
    return this.isSubmitInProgress || !name.trim() || name === oldName;
  }

  getSubmitBtnName(): string {
    return this.isSubmitInProgress ? 'Submitting...' : 'Submit';
  }

  /**
   * Check if group with such a name already exists
   */
  private isExists(name): boolean {
    const lcName = name.toLowerCase();

    return this.groups.some((group) => group.name.toLowerCase() === lcName);
  }
}
