import { BankAccount } from '../../models/BankAccount';
import { MerchantProfile } from '../../models/MerchantProfile';
import { Endpoint } from '../../services/endpoint';
import { RequestConfig } from '../../services/http.service';

export class MerchantBankAccountEndpoint extends Endpoint {
  protected resourceType = 'BankAccount';

  saveMerchantBankAccount(
    merchant: MerchantProfile,
    bankAccount: BankAccount
  ): ng.IPromise<void> {
    let url = this.endpointsService.getBankAccountUrlTemplate(merchant.$id);
    let config: RequestConfig = {
      method: 'POST',
      url: url,
      data: bankAccount,
      resourceType: 'BankAccount',
    };
    return this.http.request(config);
  }

  updateMerchantBankAccount(
    merchant: MerchantProfile,
    bankAccount: BankAccount
  ): ng.IPromise<void> {
    let url = this.endpointsService.getBankAccountUrlTemplate(merchant.$id);
    let config: RequestConfig = {
      method: 'PATCH',
      url: url,
      data: bankAccount,
      resourceType: 'BankAccount',
    };
    return this.http.request(config);
  }

  putMerchantBankAccount(
    merchant: MerchantProfile,
    bankAccount: BankAccount
  ): ng.IPromise<void> {
    if (bankAccount.$id) {
      return this.updateMerchantBankAccount(merchant, bankAccount);
    } else {
      return this.saveMerchantBankAccount(merchant, bankAccount);
    }
  }

  fetchMerchantBankAccount(
    merchant: MerchantProfile
  ): ng.IPromise<BankAccount> {
    let url = this.endpointsService.getBankAccountUrlTemplate(merchant.$id);
    let config: RequestConfig = { method: 'GET', url: url };
    return this.http.request(config);
  }
}
