import axios from 'axios';
import { pipe } from 'ramda';
import ErrorHandler from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import { FulfillmentTypeT } from 'Api/models/MerchantProfile';

interface StockResponseT {
  countryCode: string;
  fulfillmentType: FulfillmentTypeT;
  salesChannelId: string;
  stockId: string;
  stockLocationId: string;
  stockLocationName: string;
  stockName: string;
}

export interface SteeringResponseT {
  activeConfigurations: StockResponseT[];
  availableConfigurations: StockResponseT[];
  merchantId: string;
}

export interface StockUpdateRequestT {
  businessReason: string;
  onboardStockIds: string[];
  offboardStockIds: string[];
}

const stocksApi = axios.create({
  baseURL: '/api/merchant-outbound-steering/merchants',
});

stocksApi.interceptors.request.use(decamelizeRequestHandler);
stocksApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

export function fetchActiveStockLocations(
  merchantId: string,
  salesChannelId: string,
  fulfillmentType: string
): Promise<SteeringResponseT> {
  return stocksApi.get(
    `${merchantId}/sales-channels/${salesChannelId}/fulfillment-types/${fulfillmentType}`
  );
}

export function updateAllStockLocations(
  merchantId: string,
  salesChannelId: string,
  fulfillmentType: string,
  data: StockUpdateRequestT
): Promise<void> {
  return stocksApi.post(
    `${merchantId}/sales-channels/${salesChannelId}/fulfillment-types/${fulfillmentType}/outbound-steering-commands`,
    data
  );
}
