export default [
  { name: 'mcp.admin.merchants', path: '/' },
  { name: 'mcp.admin.register', path: '/register' },
  {
    name: 'mcp.admin.merchant-account-create',
    path: '/create-merchant-account',
  },
  { name: 'mcp.admin.merchant-accounts', path: '/merchant-accounts' },
  {
    name: 'mcp.admin.merchant-account-details',
    path: '/merchant-accounts/:merchantAccountId',
  },
  {
    name: 'mcp.admin.merchant-account-edit',
    path: '/merchant-accounts/:merchantAccountId/edit',
  },
  {
    name: 'mcp.admin.legal-entity-details',
    path: '/legal-entities/:legalEntityId',
  },
  {
    name: 'mcp.admin.merchant.general.company-details',
    path: '/merchants/:merchantId',
  },
  {
    name: 'mcp.admin.merchant.general.partner-contract-details',
    path: '/merchants/:merchantId/partner-contract-details',
  },
  {
    name: 'mcp.admin.merchant.general.business-details',
    path: '/merchants/:merchantId/business-details',
  },
  {
    name: 'mcp.admin.merchant.general.compliance-details',
    path: '/merchants/:merchantId/compliance-details',
  },
  {
    name: 'mcp.admin.merchant.general.company-details-approval',
    path: '/merchants/:merchantId/approve',
  },
  {
    name: 'mcp.admin.merchant.general.partner-contract-details-approval',
    path: '/merchants/:merchantId/partner-contract-details-approval',
  },
  {
    name: 'mcp.admin.merchant.general.business-details-approval',
    path: '/merchants/:merchantId/business-details-approval',
  },
  {
    name: 'mcp.admin.merchant.general.compliance-details-approval',
    path: '/merchants/:merchantId/compliance-details-approval',
  },
  {
    name: 'mcp.admin.merchant.general.metadata',
    path: '/merchants/:merchantId/metadata',
  },
  {
    name: 'mcp.admin.merchant.general.metadata-readonly',
    path: '/merchants/:merchantId/metadata-readonly',
  },
  {
    name: 'mcp.admin.merchant.general.commission',
    path: '/merchants/:merchantId/commission',
  },
  {
    name: 'mcp.admin.merchant.general.masterdata',
    path: '/merchants/:merchantId/masterdata',
  },
  {
    name: 'mcp.admin.merchant.general.sales-channels',
    path: '/merchants/:merchantId/sales-channels',
  },
  {
    name: 'mcp.admin.merchant.sales-channel.overview',
    path: '/merchants/:merchantId/sales-channels/:salesChannelId/overview',
  },
  {
    name: 'mcp.admin.merchant.contract.overview',
    path: '/merchants/:merchantId/contracts/:contractId/overview',
  },
  {
    name: 'mcp.admin.merchant.contract.company-data',
    path: '/merchants/:merchantId/contracts/:contractId/contract-company-details',
  },
  {
    name: 'mcp.admin.merchant.contract.carrier',
    path: '/merchants/:merchantId/contracts/:contractId/carrier-details',
  },
  {
    name: 'mcp.admin.merchant.contract.configuration',
    path: '/merchants/:merchantId/contracts/:contractId/configuration',
  },
  {
    name: 'mcp.admin.contract.row',
    path: '/merchants/:merchantId/contracts/:contractId/row',
  },
  {
    name: 'mcp.admin.merchant.contract.approval',
    path: '/merchants/:merchantId/contracts/:contractId/contract-approval',
  },
];
