import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "ml-8" },
        [_c("VatSectionTitle", { attrs: { title: _vm.title } })],
        1
      ),
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "py-3",
            attrs: { "data-testid": `${_vm.title}-${item.id}` },
          },
          [
            _c("VatReviewSection", {
              attrs: {
                "is-sub-section": "",
                title: item.title,
                address: item.address,
                vatDetails: item.vatDetails,
                status: item.approvalStatus,
                "rejection-reason": item.rejectionReason,
                "selected-status": item.selectedStatus,
                "enable-review": _vm.enableReview,
                "compliance-status": _vm.complianceStatus,
                "lrd-details": item.lrdDetails,
              },
              on: {
                "on-rejection-reason-change": function ($event) {
                  return _vm.$emit("on-rejection-reason-change", {
                    value: $event,
                    id: item.id,
                  })
                },
                "on-status-change": function ($event) {
                  return _vm.$emit("on-status-change", {
                    value: $event,
                    id: item.id,
                  })
                },
              },
            }),
            _vm.list.length - 1 !== index ? _c(VDivider) : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }