interface MerchantComplianceRequirementItemT {
  required: boolean;
  section: MerchantComplianceRequirementT;
}

export interface MerchantComplianceRequirementResponseT {
  items: MerchantComplianceRequirementItemT[];
}

export enum MerchantComplianceRequirementT {
  FIXED_ESTABLISHMENT_EU = 'FIXED_ESTABLISHMENT_EU',
  FIXED_ESTABLISHMENT_UK = 'FIXED_ESTABLISHMENT_UK',
  OSS = 'OSS',
  ZFS_VAT = 'ZFS_VAT',
  OUTBOUND_WAREHOUSES = 'OUTBOUND_WAREHOUSES',
}

export enum SalesChannelComplianceRequirementT {
  VAT_ID = 'VAT_ID',
}
