<template lang="pug">
v-row
  v-col.py-0(md="3")
    Search(
      label="Search for merchants"
      ref="search"
      data-testid="search-merchants-input"
      :disabled="disabled"
      :default-value="defaultSearch"
      @input="search"
    )
  v-col.py-0(md="2")
    FilterbarSelect(
      label="Type"
      data-testid="filter-by-type"
      :items="typeOptions"
      :value="defaultType"
      :disabled="disabled"
      ref="type"
      @change="$event => $emit('type-change', $event)"
    )
  v-col.py-0(md="2")
    FilterbarSelect(
      label="Factoring"
      data-testid="filter-by-factoring"
      :items="factoringOptions"
      :value="defaultFactoring"
      :disabled="disabled"
      ref="factoring"
      @change="$event => $emit('factoring-change', $event)"
    )
  v-col.py-0(auto)
    FilterbarSelect(
      label="Status"
      data-testid="filter-by-status"
      :items="statusOptions"
      :value="defaultStatus"
      :disabled="disabled"
      ref="status"
      @change="$event => $emit('status-change', $event)"
    )
  v-col.py-0(auto)
    FilterbarSelect(
      label="Sort By"
      data-testid="sort-by-input"
      :items="sortOptions"
      :value="defaultSort"
      :disabled="disabled"
      ref="sort"
      @change="$event => $emit('sort-change', $event)"
    )
</template>

<script lang="ts">
import Vue from 'vue';
import FilterbarSelect from './select/select.vue';
import Search from 'Common/components/search/search.vue';
import { MCP_MERCHANT_TYPE_OPTIONS } from 'Common/constants/merchant-types.constant';
import { MCP_MERCHANT_STATUS_OPTIONS } from 'Common/constants/merchant-statuses.constant';
import { MCP_MERCHANT_SORT_OPTIONS } from 'Common/constants/merchant-sort.constant';
import { MCP_FACTORING_TYPE_OPTIONS } from 'Common/constants/factoring.constant';

export default Vue.extend({
  components: { Search, FilterbarSelect },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    defaultSearch: {
      type: String,
      required: true,
    },
    defaultType: {
      type: String,
      required: true,
    },
    defaultStatus: {
      type: String,
      required: true,
    },
    defaultSort: {
      type: String,
      required: true,
    },
    defaultFactoring: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      factoringOptions: MCP_FACTORING_TYPE_OPTIONS,
      typeOptions: MCP_MERCHANT_TYPE_OPTIONS,
      statusOptions: MCP_MERCHANT_STATUS_OPTIONS,
      sortOptions: MCP_MERCHANT_SORT_OPTIONS,
    };
  },
  methods: {
    search(event: string): void {
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      if (event && event.length === 36 && uuidRegex.test(event)) {
        this.$emit('switch-merchant', event);
      } else {
        this.$emit('search-input', event);
      }
    },
  },
});
</script>
