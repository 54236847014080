import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    [
      _c(
        "Appbar",
        { attrs: { "hide-tabs": "" } },
        [
          !_vm.loading && !_vm.hasError
            ? _c(
                "BackLink",
                {
                  staticClass: "back-link",
                  attrs: { href: "/#/merchant-accounts" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.merchantAccount
                        ? _vm.merchantAccount.name
                        : "Merchant Accounts"
                    )
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        VMain,
        { staticStyle: { "background-color": "backgroundColor" } },
        [
          _c(
            VSnackbar,
            {
              attrs: { timeout: -1, bottom: "", color: "reject" },
              model: {
                value: _vm.hasError,
                callback: function ($$v) {
                  _vm.hasError = $$v
                },
                expression: "hasError",
              },
            },
            [
              _vm._v(_vm._s(this.errorMessage)),
              _c(
                VBtn,
                {
                  staticClass: "ml-1",
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.fetchData()
                    },
                  },
                },
                [_vm._v("Retry")]
              ),
              _c(
                "a",
                {
                  staticClass: "text-center",
                  attrs: { href: "/#/merchant-accounts" },
                },
                [
                  _c(VBtn, { staticClass: "ml-1", attrs: { text: "" } }, [
                    _vm._v("Back"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.loading ? _c(VContainer, [_c("PageLoader")], 1) : _vm._e(),
          !_vm.loading && !_vm.hasError
            ? _c(
                VFlex,
                { attrs: { "d-flex": "", "fill-height": "" } },
                [
                  _vm.merchantAccount
                    ? _c("SidePanel", {
                        attrs: {
                          merchantAccount: _vm.merchantAccount,
                          selectedLegalEntityId: _vm.legalEntityId,
                        },
                      })
                    : _vm._e(),
                  _c(VDivider, { attrs: { vertical: "" } }),
                  _c(
                    VContainer,
                    { staticClass: "px-6 pt-6" },
                    [
                      _vm.legalEntity
                        ? _c("LegalEntityDetails", {
                            attrs: { legalEntity: _vm.legalEntity },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }