/**
 * @ngdoc service
 * @name mcbResource
 *
 * @requires $resource
 * @requires mcbHttpTransformers
 */
let mcbResource = function mcbResource(
  $resource,
  mcbHttpTransformers,
  $$mcbResourceMergeTransformers
) {
  let DEFAULT_TIMEOUT = 10000;
  let DEFAULT_TRANSFORMERS = {
    response: [mcbHttpTransformers.fromJson, mcbHttpTransformers.camelize],
    request: [mcbHttpTransformers.decamelize, mcbHttpTransformers.toJson],
  };
  let DEFAULT_ACTIONS = {
    get: {
      method: 'GET',
    },
    save: {
      method: 'POST',
    },
    update: {
      method: 'PATCH',
    },
    put: {
      method: 'PUT',
    },
    query: {
      method: 'GET',
      isArray: true,
    },
    remove: {
      method: 'DELETE',
    },
    delete: {
      method: 'DELETE',
    },
    patch: {
      method: 'PATCH',
    },
  };

  /**
   * add/override action methods:
   *
   * - add static methods they are like ngResource methods but they returns a promise, not a reference (no $scope magic)
   * - override instance methods: they are like ngResource without success and error callbacks and they don't mutate the object (no $scope magic)
   *
   * @param {Resource} Resource
   * @returns {Resource} Resource - The augmented resource
   *
   * @examples
   *
   *  ```
   *  var ViewModel = {};
   *  var PostResource = mcbResource('/posts/:postId', {postId: '@postId'});
   *
   *  //
   *  // ORIGINAL
   *  //
   *  var posts = PostResource.query({include: 'author'}) GET /posts?include=author
   *    .$promise
   *    .then(function (posts) {
   *     console.log(posts); // Array<Resource>
   *    });
   *
   *  console.log(posts) // Array<Resource>
   *
   *  //
   *  // NEW: with the new $ prefixed method
   *  //
   *  posts = PostResource.$query({include: 'author'}) // GET /posts?include=author
   *    .then(function (posts) {
   *      console.log(posts); Array<Resource>
   *    });
   *
   *  console.log(posts) // Promise
   *
   *  //
   *  // ORIGINAL instance behavior
   *  //
   *  var post = new PostResource({postId: 1});
   *
   *  // when the Promise will be resolved, post will be updated with the fetched version
   *  post.$get() // GET /posts/1
   *    .then(function (fetchedPost) {
   *      console.log(fetchedPost === post) // true ... is the same object
   *    });
   *
   *  //
   *  // NEW: $ methods overriden, to remove mutability and $scope magic
   *  //
   *  post.$get()
   *    .then(function (fetchedPost) {
   *      console.log(fetchedPost === post) // false ... is not the same object
   *    });
   *
   *  ```
   */
  function removeNgResourceMagic(Resource) {
    Object.keys(Resource.DEFAULTS.actions).forEach(function (key) {
      let originalPrototypeMethod = Resource.prototype['$' + key];
      let originalStaticMethod = Resource[key];
      let action = Resource.DEFAULTS.actions[key];

      // with HTTP GET like actions
      // action methods on the class object or instance object can be invoked with the following signature:
      // Resource.$action([params])
      // instance.$action([params])
      if (['get', 'delete'].indexOf(action.method.toLowerCase()) > -1) {
        Resource['$' + key] = function (params) {
          return originalStaticMethod.apply(this, [params]).$promise;
        };
        Resource.prototype['$' + key] = function (params) {
          let copy = angular.copy(this);
          return originalPrototypeMethod.apply(copy, [params]);
        };
      } else {
        // with HTTP POST like actions
        // action methods on the class object or instance object can be invoked with the following signature:
        // Resource.$action([params], [data])
        // instance.$action([params], [data])
        Resource['$' + key] = function (params, data) {
          return originalStaticMethod.apply(this, [params, data]).$promise;
        };
        Resource.prototype['$' + key] = function (params, data) {
          let copy = angular.copy(this);
          return originalPrototypeMethod.apply(copy, [params, data]);
        };
      }
    });
    return Resource;
  }

  function ResourceBuilder() {
    this._defaults = {
      headers: {},
      timeout: DEFAULT_TIMEOUT,
      interceptor: null,
      transformResponse: angular.copy(DEFAULT_TRANSFORMERS.response),
      transformRequest: angular.copy(DEFAULT_TRANSFORMERS.request),
    };

    this._customActions = {};
  }

  /**
   * Add default response transformers for every action
   * @param {Array<Function>} transformers
   * @returns {ResourceBuilder}
   */
  ResourceBuilder.prototype.transformResponse = function (transformers) {
    this._defaults.transformResponse =
      this._defaults.transformResponse.concat(transformers);
    return this;
  };

  /**
   * Add default request transformers for every action
   * @param {Array<Function>} transformers
   * @returns {ResourceBuilder}
   */
  ResourceBuilder.prototype.transformRequest = function (transformers) {
    Array.prototype.unshift.apply(
      this._defaults.transformRequest,
      transformers
    );
    return this;
  };

  /**
   * Set default interceptor for every action
   * @param {Object} interceptor
   * @returns {ResourceBuilder}
   */
  ResourceBuilder.prototype.interceptor = function (interceptor) {
    this._defaults.interceptor = interceptor;
    return this;
  };

  /**
   * Add default headers for every action
   * @param {Object} headers
   * @returns {ResourceBuilder}
   */
  ResourceBuilder.prototype.headers = function (headers) {
    this._defaults.headers = angular.extend(
      {},
      this._defaults.headers,
      headers
    );
    return this;
  };

  /**
   * Set default timeout for every action
   * @param {Number} timeout
   * @returns {ResourceBuilder}
   */
  ResourceBuilder.prototype.timeout = function (timeout) {
    this._defaults.timeout = timeout;
    return this;
  };

  /**
   * Set default resource decorator
   * @param {function} decorator  - a function to decorate a Resource "class"
   */
  ResourceBuilder.prototype.resourceDecorator = function (decorator) {
    if (!angular.isFunction(decorator)) {
      throw 'Resource decorator must be a function.';
    }

    this._defaults.resourceDecorator = decorator;
    return this;
  };

  /**
   * Add a custom action (will not inherit defaults)
   *
   * @param {string} name
   * @param {Object} config
   * @returns {ResourceBuilder}
   */
  ResourceBuilder.prototype.customAction = function (name, config) {
    this._customActions[name] = config;
    return this;
  };

  /**
   * Apply behavior
   *
   * @param behavior
   * @returns {ResourceBuilder}
   */
  ResourceBuilder.prototype.behavior = function (behavior) {
    this._defaults.behavior = behavior;

    if (behavior.transformResponse) {
      this.transformResponse(behavior.transformResponse);
    }

    if (behavior.transformRequest) {
      this.transformRequest(behavior.transformRequest);
    }

    if (behavior.interceptor) {
      this.interceptor(behavior.interceptor);
    }

    if (behavior.headers) {
      this.headers(behavior.headers);
    }

    if (behavior.timeout) {
      this.timeout(behavior.timeout);
    }

    if (behavior.resourceDecorator) {
      this.resourceDecorator(behavior.resourceDecorator);
    }

    return this;
  };

  /**
   * @param {String} url
   * @param {Object} params
   * @param {Object} actions
   * @param {Object} options
   * @returns {Resource} - ngResource
   */
  ResourceBuilder.prototype.create = function (url, params, actions, options) {
    let defaultActions = angular.copy(DEFAULT_ACTIONS);

    Object.keys(defaultActions).forEach(
      function (prop) {
        let _action = defaultActions[prop];
        $$mcbResourceMergeTransformers.request(
          _action,
          this._defaults.transformRequest
        );
        $$mcbResourceMergeTransformers.response(
          _action,
          this._defaults.transformResponse
        );
        _action.headers = this._defaults.headers;
        _action.timeout = this._defaults.timeout;
        if (this._defaults.interceptor) {
          _action.interceptor = this._defaults.interceptor;
        }
      }.bind(this)
    );

    let userActions = angular.copy(actions);
    if (angular.isObject(userActions)) {
      Object.keys(userActions).forEach(
        function (prop) {
          let action = userActions[prop];
          $$mcbResourceMergeTransformers.request(
            action,
            this._defaults.transformRequest
          );
          $$mcbResourceMergeTransformers.response(
            action,
            this._defaults.transformResponse
          );
          if (!action.interceptor && this._defaults.interceptor) {
            action.interceptor = this._defaults.interceptor;
          }
          if (!action.timeout) {
            action.timeout = this._defaults.timeout;
          }
          action.headers = angular.extend(
            {},
            this._defaults.headers,
            action.headers || {}
          );
        }.bind(this)
      );
    }

    let finalActions = angular.extend(
      {},
      defaultActions,
      userActions,
      this._customActions || {}
    );

    let Resource = $resource(url, params, finalActions, options);

    Resource.DEFAULTS = angular.copy(this._defaults);
    Resource.DEFAULTS.actions = angular.copy(finalActions);
    Resource.DEFAULTS.url = url;
    Resource.DEFAULTS.options = angular.copy(options);
    Resource.DEFAULTS.params = angular.copy(params);

    removeNgResourceMagic(Resource);

    // call resource decorator if exists
    if (this._defaults.resourceDecorator) {
      this._defaults.resourceDecorator(Resource);
    }

    return Resource;
  };

  let defaultBuilder = new ResourceBuilder();

  function mcbResourceFactory(url, params, actions, options) {
    return defaultBuilder.create(url, params, actions, options);
  }

  mcbResourceFactory.DEFAULTS = {
    TIMEOUT: DEFAULT_TIMEOUT,
    TRANSFORM_REQUEST: DEFAULT_TRANSFORMERS.request,
    TRANSFORM_RESPONSE: DEFAULT_TRANSFORMERS.response,
  };
  mcbResourceFactory.Builder = ResourceBuilder;

  return mcbResourceFactory;
};

mcbResource.$inject = [
  '$resource',
  'mcbHttpTransformers',
  '$$mcbResourceMergeTransformers',
];

export default mcbResource;
