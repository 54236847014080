import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(VAutocomplete, {
        attrs: {
          filled: "",
          "hide-no-data": "",
          "single-line": "",
          clearable: "",
          label: "Select zDirect Merchant Admin",
          hint: "Select zDirect Merchant Admin user",
          "persistent-hint": "",
          "item-value": "id",
          "return-object": "",
          autocomplete: "off",
          disabled: _vm.disabled,
          items: _vm.contacts,
          filter: _vm.filter,
          "menu-props": { closeOnContentClick: true },
        },
        on: { input: _vm.onContactSelect },
        scopedSlots: _vm._u(
          [
            {
              key: "item",
              fn: function ({ item }) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(item.firstName) +
                        " " +
                        _vm._s(item.lastName) +
                        " - " +
                        _vm._s(item.email)
                    ),
                  ]),
                ]
              },
            },
            {
              key: "selection",
              fn: function ({ item }) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(item.firstName) +
                        " " +
                        _vm._s(item.lastName) +
                        " - " +
                        _vm._s(item.email)
                    ),
                  ]),
                ]
              },
            },
            !_vm.isFormVisible
              ? {
                  key: "append-item",
                  fn: function () {
                    return [
                      _c(VDivider),
                      _c("div", { staticClass: "py-3 px-4" }, [
                        _c("span", { staticClass: "body-2 font-weight-bold" }, [
                          _vm._v("Correct contact not available?"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "body-2",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.showForm.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Add another contact")]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedContact,
          callback: function ($$v) {
            _vm.selectedContact = $$v
          },
          expression: "selectedContact",
        },
      }),
      _vm.isFormVisible
        ? _c("Form", {
            attrs: {
              disabled: _vm.disabled,
              "default-value": _vm.defaultValue,
            },
            on: { input: _vm.onContactChange },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }