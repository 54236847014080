import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { staticClass: "root", attrs: { id: "market-container" } },
    [
      _c(
        VContainer,
        [
          _c("div", { staticClass: "ml-3" }, [
            _vm.imagesPath
              ? _c("img", {
                  attrs: { src: _vm.imagesPath, width: "30", height: "17" },
                })
              : _vm._e(),
            _c("span", { staticClass: "text-h6 ml-2" }, [
              _vm._v(_vm._s(_vm.countryName)),
            ]),
          ]),
          _c(
            VTabs,
            {
              staticClass: "ml-n7",
              attrs: {
                "align-with-title": "",
                "background-color": "transparent",
              },
            },
            [
              _c(VTabsSlider, { attrs: { color: "primary" } }),
              _c(VTab, [_vm._v("Overview")]),
            ],
            1
          ),
          _vm.channel && _vm.merchant
            ? _c("SalesChannelOverview", {
                attrs: {
                  "merchant-id": _vm.merchant.$id,
                  "merchant-name": _vm.merchant.name,
                  "channel-id": _vm.channel.$id,
                  "country-name": _vm.channel.countryName,
                  "shipment-data": _vm.shipmentData,
                  "is-shipment-data-loading": _vm.isShipmentDataLoading,
                  "is-live": _vm.isLive,
                  "is-put-live-btn-displayed": !_vm.isLive,
                  "is-put-live-btn-enabled": _vm.canGoLive,
                  "is-take-offline-btn-displayed": _vm.isLive,
                  "is-both-contracts-online": _vm.isBothContractsOnline,
                  "is-zfs-channel": _vm.isPureZFSChannel,
                  "is-hybrid-channel": _vm.isPureHybridChannel,
                  "is-zfs-channel-launched": _vm.isZFSChannelLaunched,
                  "on-put-live-cb": _vm.onPutLiveCb,
                  "on-take-offline-cb": _vm.onTakeOfflineCb,
                  "on-shipment-change": _vm.updateShipment,
                  "request-go-online-confirmation":
                    _vm.launchedContracts.length > 1,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(VContainer, [
        _vm.isVatDataLoading
          ? _c(
              "div",
              [
                _c(VProgressCircular, {
                  attrs: { color: "secondaryDark", indeterminate: "" },
                }),
              ],
              1
            )
          : _c(
              "div",
              [
                _vm.vatData !== null
                  ? _c(
                      "div",
                      { staticClass: "pb-4" },
                      [
                        _c("VatReviewSection", {
                          attrs: {
                            title: "Vat ID details",
                            "vat-details": _vm.vatData,
                            status: _vm.vatData.approvalStatus,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.complianceRequirements !== null &&
                _vm.complianceRequirements.length !== 0 &&
                _vm.vatData === null
                  ? _c("NoDataSection", {
                      attrs: { title: _vm.titles.scVatDetails },
                    })
                  : _vm._e(),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }