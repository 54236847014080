import { VCard } from 'vuetify/lib/components/VCard';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "vat-review-section" }, [
    _c(
      "div",
      { staticClass: "d-flex justify-space-between align-center mb-4" },
      [
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c("VatStatusIcon", {
              attrs: {
                status: _vm.status,
                "manual-review-required": _vm.isManualReviewRequired,
              },
            }),
            _c("VatSectionTitle", {
              attrs: { title: _vm.title, "is-small": _vm.isSubSection },
            }),
          ],
          1
        ),
        _vm.isStatusSelectorVisible
          ? _c(
              "div",
              [
                _c("StatusSelector", {
                  attrs: {
                    "selected-status": _vm.selectedStatus,
                    "hide-rejection-option": _vm.hideRejectionOption,
                  },
                  on: {
                    "on-status-select": function ($event) {
                      return _vm.$emit("on-status-change", $event)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      { staticClass: "ml-8 pb-3" },
      [
        _vm._t("default", function () {
          return [
            _vm.vatGroupDetails
              ? _c("VatGroupDetails", {
                  attrs: { details: _vm.vatGroupDetails },
                })
              : _vm._e(),
            _vm.lrdDetails
              ? _c("LrdDetails", { attrs: { details: _vm.lrdDetails } })
              : _vm._e(),
            _vm.address
              ? _c("VatAddress", {
                  attrs: {
                    address: _vm.address,
                    showSubtext: _vm.showAddressSubtext,
                  },
                })
              : _vm._e(),
            _vm.vatDetails
              ? _c("VatIdDetails", {
                  attrs: {
                    details: _vm.vatDetails,
                    "label-prefix": _vm.vatDetailsLabelPrefix,
                  },
                })
              : _vm._e(),
          ]
        }),
        _vm.isDataRejected
          ? _c(
              VCard,
              { staticClass: "px-4 pt-4", attrs: { outlined: "" } },
              [
                _c(
                  "div",
                  { staticClass: "mb-4 text-caption font-weight-bold" },
                  [
                    _vm._v(
                      "Please provide exact reasons for your rejection and the next steps for partners:"
                    ),
                  ]
                ),
                _c(VTextarea, {
                  attrs: {
                    "no-resize": "",
                    outlined: "",
                    "persistent-hint": "",
                    height: "80",
                    placeholder: "Write your reasons and next steps in detail",
                    value: _vm.rejectionReason,
                    hint:
                      "Please specify at least " + _vm.minWordsCount + " words",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$emit("on-rejection-reason-change", $event)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }