var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Toolbar", {
    attrs: {
      title: _vm.title,
      hasNewMerchantBtn: _vm.hasNewMerchantBtn,
      hasNewMerchantAccountBtn: _vm.hasNewMerchantAccountBtn,
    },
    on: {
      "add-merchant": _vm.addMerchant,
      "add-merchant-account": _vm.addMerchantAccount,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }