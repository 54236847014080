<template lang="pug">
  div.mcp-admin-merchant-details-section(:class="{'is-loading':isLoading}")
    div.mcp-admin-merchant-details-section__header
      | Return Address
      ConfirmDataChange(
        v-if="isAddressDefined && !isFormVisible"
        :confirm-cb="confirm"
        :customEditText="isMarketSpecific ? '' : 'Use different address'"
      )
    div.mcp-admin__spinner-container(v-if="isLoading" data-testid="return-details-loader")
      div.dc-spinner

    div(v-else-if="isAddressDefined")
      AddressForm(
        v-if="isFormVisible"
        :country-name="countryName"
        :is-submitting="isSubmitting"
        :address="address"
        @submit="saveReturnAddress"
        @use-merchant-address="useMerchantAddress"
        @cancel="cancel"
      )

      //- Read only mode
      AddressReadOnly(
        v-else
        :address="address"
        show-name
      )

    div.dc-msg.dc-msg--warning(v-else)
      div.dc-msg__inner
        div.dc-msg__icon-frame
          i.dc-icon.dc-msg__icon.dc-icon--warning
        div.dc-msg__bd
          h1.dc-msg__title
            | Please define return address in&nbsp;
            a.dc-link.dc-msg__bd__link(:href="merchantDataPageLink")
              | General Company Data
            | &nbsp;page
</template>

<script lang="ts">
import Vue from 'vue';
import {
  fetchReturnAddress,
  useMerchantReturnAddress,
  updateReturnAddress,
} from 'Api/endpoints/sales-channel/return-address.endpoint';
import AddressReadOnly from './address-read-only.vue';
import AddressForm from './address-form.vue';
import { MarketAddressT } from 'Api/models/Address';
import ConfirmDataChange from '@/common/components/confirm-data-change/confirm-data-change.vue';
import { sendUpdateMerchantDataReason } from '@/api/endpoints/merchant/merchant.endpoint.es';

export default Vue.extend({
  components: {
    AddressForm,
    AddressReadOnly,
    ConfirmDataChange,
  },
  props: {
    merchantId: {
      type: String,
      required: true,
    },
    salesChannelId: {
      type: String,
      required: true,
    },
    fulfillmentType: {
      type: String,
      required: false,
      default: '',
    },
    countryName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      isFormVisible: false,
      address: null,
      showConfirmDialog: false,
      editReason: '',
    };
  },

  computed: {
    isAddressDefined(): boolean {
      return !!this.address;
    },
    isMarketSpecific(): boolean {
      return this.address.source === 'SALES_CHANNEL';
    },
    merchantDataPageLink(): string {
      return this.$router.resolve({
        name: 'mcp.admin.merchant.general.company-details',
        params: {
          merchantId: this.merchantId,
        },
      }).href;
    },
  },

  mounted() {
    fetchReturnAddress(this.merchantId, this.salesChannelId)
      .then((address) => {
        this.address = address;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    confirm(reason: string): void {
      this.editReason = reason;
      this.isFormVisible = true;
    },
    cancel(): void {
      this.isFormVisible = false;
      this.editReason = null;
    },
    saveReturnAddress(address: MarketAddressT): void {
      this.isSubmitting = true;
      updateReturnAddress(this.merchantId, this.salesChannelId, address)
        .then((updatedAddress) => {
          this.isFormVisible = false;
          this.address = updatedAddress;
          this.$emit('save', `Return address was successfully saved`);
        })
        .catch((err) => {
          this.$emit('error', 'Unable to save Return Address');
        })
        .finally(() => {
          this.isSubmitting = false;
        });

      if (this.editReason) {
        sendUpdateMerchantDataReason(this.merchantId, this.editReason, {
          salesChannelId: this.salesChannelId,
          fulfilmentType: this.fulfillmentType,
        });
      }
    },
    useMerchantAddress(): void {
      this.isSubmitting = true;
      useMerchantReturnAddress(this.merchantId, this.salesChannelId)
        .then(() => {
          this.$emit('save', `Return address was successfully saved`);
        })
        .catch((err) => {
          this.$emit('error', 'Unable to save Return Address');
        })
        .finally(() => {
          this.isFormVisible = false;
          this.isSubmitting = false;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.is-loading {
  min-height: 250px;
}
.mcp-admin-merchant-details-section {
  border-bottom: 1px solid #d1d1d1;
}
.mcp-admin-merchant-details-section__header ::v-deep div.v-application--wrap {
  margin-top: 0;
  min-height: auto;
}
</style>
