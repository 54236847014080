<template lang="pug">
  div
    v-row
      v-col.pb-0(md=12)
        h4 Bank Account: {{bankAccount.currencyCode}}
      v-col.pb-0(md="6")
        v-text-field(
          label="IBAN"
          :value="bankAccount.iban"
          @change="value => $emit('on-change', bankAccount.currencyCode, 'iban', value)"
          :rules="[$options.filters.formFieldRequired]"
          filled
        )

    v-row
      v-col.pb-0
        v-text-field(
          label="Bank name"
          :value="bankAccount.bankName"
          @input="value => $emit('on-change', bankAccount.currencyCode, 'bankName', value)"
          :rules="[$options.filters.formFieldRequired]"
          filled
        )
      v-col.pb-0
        v-text-field(
          label="Account holder name"
          :value="bankAccount.accountHolder"
          @input="value => $emit('on-change', bankAccount.currencyCode, 'accountHolder', value)"
          :rules="[$options.filters.formFieldRequired]"
          filled
        )

    v-row
      v-col(md=6).pt-0
        BankDocumentUpload(
          :document-id="bankAccount.confirmationDocumentId"
          @on-upload-finish="documentId => $emit('on-change', bankAccount.currencyCode, 'confirmationDocumentId', documentId)"
        )
</template>

<script lang="ts">
import Vue from 'vue';
import { BankAccountInfoT } from 'Api/models/BankAccount';
import BankDocumentUpload from 'Merchant/common/components/document-upload/bank-document-upload.vue';

export default Vue.extend({
  components: {
    BankDocumentUpload,
  },

  props: {
    bankAccount: {
      type: Object as () => BankAccountInfoT,
      required: true,
    },
  },
});
</script>
