import { ContactTypeT } from 'Api/models/MerchantContact';

export const CTYPE_GENERAL = 'GENERAL';
const CTYPE_CUSTOMER_SERVICE = 'CUSTOMER_SERVICE';
export const CTYPE_CUSTOMER_ESCALATION = 'CUSTOMER_ESCALATION';
export const CTYPE_INVOICES_AND_SETTLEMENT = 'INVOICES_AND_SETTLEMENT';
export const CTYPE_INVOICES_AND_SETTLEMENT_ZFS = 'INVOICES_AND_SETTLEMENT_ZFS';
const CTYPE_LOGISTICS_AND_OPERATIONS_ZFS = 'LOGISTICS_AND_OPERATIONS_ZFS';
export const CTYPE_STOCK_REPORT_ZFS = 'STOCK_REPORT_ZFS';

export const COMPANY_CONTACT_TYPES: ContactTypeT[] = [CTYPE_GENERAL];

export const COUNTRY_CONTACT_TYPES: ContactTypeT[] = [
  CTYPE_INVOICES_AND_SETTLEMENT,
  CTYPE_CUSTOMER_SERVICE,
  CTYPE_CUSTOMER_ESCALATION,
];

// This is deprecated. Will be removed in future.
// Use COMPANY_CONTACT_TYPES or COUNTRY_CONTACT_TYPES.
export const CONTACT_TYPES: ContactTypeT[] = [
  CTYPE_GENERAL,
  CTYPE_CUSTOMER_SERVICE,
  CTYPE_CUSTOMER_ESCALATION,
  CTYPE_INVOICES_AND_SETTLEMENT,
];

export const ZFS_CONTACT_TYPES: ContactTypeT[] = [
  CTYPE_INVOICES_AND_SETTLEMENT_ZFS,
];

export const CTYPE_LABELS = {
  [CTYPE_GENERAL]: 'Main Company Contact',
  [CTYPE_CUSTOMER_SERVICE]: 'Customer Service Contact',
  [CTYPE_CUSTOMER_ESCALATION]: 'Customer Escalation Contact',
  [CTYPE_INVOICES_AND_SETTLEMENT]: 'Monthly Invoices / Settlement File Contact',
  [CTYPE_INVOICES_AND_SETTLEMENT_ZFS]: 'ZFS Invoice Contact',
  [CTYPE_LOGISTICS_AND_OPERATIONS_ZFS]: 'Logistics / Operations Contact ZFS',
  [CTYPE_STOCK_REPORT_ZFS]: 'Stock Report Contact ZFS',
};
