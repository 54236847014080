export function IamCheckRolesService() {
  /**
   * Check the roles (permission) of an Iam user
   *
   * NOTE: If roles to check are passed as a String or as an Array of strings
   * The userRoles array must contain the specified role or all the specified roles.
   *
   * {String|Array<string>|RegExp} roles - roles to check for
   * {Array} userRoles - user roles
   *
   * @return boolean
   */
  return function (roles, userRoles) {
    if (roles instanceof RegExp) {
      return checkRolesRegExp(roles, userRoles);
    }
    return checkRolesArray(roles, userRoles);
  };
}

function checkRolesArray(roles, userRoles) {
  const rolesArray = angular.isArray(roles) ? roles : [roles];
  userRoles = userRoles || [];

  return rolesArray.filter((role) => userRoles.indexOf(role) > -1).length > 0;
}

function checkRolesRegExp(rolesRegExp, userRoles) {
  for (let i = 0; i < userRoles.length; i++) {
    if (rolesRegExp.test(userRoles[i]) === true) {
      return true;
    }
  }
  return false;
}
