var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.hideTitle
        ? _c("ReviewSectionTitle", { attrs: { title: "Billing Details" } })
        : _vm._e(),
      _c("div", { staticClass: "py-3" }, [
        _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "label text-caption text-uppercase" }, [
            _vm._v("Billing mode"),
          ]),
          _c("div", [
            _vm._v(_vm._s(_vm._f("formatBillingMode")(_vm.billingMode))),
          ]),
        ]),
        _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "label text-caption text-uppercase" }, [
            _vm._v("Invoice Language"),
          ]),
          _c("div", [
            _vm._v(
              _vm._s(_vm._f("formatInvoiceLanguage")(_vm.invoiceLanguage))
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }