import './mc-client';
import './admin.module';

function bootstrap(host) {
  // @ts-ignore
  const mc = host.mc;
  const bodyHTML =
    '<div class="mc-app--full-height">' +
    '<div mc-loading-screen>' +
    '<div class="dc-spinner"></div>' +
    '</div>' +
    '<mcb-toast ng-cloak></mcb-toast>' +
    '<div ng-cloak>' +
    '<div ui-view id="index-ui-view"></div>' +
    '</div>';

  const $body = $('body');
  $body.attr('ng-controller', 'AppCtrl as app');
  $body.append(bodyHTML);

  mc.ng.bundle.bootstrap(document, ['mcp.admin']);

  mc.emitter.on('error', () => {
    $body.append(
      '<div class="mc-screen-error-alert">' +
        '<div class="mc-screen-error-alert__item">' +
        '<h3>Something went horribly wrong!</h3>' +
        "We've logged the error. <br>" +
        'Please try again or contact ' +
        '<a class="dc-link" href="mailto:team-wilma@zalando.de">team-wilma@zalando.de</a> ' +
        "and we'll get back to you as soon as possible!" +
        '</div>' +
        '</div>'
    );
  });
}

$(document).ready(() => bootstrap(window));
