export const ERROR_STATE_NAME = 'mc-public.error';

export const MC_ERROR_STATE_TYPES = {
  UNKNOWN: 'UNKNOWN',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  NOT_FOUND: 'NOT_FOUND',
};

export const DEFAULT_ERROR_TYPE = MC_ERROR_STATE_TYPES.UNKNOWN;

export const MC_STATE_ERRORS = {
  UNKNOWN: {
    type: MC_ERROR_STATE_TYPES.UNKNOWN,
    code: 500,
    img: '/images/ic_error_403.svg',
    message: 'Sorry, something went wrong during requesting the page.',
  },
  UNAUTHORIZED: {
    type: MC_ERROR_STATE_TYPES.UNAUTHORIZED,
    code: 401,
    img: '/images/ic_error_403.svg',
    message: "Sorry, You don't have required roles to access that page.",
  },
  FORBIDDEN: {
    type: MC_ERROR_STATE_TYPES.FORBIDDEN,
    code: 403,
    img: '/images/ic_error_403.svg',
    message: "Sorry, You don't have required roles to access that page.",
  },
  NOT_FOUND: {
    type: MC_ERROR_STATE_TYPES.NOT_FOUND,
    code: 404,
    img: '/images/ic_error_404.svg',
    message: "Sorry, we can't find the page you're looking for.",
  },
};
