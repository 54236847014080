/**
 * This is a place for for defining feature flags
 * export function canDoSomething(): boolean {
 *    return new URLSearchParams(window.location.search).get('do-something') !== null;
 * }
 * */

const isStaging = () =>
  window.location.origin === 'https://merchant-profile-staging.zalando.net';

export function canEnableVatCollection(merchant): boolean {
  const ff = true;
  if (!merchant) {
    return ff;
  } else if (merchant) {
    return (
      merchant.type !== 'CONNECTED_RETAIL' && !!merchant.legalEntityId && ff
    );
  }
}

export function canEnableACUChanges(): boolean {
  return true;
}

export function canEnableBankDetails(): boolean {
  return true;
}

export function canEnableLegalEntityDataEdit(): boolean {
  return (
    new URLSearchParams(window.location.search).get('enable-le-edit') !== null
  );
}

export function canEnableSizeChartChanges(): boolean {
  if (isStaging()) return true;
  return (
    new URLSearchParams(window.location.search).get(
      'enable-size-chart-changes'
    ) !== null
  );
}
