import { mcb } from 'mcb';
import coreModule from 'Common/core.module';
import { GeneralContainer } from './general.container';
import wrapVue from 'Common/wrap-vue.converter';
import { SalesChannelSelectionComponent } from './components/sales-channel-selection/sales-channel-selection.component';
import SalesChannelSelectionVueComponent from './components/sales-channel-selection/sales-channel-selection.vue';
import { CompanyMetadataComponent } from './components/company-metadata/company-metadata.component';
import { CompanyMetadataReadonlyComponent } from './components/company-metadata-readonly/company-metadata-readonly.component';
import { CompanyMetadataGroupComponent } from './components/company-metadata/group/company-metadata-group.component';
import { CompanyMetadataGroupModalComponent } from './components/company-metadata/group-modal/company-metadata-group-modal.component';
import { CompanyMetadataGroupChangeModalComponent } from './components/company-metadata/group-change-modal/company-metadata-group-change-modal.component';
import CompanyMetadataMain from './components/company-metadata/main/company-metadata-main.vue';
import CompanyMetadataFactoring from './components/company-metadata/factoring/company-metadata-factoring.vue';
import { CompanyMetadataMDExportLangComponent } from './components/company-metadata/masterdata-export-lang/company-metadata-md-export-lang.component';
import { CompanyDetailsApprovalComponent } from './components/company-details-approval/company-details-approval.component';
import { CompanyDetailsComponent } from './components/company-details/company-details.component';
import { CommissionComponent } from './components/commission/commission.component';
import MerchantMasterdataComponent from '../masterdata/merchant-masterdata.container.vue';
import CompanyDetailsContactsComponent from './components/company-details-contacts/company-details-contacts-container.vue';
import { MCP_ADMIN_ROLES } from 'Common/constants/roles.constant';
import { CompanyDetailsReadonlyComponent } from './components/company-details-readonly/company-details-readonly.component';
import CompanyMetadataFeeOverview from './components/company-metadata/fee-overview/company-metadata-fee-overview.vue';
import CompanyComplianceDetailsApprovalContainer from './components/company-compliance-details/company-compliance-details-approval-container.vue';
import PartnerContractDetailsContainer from './components/partner-contract-details/partner-contract-details-container.vue';
import CompanyDetailsReview from 'Merchant/general/components/company-details-approval/company-details-review.vue';
import { canEnableBankDetails } from '@/feature-flags';

export default angular
  .module('mcp.merchant.general', [coreModule.name])

  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider): void => {
      $stateProvider
        .state('mcp.admin.merchant.general', {
          url: '',
          abstract: true,
          template: `<mcp-admin-general-container merchant="merchantCtrl.merchant"
                    contracts="merchantCtrl.contracts"
                    ng-if="merchantCtrl.merchant !== null && merchantCtrl.contracts"></mcp-admin-general-container>`,
        })
        .state('mcp.admin.merchant.general.company-details-approval', {
          url: '/approve',
          template: `<mcp-admin-company-details-approval merchant="generalCtrl.merchant" is-business-details="false"></mcp-admin-company-details-approval>`,
          firewalls: {
            fallback: {
              state: 'mcp.admin.merchant.general.company-details',
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
            iam: {
              roles: [MCP_ADMIN_ROLES.FINANCIAL_APPROVER],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.partner-contract-details-approval', {
          url: '/partner-contract-details-approval',
          template: `<mcp-partner-contract-details merchant="generalCtrl.merchant" read-only="true"></mcp-partner-contract-details>`,
          firewalls: {
            fallback: {
              state: 'mcp.admin.merchant.general.partner-contract-details',
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
            iam: {
              roles: [MCP_ADMIN_ROLES.FINANCIAL_APPROVER],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.business-details-approval', {
          url: '/business-details-approval',
          template: `<mcp-admin-company-details-approval merchant="generalCtrl.merchant" is-business-details="true"></mcp-admin-company-details-approval>`,
          firewalls: {
            fallback: {
              state: 'mcp.admin.merchant.general.business-details',
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
            iam: {
              roles: [MCP_ADMIN_ROLES.FINANCIAL_APPROVER],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.compliance-details-approval', {
          url: '/compliance-details-approval',
          template: `<mcp-admin-company-compliance-details-approval></mcp-admin-company-compliance-details-approval>`,
          firewalls: {
            fallback: {
              state: 'mcp.admin.merchant.general.compliance-details',
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
            iam: {
              roles: [MCP_ADMIN_ROLES.FINANCIAL_APPROVER],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.company-details-readonly', {
          url: '/readonly',
          template: `<mcp-admin-company-details-readonly merchant="generalCtrl.merchant">
                    </mcp-admin-company-details-readonly>`,
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
                MCP_ADMIN_ROLES.READ_ONLY,
                MCP_ADMIN_ROLES.OPERATIONS_MANAGER,
              ],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.company-details', {
          url: '',
          template: `<mcp-admin-company-details merchant="generalCtrl.merchant" is-business-details="false">
                    </mcp-admin-company-details>`,
          firewalls: {
            fallback: {
              state: 'mcp.admin.merchant.general.company-details-approval',
              roles: [MCP_ADMIN_ROLES.FINANCIAL_APPROVER],
            },
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.business-details', {
          url: '/business-details',
          template: `<mcp-admin-company-details merchant="generalCtrl.merchant" is-business-details="true">
                    </mcp-admin-company-details>`,
          firewalls: {
            fallback: {
              state: 'mcp.admin.merchant.general.business-details-approval',
              roles: [MCP_ADMIN_ROLES.FINANCIAL_APPROVER],
            },
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.compliance-details', {
          url: '/compliance-details',
          template: `<mcp-admin-company-compliance-details-approval read-only="true">
                    </mcp-admin-company-compliance-details-approval>`,
          firewalls: {
            fallback: {
              state: 'mcp.admin.merchant.general.compliance-details-approval',
              roles: [MCP_ADMIN_ROLES.FINANCIAL_APPROVER],
            },
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.partner-contract-details', {
          url: '/partner-contract-details',
          template: `<mcp-partner-contract-details read-only="false"></mcp-partner-contract-details>`,
          firewalls: {
            fallback: {
              state:
                'mcp.admin.merchant.general.partner-contract-details-approval',
              roles: [MCP_ADMIN_ROLES.FINANCIAL_APPROVER],
            },
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.commission', {
          url: '/commission',
          template: `<mcp-admin-commission merchant="generalCtrl.merchant"></mcp-admin-commission>`,
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.sales-channels', {
          url: '/sales-channels',
          template: `<mcp-admin-sales-channel-selection></mcp-admin-sales-channel-selection>`,
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.metadata', {
          url: '/metadata',
          template: `<mcp-admin-company-metadata></mcp-admin-company-metadata>`,
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.metadata-readonly', {
          url: '/metadata-readonly',
          template: `<mcp-admin-company-metadata-readonly></mcp-admin-company-metadata-readonly>`,
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.SUPER_ADMIN,
                MCP_ADMIN_ROLES.READ_ONLY,
                MCP_ADMIN_ROLES.OPERATIONS_MANAGER,
              ],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.general.masterdata', {
          url: '/masterdata',
          template: `<mcp-admin-merchant-masterdata
                    on-export-cb="generalCtrl.onMasterDataExport"
                    ng-if="generalCtrl.merchant"></mcp-admin-merchant-masterdata>`,
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
                MCP_ADMIN_ROLES.OPERATIONS_MANAGER,
              ],
            },
          },
        } as mcb.firewall.IMcbState);
    },
  ])
  .component('mcpAdminGeneralContainer', GeneralContainer.Factory())
  .component(
    'mcpAdminSalesChannelSelection',
    SalesChannelSelectionComponent.Factory()
  )
  .component(
    'mcpAdminSalesChannelSelectionVue',
    wrapVue(
      SalesChannelSelectionVueComponent,
      'mcpAdminSalesChannelSelectionVue'
    )
  )
  .component(
    'mcpCompanyDetailsContacts',
    wrapVue(CompanyDetailsContactsComponent, 'mcpCompanyDetailsContacts')
  )
  .component('mcpAdminCompanyMetadata', CompanyMetadataComponent.Factory())
  .component(
    'mcpAdminCompanyMetadataReadonly',
    CompanyMetadataReadonlyComponent.Factory()
  )
  .component('mpMetadataMain', wrapVue(CompanyMetadataMain, 'mpMetadataMain'))
  .component(
    'mpMetadataFactoring',
    wrapVue(CompanyMetadataFactoring, 'mpMetadataFactoring')
  )
  .component(
    'mcpAdminCompanyMetadataMdExportLang',
    CompanyMetadataMDExportLangComponent.Factory()
  )
  .component(
    'mcpAdminCompanyMetadataGroup',
    CompanyMetadataGroupComponent.Factory()
  )
  .component(
    'mcpAdminCompanyMetadataGroupModal',
    CompanyMetadataGroupModalComponent.Factory()
  )
  .component(
    'mcpAdminCompanyMetadataGroupChangeModal',
    CompanyMetadataGroupChangeModalComponent.Factory()
  )
  .component(
    'mcpAdminCompanyDetailsApproval',
    canEnableBankDetails()
      ? wrapVue(CompanyDetailsReview, 'mcpAdminCompanyDetailsApproval')
      : CompanyDetailsApprovalComponent.Factory()
  )
  .component(
    'mcpAdminCompanyDetailsReadonly',
    CompanyDetailsReadonlyComponent.Factory()
  )
  .component('mcpAdminCompanyDetails', CompanyDetailsComponent.Factory())
  .component(
    'mcpAdminMerchantMasterdata',
    wrapVue(MerchantMasterdataComponent, 'mcpAdminMerchantMasterdata')
  )
  .component('mcpAdminCommission', CommissionComponent.Factory())
  .component(
    'mpMetadataFeesOverview',
    wrapVue(CompanyMetadataFeeOverview, 'mpMetadataFeesOverview')
  )
  .component(
    'mcpAdminCompanyComplianceDetailsApproval',
    wrapVue(
      CompanyComplianceDetailsApprovalContainer,
      'mcpAdminCompanyComplianceDetailsApproval'
    )
  )
  .component(
    'mcpPartnerContractDetails',
    wrapVue(PartnerContractDetailsContainer, 'mcpPartnerContractDetails')
  );
