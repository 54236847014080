import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { staticClass: "root px-10 py-6" },
    [
      _c(
        VSnackbar,
        {
          attrs: { timeout: 5000, color: "reject", bottom: "", right: "" },
          model: {
            value: _vm.showError,
            callback: function ($$v) {
              _vm.showError = $$v
            },
            expression: "showError",
          },
        },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _c("ReviewStatusLegend", { attrs: { title: "Company data" } }),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c(VProgressCircular, {
                attrs: { color: "secondaryDark", indeterminate: "" },
              }),
            ],
            1
          )
        : _vm.bankAccountList.length
        ? _c(
            "div",
            { staticClass: "ml-8 px-2 py-4" },
            [
              _c(
                "div",
                { staticClass: "my-3" },
                [_c("CrLegalEntityDetails")],
                1
              ),
              _c("div", { staticClass: "my-3" }, [_c("FactoringInfo")], 1),
              _c(
                "div",
                { staticClass: "my-3" },
                [
                  _c("BankAccountReviewList", {
                    attrs: {
                      list: _vm.bankAccountList,
                      "legal-entity-name": _vm.legalEntityName,
                    },
                    on: {
                      "on-rejection-reason-change": _vm.onRejectionReasonChange,
                      "on-status-change": _vm.onReviewStatusChange,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "my-3" },
                [
                  _c("BillingDetailsReadonly", {
                    attrs: {
                      "billing-mode": _vm.billingMode,
                      "invoice-language": _vm.invoiceLanguage,
                    },
                  }),
                ],
                1
              ),
              _c(VDivider),
              _c("ShopDetails", { attrs: { "shop-name": _vm.shopName } }),
              _c(VDivider),
              _c("ContactDetailsReview", {
                attrs: {
                  contacts: _vm.contacts,
                  "has-zfs": _vm.hasZfsContracts,
                },
              }),
              _c(VDivider),
              _vm.showSubmitBtn
                ? _c(
                    "div",
                    { staticClass: "text-center mt-4" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "white--text text-none",
                          attrs: {
                            loading: _vm.isSubmitting,
                            color: "black",
                            disabled: _vm.isSubmitBtnDisabled,
                          },
                          on: { click: _vm.handleSubmit },
                        },
                        [_vm._v("Confirm review of Company details")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("div", { staticClass: "text-h5 text-center mt-4" }, [
            _vm._v("No data available for review"),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }