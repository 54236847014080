import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { attrs: { id: "confirm-data-change" } },
    [
      _c(
        "a",
        {
          staticClass: "dc-btn dc-btn--link mcp-admin-merchant-button--edit",
          on: { click: _vm.editForm },
        },
        [
          !_vm.customEditText
            ? _c("span", [
                _vm._v("Edit"),
                _c("i", {
                  staticClass:
                    "dc-icon dc-icon--pencil dc-icon--btn dc-icon--btn--left",
                }),
              ])
            : _c("span", [_vm._v(_vm._s(_vm.customEditText))]),
        ]
      ),
      _c(
        VDialog,
        {
          attrs: { width: "550" },
          model: {
            value: _vm.showConfirmDataChangeModal,
            callback: function ($$v) {
              _vm.showConfirmDataChangeModal = $$v
            },
            expression: "showConfirmDataChangeModal",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "title" }, [
                _vm._v("Do you want to edit company information?"),
              ]),
              _c("mpAlert", { attrs: { type: "warning" } }, [
                _vm._v(
                  "You are about to change the Company Information for " +
                    _vm._s(_vm.merchantProfile.name) +
                    " who is supposed to use zDirect to provide this information. Please confirm by specifying the reason why you would like to perform this action."
                ),
              ]),
              _c(
                VCardText,
                [
                  _c(VTextarea, {
                    attrs: {
                      filled: "",
                      autofocus: "",
                      label: "A reason for data change(s)",
                      hint:
                        "Please specify at least " +
                        _vm.minWordsCount +
                        " words",
                    },
                    model: {
                      value: _vm.dataChangeReason,
                      callback: function ($$v) {
                        _vm.dataChangeReason = $$v
                      },
                      expression: "dataChangeReason",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    { attrs: { text: "" }, on: { click: _vm.cancel } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        color: "primary",
                        disabled: !_vm.reasonSpecified,
                      },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v("Confirm")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }