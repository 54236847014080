<template lang="pug">
  v-app.root.px-10.py-6
    v-snackbar(v-model="showError" :timeout="5000" color="reject" bottom right)
      | {{errorMessage}}

    ReviewStatusLegend(:title="`${countryName ? `${countryName} Compliance data` : 'Compliance data'}`")
    div.d-flex.justify-center(v-if="isLoading")
      v-progress-circular(
        color="secondaryDark"
        indeterminate
      )
    div.px-2.py-4(v-else-if="!showError")
      div.pb-4(v-if="vatData")
        VatReviewSection(
          title="Vat ID details"
          :vat-details="vatData"
          :status="vatData.approvalStatus"
          :selected-status="selectedStatus"
          :rejection-reason="providedRejectionReason"
          @on-rejection-reason-change="providedRejectionReason = $event"
          @on-status-change="selectedStatus = $event"
          :compliance-status="complianceStatus"
          enable-review
        )

      NoDataSection(
        v-else
        :title="titles.scVatDetails"
      )

      div.text-center(v-if="showSubmitBtn")
        v-btn.white--text.text-none(
          :loading="isSubmitting"
          color="black"
          @click="handleSubmit"
          :disabled="isSubmitBtnDisabled"
        ) Confirm review of Compliance details

</template>

<script lang="ts">
import Vue from 'vue';
import VatReviewSection from 'Merchant/common/components/vat-review-section/vat-review-section.vue';
import { SalesChannelVatInfoT } from 'Api/models/SalesChannelVat';
import {
  fetchSalesChannelVatDetails,
  updateSalesChannelVatStatus,
} from 'Api/endpoints/legal-entity/compliance.endpoint';
import { isDataMissingForTask } from 'Merchant/general/components/company-compliance-details/util';
import NoDataSection from 'Merchant/general/components/company-compliance-details/no-data-compliance-section.vue';
import ReviewStatusLegend from 'Merchant/common/components/review-status-legend/review-status-legend.vue';
import {
  ApprovalStatusT,
  SelectableReviewStatusT,
} from 'Api/models/ApprovalStatus';
import { fetchSalesChannelComplianceStatus } from 'Api/endpoints/sales-channel/compliance.endpoint';

const FETCH_ERROR_MESSAGE = 'Failed to fetch compliance data for review';
const UPDATE_ERROR_MESSAGE =
  'Failed to update the review status for compliance data';

export default Vue.extend({
  components: {
    ReviewStatusLegend,
    VatReviewSection,
    NoDataSection,
  },
  props: {
    salesChannelId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      vatData: null as SalesChannelVatInfoT,
      complianceStatus: '' as ApprovalStatusT,
      showError: false,
      errorMessage: '',
      selectedStatus: '' as SelectableReviewStatusT,
      providedRejectionReason: '',
      isSubmitting: false,
      // @ts-ignore
      ...this.mapState({
        merchantId: 'flow.general.merchant.data.$id',
        legalEntityId: 'flow.general.merchant.data.legalEntityId',
        merchantContracts: 'flow.general.contracts.data',
      }),
      titles: {
        scVatDetails: 'Vat ID details',
      },
    };
  },

  computed: {
    isSubmitBtnDisabled(): boolean {
      if (this.isLoading || this.vatData === null) return true;

      return (
        this.vatData.approvalStatus === ApprovalStatusT.APPROVED ||
        isDataMissingForTask(
          this.vatData.approvalStatus,
          this.selectedStatus,
          this.providedRejectionReason
        )
      );
    },
    showSubmitBtn(): boolean {
      return (
        this.vatData !== null &&
        this.vatData.approvalStatus === ApprovalStatusT.IN_REVIEW
      );
    },
    countryName: function (): string {
      const firstMatchingContract =
        this.merchantContracts &&
        this.merchantContracts.find(
          (contract) => contract.salesChannelId === this.salesChannelId
        );

      return firstMatchingContract
        ? firstMatchingContract.salesChannel.countryName
        : '';
    },
  },

  async mounted() {
    try {
      this.isLoading = true;

      await this.fetchDataToReview();
      await this.fetchComplianceStatus();
    } catch (e) {
      this.showApiError(FETCH_ERROR_MESSAGE);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async fetchDataToReview(): Promise<void> {
      this.vatData = await fetchSalesChannelVatDetails(
        this.legalEntityId,
        this.salesChannelId
      );
    },
    async fetchComplianceStatus(): Promise<void> {
      const response = await fetchSalesChannelComplianceStatus(
        this.merchantId,
        this.salesChannelId
      );
      this.complianceStatus = response.status;
    },

    async handleSubmit(): Promise<void> {
      this.isSubmitting = true;

      try {
        const body =
          this.selectedStatus === ApprovalStatusT.REJECTED
            ? {
                status: this.selectedStatus,
                rejectionReason: this.providedRejectionReason,
              }
            : { status: this.selectedStatus };
        await updateSalesChannelVatStatus(
          this.legalEntityId,
          this.salesChannelId,
          body
        );
        window.location.reload();
      } catch (e) {
        this.showApiError(UPDATE_ERROR_MESSAGE);
      } finally {
        this.isSubmitting = false;
      }
    },
    showApiError(message: string): void {
      this.showError = true;
      this.errorMessage = message;
    },
  },
});
</script>

<style lang="scss" scoped></style>
