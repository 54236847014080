<template lang="pug">
  div
    div.field
      div.label.text-caption.text-uppercase Is Using LRD?
      div(v-if="details.isUsingLrd") Yes
      div(v-else) No

    div.field(v-if="details.lrdCertificateId")
      div.label.text-caption.text-uppercase LRD Proof
      VatCertificate(:certificate-id="details.lrdCertificateId")
</template>

<script lang="ts">
import Vue from 'vue';
import VatCertificate from 'Merchant/common/components/vat-section/vat-certificate.vue';
import { LrdDetailsT } from 'Api/models/OutboundWarehouse';

export default Vue.extend({
  components: { VatCertificate },
  props: {
    details: {
      type: Object as () => LrdDetailsT,
      required: true,
    },
  },
});
</script>
