/**
 * @ngdoc service
 * @name mcbHttpLoadingTracker
 */
export default function httpLoadingTracker() {
  let pendingCounter = 0;
  return {
    defaults: {
      delay: 1000,
    },
    removePending: function () {
      if (pendingCounter > 0) {
        pendingCounter = pendingCounter - 1;
      }
      return this;
    },
    addPending: function () {
      pendingCounter = pendingCounter + 1;
      return this;
    },
    hasPending: function () {
      return pendingCounter > 0;
    },
    count: function () {
      return pendingCounter;
    },
  };
}
