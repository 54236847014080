import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { style: { background: "white" }, attrs: { id: "vue-carrier" } },
    [
      _c(
        VMain,
        [
          _c(
            VContainer,
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _vm.isLoading
                ? _c(
                    "div",
                    { staticClass: "text-center mt-2" },
                    [
                      _c(VProgressCircular, {
                        attrs: {
                          size: 50,
                          color: "primary",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.isLoadingError
                ? _c(VAlert, { attrs: { value: true, type: "error" } }, [
                    _vm._v(
                      "Something went wrong while fetching Carrier Details information"
                    ),
                  ])
                : [
                    !_vm.preferredCarrier &&
                    !_vm.configuredDeliveryProducts.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex mb-5 pa-3 mcp-admin-merchant-carrier-alert",
                          },
                          [
                            _c(
                              VIcon,
                              { staticClass: "mr-4 justify-center" },
                              [_vm._v("mdi-alert")]
                            ),
                            _c("div", { staticClass: "mt-2" }, [
                              _c("b", [_vm._v("Not ready: ")]),
                              _vm._v(
                                "This partner does not have a carrier preference set yet.\nIf this is a hybrid partner (the merchant is using both ZFS and PF),\nyou will need to set their carrier for them. If this is not a hybrid partner,\nplease wait for the partner to set their carrier in zDirect."
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.preferredCarrier &&
                    !_vm.configuredDeliveryProducts.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex mb-5 pa-3 mcp-admin-merchant-carrier-warning",
                          },
                          [
                            _c(
                              VIcon,
                              {
                                staticClass:
                                  "mr-4 justify-center blue--text text--darken-3",
                              },
                              [_vm._v("mdi-information-outline")]
                            ),
                            _c("div", { staticClass: "mt-2" }, [
                              _c("b", [
                                _vm._v("Ready for carrier configuration:"),
                              ]),
                              _vm._v(
                                " You can begin adding the carrier configurations using the “Add delivery carrier configuration” or “Add return carrier configuration” buttons below. \nThe partner has selected “Delivery Carrier: " +
                                  _vm._s(_vm.preferredCarrier) +
                                  '" and  “Delivery method: ' +
                                  _vm._s(_vm.preferredDeliveryProductsLabels) +
                                  '" as their carrier preferences.'
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "grey lighten-5 py-5 px-4 my-5" },
                      [
                        _c("h3", { staticClass: "black--text" }, [
                          _vm._v("Delivery carrier configurations"),
                        ]),
                        !_vm.preferredCarrier
                          ? _c("div", { staticClass: "text-dark mt-2" }, [
                              _vm._v(
                                "The partner has not yet selected their carrier preferences on zDirect.\nPlease wait before adding configuration for delivery carriers."
                              ),
                            ])
                          : _vm._e(),
                        _vm.preferredCarrier
                          ? _c("div", { staticClass: "text-dark mt-2" }, [
                              _vm._v(
                                "Add configuration for delivery carriers.\nFor any carriers not listed, contact the OrderX team for overrides or exceptions."
                              ),
                            ])
                          : _vm._e(),
                        !_vm.configuredDeliveryProducts.length &&
                        !_vm.showAddNewDeliveryMethod
                          ? _c(
                              VBtn,
                              {
                                staticClass: "mt-5 white--text text-none",
                                attrs: {
                                  color: "black",
                                  disabled: !_vm.preferredCarrier,
                                  loading: _vm.isDeliveryMethodSubmitting,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.showAddNewDeliveryMethod =
                                      !_vm.showAddNewDeliveryMethod
                                  },
                                },
                              },
                              [_vm._v("Add delivery carrier configuration")]
                            )
                          : _vm._e(),
                        _vm.configuredDeliveryProducts.length
                          ? _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c(
                                  VCard,
                                  {
                                    staticClass: "pa-4 rounded mt-4",
                                    attrs: {
                                      outlined: "",
                                      tile: "",
                                      rounded: "sm",
                                    },
                                  },
                                  [
                                    _vm.selectedCarrierConfiguration
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-bold mr-2 black--text",
                                            },
                                            [_vm._v("Delivery carrier:")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-body-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .selectedCarrierConfiguration
                                                    .carrier.name
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.configuredDeliveryParcelPerformMapping
                                      ? _c("div", { staticClass: "mt-2" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-bold mr-2 black--text",
                                            },
                                            [_vm._v("Track & trace reference:")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-body-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .configuredDeliveryParcelPerformMapping
                                                    .reference
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.configuredDeliveryProducts.length
                          ? _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-subtitle-2 font-weight-bold black--text",
                                  },
                                  [_vm._v("Delivery methods")]
                                ),
                                _c("CarrierCards", {
                                  attrs: {
                                    list: _vm.configuredDeliveryProducts,
                                    "on-delete":
                                      _vm.deleteDeliveryProductSelection,
                                  },
                                }),
                                !_vm.showAddNewDeliveryMethod
                                  ? _c(
                                      VBtn,
                                      {
                                        staticClass:
                                          "mt-5 white--text text-none",
                                        attrs: {
                                          color: "black",
                                          disabled: !_vm.preferredCarrier,
                                          loading:
                                            _vm.isDeliveryMethodSubmitting,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showAddNewDeliveryMethod =
                                              !_vm.showAddNewDeliveryMethod
                                          },
                                        },
                                      },
                                      [_vm._v("Add delivery method")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showAddNewDeliveryMethod
                          ? _c(
                              "div",
                              { staticClass: "my-5" },
                              [
                                _c("h3", { staticClass: "black--text mb-4" }, [
                                  _vm._v("Add delivery method"),
                                ]),
                                _vm.configuredDeliveryProducts.length === 0
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-1 row" },
                                      [
                                        _c(VSelect, {
                                          staticClass: "ml-4",
                                          style: { maxWidth: "40%" },
                                          attrs: {
                                            label: "Select delivery carrier",
                                            items: _vm.carriersList,
                                          },
                                          on: {
                                            change: _vm.onDeliveryCarrierChange,
                                          },
                                          model: {
                                            value: _vm.selectedDeliveryCarrier,
                                            callback: function ($$v) {
                                              _vm.selectedDeliveryCarrier = $$v
                                            },
                                            expression:
                                              "selectedDeliveryCarrier",
                                          },
                                        }),
                                        _c(VSelect, {
                                          staticClass: "ml-4",
                                          style: { maxWidth: "40%" },
                                          attrs: {
                                            label:
                                              "Select track & trace reference",
                                            items:
                                              _vm.parcelPerformMappingsList,
                                          },
                                          model: {
                                            value:
                                              _vm.selectedDeliveryParcelPerformMapping,
                                            callback: function ($$v) {
                                              _vm.selectedDeliveryParcelPerformMapping =
                                                $$v
                                            },
                                            expression:
                                              "selectedDeliveryParcelPerformMapping",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c(VSelect, {
                                      staticClass: "ml-4",
                                      style: { maxWidth: "40%" },
                                      attrs: {
                                        label: "Delivery method",
                                        items: _vm.deliveryDestinationTypes,
                                        disabled:
                                          !_vm.deliveryDestinationTypes.length,
                                      },
                                      on: { change: _vm.setParcelServices },
                                      model: {
                                        value: _vm.selectedDeliveryProductId,
                                        callback: function ($$v) {
                                          _vm.selectedDeliveryProductId = $$v
                                        },
                                        expression: "selectedDeliveryProductId",
                                      },
                                    }),
                                    _c(VSelect, {
                                      staticClass: "ml-4",
                                      style: { maxWidth: "40%" },
                                      attrs: {
                                        label: "Opt-in services",
                                        items: _vm.optionalCapabilities,
                                        multiple: true,
                                        disabled:
                                          !_vm.optionalCapabilities.length,
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.isAddDeliveryCarrierSelectionsChanged = true
                                        },
                                      },
                                      model: {
                                        value: _vm.selectedOptionalCapabilities,
                                        callback: function ($$v) {
                                          _vm.selectedOptionalCapabilities = $$v
                                        },
                                        expression:
                                          "selectedOptionalCapabilities",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-column ml-4",
                                    style: { maxWidth: "100%" },
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "text-caption v-label theme--light font-weight-light",
                                      },
                                      [_vm._v("Parcel service")]
                                    ),
                                    _c("div", { staticClass: "mt-2" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedParcelServices.join(
                                            ", "
                                          ) || "—"
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  VBtn,
                                  {
                                    staticClass:
                                      "mt-5 mb-6 ml-2 white--text text-none",
                                    attrs: {
                                      color: "black",
                                      disabled:
                                        _vm.shouldDisableShipmentCarrierSubmit,
                                      loading: _vm.isDeliveryMethodSubmitting,
                                    },
                                    on: {
                                      click: _vm.addNewDeliveryProductSelection,
                                    },
                                  },
                                  [_vm._v("Save")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "grey lighten-5 pa-5 my-5" },
                      [
                        _c("h3", { staticClass: "black--text" }, [
                          _vm._v("Add return carrier configurations"),
                        ]),
                        !_vm.preferredCarrier
                          ? _c("div", { staticClass: "text-dark mt-2" }, [
                              _vm._v(
                                "The partner has not yet selected their carrier preferences on zDirect.\nPlease wait before adding configuration for delivery carriers."
                              ),
                            ])
                          : _vm._e(),
                        _vm.preferredCarrier
                          ? _c("div", { staticClass: "text-dark mt-2" }, [
                              _vm._v(
                                "Add configuration for return carrier. For any carriers not listed,\ncontact the OrderX team for overrides or exceptions.\nNote that you can only configure one return carrier at a time."
                              ),
                            ])
                          : _vm._e(),
                        _vm.configuredReturnCarrierSelection
                          ? _c(
                              "div",
                              { staticClass: "mt-4" },
                              [
                                _c(
                                  VCard,
                                  {
                                    staticClass: "pa-4 rounded mt-4",
                                    attrs: {
                                      outlined: "",
                                      tile: "",
                                      rounded: "sm",
                                    },
                                  },
                                  [
                                    _vm.configuredReturnCarrier
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-bold mr-2 black--text",
                                            },
                                            [_vm._v("Return carrier:")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-body-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .configuredReturnCarrierSelection
                                                    .carrier.name
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.configuredReturnParcelPerformMapping
                                      ? _c("div", { staticClass: "mt-2" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-bold mr-2 black--text",
                                            },
                                            [_vm._v("Track & trace reference:")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-body-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .configuredReturnCarrierSelection
                                                    .parcelPerformReference
                                                    .reference
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          VBtn,
                          {
                            staticClass: "mt-5 white--text text-none",
                            attrs: {
                              color: "black",
                              disabled:
                                !!_vm.configuredReturnCarrierSelection ||
                                !_vm.preferredCarrier,
                              loading: _vm.isReturnCarrierSubmitting,
                            },
                            on: {
                              click: function ($event) {
                                _vm.showAddNewReturnCarrier =
                                  !_vm.showAddNewReturnCarrier
                              },
                            },
                          },
                          [_vm._v("Add return carrier configuration")]
                        ),
                        _vm.showAddNewReturnCarrier
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  { staticClass: "mt-1 row" },
                                  [
                                    _c(VSelect, {
                                      staticClass: "ml-4",
                                      style: { maxWidth: "40%" },
                                      attrs: {
                                        label: "Select return carrier",
                                        items: _vm.carriersList,
                                      },
                                      on: { change: _vm.onReturnCarrierChange },
                                      model: {
                                        value: _vm.selectedReturnCarrier,
                                        callback: function ($$v) {
                                          _vm.selectedReturnCarrier = $$v
                                        },
                                        expression: "selectedReturnCarrier",
                                      },
                                    }),
                                    _c(VSelect, {
                                      staticClass: "ml-4",
                                      style: { maxWidth: "40%" },
                                      attrs: {
                                        label: "Select track & trace reference",
                                        items: _vm.parcelPerformMappingsList,
                                      },
                                      model: {
                                        value:
                                          _vm.selectedReturnParcelPerformMapping,
                                        callback: function ($$v) {
                                          _vm.selectedReturnParcelPerformMapping =
                                            $$v
                                        },
                                        expression:
                                          "selectedReturnParcelPerformMapping",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  VBtn,
                                  {
                                    staticClass:
                                      "mt-5 mb-6 ml-2 white--text text-none",
                                    attrs: {
                                      color: "black",
                                      disabled:
                                        _vm.shouldDisableReturnCarrierSubmit,
                                      loading: _vm.isReturnCarrierSubmitting,
                                    },
                                    on: { click: _vm.addNewReturnCarrier },
                                  },
                                  [_vm._v("Save")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }