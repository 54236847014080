import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          !_vm.hideTitle
            ? _c("ReviewSectionTitle", { attrs: { title: "Bank details" } })
            : _vm._e(),
        ],
        1
      ),
      _vm._l(_vm.list, function (details, index) {
        return _c(
          "div",
          {
            key: details.id,
            staticClass: "py-3",
            attrs: { "data-testid": `${details.id}` },
          },
          [
            _c("BankAccountReviewItem", {
              attrs: {
                readonly: _vm.readonly,
                hideSalesChannels: _vm.hideSalesChannels,
                details: details,
                "legal-entity-name": _vm.legalEntityName,
              },
              on: {
                "on-status-change": function ($event) {
                  return _vm.$emit("on-status-change", {
                    status: $event,
                    id: details.id,
                  })
                },
                "on-rejection-reason-change": function ($event) {
                  return _vm.$emit("on-rejection-reason-change", {
                    message: $event,
                    id: details.id,
                  })
                },
              },
            }),
            _c(VDivider),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }