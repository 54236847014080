<template lang="pug">
Toolbar(
  :title="title"
  :hasNewMerchantBtn="hasNewMerchantBtn"
  :hasNewMerchantAccountBtn="hasNewMerchantAccountBtn"
  @add-merchant="addMerchant"
  @add-merchant-account="addMerchantAccount"
  )
</template>

<script lang="ts">
import Vue from 'vue';
import Toolbar from './toolbar.vue';
import { hasEditPermissions } from 'Common/services/permissions.service';

export default Vue.extend({
  components: { Toolbar },
  computed: {
    title(): string {
      switch (this.$route.name) {
        case 'mcp.admin.merchants':
          return 'Merchants';
        case 'mcp.admin.merchant-accounts':
          return 'Merchant Accounts';
        default:
          return '';
      }
    },

    hasNewMerchantBtn(): boolean {
      return hasEditPermissions();
    },

    hasNewMerchantAccountBtn(): boolean {
      return hasEditPermissions();
    },
  },
  methods: {
    addMerchant(): void {
      window.location.assign(
        this.$router.resolve({ name: 'mcp.admin.register' }).href
      );
    },

    addMerchantAccount(): void {
      window.location.assign(
        this.$router.resolve({ name: 'mcp.admin.merchant-account-create' }).href
      );
    },
  },
});
</script>

<style lang="scss" scoped></style>
