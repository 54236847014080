import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VExpansionPanel,
    { staticClass: "root secondaryLight" },
    [
      _c(VExpansionPanelHeader, [_c("div", [_vm._v(_vm._s(_vm.label))])]),
      _c(
        VExpansionPanelContent,
        [
          !_vm.data.length
            ? _c(VLayout, { staticClass: "white", attrs: { light: "" } }, [
                _c("p", { staticClass: "px-6 pt-6" }, [
                  _vm._v("No values were specified"),
                ]),
              ])
            : [
                _vm._l(_vm.data, function (item, index) {
                  return [
                    _c(VHover, {
                      key: item.value,
                      staticClass: "px-6 white",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ hover }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      "grey lighten-4": hover,
                                      clickable: _vm.clickable,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "list-item py-2",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$emit(
                                              "click",
                                              item.value
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: { overflow: "hidden" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-uppercase text-truncate",
                                              },
                                              [_vm._v(_vm._s(item.label))]
                                            ),
                                            _c("div", [
                                              _vm._v(_vm._s(item.description)),
                                            ]),
                                          ]
                                        ),
                                        !_vm.readonly
                                          ? _c(
                                              VBtn,
                                              {
                                                staticClass: "mr-0",
                                                attrs: { small: "", icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.$emit(
                                                      "remove",
                                                      item.value
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  VIcon,
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: hover,
                                                        expression: "hover",
                                                      },
                                                    ],
                                                    attrs: {
                                                      light: "",
                                                      small: "",
                                                      color: "reject",
                                                    },
                                                  },
                                                  [_vm._v("cancel")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    index !== _vm.data.length - 1
                                      ? _c(VDivider, {
                                          key: `divider-${item.value}`,
                                          class: hover ? "grey lighten-2" : "",
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                }),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }