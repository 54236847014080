<template lang="pug">
div
  div
    v-row(dense)
      v-col.text-subtitle-1(cols="3") Partner Id
      v-col
        div.mb-1(v-for="partner in partners" :key="partner.$id")
          span.mono {{partner.$id}} ({{partner.locale}})

          v-chip.ml-2(
            v-if="partner.isZfsPartner"
            color="cyan darken-2"
            text-color="white"
            small
          ) ZFS PARTNER

          v-chip.ml-2(
            v-if="partner.partnerLaunchStatus === 'INACTIVE'"
            color="red"
            text-color="white"
            small
          ) INACTIVE

    v-row.mt-n1(dense align="center")
      v-col.text-subtitle-1(cols="3") Business Partner Id
      v-col.mono {{ merchantId }}

    v-row(dense align="center")
      v-col.text-subtitle-1(cols="3") Sales Channel Id
      v-col.mono {{ channelId }}

    v-row(dense align="center")
      v-col.text-subtitle-1(cols="3") Fulfillment Type
      v-col {{ fulfillmentType | mcpGetFFTypeName}}

    div.text-h6.mt-2 Status

    div.mb-2(v-for="check in filteredChecks" :key="check.title")
      div.d-flex.align-center
        v-icon(v-if="check.status === 'COMPLETED'" color="green") check_circle
        v-icon(v-else-if="check.status === 'PENDING'" color="gray") check_circle
        v-icon(v-else-if="check.status === 'IN_PROGRESS'" color="orange") check_circle
        v-icon(v-else-if="check.status === 'ON_HOLD'" color="orange") pause_circle_filled
        v-icon(v-else-if="check.status === 'FAILED'" color="red") cancel
        v-icon(v-else="check.status === 'LOADING'" color="grey") replay_circle_filled
        //autorenew

        div.ml-2.subtitle-1(v-if="!check.titleLink") {{ check.title }}
        div.ml-2.subtitle-1(v-else)
          a(:href="check.titleLink" target="_blank") {{ check.title }}

      div.ml-8
        div(v-if="check.title === 'Online' || check.title === 'On Hold'")
          div(v-if="liveStatusModifiedAt && liveStatusModifiedBy")
            | Last modified on {{ liveStatusModifiedAt | formatDateTime }}
            | by {{ liveStatusModifiedBy }}
          div(v-if="!isLive && offlineReason")
            | Offline reason: {{ offlineReason }}
        div(v-if="check.info") {{ check.info }}

    v-card.mt-5.pb-2(v-if="canEdit && !hasBeenLaunched")
      v-card-title
        div(v-if="isLaunchEnabled") Contract is currently NOT launched in {{ countryName }}
        div(v-else) Contract is currently NOT launched in {{ countryName }}

      v-card-actions
        v-btn.ml-2(
          v-if="isLaunchEnabled"
          :loading="isLaunchInProgress"
          @click="isChannelLive ? isModalVisible = true : launch()"
          color="primary"
          depressed
        ) Launch {{ countryName }}
        v-btn.ml-2(v-else disabled) Launch {{ countryName }}

  //- Launch Contract confirmation dialog
  v-dialog(v-model="isModalVisible" width="550")
    v-card
      mpAlert.text-h6(type="warning") Warning!

      v-card-text
        div
          span There is already another fulfilment type that is
          strong #{' '} ONLINE #{' '}
          span in {{ countryName }}.
          br
          span Launching
          strong #{' '} {{ fulfillmentType | mcpGetFFTypeName }} #{' '}
          span will put it directly to
          strong #{' '} ONLINE #{' '}
          span status.

      v-card-actions
        v-spacer
        v-btn(text @click="isModalVisible = false") Cancel

        v-btn(
          color="primary"
          depressed
          :loading="isLaunchInProgress"
          @click="launch"
        ) Launch and put ONLINE
</template>

<script lang="ts">
import Vue from 'vue';
import { Partner } from 'Api/models/Contract';

export default Vue.extend({
  props: {
    partners: { type: Array as () => Partner[], required: true },
    merchantId: { type: String, required: true },
    channelId: { type: String, required: true },
    countryName: { type: String, required: true },
    fulfillmentType: { type: String, required: true },
    launchChecks: {
      type: Array as () => { title: string; status: string }[],
      required: true,
    },
    hasBeenLaunched: { type: Boolean, required: true },
    isLaunchEnabled: { type: Boolean, required: true },
    canEdit: { type: Boolean, required: true },
    isLive: { type: Boolean, required: true },
    isChannelLive: { type: Boolean, required: true },
    liveStatusModifiedAt: { type: String, required: false, default: '' },
    liveStatusModifiedBy: { type: String, required: false, default: '' },
    offlineReason: { type: String, required: false, default: '' },
  },

  data() {
    return {
      isModalVisible: false,
      isLaunchInProgress: false,
    };
  },

  computed: {
    filteredChecks() {
      return this.launchChecks.filter((check) => !check.isHidden);
    },
  },

  methods: {
    launch() {
      this.isLaunchInProgress = true;
      this.$emit('contract-launch');
    },
  },
});
</script>

<style lang="scss" scoped>
.root ::v-deep .v-application--wrap {
  min-height: inherit;
}
.mono {
  font-family: monospace;
}
</style>
