var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c("div", { staticClass: "mcp-dialog-wrapper" }, [
            _c("div", { staticClass: "dc-dialog" }, [
              _c("div", { staticClass: "dc-dialog__content" }, [
                _vm.showWarning
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "dc-dialog__header dc-dialog__header--warning",
                      },
                      [_vm._v("WARNING!")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "dc-dialog__body" },
                  [_vm._t("default")],
                  2
                ),
                _c("div", { staticClass: "dc-dialog__actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "dc-btn dc-btn--link",
                      attrs: {
                        "data-testid": "confirmModalNoBtn",
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("reject")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.noBtn))]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "dc-btn dc-btn--primary",
                      attrs: {
                        "data-testid": "confirmModalYesBtn",
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("confirm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.yesBtn))]
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }