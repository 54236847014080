import { VCard } from 'vuetify/lib/components/VCard';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pb-3" },
    [
      _c("div", { staticClass: "d-flex justify-space-between" }, [
        _c(
          "div",
          { staticClass: "field icon-field" },
          [
            _c("ReviewStatusIcon", {
              attrs: {
                status: _vm.details.status,
                "manual-review-required": _vm.showNameMismatchNote,
              },
            }),
            _c("div", { staticClass: "label text-caption text-uppercase" }, [
              _vm._v("Currency"),
            ]),
            _c("div", [_vm._v(_vm._s(_vm.details.currencyCode))]),
          ],
          1
        ),
        _vm.isStatusSelectorVisible
          ? _c(
              "div",
              [
                _c("StatusSelector", {
                  attrs: { "selected-status": _vm.details.selectedStatus },
                  on: {
                    "on-status-select": function ($event) {
                      return _vm.$emit("on-status-change", $event)
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      !_vm.hideSalesChannels
        ? _c("div", { staticClass: "field align-start" }, [
            _c("div", { staticClass: "label text-caption text-uppercase" }, [
              _vm._v("Sales Channel ID"),
            ]),
            _c(
              "div",
              _vm._l(_vm.details.salesChannelIds, function (salesChannelId) {
                return _c("div", [_c("div", [_vm._v(_vm._s(salesChannelId))])])
              }),
              0
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "label text-caption text-uppercase" }, [
          _vm._v("IBAN"),
        ]),
        _c("div", [_vm._v(_vm._s(_vm.details.iban))]),
      ]),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "label text-caption text-uppercase" }, [
          _vm._v("Account holder"),
        ]),
        _c("div", [_vm._v(_vm._s(_vm.details.accountHolder))]),
      ]),
      _c("div", { staticClass: "field" }, [
        _c("div", { staticClass: "label text-caption text-uppercase" }, [
          _vm._v("Banking Country"),
        ]),
        _c("div", [_vm._v(_vm._s(_vm.country || "-"))]),
      ]),
      _vm.details.confirmationDocumentId
        ? _c(
            "div",
            { staticClass: "field" },
            [
              _c("div", { staticClass: "label text-caption text-uppercase" }, [
                _vm._v("Confirmation Document"),
              ]),
              _c("ConfirmationCertificate", {
                attrs: { "certificate-id": _vm.details.confirmationDocumentId },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showNameMismatchNote
        ? _c("p", { staticClass: "orange--text text-caption mt-2 mb-0" }, [
            _c("span", { staticClass: "font-weight-bold mr-2" }, [
              _vm._v("Note:"),
            ]),
            _c("span", [
              _vm._v(
                "Account holder name needs to be validated manually and compared against the confirmation document."
              ),
            ]),
          ])
        : _vm._e(),
      _vm.isDataRejected
        ? _c(
            VCard,
            { staticClass: "px-4 pt-4", attrs: { outlined: "" } },
            [
              _c("div", { staticClass: "mb-4 text-caption font-weight-bold" }, [
                _vm._v(
                  "Please provide exact reasons for your rejection and the next steps for partners:"
                ),
              ]),
              _c(VTextarea, {
                attrs: {
                  "no-resize": "",
                  outlined: "",
                  "persistent-hint": "",
                  height: "80",
                  placeholder: "Write your reasons and next steps in detail",
                  value: _vm.details.providedRejectionReason,
                  hint:
                    "Please specify at least " + _vm.minWordsCount + " words",
                },
                on: {
                  change: function ($event) {
                    return _vm.$emit("on-rejection-reason-change", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }