<template lang="pug">
div(style="width:100%")
  VAutocomplete.field-wrapper(
    filled
    attach
    multiple
    :messages="helpText"
    :item-value="valueProp"
    :item-text="nameProp"
    :value="value"
    :search-input.async="search"
    :items="isMenuActive() ? computedAttributes : valueAttrs"
    :label="label"
    :no-data-text="noDataText"
    :disabled="readonly"
    @input="$emit('input', $event)"
    @update:search-input="onSearch"
    v-on:keydown.delete="onBackspaceClicked"
    ref="autocomplete"
  )
    //- Text to be prepended in the menu
    template(v-slot:prepend-item)
      template(v-if="computedAttributes.length && !loading")
        div.px-4(v-if="!search") Start typing to see more values
        template(v-else)
          div.px-4(v-if="computedAttributes.length < 100") Found {{computedAttributes.length}} items
          div.px-4(v-else) Search results are limited to 100 items

    //- A slot for displaying selected items in the input field.
    //- Items in this slot should only be selected in the menu is hidden.
    //- Otherwise the field should be used as a search field.
    template(v-slot:selection="{ item, index }")
      template(v-if="!isMenuActive()")
        template(v-if="index > 0") ,
        | {{ getItemLabel(item) }}

  div.green--text(v-if="valuesAdded.length")
    span.font-weight-bold.mr-1 Added ({{valuesAdded.length}}):
    span.font-weight-medium {{ valuesAdded.join(', ') }}
  div.red--text(v-if="valuesRemoved.length")
    span.font-weight-bold.mr-1 Removed ({{valuesRemoved.length}}):
    span.font-weight-medium {{ valuesRemoved.join(', ') }}
</template>

<script lang="ts">
import Vue from 'vue';
import { difference } from 'ramda';

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    savedValue: {
      type: Array,
      required: false,
      default: () => [],
    },
    valueToAttributesMap: {
      type: Map,
      required: false,
      default: () => new Map(),
    },
    attributes: {
      type: Array,
      required: true,
    },
    valueAttrs: {
      type: Array,
      required: true,
    },
    helpText: {
      type: String,
      required: true,
    },
    valueProp: {
      type: String,
      default: 'value',
    },
    nameProp: {
      type: [String, Function],
      default: 'name',
    },
    readonly: Boolean,
    loading: Boolean,
  },

  data() {
    return {
      search: null,
    };
  },

  computed: {
    label() {
      const { name, value } = this;
      return value.length ? `${name} (${value.length} selected)` : name;
    },
    valuesAdded(): string[] {
      return difference(this.value, this.savedValue)
        .map(this.getLabelFromValue)
        .sort() as string[];
    },
    valuesRemoved(): string[] {
      return difference(this.savedValue, this.value)
        .map(this.getLabelFromValue)
        .sort() as string[];
    },
    noDataText(): string {
      if (this.loading) {
        return 'Loading...';
      }

      if (this.emptySearch) {
        return 'Start typing to see more values';
      }

      return 'No items found';
    },
    emptySearch(): boolean {
      return this.search === null || this.search === '';
    },
    /**
     * computedAttributes prop is used in case the menu is active/open
     */
    computedAttributes() {
      const {
        loading,
        emptySearch,
        attributes,
        valueAttrs,
        nameProp,
        valueProp,
        value,
      } = this;

      if (loading) {
        return [];
      }

      if (emptySearch) {
        return valueAttrs;
      }

      // The data that we need to show a user consists of 2 parts:
      // - selected values (should always be on top)
      // - not selected loaded data (should always be below the selected values)
      // Attributes (not selected loaded data) that come from the parent component
      // may not always contain the selected values, because they are limited to 100.
      // It means that we need to add them manually.
      const lcSearch = this.search.toLowerCase();
      const attributesNoValues = attributes.filter(
        (item) => !value.includes(item[valueProp])
      );
      const filteredValuesAttrs = valueAttrs.filter(
        (item) =>
          this.getItemLabel(item).toLowerCase().includes(lcSearch) ||
          item[valueProp].toLowerCase().includes(lcSearch)
      );

      const items = [...filteredValuesAttrs, ...attributesNoValues];

      // Cut the items to 100 items
      if (items.length >= 100) {
        items.length = 100;
      }

      return items;
    },
  },

  methods: {
    getItemLabel(item): string {
      // @ts-ignore
      const { nameProp } = this;
      return typeof nameProp === 'function' ? nameProp(item) : item[nameProp];
    },
    getLabelFromValue(value: string): string {
      const attr = this.valueToAttributesMap.get(value);
      return this.getItemLabel(attr);
    },
    isMenuActive(): boolean {
      const { autocomplete } = this.$refs;
      // @ts-ignore
      return !!(autocomplete && autocomplete.isMenuActive);
    },
    onSearch(query): void {
      // @ts-ignore
      if (this.search === query) {
        return;
      }
      // @ts-ignore
      this.search = query;
      this.$emit('update:search-input', query);
    },
    onBackspaceClicked() {
      if (this.search === '' || this.search === null) {
        this.$refs.autocomplete.blur();
      }
    },
  },
});
</script>

<style lang="scss">
// Long names in the dropdown should be trimmed and display ellipsis
.app-msel-aync-chbox {
  width: 100%;
  .v-input__control {
    width: 100%;
  }
  &.v-input--selection-controls.v-input .v-label {
    display: block;
  }
}
</style>
