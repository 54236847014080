<template lang="pug">
  v-app-bar(color="white" height="60" flat app elevation="1")
    ZalandoLogo
    div.ml-2.text-h6.font-weight-light Merchant Profile Cockpit

    div.ml-2.env-label.warning
      span {{ env | envLabel }}

    slot

    div.ml-4(v-if="!hideTabs")
      v-tabs(
        active-class="v-tabs__item--active--header"
        height="60"
        color="secondaryDark"
        slider-color="secondaryDark"
        optional
        v-model="activeTabModel"
        @change="tabIndex => $emit('tab-changed', tabIndex)"
      )
        v-tab.subtitle-1(
          v-for="(tab, index) in tabs" :key="tab"
          :disabled="index === activeTabModel"
          :class="{'first-tab': index === 0}"
          ) {{ tab }}

    v-spacer

    v-select.flex-grow-0.mr-4(
      style="width: 350px"
      v-if="roles.length > 1"
      v-model="currentRoleModel"
      :items="roles"
      @change="$emit('role-changed', $event)"
      hide-details
    )

    UserProfile(
      :username="username"
      @logout="$emit('logout')"
    )
</template>

<script lang="ts">
import Vue from 'vue';
import { ENVIRONMENT } from 'Common/constants/misc.constant';
import ZalandoLogo from 'Common/components/icons/zalando-logo-icon.vue';
import UserProfile from 'Common/components/user-profile/user-profile.vue';

export default Vue.extend({
  components: {
    ZalandoLogo,
    UserProfile,
  },
  filters: {
    envLabel(env): string {
      const labels = {
        [ENVIRONMENT.PRODUCTION]: 'P',
        [ENVIRONMENT.STAGING]: 'S',
        [ENVIRONMENT.TEST]: 'T',
        [ENVIRONMENT.DEV]: 'D',
      };

      return labels[env];
    },
  },
  props: {
    env: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    currentRole: {
      type: String,
      required: true,
    },
    tabs: {
      type: Array,
      required: false,
    },
    activeTab: {
      type: Number,
      required: false,
    },
    hideTabs: Boolean,
  },
  data() {
    return {
      activeTabModel: this.activeTab,
      currentRoleModel: this.currentRole,
    };
  },
  watch: {
    // Update active tab whenever it's being changed outside
    // E.g. when a user goes to a different page
    activeTab(newActiveTabIndex): void {
      this.activeTabModel = newActiveTabIndex;
    },
  },
});
</script>

<style lang="scss" scoped>
.v-tab.v-tabs__item--active--header {
  color: #212121 !important;
  font-weight: bold;
}
.v-tab {
  color: #4a4a4a !important;
  text-transform: capitalize;
}
.first-tab {
  margin-left: 0px !important;
}
.v-tab--disabled {
  opacity: 1;
}
.env-label {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  line-height: 1;
}
</style>
