<template lang="pug">
v-alert.secondary--text
  template(v-slot:prepend)
    v-icon.mr-4(v-if="type === 'error'" color="reject") mdi-alert
    v-icon.mr-4(v-if="type === 'warning'" color="warning") mdi-alert-circle-outline
    v-icon.mr-4(v-if="type === 'info'" color="warning") mdi-information-outline
  slot
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'Alert',
  props: {
    type: {
      type: String as () => 'error' | 'warning' | 'info',
      default: 'info',
    },
  },
});
</script>

<style lang="scss" scoped>
.v-alert.v-sheet {
  background-color: #fdebe2 !important;
  font-size: 12px;
}
</style>
