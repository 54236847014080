<template lang="pug">
div
  v-sheet.padded-container
    div(style="width: 688px")
      div.display-1.secondary--text.mb-6
        | Legal Entity Information

      v-row
        v-col(cols="6")
          mp-labeled-text(label="Legal Name" :value="legalEntity.name")
        v-col(cols="6")
          mp-labeled-text(label="Supplier ID" :value="legalEntity.supplierId")
        v-col(cols="6")
          mp-labeled-text(label="VAT ID" :value="legalEntity.vatId")
        v-col(cols="6")
          mp-labeled-text(label="Tax ID" :value="legalEntity.taxId")

      v-divider.mt-3(light)

      div.display-1.secondary--text.mb-6.mt-8
        | Billing Information

      v-row
        v-col(cols="6")
          mp-labeled-text.text-capitalize(
            label="Billing Cycle"
            :value="legalEntity.billingCycle"
          )
        v-col(cols="6")
          mp-labeled-text.text-capitalize(
            label="Billing Currency"
            :value="legalEntity.billingCurrency"
          )
        v-col(cols="6")
          mp-labeled-text(label="Invoice Language" :value="legalEntity.invoiceLanguage")

  v-sheet.padded-container.mt-6
    div.display-1.secondary--text.mb-6
      | Merchants

    v-flex.flex-wrap(d-flex v-if="legalEntity.merchants.length")
      div(v-for="{ name, businessPartnerId } in legalEntity.merchants")
        a.title.mr-11(:href="getMerchantUrl(businessPartnerId)")
          | {{ name }}
      div.ml-2(v-if="!legalEntity.merchants.length") -
</template>

<script lang="ts">
import Vue from 'vue';
import LegalEntity from 'Api/models/LegalEntity';
import { canEnableVatCollection } from '@/feature-flags';

export default Vue.extend({
  name: 'LegalEntityDetails',
  props: {
    legalEntity: {
      type: Object as () => LegalEntity,
      required: true,
    },
  },
  methods: {
    getMerchantUrl(merchantId): string {
      return this.$router.resolve({
        name: canEnableVatCollection({
          legalEntityId: this.legalEntity.legalEntityId,
          type: 'VALID', // cause here LE view, so we can assume the condition meet
        })
          ? 'mcp.admin.merchant.general.business-details'
          : 'mcp.admin.merchant.general.company-details',
        params: { merchantId },
      }).href;
    },
  },
});
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.padded-container {
  padding: 48px 0 48px 98px;
}
</style>
