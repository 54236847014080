import template from './company-metadata-group.html';
import { MerchantGroup } from 'Api/models/MerchantGroup';
import { DialogService } from 'Common/services/dialog.service';

export class CompanyMetadataGroupComponent implements ng.IComponentOptions {
  static Factory() {
    return new CompanyMetadataGroupComponent();
  }

  bindings: { [binding: string]: string } = {
    onSubmit: '<',
    onCreate: '<',
    onChange: '<',
    value: '<',
    options: '<',
  };

  controller = MetadataController;
  controllerAs = 'ctrl';
  template: string = template;
}

export class MetadataController {
  static $inject = ['$rootScope', 'mcpAdminDialogService'];

  formValue: string; // id of the selected group
  isSubmitInProgress: boolean = false;
  isInitialised: boolean = false;
  private options: MerchantGroup[] = [];
  private value: string; // id of the currently assigned group
  private onSubmit: Function; // is called when a group is assigned to the merchant
  private onChange: Function; // is called when group is being renamed
  private onCreate: Function;
  private selectGroupDialog: any;
  private renameGroupDialog: any;

  constructor(private $rootScope, private dialogService: DialogService) {}

  $onInit(): void {
    this.formValue = this.value || '';
  }

  $onChanges(): void {
    if (!this.isInitialised && this.options && this.options.length) {
      this.isInitialised = true;
    }
  }

  /**
   * Revert the selected group to the assigned group
   */
  cancel(): void {
    this.formValue = this.value || '';
  }

  /**
   * Open modal to select a new group
   */
  openModal() {
    const parentCtrl = this;

    this.selectGroupDialog = this.dialogService.openDialog({
      template: `
      <div class="dc-dialog__header mcp-admin-metadata-group-modal__header">
          <div>Set merchant group</div>
          <i class="dc-icon dc-icon--close dc-icon--interactive" 
              ng-click="closeThisDialog()"></i>
      </div>
      <div class="dc-dialog__body mcp-admin-metadata-group-modal__body">
        <mcp-admin-company-metadata-group-modal
                on-select="onSelect"
                on-create="onCreate"
                groups="groups"
                selected="selected"></mcp-admin-company-metadata-group-modal>
      </div>`,
      plain: true,
      scope: Object.assign(this.$rootScope.$new(true), {
        onSelect: parentCtrl.onGroupSelect.bind(parentCtrl),
        onCreate: parentCtrl.onGroupCreate.bind(parentCtrl),
        groups: parentCtrl.options,
        selected: parentCtrl.formValue,
      }),
    });
  }

  /**
   * Open modal to rename the current assigned group
   */
  change() {
    const parentCtrl = this;

    this.renameGroupDialog = this.dialogService.openDialog({
      template: `
      <div class="dc-dialog__header mcp-admin-metadata-group-modal__header">
          <div>Change merchant group name</div>
          <i class="dc-icon dc-icon--close dc-icon--interactive" 
              ng-click="closeThisDialog()"></i>
      </div>
      <div class="dc-dialog__body mcp-admin-metadata-group-modal__body">
        <mcp-admin-company-metadata-group-change-modal
              on-close="close"
              on-submit="submit"
              group="group"
              groups="groups"></mcp-admin-company-metadata-group-change-modal>
      </div>`,
      plain: true,
      scope: Object.assign(this.$rootScope.$new(true), {
        group: parentCtrl.getGroup(parentCtrl.value),
        groups: parentCtrl.options,
        close: () => this.renameGroupDialog.close(),
        submit: (newGroup: MerchantGroup) =>
          parentCtrl
            .onChange(newGroup)
            .then(
              () => this.renameGroupDialog && this.renameGroupDialog.close()
            ),
      }),
    });
  }

  /**
   * Submit the selected group
   */
  submit(): void {
    this.isSubmitInProgress = true;

    this.onSubmit({ merchantGroupId: this.formValue }).then(() => {
      this.formValue = this.value || '';
      this.isSubmitInProgress = false;
    });
  }

  onGroupSelect(group): void {
    this.formValue = group.$id;
    if (this.selectGroupDialog) {
      this.selectGroupDialog.close();
    }
  }

  onGroupCreate(groupName) {
    return this.onCreate(groupName).then((newGroup) =>
      this.onGroupSelect(newGroup)
    );
  }

  isCancelDisabled(): boolean {
    const { formValue, value } = this;

    return formValue === value || (!formValue && !value);
  }

  /**
   * Is group rename disabled
   */
  isChangeDisabled(): boolean {
    if (!this.isInitialised || this.isSubmitInProgress) {
      return true;
    }

    if (this.formValue && this.formValue !== this.value) {
      return true;
    }

    return !this.value;
  }

  /**
   * Disable "Save" button if nothing changed or the submit is already in progress
   * @returns {boolean}
   */
  isSubmitDisabled(): boolean {
    const { value, formValue } = this;

    if (this.isSubmitInProgress) {
      return true;
    }

    return !formValue || formValue === value;
  }

  getGroupName(groupId): string {
    let group;

    if (!this.isInitialised) {
      return 'Initializing...';
    }

    group = this.getGroup(groupId);

    return (group && group.name) || '-- NOT DEFINED --';
  }

  getGroup(groupId): MerchantGroup {
    return this.options.find((item) => item.$id === groupId);
  }
}
