<template lang="pug">
  div.certificate-container
    v-snackbar(v-model="showError" :timeout="5000" color="reject" bottom right)
      | {{errorMessage}}


    v-progress-circular(
      v-if="isFetchingCertificate"
      indeterminate
      color="secondaryDark"
    )

    button.blue--text.certificate-link(
      v-else-if="name"
      @click="handleCertificateClick"
    ) {{name}}
</template>

<script lang="ts">
import Vue from 'vue';
import {
  fetchBankConfirmationCertificateMetaData,
  fetchBankConfirmationCertificate,
} from 'Api/endpoints/merchant/merchant-bank-accounts.endpoint';

const CERTIFICATE_FETCH_FAILED = 'Failed to fetch the certificate file.';
const METADATA_FETCH_FAILED =
  'Failed to fetch matadata for the certificate file.';

export default Vue.extend({
  props: {
    certificateId: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      errorMessage: '',
      showError: false,
      isFetchingCertificate: false,
      name: '',
      // @ts-ignore
      ...this.mapState({
        merchantId: 'flow.general.merchant.data.$id',
      }),
    };
  },

  async mounted() {
    try {
      if (this.merchantId && this.certificateId) {
        const metadata = await fetchBankConfirmationCertificateMetaData(
          this.merchantId,
          this.certificateId
        );
        this.name = metadata.filename;
      }
    } catch (e) {
      this.showMetaDataFetchFailedMessage();
    }
  },

  methods: {
    async handleCertificateClick(): Promise<void> {
      try {
        this.showError = false;
        this.isFetchingCertificate = true;
        const pdfContent = await fetchBankConfirmationCertificate(
          this.merchantId,
          this.certificateId
        );

        const pdfBlob = new File([pdfContent], this.name, {
          type: 'application/pdf',
        });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
        this.isFetchingCertificate = false;
      } catch (e) {
        this.showCertificateFetchFailedMessage();
      }
    },
    showMetaDataFetchFailedMessage(): void {
      this.isFetchingCertificate = false;
      this.errorMessage = METADATA_FETCH_FAILED;
      this.showError = true;
    },
    showCertificateFetchFailedMessage(): void {
      this.isFetchingCertificate = false;
      this.errorMessage = CERTIFICATE_FETCH_FAILED;
      this.showError = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.certificate-container {
  flex: 1;
}
.certificate-link {
  overflow-wrap: anywhere;
  text-align: left;
  transition: 0.3s;
  cursor: pointer;
}
.certificate-link:hover {
  opacity: 0.7;
}
</style>
