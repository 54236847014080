<template lang="pug">
v-app.root(id="vue-contract-approval")
  v-alert(type="info" text v-if="needGeneralDataApproval")
    | General Company Data should be approved first before this contract can be approved.

  v-alert(type="success" text v-if="isApproved") Approved

  v-alert(type="error" text v-if="isRejected") Rejected

  div.mcp-admin-merchant-details-section(style="margin: 0")
    div.mcp-admin-merchant-details-section__header Details

    div.mcp-admin-contract-overview__details
      div.mcp-admin-contract-overview__details__item
        div.mcp-admin-contract-overview__details__label Business Partner Id
        div {{merchant.$id}}

      div.mcp-admin-contract-overview__details__item
        div.mcp-admin-contract-overview__details__label Sales Channel Id
        div {{contract.salesChannel.$id}}

      //div.mcp-admin-contract-overview__details__item
      //  div.mcp-admin-contract-overview__details__label Fulfillment type
      //  div {{ contract.fulfillmentType | fulfilmentTypeName }}

  Contacts(
    readonly
    show-market-specific-label
    :merchant-id="merchantId"
    :sales-channel-id="salesChannelId"
    :show-data-changed-label="isInReview && isContactDataChanged"
    )

    //- Address
  div(v-if="!isVatEnabled").mcp-admin-merchant-details-section
    div.mcp-admin-merchant-details-section__header Company Address
      span(
        v-if="address && address.source === 'SALES_CHANNEL'"
        class="mcp-admin-merchant-detail__header-label"
        ) Sales channel specific data

      span(
        v-if="isInReview && isAddressChanged"
        class="mcp-admin-merchant-detail__header-label"
        ) Data for review

    div(v-if="isAddressLoading") Loading...

    div(v-else-if="!address") No address data available

    AddressReadonly(v-else :address="address")

  div(v-if="!isBankDetailsChangesEnabled")
    BankData(
      readonly
      show-market-specific-label
      :merchant-id="merchantId"
      :sales-channel-id="salesChannelId"
      :show-data-changed-label="isInReview && isBankDataChanged"
    )

    BillingData(
      :class="{'noborder': !canBeReviewed}"
      readonly
      :merchant-id="merchantId"
      :sales-channel-id="salesChannelId"
      :fulfillment-type="contract.fulfillmentType"
      :country-name="contract.salesChannel.countryName"
      :show-data-changed-label="isInReview && isBillingDataChanged"
    )

    ReviewButtons(
    v-if="canBeReviewed"
    style="margin: 20px 0;"
    :merchant="merchant"
    :contract="contract"
    :approveCb="approve"
    :rejectCb="reject"
  )

  //- Snackbar to notify about errors
  v-snackbar(
    v-model="isErrorDisplayed"
    content-class="d-flex align-center justify-space-between"
    color="reject"
    bottom
    :timeout="-1"
  )
    | {{errorMsg}}
    v-btn.ml-4.mr-n5(@click="isErrorDisplayed = false" text) Close
</template>

<script lang="ts">
import Vue from 'vue';
import Contacts from 'Merchant/contract/components/contract-contacts/contacts-container.vue';
import AddressReadonly from 'Merchant/contract/components/return-address/address-read-only.vue';
import BankData from 'Merchant/contract/components/bank-data/bank-data.container.vue';
import BillingData from 'Merchant/contract/components/contract-billing-details/billing-details-container.vue';
import ReviewButtons from 'Common/components/approve-reject/approve-reject.vue';
import { Contract } from 'Api/models/Contract';
import { MCP_CONTRACT_STATUSES as STATUSES } from 'Common/constants/contract-statuses.constant';
import { MCP_MERCHANT_STATUSES as MERCHANT_STATUSES } from 'Common/constants/merchant-statuses.constant';
import { MCP_FF_TYPE_NAMES as FULFILMENT_TYPE_NAMES } from 'Common/constants/misc.constant';
import { MerchantProfile as MerchantT } from 'Api/models/MerchantProfile';
import { DataSections } from 'Api/models/DataSections';
import {
  fetchSalesChannelDataChanges,
  markAsFinancialApproved,
  markAsFinancialRejected,
} from 'Api/endpoints/contract/contract-new.endpoint';
import { canEnableBankDetails, canEnableVatCollection } from '@/feature-flags';

export default Vue.extend({
  name: 'ContractApprovalContainer',

  components: {
    Contacts,
    AddressReadonly,
    BankData,
    BillingData,
    ReviewButtons,
  },

  filters: {
    fulfilmentTypeName(value: 'ZALANDO' | 'PARTNER'): string {
      return FULFILMENT_TYPE_NAMES[value];
    },
  },

  props: {
    merchant: {
      type: Object as () => MerchantT,
      required: true,
    },
    onApproveCb: {
      type: Function,
      required: true,
    },
    onRejectCb: {
      type: Function,
      required: true,
    },
    salesChannelId: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      errorMsg: '',
      isErrorDisplayed: false,
      changedSections: null,
      address: null,
      isAddressLoading: true,
      isVatEnabled: canEnableVatCollection(this.merchant),
      isBankDetailsChangesEnabled: canEnableBankDetails(),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...this.mapState({
        contracts: 'flow.general.contracts.data',
      }),
    };
  },

  computed: {
    merchantId(): string {
      return this.merchant.$id;
    },
    contract(): Contract {
      return (this.contracts as Contract[]).filter(
        (c) => c.salesChannelId === this.salesChannelId
      )[0];
    },
    isInReview(): boolean {
      return this.contract.status === STATUSES.IN_FINANCIAL_REVIEW;
    },
    isContactDataChanged(): boolean {
      return this.changedSections?.includes(DataSections.Contact);
    },
    isAddressChanged(): boolean {
      return this.changedSections?.includes(DataSections.Address);
    },
    isBankDataChanged(): boolean {
      return this.changedSections?.includes(DataSections.Bank);
    },
    isBillingDataChanged(): boolean {
      return this.changedSections?.includes(DataSections.Billing);
    },
    canBeReviewed(): boolean {
      return (
        this.merchant.status === MERCHANT_STATUSES.FINANCIAL_APPROVED &&
        this.contract.status === STATUSES.IN_FINANCIAL_REVIEW
      );
    },
    isApproved(): boolean {
      return (
        this.merchant.status === MERCHANT_STATUSES.FINANCIAL_APPROVED &&
        this.contract.status === STATUSES.FINANCIAL_APPROVED
      );
    },

    isRejected(): boolean {
      return (
        this.merchant.status !== MERCHANT_STATUSES.IN_FINANCIAL_REVIEW &&
        this.contract.status === STATUSES.FINANCIAL_REJECTED
      );
    },

    needGeneralDataApproval(): boolean {
      return (
        this.contract.status === STATUSES.IN_FINANCIAL_REVIEW &&
        (this.merchant.status === MERCHANT_STATUSES.IN_FINANCIAL_REVIEW ||
          this.merchant.status === MERCHANT_STATUSES.FINANCIAL_REJECTED)
      );
    },
  },

  mounted(): void {
    fetchSalesChannelDataChanges(this.merchantId, this.salesChannelId)
      .then((data) => {
        this.changedSections = data;
      })
      .catch(() => {
        this.isErrorDisplayed = true;
        this.errorMsg = 'Error while loading chanded sections data';
      });
  },

  methods: {
    approve(): void {
      markAsFinancialApproved(this.merchantId, this.salesChannelId)
        .then(this.onApproveCb)
        .catch(() => {
          this.isErrorDisplayed = true;
          this.errorMsg = 'Error while approving the contract';
        });
    },
    reject(reason, scope, sections): void {
      markAsFinancialRejected(
        this.merchantId,
        this.salesChannelId,
        reason,
        scope,
        sections
      )
        .then(this.onRejectCb)
        .catch(() => {
          this.isErrorDisplayed = true;
          this.errorMsg = 'Error while rejecting the contract';
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.root {
  background-color: white;
}
.root ::v-deep .v-application--wrap {
  min-height: auto;
}
.noborder {
  border: none;
}
</style>
