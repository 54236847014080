<template lang="pug">
div
  v-row
    v-col
      v-text-field(
        label="First Name"
        filled
        v-model="firstName"
        :disabled="disabled"
        @input="onChange"
      )
    v-col
      v-text-field(
        label="Last Name"
        filled
        v-model="lastName"
        :disabled="disabled"
        @input="onChange"
      )
  v-text-field(
    label="Email"
    filled
    hint="Enter contact email address of the user who will be the Merchant Admin on zDirect Portal"
    persistent-hint
    :error="!!email && !isEmailValid"
    :disabled="disabled"
    v-model="email"
    @input="onChange"
  )
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    defaultValue: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      firstName: '' as string,
      lastName: '' as string,
      email: '' as string,
    };
  },
  computed: {
    isEmailValid(): boolean {
      const re =
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

      return re.test(this.email);
    },
    isFormValid(): boolean {
      return !!(
        this.firstName.trim() &&
        this.lastName.trim() &&
        this.isEmailValid
      );
    },
  },
  mounted() {
    if (this.defaultValue) {
      this.firstName = this.defaultValue.firstName;
      this.lastName = this.defaultValue.lastName;
      this.email = this.defaultValue.email;
    }
  },
  methods: {
    onChange(): void {
      const contact = this.isFormValid
        ? {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
          }
        : undefined;

      this.$emit('input', contact);
    },
  },
});
</script>

<style lang="scss" scoped></style>
