<template lang="pug">
  div
    div(v-if="isSmall").title.text-body-1 {{title}}
    div(v-else).title.text-h6.font-weight-bold {{title}}
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.title {
  line-height: 1;
}
</style>
