import axios from 'axios';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import { pipe } from 'ramda';
import ErrorHandler, { nullIf404Handler } from 'Api/api-errors-handler';
import {
  FixedEstablishmentResponseT,
  FixedEstablishmentTypeT,
} from 'Api/models/FixedEstablishment';
import { OssInfoT } from 'Api/models/Oss';
import { ZfsVatInfoResponseT, ZfsVatInfoT } from 'Api/models/ZfsVat';
import { ComplianceReviewRequestT } from 'Api/models/ComplianceStatus';
import { VatCertificateMetaDataResponseT } from 'Api/models/Vat';
import { SalesChannelVatInfoT } from 'Api/models/SalesChannelVat';

const legalEntityApi = axios.create({
  baseURL: 'api/merchant-profile/legal-entities',
});
legalEntityApi.interceptors.request.use(decamelizeRequestHandler);
legalEntityApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

export async function fetchFixedEstablishmentData(
  legalEntityId: string,
  type: FixedEstablishmentTypeT
): Promise<FixedEstablishmentResponseT> {
  try {
    return await legalEntityApi.get(
      `${legalEntityId}/fixed-establishments/${type}`
    );
  } catch (err) {
    return nullIf404Handler(err);
  }
}

export async function fetchOssData(
  legalEntityId: string
): Promise<OssInfoT | null> {
  try {
    return await legalEntityApi.get(`${legalEntityId}/oss`);
  } catch (err) {
    return nullIf404Handler(err);
  }
}

export async function fetchZfsVatData(
  legalEntityId: string
): Promise<ZfsVatInfoT[]> {
  const response: ZfsVatInfoResponseT = await legalEntityApi.get(
    `${legalEntityId}/zfs-vat`
  );
  return response.items;
}

// Status Update Endpoints
export function updateFixedEstablishmentReviewStatus(
  legalEntityId: string,
  type: FixedEstablishmentTypeT,
  data: ComplianceReviewRequestT
): Promise<void> {
  return legalEntityApi.post(
    `${legalEntityId}/fixed-establishments/${type}/status`,
    data
  );
}

export function updateOssReviewStatus(
  legalEntityId: string,
  data: ComplianceReviewRequestT
): Promise<void> {
  return legalEntityApi.post(`${legalEntityId}/oss/status`, data);
}

export function updateZfsVatReviewStatus(
  legalEntityId: string,
  zfsVatKey: number,
  data: ComplianceReviewRequestT
): Promise<void> {
  return legalEntityApi.post(
    `${legalEntityId}/zfs-vat/${zfsVatKey}/status`,
    data
  );
}

export function fetchCertificateMetadata(
  legalEntityId: string,
  certificateId: string
): Promise<VatCertificateMetaDataResponseT> {
  return legalEntityApi.get(
    `${legalEntityId}/certificates/${certificateId}/metadata`
  );
}

export async function fetchSalesChannelVatDetails(
  legalEntityId: string,
  salesChannelId: string
): Promise<SalesChannelVatInfoT | null> {
  try {
    return await legalEntityApi.get(
      `${legalEntityId}/sales-channels/${salesChannelId}/vat`
    );
  } catch (err) {
    return nullIf404Handler(err);
  }
}

export function updateSalesChannelVatStatus(
  legalEntityId: string,
  salesChannelId: string,
  data: ComplianceReviewRequestT
): Promise<void> {
  return legalEntityApi.post(
    `${legalEntityId}/sales-channels/${salesChannelId}/vat/status`,
    data
  );
}
