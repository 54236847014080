import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "my-3" },
    [
      _c("ReviewSectionTitle", { attrs: { title: "Factoring Details" } }),
      _c(
        "div",
        { staticClass: "py-3" },
        [
          _c("div", { staticClass: "field mb-3" }, [
            _c("div", { staticClass: "label text-caption text-uppercase" }, [
              _vm._v("Factoring Type"),
            ]),
            _c("div", [_vm._v(_vm._s(_vm.merchant.factoring))]),
          ]),
          _c(VDivider),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }