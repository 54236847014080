import {
  MerchantProfile,
  MerchantProfileOptional,
} from 'Api/models/MerchantProfile';
import template from './merchant-name-form.html';
import { canEnableVatCollection } from '@/feature-flags';

export class MerchantNameFormComponent implements ng.IComponentOptions {
  static Factory() {
    return new MerchantNameFormComponent();
  }

  bindings: { [binding: string]: string } = {
    merchant: '<',
    onSave: '<',
    onCancel: '<',
  };

  controller = MerchantNameFormController;
  controllerAs = 'ctrl';
  template: string = template;
}

class MerchantNameFormController implements angular.IComponentController {
  // injected by component
  nameForm: ng.IFormController;
  merchant: MerchantProfile;
  isLegalEntityUpdateDisabled: boolean;
  onSave: Function;
  onCancel: Function;
  isVatEnabled: boolean;

  formData: MerchantProfileOptional;

  /**
   * $onInit - angular's lifecycle method
   *
   * Here we are creating a scoped copy of merchant object to simulate a one-time (+one-way) binding,
   * so that editing properties in the scoped object doesn't change the property in parent controller.
   */
  $onInit(): void {
    this.formData = {};
    if (this.merchant) {
      const { name, companyName } = this.merchant;
      this.isVatEnabled = canEnableVatCollection(this.merchant);
      if (this.isVatEnabled) {
        this.formData = { name };
      } else {
        this.formData = { name, companyName };
      }
      this.isLegalEntityUpdateDisabled =
        this.merchant.merchantType !== 'CONNECTED_RETAIL';
    }
  }

  save(): void {
    if (this.nameForm.$invalid) {
      return;
    }

    this.onSave(this.formData);
  }

  cancel(): void {
    this.onCancel();
  }
}
