import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VCard,
    { attrs: { width: "858" } },
    [
      _c(
        VToolbar,
        { attrs: { flat: "", height: "72", color: "white" } },
        [
          _c(VToolbarTitle, { staticClass: "secondary--text" }, [
            _vm._v("Size Chart Preview"),
          ]),
          _c(VSpacer),
          _c(
            VBtn,
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [
              _c(VIcon, { attrs: { color: "secondary" } }, [
                _vm._v("close"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(VDivider),
      _vm.loading
        ? _c(
            VCardText,
            { staticClass: "loading text-center" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", color: "secondaryDark" },
              }),
            ],
            1
          )
        : _c(
            VCardText,
            { staticClass: "px-6 pt-4 secondary--text" },
            [
              _c("div", { staticClass: "subtitle-1 pb-4" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c("th", { staticClass: "caption header-col" }, [
                        _vm._v("Supplier Size"),
                      ]),
                      _vm._l(_vm.conversions, function (country) {
                        return _c(
                          "th",
                          {
                            staticClass:
                              "caption header-col header-col--country-header",
                          },
                          [_vm._v(_vm._s(country))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c(
                  "tbody",
                  { staticClass: "table-body" },
                  _vm._l(_vm.sizeChart.sizes, function (sizeItem) {
                    return _c(
                      "tr",
                      { staticClass: "table-row" },
                      [
                        _c("td", { staticClass: "body-col" }, [
                          _vm._v(_vm._s(sizeItem.supplierSize)),
                        ]),
                        _vm._l(sizeItem.conversions, function (conversion) {
                          return _c("td", { staticClass: "body-col" }, [
                            _vm._v(_vm._s(conversion.raw)),
                          ])
                        }),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]),
              _vm.isSizeChartToggleEnabled &&
              _vm.sizeChart.certificateIds &&
              _vm.sizeChart.certificateIds.length
                ? _c(
                    VList,
                    { attrs: { flat: "" } },
                    [
                      _c("div", { staticClass: "subtitle-1" }, [
                        _vm._v("Proof Documents:"),
                      ]),
                      _vm._l(
                        _vm.sizeChart.certificateIds,
                        function (certificateId) {
                          return _c(
                            VListItem,
                            { key: certificateId },
                            [
                              _c("ConfirmationCertificate", {
                                attrs: { "certificate-id": certificateId },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }