<template lang="pug">
v-app
  Appbar(hide-tabs)

  v-main
    v-container.my-3(fluid)
      v-row(justify="center")
        v-col.white(cols="8" lg="6")
          v-row(justify="center")
            v-col.white(cols="10")
              PageLoader(v-if="isInitInProgress")
              MerchantAccountCreateMainContainer(
                v-else
                :account-id="accountId"
                :default-name="defaultName"
                :default-contact="defaultContact"
                :default-legal-entities="defaultLegalEntities"
                :can-delete-legal-entities="canDeleteLegalEntities"
                :can-edit-contact="canEditContact"
                @canceled="onCancel"
                @saved="onSave"
              )

  //- Notifications
  v-snackbar(
    v-if="notify === 'NOTIFY_INIT_FAILED'"
    v-model="notify"
    color="reject"
    bottom
    :timeout="-1"
  )
    | Error while fetching Merchant Account data
    v-btn(@click="notify = false" text) Close
</template>

<script lang="ts">
import Vue from 'vue';
import Appbar from 'Common/components/appbar/appbar.container.vue';
import MerchantAccountCreateMainContainer from './main/merchant-account-edit-main.container.vue';
import PageLoader from 'Common/components/page-loader/page-loader.vue';
import { fetchMerchantAccountDetails } from 'Api/endpoints/merchant-account/merchant-account.endpoint';
import { pick } from 'ramda';

export default Vue.extend({
  components: {
    Appbar,
    PageLoader,
    MerchantAccountCreateMainContainer,
  },
  props: {
    accountId: {
      type: String,
      required: false,
      default: null,
    },
    onCancel: {
      type: Function,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      notify: false,
      isInitInProgress: true,
      defaultName: '',
      defaultContact: null,
      defaultLegalEntities: [],
      canDeleteLegalEntities: false,
      canEditContact: false,
    };
  },
  mounted(): void {
    if (!this.accountId) {
      this.isInitInProgress = false;
      this.canDeleteLegalEntities = true;
      this.canEditContact = true;
      return;
    }

    fetchMerchantAccountDetails(this.accountId)
      .then((data) => {
        this.defaultName = data.name;
        this.defaultContact = pick(['firstName', 'lastName', 'email'], data);
        this.defaultLegalEntities = data.legalEntities;
        this.canDeleteLegalEntities = !data.isInvitationSent;
        this.canEditContact = !data.isInvitationSent;
        return data;
      })
      .catch(() => (this.notify = 'NOTIFY_INIT_FAILED'))
      .finally(() => (this.isInitInProgress = false));
  },
});
</script>

<style lang="scss" scoped></style>
