import { mcb } from 'mcb';
import { MCP_ADMIN_ROLES } from 'Common/constants/roles.constant';
import wrapVue from 'Common/wrap-vue.converter';
import MerchantsList from './merchants-list.vue';

export default angular
  .module('mcp.admin.merchants', [])

  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider): void => {
      $stateProvider.state('mcp.admin.merchants', {
        url: '/?search',
        template: `<mp-merchants-list></mp-merchants-list>`,
        firewalls: {
          iam: {
            roles: [
              MCP_ADMIN_ROLES.FINANCIAL_APPROVER,
              MCP_ADMIN_ROLES.SUPER_ADMIN,
              MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
              MCP_ADMIN_ROLES.STOCK_MANAGER,
              MCP_ADMIN_ROLES.READ_ONLY,
              MCP_ADMIN_ROLES.OPERATIONS_MANAGER,
            ],
          },
        },
      } as mcb.firewall.IMcbState);
    },
  ])

  .component('mpMerchantsList', wrapVue(MerchantsList, 'mpMerchantsList'));
