export default function dashboardProvider() {
  let widgets = [];

  this.addWidget = function (widget) {
    widgets.push(widget);
    return this;
  };

  this.$get = function () {
    return {
      getWidgets: function () {
        return widgets;
      },
    };
  };
  return this;
}
