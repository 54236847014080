import { MerchantFlowActions } from './merchant-flow.actions';
import { ContractActions } from './contract.actions';

export class ConfigurationActions {
  static $inject = [
    '$q',
    'mcpAdminMerchantFlowActions',
    'mcpAdminContractActions',
  ];

  constructor(
    private q,
    private merchantFlowActions: MerchantFlowActions,
    private contractActions: ContractActions
  ) {}

  updateState() {
    return (dispatch): ng.IPromise<void> => {
      return this.q
        .resolve(dispatch(this.merchantFlowActions.setFlowStatus()))
        .then(() => dispatch(this.merchantFlowActions.setCurrentContract()))
        .then(() => dispatch(this.merchantFlowActions.setCurrentContracts()))
        .then(() => dispatch(this.contractActions.setContractStatus()));
    };
  }
}
