<template lang="pug">
  div
    div.field
      div.label.text-caption.text-uppercase {{labelPrefix}} Vat id
      span {{details.vatId}}

    div.field(v-if="details.certificateId")
      div.label.text-caption.text-uppercase {{labelPrefix}} Id Proof
      VatCertificate(:certificate-id="details.certificateId")

    p(
      v-if="additionalNote"
      :class="`${additionalNote.color}`"
    ).text-caption.mt-2
      span.font-weight-bold.mr-2
        | Note:
      span
        | {{additionalNote.text}}

</template>

<script lang="ts">
import Vue from 'vue';
import { ValidationResultT, VatDetailsT } from 'Api/models/Vat';
import VatCertificate from 'Merchant/common/components/vat-section/vat-certificate.vue';

export default Vue.extend({
  components: { VatCertificate },
  props: {
    labelPrefix: {
      type: String,
      required: false,
      default: '',
    },
    details: {
      type: Object as () => VatDetailsT,
      required: true,
    },
  },
  computed: {
    additionalNote(): { color: string; text: string } {
      const manualReviewMessage =
        'VAT ID needs to be validated manually as it could not be auto-validated by the system. VAT ID and provided ID proof must be accurate for approval.';
      return {
        [ValidationResultT.NOT_VALIDATED]: {
          color: 'orange--text',
          text: manualReviewMessage,
        },
        [ValidationResultT.INVALID]: {
          color: 'orange--text',
          text: manualReviewMessage,
        },
        [ValidationResultT.VALID]: {
          color: 'green--text',
          text: 'Valid VAT ID and matches with legal entity name.',
        },
      }[this.details.validationResult];
    },
  },
});
</script>
