import template from './error.html';
import { errorHelper } from './error-helper.service';
import { ERROR_STATE_NAME } from './error.constant';
import { ErrorController } from './error.controller';

export default angular
  .module('mc.error', ['mcb'])
  .config([
    '$stateProvider',
    ($stateProvider) => {
      $stateProvider.state(ERROR_STATE_NAME, {
        url: '/error',
        params: { errorType: null },
        template,
        controller: 'McErrorController',
        controllerAs: 'error',
      });
    },
  ])
  .run([
    '$rootScope',
    'mcErrorStateHelper',
    ($rootScope, mcErrorStateHelper) => {
      $rootScope.$on(
        'mcStateFirstLoadError',
        mcErrorStateHelper.onStateFirstLoadError
      );
    },
  ])
  .factory('mcErrorStateHelper', errorHelper)
  .controller('McErrorController', ErrorController);
