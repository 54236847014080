import axios from 'axios';
import { pipe } from 'ramda';
import { MarketAddressT } from 'Api/models/Address';
import ErrorHandler, { nullIf404Handler } from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';

const addressApi = axios.create({ baseURL: 'api/row/merchants' });

addressApi.interceptors.request.use(decamelizeRequestHandler);
addressApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

export function fetchReturnAddress(
  merchantId: string,
  salesChannelId: string
): Promise<MarketAddressT | null> {
  return addressApi
    .get(
      `/${merchantId}/return-addresses?sales_channel_id=${salesChannelId}&fallback=true`
    )
    .catch(nullIf404Handler) as Promise<MarketAddressT | null>;
}

export function useMerchantReturnAddress(
  merchantId: string,
  salesChannelId: string
) {
  return addressApi.post(
    `/${merchantId}/return-addresses/use-merchant?sales_channel_id=${salesChannelId}`
  );
}

export function updateReturnAddress(
  merchantId: string,
  salesChannelId: string,
  address: MarketAddressT
) {
  return addressApi.put(
    `/${merchantId}/return-addresses?sales_channel_id=${salesChannelId}`,
    address
  );
}
