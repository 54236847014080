export function fixedOnScroll($window) {
  const $win = angular.element($window);
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      const fixedClass = attrs.fixedClassName;
      const topPadding = parseInt(attrs.paddingWhenAtTop, 10);
      const offsetTop = element.prop('offsetTop');

      $win.on('scroll', function (e) {
        if ($window.pageYOffset + topPadding >= offsetTop) {
          element.addClass(fixedClass);
        } else {
          element.removeClass(fixedClass);
        }
      });
    },
  };
}

fixedOnScroll.$inject = ['$window'];
