<template lang="pug">
v-layout.align-center(v-if="!hasData" justify-space-around column)
  v-icon.no-data-icon(size="56")  remove_circle_outline
  p.subtitle-1.copy.pt-6.text-center Specify master data configuration <br/> to view the summary

v-expansion-panels(v-else :value="0" accordion)
  MasterdataSummaryItem(
    label="Season"
    :readonly="readonly"
    :data="seasons"
    @remove="remove('seasons', $event)"
  )

  MasterdataSummaryItem(
    label="Brand"
    :readonly="readonly"
    :data="brands"
    @remove="remove('brands', $event)"
  )

  MasterdataSummaryItem(
    v-if="isACUChangesEnabled"
    label="Brand ownership"
    :readonly="readonly"
    :data="ownerBrands"
    @remove="remove('ownerBrands', $event)"
  )

  MasterdataSummaryItem(
    label="Silhouette"
    :readonly="readonly"
    :data="silhouettes"
    @remove="remove('silhouettes', $event)"
  )

  MasterdataSummaryItem(
    clickable
    label="Size Chart"
    :readonly="readonly"
    :data="sizeCharts"
    @click="$emit('showPreview', $event)"
    @remove="remove('sizeCharts', $event)"
  )
</template>

<script lang="ts">
import Vue from 'vue';
import Masterdata from 'Api/models/Masterdata';
import { MasterdataSummaryItemValue } from 'Common/components/masterdata/masterdata-helper';
import MasterdataSummaryItem from '../summary-item/masterdata-summary-item.vue';
import { canEnableACUChanges } from '@/feature-flags';

export default Vue.extend({
  components: {
    MasterdataSummaryItem,
  },
  props: {
    readonly: Boolean,
    /** @type {MasterdataAttributesMaps} */
    attributesMaps: Object,
    /** @type {Masterdata} */
    values: {
      type: Object,
      required: true,
    },
  },
  computed: {
    seasons(): Array<MasterdataSummaryItemValue> {
      const values = this.values.seasons;
      const attrs = this.attributesMaps.seasons;

      if (!this.hasData) {
        return [];
      }

      return values.map((value) => ({
        value,
        label: attrs.get(value),
      }));
    },
    brands(): Array<MasterdataSummaryItemValue> {
      const values = this.values.brands;
      const attrs = this.attributesMaps.brands;

      if (!this.hasData) {
        return [];
      }

      return values.map((value) => ({
        value,
        label: `${value} / ${attrs.get(value)}`,
      }));
    },
    silhouettes(): Array<MasterdataSummaryItemValue> {
      const values = this.values.silhouettes;
      const attrs = this.attributesMaps.silhouettes;

      if (!this.hasData) {
        return [];
      }

      return values.map((value) => ({
        value,
        label: attrs.get(value),
      }));
    },
    sizeCharts(): Array<MasterdataSummaryItemValue> {
      const values = this.values.sizeCharts;
      const attrs = this.attributesMaps.sizeCharts;

      if (!this.hasData) {
        return [];
      }

      return values.map((value) => ({
        value,
        label: attrs.get(value).legacyCode,
        description: attrs.get(value).description,
      }));
    },
    ownerBrands(): Array<MasterdataSummaryItemValue> {
      const values = this.values.ownerBrands;
      const attrs = this.attributesMaps.ownerBrands;

      if (!this.hasData) {
        return [];
      }

      return values.map((value) => ({
        value,
        label: `${value} / ${attrs.get(value)}`,
      }));
    },
    isACUChangesEnabled() {
      return canEnableACUChanges();
    },
    hasData() {
      const { seasons, brands, silhouettes, sizeCharts, ownerBrands } = this
        .values as Masterdata;
      return !!(
        seasons.length ||
        brands.length ||
        silhouettes.length ||
        sizeCharts.length ||
        (canEnableACUChanges() ? ownerBrands.length : false)
      );
    },
  },
  methods: {
    remove(type, value: string) {
      const values = this.values[type];
      values.splice(values.indexOf(value), 1);
      if (canEnableACUChanges() && type === 'brands') {
        const { ownerBrands, brands } = this.values;
        const brandsSet = new Set(brands);
        const hasBrandsChanged = ownerBrands.some((ob) => !brandsSet.has(ob));

        if (hasBrandsChanged) {
          const index = ownerBrands.findIndex((ob) => ob === value);
          if (index !== -1) {
            ownerBrands.splice(index, 1);
          }
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.no-data-icon {
  margin-top: 80px;
}

.copy {
  width: 226px;
}

.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border-color: white;
}
</style>
