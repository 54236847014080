<template lang="pug">
  transition(name="modal")
    div.modal-mask
      div.modal-wrapper
        div.modal-container
          div.mcp-dialog-wrapper
            div.dc-dialog
              div.dc-dialog__content
                div.dc-dialog__header.dc-dialog__header--warning(
                  v-if="showWarning"
                ) WARNING!

                div.dc-dialog__body
                  slot

                div.dc-dialog__actions
                  button.dc-btn.dc-btn--link(
                    data-testid="confirmModalNoBtn"
                    type="button"
                    @click="$emit('reject')"
                  ) {{ noBtn }}

                  button.dc-btn.dc-btn--primary(
                  data-testid="confirmModalYesBtn"
                    type="button"
                    @click="$emit('confirm')"
                  ) {{ yesBtn }}
</template>

<script lang="ts">
// We need to use this custom component instead of the
// Vuetify modal component during the AngularJS=>Vue transition period
// because of the limitaions of VuetifyJS (only one VuetifyJS instance per page)
import Vue from 'vue';
export default Vue.extend({
  props: {
    showWarning: {
      type: Boolean,
    },
    yesBtn: {
      type: String,
      default: 'Approve',
    },
    noBtn: {
      type: String,
      default: 'Reject',
    },
  },
});
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.dc-dialog__content {
  @media (min-width: 1025px) {
    width: 550px;
  }
}
</style>
