var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      attrs: { novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "mcp-admin-merchant-details-section" },
        [
          _c("ConfirmationModal", {
            attrs: {
              "confirm-cb": _vm.updateChoice,
              "cancel-cb": _vm.cancelConfirmationModal,
              choice: _vm.nextChoiceValue,
              "show-confirm-data-change-modal": _vm.showModal,
              period: _vm.nextPeriod,
              loading: _vm.isSubmitInProgress,
            },
          }),
          _c(
            "div",
            { staticClass: "mcp-admin-merchant-details-section-form" },
            [
              !_vm.isMerchantOnboarded
                ? _c(
                    "div",
                    { staticClass: "mcp-admin__form-field field-wrapper" },
                    [
                      _c("label", { staticClass: "dc-label" }, [
                        _vm._v("Marketplace service fees"),
                      ]),
                      _c("div", [
                        _c("label", { staticClass: "dc-tag" }, [
                          _vm._v("Starting from:"),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.currentPeriodStartDate)),
                        ]),
                      ]),
                      _c("div", [
                        _c("label", { staticClass: "dc-tag" }, [
                          _vm._v("Initial fee selection:"),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.nextFeesChoice))]),
                      ]),
                      _vm._m(0),
                    ]
                  )
                : _vm._e(),
              _vm.isMerchantOnboarded
                ? _c(
                    "div",
                    { staticClass: "mcp-admin__form-field field-wrapper" },
                    [
                      _c("label", { staticClass: "dc-label" }, [
                        _vm._v("Marketplace service fees"),
                      ]),
                      _c("div", [
                        _c("label", { staticClass: "dc-tag" }, [
                          _vm._v("Fees applicable from:"),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.currentPeriod))]),
                      ]),
                      _c("div", [
                        _c("label", { staticClass: "dc-tag" }, [
                          _vm._v("Fee selection for current period:"),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.currentFeesChoice) +
                              " Marketplace Service fees"
                          ),
                        ]),
                      ]),
                      _c("hr", { staticClass: "dc-divider" }),
                      _c("div", [
                        _c("label", { staticClass: "dc-tag" }, [
                          _vm._v("Fees applicable from:"),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.nextPeriod))]),
                      ]),
                      _c("div", [
                        _c("label", { staticClass: "dc-tag" }, [
                          _vm._v("Fee selection for upcoming period:"),
                        ]),
                        _c("span", [_vm._v(_vm._s(_vm.nextFeesChoice))]),
                      ]),
                      _vm._m(1),
                    ]
                  )
                : _vm._e(),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.nextChoiceValue,
                    expression: "nextChoiceValue",
                  },
                ],
                staticClass: "dc-radio",
                attrs: { type: "radio", id: "reduced-fees", value: "OPT_IN" },
                domProps: { checked: _vm._q(_vm.nextChoiceValue, "OPT_IN") },
                on: {
                  change: function ($event) {
                    _vm.nextChoiceValue = "OPT_IN"
                  },
                },
              }),
              _c(
                "label",
                {
                  staticClass: "dc-label",
                  class: { selected: _vm.nextChoiceValue === "OPT_IN" },
                  attrs: { for: "reduced-fees" },
                },
                [
                  _c("span", [_vm._v("Reduced Marketplace Service fees")]),
                  _vm._m(2),
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.nextChoiceValue,
                    expression: "nextChoiceValue",
                  },
                ],
                staticClass: "dc-radio",
                attrs: { type: "radio", id: "standard-fees", value: "OPT_OUT" },
                domProps: { checked: _vm._q(_vm.nextChoiceValue, "OPT_OUT") },
                on: {
                  change: function ($event) {
                    _vm.nextChoiceValue = "OPT_OUT"
                  },
                },
              }),
              _c(
                "label",
                {
                  staticClass: "dc-label",
                  class: { selected: _vm.nextChoiceValue === "OPT_OUT" },
                  attrs: { for: "standard-fees" },
                },
                [
                  _c("span", [_vm._v("Standard Marketplace Service fees")]),
                  _vm._m(3),
                ]
              ),
              _c("div", { staticClass: "dc-row dc-row--align--right" }, [
                _c("div", { staticClass: "dc-column dc-column--shrink" }, [
                  _c(
                    "button",
                    {
                      staticClass: "dc-btn",
                      class: { "dc-btn--disabled": !_vm.hasChanges },
                      attrs: {
                        type: "reset",
                        disabled: !_vm.hasChanges,
                        "data-testid": "cancel-btn",
                      },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "dc-btn dc-btn--primary",
                      class: {
                        "dc-btn--disabled":
                          _vm.isSubmitInProgress || !_vm.hasChanges,
                      },
                      staticStyle: { "margin-left": "3px" },
                      attrs: {
                        type: "submit",
                        disabled: _vm.isSubmitInProgress || !_vm.hasChanges,
                        "data-testid": "save-btn",
                      },
                      on: { click: _vm.confirmChange },
                    },
                    [_vm._v("Save")]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dc-section" }, [
      _c("span", { staticClass: "dc-tag small-size" }, [_vm._v("Note:")]),
      _c("span", { staticClass: "small-size" }, [
        _vm._v(
          "This setting can be changed at any time during the current period.\nAny changes made will only apply to the upcoming period."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dc-section" }, [
      _c("span", { staticClass: "dc-tag small-size" }, [_vm._v("Note:")]),
      _c("span", { staticClass: "small-size" }, [
        _vm._v(
          "This setting can be changed at any time during the current period.\nAny changes made will only apply to the upcoming period."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", [
      _c("li", [
        _vm._v("Reduced fees will be applied to overlapping articles."),
      ]),
      _c("li", [
        _vm._v(
          "Partner will not be able to compete with Zalando Wholesale or other partners offering the same overlapping article during Dynamic Partner Selection."
        ),
      ]),
      _c("li", [
        _vm._v(
          "Partner will only be eligible to fulfill the order for an overlapping article when Zalando Wholesale and other partners are sold out."
        ),
      ]),
      _c("li", [
        _vm._v(
          "Standard fees will apply to the rest of Partner’s assortment, and Dynamic Partner Selection will also apply to those articles."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("ul", [
      _c("li", [
        _vm._v("Standard fees will be applied to the entire assortment."),
      ]),
      _c("li", [
        _vm._v(
          "Partner will be able to compete on price during Dynamic Partner Selection."
        ),
      ]),
      _c("li", [
        _vm._v(
          "During Dynamic Partner Selection, Partner’s overlapping articles with the lowest price for that size will be offered first to customers ahead of Zalando Wholesale or other partners."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }