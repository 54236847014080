import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    [
      _c("Appbar", { attrs: { "hide-tabs": "" } }),
      _c(
        VMain,
        [
          _c(
            VContainer,
            { staticClass: "my-3", attrs: { fluid: "" } },
            [
              _c(
                VRow,
                { attrs: { justify: "center" } },
                [
                  _c(
                    VCol,
                    { staticClass: "white", attrs: { cols: "8", lg: "6" } },
                    [
                      _c(
                        VRow,
                        { attrs: { justify: "center" } },
                        [
                          _c(
                            VCol,
                            { staticClass: "white", attrs: { cols: "10" } },
                            [
                              _vm.isInitInProgress
                                ? _c("PageLoader")
                                : _c("MerchantAccountCreateMainContainer", {
                                    attrs: {
                                      "account-id": _vm.accountId,
                                      "default-name": _vm.defaultName,
                                      "default-contact": _vm.defaultContact,
                                      "default-legal-entities":
                                        _vm.defaultLegalEntities,
                                      "can-delete-legal-entities":
                                        _vm.canDeleteLegalEntities,
                                      "can-edit-contact": _vm.canEditContact,
                                    },
                                    on: {
                                      canceled: _vm.onCancel,
                                      saved: _vm.onSave,
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.notify === "NOTIFY_INIT_FAILED"
        ? _c(
            VSnackbar,
            {
              attrs: { color: "reject", bottom: "", timeout: -1 },
              model: {
                value: _vm.notify,
                callback: function ($$v) {
                  _vm.notify = $$v
                },
                expression: "notify",
              },
            },
            [
              _vm._v("Error while fetching Merchant Account data"),
              _c(
                VBtn,
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.notify = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }