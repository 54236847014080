import { mcb } from 'mcb';
import wrapVue from 'Common/wrap-vue.converter';
import coreModule from 'Common/core.module';
import { MCP_ADMIN_ROLES } from 'Common/constants/roles.constant';
import ChannelVueContainer from './channel.container.vue';
import ChannelComplianceDetailsApprovalContainer from './channel-compliance-details-approval-container.vue';

export default angular
  .module('mcp.admin.merchant.sales-channel', [coreModule.name])

  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider): void => {
      $stateProvider
        .state('mcp.admin.merchant.sales-channel', {
          url: '/sales-channels/:salesChannelId',
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.sales-channel.overview', {
          url: '/overview',
          template: `<mp-market-container
                         ng-if="merchantCtrl.merchant"
                         on-go-online-cb="merchantCtrl.onGoOnlineCb"
                         on-go-online-error-cb="merchantCtrl.onGoOnlineErrorCb"
                         on-go-offline-cb="merchantCtrl.onGoOfflineCb"
                         on-go-offline-error-cb="merchantCtrl.onGoOfflineErrorCb"
                     />`,
          firewalls: {
            iam: {
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
              ],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.sales-channel.approval', {
          url: '/sales-channel-approval',
          template: `<mp-contract-approval
                        merchant="merchantCtrl.merchant"
                        contract="merchantCtrl.contract"
                        sales-channel-id="merchantCtrl.params.salesChannelId"
                        on-approve-cb="merchantCtrl.onApprove"
                        on-reject-cb="merchantCtrl.onReject"
                      ></mp-contract-approval>`,
          firewalls: {
            fallback: {
              state: 'mcp.admin.merchant.contract.overview',
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
                MCP_ADMIN_ROLES.READ_ONLY,
                MCP_ADMIN_ROLES.OPERATIONS_MANAGER,
              ],
            },
            iam: {
              roles: [MCP_ADMIN_ROLES.FINANCIAL_APPROVER],
            },
          },
        } as mcb.firewall.IMcbState)
        .state('mcp.admin.merchant.sales-channel.compliance-details-approval', {
          url: '/compliance-details-approval',
          template: `<mp-market-compliance-approval
                        sales-channel-id="merchantCtrl.params.salesChannelId"
                      ></mp-market-compliance-approval>`,
          firewalls: {
            fallback: {
              state: 'mcp.admin.merchant.sales-channel.overview',
              roles: [
                MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
                MCP_ADMIN_ROLES.STOCK_MANAGER,
                MCP_ADMIN_ROLES.SUPER_ADMIN,
                MCP_ADMIN_ROLES.READ_ONLY,
                MCP_ADMIN_ROLES.OPERATIONS_MANAGER,
              ],
            },
            iam: {
              roles: [MCP_ADMIN_ROLES.FINANCIAL_APPROVER],
            },
          },
        } as mcb.firewall.IMcbState);
    },
  ])
  .component(
    'mpMarketContainer',
    wrapVue(ChannelVueContainer, 'mpMarketContainer')
  )
  .component(
    'mpMarketComplianceApproval',
    wrapVue(
      ChannelComplianceDetailsApprovalContainer,
      'mpMarketComplianceApproval'
    )
  );
