var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "27",
        height: "27",
        viewBox: "0 0 27 27",
      },
    },
    [
      _c("g", { attrs: { fill: "#4A4A4A", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d: "M13.5 13.5a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25zm0-9.563a3.938 3.938 0 1 1 0 7.876 3.938 3.938 0 0 1 0-7.876zM13.5 14.622A10.197 10.197 0 0 0 3.375 23.62l1.703.007a8.506 8.506 0 0 1 16.844 0h1.704A10.196 10.196 0 0 0 13.5 14.623z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }