import {
  getCountryCode,
  isMinLengthCorrect,
  isMaxLengthCorrect,
  isFormatCorrect,
  stringToIbanFormat,
} from 'Common/services/iban.helper';
export function ibanFormatter() {
  return {
    restrict: 'A',
    require: '?ngModel',
    bindToController: {
      onCountryDetected: '&',
    },
    controller: IbanFormatterController,
    controllerAs: '$ctrl',
  };
}

class IbanFormatterController {
  public static $inject = ['$element'];

  public onCountryDetected: Function;
  private ngModel: ng.INgModelController;

  constructor(private $element: ng.IAugmentedJQuery) {
    this.ngModel = this.$element.controller('ngModel');
  }

  $onInit() {
    this.initializeFormatterAndParser();
  }

  initializeFormatterAndParser() {
    const ngModel = this.ngModel;
    if (!ngModel) {
      return;
    }
    ngModel.$formatters.push(this.ibanFormatter.bind(this));
    ngModel.$parsers.push(this.ibanParser.bind(this));
  }

  ibanFormatter(value: string) {
    if (!value) {
      return;
    }
    const iban = value.replace(/\s/g, '');
    return stringToIbanFormat(iban);
  }

  ibanParser(value: string) {
    const ngModel = this.ngModel;
    if (!value) {
      return;
    }
    const iban = value.replace(/\s/g, '').toUpperCase();
    const validMinLength = isMinLengthCorrect(iban);
    const validMaxLength = isMaxLengthCorrect(iban);
    const validFormat = isFormatCorrect(iban);

    if (validFormat) {
      this.onCountryDetected({
        country: getCountryCode(iban),
      });
    }
    ngModel.$setValidity('IbanMin', validMinLength);
    ngModel.$setValidity('IbanMax', validMaxLength);
    ngModel.$setValidity('IbanFormat', validFormat);

    const formattedIban = stringToIbanFormat(iban);

    const input: HTMLInputElement = <HTMLInputElement>this.$element.get(0);
    let selectionStart = input.selectionStart;

    ngModel.$setViewValue(formattedIban);
    ngModel.$commitViewValue();
    ngModel.$render();

    // if caret reached space in formatting, then move it to the next number
    if (selectionStart !== 0 && selectionStart % 5 === 0) {
      selectionStart = selectionStart + 1;
    }
    input.setSelectionRange(selectionStart, selectionStart);
    return iban;
  }
}
