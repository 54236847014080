import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { staticClass: "root", attrs: { id: "vue-contract-company-data" } },
    [
      _c("Contacts", {
        attrs: {
          "merchant-id": _vm.merchant.$id,
          "sales-channel-id": _vm.salesChannelId,
          "fulfillment-type": _vm.contract.fulfillmentType,
          "get-confirm": _vm.getConfirm,
        },
        on: { save: _vm.onSaveSuccess, error: _vm.showSaveError },
      }),
      !_vm.isVatEnabled
        ? _c(
            "div",
            { staticClass: "mcp-admin-merchant-details-section" },
            [
              _c(
                "div",
                { staticClass: "mcp-admin-merchant-details-section__header" },
                [_vm._v("Company Address")]
              ),
              _vm.contract.address || _vm.merchant.address
                ? _c("AddressReadonly", {
                    attrs: {
                      address: _vm.contract.address || _vm.merchant.address,
                    },
                  })
                : _c("div", [_vm._v("No address data available")]),
            ],
            1
          )
        : _vm._e(),
      _vm.isPartnerFulfilled
        ? _c("ReturnAddress", {
            attrs: {
              "merchant-id": _vm.merchant.$id,
              "sales-channel-id": _vm.salesChannelId,
              "fulfillment-type": _vm.contract.fulfillmentType,
              "country-name": _vm.countryName,
            },
            on: { save: _vm.onSaveSuccess, error: _vm.showSaveError },
          })
        : _vm._e(),
      !_vm.isBankDetailsChangesEnabled
        ? _c("BankData", {
            attrs: {
              "merchant-id": _vm.merchant.$id,
              "sales-channel-id": _vm.salesChannelId,
              "fulfillment-type": _vm.contract.fulfillmentType,
              "country-name": _vm.countryName,
              "company-name": _vm.companyName,
              "get-confirm": _vm.getConfirm,
              merchant: _vm.merchant,
            },
            on: { save: _vm.onSaveSuccess, error: _vm.showSaveError },
          })
        : _vm._e(),
      !_vm.isBankDetailsChangesEnabled
        ? _c("BillingData", {
            attrs: {
              "merchant-id": _vm.merchant.$id,
              "sales-channel-id": _vm.salesChannelId,
              "fulfillment-type": _vm.contract.fulfillmentType,
              "country-name": _vm.countryName,
              "get-confirm": _vm.getConfirm,
            },
            on: { save: _vm.onSaveSuccess, error: _vm.showSaveError },
          })
        : _vm._e(),
      _c(
        VDialog,
        {
          attrs: { persistent: "", "max-width": "390" },
          model: {
            value: _vm.showConfirmModal,
            callback: function ($$v) {
              _vm.showConfirmModal = $$v
            },
            expression: "showConfirmModal",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v("Warning"),
              ]),
              _c(VCardText, [
                _vm._v(
                  'This modification will change status of the contract to "'
                ),
                _c("i", [_vm._v("in progress")]),
                _vm._v('". '),
                _c("br"),
                _vm._v(" Are you sure?"),
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    { attrs: { text: "" }, on: { click: _vm.reject } },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    VBtn,
                    { attrs: { color: "primary" }, on: { click: _vm.confirm } },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VSnackbar,
        {
          attrs: {
            "content-class": "d-flex align-center justify-space-between",
            color: "reject",
            bottom: "",
            timeout: -1,
          },
          model: {
            value: _vm.isSaveErrorDisplayed,
            callback: function ($$v) {
              _vm.isSaveErrorDisplayed = $$v
            },
            expression: "isSaveErrorDisplayed",
          },
        },
        [
          _vm._v(_vm._s(_vm.saveErrorMsg)),
          _c(
            VBtn,
            {
              staticClass: "ml-4 mr-n5",
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  _vm.isSaveErrorDisplayed = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }