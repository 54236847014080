<template lang="pug">
  form(novalidate @submit.prevent)
    div.mcp-admin-merchant-details-section
      ConfirmationModal(
        :confirm-cb="updateChoice"
        :cancel-cb="cancelConfirmationModal"
        :choice="nextChoiceValue"
        :show-confirm-data-change-modal="showModal"
        :period="nextPeriod"
        :loading="isSubmitInProgress"
      )
      div.mcp-admin-merchant-details-section-form
        div.mcp-admin__form-field.field-wrapper(v-if="!isMerchantOnboarded")
          label.dc-label Marketplace service fees
          div
            label.dc-tag Starting from:
            span {{ currentPeriodStartDate }}
          div
            label.dc-tag Initial fee selection:
            span {{ nextFeesChoice }}

          div.dc-section
            span.dc-tag.small-size Note:
            span.small-size
              | This setting can be changed at any time during the current period.
              | Any changes made will only apply to the upcoming period.
        div.mcp-admin__form-field.field-wrapper(v-if="isMerchantOnboarded")
          label.dc-label Marketplace service fees
          div
            label.dc-tag Fees applicable from:
            span {{ currentPeriod }}
          div
            label.dc-tag Fee selection for current period:
            span {{ currentFeesChoice }} Marketplace Service fees

          hr.dc-divider

          div
            label.dc-tag Fees applicable from:
            span {{ nextPeriod }}
          div
            label.dc-tag Fee selection for upcoming period:
            span {{ nextFeesChoice }}

          div.dc-section
            span.dc-tag.small-size Note:
            span.small-size
              | This setting can be changed at any time during the current period.
              | Any changes made will only apply to the upcoming period.

        input(
            type="radio"
            class="dc-radio"
            id="reduced-fees"
            value="OPT_IN"
            v-model="nextChoiceValue"
          )
        label.dc-label(for="reduced-fees" :class="{'selected': nextChoiceValue === 'OPT_IN'}")
          span Reduced Marketplace Service fees
          ul
            li Reduced fees will be applied to overlapping articles.
            li Partner will not be able to compete with Zalando Wholesale or other partners offering the same overlapping article during Dynamic Partner Selection.
            li Partner will only be eligible to fulfill the order for an overlapping article when Zalando Wholesale and other partners are sold out.
            li Standard fees will apply to the rest of Partner’s assortment, and Dynamic Partner Selection will also apply to those articles.


        input(
          type="radio"
          class="dc-radio"
          id="standard-fees"
          value="OPT_OUT"
          v-model="nextChoiceValue"
        )
        label.dc-label(for="standard-fees" :class="{'selected': nextChoiceValue === 'OPT_OUT'}")
          span Standard Marketplace Service fees
          ul
            li Standard fees will be applied to the entire assortment.
            li Partner will be able to compete on price during Dynamic Partner Selection.
            li During Dynamic Partner Selection, Partner’s overlapping articles with the lowest price for that size will be offered first to customers ahead of Zalando Wholesale or other partners.
        div.dc-row.dc-row--align--right
          div.dc-column.dc-column--shrink
            button.dc-btn(
              @click="cancel"
              type="reset"
              :class="{'dc-btn--disabled': !hasChanges}"
              :disabled="!hasChanges"
              data-testid="cancel-btn"
            ) Cancel

            button.dc-btn.dc-btn--primary(
              style="margin-left: 3px"
              type="submit"
              @click="confirmChange"
              :class="{'dc-btn--disabled': isSubmitInProgress || !hasChanges}"
              :disabled="isSubmitInProgress || !hasChanges"
              data-testid="save-btn"
            ) Save
</template>

<script lang="ts">
import Vue from 'vue';
import {
  fetchMerchantChoices,
  updateMerhchantChoice,
} from 'Api/endpoints/merchant/merchant-choice.endpoint';
import ConfirmationModal from './confirmation-modal.vue';
import Swal from 'sweetalert2';

export default Vue.extend({
  components: {
    ConfirmationModal,
  },

  data() {
    return {
      initialNextChoiceValue: null,
      currentChoiceValue: null,
      nextChoiceValue: null,
      isSubmitInProgress: false,
      showModal: false,
      startDate: null,
      selectionPeriod: {
        current: null,
        next: null,
      },
      // @ts-ignore
      ...this.mapState({
        merchantId: 'flow.general.merchant.data.$id',
        isMerchantOnboarded: 'flow.general.merchant.data.hasBeenOnboarded',
      }),
    };
  },

  computed: {
    hasChanges() {
      return this.initialNextChoiceValue !== this.nextChoiceValue;
    },
    currentPeriod() {
      const startOfPeriod = this.formatDate(this.selectionPeriod.current);
      const endDate = new Date(this.selectionPeriod.next).setDate(0);
      const endOfPeriod = this.formatDate(endDate);
      return `${startOfPeriod} - ${endOfPeriod}`;
    },
    currentFeesChoice() {
      if (this.currentChoiceValue === 'OPT_IN') {
        return 'Reduced';
      }
      return 'Standard';
    },
    currentPeriodStartDate() {
      return this.formatDate(this.selectionPeriod.current);
    },
    nextPeriod() {
      if (this.isMerchantOnboarded) {
        return this.formatDate(this.selectionPeriod.next);
      }
      return this.formatDate(this.selectionPeriod.current);
    },
    nextFeesChoice() {
      if (this.initialNextChoiceValue) {
        return `${
          this.initialNextChoiceValue === 'OPT_IN' ? 'Reduced' : 'Standard'
        } Marketplace Service fees`;
      }
      return '(Please make a selection below)';
    },
  },
  mounted() {
    fetchMerchantChoices(this.merchantId).then((data) => {
      if (data) {
        this.selectionPeriod = {
          current: data.offerBackfill.selectionPeriods.current.startDate,
          next: data.offerBackfill.selectionPeriods.next.startDate,
        };
        this.currentChoiceValue = data.offerBackfill.current.choice;
        this.isMerchantOnboarded
          ? (this.initialNextChoiceValue = data.offerBackfill?.next?.choice)
          : (this.initialNextChoiceValue = data.offerBackfill?.current?.choice);

        this.nextChoiceValue = this.initialNextChoiceValue;
      }
    });
  },
  methods: {
    confirmChange() {
      this.showModal = true;
    },
    cancel() {
      this.nextChoiceValue = this.initialNextChoiceValue;
    },
    cancelConfirmationModal() {
      this.showModal = false;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
    updateChoice() {
      this.isSubmitInProgress = true;
      updateMerhchantChoice(this.merchantId, {
        [this.isMerchantOnboarded ? 'next' : 'current']: {
          choice: this.nextChoiceValue,
        },
      })
        .then(() => {
          this.showModal = false;
          this.initialNextChoiceValue = this.nextChoiceValue;
          Swal.fire({
            html: 'Marketplace Service fees has been updated successfully!',
            timer: 2000,
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            icon: 'success',
          });
        })
        .catch(() => {
          this.showModal = false;
          Swal.fire({
            html: 'Something went wrong, please try again!',
            timer: 2000,
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            icon: 'error',
          });
        })
        .finally(() => {
          this.isSubmitInProgress = false;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.field {
  width: 100%;
}
.errors {
  position: absolute;
  bottom: 0;
}
.info {
  color: #1e92f4;
  a {
    color: #1e92f4;
  }
}
.success {
  color: #376e37;
}
.dc-label ul {
  margin-left: 16px;
  color: #555658;
}
.dc-label.selected span {
  font-weight: 500;
}
.dc-tag {
  display: inline-block;
  font-weight: 500;
  padding-right: 5px;
}
.small-size {
  font-size: 12px;
}
.dc-divider {
  margin: 8px auto;
}
.dc-section {
  margin: 8px auto;
}
</style>
