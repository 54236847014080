import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.logs.length
    ? _c(
        VLayout,
        {
          attrs: { "justify-space-around": "", column: "", "align-center": "" },
        },
        [
          _c(
            VIcon,
            {
              staticClass: "no-data-icon",
              staticStyle: { "margin-top": "80px" },
              attrs: { size: "56" },
            },
            [_vm._v(" history")]
          ),
          _c("p", { staticClass: "subtitle-1 pt-6 copy text-center" }, [
            _vm._v("No export history found for Master Data Configuration"),
          ]),
        ],
        1
      )
    : _c(
        VList,
        { staticClass: "px-6", attrs: { "three-line": "" } },
        [
          _vm._l(_vm.logs, function (item, index) {
            return [
              _c(
                VListItem,
                { key: item.createdAt },
                [
                  _c(
                    VListItemContent,
                    [
                      _c(VListItemTitle, [
                        _vm._v(
                          _vm._s(_vm._f("formatDateTime")(item.createdAt))
                        ),
                      ]),
                      _c(VListItemSubtitle, [
                        _vm._v("Master Data sent to export"),
                      ]),
                      _c(VListItemSubtitle, [
                        _vm._v(_vm._s(item.createdBy)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              index < _vm.logs.length - 1
                ? _c(VDivider, { key: index })
                : _vm._e(),
            ]
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }