import { mcb } from 'mcb';
import dialogTemplate from 'Merchant/common/views/confirm-send-to-review-dialog.html';
import { RetailerToContracts } from 'Api/endpoints/contract/retailer.endpoint';
import { USER_DID_NOT_CONFIRM_ERROR } from '../constants/misc.constant';
import { SalesChannel } from 'Api/models/SalesChannel';
import { canEnableBankDetails } from '@/feature-flags';

export class DialogService {
  static $inject = ['$q', 'mcbDialog'];

  constructor(private q: ng.IQService, private dialog: mcb.IDialog) {}

  openDialog(options): any {
    return this.dialog.open(options);
  }

  openConfirmationDialog(
    template: string,
    data: Object = {}
  ): ng.IPromise<any> {
    return this.dialog
      .openConfirm({
        data,
        template,
        plain: true,
        bindToController: true,
        controllerAs: 'dialogCtrl',
      })
      .catch(() => this.q.reject(USER_DID_NOT_CONFIRM_ERROR));
  }

  openReviewConfirmationDialog(data: Object = {}): ng.IPromise<any> {
    return this.dialog
      .openConfirm({
        template: dialogTemplate,
        plain: true,
        bindToController: true,
        controllerAs: 'dialogCtrl',
        data: data,
        controller: function () {
          this.isBankDetailsChangesEnabled = canEnableBankDetails();
          this.reason = '';
          this.selectAllSalesChannelsForRetailer = function (
            retailer: RetailerToContracts
          ) {
            retailer.channels.forEach((channel: SalesChannel) => {
              let selectionObject = {};
              selectionObject[channel.$id] = true;
              angular.extend(
                this.mcbDialogData.reviewSelection.channels,
                selectionObject
              );
            });
          };

          this.deselectAllSalesChannelsForRetailer = function (
            retailer: RetailerToContracts
          ) {
            retailer.channels.forEach((channel: SalesChannel) => {
              let selectionObject = {};
              selectionObject[channel.$id] = false;
              angular.extend(
                this.mcbDialogData.reviewSelection.channels,
                selectionObject
              );
            });
          };

          this.enableSendToReview = function (): boolean {
            let hasSelection = false;
            if (this.mcbDialogData.reviewSelection.merchant) {
              hasSelection = true;
            }

            Object.keys(this.mcbDialogData.reviewSelection.channels).forEach(
              (key) => {
                if (this.mcbDialogData.reviewSelection.channels[key]) {
                  hasSelection = true;
                  return;
                }
              }
            );

            const isReasonValid =
              this.reason && this.reason.trim().split(' ').length >= 5;

            return hasSelection && (!this.mcbDialogData.isZPO || isReasonValid);
          };
        },
      })
      .catch(() => this.q.reject(USER_DID_NOT_CONFIRM_ERROR));
  }
}
