import { mcb } from 'mcb';
import template from './company-metadata.html';
import { MerchantGroup } from 'Api/models/MerchantGroup';
import { MerchantFlowActions } from 'Merchant/common/state/actions/merchant-flow.actions';
import {
  getMerchant,
  getMerchantGroups,
  getMerchantSupplierCode,
  getFlowStatus,
  getMerchantFactoringMigrationStatus,
} from 'Merchant/common/state/selectors/flow-state.selector';
import { ApiService } from 'Api/services/api.service';
import {
  fetchExportLang,
  changeExportLang,
} from 'Api/endpoints/masterdata/masterdata.endpoint';
import { MerchantsMigrationDetails } from '@/api/models/MerchantFactoringMigration';

export class CompanyMetadataComponent implements ng.IComponentOptions {
  static Factory() {
    return new CompanyMetadataComponent();
  }

  controller = MetadataController;
  controllerAs = 'ctrl';
  template: string = template;
}

export class MetadataController {
  static $inject = [
    '$q',
    '$rootScope',
    'mcbToast',
    'mcpAdminApi',
    'mcpAdminMerchantFlowActions',
    '$ngRedux',
  ];

  serviceLevel: string;
  merchantType: string;
  merchantGroupId: string = null;
  supplierCode = '';
  isMerchantTypeDisabled: true;
  isFactoringDisabled: true;
  mdExportLang: string = null; // Master data export language
  groupOptions: MerchantGroup[] = [];
  isSubmitInProgress = false;
  isExportLangSubmitInProgress = false;
  isExportLangInitialized = false;
  isMetadataFeeOverview = false;
  private merchantId: string;
  private unsubscribe: Function;
  private setCurrentMerchant: Function;
  private setFlowStatus: Function;
  private fetchMerchantGroups: Function;
  private setMerchantFactoringMigrationStatus: Function;

  constructor(
    private $q: ng.IQService,
    private $rootScope: ng.IScope,
    private toast: mcb.IToast,
    private api: ApiService,
    private merchantFlowActions: MerchantFlowActions,
    private $ngRedux
  ) {
    this.submitData = this.submitData.bind(this);
    this.submitFactoringData = this.submitFactoringData.bind(this);
    this.onGroupCreate = this.onGroupCreate.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
    this.submitExportLang = this.submitExportLang.bind(this);
  }

  $onInit(): void {
    this.unsubscribe = this.$ngRedux.connect(
      this.mapStateToThis.bind(this),
      this.mapDispatchToThis()
    )(this);

    this.fetchMerchantGroups();

    this.initMDExportLang();
  }

  $onDestroy(): void {
    this.unsubscribe();
  }

  onGroupCreate(groupName): ng.IPromise<any> {
    const { $q, toast, api } = this;

    return api.merchant
      .createMerchantGroup({ name: groupName })
      .then((newGroup) => {
        toast.success(
          `New group "<strong>${newGroup.name}</strong>" successfully created. Now you can assign it to the merchant`
        );

        this.groupOptions = [...this.groupOptions, newGroup];

        return newGroup;
      })
      .catch(() => {
        toast.error(`Creation of the group ${groupName} failed`);
        return $q.reject('');
      });
  }

  /**
   * Callback is called when assigned group is being renamed
   * @param newGroup
   */
  onGroupChange(newGroup: MerchantGroup) {
    const { $q, toast, api } = this;

    const oldGroupName = this.groupOptions.find(
      (item) => item.$id === newGroup.$id
    ).name;

    return api.merchant
      .updateMerchantGroup(newGroup.$id, { name: newGroup.name })
      .then((updatedGroup: MerchantGroup) => {
        toast.success(
          `Group "<strong>${oldGroupName}</strong>" successfully renamed to "<strong>${updatedGroup.name}</strong>"`
        );

        // Search for the group and update it
        const groupOptionIndex = this.groupOptions.findIndex(
          (item) => item.$id === updatedGroup.$id
        );

        this.groupOptions.splice(groupOptionIndex, 1, updatedGroup);

        return updatedGroup;
      })
      .catch(() => {
        toast.error(`Renaming the group ${oldGroupName} failed`);
        return $q.reject('');
      });
  }

  submitData(data): ng.IPromise<unknown> {
    const { $q, toast, api } = this;

    return api.merchant
      .updateMerchantMetadata(this.merchantId, data)
      .catch(() => {
        toast.error('Metadata update failed');
        return $q.reject('');
      })
      .then(() => {
        toast.success('Metadata successfully updated');

        // Update merchant information
        return $q.all([this.setCurrentMerchant(), this.setFlowStatus()]);
      });
  }

  submitFactoringData(data): ng.IPromise<unknown> {
    const { $q, toast, api } = this;

    return api.merchant
      .updateMerchantFactoringMigrationDate(this.merchantId, data)
      .catch(() => {
        toast.error('Factoring Migration Valid date update failed');
        return $q.reject('');
      })
      .then((response: MerchantsMigrationDetails) => {
        if (response.failedMerchants.length) {
          const errorToShow = response.failedMerchants[0].reason;
          toast.error(
            `Factoring Migration Valid date updation failed because ${errorToShow.replace(
              this.merchantId,
              'this merchant'
            )}`
          );
        } else {
          toast.success('Factoring Migration Valid date successfully updated.');
        }

        // Update merchant factoring migration information
        return $q.all([
          this.setCurrentMerchant(),
          this.setFlowStatus(),
          this.setMerchantFactoringMigrationStatus(),
        ]);
      });
  }

  submitExportLang(lang: string): void {
    const { toast } = this;
    this.isExportLangSubmitInProgress = true;

    changeExportLang(this.merchantId, lang)
      .then(() => {
        this.mdExportLang = lang;
        toast.success('Masterdata export language successfully updated');
        // Update requirements check marks
        return this.setFlowStatus();
      })
      .catch(() => {
        toast.error('Masterdata export language update failed');
      })
      .finally(() => {
        this.isExportLangSubmitInProgress = false;
        this.$rootScope.$applyAsync(); // We need to tell angular about the updates because the request is done out of AngularJS's $digest cycle
      });
  }

  private canDisplayFeesOverview(state): boolean {
    const merchant = getMerchant(state);
    const allowedMerchantTypes = () =>
      ['CONNECTED_RETAIL', 'RETAILER', 'SERVICE_PROVIDER'].includes(
        merchant.merchantType
      );
    const merchantHasBeenOnboarded = () =>
      merchant.hasBeenOnboarded || merchant.merchantType === 'CONNECTED_RETAIL';
    const allowedFactoringType = () => merchant.factoring === '2.0';

    const conds: (() => boolean)[] = [
      allowedMerchantTypes,
      allowedFactoringType,
      merchantHasBeenOnboarded,
    ];
    return conds.reduce<boolean>((result, cond) => result && cond(), true);
  }

  private initMDExportLang() {
    return fetchExportLang(this.merchantId)
      .then((lang) => {
        this.mdExportLang = lang;
        this.isExportLangInitialized = true;
      })
      .catch(() => {
        this.toast.error('Masterdata export language initialization failed');
      })
      .finally(() => {
        this.$rootScope.$applyAsync(); // We need to tell angular about the updates because fetching is done out of AngularJS's $digest cycle
      });
  }

  private mapStateToThis(state) {
    const merchantRequirements = getFlowStatus(state);
    const merchantType = getMerchant(state).merchantType;
    const factoringMigrationStatus = getMerchantFactoringMigrationStatus(state);

    return {
      serviceLevel: getMerchant(state).serviceLevel,
      merchantType,
      integrationType: getMerchant(state).integrationType,
      factoring: getMerchant(state).factoring,
      merchantGroupId: getMerchant(state).merchantGroupId,
      merchantId: getMerchant(state).$id,
      supplierCode: getMerchantSupplierCode(state),
      groupOptions: getMerchantGroups(state),
      isMerchantTypeDisabled: getMerchant(state).hasBeenOnboarded,
      isFactoringDisabled:
        !merchantRequirements || !merchantRequirements.factoringChangeable,
      status: getMerchant(state).status,
      migrationStatus: factoringMigrationStatus.status,
      validityDate: factoringMigrationStatus.startDate,
      migrationVersion: factoringMigrationStatus.version,
      isMetadataFeeOverview: this.canDisplayFeesOverview(state),
    };
  }
  private mapDispatchToThis() {
    const { merchantFlowActions, merchantId } = this;

    return {
      setCurrentMerchant: () =>
        merchantFlowActions.setCurrentMerchant(merchantId),
      setFlowStatus: () => merchantFlowActions.setFlowStatus(),
      fetchMerchantGroups: () => merchantFlowActions.setMerchantGroups(),
      setMerchantFactoringMigrationStatus: () =>
        merchantFlowActions.setMerchantFactoringMigrationStatus(),
    };
  }
}
