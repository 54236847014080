import axios from 'axios';
import { pipe } from 'ramda';
import MerchantContact from 'Api/models/MerchantContact';
import ErrorHandler from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';

const contactsApi = axios.create({ baseURL: 'api/merchant-profile/merchants' });

contactsApi.interceptors.request.use(decamelizeRequestHandler);
contactsApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data.items),
  ErrorHandler
);

/**
 *
 * @param businessPartnerId Merchant Id
 */
export function fetchContacts(
  businessPartnerId: string
): Promise<MerchantContact[]> {
  return contactsApi.get(`${businessPartnerId}/contacts`);
}

/**
 * Update contacts
 * @param businessPartnerId Merchant Id
 * @param contacts
 */
export function updateContacts(
  businessPartnerId: string,
  contacts: MerchantContact[]
): Promise<MerchantContact[]> {
  return contactsApi.put(`${businessPartnerId}/contacts`, { items: contacts });
}
