import { MerchantProfile } from 'Api/models/MerchantProfile';
export const MERCHANT_LOADED = 'MERCHANT_LOADED';

export const MERCHANT_LOADING = 'MERCHANT_LOADING';
export const MERCHANT_NAME_LOADING = 'MERCHANT_NAME_LOADING';
export const MERCHANT_CONTACT_LOADING = 'MERCHANT_CONTACT_LOADING';
export const MERCHANT_ADDRESS_LOADING = 'MERCHANT_ADDRESS_LOADING';
export const MERCHANT_BANK_ACCOUNT_LOADING = 'MERCHANT_BANK_ACCOUNT_LOADING';
export const MERCHANT_DATA_LOADING = 'MERCHANT_DATA_LOADING';

export interface MerchantState {
  merchantLoading: boolean;
  nameLoading: boolean;
  contactLoading: boolean;
  addressLoading: boolean;
  bankAccountLoading: boolean;
  dataLoading: boolean;
  data: MerchantProfile;
  error: null;
}

export const merchantInitialState: MerchantState = {
  merchantLoading: false,
  nameLoading: false,
  contactLoading: false,
  addressLoading: false,
  bankAccountLoading: false,
  dataLoading: false,
  data: {} as MerchantProfile,
  error: null,
};

export const merchantReducer = (
  state = { ...merchantInitialState },
  { type, payload }
) => {
  switch (type) {
    case MERCHANT_LOADED:
      return {
        ...state,
        data: payload,
        merchantLoading: false,
        nameLoading: false,
        contactLoading: false,
        addressLoading: false,
        bankAccountLoading: false,
        dataLoading: false,
      };
    case MERCHANT_LOADING:
      return {
        ...state,
        merchantLoading: true,
      };
    case MERCHANT_NAME_LOADING:
      return {
        ...state,
        nameLoading: true,
      };
    case MERCHANT_CONTACT_LOADING:
      return {
        ...state,
        contactLoading: true,
      };
    case MERCHANT_ADDRESS_LOADING:
      return {
        ...state,
        addressLoading: true,
      };
    case MERCHANT_BANK_ACCOUNT_LOADING:
      return {
        ...state,
        bankAccountLoading: true,
      };
    case MERCHANT_DATA_LOADING:
      return {
        ...state,
        dataLoading: true,
      };
    default:
      return state;
  }
};
