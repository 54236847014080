import { mcb } from 'mcb';
import { RetailerEndpoint } from '../endpoints/contract/retailer.endpoint';
import { CommissionEndpoint } from '../endpoints/commission/commission.endpoint';
import { MerchantEndpoint } from '../endpoints/merchant/merchant.endpoint';
import { MerchantStatusEndpoint } from '../endpoints/merchant/merchant-status.endpoint';
import { MerchantAddressEndpoint } from '../endpoints/merchant/merchant-address.endpoint';
import { MerchantBankAccountEndpoint } from '../endpoints/merchant/merchant-bank-account.endpoint';
import { RightsOfWithdrawalEndpoint } from '../endpoints/rights-of-withdrawal/rights-of-withdrawal-legacy.endpoint';
import { ConfigurationEndpoint } from '../endpoints/configuration/configuration.endpoint';
import { ContractEndpoint } from '../endpoints/contract/contract-legacy.endpoint';
import { ContractStatusEndpoint } from '../endpoints/contract/contract-status.endpoint';
import { HttpService } from './http.service';
import { Endpoints } from './endpoints';
import { MerchantFactoringMigrationStatusEndpoint } from '../endpoints/merchant/merchant-factoring-migration-status.endpoint';

export class ApiService {
  static $inject = ['mcpAdminHttpService', 'mcbConfig'];

  public retailer: RetailerEndpoint;
  public commission: CommissionEndpoint;
  public merchant: MerchantEndpoint;
  public merchantStatus: MerchantStatusEndpoint;
  public merchantAddress: MerchantAddressEndpoint;
  public merchantBankAccount: MerchantBankAccountEndpoint;
  public rightsOfWithdrawal: RightsOfWithdrawalEndpoint;
  public configuration: ConfigurationEndpoint;
  public contract: ContractEndpoint;
  public contractStatus: ContractStatusEndpoint;
  public factoringMigrationStatus: MerchantFactoringMigrationStatusEndpoint;
  private endpoints: Endpoints;

  constructor(
    private http: HttpService,
    private mcbConfig: mcb.config.IConfigProvider
  ) {
    const packageConfig = mcbConfig.package('mc-package-admin');
    const merchantApiUrl = packageConfig.get('apiUrl');
    const configurationApiUrl = packageConfig.get('configurationUrl');
    this.endpoints = new Endpoints(merchantApiUrl, configurationApiUrl);

    this.retailer = new RetailerEndpoint(this.http, this.endpoints);
    this.commission = new CommissionEndpoint(this.http, this.endpoints);
    this.merchant = new MerchantEndpoint(this.http, this.endpoints);
    this.merchantStatus = new MerchantStatusEndpoint(this.http, this.endpoints);
    this.merchantAddress = new MerchantAddressEndpoint(
      this.http,
      this.endpoints
    );
    this.merchantBankAccount = new MerchantBankAccountEndpoint(
      this.http,
      this.endpoints
    );
    this.rightsOfWithdrawal = new RightsOfWithdrawalEndpoint(
      this.http,
      this.endpoints
    );
    this.configuration = new ConfigurationEndpoint(this.http, this.endpoints);
    this.contract = new ContractEndpoint(this.http, this.endpoints);
    this.contractStatus = new ContractStatusEndpoint(this.http, this.endpoints);
    this.factoringMigrationStatus =
      new MerchantFactoringMigrationStatusEndpoint(this.http, this.endpoints);
  }
}
