import { Retailer } from '../../models/Retailer';
import { Contract } from '../../models/Contract';
import { Endpoint } from '../../services/endpoint';
import { RequestConfig } from '../../services/http.service';
import { SalesChannelExt } from 'Api/models/SalesChannel';

export interface RetailerToContracts {
  retailerId: string;
  name: string;
  contracts: Contract[];
  channels: SalesChannelExt[];
}

export class RetailerEndpoint extends Endpoint {
  private retailers: Retailer[];

  fetchRetailers(): ng.IPromise<Retailer[]> {
    let url: string = this.endpointsService.getRetailerTemplate();
    let config: RequestConfig = {
      url: url,
      method: 'GET',
      include: ['sales-channels'],
    };
    if (this.retailers) {
      return this.http.when(this.retailers);
    } else {
      return this.http
        .request(config)
        .then((retailers: Retailer[]) => {
          this.retailers = retailers;
          return retailers;
        })
        .catch(() => {
          throw new Error('Cannot load retailers');
        });
    }
  }
}
