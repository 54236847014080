import { VApp } from 'vuetify/lib/components/VApp';
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { staticClass: "root px-10 py-6" },
    [
      _c(
        VSnackbar,
        {
          attrs: { timeout: 5000, color: "reject", bottom: "", right: "" },
          model: {
            value: _vm.showError,
            callback: function ($$v) {
              _vm.showError = $$v
            },
            expression: "showError",
          },
        },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _c("ReviewStatusLegend", { attrs: { title: "Compliance data" } }),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c(VProgressCircular, {
                attrs: { color: "secondaryDark", indeterminate: "" },
              }),
            ],
            1
          )
        : !_vm.showError
        ? _c("div", { staticClass: "px-2 py-4" }, [
            _vm.isSectionRequired(_vm.sectionTypes.FIXED_ESTABLISHMENT_EU)
              ? _c(
                  "div",
                  { attrs: { "data-testid": "fixed-establishment-eu" } },
                  [
                    _vm.euFixedEstablishmentData
                      ? _c(
                          "div",
                          { staticClass: "pb-4" },
                          [
                            _c(
                              "VatReviewSection",
                              {
                                attrs: {
                                  title: _vm.titles.euFixedEstablishment,
                                  address: _vm.euFixedEstablishmentData.address,
                                  "vat-details":
                                    _vm.euFixedEstablishmentData.vat,
                                  status:
                                    _vm.euFixedEstablishmentData.approvalStatus,
                                  "show-address-subtext": true,
                                  "selected-status":
                                    _vm.selectedStatuses.euFixedEstablishment,
                                  "rejection-reason":
                                    _vm.providedRejectionReasons
                                      .euFixedEstablishment,
                                  "enable-review": _vm.isReviewEnabled,
                                  "hide-rejection-option":
                                    !_vm.euFixedEstablishmentData
                                      .hasFixedEstablishment,
                                  "compliance-status": _vm.complianceStatus,
                                },
                                on: {
                                  "on-rejection-reason-change": function (
                                    $event
                                  ) {
                                    return _vm.onRejectionReasonChange(
                                      "euFixedEstablishment",
                                      $event
                                    )
                                  },
                                  "on-status-change": function ($event) {
                                    return _vm.onReviewStatusChange(
                                      "euFixedEstablishment",
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                !_vm.euFixedEstablishmentData
                                  .hasFixedEstablishment
                                  ? _c(
                                      "div",
                                      [
                                        _vm.isEuFixedEstablishmentApproved
                                          ? _c(
                                              "div",
                                              { staticClass: "text-body-1" },
                                              [
                                                _vm._v(
                                                  "Fixed Establishment in EU does not exist"
                                                ),
                                              ]
                                            )
                                          : _c(
                                              VBanner,
                                              {
                                                attrs: {
                                                  icon: "mdi-information-outline",
                                                  "icon-color": "#315adc",
                                                  outlined: "",
                                                  rounded: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Partner does not have a Fixed Establishment in EU. You may need to check and update this information in SAP as their Fixed Establishment status may have changed."
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c("NoDataSection", {
                          attrs: { title: _vm.titles.euFixedEstablishment },
                        }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isSectionRequired(_vm.sectionTypes.OSS)
              ? _c(
                  "div",
                  { attrs: { "data-testid": "oss" } },
                  [
                    _vm.ossVatDetails
                      ? _c(
                          "div",
                          { staticClass: "pb-4" },
                          [
                            _c(
                              "VatReviewSection",
                              {
                                attrs: {
                                  title: _vm.titles.oss,
                                  "vat-details": _vm.ossVatDetails.vat,
                                  status: _vm.ossVatDetails.approvalStatus,
                                  "show-address-subtext": true,
                                  "selected-status": _vm.selectedStatuses.oss,
                                  "rejection-reason":
                                    _vm.providedRejectionReasons.oss,
                                  "enable-review": _vm.isReviewEnabled,
                                  "hide-rejection-option":
                                    !_vm.ossVatDetails.hasOss,
                                  "compliance-status": _vm.complianceStatus,
                                  "vat-group-details":
                                    _vm.ossVatDetails.vatGroup,
                                },
                                on: {
                                  "on-rejection-reason-change": function (
                                    $event
                                  ) {
                                    return _vm.onRejectionReasonChange(
                                      "oss",
                                      $event
                                    )
                                  },
                                  "on-status-change": function ($event) {
                                    return _vm.onReviewStatusChange(
                                      "oss",
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                !_vm.ossData.hasOss
                                  ? _c("div", { staticClass: "text-body-1" }, [
                                      _vm._v(
                                        "Partner does not have One Stop Shop details"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c("NoDataSection", {
                          attrs: { title: _vm.titles.oss },
                        }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isSectionRequired(_vm.sectionTypes.FIXED_ESTABLISHMENT_UK)
              ? _c(
                  "div",
                  { attrs: { "data-testid": "fixed-establishment-uk" } },
                  [
                    _vm.ukFixedEstablishmentData
                      ? _c(
                          "div",
                          { staticClass: "pb-4" },
                          [
                            _c(
                              "VatReviewSection",
                              {
                                attrs: {
                                  title: _vm.titles.ukFixedEstablishment,
                                  address: _vm.ukFixedEstablishmentData.address,
                                  "vat-details":
                                    _vm.ukFixedEstablishmentData.vat,
                                  status:
                                    _vm.ukFixedEstablishmentData.approvalStatus,
                                  "show-address-subtext": true,
                                  "selected-status":
                                    _vm.selectedStatuses.ukFixedEstablishment,
                                  "rejection-reason":
                                    _vm.providedRejectionReasons
                                      .ukFixedEstablishment,
                                  "hide-rejection-option":
                                    !_vm.ukFixedEstablishmentData
                                      .hasFixedEstablishment,
                                  "enable-review": _vm.isReviewEnabled,
                                  "compliance-status": _vm.complianceStatus,
                                },
                                on: {
                                  "on-rejection-reason-change": function (
                                    $event
                                  ) {
                                    return _vm.onRejectionReasonChange(
                                      "ukFixedEstablishment",
                                      $event
                                    )
                                  },
                                  "on-status-change": function ($event) {
                                    return _vm.onReviewStatusChange(
                                      "ukFixedEstablishment",
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                !_vm.ukFixedEstablishmentData
                                  .hasFixedEstablishment
                                  ? _c(
                                      "div",
                                      [
                                        _vm.isUkFixedEstablishmentApproved
                                          ? _c(
                                              "div",
                                              { staticClass: "text-body-1" },
                                              [
                                                _vm._v(
                                                  "Fixed Establishment in UK does not exist"
                                                ),
                                              ]
                                            )
                                          : _c(
                                              VBanner,
                                              {
                                                attrs: {
                                                  icon: "mdi-information-outline",
                                                  "icon-color": "#315adc",
                                                  outlined: "",
                                                  rounded: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Partner does not have a Fixed Establishment in UK. You may need to check and update this information in SAP as their Fixed Establishment status may have changed."
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c("NoDataSection", {
                          attrs: { title: _vm.titles.ukFixedEstablishment },
                        }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isSectionRequired(_vm.sectionTypes.ZFS_VAT)
              ? _c(
                  "div",
                  { attrs: { "data-testid": "zfs-vat" } },
                  [
                    _vm.zfsVatList.length
                      ? _c(
                          "div",
                          { staticClass: "pb-4" },
                          [
                            _c("VatReviewListSection", {
                              attrs: {
                                title: _vm.titles.zfsVat,
                                list: _vm.zfsVatList,
                                "enable-review": _vm.isReviewEnabled,
                                "compliance-status": _vm.complianceStatus,
                              },
                              on: {
                                "on-rejection-reason-change": ({ value, id }) =>
                                  _vm.onRejectionReasonChange(
                                    "zfsVat",
                                    value,
                                    id
                                  ),
                                "on-status-change": ({ value, id }) =>
                                  _vm.onReviewStatusChange("zfsVat", value, id),
                              },
                            }),
                          ],
                          1
                        )
                      : _c("NoDataSection", {
                          attrs: { title: _vm.titles.zfsVat },
                        }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isSectionRequired(_vm.sectionTypes.OUTBOUND_WAREHOUSES)
              ? _c(
                  "div",
                  { attrs: { "data-testid": "outbound-warehouse" } },
                  [
                    _vm.outboundWarehouseList.length
                      ? _c(
                          "div",
                          { staticClass: "pb-4" },
                          [
                            _c("VatReviewListSection", {
                              attrs: {
                                title: _vm.titles.outboundWarehouse,
                                list: _vm.outboundWarehouseList,
                                "enable-review": _vm.isReviewEnabled,
                                "compliance-status": _vm.complianceStatus,
                              },
                              on: {
                                "on-rejection-reason-change": ({ value, id }) =>
                                  _vm.onRejectionReasonChange(
                                    "outboundWarehouse",
                                    value,
                                    id
                                  ),
                                "on-status-change": ({ value, id }) =>
                                  _vm.onReviewStatusChange(
                                    "outboundWarehouse",
                                    value,
                                    id
                                  ),
                              },
                            }),
                          ],
                          1
                        )
                      : _c("NoDataSection", {
                          attrs: { title: _vm.titles.outboundWarehouse },
                        }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showSubmitBtn
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "white--text text-none",
                        attrs: {
                          loading: _vm.isSubmitting,
                          color: "black",
                          disabled: _vm.isSubmitBtnDisabled,
                        },
                        on: { click: _vm.handleSubmit },
                      },
                      [_vm._v("Confirm review of Compliance details")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }