export class NavbarLoadingTrackerComponent implements ng.IComponentOptions {
  static Factory() {
    return new NavbarLoadingTrackerComponent();
  }

  bindings: { [binding: string]: string } = {
    delay: '<',
  };

  controller = NavbarLoadingTrackerController;
  controllerAs = 'ctrl';
  template =
    '<div class="dc-loading-bar" ng-if="ctrl.show">' +
    '<div class="dc-loading-bar__bar"></div>' +
    '<div class="dc-loading-bar__fill"></div>' +
    '</div>';
}

export class NavbarLoadingTrackerController {
  static $inject = ['mcbHttpLoadingTracker', '$timeout', '$scope'];

  show: boolean = true;

  // injected by component
  delay: number;

  constructor(
    private mcbHttpLoadingTracker,
    private $timeout: ng.ITimeoutService,
    private $scope: ng.IScope
  ) {}

  $onInit(): void {
    const { mcbHttpLoadingTracker, $scope, $timeout } = this;

    const delay = angular.isNumber(this.delay)
      ? this.delay
      : mcbHttpLoadingTracker.defaults.delay;

    $scope.$watch(
      () => mcbHttpLoadingTracker.hasPending(),
      (val) => {
        if (val === true) {
          this.show = true;
        } else {
          $timeout(() => (this.show = false), delay);
        }
      }
    );
  }
}
