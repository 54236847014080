export interface UserT {
  id: string;
  name: string;
  email: string;
  fullName: string;
  roles?: string[];
  currentRole: string;
}

export interface TokenT {
  getUser(): UserT;
  getUsername(): string;
  getRoles(): string[];
  getCurrentRole(): string;
  setCurrentRole(string): void;
  getPreferredRole(): string;
  isAuthenticated(): boolean;
}

const anonymousToken = {
  getUser() {
    return null;
  },
  isAuthenticated() {
    return false;
  },
  getUsername() {
    return 'Anonymous';
  },
  getCurrentRole() {
    return 'AnonymousRole';
  },
} as TokenT;
let token = anonymousToken;

export function setToken(value: TokenT): void {
  token = value;
}

export function getToken(): TokenT {
  return token;
}

export function clearToken(): void {
  token = anonymousToken;
}

export function getUser(): UserT {
  return token.getUser();
}

export function getUsername(): string {
  return token.getUsername();
}

export function isAuthenticated(): boolean {
  return token.isAuthenticated();
}
