import toastMessageTemplate from './toast-message.html';

let mcbToastMessageDirective = function mcbToastMessageDirective(
  $timeout,
  $compile,
  mcbToast
) {
  return {
    replace: true,
    transclude: true,
    restrict: 'EA',
    scope: {
      message: '=',
      count: '=',
    },
    controller: [
      '$scope',
      function ($scope) {
        $scope.dismiss = function () {
          mcbToast.dismiss($scope.message.id);
        };
      },
    ],
    template: toastMessageTemplate, // TODO: make it configurable
    link: function (scope, element, attrs, ctrl, transclude) {
      element.attr('data-message-id', scope.message.id);

      let dismissTimeout;
      let scopeToBind = scope.message.compileContent;

      scope.cancelTimeout = function () {
        $timeout.cancel(dismissTimeout);
      };

      scope.startTimeout = function () {
        if (scope.message.dismissOnTimeout) {
          dismissTimeout = $timeout(function () {
            mcbToast.dismiss(scope.message.id);
          }, scope.message.timeout);
        }
      };

      scope.onMouseEnter = function () {
        scope.cancelTimeout();
      };

      scope.onMouseLeave = function () {
        scope.startTimeout();
      };

      if (scopeToBind) {
        let transcludedEl;

        transclude(scope, function (clone) {
          transcludedEl = clone;
          element.children().append(transcludedEl);
        });

        $timeout(function () {
          $compile(transcludedEl.contents())(
            typeof scopeToBind === 'boolean' ? scope.$parent : scopeToBind,
            function (compiledClone) {
              transcludedEl.replaceWith(compiledClone);
            }
          );
        }, 0);
      }

      scope.startTimeout();

      if (scope.message.dismissOnClick) {
        element.bind('click', function () {
          mcbToast.dismiss(scope.message.id);
          scope.$apply();
        });
      }
    },
  };
};

mcbToastMessageDirective.$inject = ['$timeout', '$compile', 'mcbToast'];

export default mcbToastMessageDirective;
