<template lang="pug">
  div.my-3(v-if="!legalEntityId" )
    ReviewSectionTitle(title="Legal Entity Details")

    div.py-3
      div.field
        div.label.text-caption.text-uppercase Legal Entity Name
        div {{merchant.companyName}}

      VatAddress(
        :address="merchant.address"
      )
      div.field
        div.label.text-caption.text-uppercase Vat Id
        div {{merchant.vatId}}
      div.field.mb-3
        div.label.text-caption.text-uppercase Tax Id
        div {{merchant.taxId}}
      v-divider
</template>

<script lang="ts">
import Vue from 'vue';
import ReviewSectionTitle from 'Merchant/common/components/review-section-title/review-section-title.vue';
import { MerchantProfile } from 'Api/models/MerchantProfile';
import AddressReadOnly from 'Merchant/contract/components/return-address/address-read-only.vue';
import VatAddress from 'Merchant/common/components/vat-section/vat-address.vue';
import VatIdDetails from 'Merchant/common/components/vat-section/vat-id-details.vue';

interface StoreStateT {
  legalEntityId: string;
  merchant: MerchantProfile;
}

export default Vue.extend({
  components: { VatIdDetails, VatAddress, AddressReadOnly, ReviewSectionTitle },
  props: {
    shopName: {
      type: String,
      required: false,
      default: 'Not defined',
    },
  },

  data() {
    return {
      // @ts-ignore
      ...(this.mapState({
        merchant: 'flow.general.merchant.data',
        legalEntityId: 'flow.general.merchant.data.legalEntityId',
      }) as StoreStateT),
    };
  },
});
</script>
