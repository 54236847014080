<template lang="pug">
  div
    div.mcp-admin-merchant-details-section__header
      | Billing and Bank details
      ConfirmDataChange(
        :confirm-cb="reason => $emit('change-confirm', reason)"
      )

    BillingDetailsReadonly(
      hide-title
      :billing-mode="billingMode"
      :invoice-language="invoiceLanguage"
    )

    v-divider

    BankAccountReviewList(
      :list="bankAccountList"
      legal-entity-name=""
      hide-sales-channels
      hide-title
      readonly
    )

</template>

<script lang="ts">
import Vue from 'vue';
import ConfirmDataChange from 'Common/components/confirm-data-change/confirm-data-change.vue';
import BillingDetailsReadonly from 'Merchant/common/components/billing-details/billing-details-readonly.vue';
import BankAccountReviewList from 'Merchant/common/components/bank-account-details/bank-account-review-list.vue';
import { BankAccountListItemT } from 'Merchant/common/components/bank-account-details/bank-account-review-item.vue';
import { BillingModeT } from 'Api/models/BillingMode';
import { PreferredLanguageT } from 'Api/models/MerchantProfile';

export default Vue.extend({
  components: {
    BankAccountReviewList,
    BillingDetailsReadonly,
    ConfirmDataChange,
  },

  props: {
    billingMode: {
      type: String as () => BillingModeT,
      required: true,
    },
    invoiceLanguage: {
      type: String as () => PreferredLanguageT,
      required: true,
    },
    bankAccountList: {
      type: Array as () => BankAccountListItemT[],
      required: true,
    },
    merchantId: {
      type: String,
      required: true,
    },
  },
});
</script>
