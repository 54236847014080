<template lang="pug">
  div.vat-review-section
    div.d-flex.justify-space-between.align-center.mb-4
      div.d-flex.align-center
        VatStatusIcon(
          :status="status"
          :manual-review-required="isManualReviewRequired"
        )
        VatSectionTitle(
          :title="title"
          :is-small="isSubSection"
        )
      div(v-if="isStatusSelectorVisible")
        StatusSelector(
          :selected-status="selectedStatus"
          @on-status-select="$emit('on-status-change', $event)"
          :hide-rejection-option="hideRejectionOption"
        )
    div.ml-8.pb-3
        slot
          VatGroupDetails(
            v-if="vatGroupDetails"
            :details="vatGroupDetails"
          )

          LrdDetails(
            v-if="lrdDetails"
            :details="lrdDetails"
          )

          VatAddress(
            v-if="address"
            :address="address"
            :showSubtext="showAddressSubtext"
          )

          VatIdDetails(
            v-if="vatDetails"
            :details="vatDetails"
            :label-prefix="vatDetailsLabelPrefix"
          )

        v-card(
          v-if="isDataRejected"
          outlined
        ).px-4.pt-4
          div.mb-4.text-caption.font-weight-bold Please provide exact reasons for your rejection and the next steps for partners:
          v-textarea(
            no-resize
            outlined
            persistent-hint
            height="80"
            placeholder="Write your reasons and next steps in detail"
            :value="rejectionReason"
            :hint="'Please specify at least '+minWordsCount+' words'"
            v-on:change="$emit('on-rejection-reason-change', $event)"
          )
</template>

<script lang="ts">
import Vue from 'vue';
import VatAddress from 'Merchant/common/components/vat-section/vat-address.vue';
import {
  ValidationResultT,
  VatAddressT,
  VatDetailsT,
  VatGroupDetailsT,
} from 'Api/models/Vat';
import VatSectionTitle from 'Merchant/common/components/review-section-title/review-section-title.vue';
import StatusSelector from 'Merchant/common/components/review-status-selector/review-status-selector.vue';
import VatIdDetails from 'Merchant/common/components/vat-section/vat-id-details.vue';
import VatStatusIcon from 'Merchant/common/components/review-status-icon/review-status-icon.vue';
import { REJECTION_REASON_MIN_WORD_COUNT } from 'Merchant/general/components/company-compliance-details/util';
import VatGroupDetails from 'Merchant/common/components/vat-section/vat-group-details.vue';
import { LrdDetailsT } from 'Api/models/OutboundWarehouse';
import LrdDetails from 'Merchant/common/components/vat-section/lrd-details.vue';
import { ApprovalStatusT } from 'Api/models/ApprovalStatus';

export default Vue.extend({
  components: {
    LrdDetails,
    VatStatusIcon,
    VatIdDetails,
    StatusSelector,
    VatSectionTitle,
    VatAddress,
    VatGroupDetails,
  },
  props: {
    isSubSection: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    address: {
      type: [Object as () => VatAddressT, null],
      required: false,
      default: null,
    },
    vatDetails: {
      type: Object as () => VatDetailsT,
      required: false,
      default: null,
    },
    status: {
      type: String as () => ApprovalStatusT,
      required: true,
    },
    showAddressSubtext: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedStatus: {
      type: String as () => ApprovalStatusT,
      required: false,
      default: '',
    },
    rejectionReason: {
      type: String,
      required: false,
      default: '',
    },
    enableReview: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideRejectionOption: {
      type: Boolean,
      required: false,
      default: false,
    },
    vatGroupDetails: {
      type: Object as () => VatGroupDetailsT,
      required: false,
      default: null,
    },
    lrdDetails: {
      type: Object as () => LrdDetailsT,
      required: false,
      default: null,
    },
    complianceStatus: {
      type: String as () => ApprovalStatusT,
      required: true,
    },
  },

  data() {
    return {
      minWordsCount: REJECTION_REASON_MIN_WORD_COUNT,
      isStatusSelectorVisible:
        this.enableReview &&
        this.status === ApprovalStatusT.IN_REVIEW &&
        this.complianceStatus === ApprovalStatusT.IN_REVIEW,
    };
  },

  computed: {
    isDataRejected(): boolean {
      return this.selectedStatus === ApprovalStatusT.REJECTED;
    },
    vatDetailsLabelPrefix(): string {
      return this.vatGroupDetails?.belongsToVatGroup ? 'Group' : '';
    },
    isManualReviewRequired(): boolean {
      const manualReviewStatuses = [
        ValidationResultT.INVALID,
        ValidationResultT.NOT_VALIDATED,
      ];

      return (
        manualReviewStatuses.includes(this.address?.validationResult) ||
        manualReviewStatuses.includes(this.vatDetails?.validationResult)
      );
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep .field {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}

::v-deep .label {
  width: 191px;
  font-weight: 700;
}
</style>
