export const DELIVERY_DESTINATIONS = {
  DIRECT_DELIVERY_DESTINATION: 'Home',
  PICKUP_POINT: 'Pick up Point',
  PARCEL_LOCKER: 'Packstation',
};

export const OPTIONAL_CAPABILITIES = {
  CASH_ON_DELIVERY: 'Cash on Delivery',
  SECURE_DELIVERY: 'Secure Delivery',
};

export const PARCEL_SERVICES = {
  EXPRESS: 'Express',
  SAME_DAY_DELIVERY: 'Same day Delivery',
  STANDARD: 'Standard',
  DIRECT: 'Direct',
  NORMAL: 'Normal',
  DELIVERY_REINJECTION: 'Delivery Reinjection',
  DELIVERY_RETRY: 'Delivery Retry',
  PRIO: 'Priority',
  EVENING: 'Evening',
};
