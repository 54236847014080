import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { staticClass: "root", attrs: { id: "contract-overview" } },
    [
      _c(
        VContainer,
        [
          _c("ContractOverview", {
            attrs: {
              partners: _vm.contract.partners,
              "merchant-id": _vm.merchant.$id,
              "channel-id": _vm.contract.salesChannel.$id,
              "fulfillment-type": _vm.contract.fulfillmentType,
              "launch-checks": _vm.contractTimeline,
              "is-live": _vm.contract.live,
              "is-channel-live": _vm.isChannelLive,
              "live-status-modified-at": _vm.contract.liveStatusModifiedAt,
              "live-status-modified-by": _vm.contract.liveStatusModifiedBy,
              "offline-reason": _vm.contract.offlineReason,
              "has-been-launched": _vm.arePartnersLaunched,
              "is-launch-enabled": _vm.canContractBeLaunched,
              "can-edit": _vm.canEdit,
              "country-name": _vm.contract.salesChannel.countryName,
            },
            on: { "contract-launch": _vm.launch },
          }),
          _vm.arePartnersLaunched
            ? _c("Stocks", {
                attrs: {
                  "merchant-id": _vm.merchant.$id,
                  "channel-id": _vm.contract.salesChannel.$id,
                  "fulfillment-type": _vm.contract.fulfillmentType,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }