// eslint-disable-next-line @typescript-eslint/naming-convention
export type TIMELINE_STATUS_T =
  | 'COMPLETED'
  | 'PENDING'
  | 'IN_PROGRESS'
  | 'FAILED'
  | 'ON_HOLD'
  | 'LOADING';

export const TIMELINE_ELEMENT_STATUSES: Record<string, TIMELINE_STATUS_T> = {
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  ON_HOLD: 'ON_HOLD',
  LOADING: 'LOADING',
};
