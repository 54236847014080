<template lang="pug">
  div
    div.field
      div.label.text-caption.text-uppercase Belongs to Vat group?
      div(v-if="details.belongsToVatGroup") Yes
      div(v-else) No

    div.field(v-if="details.name")
      div.label.text-caption.text-uppercase Group Vat Name
      div {{details.name}}

    div.field(v-if="details.certificateId")
      div.label.text-caption.text-uppercase Group Vat Proof
      VatCertificate(:certificate-id="details.certificateId")
</template>

<script lang="ts">
import Vue from 'vue';
import { VatGroupDetailsT } from 'Api/models/Vat';
import VatCertificate from 'Merchant/common/components/vat-section/vat-certificate.vue';

export default Vue.extend({
  components: { VatCertificate },
  props: {
    details: {
      type: Object as () => VatGroupDetailsT,
      required: true,
    },
  },
});
</script>
