import { mcb } from 'mcb';
import coreModule from 'Common/core.module';
import { MCP_ADMIN_ROLES } from 'Common/constants/roles.constant';
import wrapVue from 'Common/wrap-vue.converter';
import MerchantAccounts from './merchant-accounts-container.vue';

export default angular
  .module('mcp.admin.merchant-accounts', ['ui.router', coreModule.name])

  .config([
    '$stateProvider',
    ($stateProvider: angular.ui.IStateProvider): void => {
      $stateProvider.state('mcp.admin.merchant-accounts', {
        url: '/merchant-accounts',
        template: `<mcp-merchant-accounts></mcp-merchant-accounts>`,
        firewalls: {
          iam: {
            roles: [
              MCP_ADMIN_ROLES.INTEGRATION_KEY_ACCOUNT_MANAGER,
              MCP_ADMIN_ROLES.STOCK_MANAGER,
              MCP_ADMIN_ROLES.SUPER_ADMIN,
            ],
          },
        },
      } as mcb.firewall.IMcbState);
    },
  ])
  .component(
    'mcpMerchantAccounts',
    wrapVue(MerchantAccounts, 'mcpMerchantAccounts')
  );
