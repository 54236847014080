import { ICountry } from 'mcb';
import template from './country-label.html';
import { COUNTRIES } from '../../constants/misc.constant';

export class CountryLabelComponent implements ng.IComponentOptions {
  static Factory() {
    return new CountryLabelComponent();
  }

  bindings: { [binding: string]: string } = {
    code: '@',
  };

  controller = MerchantStatusController;
  template: string = template;
}

class MerchantStatusController {
  static $inject = [];

  $onInit() {
    // Empty block
  }

  getCountryNameByCode(code: string): string {
    let results = COUNTRIES.filter(
      (country: ICountry) => country.code === code
    );
    if (results.length) {
      return results[0].name;
    }
    return 'Unknown';
  }
}
