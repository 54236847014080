import { FactoringTypeT } from '@/api/models/MerchantProfile';

export type FactoringTypeOptionT = {
  value: FactoringTypeT | '';
  text: string;
};

export const MCP_FACTORING_TYPE_OPTIONS: FactoringTypeOptionT[] = [
  { value: '', text: 'All' },
  { value: '1.0', text: '1.0' },
  { value: '2.0', text: '2.0' },
];
export const MCP_FACTORING_TYPE_OPTIONS_WITHOUT_ALL =
  MCP_FACTORING_TYPE_OPTIONS.filter((el) => el.value);
