import axios from 'axios';
import { pipe } from 'ramda';
import { MarketBillingDataT } from 'Api/models/BillingData';
import ErrorHandler from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';

const billingApi = axios.create({ baseURL: 'api/merchant-profile/merchants' });

billingApi.interceptors.request.use(decamelizeRequestHandler);
billingApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

export function fetchBillingDetails(
  merchantId: string,
  salesChannelId: string
): Promise<MarketBillingDataT> {
  return billingApi.get(
    `${merchantId}/sales-channels/${salesChannelId}/billing-details`
  );
}

export function useMerchantBillingDetails(
  merchantId: string,
  salesChannelId: string
): Promise<MarketBillingDataT> {
  return billingApi.post(
    `${merchantId}/sales-channels/${salesChannelId}/billing-details/use-merchant`
  );
}

export function updateBillingDetails(
  merchantId: string,
  salesChannelId: string,
  billingData: MarketBillingDataT
): Promise<MarketBillingDataT> {
  return billingApi.put(
    `${merchantId}/sales-channels/${salesChannelId}/billing-details`,
    billingData
  );
}
