/**
 * @ngdoc service
 * @name mcbHttpTransformers
 */
let httpTransformers = function httpTransformers($log, mcbHumps) {
  /**
   * @param {String|*} object
   * @returns {Object|*}
   */
  function fromJson(object) {
    if (object && angular.isString(object)) {
      try {
        return angular.fromJson(object);
      } catch (e) {
        $log.error(e);
        return object;
      }
    }
    return object;
  }

  /**
   * @param {Object} object
   * @returns {String}
   */
  function toJson(object) {
    return angular.toJson(object);
  }

  /**
   * @param {Object|*} object
   * @returns {Object|*}
   */
  function camelize(object) {
    try {
      if (angular.isObject(object)) {
        return mcbHumps.camelizeKeys(object);
      }
      return object;
    } catch (e) {
      $log.warn('camelize the response data fails with', e, object);
      return object;
    }
  }

  /**
   * @param {Object|*} object
   * @returns {Object|*}
   */
  function decamelize(object) {
    if (angular.isObject(object)) {
      let copy = angular.copy(object);
      if (angular.isDefined(copy.$promise)) {
        delete copy.$promise;
      } // delete $promise to prevent humps infinite loop
      if (angular.isDefined(copy.$resolved)) {
        delete copy.$resolved;
      } // delete $resolved ngResource special property
      return mcbHumps.decamelizeKeys(copy);
    }
    return object;
  }

  /**
   * @param {Object} dejsonapifiedObject
   * @returns {Object}
   */
  function jsonapifyObject(dejsonapifiedObject) {
    let jsonapifiedObject = angular.copy(dejsonapifiedObject);
    ['links', 'relationships', 'id', 'type', 'meta'].forEach(function (prop) {
      if (jsonapifiedObject[prop]) {
        jsonapifiedObject['$' + prop] = jsonapifiedObject[prop];
        delete jsonapifiedObject[prop];
      }
    });

    if (jsonapifiedObject.attributes) {
      Object.keys(jsonapifiedObject.attributes).forEach(function (attr) {
        jsonapifiedObject[attr] = jsonapifiedObject.attributes[attr];
      });
      delete jsonapifiedObject.attributes;
    }
    return jsonapifiedObject;
  }

  /**
   * @param {Object|*} dejsonapifiedObject
   * @returns {Object|*}
   */
  function jsonapify(dejsonapifiedObject) {
    if (!angular.isObject(dejsonapifiedObject)) {
      return dejsonapifiedObject;
    }
    let dejsonapifiedObjectCopy = angular.copy(dejsonapifiedObject);
    if (
      dejsonapifiedObjectCopy.data &&
      angular.isArray(dejsonapifiedObjectCopy.data)
    ) {
      dejsonapifiedObjectCopy.data =
        dejsonapifiedObjectCopy.data.map(jsonapifyObject);
    }
    if (
      dejsonapifiedObjectCopy.data &&
      angular.isObject(dejsonapifiedObjectCopy.data)
    ) {
      dejsonapifiedObjectCopy.data = jsonapifyObject(
        dejsonapifiedObjectCopy.data
      );
    }
    let jsonapifiedObject =
      dejsonapifiedObjectCopy.data || dejsonapifiedObjectCopy.errors;

    if (dejsonapifiedObjectCopy.data) {
      ['links', 'meta', 'included'].forEach(function (prop) {
        if (dejsonapifiedObjectCopy[prop]) {
          jsonapifiedObject['$' + prop] = dejsonapifiedObjectCopy[prop];
        }
      });
    }
    return jsonapifiedObject;
  }

  function dejsonapifyObject(jsonapifiedObject) {
    let jsonapifiedObjectCopy = angular.copy(jsonapifiedObject);
    let dejsonapifiedObject = {
      attributes: {},
    };
    ['$links', '$meta', '$id', '$type', '$relationships'].forEach(function (
      prop
    ) {
      if (jsonapifiedObjectCopy[prop]) {
        dejsonapifiedObject[prop.substring(1)] = jsonapifiedObjectCopy[prop];
        delete jsonapifiedObjectCopy[prop];
      }
    });
    Object.keys(jsonapifiedObjectCopy).forEach(function (attribute) {
      dejsonapifiedObject.attributes[attribute] =
        jsonapifiedObjectCopy[attribute];
    });
    return dejsonapifiedObject;
  }

  function dejsonapify(jsonapifiedObject) {
    let dejsonapifiedObject = { data: {} };

    if (angular.isArray(jsonapifiedObject)) {
      ['$links', '$meta', '$included'].forEach(function (prop) {
        if (jsonapifiedObject[prop]) {
          dejsonapifiedObject[prop.substring(1)] = angular.copy(
            jsonapifiedObject[prop]
          );
        }
      });
      dejsonapifiedObject.data = jsonapifiedObject.map(function (r) {
        return dejsonapifyObject(r);
      });
      return dejsonapifiedObject;
    }

    if (angular.isObject(jsonapifiedObject)) {
      let jsonapifiedObjectCopy = angular.copy(jsonapifiedObject);
      if (jsonapifiedObjectCopy.$included) {
        // @ts-ignore
        dejsonapifiedObject.included = angular.copy(
          jsonapifiedObjectCopy.$included
        );
        delete jsonapifiedObjectCopy.$included;
      }
      dejsonapifiedObject.data = dejsonapifyObject(jsonapifiedObjectCopy);
      return dejsonapifiedObject;
    }
  }

  function jsonapiToSimpleIsValidObject(resource) {
    return angular.isObject(resource.attributes);
  }

  function jsonapiToSimpleObject(resource) {
    let simple = {
      id: resource.id,
      type: resource.type,
    };
    Object.keys(resource.attributes).forEach(function (attrKey) {
      simple[attrKey] = resource.attributes[attrKey];
    });
    return simple;
  }

  function jsonapiToSimple(object) {
    if (!object || !object.data) {
      return object;
    }

    if (angular.isArray(object.data)) {
      return object.data.map(jsonapiToSimpleObject);
    }

    if (
      angular.isObject(object.data) &&
      jsonapiToSimpleIsValidObject(object.data)
    ) {
      return jsonapiToSimpleObject(object.data);
    }

    return object;
  }

  // public api
  return {
    fromJson: fromJson,
    toJson: toJson,
    camelize: camelize,
    decamelize: decamelize,
    jsonapify: jsonapify,
    dejsonapify: dejsonapify,
    jsonapiToSimple: jsonapiToSimple,
  };
};

httpTransformers.$inject = ['$log', 'mcbHumps'];

export default httpTransformers;
