import breadcrumbsTemplate from './breadcrumbs.html';

export class BreadcrumbsComponentController implements ng.IController {
  breadcrumbs: any;
  backBreadcrumb: any;

  /**
   * @param {BreadcrumbsStore} breadcrumbsStore
   */
  constructor(breadcrumbsStore) {
    this.breadcrumbs = breadcrumbsStore.getBreadcrumbs();

    let onBreadcrumbsUpdateHandler = (breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;

      if (this.breadcrumbs.length < 2) {
        return;
      }

      this.backBreadcrumb = this.breadcrumbs[this.breadcrumbs.length - 2];
    };

    breadcrumbsStore.onBreadcrumbsUpdate(onBreadcrumbsUpdateHandler);

    this.$onDestroy = () => {
      breadcrumbsStore.offBreadcrumbsUpdate(onBreadcrumbsUpdateHandler);
    };
  }

  $onDestroy() {
    // Empty block
  }
}

BreadcrumbsComponentController.$inject = ['mcbBreadcrumbsStore'];

/**
 * @ngdoc component
 * @name mcbBreadcrumbs
 * @module mcb.components.breadcrumbs
 */
let breadcrumbsComponent = {
  template: breadcrumbsTemplate,
  controller: BreadcrumbsComponentController,
};

export default breadcrumbsComponent;
