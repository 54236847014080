import template from './highlight-substring.html';

export class HighlightSubstringComponent implements ng.IComponentOptions {
  static Factory() {
    return new HighlightSubstringComponent();
  }

  bindings: { [binding: string]: string } = {
    text: '@',
    match: '@',
  };

  controller = HighlightSubstringController;
  template: string = template;
}

class HighlightSubstringController {
  static $inject = ['mcbUtil'];

  // injected by binding
  text: string;
  match: string;

  highlightedText = '';

  constructor(private utils) {
    // empty
  }

  $onChanges(): void {
    this.highlightedText = this.highlightMatch();
  }

  private highlightMatch(): string {
    const text = this.text;
    const match = this.match;
    if (!match || match === '') {
      return text;
    }
    const escapedMatch = this.utils.escapeRegExp(match);
    const regExp = new RegExp(`(${escapedMatch})`, 'gi');

    return text.replace(
      regExp,
      `<span class="highlighted-substring">$1</span>`
    );
  }
}
