<template>
  <div
    id="name-autocomplete-area"
    class="mcp-admin-register-suggestions-area mcp-admin__form-field"
  >
    <label
      class="dc-label dc-label--required"
      :class="{ 'dc-label--is-error': !value && isSubmitted }"
      :for="label"
      >{{ label }}</label
    >
    <div
      v-if="!value && isSubmitted"
      class="mcp-admin__field-error-message"
      role="alert"
    >
      <span class="dc--text-error">Please don't leave this empty.</span>
    </div>
    <input
      :id="label"
      v-model="value"
      class="dc-input dc-input--block"
      type="text"
      autocomplete="off"
      :class="{ 'dc-input--is-error': !value && isSubmitted }"
      :placeholder="placeholder"
      required
      @input="$emit('input', $event.target.value)"
      @focus="$emit('input', value)"
      @blur="$emit('blur')"
    />

    <div v-if="merchants.length > 0" class="mcp-admin-register-suggestions">
      <div class="dc-row mcp-admin-register-suggestions-help">
        <div class="dc-column dc-column--small-12">
          Merchants with similar shop name, click to check the details:
        </div>
      </div>
      <div class="dc-row mcp-admin-register-suggestions-header">
        <div class="dc-column dc-column--small-6">Legal name</div>
        <div class="dc-column dc-column--small-6">Shop name</div>
      </div>

      <a
        v-for="merchant in merchants"
        :key="merchant.id"
        class="dc-link"
        target="_blank"
        :href="
          $root.getUrl('mcp.admin.merchant.general.company-details', {
            merchantId: merchant.id,
          })
        "
      >
        <div
          data-testid="register-suggestions-item"
          class="dc-row mcp-admin-register-suggestions-item"
        >
          <div class="dc-column dc-column--small-6">
            <mp-highlight-substring
              :text="merchant.companyName"
              :match="value"
              dresscode
            />
          </div>
          <div class="dc-column dc-column--small-6">
            <mp-highlight-substring
              :text="merchant.name"
              :match="value"
              dresscode
            />
          </div>
        </div>
      </a>

      <div
        v-if="additionalMerchantsCount > 0"
        class="dc-row mcp-admin-register-suggestions-show-more"
      >
        <div class="dc-column dc-column--small-12">
          <a
            :href="$root.getUrl('mcp.admin.merchants', { search: value })"
            target="_blank"
            class="dc-link"
            >Show {{ additionalMerchantsCount }} more matches</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'MerchantRegisterInput',
  props: {
    merchants: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    isSubmitted: {
      type: Boolean,
    },
    additionalMerchantsCount: {
      type: Number,
    },
  },
  data() {
    return {
      value: '',
    };
  },
});
</script>
