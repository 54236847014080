import { VApp } from 'vuetify/lib/components/VApp';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(VApp, [
    _c(
      "div",
      {
        staticClass: "mcp-admin-register mx-auto",
        staticStyle: { "max-width": "800px", "min-width": "600px" },
      },
      [
        _c(VBreadcrumbs, {
          staticClass: "ml-n5",
          attrs: { items: _vm.breadcrumbItems },
        }),
        _vm._v(" "),
        _c(
          VCard,
          [
            _c(VCardTitle, [_vm._v("Create Connected Retail Merchant")]),
            _vm._v(" "),
            _c(VCardText, [
              _c(
                "form",
                { attrs: { novalidate: "" } },
                [
                  _c("input-component", {
                    attrs: {
                      merchants: _vm.shopNameSuggestions,
                      label: `SHOP NAME`,
                      placeholder: `e.g. Zalando`,
                      "is-submitted": _vm.isSubmitted,
                      "additional-merchants-count":
                        _vm.additionalMerchantsCount,
                    },
                    on: {
                      input: _vm.handleShopNameChange,
                      blur: _vm.resetSuggestions,
                    },
                  }),
                  _vm._v(" "),
                  _c("input-component", {
                    staticClass: "mt-2",
                    attrs: {
                      merchants: _vm.nameSuggestions,
                      label: `LEGAL NAME`,
                      placeholder: `e.g. Zalando SE`,
                      "is-submitted": _vm.isSubmitted,
                      "additional-merchants-count":
                        _vm.additionalMerchantsCount,
                    },
                    on: {
                      input: _vm.handleLegalNameChange,
                      blur: _vm.resetSuggestions,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "dc-label dc-label--required",
                          attrs: { for: "factoring-type" },
                        },
                        [_vm._v("\n              FACTORING TYPE\n            ")]
                      ),
                      _vm._v(" "),
                      _c(VSelect, {
                        attrs: {
                          id: "factoring-type",
                          items: _vm.factoringTypes,
                        },
                        model: {
                          value: _vm.selectedFactoringType,
                          callback: function ($$v) {
                            _vm.selectedFactoringType = $$v
                          },
                          expression: "selectedFactoringType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-end pb-4 px-4" },
              [
                _c(
                  VBtn,
                  {
                    staticClass: "primary",
                    attrs: { loading: _vm.isSubmitDisabled },
                    on: { click: _vm.handleSubmit },
                  },
                  [_vm._v("\n          Save\n        ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }