import { MCP_MERCHANT_STATUSES } from 'Common/constants/merchant-statuses.constant';
import dialogTemplate from '../views/confirm-merchant-status-change-dialog.html';
import taxVatdialogTemplate from '../views/confirm-tax-vat-destroy-dialog.html';
import { DialogService } from 'Common/services/dialog.service';
import { Store } from 'redux';
import { FlowState } from '../state/flow.state';
import { FlowStateSelector } from '../state/selectors/flow-state.selector';

export class MerchantService {
  static $inject = [
    '$q',
    '$ngRedux',
    'mcpAdminFlowStateSelector',
    'mcpAdminDialogService',
  ];

  constructor(
    private q: ng.IQService,
    private store: Store<FlowState>,
    private flowSelector: FlowStateSelector,
    private dialogService: DialogService
  ) {}

  confirmMerchantStatusChangeToInProgress(): ng.IPromise<void> {
    const { q, dialogService } = this;

    const merchant = this.flowSelector.getFlowMerchant(this.store.getState());

    if (merchant.status === MCP_MERCHANT_STATUSES.IN_PROGRESS) {
      return q.when();
    }

    return dialogService.openConfirmationDialog(dialogTemplate);
  }

  /**
   * On Country change we need the user to confirm removing TaxId/VatId
   *
   * It makes to display the confirmation window only in case
   * the country was changed and at least one of the fields taxId and vatId is not empty
   *
   * VatId and TaxId should be cleared out on country change because
   * their validation directly depends on the merchant country.
   * @param country     New country code
   * @param oldCountry  Current country code. Empty string if address doesn't exist
   */
  confirmVatTaxDestroy(country: string, oldCountry: string): ng.IPromise<void> {
    const merchant = this.flowSelector.getFlowMerchant(this.store.getState());

    if (country === oldCountry) {
      return this.q.when();
    }

    if (!merchant.vatId && !merchant.taxId) {
      return this.q.when();
    }

    return this.dialogService.openConfirmationDialog(taxVatdialogTemplate);
  }
}
