<template lang="pug">
  v-card.pa-6
    v-snackbar(v-model="showMessage" :timeout="5000" color="reject" bottom right)
      | {{message}}

    v-form(
      ref="billingAndBankForm"
      lazy-validation
    )
      v-radio-group.mt-0(v-model="updatedBillingMode" row @change="handleBillingModeChange")
        v-row
          v-col.pb-0(md=12)
            h3 Billing Mode
          v-col(md=3)
            v-radio(
              :label="billingModeOptions.LOCAL_CURRENCY_MODE | formatBillingMode"
              :value="billingModeOptions.LOCAL_CURRENCY_MODE"
            )
          v-col(md="3")
            v-radio(
              :label="billingModeOptions.EURO_MODE | formatBillingMode"
              :value="billingModeOptions.EURO_MODE"
            )

      v-radio-group.mt-0(
        v-model="updatedInvoiceLanguage"
        :rules="[$options.filters.formFieldRequired]"
        row
      )
        v-row
          v-col.pb-0(md=12)
            h3 Invoice Language
          v-col(md=3)
            v-radio(
              :label="invoiceLanguageOptions.English | formatInvoiceLanguage"
              :value="invoiceLanguageOptions.English"
            )
          v-col(md=3)
            v-radio(
              :label="invoiceLanguageOptions.German | formatInvoiceLanguage"
              :value="invoiceLanguageOptions.German"
            )


      div.my-4
        v-divider

      h3.mb-3 Bank Account Details
      div(v-for="bankAccount in updatedBankAccountList")
        BankAccountFormSection(
          :bank-account="bankAccount"
          @on-change="handleBankAccountFieldChange"
        )
        div.my-4
          v-divider

      div.d-flex.justify-end
        v-btn.mr-4(
          depressed
          @click="$emit('on-close')"
        ) Cancel

        v-btn(
          color="primary"
          depressed
          :loading="isSubmitting"
          @click="onSave"
        ) Save

</template>

<script lang="ts">
import Vue from 'vue';
import handleFormSubmit from 'Merchant/common/components/billing-and-bank-details-form/handleFormSubmit';
import { BillingModeT } from 'Api/models/BillingMode';
import { PreferredLanguageT } from 'Api/models/MerchantProfile';
import { BankAccountInfoT } from 'Api/models/BankAccount';
import BankAccountFormSection from 'Merchant/common/components/billing-and-bank-details-form/bank-account-form-section.vue';
import { clone } from 'ramda';
import { getBankAccountsForBillingMode } from 'Merchant/common/components/billing-and-bank-details-form/util';
import { CurrencySalesChannelT } from 'Api/models/CurrencySalesChannel';

const UPDATE_ERROR_MESSAGE = 'Failed to update data';

export default Vue.extend({
  components: {
    BankAccountFormSection,
  },

  props: {
    billingMode: {
      type: String as () => BillingModeT,
      required: true,
    },
    invoiceLanguage: {
      type: String as () => PreferredLanguageT,
      required: true,
    },
    bankAccountList: {
      type: Array as () => BankAccountInfoT[],
      required: true,
    },
    currencySalesChannels: {
      type: Array as () => CurrencySalesChannelT[],
      required: true,
    },
    merchantId: {
      type: String,
      required: true,
    },
    legalEntityName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showMessage: false,
      message: '',
      isSubmitting: false,
      updatedBillingMode: '',
      updatedInvoiceLanguage: '',
      updatedBankAccountList: [] as BankAccountInfoT[],
      billingModeOptions: BillingModeT,
      invoiceLanguageOptions: PreferredLanguageT,
    };
  },

  mounted() {
    this.updatedBillingMode = this.billingMode;
    this.updatedInvoiceLanguage = this.invoiceLanguage;

    this.resetBankAccountsByCurrencyMode();
  },

  methods: {
    onSave: async function (): Promise<void> {
      if (this.$refs.billingAndBankForm.validate()) {
        try {
          this.isSubmitting = true;
          await handleFormSubmit({
            merchantId: this.merchantId,
            existingData: {
              billingMode: this.billingMode,
              invoiceLanguage: this.invoiceLanguage,
              bankAccountList: this.bankAccountList,
            },
            updatedData: {
              billingMode: this.updatedBillingMode,
              invoiceLanguage: this.updatedInvoiceLanguage,
              bankAccountList: this.updatedBankAccountList,
            },
          });
          this.$emit('on-success');
        } catch (e) {
          this.showSnackbar(`${UPDATE_ERROR_MESSAGE}: ${e.errorMessage}`);
        } finally {
          this.isSubmitting = false;
        }
      }
    },
    resetBankAccountsByCurrencyMode(): void {
      this.updatedBankAccountList = clone(
        getBankAccountsForBillingMode({
          billingMode: this.updatedBillingMode,
          bankAccounts: this.bankAccountList,
          currencySalesChannels: this.currencySalesChannels,
          legalEntityName: this.legalEntityName,
        })
      );
    },
    handleBankAccountFieldChange(
      modifiedBankCurrencyCode: string,
      field: string,
      value: string
    ) {
      const currencyCodes = this.updatedBankAccountList.map(
        ({ currencyCode }: BankAccountInfoT) => currencyCode
      );

      const indexOfBankAccount = currencyCodes.indexOf(
        modifiedBankCurrencyCode
      );

      this.$set(this.updatedBankAccountList[indexOfBankAccount], field, value);
    },
    showSnackbar(message: string): void {
      this.showMessage = true;
      this.message = message;
    },
    handleBillingModeChange(): void {
      this.resetBankAccountsByCurrencyMode();
    },
  },
});
</script>
