export default function resourceMergeTransformers() {
  function isRequestKey(key) {
    return key === 'transformRequest';
  }

  function $$mcbResourceMergeTransformers(action, defaultTransformers, key) {
    if (!action[key]) {
      action[key] = defaultTransformers;
      return;
    }
    if (angular.isFunction(action[key])) {
      action[key] = isRequestKey(key)
        ? [action[key]].concat(defaultTransformers)
        : defaultTransformers.concat(action[key]);
      return;
    }

    if (angular.isArray(action[key])) {
      action[key] = isRequestKey(key)
        ? action[key].concat(defaultTransformers)
        : defaultTransformers.concat(action[key]);
    }
  }

  $$mcbResourceMergeTransformers.request = function (
    action,
    defaultTransformers
  ) {
    return $$mcbResourceMergeTransformers(
      action,
      defaultTransformers,
      'transformRequest'
    );
  };

  $$mcbResourceMergeTransformers.response = function (
    action,
    defaultTransformers
  ) {
    return $$mcbResourceMergeTransformers(
      action,
      defaultTransformers,
      'transformResponse'
    );
  };

  return $$mcbResourceMergeTransformers;
}
