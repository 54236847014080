<template lang="pug">
v-select(
  :items="items"
  :value="value"
  :disabled="disabled"
  height="40px"
  background-color="secondaryLight"
  hide-details
  ref="select"
  @change="change"
)
  template(v-slot:prepend)
    v-container.prepended(fill-height)
      span.caption.secondary--text {{ label }}
  template(v-slot:selection="{ item }")
    span.selection(:class="{ 'secondary--text': !disabled, 'disabled--text': disabled }") {{ item.text }}
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'FilterbarSelect',
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change(event): void {
      this.$emit('change', event);
    },
  },
});
</script>

<style lang="scss" scoped>
.container {
  height: 32px;
  align-items: center;
}

.prepended {
  padding-right: 7px;
  padding-left: 0;
  margin-top: -6px;
  white-space: nowrap;
}

.selection {
  font-size: 14px;
  padding-left: 8px;
}
</style>
