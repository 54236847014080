<template lang="pug">
v-app
  Appbar(hide-tabs)
    BackLink.back-link(
      v-if="merchantAccount"
      href="/#/merchant-accounts"
      )
      | {{ merchantAccount.name }}

  v-main(style="background-color: backgroundColor")
    //- Error message
    v-snackbar(v-model="hasError" :timeout="-1" bottom color="reject")
      | {{ this.errorMessage }}
      a.text-center(v-if="error && error.backLink" :href="error.backLink")
        v-btn.ml-1(text) Back
      v-btn.text-center.ml-1(text @click="error = null") Close
    //- suucess message
    v-snackbar(
      v-if="notify === 'INVITE_SENT_SUCCESS'"
      v-model="notify"
      color="success"
      bottom
      :timeout="2000"
    )
      | zDirect invite has been sent successfully.
      v-btn.text-center.ml-1(text @click="notify = null") close

    v-container(v-if="loading")
      PageLoader

    v-flex(v-else d-flex fill-height)
      SidePanel(
        :merchantAccount="merchantAccount"
        :selectedLegalEntityId="selectedLegalEntityId"
        )
      v-divider(vertical)

      v-container.px-6.pt-6
        mp-alert(type="info")
          | Legal entities cannot be modified or deleted after zDirect invite has been sent.

        mp-alert(
          v-if="!invitationRequirements.requirementsCompleted && !isInvitationSent"
          type="info"
        )
          span Invitation cannot be sent because the below merchant/s are missing certain configurations. Please find below the required information:
          ul
            li(v-for="merchant in incompleteMerchants" :key="merchant.businessPartnerId")
              a(:href="getMerchantUrl(merchant.businessPartnerId)") {{merchant.name}}
              span : {{getIncompleteMerchantReason(merchant)}}

        div.mt-6
          MerchantAccountDetails(
            :merchantAccount="merchantAccount"
            @send-zdirect-invite="sendInvite()"
            @edit-details="onEdit"
            :isInvitationSent="isInvitationSent"
            :isInvitationRequirementsCompleted="invitationRequirements.requirementsCompleted"
            :inviteHidden="!!merchantAccount.isConnectedRetailOnly"
          )
</template>

<script lang="ts">
import Vue from 'vue';
import Appbar from 'Common/components/appbar/appbar.container.vue';
import BackLink from 'Common/components/back-link/back-link.vue';
import SidePanel from 'Common/components/accounts-side-panel/side-panel.vue';
import PageLoader from 'Common/components/page-loader/page-loader.vue';
import MerchantAccountDetails from './main/main.vue';
import {
  fetchMerchantAccountDetails,
  sendZDirectInvite,
  fetchAccountRequirements,
  MerchantAccountRequirements,
  MerchantRequirement,
} from 'Api/endpoints/merchant-account/merchant-account.endpoint';
import MerchantAccount from 'Api/models/MerchantAccount';
import { flatten } from 'ramda';

export default Vue.extend({
  components: {
    Appbar,
    BackLink,
    MerchantAccountDetails,
    PageLoader,
    SidePanel,
  },
  props: {
    onEdit: {
      type: Function,
      required: true,
    },
    merchantAccountId: {
      type: String,
      required: true,
    },
    selectedLegalEntityId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      error: null as {
        backLink: string | null;
      } | null,
      errorMessage: null as string,
      loading: true,
      isInvitationSent: true,
      merchantAccount: null as MerchantAccount | null,
      invitationRequirements: null as MerchantAccountRequirements | null,
      notify: null as string | null,
    };
  },
  computed: {
    hasError(): boolean {
      return this.error !== null;
    },
    incompleteMerchants(): MerchantRequirement[] {
      if (this.invitationRequirements.requirementsCompleted) {
        return [];
      }

      const merchantsRequirements = this.invitationRequirements.merchants;
      const merchantsList = flatten(
        this.merchantAccount.legalEntities.map(
          (legalEntity) => legalEntity.merchants
        )
      );

      return merchantsList.filter((merchant) => {
        // Find those merchants who have a step incompleted
        return merchantsRequirements.find(
          (merchantRequirements) =>
            merchantRequirements.businessPartnerId ===
              merchant.businessPartnerId &&
            Object.values(merchantRequirements).some((req) => req === false)
        );
      });
    },
  },
  mounted() {
    this.loading = true;
    this.error = null;

    Promise.all([
      fetchMerchantAccountDetails(this.merchantAccountId),
      fetchAccountRequirements(this.merchantAccountId),
    ])
      .then(([merchantAccount, requirements]) => {
        this.isInvitationSent = merchantAccount.isInvitationSent;
        this.merchantAccount = merchantAccount;
        this.invitationRequirements = requirements;
      })
      .catch(() => {
        this.error = {
          backLink: '/#/merchant-accounts',
        };
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    getMerchantUrl(merchantId: string): string {
      return this.$router.resolve({
        name: 'mcp.admin.merchant.general.company-details',
        params: { merchantId },
      }).href;
    },
    sendInvite(): void {
      this.isInvitationSent = true;
      sendZDirectInvite(this.merchantAccountId)
        .then(() => {
          this.notify = 'INVITE_SENT_SUCCESS';
        })
        .catch((error) => {
          let errorString = error.toString();
          this.isInvitationSent = false;
          this.error = {
            backLink: null,
          };
          if (errorString.includes('409') || errorString.includes('422')) {
            this.errorMessage =
              'Please try a different user and if the issue still persists, please contact team WILMA.';
          } else {
            this.errorMessage =
              'An internal system error has occurred. Please try again after some time.';
          }
        });
    },
    getIncompleteMerchantReason(merchant: {
      businessPartnerId: string;
    }): string {
      const msgs: Partial<Record<keyof MerchantRequirement, string>> = {
        integrationDetailsDefined:
          'Integration Type / Partner Provider (for at least one sales channel)',
        factoringDefined: 'Factoring Type',
        serviceLevelDefined: 'Service Level',
        merchantTypeDefined: 'Merchant Type',
      };

      const merchantRequirement = this.invitationRequirements.merchants.find(
        ({ businessPartnerId }) =>
          businessPartnerId === merchant.businessPartnerId
      );

      const msg = Object.entries(merchantRequirement)
        .filter(([_key, value]) => value === false)
        .map(([key]) => msgs[key as keyof typeof msgs] || key)
        .join(', ');

      return `please configure ${msg}`;
    },
  },
});
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.back-link {
  margin-left: 90px;
}
</style>
