import { mcb } from 'mcb';
import template from './company-metadata-group-modal.html';
import { MerchantGroup } from 'Api/models/MerchantGroup';

export class CompanyMetadataGroupModalComponent
  implements ng.IComponentOptions
{
  static Factory() {
    return new CompanyMetadataGroupModalComponent();
  }

  bindings: { [binding: string]: string } = {
    onSelect: '<',
    onCreate: '<',
    groups: '<',
    selected: '<',
  };

  controller = MetadataGroupController;
  controllerAs = 'ctrl';
  template: string = template;
}

interface MetadataGroupControlllerVM {
  selected: string;
  groups: MerchantGroup[];
  newGroup: string;
  onSelect: Function;
}

export class MetadataGroupController implements MetadataGroupControlllerVM {
  static $inject = ['$q', 'mcbToast'];

  selected;
  groups;
  newGroup = '';
  onSelect: Function;
  private isCreateInProgress: boolean = false;
  private onCreate: Function;

  constructor(private $q: ng.IQService, private toast: mcb.IToast) {}

  $onInit() {
    // Empty block
  }

  create(): void {
    const name = this.newGroup.trim();

    if (this.isExists(name)) {
      this.toast.warning(`Group "<strong>${name}</strong>" already exists`);
      return;
    }

    this.isCreateInProgress = true;

    this.onCreate(name).finally(() => (this.isCreateInProgress = false));
  }

  isCreateDisabled() {
    return !this.newGroup.trim() || this.isCreateInProgress;
  }

  private isExists(name): boolean {
    return this.groups.some(
      (group) => group.name.toLowerCase() === name.toLowerCase()
    );
  }
}
