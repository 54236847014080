<template lang="pug">
a(:href="href")
  v-flex(d-flex)
    v-icon.mr-5.mb-1(color="secondary") mdi-arrow-left
    span.title.secondary--text
      slot
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'BackLink',
  props: {
    href: {
      type: String,
      default: 'javascript:;',
    },
  },
});
</script>

<style lang="scss" scoped></style>
