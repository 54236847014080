import axios from 'axios';
import { pipe } from 'ramda';
import ErrorHandler from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import MerchantAccount from 'Api/models/MerchantAccount';
import LegalEntityContact from 'Api/models/LegalEntityContact';
import { fetchLegalEntities } from 'Api/endpoints/legal-entity/legal-entity.endpoint';

const merchantAccountApi = axios.create({
  baseURL: 'api/merchant-profile/merchant-accounts',
});
merchantAccountApi.interceptors.request.use(decamelizeRequestHandler);
merchantAccountApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

export interface MerchantAccountsResponse {
  items?: MerchantAccount[];
  count: number;
}

export interface MerchantRequirement {
  businessPartnerId: string;
  factoringDefined?: boolean;
  integrationDetailsDefined?: boolean;
  serviceLevelDefined?: boolean;
  merchantTypeDefined?: boolean;
  name?: string;
}

export interface MerchantAccountRequirements {
  requirementsCompleted: boolean;
  merchants: MerchantRequirement[];
}

export function fetchMerchantAccounts({
  name = '',
  offset,
  limit,
}: {
  name: string;
  offset: number;
  limit: number;
}): Promise<MerchantAccountsResponse> {
  return merchantAccountApi.get('', {
    params: {
      include: 'legal-entities',
      name: name || undefined, // Do not include empty name
      offset,
      limit,
    },
  });
}

export function saveMerchantAccount(
  name: string,
  contact: LegalEntityContact,
  legalEntitiesIds: string[]
): Promise<MerchantAccount> {
  return merchantAccountApi.post('', {
    name,
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.email,
    legalEntitiesIds,
  });
}

export function updateMerchantAccount({
  accountId,
  name,
  contact,
  legalEntitiesIds,
}: {
  accountId: string;
  name?: string;
  contact?: LegalEntityContact;
  legalEntitiesIds: string[];
}) {
  return merchantAccountApi.patch(accountId, {
    ...(name ? { name } : {}),
    legalEntitiesIds,
    ...contact,
  });
}

export function fetchMerchantAccount(id: string): Promise<MerchantAccount> {
  return merchantAccountApi.get(id, {
    params: {
      include: 'legal-entities,is-connected-retail-only',
    },
  });
}

export function fetchMerchantAccountDetails(
  id: string
): Promise<MerchantAccount> {
  return Promise.all([
    fetchMerchantAccount(id),
    fetchLegalEntities({ merchantAccountId: id, includeMerchants: true }),
  ]).then(([merchantAccount, legalEntities]) => ({
    ...merchantAccount,
    legalEntities,
  }));
}

export function fetchSimilarMerchantAccountNames(
  name: string
): Promise<string[]> {
  return merchantAccountApi
    .get<unknown, { items: string[] }>('similar-names', {
      params: { name },
    })
    .then(({ items }) => items);
}

export function sendZDirectInvite(merchantAccountId: string): Promise<void> {
  return merchantAccountApi.post(`${merchantAccountId}/platform-invitation`);
}

export function fetchAccountRequirements(
  merchantAccountId: string
): Promise<MerchantAccountRequirements> {
  return merchantAccountApi.get(`${merchantAccountId}/requirements`);
}
