<template lang="pug">
v-menu(offset-y)
  template(v-slot:activator="{on}")
    v-btn(
      icon v-on="on"
      data-testid="userProfileMenuButton"
    )
      UserProfileIcon

  v-card(data-testid="userProfileMenu")
    v-card-text
      div.secondary--text {{ username }}

    v-btn(text tile block large @click="$emit('logout')" data-testid="logoutBtn")
      v-icon(left) logout
      | Logout
</template>

<script lang="ts">
import Vue from 'vue';
import UserProfileIcon from 'Common/components/icons/user-profile-icon.vue';

export default Vue.extend({
  components: {
    UserProfileIcon,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss"></style>
