import axios from 'axios';
import { path, pipe } from 'ramda';
import { ShipmentServiceT } from 'Api/models/ShipmentService';
import ErrorHandler, { nullIf404Handler } from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';

const shipmentServiceApi = axios.create({
  baseURL: 'api/merchant-profile/merchants',
});

shipmentServiceApi.interceptors.request.use(decamelizeRequestHandler);
shipmentServiceApi.interceptors.response.use(
  pipe(camelizeResponseHandler, path(['data', 'shipmentServices'])),
  ErrorHandler
);

/**
 * Function returns null if Shipment Services are not defined
 */
export function fetchShipmentServices(
  merchantId: string,
  salesChannelId: string
): Promise<ShipmentServiceT | null> {
  return shipmentServiceApi
    .get(`/${merchantId}/sales-channels/${salesChannelId}/shipment-services`)
    .catch(nullIf404Handler) as Promise<ShipmentServiceT | null>;
}

export function updateShipmentServices(
  merchantId: string,
  salesChannelId: string,
  data: ShipmentServiceT
): Promise<ShipmentServiceT | null> {
  return shipmentServiceApi.put(
    `/${merchantId}/sales-channels/${salesChannelId}/shipment-services`,
    { shipmentServices: data }
  ) as Promise<ShipmentServiceT>;
}

export function patchUpdateShipmentServices(
  merchantId: string,
  salesChannelId: string,
  data
): Promise<ShipmentServiceT | null> {
  return shipmentServiceApi.patch(
    `/${merchantId}/sales-channels/${salesChannelId}/shipment-services`,
    { shipmentServices: data }
  ) as Promise<ShipmentServiceT>;
}
