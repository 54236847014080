var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "mcp-admin-register-suggestions-area mcp-admin__form-field",
      attrs: { id: "name-autocomplete-area" },
    },
    [
      _c(
        "label",
        {
          staticClass: "dc-label dc-label--required",
          class: { "dc-label--is-error": !_vm.value && _vm.isSubmitted },
          attrs: { for: _vm.label },
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _vm._v(" "),
      !_vm.value && _vm.isSubmitted
        ? _c(
            "div",
            {
              staticClass: "mcp-admin__field-error-message",
              attrs: { role: "alert" },
            },
            [
              _c("span", { staticClass: "dc--text-error" }, [
                _vm._v("Please don't leave this empty."),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        staticClass: "dc-input dc-input--block",
        class: { "dc-input--is-error": !_vm.value && _vm.isSubmitted },
        attrs: {
          id: _vm.label,
          type: "text",
          autocomplete: "off",
          placeholder: _vm.placeholder,
          required: "",
        },
        domProps: { value: _vm.value },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.value = $event.target.value
            },
            function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
          ],
          focus: function ($event) {
            return _vm.$emit("input", _vm.value)
          },
          blur: function ($event) {
            return _vm.$emit("blur")
          },
        },
      }),
      _vm._v(" "),
      _vm.merchants.length > 0
        ? _c(
            "div",
            { staticClass: "mcp-admin-register-suggestions" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _vm._l(_vm.merchants, function (merchant) {
                return _c(
                  "a",
                  {
                    key: merchant.id,
                    staticClass: "dc-link",
                    attrs: {
                      target: "_blank",
                      href: _vm.$root.getUrl(
                        "mcp.admin.merchant.general.company-details",
                        {
                          merchantId: merchant.id,
                        }
                      ),
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "dc-row mcp-admin-register-suggestions-item",
                        attrs: { "data-testid": "register-suggestions-item" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "dc-column dc-column--small-6" },
                          [
                            _c("mp-highlight-substring", {
                              attrs: {
                                text: merchant.companyName,
                                match: _vm.value,
                                dresscode: "",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "dc-column dc-column--small-6" },
                          [
                            _c("mp-highlight-substring", {
                              attrs: {
                                text: merchant.name,
                                match: _vm.value,
                                dresscode: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.additionalMerchantsCount > 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "dc-row mcp-admin-register-suggestions-show-more",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "dc-column dc-column--small-12" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "dc-link",
                              attrs: {
                                href: _vm.$root.getUrl("mcp.admin.merchants", {
                                  search: _vm.value,
                                }),
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "Show " +
                                  _vm._s(_vm.additionalMerchantsCount) +
                                  " more matches"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "dc-row mcp-admin-register-suggestions-help" },
      [
        _c("div", { staticClass: "dc-column dc-column--small-12" }, [
          _vm._v(
            "\n        Merchants with similar shop name, click to check the details:\n      "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "dc-row mcp-admin-register-suggestions-header" },
      [
        _c("div", { staticClass: "dc-column dc-column--small-6" }, [
          _vm._v("Legal name"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dc-column dc-column--small-6" }, [
          _vm._v("Shop name"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }