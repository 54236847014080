/**
 * Due to a conflict between ui-router version and angulartics
 * we should track pageView by our own without using the non-stable $transition service
 * exposed by the ui-router and used by angulartics when virtualPageviews option it's true.
 *
 * For instance angulartics was causing this exception raised by ui router:
 *
 * ```Swallowed exception during synchronous hook handler: Error: [$injector:unpr] Unknown provider: eProvider <- e```
 *
 * ...that can be correlated to two issues open in the angulartics repo:
 *
 * - https://github.com/angulartics/angulartics/issues/491
 * - https://github.com/angulartics/angulartics/issues/504
 *
 * The fix consist of two steps:
 *
 * - disabling virtualPageviews in a config phase (to avoid the execution of angulartics broken code)
 * - do virtual page view tracking by our own by listening to $stateChangeSuccess event
 *
 * TODO: remove this fix when upgrading ui-router to the beta version where this bug should be fixed
 */

class UiRouterIntegration {
  static config($analyticsProvider) {
    $analyticsProvider.virtualPageviews(false);
  }

  private _$window: any;
  private _$location: any;
  private _$injector: any;
  private _$rootScope: any;
  private _$analytics: any;

  constructor($window, $location, $injector, $rootScope, $analytics) {
    this._$window = $window;
    this._$location = $location;
    this._$injector = $injector;
    this._$rootScope = $rootScope;
    this._$analytics = $analytics;
  }

  run() {
    let basePath = '';
    if (this._$analytics.settings.pageTracking.autoBasePath) {
      /* Add the full route to the base. */
      basePath = this._$window.location.pathname + '#';
    }

    if (this._$injector.has('$state')) {
      this._$rootScope.$on('$stateChangeSuccess', () => {
        let url = basePath + this._$location.url();
        this._$analytics.pageTrack(url, this._$location);
      });
    }
  }
}

UiRouterIntegration.$inject = [
  '$window',
  '$location',
  '$injector',
  '$rootScope',
  '$analytics',
];

export default UiRouterIntegration;
