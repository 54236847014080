<template lang="pug">
  div
    p(v-if="showValidationMessage" ).green--text.text-caption
      span.font-weight-bold
        | Address matched:
      span
        |  Submitted VAT ID proof is allowed to be older than 6 months

    div.field
      div.label.text-caption.text-uppercase {{addressLabel}}
      span {{address.addressLine1}} {{address.addressLine2}}
      span.body-2.ml-4.red--text.text--lighten-2(
        v-if="isInvalid"
        ) (Address does not match with registry response)


    div.field
      div.label.text-caption.text-uppercase Postcode
      div {{address.zipCode}}

    div.field
      div.label.text-caption.text-uppercase City
      div {{address.city}}

    div.field
      div.label.text-caption.text-uppercase Country
      div {{countryName}}

    div(v-if="this.address.certificateId").field
      div.label.text-caption.text-uppercase Address Proof
      VatCertificate(:certificate-id="this.address.certificateId")

</template>

<script lang="ts">
import Vue from 'vue';
import { COUNTRIES } from 'Common/constants/misc.constant';
import { ValidationResultT, VatAddressT } from 'Api/models/Vat';
import VatCertificate from 'Merchant/common/components/vat-section/vat-certificate.vue';

export default Vue.extend({
  components: { VatCertificate },
  props: {
    address: {
      type: Object as () => VatAddressT,
      required: true,
    },
    showSubtext: {
      type: Boolean,
      required: false,
      default: false,
    },
    addressLabel: {
      type: String,
      required: false,
      default: 'Address',
    },
  },

  computed: {
    countryName(): string {
      return COUNTRIES.find(
        (country) => country.code === this.address.countryCode
      )?.name;
    },
    showValidationMessage(): boolean {
      return (
        this.showSubtext &&
        this.address.validationResult === ValidationResultT.VALID
      );
    },
    isInvalid(): boolean {
      return this.address.validationResult === ValidationResultT.INVALID;
    },
  },
});
</script>
