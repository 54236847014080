import { mcb } from 'mcb';
import template from './company-metadata-readonly.html';
import { MerchantFlowActions } from 'Merchant/common/state/actions/merchant-flow.actions';
import {
  getMerchant,
  getMerchantGroupName,
  getMerchantSupplierCode,
} from 'Merchant/common/state/selectors/flow-state.selector';
import { fetchExportLang } from 'Api/endpoints/masterdata/masterdata.endpoint';

import { MD_EXPORT_LANGS_MAP } from 'Common/constants/misc.constant';

export class CompanyMetadataReadonlyComponent implements ng.IComponentOptions {
  static Factory() {
    return new CompanyMetadataReadonlyComponent();
  }

  controller = MetadataController;
  controllerAs = 'ctrl';
  template = template;
}

export class MetadataController {
  static $inject = [
    'mcpAdminMerchantFlowActions',
    '$ngRedux',
    'mcbToast',
    '$rootScope',
  ];

  serviceLevel: string;
  merchantType: string;
  merchantGroupName: string = '';
  supplierCode: string = '';
  mdExportLang: string;
  private merchantId: string;
  private unsubscribe: Function;
  private fetchMerchantGroups: Function;

  constructor(
    private merchantFlowActions: MerchantFlowActions,
    private $ngRedux,
    private toast: mcb.IToast,
    private $rootScope: ng.IScope
  ) {}

  $onInit(): void {
    this.unsubscribe = this.$ngRedux.connect(
      this.mapStateToThis,
      this.mapDispatchToThis()
    )(this);

    this.fetchMerchantGroups();

    this.initMDExportLang();
  }

  $onDestroy(): void {
    this.unsubscribe();
  }

  private initMDExportLang() {
    return (
      fetchExportLang(this.merchantId)
        .then((lang) => (this.mdExportLang = MD_EXPORT_LANGS_MAP[lang]))
        .catch(() =>
          this.toast.error('Masterdata export language initialization failed')
        )
        // We need to tell angular about the updates because fetching is done out of AngularJS's $digest cycle
        .finally(() => this.$rootScope.$applyAsync())
    );
  }

  private mapStateToThis(state) {
    const merchant = getMerchant(state);

    return {
      serviceLevel: merchant.serviceLevel,
      merchantType: merchant.merchantType,
      merchantId: merchant.$id,
      merchantGroupName: getMerchantGroupName(state),
      supplierCode: getMerchantSupplierCode(state),
    };
  }

  private mapDispatchToThis() {
    return {
      fetchMerchantGroups: () => this.merchantFlowActions.setMerchantGroups(),
    };
  }
}
