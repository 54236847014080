import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "document-container" },
    [
      _c(
        VSnackbar,
        {
          attrs: { timeout: 5000, color: "reject", bottom: "", right: "" },
          model: {
            value: _vm.showError,
            callback: function ($$v) {
              _vm.showError = $$v
            },
            expression: "showError",
          },
        },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _c(VFileInput, {
        attrs: {
          accept: "application/pdf",
          label: _vm.label,
          loading: _vm.isUploading,
          value: _vm.documentFile,
          rules: [_vm.$options.filters.formFieldRequired],
        },
        on: { change: _vm.handleUpload },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }