import humps from 'humps';

interface JsonApiObjectT {
  id: object;
  type: string;
}

export function decamelizeRequestHandler(config: any): unknown {
  const newConfig = { ...config };

  if (newConfig.data) {
    newConfig.data = humps.decamelizeKeys(config.data);
  }

  if (newConfig.params) {
    newConfig.params = humps.decamelizeKeys(config.params);
  }
  return newConfig;
}

export function camelizeResponseHandler(response: any): unknown {
  const newResponse = { ...response };

  if (newResponse.data) {
    newResponse.data = humps.camelizeKeys(response.data);
  }

  return newResponse;
}

export function resolveRelationships({
  data,
  included,
  meta,
}): { items: unknown[]; meta?: { count: number } } | unknown {
  if (!data) return;
  if (Array.isArray(data)) {
    return {
      items: data.map((obj) => getObjectWithResolvedDeps(obj, included)),
      meta,
    };
  }

  return getObjectWithResolvedDeps(data, []);
}

export function dejsonapify({ attributes, type, ...rest }): unknown {
  return { ...attributes, ...rest };
}

function getObjectWithResolvedDeps(
  {
    relationships,
    ...obj
  }: {
    id: string;
    type: string;
    attributes: unknown;
    relationships: unknown;
  },
  included
): unknown {
  const newObj = dejsonapify(obj);

  if (!relationships) {
    return newObj;
  }

  Object.entries(relationships).forEach(
    // @ts-ignore
    ([relationshipName, { data: relationships }]): unknown => {
      newObj[relationshipName] = included
        .filter((includedData: JsonApiObjectT): boolean =>
          relationships.some((relationship: JsonApiObjectT): boolean => {
            return (
              includedData.id === relationship.id &&
              includedData.type === relationship.type
            );
          })
        )
        .map(dejsonapify);
    }
  );

  return newObj;
}
