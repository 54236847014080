<template lang="pug">
  div.document-container
    v-snackbar(v-model="showError" :timeout="5000" color="reject" bottom right)
      | {{errorMessage}}

    v-file-input(
      accept="application/pdf"
      :label="label"
      :loading="isUploading"
      :value="documentFile"
      @change="handleUpload"
      :rules="[$options.filters.formFieldRequired]"
    )

</template>

<script lang="ts">
import Vue from 'vue';

const DOCUMENT_SIZE_LIMIT_EXCEED_ERROR =
  'File size exceeds. Please select a PDF file with a max size of 10 MB';
const DOCUMENT_UPLOAD_ERROR = 'Failed to upload certificate.';

export default Vue.extend({
  props: {
    documentId: {
      type: String,
      required: false,
      default: '',
    },
    fileName: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showError: false,
      errorMessage: '',
      isFetching: false,
      isUploading: false,
      documentFile: this.fileName ? new File([], this.fileName) : null,
    };
  },

  methods: {
    async handleUpload(file: File): Promise<void> {
      if (file) {
        const fileSize = file.size;
        const oneMbInKbs = 1024 * 1024;
        const fileMb = Number.parseFloat((fileSize / oneMbInKbs).toFixed(1));
        const isFileSizeLarge = fileMb > 10;
        if (isFileSizeLarge) {
          this.showErrorSnackbar(DOCUMENT_SIZE_LIMIT_EXCEED_ERROR);
        } else {
          try {
            this.isUploading = true;
            await this.$emit('upload-document', file);
          } catch (e) {
            this.showErrorSnackbar(DOCUMENT_UPLOAD_ERROR);
          } finally {
            this.isUploading = false;
          }
        }
      } else {
        this.documentFile = null;
      }
    },
    showErrorSnackbar(errorMessage: string): void {
      this.showError = true;
      this.errorMessage = errorMessage;
    },
  },
});
</script>

<style lang="scss" scoped>
.document-container {
  flex: 1;
}
.document-link {
  overflow-wrap: anywhere;
  text-align: left;
  transition: 0.3s;
  cursor: pointer;
}
.document-link:hover {
  opacity: 0.7;
}
</style>
