import { MerchantTypeT } from '@/api/models/MerchantProfile';

type MerchantTypeOption = {
  text: string;
  value: MerchantTypeT | '';
};

export const MCP_MERCHANT_TYPE_OPTIONS: MerchantTypeOption[] = [
  { value: '', text: 'All' },
  { value: 'BRAND', text: 'Brand' },
  { value: 'RETAILER', text: 'Retailer' },
  { value: 'CONNECTED_RETAIL', text: 'Connected Retail' },
  { value: 'DISTRIBUTOR', text: 'Distributor' },
  { value: 'SERVICE_PROVIDER', text: 'Service Provider' },
];
// eslint-disable-next-line max-len
export const MCP_MERCHANT_TYPE_OPTIONS_WITHOUT_ALL: MerchantTypeOption[] =
  MCP_MERCHANT_TYPE_OPTIONS.filter((el) => el.value);
