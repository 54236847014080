<template lang="pug">
  v-app.root.px-10.py-6
    v-snackbar(v-model="showError" :timeout="5000" color="reject" bottom right)
      | {{errorMessage}}

    ReviewStatusLegend(title="Company data")
    div.d-flex.justify-center(v-if="isLoading")
      v-progress-circular(
        color="secondaryDark"
        indeterminate
      )
    div.ml-8.px-2.py-4(v-else-if="bankAccountList.length")
      div.my-3
        CrLegalEntityDetails()

      div.my-3
        FactoringInfo()

      div.my-3
        BankAccountReviewList(
          :list="bankAccountList"
          :legal-entity-name="legalEntityName"
          @on-rejection-reason-change="onRejectionReasonChange"
          @on-status-change="onReviewStatusChange"
        )

      div.my-3
        BillingDetailsReadonly(
          :billing-mode="billingMode"
          :invoice-language="invoiceLanguage"
        )

      v-divider

      ShopDetails(
        :shop-name="shopName"
      )

      v-divider

      ContactDetailsReview(
        :contacts="contacts"
        :has-zfs="hasZfsContracts"
      )

      v-divider

      div.text-center.mt-4(v-if="showSubmitBtn")
        v-btn.white--text.text-none(
          :loading="isSubmitting"
          color="black"
          @click="handleSubmit"
          :disabled="isSubmitBtnDisabled"
        ) Confirm review of Company details

    div.text-h5.text-center.mt-4(v-else)
      | No data available for review

</template>

<script lang="ts">
import Vue from 'vue';
import { MerchantComplianceRequirementT } from 'Api/models/ComplianceRequirement';
import ReviewStatusLegend from 'Merchant/common/components/review-status-legend/review-status-legend.vue';
import {
  ApprovalStatusT,
  SelectableReviewStatusT,
} from 'Api/models/ApprovalStatus';
import { BankAccountListItemT } from 'Merchant/common/components/bank-account-details/bank-account-review-item.vue';
import BankAccountReviewList from 'Merchant/common/components/bank-account-details/bank-account-review-list.vue';
import { handleReviewSubmit } from 'Merchant/general/components/company-details-approval/submitHandler';
import {
  getBankAccountList,
  isReviewSubmitDisabled,
} from 'Merchant/general/components/company-details-approval/util';
import { fetchPartnerLegalEntityDetails } from 'Api/endpoints/legal-entity/legal-entity.endpoint';
import BillingDetailsReadonly from 'Merchant/common/components/billing-details/billing-details-readonly.vue';
import ShopDetails from 'Merchant/common/components/shop-details/shop-details.vue';
import { MCP_FF_TYPES } from '@/common/constants/misc.constant';
import {
  MerchantProfile,
  PreferredLanguageT,
} from 'Api/models/MerchantProfile';
import { Contract } from 'Api/models/Contract';
import ContactDetailsReview from 'Merchant/general/components/company-details-contacts/contact-details-review.vue';
import { fetchContacts } from 'Api/endpoints/merchant/merchant-contact.endpoint';
import MerchantContactT from 'Api/models/MerchantContact';
import { fetchBillingMode } from 'Api/endpoints/merchant/merchant-billing-mode.endpoint';
import {
  fetchBankAccountList,
  fetchCurrencySalesChannels,
} from 'Api/endpoints/merchant/merchant-bank-accounts.endpoint';
import { BillingModeT } from 'Api/models/BillingMode';
import CrLegalEntityDetails from 'Merchant/general/components/company-details-approval/CrLegalEntityDetails.vue';
import FactoringInfo from 'Merchant/general/components/company-details-approval/FactoringInfo.vue';

const FETCH_ERROR_MESSAGE = 'Failed to fetch data for review';
const UPDATE_ERROR_MESSAGE =
  'Failed to update the review status for bank account data';

interface StoreStateT {
  merchant: MerchantProfile;
  legalEntityId: string;
  contracts: Contract[];
}

export default Vue.extend({
  components: {
    FactoringInfo,
    ShopDetails,
    BillingDetailsReadonly,
    BankAccountReviewList,
    ReviewStatusLegend,
    ContactDetailsReview,
    CrLegalEntityDetails,
  },

  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      showError: false,
      errorMessage: '',
      bankAccountList: [] as BankAccountListItemT[],
      legalEntityName: '',
      billingMode: '' as BillingModeT,
      contacts: [] as MerchantContactT[],
      // @ts-ignore
      ...(this.mapState({
        merchant: 'flow.general.merchant.data',
        legalEntityId: 'flow.general.merchant.data.legalEntityId',
        contracts: 'flow.general.contracts.data',
      }) as StoreStateT),
    };
  },

  computed: {
    isSubmitBtnDisabled(): boolean {
      if (this.isLoading) return true;
      return isReviewSubmitDisabled(this.bankAccountList);
    },
    showSubmitBtn(): boolean {
      return this.bankAccountList.some(
        (item: BankAccountListItemT) =>
          item.status === ApprovalStatusT.IN_REVIEW
      );
    },
    hasZfsContracts(): boolean {
      return this.contracts.some(
        (contract) => contract.fulfillmentType === MCP_FF_TYPES.ZFS
      );
    },
    shopName(): string {
      return this.merchant.name;
    },
    invoiceLanguage(): PreferredLanguageT {
      return this.merchant.preferredLanguage;
    },
  },

  mounted: async function () {
    try {
      this.isLoading = true;
      const requests = [
        this.fetchLegalEntityName(),
        this.fetchBillingMode(),
        this.fetchMerchantContacts(),
        this.fetchBankData(),
      ];

      await Promise.all(requests);
    } catch (e) {
      this.showApiError(FETCH_ERROR_MESSAGE);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async fetchMerchantContacts(): Promise<void> {
      this.contacts = await fetchContacts(this.merchant.$id);
    },
    async fetchBillingMode(): Promise<void> {
      this.billingMode = await fetchBillingMode(this.merchant.$id);
    },
    async fetchLegalEntityName(): Promise<void> {
      if (this.legalEntityId) {
        const legalEntityData = await fetchPartnerLegalEntityDetails(
          this.legalEntityId
        );
        this.legalEntityName = legalEntityData.name.value;
      }
    },
    async fetchBankData(): Promise<void> {
      const bankAccounts = await fetchBankAccountList(this.merchant.$id);

      const currencySalesChannels = await fetchCurrencySalesChannels(
        this.merchant.$id
      );
      this.bankAccountList = getBankAccountList(
        bankAccounts,
        currencySalesChannels
      );
    },
    async handleSubmit(): Promise<void> {
      this.isSubmitting = true;

      try {
        await handleReviewSubmit({
          merchantId: this.merchant.$id,
          bankAccountList: this.bankAccountList,
        });
        window.location.reload();
      } catch (e) {
        this.showApiError(UPDATE_ERROR_MESSAGE);
      } finally {
        this.isSubmitting = false;
      }
    },
    isSectionRequired(section: MerchantComplianceRequirementT): boolean {
      return this.requirements.includes(section);
    },
    showApiError(message: string): void {
      this.showError = true;
      this.errorMessage = message;
    },
    onRejectionReasonChange({
      message,
      id,
    }: {
      message: string;
      id?: string;
    }): void {
      this.$set(this.bankAccountList, id, {
        ...this.bankAccountList[id],
        providedRejectionReason: message,
      });
    },
    onReviewStatusChange({
      status,
      id,
    }: {
      status: SelectableReviewStatusT;
      id?: string;
    }): void {
      this.$set(this.bankAccountList, id, {
        ...this.bankAccountList[id],
        selectedStatus: status,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep .field {
  display: flex;
  margin-bottom: 6px;
  align-items: center;
}

::v-deep .label {
  width: 191px;
  font-weight: 700;
}
</style>
