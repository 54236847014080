import axios from 'axios';
import { MarketAddressT } from 'Api/models/Address';
import { MarketBillingDataT } from 'Api/models/BillingData';
import { ContractRequirements } from 'Api/models/ContractRequirements';
import { ChangedData } from 'Api/models/DataSections';
import { pipe } from 'ramda';
import ErrorHandler, { nullIf404Handler } from 'Api/api-errors-handler';
import {
  camelizeResponseHandler,
  decamelizeRequestHandler,
} from 'Api/api-mutations';
import { MCP_CONTRACT_STATUSES as CONTRACT_STATUSES } from 'Common/constants/contract-statuses.constant';
import { ContractStatusT, RejectionScopes } from '@/api/models/Contract';

const salesChannelApi = axios.create({
  baseURL: 'api/merchant-profile/merchants',
});

const contractApi = axios.create({
  baseURL: 'api/merchant-profile/contracts',
});

salesChannelApi.interceptors.request.use(decamelizeRequestHandler);
salesChannelApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

contractApi.interceptors.request.use(decamelizeRequestHandler);
contractApi.interceptors.response.use(
  pipe(camelizeResponseHandler, ({ data }) => data),
  ErrorHandler
);

/**
 * Function returns null if Company Address is not defined
 * neither on Merchant Level nor on Market level
 *
 */
export function fetchSalesChannelAddress(
  merchantId: string,
  salesChannelId: string
): Promise<MarketAddressT | null> {
  return salesChannelApi
    .get(`/${merchantId}/sales-channels/${salesChannelId}/address`)
    .catch(nullIf404Handler) as Promise<MarketAddressT | null>;
}

/**
 * Function returns null if Billing Details are not defined
 * neither on Merchant Level nor on Market level
 *
 */
export function fetchSalesChannelBillingData(
  merchantId: string,
  salesChannelId: string
): Promise<MarketBillingDataT | null> {
  return salesChannelApi
    .get(`/${merchantId}/sales-channels/${salesChannelId}/billing-details`)
    .catch(nullIf404Handler) as Promise<MarketBillingDataT | null>;
}

export function markAsFinancialApproved(
  merchantId: string,
  salesChannelId: string
): Promise<unknown> {
  return salesChannelApi.post(
    `/${merchantId}/sales-channels/${salesChannelId}/status`,
    { status: CONTRACT_STATUSES.FINANCIAL_APPROVED }
  ) as Promise<unknown>;
}

export function markAsFinancialRejected(
  merchantId: string,
  salesChannelId: string,
  reason: string,
  scope?: RejectionScopes,
  sections?: string[]
): Promise<unknown> {
  return salesChannelApi.post(
    `/${merchantId}/sales-channels/${salesChannelId}/status`,
    {
      status: CONTRACT_STATUSES.FINANCIAL_REJECTED,
      approverComment: reason,
      rejection_scope: scope,
      rejected_sections: sections,
    }
  ) as Promise<unknown>;
}

interface SendForFinancialApprovalPropsT {
  merchantId: string;
  salesChannelIds: string[];
}

export function sendSalesChannelsForFinancialApproval({
  merchantId,
  salesChannelIds,
}: SendForFinancialApprovalPropsT): Promise<unknown> {
  const apiPromises = salesChannelIds.map((salesChannelId) => {
    return salesChannelApi.post(
      `/${merchantId}/sales-channels/${salesChannelId}/status`,
      {
        status: CONTRACT_STATUSES.IN_FINANCIAL_REVIEW,
      }
    );
  });

  return Promise.all(apiPromises);
}

export function markAsLaunched(contractId: string): Promise<unknown> {
  return contractApi.post(`/${contractId}/launch`) as Promise<unknown>;
}

export function fetchContractDataChanges(
  contractId: string
): Promise<ChangedData> {
  return (
    contractApi
      .get(`/${contractId}/modified-ui-sections`)
      // @ts-ignore
      .then((data) => data.items) as Promise<ChangedData>
  );
}

export function fetchSalesChannelDataChanges(
  merchantId: string,
  salesChannelId: string
): Promise<ChangedData> {
  return (
    salesChannelApi
      .get(
        `${merchantId}/sales-channels/${salesChannelId}/modified-ui-sections`
      )
      // @ts-ignore
      .then((data) => data.items) as Promise<ChangedData>
  );
}

export function putChannelOnline(contractId: string): Promise<void> {
  return contractApi.post(
    `/${contractId}/live`,
    {
      data: {
        attributes: { live: true },
        type: 'ContractLiveStatus',
      },
    },
    {
      headers: {
        accept: 'application/vnd.api+json',
        'content-type': 'application/vnd.api+json',
      },
    }
  ) as Promise<void>;
}

export function putChannelOffline(
  contractId: string,
  offlineReason: string
): Promise<void> {
  return contractApi.post(
    `/${contractId}/live`,
    {
      data: {
        attributes: { live: false, offlineReason },
        type: 'ContractLiveStatus',
      },
    },
    {
      headers: {
        accept: 'application/vnd.api+json',
        'content-type': 'application/vnd.api+json',
      },
    }
  ) as Promise<void>;
}

interface ContractRequirementsWithLaunchStatus extends ContractRequirements {
  areAllPartnersLaunched: boolean;
}

/**
 * Include areAllPartnersLaunched flag
 */
export function fetchContractRequirementsWithLaunchedStatus(
  contractId: string
): Promise<ContractRequirementsWithLaunchStatus> {
  return Promise.all([
    fetchContractRequirements(contractId),
    fetchAreContractPartnersLaunched(contractId),
  ]).then(([contractsRequirements, areAllPartnersLaunched]) => {
    return {
      ...contractsRequirements,
      areAllPartnersLaunched,
    };
  });
}

export function fetchContractRequirements(
  contractId: string
): Promise<ContractRequirements> {
  return contractApi.get(`/${contractId}/requirement`).then((data) => {
    return { ...data.data.attributes, contractId };
  }) as Promise<ContractRequirements>;
}

/**
 * We can't rely on Contract's launch_status property, because it's marked as LAUNCH_DONE
 * whenever one of the partners is launched
 * We need to ensure that every partner is launched
 */
function fetchAreContractPartnersLaunched(
  contractId: string
): Promise<boolean> {
  return contractApi.get(`/${contractId}?include=partners`).then((data) => {
    // @ts-ignore
    const areAllPartnersLaunched = data.included
      .map(({ attributes }) => attributes.partnerLaunchStatus)
      .every(
        (launchStatus) =>
          launchStatus === 'LAUNCH_DONE' || launchStatus === 'INACTIVE'
      );
    return areAllPartnersLaunched;
  }) as Promise<boolean>;
}
