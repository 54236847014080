import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VApp,
    { staticClass: "root px-10 py-6" },
    [
      _c(
        VSnackbar,
        {
          attrs: { timeout: 5000, color: "reject", bottom: "", right: "" },
          model: {
            value: _vm.showError,
            callback: function ($$v) {
              _vm.showError = $$v
            },
            expression: "showError",
          },
        },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _c("ReviewStatusLegend", {
        attrs: {
          title: `${
            _vm.countryName
              ? `${_vm.countryName} Compliance data`
              : "Compliance data"
          }`,
        },
      }),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center" },
            [
              _c(VProgressCircular, {
                attrs: { color: "secondaryDark", indeterminate: "" },
              }),
            ],
            1
          )
        : !_vm.showError
        ? _c(
            "div",
            { staticClass: "px-2 py-4" },
            [
              _vm.vatData
                ? _c(
                    "div",
                    { staticClass: "pb-4" },
                    [
                      _c("VatReviewSection", {
                        attrs: {
                          title: "Vat ID details",
                          "vat-details": _vm.vatData,
                          status: _vm.vatData.approvalStatus,
                          "selected-status": _vm.selectedStatus,
                          "rejection-reason": _vm.providedRejectionReason,
                          "compliance-status": _vm.complianceStatus,
                          "enable-review": "",
                        },
                        on: {
                          "on-rejection-reason-change": function ($event) {
                            _vm.providedRejectionReason = $event
                          },
                          "on-status-change": function ($event) {
                            _vm.selectedStatus = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _c("NoDataSection", {
                    attrs: { title: _vm.titles.scVatDetails },
                  }),
              _vm.showSubmitBtn
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "white--text text-none",
                          attrs: {
                            loading: _vm.isSubmitting,
                            color: "black",
                            disabled: _vm.isSubmitBtnDisabled,
                          },
                          on: { click: _vm.handleSubmit },
                        },
                        [_vm._v("Confirm review of Compliance details")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }