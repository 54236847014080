<template lang="pug">
v-flex.d-flex.flex-column.ml-4(style="height: 38px; justify-content: space-evenly")
  div.caption.secondary--text
    | {{ label }}
  div.body-1.secondaryDark--text
    | {{ value || '-' }}
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'LabeledText',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped></style>
