import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        VSnackbar,
        {
          attrs: { timeout: 5000, color: "reject", bottom: "", right: "" },
          model: {
            value: _vm.showError,
            callback: function ($$v) {
              _vm.showError = $$v
            },
            expression: "showError",
          },
        },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _c(
        "form",
        { staticClass: "ma-5", attrs: { novalidate: "" } },
        [
          _c(
            "div",
            { staticClass: "heading text-h6 font-weight-regular mb-3" },
            [_vm._v("Legal entity address")]
          ),
          _c("div", { staticClass: "mcp-admin__form-field" }, [
            _c(
              "label",
              {
                staticClass: "dc-label dc-label--required mt-2",
                class: { "dc-label--is-error": !_vm.isNameValid },
                attrs: { for: "legal-entity-field-name" },
              },
              [_vm._v("Legal entity name")]
            ),
            !_vm.isNameValid
              ? _c(
                  "div",
                  {
                    staticClass: "mcp-admin__field-error-message",
                    attrs: { role: "alert" },
                  },
                  [
                    _c("span", { staticClass: "dc--text-error" }, [
                      _vm._v("Please don't leave this empty."),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.formData.name,
                  expression: "formData.name",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "dc-input dc-input--block",
              class: { "dc-input--is-error": !_vm.isNameValid },
              attrs: {
                type: "text",
                name: "legal-entity-name",
                id: "legal-entity-field-name",
                required: "",
              },
              domProps: { value: _vm.formData.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.formData, "name", $event.target.value.trim())
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "mcp-admin__form-field" }, [
            _c(
              "label",
              {
                staticClass: "dc-label dc-label--required mt-2",
                class: { "dc-label--is-error": !_vm.isAddressLine1Valid },
                attrs: { for: "legal-entity-field-address-line-1" },
              },
              [_vm._v("Street and house number")]
            ),
            !_vm.isAddressLine1Valid
              ? _c(
                  "div",
                  {
                    staticClass: "mcp-admin__field-error-message",
                    attrs: { role: "alert" },
                  },
                  [
                    _c("span", { staticClass: "dc--text-error" }, [
                      _vm._v("Please don't leave this empty."),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.formData.addressLine1,
                  expression: "formData.addressLine1",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "dc-input dc-input--block",
              class: { "dc-input--is-error": !_vm.isAddressLine1Valid },
              attrs: {
                type: "text",
                name: "street-and-house",
                id: "legal-entity-field-address-line-1",
                required: "",
              },
              domProps: { value: _vm.formData.addressLine1 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.formData,
                    "addressLine1",
                    $event.target.value.trim()
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "mcp-admin__form-field" }, [
            _c(
              "label",
              {
                staticClass: "dc-label mt-2",
                attrs: { for: "legal-entity-field-address-line-2" },
              },
              [_vm._v("Address Line 2")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.formData.addressLine2,
                  expression: "formData.addressLine2",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "dc-input dc-input--block",
              attrs: {
                type: "text",
                name: "address-line-2",
                id: "legal-entity-field-address-line-2",
                required: "",
              },
              domProps: { value: _vm.formData.addressLine2 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.formData,
                    "addressLine2",
                    $event.target.value.trim()
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "d-flex" }, [
            _c("div", { staticClass: "mcp-admin__form-field flex-grow-1" }, [
              _c(
                "label",
                {
                  staticClass: "dc-label dc-label--required mt-2",
                  class: { "dc-label--is-error": !_vm.isZipCodeValid },
                  attrs: { for: "legal-entity-field-zip" },
                },
                [_vm._v("Zip")]
              ),
              !_vm.isZipCodeValid
                ? _c(
                    "div",
                    {
                      staticClass: "mcp-admin__field-error-message",
                      attrs: { role: "alert" },
                    },
                    [
                      _c("span", { staticClass: "dc--text-error" }, [
                        _vm._v("Please don't leave this empty."),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.zipCode,
                    expression: "formData.zipCode",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "dc-input dc-input--block",
                class: { "dc-input--is-error": !_vm.isZipCodeValid },
                attrs: {
                  type: "text",
                  name: "zip-code",
                  id: "legal-entity-field-zip",
                  required: "",
                },
                domProps: { value: _vm.formData.zipCode },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.formData,
                      "zipCode",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "mcp-admin__form-field flex-grow-1 ml-3" },
              [
                _c(
                  "label",
                  {
                    staticClass: "dc-label dc-label--required mt-2",
                    class: { "dc-label--is-error": !_vm.isCityValid },
                    attrs: { for: "legal-entity-field-city" },
                  },
                  [_vm._v("City")]
                ),
                !_vm.isCityValid
                  ? _c(
                      "div",
                      {
                        staticClass: "mcp-admin__field-error-message",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("span", { staticClass: "dc--text-error" }, [
                          _vm._v("Please don't leave this empty."),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.city,
                      expression: "formData.city",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "dc-input dc-input--block",
                  class: { "dc-input--is-error": !_vm.isCityValid },
                  attrs: {
                    type: "text",
                    name: "city",
                    id: "legal-entity-field-city",
                    required: "",
                  },
                  domProps: { value: _vm.formData.city },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.formData, "city", $event.target.value.trim())
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]
            ),
          ]),
          _c("div", { staticClass: "mcp-admin__form-field" }, [
            _c(
              "label",
              {
                staticClass: "dc-label dc-label--required mt-2",
                class: { "dc-label--is-error": !_vm.isCountryCodeValid },
                attrs: { for: "legal-entity-field-country" },
              },
              [_vm._v("Country")]
            ),
            !_vm.isCountryCodeValid
              ? _c(
                  "div",
                  {
                    staticClass: "mcp-admin__field-error-message",
                    attrs: { role: "alert" },
                  },
                  [
                    _c("span", { staticClass: "dc--text-error" }, [
                      _vm._v("Please don't leave this empty."),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.countryCode,
                    expression: "formData.countryCode",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "dc-select mcp-admin-merchant-details-select",
                class: { "dc-input--is-error": !_vm.isCountryCodeValid },
                attrs: {
                  name: "country-code",
                  id: "legal-entity-field-country",
                  required: "",
                },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.formData,
                      "countryCode",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.countries, function (country) {
                return _c("option", { domProps: { value: country.code } }, [
                  _vm._v(_vm._s(country.name)),
                ])
              }),
              0
            ),
          ]),
          _c(VDivider, { staticClass: "my-6" }),
          _c(
            "div",
            { staticClass: "heading text-h6 font-weight-regular mb-3" },
            [_vm._v("Signer details")]
          ),
          _c("div", { staticClass: "text-body-1 mb-2" }, [_vm._v("Signer 1:")]),
          _c("div", { staticClass: "d-flex" }, [
            _c("div", { staticClass: "mcp-admin__form-field flex-grow-1" }, [
              _c(
                "label",
                {
                  staticClass: "dc-label dc-label--required mt-2",
                  class: { "dc-label--is-error": !_vm.isSigner1FirstNameValid },
                  attrs: { for: "signer1-field-first-name" },
                },
                [_vm._v("First name")]
              ),
              !_vm.isSigner1FirstNameValid
                ? _c(
                    "div",
                    {
                      staticClass: "mcp-admin__field-error-message",
                      attrs: { role: "alert" },
                    },
                    [
                      _c("span", { staticClass: "dc--text-error" }, [
                        _vm._v("Please don't leave this empty."),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.signer1FirstName,
                    expression: "formData.signer1FirstName",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "dc-input dc-input--block",
                class: { "dc-input--is-error": !_vm.isSigner1FirstNameValid },
                attrs: {
                  type: "text",
                  name: "signer1-first-name",
                  id: "signer1-field-first-name",
                  required: "",
                },
                domProps: { value: _vm.formData.signer1FirstName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.formData,
                      "signer1FirstName",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "mcp-admin__form-field flex-grow-1 ml-3" },
              [
                _c(
                  "label",
                  {
                    staticClass: "dc-label dc-label--required mt-2",
                    class: {
                      "dc-label--is-error": !_vm.isSigner1LastNameValid,
                    },
                    attrs: { for: "signer1-field-last-name" },
                  },
                  [_vm._v("Last name")]
                ),
                !_vm.isSigner1LastNameValid
                  ? _c(
                      "div",
                      {
                        staticClass: "mcp-admin__field-error-message",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("span", { staticClass: "dc--text-error" }, [
                          _vm._v("Please don't leave this empty."),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.signer1LastName,
                      expression: "formData.signer1LastName",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "dc-input dc-input--block",
                  class: { "dc-input--is-error": !_vm.isSigner1LastNameValid },
                  attrs: {
                    type: "text",
                    name: "signer1-last-name",
                    id: "signer1-field-last-name",
                    required: "",
                  },
                  domProps: { value: _vm.formData.signer1LastName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.formData,
                        "signer1LastName",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]
            ),
          ]),
          _c("div", { staticClass: "d-flex" }, [
            _c("div", { staticClass: "mcp-admin__form-field flex-grow-1" }, [
              _c(
                "label",
                {
                  staticClass: "dc-label dc-label--required mt-2",
                  class: { "dc-label--is-error": !_vm.isSigner1TitleValid },
                  attrs: { for: "signer1-field-title" },
                },
                [_vm._v("Signer Title")]
              ),
              !_vm.isSigner1TitleValid
                ? _c(
                    "div",
                    {
                      staticClass: "mcp-admin__field-error-message",
                      attrs: { role: "alert" },
                    },
                    [
                      _c("span", { staticClass: "dc--text-error" }, [
                        _vm._v("Please don't leave this empty."),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.signer1Title,
                    expression: "formData.signer1Title",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "dc-input dc-input--block",
                class: { "dc-input--is-error": !_vm.isSigner1TitleValid },
                attrs: {
                  type: "text",
                  name: "signer1-title",
                  id: "signer1-field-title",
                  required: "",
                },
                domProps: { value: _vm.formData.signer1Title },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.formData,
                      "signer1Title",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "mcp-admin__form-field flex-grow-1 ml-3" },
              [
                _c(
                  "label",
                  {
                    staticClass: "dc-label dc-label--required mt-2",
                    class: { "dc-label--is-error": !_vm.isSigner1EmailValid },
                    attrs: { for: "signer1-field-email" },
                  },
                  [_vm._v("Signer Email")]
                ),
                !_vm.isSigner1EmailValid
                  ? _c(
                      "div",
                      {
                        staticClass: "mcp-admin__field-error-message",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("span", { staticClass: "dc--text-error" }, [
                          _vm._v("Please add correct email."),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.signer1Email,
                      expression: "formData.signer1Email",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "dc-input dc-input--block",
                  class: { "dc-input--is-error": !_vm.isSigner1EmailValid },
                  attrs: {
                    type: "text",
                    name: "signer1-email",
                    id: "signer1-field-email",
                    required: "",
                  },
                  domProps: { value: _vm.formData.signer1Email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.formData,
                        "signer1Email",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]
            ),
          ]),
          _c(VDivider, { staticClass: "my-6" }),
          _c("div", { staticClass: "text-body-1 mb-2" }, [
            _vm._v("Signer 2 (optional):"),
          ]),
          _c("div", { staticClass: "d-flex" }, [
            _c("div", { staticClass: "mcp-admin__form-field flex-grow-1" }, [
              _c(
                "label",
                {
                  staticClass: "dc-label mt-2",
                  class: {
                    "dc-label--is-error": !_vm.isSigner2FirstNameValid,
                    "dc-label--required": _vm.isSigner2FieldsRequired,
                  },
                  attrs: { for: "signer2-field-first-name" },
                },
                [_vm._v("First name")]
              ),
              !_vm.isSigner2FirstNameValid
                ? _c(
                    "div",
                    {
                      staticClass: "mcp-admin__field-error-message",
                      attrs: { role: "alert" },
                    },
                    [
                      _c("span", { staticClass: "dc--text-error" }, [
                        _vm._v("Please don't leave this empty."),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.signer2FirstName,
                    expression: "formData.signer2FirstName",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "dc-input dc-input--block",
                class: { "dc-input--is-error": !_vm.isSigner2FirstNameValid },
                attrs: {
                  type: "text",
                  name: "signer2-first-name",
                  id: "signer2-field-first-name",
                  required: "",
                },
                domProps: { value: _vm.formData.signer2FirstName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.formData,
                      "signer2FirstName",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "mcp-admin__form-field flex-grow-1 ml-3" },
              [
                _c(
                  "label",
                  {
                    staticClass: "dc-label mt-2",
                    class: {
                      "dc-label--is-error": !_vm.isSigner2LastNameValid,
                      "dc-label--required": _vm.isSigner2FieldsRequired,
                    },
                    attrs: { for: "signer2-field-last-name" },
                  },
                  [_vm._v("Last name")]
                ),
                !_vm.isSigner2LastNameValid
                  ? _c(
                      "div",
                      {
                        staticClass: "mcp-admin__field-error-message",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("span", { staticClass: "dc--text-error" }, [
                          _vm._v("Please don't leave this empty."),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.signer2LastName,
                      expression: "formData.signer2LastName",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "dc-input dc-input--block",
                  class: { "dc-input--is-error": !_vm.isSigner2LastNameValid },
                  attrs: {
                    type: "text",
                    name: "signer2-last-name",
                    id: "signer2-field-last-name",
                    required: "",
                  },
                  domProps: { value: _vm.formData.signer2LastName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.formData,
                        "signer2LastName",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]
            ),
          ]),
          _c("div", { staticClass: "d-flex" }, [
            _c("div", { staticClass: "mcp-admin__form-field flex-grow-1" }, [
              _c(
                "label",
                {
                  staticClass: "dc-label mt-2",
                  class: {
                    "dc-label--is-error": !_vm.isSigner2TitleValid,
                    "dc-label--required": _vm.isSigner2FieldsRequired,
                  },
                  attrs: { for: "signer2-field-title" },
                },
                [_vm._v("Signer Title")]
              ),
              !_vm.isSigner2TitleValid
                ? _c(
                    "div",
                    {
                      staticClass: "mcp-admin__field-error-message",
                      attrs: { role: "alert" },
                    },
                    [
                      _c("span", { staticClass: "dc--text-error" }, [
                        _vm._v("Please don't leave this empty."),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.formData.signer2Title,
                    expression: "formData.signer2Title",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "dc-input dc-input--block",
                class: { "dc-input--is-error": !_vm.isSigner2TitleValid },
                attrs: {
                  type: "text",
                  name: "signer2-title",
                  id: "signer2-field-title",
                  required: "",
                },
                domProps: { value: _vm.formData.signer2Title },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.formData,
                      "signer2Title",
                      $event.target.value.trim()
                    )
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]),
            _c(
              "div",
              { staticClass: "mcp-admin__form-field flex-grow-1 ml-3" },
              [
                _c(
                  "label",
                  {
                    staticClass: "dc-label mt-2",
                    class: {
                      "dc-label--is-error": !_vm.isSigner2EmailValid,
                      "dc-label--required": _vm.isSigner2FieldsRequired,
                    },
                    attrs: { for: "signer2-field-email" },
                  },
                  [_vm._v("Signer Email")]
                ),
                !_vm.isSigner2EmailValid
                  ? _c(
                      "div",
                      {
                        staticClass: "mcp-admin__field-error-message",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("span", { staticClass: "dc--text-error" }, [
                          _vm._v("Please add correct email."),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.signer2Email,
                      expression: "formData.signer2Email",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "dc-input dc-input--block",
                  class: { "dc-input--is-error": !_vm.isSigner2EmailValid },
                  attrs: {
                    type: "text",
                    name: "signer2-email",
                    id: "signer2-field-email",
                    required: "",
                  },
                  domProps: { value: _vm.formData.signer2Email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.formData,
                        "signer2Email",
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]
            ),
          ]),
          _c("div", { staticClass: "mcp-admin__form-field d-inline-block" }, [
            _c(
              "label",
              {
                staticClass: "dc-label dc-label--required mt-2",
                class: {
                  "dc-label--is-error": !_vm.isAddressCertificateIdValid,
                },
                attrs: { for: "legal-entity-field-address-cert" },
              },
              [_vm._v("Address Proof")]
            ),
            !_vm.isAddressCertificateIdValid
              ? _c(
                  "div",
                  {
                    staticClass: "mcp-admin__field-error-message",
                    attrs: { role: "alert" },
                  },
                  [
                    _c("span", { staticClass: "dc--text-error" }, [
                      _vm._v("Please don't leave this empty."),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.isUploadingAddressCertificate
              ? _c("div", { staticClass: "dc-spinner dc-spinner--small" })
              : _c("div", [
                  !_vm.formData.addressCertificateId
                    ? _c("input", {
                        staticClass: "dc-input dc-input--block",
                        class: {
                          "dc-input--is-error":
                            !_vm.isAddressCertificateIdValid,
                        },
                        attrs: {
                          accept: "application/pdf",
                          type: "file",
                          name: "address-cert",
                          id: "legal-entity-field-address-cert",
                          required: "",
                        },
                        on: { change: _vm.onAddressCertificateUpload },
                      })
                    : _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("VatCertificate", {
                            attrs: {
                              "certificate-id":
                                _vm.formData.addressCertificateId,
                            },
                          }),
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.formData.addressCertificateId = ""
                                },
                              },
                            },
                            [
                              _c(VIcon, { staticClass: "ml-3" }, [
                                _vm._v("mdi-trash-can-outline"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]),
          ]),
          _c(VDivider, { staticClass: "my-6" }),
          _c(
            "div",
            { staticClass: "heading text-h6 font-weight-regular mb-3" },
            [_vm._v("Legal VAT details")]
          ),
          _c("div", { staticClass: "mcp-admin__form-field width-half" }, [
            _c(
              "label",
              {
                staticClass: "dc-label dc-label--required mt-2",
                class: { "dc-label--is-error": !_vm.isVatIdValid },
                attrs: { for: "legal-entity-field-vat-id" },
              },
              [_vm._v("Company Value Added Tax (VAT)")]
            ),
            !_vm.isVatIdValid
              ? _c(
                  "div",
                  {
                    staticClass: "mcp-admin__field-error-message",
                    attrs: { role: "alert" },
                  },
                  [
                    _c("span", { staticClass: "dc--text-error" }, [
                      _vm._v("Please don't leave this empty."),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.formData.vatId,
                  expression: "formData.vatId",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "dc-input dc-input--block",
              class: { "dc-input--is-error": !_vm.isVatIdValid },
              attrs: {
                type: "text",
                name: "vat-id",
                id: "legal-entity-field-vat-id",
                required: "",
              },
              domProps: { value: _vm.formData.vatId },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.formData, "vatId", $event.target.value.trim())
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _c("div", { staticClass: "mcp-admin__form-field d-inline-block" }, [
            _c(
              "label",
              {
                staticClass: "dc-label dc-label--required mt-2",
                class: { "dc-label--is-error": !_vm.isVatCertificateIdValid },
                attrs: { for: "legal-entity-field-vat-cert" },
              },
              [_vm._v("Vat Id Proof")]
            ),
            !_vm.isVatCertificateIdValid
              ? _c(
                  "div",
                  {
                    staticClass: "mcp-admin__field-error-message",
                    attrs: { role: "alert" },
                  },
                  [
                    _c("span", { staticClass: "dc--text-error" }, [
                      _vm._v("Please don't leave this empty."),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.isUploadingVatCertificate
              ? _c("div", { staticClass: "dc-spinner dc-spinner--small" })
              : _c("div", [
                  !_vm.formData.certificateId
                    ? _c("input", {
                        class: {
                          "dc-input--is-error": !_vm.isVatCertificateIdValid,
                        },
                        attrs: {
                          accept: "application/pdf",
                          type: "file",
                          name: "address-cert",
                          id: "legal-entity-field-vat-cert",
                          required: "",
                        },
                        on: { change: _vm.onVatCertificateUpload },
                      })
                    : _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("VatCertificate", {
                            attrs: {
                              "certificate-id": _vm.formData.certificateId,
                            },
                          }),
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.formData.certificateId = ""
                                },
                              },
                            },
                            [
                              _c(VIcon, { staticClass: "ml-3" }, [
                                _vm._v("mdi-trash-can-outline"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "d-flex justify-end mr-5" }, [
        _c(
          "button",
          {
            staticClass: "dc-btn dc-btn--link",
            on: {
              click: function ($event) {
                return _vm.$emit("on-cancel")
              },
            },
          },
          [_vm._v("Cancel")]
        ),
        _c(
          "button",
          {
            staticClass: "dc-btn dc-btn--primary",
            attrs: { type: "submit" },
            on: { click: _vm.onSave },
          },
          [_vm._v("Update")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }