<template lang="pug">
  div
    v-snackbar(v-model="showError" :timeout="5000" color="reject" bottom right)
      | {{errorMessage}}

    div(v-if="isFetchingMetaData" ).dc-spinner.dc-spinner--small

    div(v-else)
        DocumentUpload(
          :document-id="documentId"
          :file-name="filename"
          label="Upload Confirmation Document"
          @upload-document="handleDocumentUpload"
        )

</template>

<script lang="ts">
import Vue from 'vue';
import {
  fetchBankConfirmationCertificateMetaData,
  uploadBankConfirmationCertificate,
} from 'Api/endpoints/merchant/merchant-bank-accounts.endpoint';
import DocumentUpload from 'Merchant/common/components/document-upload/document-upload.vue';

const METADATA_FETCH_FAILED =
  'Failed to fetch matadata for the certificate file.';

interface StoreState {
  merchantId: string;
}

export default Vue.extend({
  components: { DocumentUpload },
  props: {
    documentId: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      errorMessage: '',
      showError: false,
      isFetchingMetaData: false,
      filename: '',
      // @ts-ignore
      ...(this.mapState({
        merchantId: 'flow.general.merchant.data.$id',
      }) as StoreState),
    };
  },

  async mounted() {
    try {
      if (this.documentId) {
        this.isFetchingMetaData = true;
        const metadata = await fetchBankConfirmationCertificateMetaData(
          this.merchantId,
          this.documentId
        );
        this.filename = metadata.filename;
      }
    } catch (e) {
      this.showErrorSnackbar(METADATA_FETCH_FAILED);
    } finally {
      this.isFetchingMetaData = false;
    }
  },

  methods: {
    async handleDocumentUpload(file: Blob): Promise<void> {
      const metaInfo = await uploadBankConfirmationCertificate(
        this.merchantId,
        file
      );
      this.$emit('on-upload-finish', metaInfo.confirmationId);
    },
    showErrorSnackbar(errorMessage: string): void {
      this.showError = true;
      this.errorMessage = errorMessage;
    },
  },
});
</script>
