import { ProviderFormField } from 'Common/constants/configuration.constant';
import { Configuration } from 'Api/models/Configuration';

interface FortuneglobeConfigFormParams {
  baseUrl: string;
  partnerName: string;
}

export function generateFortuneglobeConfig(
  config: Configuration, // current config
  params: FortuneglobeConfigFormParams
): Configuration {
  const { baseUrl, partnerName } = params;

  return {
    ...config,
    articleConfig: {
      ...config.articleConfig,
      articleUrl: `${baseUrl}/alvine/services/zalando/index.php?&sxx_action=getitems&sxx_data[partner]=${partnerName}&sxx_baseauth=TRUE`,
      stockUrl: `${baseUrl}/alvine/services/zalando/index.php?&sxx_action=getinventory&sxx_data[partner]=${partnerName}&sxx_baseauth=TRUE`,
    },
  };
}

export function getFortuneglobeFormFields(): ProviderFormField[] {
  return [
    { label: 'Base Url', name: 'baseUrl', required: true },
    { label: 'Partnername', name: 'partnerName', required: true },
  ];
}
