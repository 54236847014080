var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "field" }, [
      _c("div", { staticClass: "label text-caption text-uppercase" }, [
        _vm._v("Is Using LRD?"),
      ]),
      _vm.details.isUsingLrd
        ? _c("div", [_vm._v("Yes")])
        : _c("div", [_vm._v("No")]),
    ]),
    _vm.details.lrdCertificateId
      ? _c(
          "div",
          { staticClass: "field" },
          [
            _c("div", { staticClass: "label text-caption text-uppercase" }, [
              _vm._v("LRD Proof"),
            ]),
            _c("VatCertificate", {
              attrs: { "certificate-id": _vm.details.lrdCertificateId },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }