<template lang="pug">
Appbar(
  :env="env"
  :roles="roles"
  :currentRole="currentRole"
  :username="username"
  :tabs="tabsNames"
  :activeTab="activeTab"
  :hideTabs="hideTabs"
  @tab-changed="changeTab"
  @role-changed="switchRole"
  @logout="logout"
)
  slot
</template>

<script lang="ts">
import Vue from 'vue';
import Appbar from './appbar.vue';
import { ENV_CURRENT, ENVIRONMENT } from 'Common/constants/misc.constant';
import {
  ROLES_TO_DEFAULT_MERCHANT_ROUTE_STATE_MAP,
  ROLES_TO_DEFAULT_CONTRACT_ROUTE_STATE_MAP,
} from 'Common/constants/roles.constant';
import { hasEditPermissions } from 'Common/services/permissions.service';
import { getToken, getUser } from '@/authentication/token.helper';
import { logout } from '@/authentication/authentication.service';

const ENVIRONMENT_LABEL = ENVIRONMENT[ENV_CURRENT] || 'Not detected!';

interface TabT {
  label: string;
  route: string;
}

const MERCHANTS_TAB: TabT = {
  label: 'Merchants',
  route: 'mcp.admin.merchants',
};

const MERCHANTS_ACCOUNTS_TAB: TabT = {
  label: 'Merchant Accounts',
  route: 'mcp.admin.merchant-accounts',
};

export default Vue.extend({
  components: {
    Appbar,
  },

  props: {
    hideTabs: Boolean,
  },

  data() {
    const user = getUser();
    const token = getToken();

    return {
      env: ENVIRONMENT_LABEL,
      username: user ? user.fullName : '',
      roles: token.getRoles(),
      currentRole: token.getCurrentRole(),
    };
  },

  computed: {
    tabs(): TabT[] {
      if (!hasEditPermissions()) {
        return [MERCHANTS_TAB];
      }

      return [MERCHANTS_TAB, MERCHANTS_ACCOUNTS_TAB];
    },

    tabsNames(): string[] {
      return this.tabs.map((tab) => tab.label);
    },

    activeTab(): number {
      let activeTabIndex = this.tabs.findIndex(
        (tab) => this.$route.name === tab.route
      );

      return activeTabIndex === -1 ? null : activeTabIndex;
    },
  },

  methods: {
    changeTab(tabIndex: number): void {
      window.location.assign(
        this.$router.resolve({
          name: this.tabs[tabIndex].route,
        }).href
      );
    },

    switchRole(newRole: string): void {
      const { roles, $route, $router } = this;
      const { contractId, merchantId } = $route.params;
      const roleIndex = roles.indexOf(newRole);
      let newUrlPath;

      getToken().setCurrentRole(newRole);

      if (!contractId && !merchantId) {
        newUrlPath = $router.resolve({ name: 'mcp.admin.merchants' }).href;
      } else {
        const newRouteState = contractId
          ? ROLES_TO_DEFAULT_CONTRACT_ROUTE_STATE_MAP[newRole]
          : ROLES_TO_DEFAULT_MERCHANT_ROUTE_STATE_MAP[newRole];
        newUrlPath = $route.resolve({
          name: newRouteState,
          params: { merchantId, contractId },
        }).href;
      }

      const newUrl = new URL(window.location.origin + '/' + newUrlPath);

      // Set a query parameter to achieve full page reload
      newUrl.searchParams.set('randomK', roleIndex.toString());
      window.location.assign(newUrl.toString());
    },

    logout(): void {
      logout().then(() => window.location.reload());
    },
  },
});
</script>

<style lang="scss" scoped></style>
