import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VRow,
    { staticStyle: { height: "100vh" }, attrs: { "align-content": "center" } },
    [
      _c(
        VCol,
        { staticClass: "text-center" },
        [
          _c(VProgressCircular, {
            attrs: { indeterminate: "", color: "secondaryDark" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }