import { render, staticRenderFns } from "./merchant-register.component.vue?vue&type=template&id=4c090736&scoped=true"
import script from "./merchant-register.component.vue?vue&type=script&lang=ts"
export * from "./merchant-register.component.vue?vue&type=script&lang=ts"
import style0 from "./merchant-register.component.vue?vue&type=style&index=0&id=4c090736&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c090736",
  null
  
)

export default component.exports