<template lang="pug">
  VatReviewSection(
    :title="title"
    status="PENDING"
  )
    div.mb-3.text-body-1
      | {{infoMessage}}

</template>

<script lang="ts">
import Vue from 'vue';
import VatReviewSection from 'Merchant/common/components/vat-review-section/vat-review-section.vue';

export default Vue.extend({
  components: { VatReviewSection },

  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    infoMessage() {
      return this.message || `No ${this.title} data available`;
    },
  },
});
</script>

<style lang="scss" scoped></style>
