import { ApiService } from 'Api/services/api.service';
import { Configuration } from 'Api/models/Configuration';
import { ConfigProviderFlagsT } from 'Api/models/ConfigPartnerProviderDefaults';
import { Partner, Contract } from 'Api/models/Contract';
import {
  PROVIDER_ANATWINE,
  PROVIDER_CHANNEL_ADVISOR,
  PROVIDER_TRADEBYTE,
  PROVIDER_FORTUNEGLOBE,
  PROVIDER_NOVOMIND,
  PROVIDER_NETEVEN,
} from '../../merchant.constant';
import { ConfigFormFieldParams } from 'Common/constants/configuration.constant';
import {
  generateAnatwineConfig,
  getAnatwineFormFields,
} from './configuration-anatwine.helper';
import {
  generateTradebyteConfig,
  getTradebyteFormFields,
} from './configuration-tradebyte.helper';
import {
  generateChAdvisorConfig,
  getChAdvisorFormFields,
} from './configuration-ch-advisor.helper';
import {
  generateFortuneglobeConfig,
  getFortuneglobeFormFields,
} from './configuration-fortuneglobe.helper';
import {
  generateNetevenConfig,
  getNetevenFormFields,
} from './configuration-neteven.helper';
import {
  generateNovomindConfig,
  getNovomindFormFields,
} from './configuration-novomind.helper';

export const generateConfigFnMap = {
  [PROVIDER_ANATWINE]: generateAnatwineConfig,
  [PROVIDER_CHANNEL_ADVISOR]: generateChAdvisorConfig,
  [PROVIDER_TRADEBYTE]: generateTradebyteConfig,
  [PROVIDER_FORTUNEGLOBE]: generateFortuneglobeConfig,
  [PROVIDER_NOVOMIND]: generateNovomindConfig,
  [PROVIDER_NETEVEN]: generateNetevenConfig,
};

export const providerFormFieldsMap = {
  [PROVIDER_ANATWINE]: getAnatwineFormFields,
  [PROVIDER_CHANNEL_ADVISOR]: getChAdvisorFormFields,
  [PROVIDER_TRADEBYTE]: getTradebyteFormFields,
  [PROVIDER_FORTUNEGLOBE]: getFortuneglobeFormFields,
  [PROVIDER_NOVOMIND]: getNovomindFormFields,
  [PROVIDER_NETEVEN]: getNetevenFormFields,
};

export class ConfigurationService {
  static $inject = ['mcpAdminApi'];

  constructor(private api: ApiService) {}

  fetchConfiguration(
    contract: Contract,
    locale: string
  ): ng.IPromise<Configuration> {
    const partner = this.extractPartner(contract, locale);
    return this.api.configuration.fetchConfiguration(partner.$id);
  }

  fetchConfigurationByPartnerId(partnerId: string): ng.IPromise<Configuration> {
    return this.api.configuration.fetchConfiguration(partnerId);
  }

  fetchProviderDefaults(providerId: number): ng.IPromise<ConfigProviderFlagsT> {
    return this.api.configuration.fetchConfiurationProviderDefaults(providerId);
  }

  updateConfiguration(
    configuration: Configuration
  ): ng.IPromise<Configuration> {
    const partialConf = Object.assign({}, configuration);
    // Supplier code, sendCustomerEmail and shipmentCarrier is changed
    // using Merchant API and being synced down to Configurations.
    // Therefore we shouldn't accidentally overwrite it using Configuration API.
    delete partialConf.supplierCode;
    delete partialConf.sendCustomerEmail;
    delete partialConf.orderConfig.shipmentCarrier;

    // partnerProviderId is changed using Contract API and is being synced down to Configurations.
    // Therefore we shouldn't accidentally overwrite it using Configuration API.
    // @ts-ignore
    delete partialConf.partnerProvider;
    // @ts-ignore
    delete partialConf.partnerProviderId;

    return this.api.configuration.updateConfiguration(partialConf);
  }

  public extractPartner(contract: Contract, locale: string): Partner {
    if (!locale || !contract.partners || contract.partners.length < 1) {
      return null;
    }
    return (
      contract.partners.filter((p: Partner) => p.locale === locale)[0] || null
    );
  }

  public getProviderFormFields(
    providerId: number,
    params: ConfigFormFieldParams
  ): any[] {
    return providerFormFieldsMap[providerId](params);
  }

  public isConfigGenerationAvailable(providerId: number): boolean {
    return !!generateConfigFnMap[providerId];
  }

  /**
   * Generate new config from the existing one, using user/backend provided data
   */
  public generateConfig(
    providerId,
    urlsParams,
    config: Configuration
  ): Configuration {
    return generateConfigFnMap[providerId](config, urlsParams);
  }
}
