import axios, { AxiosPromise } from 'axios';
import { setToken, clearToken } from '@/authentication/token.helper';
import { ROLES_LIST } from 'Common/constants/roles.constant';

export function logout(): AxiosPromise<unknown> {
  clearToken();
  return axios.get('/auth/zalando/logout');
}

export function AuthenticationService(McIamAuthToken, $http, $q) {
  function getMerchantProfileRoles(groups): string[] {
    const prefix = 'Apps/Merchant-Profile/';

    return groups
      .filter((group) => group.name.startsWith(prefix))
      .map((group) => group.name.replace(prefix, ''))
      .filter((role) => ROLES_LIST.includes(role));
  }

  function initToken(result) {
    const authToken = new McIamAuthToken(
      result.data,
      getMerchantProfileRoles(result.data.groups)
    );
    setToken(authToken);
  }

  function refreshToken() {
    return $http.get('/auth/zalando/refresh-token');
  }

  return {
    authenticate: () => {
      const defer = $q.defer();

      $http
        .get('/auth/zalando/me')
        .then((result) => {
          initToken(result);
          defer.resolve();
        })
        .catch(() => {
          refreshToken()
            .then((result) => {
              initToken(result);
              defer.resolve();
            })
            .catch((err) => defer.reject(err));
        });

      return defer.promise;
    },

    verifyToken: () => {
      return $http.get('/auth/zalando/me');
    },

    refreshToken: refreshToken,

    logout: () => {
      clearToken();
      return $http.get('/auth/zalando/logout');
    },
  };
}

AuthenticationService.$inject = ['McIamAuthToken', '$http', '$q'];
