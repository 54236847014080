import {
  ProviderFormField,
  ContextParams,
  ConfigFormFieldParams,
} from 'Common/constants/configuration.constant';
import { CHANNELS, LOCALES } from 'Common/constants/channels.constant';
import { Configuration } from 'Api/models/Configuration';
import { ConfigPartnerProvider } from 'Api/models/ConfigPartnerProvider';
import { MCP_FF_TYPES } from 'Common/constants/misc.constant';

interface AnatwineConfigFormParams {
  merchantId: string;
  channelId: string;
}

interface AnatwineUrlParams
  extends ConfigPartnerProvider,
    ContextParams,
    AnatwineConfigFormParams {}

export function generateAnatwineConfig(
  config: Configuration, // current config
  params: AnatwineUrlParams
): Configuration {
  const {
    merchantId,
    channelId,
    ftpPassword,
    merchantName,
    channelCountryCode,
    locale,
    isZfs,
    password,
  } = params;

  const extChannelPrefix = isZfs ? 'zfs' : 'za';
  const ftpExternalChannel = `${extChannelPrefix}${channelCountryCode}`;

  return {
    ...config,
    articleConfig: {
      ...config.articleConfig,
      articleUrl: `https://ymoovdoawbif.anatwine.com/integration/articles/zalando/v2.0/${channelId}/${merchantId}/articles`,
      stockUrl: `https://ymoovdoawbif.anatwine.com/integration/stocks/zalando/v1.0/${channelId}/${merchantId}/stock/{LastUpdateUnixTimeStamp}`,
    },
    orderConfig: {
      ...config.orderConfig,
      orderExportUrl: `https://ymoovdoawbif.anatwine.com/integration/orders/zalando/v1.0/${channelId}/${merchantId}/order/`,
      orderConfirmationUrl: `https://ymoovdoawbif.anatwine.com/integration/orders/zalando/v1.0/${channelId}/${merchantId}/order/{partnerOrderNumber}/approved`,
      orderCancellationUrl: `https://ymoovdoawbif.anatwine.com/integration/orders/zalando/v1.0/${channelId}/${merchantId}/order/{partnerOrderNumber}/cancelled`,
      orderMessagesUrl: `https://ymoovdoawbif.anatwine.com/integration/messages/zalando/v1.0/${channelId}/${merchantId}/messages/`,
      orderMessagesProcessUrl: `https://ymoovdoawbif.anatwine.com/integration/messages/zalando/v1.0/${channelId}/${merchantId}/messages/{messageId}/approved`,
      orderSingleStatusUrl: `https://ymoovdoawbif.anatwine.com/integration/orders/zalando/v1.0/${channelId}/${merchantId}/order/{zalandoOrderNumber}`,
    },
    ftpConfig: {
      ...config.ftpConfig,
      ftpUsername: 'zalando',
      ftpPassword,
      ftpUrl: 'ftp://tyuhlglrsarl.anatwine.com',
      ftpPath: '/zalando/partner-article-status-report/',
      ftpExternalPartnerName: merchantName,
      ftpExternalChannel,
      ftpExternalSubChannel: getFtpExternalSubChannel(locale),
    },
    username: 'zalando',
    password,
  };
}

export function getAnatwineFormFields({
  countryCode,
  isZfs,
  locale,
}: ConfigFormFieldParams): ProviderFormField[] {
  return [
    { label: 'Anatwine Merchant ID', name: 'merchantId', required: true },
    {
      label: 'Anatwine Channel ID',
      name: 'channelId',
      required: true,
      defaultValue: getChannelId(countryCode, isZfs, locale),
    },
  ];
}

function getChannelId(
  countryCode: string,
  isZfs: boolean,
  locale: string
): string {
  const { PF, ZFS } = MCP_FF_TYPES;
  const ffType = isZfs ? ZFS : PF;
  const data = {
    [CHANNELS.AUSTRIA + LOCALES.AUSTRIA.DE_AT + PF]: 'zaat',
    [CHANNELS.AUSTRIA + LOCALES.AUSTRIA.DE_AT + ZFS]: 'zfsat',
    [CHANNELS.BELGIUM + LOCALES.BELGIUM.FR_BE + PF]: 'zabefr',
    [CHANNELS.BELGIUM + LOCALES.BELGIUM.NL_BE + PF]: 'zabenl',
    [CHANNELS.BELGIUM + LOCALES.BELGIUM.FR_BE + ZFS]: 'zfsbefr',
    [CHANNELS.BELGIUM + LOCALES.BELGIUM.NL_BE + ZFS]: 'zfsbenl',
    [CHANNELS.SWITZERLAND + LOCALES.SWITZERLAND.FR_CH + PF]: 'zachfr',
    [CHANNELS.SWITZERLAND + LOCALES.SWITZERLAND.DE_CH + PF]: 'zachde',
    [CHANNELS.GERMANY + LOCALES.GERMANY.DE_DE + PF]: 'zade',
    [CHANNELS.GERMANY + LOCALES.GERMANY.DE_DE + ZFS]: 'zfsde',
    [CHANNELS.DENMARK + LOCALES.DENMARK.DA_DK + PF]: 'zadk',
    [CHANNELS.DENMARK + LOCALES.DENMARK.DA_DK + ZFS]: 'zfsdk',
    [CHANNELS.SPAIN + LOCALES.SPAIN.ES_ES + PF]: 'zaes',
    [CHANNELS.SPAIN + LOCALES.SPAIN.ES_ES + ZFS]: 'zfses',
    [CHANNELS.FINLAND + LOCALES.FINLAND.FI_FI + PF]: 'zafi',
    [CHANNELS.FINLAND + LOCALES.FINLAND.FI_FI + ZFS]: 'zfsfi',
    [CHANNELS.FRANCE + LOCALES.FRANCE.FR_FR + PF]: 'zafr',
    [CHANNELS.FRANCE + LOCALES.FRANCE.FR_FR + ZFS]: 'zfsfr',
    [CHANNELS.UNITED_KINGDOM + LOCALES.UNITED_KINGDOM.EN_GB + PF]: 'zauk',
    [CHANNELS.UNITED_KINGDOM + LOCALES.UNITED_KINGDOM.EN_GB + ZFS]: 'zfsuk',
    [CHANNELS.ITALY + LOCALES.ITALY.IT_IT + PF]: 'zait',
    [CHANNELS.ITALY + LOCALES.ITALY.IT_IT + ZFS]: 'zfsit',
    [CHANNELS.NETHERLANDS + LOCALES.NETHERLANDS.NL_NL + PF]: 'zanl',
    [CHANNELS.NETHERLANDS + LOCALES.NETHERLANDS.NL_NL + ZFS]: 'zfsnl',
    [CHANNELS.NORWAY + LOCALES.NORWAY.NO_NO + PF]: 'zano',
    [CHANNELS.POLAND + LOCALES.POLAND.PL_PL + PF]: 'zapl',
    [CHANNELS.POLAND + LOCALES.POLAND.PL_PL + ZFS]: 'zfspl',
    [CHANNELS.SWEDEN + LOCALES.SWEDEN.SV_SE + PF]: 'zase',
    [CHANNELS.SWEDEN + LOCALES.SWEDEN.SV_SE + ZFS]: 'zfsse',
    [CHANNELS.ESTONIA + LOCALES.ESTONIA.ET_EE + ZFS]: 'zfsee',
    [CHANNELS.CROATIA + LOCALES.CROATIA.HR_HR + ZFS]: 'zfshr',
    [CHANNELS.LITHUANIA + LOCALES.LITHUANIA.LT_LT + ZFS]: 'zfslt',
    [CHANNELS.LATVIA + LOCALES.LATVIA.LV_LV + ZFS]: 'zfslv',
    [CHANNELS.SLOVENIA + LOCALES.SLOVENIA.SL_SI + ZFS]: 'zfssi',
    [CHANNELS.SLOVAKIA + LOCALES.SLOVAKIA.SK_SK + ZFS]: 'zfssk',
  };

  return data[countryCode + locale + ffType];
}

function getFtpExternalSubChannel(locale: string): string {
  switch (locale) {
    case 'fr-BE':
    case 'fr-CH':
      return 'fr';
    case 'nl-BE':
      return 'nl';
    case 'de-CH':
      return 'de';
    default:
      return '';
  }
}
