import { mcb } from 'mcb';
import Cookies from 'vue-cookies';
import monitoringHelper from '@/monitoring/monitoring.helper';

export default function errorHandler(error) {
  let { response = { status: 'UNKNOWN', statusText: 'UNKNOWN' } } = error;

  if (response.status !== 401 && response.status !== 404) {
    reportToSentry(response);
  }

  if (response.status === 401) {
    handleUnauthorized();
  }

  return Promise.reject(error);
}

export function nullIf404Handler(err) {
  if (err.response.status === 404) {
    return Promise.resolve(null);
  }

  return Promise.reject(err);
}

/**
 * 401 handler
 * Open the Login page
 */
function handleUnauthorized(): void {
  // @ts-ignore
  Cookies.set('returnUrl', window.location.href);
  window.location.assign('/auth/zalando/login');
}

function reportToSentry(response: {
  status: string;
  data: mcb.http.IError[] | mcb.http.IError;
  config: {
    url: string;
  };
}): void {
  // Temporarily ignore to report failing requests to master-data/export as the service is unstable at the moment and will be decommissioned
  if (response.config.url.endsWith('master-data/export')) {
    return;
  }

  const data = (response.data && (response.data[0] || response.data)) || {
    title: 'NO TITLE PROVIDED',
    detail: JSON.stringify(response),
  };
  monitoringHelper.reportMessage(`API Error ${response.status}`, {
    level: 'error',
    tags: { uiUrl: window.location.href },
    extra: {
      httpStatus: response.status,
      errorTitle: data.title,
      errorDescr: data.detail,
    },
  });
}
