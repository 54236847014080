export function accessibleForm() {
  return {
    restrict: 'A',
    link: function (scope, elem) {
      elem.on('submit', function () {
        const firstInvalid = elem[0].querySelector('.ng-invalid');

        // if we find one, set focus
        if (firstInvalid) {
          firstInvalid.focus();
        }
      });
    },
  };
}
