import { MCP_CONTRACT_STATUSES as STATUSES } from 'Common/constants/contract-statuses.constant';
import { ContractRequirements } from '../../models/ContractRequirements';
import { Endpoint } from '../../services/endpoint';
import { RequestConfig } from '../../services/http.service';

export class ContractStatusEndpoint extends Endpoint {
  protected resourceType = 'ContractStatus';

  fetchContractRequirements(
    contractId: string
  ): ng.IPromise<ContractRequirements> {
    const url =
      this.endpointsService.getContractRequirementsUrlTemplate(contractId);
    const config: RequestConfig = { method: 'GET', url: url };
    return this.http.request(config);
  }

  markAsInFinancialReview(contractId: string): ng.IPromise<void> {
    return this.changeContractStatus(contractId, STATUSES.IN_FINANCIAL_REVIEW);
  }

  processMultipleContracts(
    contractIds: string[],
    status: string
  ): ng.IPromise<void> {
    let promises: ng.IPromise<void>[] = [];
    contractIds.forEach((contractId) => {
      promises.push(this.changeContractStatus(contractId, status));
    });
    return this.http.all(promises).then(() => {
      return;
    });
  }

  private changeContractStatus(
    contractId: string,
    status: string,
    comment: string | undefined = undefined
  ): ng.IPromise<void> {
    const url =
      this.endpointsService.getContractStatusChangeUrlTemplate(contractId);
    const config: RequestConfig = {
      method: 'POST',
      url,
      data: { status: status, approverComment: comment },
      resourceType: this.resourceType,
    };
    return this.http.request(config);
  }
}
