import { MerchantProfile } from 'Api/models/MerchantProfile';
import { MerchantRequirements } from 'Api/models/MerchantRequiremenets';
import {
  MERCHANT_GROUPS_LOADING,
  MERCHANT_GROUPS_LOADED,
} from '../reducers/merchant-groups.reducer';
import { REQUIREMENTS_LOADED } from '../reducers/status.reducer';
import {
  MERCHANT_LOADED,
  MERCHANT_LOADING,
} from '../reducers/merchant.reducer';
import {
  CONTRACTS_LOADED,
  CONTRACTS_LOADING,
} from '../reducers/contracts.reducer';
import { Contract } from 'Api/models/Contract';
import {
  CONTRACT_LOADED,
  CONTRACT_LOADING,
} from '../reducers/contract.reducer';
import {
  FACTORING_MIGRATION_LOADED,
  FACTORING_MIGRATION_LOADING,
} from '../reducers/factoring-migration-status.reducer';
import { FlowStateSelector } from '../selectors/flow-state.selector';
import { FlowState, initialFlowState } from '../flow.state';
import { HYDRATE_STATE } from '../reducers/root.reducer';
import { ApiService } from 'Api/services/api.service';

export class MerchantFlowActions {
  static $inject = ['mcpAdminFlowStateSelector', 'mcpAdminApi', '$q'];

  constructor(
    private flowSelector: FlowStateSelector,
    private api: ApiService,
    private q: ng.IQService
  ) {}

  hydrateState() {
    return (dispatch) => {
      dispatch({ type: HYDRATE_STATE, payload: initialFlowState });
    };
  }

  // Reload Merchant Requirements
  setFlowStatus(merchant?: MerchantProfile) {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const currentMerchant: MerchantProfile =
        merchant || this.flowSelector.getFlowMerchant(state);
      this.api.merchantStatus
        .fetchMerchantRequirements(currentMerchant.$id)
        .then((merchantStatus: MerchantRequirements) => {
          dispatch({ type: REQUIREMENTS_LOADED, payload: merchantStatus });
        });
    };
  }

  setCurrentMerchant(merchantId?: string) {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const currentMerchant: MerchantProfile =
        this.flowSelector.getFlowMerchant(state);
      const currentMerchantId = merchantId || currentMerchant.$id;
      dispatch({ type: MERCHANT_LOADING });
      return this.api.merchant
        .fetchMerchant(currentMerchantId)
        .then((merchant: MerchantProfile) => {
          dispatch({ type: MERCHANT_LOADED, payload: merchant });
          return merchant;
        });
    };
  }

  setCurrentContract(contractId?: string) {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const currentContract: Contract =
        this.flowSelector.getFlowContract(state);
      const currentContractId: string = contractId || currentContract.$id;
      // It can be a case that currentContractId is undefined if current page is not a contract page
      // e.g. send to fin review from a company page
      if (!currentContractId) {
        return this.q.resolve();
      }

      dispatch({ type: CONTRACT_LOADING });
      return this.api.contract
        .fetchContract(currentContractId)
        .then((contract: Contract) => {
          dispatch({ type: CONTRACT_LOADED, payload: contract });
        });
    };
  }

  setCurrentContracts(merchant?: MerchantProfile) {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const currentMerchant: MerchantProfile =
        this.flowSelector.getFlowMerchant(state);
      dispatch({ type: CONTRACTS_LOADING });
      return this.api.merchant
        .fetchMerchantContracts(currentMerchant)
        .then((contracts: Contract[]) => {
          dispatch({ type: CONTRACTS_LOADED, payload: contracts });
          return contracts;
        });
    };
  }

  /**
   * Fetch merchant groups list and store it in redux
   */
  setMerchantGroups() {
    return (dispatch) => {
      dispatch({ type: MERCHANT_GROUPS_LOADING });

      return this.api.merchant.fetchMerchantGroups().then((payload) => {
        dispatch({ type: MERCHANT_GROUPS_LOADED, payload });
        return payload;
      });
    };
  }

  setMerchantFactoringMigrationStatus(merchantId?: string) {
    return (dispatch, getState) => {
      const state: FlowState = getState();
      const currentMerchant: MerchantProfile =
        this.flowSelector.getFlowMerchant(state);
      const currentMerchantId = merchantId || currentMerchant.$id;
      dispatch({ type: FACTORING_MIGRATION_LOADING });
      return this.api.factoringMigrationStatus
        .fetchFactoringMigrationStatus(currentMerchantId)
        .then((response) => {
          dispatch({
            type: FACTORING_MIGRATION_LOADED,
            payload: response.migration,
          });
          return response.migration;
        });
    };
  }
}
