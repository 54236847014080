var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "root mcp-admin-merchant-details-section",
      class: { "is-loading": _vm.isLoading },
    },
    [
      _c(
        "div",
        { staticClass: "mcp-admin-merchant-details-section__header" },
        [
          _vm._v("Company Bank Data"),
          !_vm.isLoading && _vm.showEditLink
            ? _c("ConfirmDataChange", {
                attrs: {
                  confirmCb: _vm.onEditHandler,
                  customEditText: _vm.isMarketSpecific
                    ? ""
                    : "Use Different Bank Account",
                },
              })
            : _vm._e(),
          _vm.showMarketSpecificLabel && _vm.isMarketSpecific
            ? _c(
                "span",
                { staticClass: "mcp-admin-merchant-detail__header-label" },
                [_vm._v("Sales channel specific data")]
              )
            : _vm._e(),
          _vm.showDataChangedLabel
            ? _c(
                "span",
                { staticClass: "mcp-admin-merchant-detail__header-label" },
                [_vm._v("Data for review")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.isLoading
        ? _c(
            "div",
            {
              staticClass: "mcp-admin__spinner-container",
              attrs: { "data-testid": "loading" },
            },
            [_c("div", { staticClass: "dc-spinner" })]
          )
        : _vm.isEditMode
        ? _c("BankDataForm", {
            attrs: {
              "show-use-merchant-bank-data-link": _vm.isMarketSpecific,
              "channel-country": _vm.countryName,
              "is-submitting": _vm.isSubmitting,
              "initial-data": _vm.initialFormData,
              "company-name": _vm.companyName,
            },
            on: {
              cancel: _vm.cancel,
              save: _vm.save,
              useMerchantData: _vm.useMerchantData,
            },
          })
        : !_vm.data
        ? _c("div", [_vm._v("No bank account data available")])
        : _c("div", [
            _c("div", { staticClass: "field" }, [
              _c("div", { staticClass: "label" }, [_vm._v("Bank")]),
              _c("div", [_vm._v(_vm._s(_vm.data.bankName))]),
            ]),
            _c("div", { staticClass: "field" }, [
              _c("div", { staticClass: "label" }, [_vm._v("IBAN")]),
              _c("div", [_vm._v(_vm._s(_vm.iban))]),
            ]),
            _c("div", { staticClass: "field" }, [
              _c("div", { staticClass: "label" }, [_vm._v("SWIFT/BIC")]),
              _c("div", [_vm._v(_vm._s(_vm.data.bic || "Not defined"))]),
            ]),
            _c("div", { staticClass: "field" }, [
              _c("div", { staticClass: "label" }, [_vm._v("Account holder")]),
              _c("div", [_vm._v(_vm._s(_vm.data.accountHolder))]),
            ]),
            _c("div", { staticClass: "field" }, [
              _c("div", { staticClass: "label" }, [_vm._v("Country")]),
              _c("div", [_vm._v(_vm._s(_vm.country))]),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }