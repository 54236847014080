import {
  MerchantProfile,
  MerchantProfileOptional,
} from '../../models/MerchantProfile';
import { MerchantGroup } from '../../models/MerchantGroup';
import { MerchantProfileList } from '../../models/MerchantProfileList';
import { Contract } from '../../models/Contract';
import { Endpoint } from '../../services/endpoint';
import { RequestConfig } from '../../services/http.service';
import { ChangedData } from 'Api/models/DataSections';
import {
  MerchantMigration,
  MerchantMigrationRequest,
  MerchantsMigrationDetails,
} from '@/api/models/MerchantFactoringMigration';

const RESOURCE_TYPE = 'MerchantProfile';

export interface MerchantsRequestConfig {
  'page[number]'?: number;
  'page[size]'?: number;
  status?: string;
  types?: string;
  sort?: string;
  name?: string;
}

export class MerchantEndpoint extends Endpoint {
  saveMerchant(merchant: MerchantProfile): ng.IPromise<MerchantProfile> {
    let config: RequestConfig = {
      method: 'POST',
      url: this.endpointsService.getMerchantUrlTemplate(),
      data: merchant,
      resourceType: RESOURCE_TYPE,
    };

    return this.http.request(config);
  }

  updateMerchant(
    merchantId: string,
    data: MerchantProfileOptional
  ): ng.IPromise<void> {
    let config: RequestConfig = {
      method: 'PATCH',
      url: this.endpointsService.getMerchantUrlTemplate(merchantId),
      data,
      resourceType: RESOURCE_TYPE,
    };

    return this.http.request(config);
  }

  updateMerchantMetadata(merchantId: string, data): ng.IPromise<void> {
    const filteredData = { ...data };
    // Backend treats empty string as a value, which is wrong.
    // To fix that, we shoulnd't send empty values
    if (!filteredData.supplierCode || !filteredData.supplierCode.trim()) {
      filteredData.supplierCode = undefined;
    }

    let config: RequestConfig = {
      method: 'PATCH',
      url: this.endpointsService.getMerchantUrlTemplate(merchantId),
      data: filteredData,
      resourceType: RESOURCE_TYPE,
    };

    return this.http.request(config);
  }

  fetchMerchants(
    merchantsRequestConfig?: MerchantsRequestConfig
  ): ng.IPromise<MerchantProfileList> {
    let config: RequestConfig = {
      method: 'GET',
      url: this.endpointsService.getMerchantUrlTemplate(),
      params: merchantsRequestConfig || {},
    };

    return this.http.request(config);
  }

  fetchMerchant(merchantId: string): ng.IPromise<MerchantProfile> {
    let config: RequestConfig = {
      method: 'GET',
      url: this.endpointsService.getMerchantUrlTemplate(merchantId),
      include: ['address', 'bank-account'],
      params: {
        include_integration_details: true,
      },
      headers: {
        'Cache-Control': 'no-cache',
      },
    };

    return this.http.request(config);
  }

  fetchMerchantContracts(merchant: MerchantProfile): ng.IPromise<Contract[]> {
    let config: RequestConfig = {
      method: 'GET',
      url: this.endpointsService.getMerchantContractsUrlTemplate(merchant.$id),
    };

    return this.http.request(config);
  }

  fetchMerchantGroups(): ng.IPromise<MerchantGroup[]> {
    let config: RequestConfig = {
      method: 'GET',
      url: this.endpointsService.getMerchantGroupsUrlTemplate(),
    };

    return this.http.request(config);
  }

  fetchMerchantDataChanges(merchantId: string): ng.IPromise<ChangedData> {
    const url = this.endpointsService.getMerchantDataChangesUrl(merchantId);
    const config: RequestConfig = {
      method: 'GET',
      url: url,
    };
    return this.http.requestJson(config);
  }

  createMerchantGroup(data: MerchantGroup): ng.IPromise<MerchantGroup> {
    let config: RequestConfig = {
      method: 'POST',
      url: this.endpointsService.getMerchantGroupsUrlTemplate(),
      data,
      resourceType: 'MerchantGroup',
    };

    return this.http.request(config);
  }

  updateMerchantGroup(
    groupId: string,
    data: MerchantGroup
  ): ng.IPromise<MerchantGroup> {
    let config: RequestConfig = {
      method: 'PATCH',
      url: this.endpointsService.getMerchantGroupUrlTemplate(groupId),
      data,
      resourceType: 'MerchantGroup',
    };

    return this.http.request(config);
  }

  updateMerchantFactoringMigrationDate(
    merchantId: string,
    data: MerchantMigration
  ): ng.IPromise<MerchantsMigrationDetails> {
    const updatedData = {
      ...data,
      merchantId,
    };
    const requestData: MerchantMigrationRequest = {
      merchants: [updatedData],
    };
    let config: RequestConfig = {
      method: 'POST',
      url: this.endpointsService.getMigrateFactoringUrl(),
      data: requestData,
    };

    return this.http.requestJson(config);
  }
}
