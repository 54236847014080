import template from './message.html';
export class MessageComponent implements ng.IComponentOptions {
  static Factory() {
    return new MessageComponent();
  }

  bindings: { [binding: string]: string } = {
    messageTitle: '@',
    messageText: '@',
    messageType: '@',
  };

  template: string = template;
  controller = MessageController;
  controllerAs = 'ctrl';
}

class MessageController implements ng.IController {
  messageTitle: string;
  messageText: string;
  messageType: string = 'success';

  $onInit() {
    // Empty block
  }

  getMessageClass(): string {
    let msgClass = '';
    switch (this.messageType) {
      case 'success':
        msgClass = 'dc-msg--success';
        break;
      case 'error':
        msgClass = 'dc-msg--error';
        break;
      case 'info':
        msgClass = 'dc-msg--info';
        break;
      case 'warning':
        msgClass = 'dc-msg--warning';
        break;
      default:
        break;
    }
    return msgClass;
  }

  getIconClass(): string {
    let iconClass = '';
    switch (this.messageType) {
      case 'success':
        iconClass = 'dc-icon--success';
        break;
      case 'error':
        iconClass = 'dc-icon--error';
        break;
      case 'info':
        iconClass = 'dc-icon--info';
        break;
      case 'warning':
        iconClass = 'dc-icon--warning';
        break;
      default:
        break;
    }
    return iconClass;
  }
}
