export enum DataSections {
  Address = 'ADDRESS',
  Bank = 'BANK_DATA',
  Billing = 'BILLING_DATA',
  Contact = 'CONTACT',
  Name = 'NAME',
}

export interface ChangedData {
  items: DataSections[];
}
