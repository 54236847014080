import {
  ProviderFormField,
  ContextParams,
} from 'Common/constants/configuration.constant';
import { Configuration } from 'Api/models/Configuration';

interface NovomindConfigFormParams {
  ftpPassword: string;
  ftpUrl: string;
  ftpExternalChannel: string;
  ftpExternalSubChannel?: string;
}

interface NovomindUrlParams extends ContextParams, NovomindConfigFormParams {}

export function generateNovomindConfig(
  config: Configuration, // current config
  params: NovomindUrlParams
): Configuration {
  const {
    ftpPassword,
    ftpUrl,
    merchantName,
    ftpExternalChannel,
    ftpExternalSubChannel,
  } = params;

  return {
    ...config,
    ftpConfig: {
      ...config.ftpConfig,
      ftpUsername: 'zalando',
      ftpPassword,
      ftpUrl,
      ftpPath: '/in/feedback/products/',
      ftpExternalPartnerName: merchantName,
      ftpExternalChannel,
      ftpExternalSubChannel,
    },
  };
}

export function getNovomindFormFields({ countryCode }): ProviderFormField[] {
  return [
    { label: 'Ftp password', name: 'ftpPassword', required: true },
    { label: 'Ftp url', name: 'ftpUrl', required: true },
    {
      label: 'External channel',
      name: 'ftpExternalChannel',
      required: true,
      defaultValue: `za${countryCode}`,
    },
    {
      label: 'External sub channel',
      name: 'ftpExternalSubChannel',
      defaultValue: '0',
    },
  ];
}
