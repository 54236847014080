import { MerchantGroup } from 'Api/models/MerchantGroup';
export const MERCHANT_GROUPS_LOADING = 'MERCHANT_GROUPS_LOADING';
export const MERCHANT_GROUPS_LOADED = 'MERCHANT_GROUPS_LOADED';

export interface MerchantGroupsState {
  loading: boolean;
  data: MerchantGroup[];
}

export const merchantGroupsInitialState: MerchantGroupsState = {
  loading: false,
  data: [],
};

export const merchantGroupsReducer = (
  state = { ...merchantGroupsInitialState },
  { type, payload }
) => {
  switch (type) {
    case MERCHANT_GROUPS_LOADED:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case MERCHANT_GROUPS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
