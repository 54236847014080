import { render, staticRenderFns } from "./review-status-legend.vue?vue&type=template&id=59c2fb64&scoped=true&lang=pug"
import script from "./review-status-legend.vue?vue&type=script&lang=ts"
export * from "./review-status-legend.vue?vue&type=script&lang=ts"
import style0 from "./review-status-legend.vue?vue&type=style&index=0&id=59c2fb64&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59c2fb64",
  null
  
)

export default component.exports