import { VAlert } from 'vuetify/lib/components/VAlert';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VAlert,
    {
      staticClass: "secondary--text",
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function () {
            return [
              _vm.type === "error"
                ? _c(
                    VIcon,
                    { staticClass: "mr-4", attrs: { color: "reject" } },
                    [_vm._v("mdi-alert")]
                  )
                : _vm._e(),
              _vm.type === "warning"
                ? _c(
                    VIcon,
                    { staticClass: "mr-4", attrs: { color: "warning" } },
                    [_vm._v("mdi-alert-circle-outline")]
                  )
                : _vm._e(),
              _vm.type === "info"
                ? _c(
                    VIcon,
                    { staticClass: "mr-4", attrs: { color: "warning" } },
                    [_vm._v("mdi-information-outline")]
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }