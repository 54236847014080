export const REQUIREMENTS_LOADED = 'REQUIREMENTS_LOADED';

export const statusReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case REQUIREMENTS_LOADED:
      return payload || state;
    default:
      return state;
  }
};
